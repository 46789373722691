/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CrBigInt: any;
  CrJson: any;
  CrTimestamp: number;
  Upload: any;
};

export type AcceptTalkLoungeRewardedQuestionInput = {
  reply_id: Scalars['ID'];
  talk_id: Scalars['ID'];
  /** 채택자 */
  user_account_id: Scalars['ID'];
};

/** activateInactiveUserAccount의 출력 */
export type ActivateInactiveUserAccountResult = {
  /** 성공여부 */
  success: Scalars['Boolean'];
};

export type ActiveExperienceGroupProductList = {
  /** 검색된 Item list */
  item_list: Array<ExperienceGroupProduct>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

export type ActiveExperienceGroupProductListInput = {
  /** Limit 수량 */
  limit_count: Scalars['Int'];
  /** Shop Department 필터 */
  shop_department?: InputMaybe<Scalars['String']>;
  /** Skip(Offset) 수량 */
  skip_count: Scalars['Int'];
};

export type ActivePromotionExperienceGroupProductList = {
  /** 검색된 Item list */
  item_list: Array<PromotionExperienceGroupProduct>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

export type ActivePromotionExperienceGroupProductListInput = {
  /** Limit 수량 */
  limit_count: Scalars['Int'];
  /** Shop Department 필터. 전체-null, 쇼핑몰-SOHO_FASHION, 뷰티-BEAUTY, 브랜드-BRAND_FASHION, 라이프-LIFE */
  shop_department?: InputMaybe<Scalars['String']>;
  /** Skip(Offset) 수량 */
  skip_count: Scalars['Int'];
};

export type ActiveRaffleList = {
  /** 검색된 Item list */
  item_list: Array<Raffle>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

/** 조회된 활성화된 포인트 보상 목록 데이터 */
export type ActivedUserAccountPointRewardList = {
  /** 조회된 활성화된 포인트 보상 목록 */
  item_list: Array<UserAccountPointReward>;
  /** 조회된 활성화된 포인트 보상 목록수 */
  total_count: Scalars['Int'];
};

export type AdAgreementSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type AdAgreementSectionProps = {
  deeplink_url?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  type: BenefitSectionType;
};

export type AdBannerBadge = {
  background_color?: Maybe<UxCommonColor>;
  badge_type: BannerBadgeType;
  text: Scalars['String'];
};

export enum AdBannerType {
  CLP = 'CLP',
  LARGE = 'LARGE',
  LARGE_NEW = 'LARGE_NEW',
  MID = 'MID',
  MID_NEW = 'MID_NEW',
  SMALL = 'SMALL',
  X_LARGE = 'X_LARGE',
  X_MID = 'X_MID',
}

export type AdBenefitBadge = {
  badge_type: BenefitBadgeType;
  text: Scalars['String'];
};

export type AdBridgeInfo = {
  ad_id: Scalars['ID'];
  bridge_item: UxGoodsCardItem;
  creative_id: Scalars['ID'];
  image_url: Scalars['String'];
  item_list?: Maybe<Array<UxGoodsCardItem>>;
  landing_url: Scalars['String'];
  shop: SearchedShop;
  text_data: Scalars['String'];
};

/** 노출중인 소재 */
export type AdDisplayActiveAd = {
  /** 소재 이미지 url */
  I: Scalars['String'];
  /** 상품의 할인율 */
  discount_rate: Scalars['Int'];
  /** 배송비 무료 여부 */
  free_shipping: Scalars['Boolean'];
  /** 소재 ID */
  i: Scalars['ID'];
  /** 소재 링크 url */
  l: Scalars['String'];
  /** 인스턴스 소재일 경우 origin소재의 id */
  o?: Maybe<Scalars['ID']>;
  /** 가격 */
  p: Scalars['Int'];
  /** 타이틀 */
  t: Scalars['String'];
  /** zpay 가능 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
};

/** 노출중인 소재 목록 */
export type AdDisplayActiveAdList = {
  /** 소재 리스트 */
  item_list: Array<AdDisplayActiveAd>;
};

export type AdDisplayBanner = {
  aspect_ratio: Scalars['Float'];
  dark_image_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export enum AdDisplayBannerType {
  GOODS_SEARCH = 'GOODS_SEARCH',
  PRODUCT_DETAIL_PAGE = 'PRODUCT_DETAIL_PAGE',
}

/** 광고 상품 카드 */
export type AdDisplayCardItem = GoodsCardItem & {
  /** 브릿지 페이지 여부 */
  bridge: Scalars['Boolean'];
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 배송비 무료 여부 */
  free_shipping: Scalars['Boolean'];
  /** 소재 이미지 url */
  image_url: Scalars['String'];
  /** 인스턴스 소재일 경우 origin 소재의 id */
  origin_id?: Maybe<Scalars['ID']>;
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** 가격 */
  price: Scalars['Int'];
  /** 소재 링크 url */
  product_url: Scalars['String'];
  /** 타이틀 */
  title: Scalars['String'];
  /** 상품 카드의 타입 */
  type: GoodsCardItemType;
  /** 소재 ID */
  uuid: Scalars['ID'];
  /** zpay 가능 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
};

export type AdDisplayShop = {
  coupon_description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  main_copy?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  shop: SearchedShop;
  type?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export enum AdDisplayShopType {
  SHOPS_FAVORITES = 'SHOPS_FAVORITES',
  SHOPS_RANKING = 'SHOPS_RANKING',
  SHOPS_SEARCH = 'SHOPS_SEARCH',
}

export type AdExhibitionInfo = {
  free_shipping?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image_url_list: Array<Scalars['String']>;
  item_list: Array<UxGoodsCardItem>;
  max_discount_rate?: Maybe<Scalars['Int']>;
  shop: SearchedShop;
  sub_text_data?: Maybe<Scalars['String']>;
  text_data: Scalars['String'];
};

export type AdMultiBoardFeed = {
  ad_multi_board_type: AdMultiBoardType;
  background_color?: Maybe<Scalars['String']>;
  badge_color?: Maybe<Scalars['String']>;
  benefit_badge?: Maybe<AdBenefitBadge>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  log: Scalars['String'];
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  shop_id: Scalars['ID'];
  sub_copy?: Maybe<Scalars['String']>;
};

export enum AdMultiBoardType {
  LARGE = 'LARGE',
  LINE = 'LINE',
  MID = 'MID',
  SMALL = 'SMALL',
  X_LARGE = 'X_LARGE',
  X_MID = 'X_MID',
}

/** 광고성 푸시 동의 상태 */
export enum AdNotiStatus {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

/** AdVideoType */
export enum AdVideoType {
  /** 플로팅 비디오 */
  FLOATING = 'FLOATING',
  /** 퀵메뉴 비디오 */
  QUICK_MENU = 'QUICK_MENU',
}

export type AddAttributeToStoryInput = {
  coupon_id?: InputMaybe<Scalars['ID']>;
  product_id_list?: InputMaybe<Array<Scalars['String']>>;
  story_id: Scalars['ID'];
};

/** 30일 지난 장바구니 상품 다시 담기의 입력 */
export type AddBackOldCartItemListInput = {
  /** CartItem 식별자 리스트 */
  cart_item_identifier_list: Array<CartItemIdentifierInput>;
};

/** 장바구니 추가할 아이템의 입력 */
export type AddCartItemListInput = {
  /** 상품 레코드 ID, 추가 옵션 정보와 아이템별 장바구니 수량 변동량의 배열 */
  product_item_quantity_list: Array<AddCartProductItemQuantityInput>;
};

/** 상품 레코드 ID, 추가 옵션 정보와 아이템별 장바구니 수량 변동량의 배열 */
export type AddCartProductItemQuantityInput = {
  /** 상품 아이템에 추가적으로 필요한 옵션 정보 리스트 */
  additional_option_detail_list?: InputMaybe<Array<ProductAdditionalOptionInput>>;
  /** 퍼널 정보 */
  funnel_info?: InputMaybe<FunnelInfoInput>;
  /** grouping 적용 수량 (2+1 상품의 경우, 할당되어야 하는 값은 3) */
  grouping_required_quantity?: InputMaybe<Scalars['Int']>;
  /** 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 프로모션 아이템 그룹 식별자 */
  promotion_item_group_key?: InputMaybe<Scalars['String']>;
  /** 아이템별 장바구니 수량 변동량 */
  quantity_delta: Scalars['Int'];
};

export type AffCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type AffCommonHtmlText = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type AffCommonImageUrl = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

export type AffCommonText = {
  color?: Maybe<AffCommonColor>;
  html?: Maybe<AffCommonHtmlText>;
  text: Scalars['String'];
};

export enum AffiliateInspectionStatus {
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
}

export type AffiliateMemberInfo = {
  is_active: Scalars['Boolean'];
  is_member: Scalars['Boolean'];
  reward_info_text: Scalars['String'];
};

/** 어필레이트 가입한 사람에게 노출될 component */
export type AffiliatePdpMemberComponent = {
  /** 배경 색상 */
  background_color: AffCommonColor;
  /** 공유리워드 부스트 뱃지 url */
  badge_url?: Maybe<AffCommonImageUrl>;
  /** 어필레이트 활성화 여부 */
  is_active: Scalars['Boolean'];
  /**
   * landing url
   * - 활성화 유저인 경우에만 값이 내려간다.
   */
  landing_url?: Maybe<Scalars['String']>;
  /** 리워드 제공 문구 */
  reward_text: AffCommonText;
};

/** 어필레이트 가입하지 않은 사람에게 노출될 component */
export type AffiliatePdpNonMemberComponent = {
  /** 배경 색상 */
  background_color: AffCommonColor;
  /** landing url */
  landing_url: Scalars['String'];
  /** 리워드 제공 문구 */
  reward_text: AffCommonText;
};

export type AffiliatePromotionShop = {
  /** 우선 노출 대상 여부 */
  is_priority: Scalars['Boolean'];
  /**
   * 샵 도메인
   * - landing url 생성시 필요한 정보
   */
  shop_domain: Scalars['String'];
  /** 스토어 id */
  shop_id: Scalars['ID'];
  /** 샵 이미지 url */
  shop_image_url?: Maybe<Scalars['String']>;
  /** 스토어 이름 */
  shop_name: Scalars['String'];
};

export type AffiliatePromotionShopList = {
  item_list: Array<AffiliatePromotionShop>;
  /** 전체 갯수 */
  total_count: Scalars['Int'];
};

export type AffiliateServiceInfo = {
  is_available: Scalars['Boolean'];
  max_reward_rate_text: Scalars['String'];
};

export type AffiliateShareLink = {
  date_created: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  long_url: Scalars['String'];
  short_url: Scalars['String'];
};

export enum AgeGroup {
  AGE_00 = 'AGE_00',
  AGE_10 = 'AGE_10',
  AGE_20 = 'AGE_20',
  AGE_30 = 'AGE_30',
  AGE_40 = 'AGE_40',
  AGE_50 = 'AGE_50',
  AGE_60 = 'AGE_60',
  AGE_OTHERS = 'AGE_OTHERS',
  NONE = 'NONE',
}

export enum AgeGroupBin2 {
  AGE_00_04 = 'AGE_00_04',
  AGE_05_09 = 'AGE_05_09',
  AGE_10_14 = 'AGE_10_14',
  AGE_15_19 = 'AGE_15_19',
  AGE_20_24 = 'AGE_20_24',
  AGE_25_29 = 'AGE_25_29',
  AGE_30_34 = 'AGE_30_34',
  AGE_35_39 = 'AGE_35_39',
  AGE_40_44 = 'AGE_40_44',
  AGE_45_49 = 'AGE_45_49',
  AGE_50_54 = 'AGE_50_54',
  AGE_55_59 = 'AGE_55_59',
  AGE_60_64 = 'AGE_60_64',
  AGE_65_69 = 'AGE_65_69',
  AGE_OTHERS = 'AGE_OTHERS',
  NONE = 'NONE',
}

export enum AgeGroupBin3 {
  AGE_00_03 = 'AGE_00_03',
  AGE_04_06 = 'AGE_04_06',
  AGE_07_09 = 'AGE_07_09',
  AGE_10_13 = 'AGE_10_13',
  AGE_14_16 = 'AGE_14_16',
  AGE_17_19 = 'AGE_17_19',
  AGE_20_23 = 'AGE_20_23',
  AGE_24_26 = 'AGE_24_26',
  AGE_27_29 = 'AGE_27_29',
  AGE_30_33 = 'AGE_30_33',
  AGE_34_36 = 'AGE_34_36',
  AGE_37_39 = 'AGE_37_39',
  AGE_40_43 = 'AGE_40_43',
  AGE_44_46 = 'AGE_44_46',
  AGE_47_49 = 'AGE_47_49',
  AGE_50_53 = 'AGE_50_53',
  AGE_54_56 = 'AGE_54_56',
  AGE_57_59 = 'AGE_57_59',
  AGE_60_63 = 'AGE_60_63',
  AGE_64_66 = 'AGE_64_66',
  AGE_67_69 = 'AGE_67_69',
  AGE_OTHERS = 'AGE_OTHERS',
  NONE = 'NONE',
}

export enum AgeGroupType {
  AGE10_19 = 'AGE10_19',
  AGE20_24 = 'AGE20_24',
  AGE25_29 = 'AGE25_29',
  AGE30_34 = 'AGE30_34',
  AGE35_39 = 'AGE35_39',
  AGE40_OVER = 'AGE40_OVER',
  ALL = 'ALL',
}

export type AgeRecommendedGoodsInfo = {
  recommended_goods_list: Array<Maybe<UxGoodsCardItem>>;
  title?: Maybe<Scalars['String']>;
};

export enum AgeRecommendedKeywordAgeType {
  AGE_10 = 'AGE_10',
  AGE_20 = 'AGE_20',
  AGE_30 = 'AGE_30',
  ALL = 'ALL',
}

export type AgeRecommendedKeywordGroup = {
  age: Scalars['String'];
  age_type: AgeRecommendedKeywordAgeType;
  recommended_keyword_list: Array<Maybe<Scalars['String']>>;
};

export type AgeRecommendedKeywordInfo = {
  age_recommended_keyword_group_list: Array<Maybe<AgeRecommendedKeywordGroup>>;
  title?: Maybe<Scalars['String']>;
};

/** AgreePushCategory의 입력 */
export type AgreePushCategoryInput = {
  /** 카테고리 동의 여부 */
  agreed: Scalars['Boolean'];
  /** 카테고리 명 */
  category: Scalars['String'];
};

/** 사용자 계정 약관 동의의 입력 */
export type AgreeUserAccountTermInput = {
  /** 약관 유형의 배열 */
  term_type_list: Array<UserAccountTermType>;
};

/** 약관 동의 결과 */
export type AgreeUserAccountTermResult = {
  /** 실패 사유 */
  error_message?: Maybe<Scalars['String']>;
  /** 성공 여부 */
  success: Scalars['Boolean'];
};

/** 이미 적용된 쿠폰 정보 */
export type AlreadyAppliedCouponInfo = {
  /** 적용된 쿠폰 ID */
  already_applied_coupon_id: Scalars['ID'];
  /** 적용된 상품 정보 목록 */
  already_applied_product_list: Array<AlreadyAppliedProductInfo>;
};

/** 이미 쿠폰이 적용된 상품 정보 */
export type AlreadyAppliedProductInfo = {
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 옵션 ID */
  product_item_id: Scalars['ID'];
};

export type AnswerTalkLoungePollInput = {
  poll_id: Scalars['ID'];
  poll_option_id_list: Array<Scalars['ID']>;
};

/** 푸시 동의 상태 */
export enum AppNotiStatus {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

/** 앱 웹뷰 에러로그 입력 */
export type AppWebViewLogInput = {
  scene_name: Scalars['String'];
  status_code: Scalars['String'];
  url: Scalars['String'];
};

export type AppleLoginConfig = {
  bundle_app_id: Scalars['String'];
  /** @deprecated use bundle_app_id or bundle_service_id */
  bundle_id?: Maybe<Scalars['String']>;
  bundle_service_id: Scalars['String'];
  nonce: Scalars['String'];
};

/** 적용 가능한 쿠폰 정책 */
export type ApplicableCouponPolicy = {
  /** 대상 국가 목록 */
  country_list?: Maybe<Array<Scalars['String']>>;
  /** 쿠폰 TARGET TYPE */
  coupon_target_type: CouponTargetType;
  /** 쿠폰 정책 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 쿠폰 등록 종료일 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 응답 포맷 적용된 쿠폰 등록 종료일 */
  date_issue_end_with_format: Scalars['String'];
  /** 쿠폰 설명 */
  description?: Maybe<Scalars['String']>;
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 통화 적용된 할인금액 */
  discount_amount_with_currency?: Maybe<CouponPriceWithCurrency>;
  /** 쿠폰 할인률 */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 쿠폰 다운로드 가능 상태 */
  download_status: UserAccountCouponStatus;
  /** 국가 글로벌 타이틀 */
  global_title?: Maybe<Scalars['CrJson']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** Z-Only 대표샵 쿠폰 여부 */
  is_zonly_store_coupon: Scalars['Boolean'];
  /** 쿠폰이 최대로 할인해줄수있는 금액 */
  max_discount_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰 정책 사용 가능 플랫폼 타입 */
  platform: PolicyPlatform;
  /** 쿠폰 발급 유저 타입 */
  registration_type: CouponRegistrationType;
  /** 유저당 사용 가능한 횟수(무제한 : -1, 기본값 1) */
  repeatable_use_count: Scalars['Int'];
  /**
   * 사이트 ID (지그재그와 다른 사이트 구분을 위해 사용)
   * @deprecated site_id_list로 대체
   */
  site_id: Scalars['Int'];
  /** 사이트 ID 목록 */
  site_id_list?: Maybe<Array<Scalars['Int']>>;
  /** policy의 target 목록 */
  target_list?: Maybe<Array<UserAccountCouponTarget>>;
  /** 쿠폰 title */
  title?: Maybe<Scalars['String']>;
};

/** 적용 가능한 쿠폰 정책 */
export type ApplicableCouponPolicyTarget_ListArgs = {
  filter_duplicate_product?: InputMaybe<Scalars['Boolean']>;
};

/** 적용 가능한 쿠폰 정책 리스트 */
export type ApplicableCouponPolicyList = {
  item_list: Array<ApplicableCouponPolicy>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export type AppliedShippingInfoList = {
  item_list: Array<ShippingInfoPerSiteCountry>;
};

/** 체험단 프로모션 신청(사용자용) Input */
export type ApplyPromotionExperienceGroupInput = {
  /** 상품 ITEM ID */
  item_id: Scalars['ID'];
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 프로모션 ID */
  promotion_id: Scalars['ID'];
  /** 배송 주소록 아이디 */
  user_shipping_address_book_id: Scalars['ID'];
  /** 배송 메모 관리 */
  user_shipping_memo?: InputMaybe<PromotionUserShippingMemoInput>;
};

/** 체험단 프로모션 신청(사용자용) Input */
export type ApplyPromotionExperienceGroupProductApplicantInput = {
  /** country */
  country: Scalars['String'];
  /** 모집주차 ID */
  experience_group_schedules_id: Scalars['ID'];
  /** 상품 ITEM ID */
  item_id: Scalars['ID'];
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 프로모션 ID */
  promotion_id: Scalars['ID'];
  /** SITE ID */
  site_id: Scalars['ID'];
};

export type ApplyRaffleInput = {
  /** 응모할 프로모션 상품 아이템 ID (PromotionProductItem, catalog_product_item_id X) */
  promotion_product_item_id: Scalars['ID'];
  /** 래플 ID */
  raffle_id: Scalars['ID'];
};

/**
 * 본인인증 노출 목록 조회
 * - KMC
 * - KAKAOBANK
 */
export type AuthenticationAgencyListResult = {
  agency?: Maybe<Array<Scalars['String']>>;
};

export type AuthorizeAndGetEmailsInput = {
  mobile_tel: Scalars['String'];
  token: Scalars['String'];
};

export type AuthorizeAndGetEmailsResult = {
  email_list: Array<Maybe<Scalars['String']>>;
  membership_benefits_url: Scalars['String'];
};

/** 이벤트용 휴대폰 토큰 인증 입력 */
export type AuthorizeMobileTokenForEventInput = {
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 토큰 */
  token: Scalars['String'];
};

/** authorizeMobileToken의 입력 */
export type AuthorizeMobileTokenInput = {
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 인증 토큰 */
  token: Scalars['String'];
};

export type BankCode = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type BankCodeList = {
  item_list?: Maybe<Array<BankCode>>;
};

export type Banner = {
  /** 배너의 비율 */
  aspect_ratio: Scalars['Float'];
  /** 컨텐츠 html url */
  content_url: Scalars['String'];
  /** 배너의 위치 */
  index?: Maybe<Scalars['Int']>;
  /** 배너의 표시 위치 */
  position: BannerPosition;
  /** 배너의 종류 */
  type: BannerType;
};

export type BannerAdBadgeInfo = {
  badge_color?: Maybe<Scalars['String']>;
  badge_type: Scalars['String'];
  text: Scalars['String'];
};

export type BannerAdCreative = {
  banner_info: BannerAdInfo;
  creative?: Maybe<Creative>;
  log?: Maybe<Scalars['String']>;
};

export type BannerAdCreativeList = {
  item_count: Scalars['Int'];
  item_list: Array<BannerAdCreative>;
};

export type BannerAdInfo = {
  background_color?: Maybe<Scalars['String']>;
  badge?: Maybe<BannerAdBadgeInfo>;
  banner_type: Scalars['String'];
  color_set_info?: Maybe<ColorSetInfo>;
  shop_id: Scalars['ID'];
  shop_name?: Maybe<Scalars['String']>;
};

export enum BannerBadgeType {
  COUPON = 'COUPON',
  DEFAULT = 'DEFAULT',
}

export type BannerItem = {
  data: BannerItemData;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  display_status: BannerItemDisplayStatus;
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: BannerItemType;
};

export type BannerItemData = ImageBannerData | ProductsPresetBannerData;

export enum BannerItemDisplayStatus {
  DISPLAY = 'Display',
  TERMINATED = 'Terminated',
  WAITING = 'Waiting',
}

export type BannerItemListResult = {
  item_list: Array<BannerItem>;
  total_count: Scalars['Int'];
};

export enum BannerItemType {
  IMAGE_BANNER = 'ImageBanner',
  PRODUCTS_PRESET_BANNER = 'ProductsPresetBanner',
}

export type BannerList = {
  /** 배너 목록 */
  item_list: Array<Banner>;
};

export type BannerNudgingBadge = {
  banner_id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

/** 배너 위치 */
export enum BannerPosition {
  FIXED_TOP = 'FIXED_TOP',
  IN_CONTENTS = 'IN_CONTENTS',
}

export type BannerShopInput = {
  banner_id: Scalars['ID'];
  shop_id?: InputMaybe<Scalars['ID']>;
};

/** 배너 종류 */
export enum BannerType {
  AD_DISPLAY = 'AD_DISPLAY',
  SEARCH = 'SEARCH',
}

/** 뷰티 이벤트 프리퀀시 메타정보 */
export type BeautyEventFrequencyMeta = {
  /** 이벤트 진행여부 */
  is_in_progress: Scalars['Boolean'];
  /** 프리퀀시 이벤트 보상 상품 */
  reward_product?: Maybe<BeautyEventFrequencyMetaRewardProduct>;
};

/** 뷰티 이벤트 프리퀀시 메타 보상 상품 */
export type BeautyEventFrequencyMetaRewardProduct = {
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 (ux 서비스 값 bypass) */
  browsing_type: Scalars['String'];
  /** 상품 ID */
  id: Scalars['ID'];
  /** 상품 클릭시 이동할 url 주소 (ux 서비스 값 by pass) */
  product_url: Scalars['String'];
  /** 샵 ID (ux 서비스 값 by pass) */
  shop_id: Scalars['ID'];
  /**
   * 안전재고 (fulfillment api bypass)
   * 안전재고 = 가용재고 - 버퍼재고
   */
  total_safety_quantity: Scalars['Int'];
};

export type BeautyInfo = {
  /** PDP 뷰티 상품 성분 상세 정보 */
  beauty_ingredient_detail?: Maybe<PdpBeautyIngredientDetail>;
  /** PDP 뷰티 상품 성분 정보 요약 */
  beauty_ingredient_summary?: Maybe<PdpBeautyIngredientSummary>;
  /** PDP 뷰티 한줄평 리뷰 */
  beauty_one_line_review?: Maybe<PdpBeautyOneLineReview>;
  /** 상품 옵션 컬러칩 list */
  product_option_color_chip_list: Array<PdpBeautyProductOptionColorChip>;
};

export type BeautyNrtRanking = {
  item_list: Array<DdpProductCardItem>;
  total_click_count: Scalars['Int'];
};

/** 뷰티 주문 아이템 이벤트 프리퀀시 요약 */
export type BeautyOrderItemEventFrequencySummary = {
  /** 발급된 쿠폰 갯수 */
  issued_coupon_count: Scalars['Int'];
  /** 뷰티 주문 아이템 프리퀀시 갯수 */
  order_item_frequency_count: Scalars['Int'];
  /** 뷰티 주문 아이템 현금 결제 금액 */
  order_item_paid_cash_amount: Scalars['Int'];
};

/** 뷰티 주문 아이템 프리퀀시 요약 */
export type BeautyOrderItemFrequencySummary = {
  /** 발급된 쿠폰 갯수 */
  issued_coupon_count: Scalars['Int'];
  /** 뷰티 주문 아이템 프리퀀시 갯수 */
  order_item_frequency_count: Scalars['Int'];
};

export type BeautySampleCategorizedItemList = {
  item_list: Array<BeautySampleItem>;
  total_count: Scalars['Int'];
};

export type BeautySampleItem = {
  /** 상품 id */
  product_id: Scalars['ID'];
  /** 상품 이미지 */
  product_image_url?: Maybe<Scalars['String']>;
  /** 상품의 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 점수 */
  review_score?: Maybe<Scalars['Float']>;
  /** 스토어 id */
  shop_id: Scalars['ID'];
  /** 스토어 이름 */
  shop_name: Scalars['String'];
  /** 상품 제목 */
  title: Scalars['String'];
};

export type BeautySpecialOfferCategorizedItem = {
  category: DdpCategory;
  item_list: Array<DdpProductCardItem>;
};

export type BeautySpecialOfferCategorizedItemList = {
  item_list: Array<BeautySpecialOfferCategorizedItem>;
};

export type BehaveDailyMissionInput = {
  /**
   * 행동미션 타입
   * --
   * CLICK_PRODUCT_BEAUTY_ON_SALE : 뷰티 특가 상품 클릭
   * VIEW_SELLER_CONTENT : 셀러 기획전 확인
   * CLICK_PRODUCT_BRAND_ON_SALE : 브랜드 특가 상품 클릭
   * CLICK_PRODUCT_BLACK_WEEK : 블랙위크 특가 상품 클릭
   * VISIT_COUPANG : 쿠팡 방문
   * CLICK_VIDEO_AD : 영상 광고 클릭
   */
  behavior_type: Scalars['String'];
  value: Scalars['String'];
};

export enum BenefitBadgeType {
  COUPON = 'COUPON',
  DEFAULT = 'DEFAULT',
  FREE_SHIPPING = 'FREE_SHIPPING',
}

export type BenefitBanner =
  | BenefitMainBanner
  | BenefitPinLightBanner
  | BenefitSquarePickBanner
  | BenefitStrapBanner
  | BenefitSubBanner;

export type BenefitBannerBadge = {
  color: Scalars['String'];
  text: Scalars['String'];
};

export type BenefitBannerDisplayAppVersion = {
  aos?: Maybe<Scalars['String']>;
  ios?: Maybe<Scalars['String']>;
};

export type BenefitBannerMyPickCouponItem = {
  /** 쿠폰 셋 ID */
  coupon_set_id: Scalars['ID'];
  /** 할인 정보 */
  discount_info: Scalars['String'];
  /** 스토어 image_url */
  image_url: Scalars['String'];
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  /** 스토어명 */
  name: Scalars['String'];
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 스토어 스타일 */
  style?: Maybe<Scalars['String']>;
};

export enum BenefitBannerOs {
  AOS = 'AOS',
  IOS = 'IOS',
}

/** 클라이언트에서 요청하는 정렬기준 */
export enum BenefitBannerRequestOrderType {
  /** 할인율순 */
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  /** 최신순 */
  LATEST = 'LATEST',
  /** 추천순(백오피스 설정값 기준) */
  RECOMMEND = 'RECOMMEND',
}

export type BenefitBannerShop = {
  id: Scalars['Int'];
  name: Scalars['String'];
  typical_image_url: Scalars['String'];
};

export type BenefitBannerTag = {
  color: Scalars['String'];
  text: Scalars['String'];
};

export enum BenefitBannerType {
  MAIN_BANNER = 'MAIN_BANNER',
  PIN_LIGHT_BANNER = 'PIN_LIGHT_BANNER',
  SQUAREPICK_BANNER = 'SQUAREPICK_BANNER',
  STRAP_BANNER = 'STRAP_BANNER',
  SUB_BANNER = 'SUB_BANNER',
}

export type BenefitCoupon = {
  /** 만료 기간 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
};

export type BenefitMainBanner = {
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  display_app_version_lt: BenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: BenefitBannerType;
  uuid: Scalars['String'];
};

export type BenefitMyPickCouponListIssuedInfo = {
  /** 쿠폰 만료 시간 */
  coupon_policy_end_date: Scalars['CrTimestamp'];
};

/** 혜택존 핀라이트배너타입 */
export type BenefitPinLightBanner = {
  badge_list?: Maybe<Array<BenefitBannerBadge>>;
  benefit_section_content?: Maybe<Scalars['String']>;
  content_name?: Maybe<Scalars['String']>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent?: Maybe<Scalars['Int']>;
  display_app_version_lt: BenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: BenefitBannerShop;
  store_pick_id?: Maybe<Scalars['Int']>;
  tag_list?: Maybe<Array<BenefitBannerTag>>;
  title: Scalars['String'];
  type: BenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

export enum BenefitProductBrowsingType {
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type BenefitProductCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** ux 스키마에서 UxGoodsCardItem 그대로 가져와서 사용 */
export type BenefitProductCardItem = BenefitProductComponent & {
  /** 광고 id */
  aid?: Maybe<Scalars['String']>;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: Scalars['Boolean'];
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge?: Maybe<Scalars['Boolean']>;
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 */
  browsing_type: BenefitProductBrowsingType;
  /** catalog_product_id */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 상품의 type */
  catalog_product_type?: Maybe<BenefitProductCatalogProductType>;
  /** 상품이 한줄에 몇개 표기할지 */
  column_count?: Maybe<Scalars['Int']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<BenefitProductCardDiscountInfo>;
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** goods_id */
  goods_id?: Maybe<Scalars['String']>;
  /** 쿠폰 사용 여부 */
  has_coupon: Scalars['Boolean'];
  /** 이미지 비율 */
  image_ratio?: Maybe<Scalars['Float']>;
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** log */
  log?: Maybe<Scalars['String']>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price?: Maybe<Scalars['Int']>;
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** 상품 카드에 숫자를 표기하기 위한 필드 */
  ranking?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** Catalog 상품의 상태 (판매중, 품절) */
  sales_status?: Maybe<BenefitProductSearchedProductSalesStatus>;
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** Goods 상품의 상태 (판매중, 품절) */
  status?: Maybe<BenefitProductGoodsStatus>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 컴포넌트 type */
  type: BenefitProductComponentType;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid?: Maybe<Scalars['String']>;
  /** 상품 카드에 비디오 형식으로 이미지 노출 (광고팀에서 사용) */
  video_url?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price?: Maybe<Scalars['Int']>;
};

export enum BenefitProductCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

export type BenefitProductComponent = {
  position: Scalars['Int'];
  type: BenefitProductComponentType;
};

export enum BenefitProductComponentType {
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

/** 상품 상태 */
export enum BenefitProductGoodsStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

export enum BenefitProductSearchedProductSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export enum BenefitProductShippingFeeType {
  /** 무료 배송 */
  FREE_SHIPPING = 'FREE_SHIPPING',
  /** SHOP의 배송정책을 따름 */
  SHOP_POLICY = 'SHOP_POLICY',
}

export type BenefitSectionData =
  | AdAgreementSectionData
  | BigProductSlideSectionData
  | MainBannerGroupSectionData
  | MyCouponSectionData
  | MyPickCouponListSectionData
  | PinLightBannerSlideSectionData
  | ProductCustomTabsSectionData
  | ProductGridSectionData
  | ProductsCategoryTabsSectionData
  | ProductsSliderSectionData
  | SquarePickBannerGroupSectionData
  | StoreCouponPriceTabsSectionData
  | StoreCouponSlideSectionData
  | StoreCouponTabsSectionData
  | StrapBannerGroupSectionData
  | SubBannerGroupSectionData
  | TimedealSectionData
  | TopImageProductGridSectionData;

export type BenefitSectionDataResult = {
  data: BenefitSectionData;
};

export type BenefitSectionProps =
  | AdAgreementSectionProps
  | BigProductSlideSectionProps
  | MainBannerGroupSectionProps
  | MyCouponSectionProps
  | MyPickCouponListSectionProps
  | PinLightBannerSlideSectionProps
  | ProductCustomTabsSectionProps
  | ProductGridSectionProps
  | ProductsCategoryTabsSectionProps
  | ProductsSliderSectionProps
  | SquarePickBannerGroupSectionProps
  | StoreCouponPriceTabsSectionProps
  | StoreCouponSlideSectionProps
  | StoreCouponTabsSectionProps
  | StrapBannerGroupSectionProps
  | SubBannerGroupSectionProps
  | TimedealSectionProps
  | TopImageProductGridSectionProps;

export type BenefitSectionPropsResult = {
  data: Array<BenefitSectionTypeProps>;
};

export enum BenefitSectionType {
  AD_AGREEMENT_SECTION = 'AdAgreementSection',
  BIG_PRODUCT_SLIDE_SECTION = 'BigProductSlideSection',
  MAIN_BANNER_GROUP_SECTION = 'MainBannerGroupSection',
  MY_COUPON_SECTION = 'MyCouponSection',
  MY_PICK_COUPON_LIST_SECTION = 'MyPickCouponListSection',
  PIN_LIGHT_BANNER_SLIDE_SECTION = 'PinLightBannerSlideSection',
  PRODUCT_CUSTOM_TABS_SECTION = 'ProductCustomTabsSection',
  PRODUCT_GRID_SECTION = 'ProductGridSection',
  PRODUCTS_CATEGORY_TABS_SECTION = 'ProductsCategoryTabsSection',
  PRODUCTS_SLIDER_SECTION = 'ProductsSliderSection',
  SQUARE_PICK_BANNER_GROUP_SECTION = 'SquarePickBannerGroupSection',
  STORE_COUPON_PRICE_TABS_SECTION = 'StoreCouponPriceTabsSection',
  STORE_COUPON_SLIDE_SECTION = 'StoreCouponSlideSection',
  STORE_COUPON_TABS_SECTION = 'StoreCouponTabsSection',
  STRAP_BANNER_GROUP_SECTION = 'StrapBannerGroupSection',
  SUB_BANNER_GROUP_SECTION = 'SubBannerGroupSection',
  TIMEDEAL_SECTION = 'TimedealSection',
  TOP_IMAGE_PRODUCT_GRID_SECTION = 'TopImageProductGridSection',
}

export type BenefitSectionTypeProps = {
  benefit_section_uuid: Scalars['String'];
  id: Scalars['Int'];
  props: BenefitSectionProps;
  type: BenefitSectionType;
};

/** 스토어검색API 스키마에서 미사용 필드만 제거 */
export type BenefitShop = {
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type BenefitSquarePickBanner = {
  badge_list?: Maybe<Array<BenefitBannerBadge>>;
  content_name?: Maybe<Scalars['String']>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent: Scalars['Int'];
  display_app_version_lt: BenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: BenefitBannerShop;
  store_pick_id?: Maybe<Scalars['Int']>;
  tag_list?: Maybe<Array<BenefitBannerTag>>;
  title: Scalars['String'];
  type: BenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

export type BenefitStrapBanner = {
  content_name?: Maybe<Scalars['String']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  display_app_version_lt: BenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: BenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

export type BenefitSubBanner = {
  badge_list?: Maybe<Array<BenefitBannerBadge>>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent: Scalars['Int'];
  display_app_version_lt: BenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: BenefitBannerShop;
  tag_list?: Maybe<Array<BenefitBannerTag>>;
  title: Scalars['String'];
  type: BenefitBannerType;
  uuid: Scalars['String'];
};

export type BetaTestItem = {
  badge?: Maybe<Scalars['String']>;
  card_inner_badge?: Maybe<Scalars['String']>;
  image_url: UxCommonImage;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  sub_title?: Maybe<UxCommonText>;
  ubl?: Maybe<UxUbl>;
};

export type BigProductSlideSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type BigProductSlideSectionProps = {
  badge_type: Scalars['String'];
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  date_started?: Maybe<Scalars['CrTimestamp']>;
  products_preset_uuid: Scalars['String'];
  server_time?: Maybe<Scalars['CrTimestamp']>;
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type BlockTalkLoungeUserInput = {
  /** 신고할 유저 ID */
  user_account_id: Scalars['ID'];
};

export type BogoInfo = {
  /** 프로모션 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 프로모션 할인률 */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 프로모션 할인 타입 */
  discount_type?: Maybe<PdpBogoDiscountType>;
  /** N+1 프로모션 적용을 위해 요구되는 최소 수량 (N값) */
  required_quantity?: Maybe<Scalars['Int']>;
};

export type BookmarkStoryCoupon = {
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  date_issue_end: Scalars['CrTimestamp'];
  date_issue_start: Scalars['CrTimestamp'];
  days_expire?: Maybe<Scalars['Int']>;
  discount_amount?: Maybe<PriceWithCurrency>;
  discount_rate_bp?: Maybe<Scalars['Int']>;
  discount_type: PolicyDiscountType;
  id: Scalars['ID'];
  managed_name: Scalars['String'];
  max_discount_amount?: Maybe<PriceWithCurrency>;
  min_required_amount?: Maybe<PriceWithCurrency>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
  registration_type: BookmarkStoryCouponRegistrationType;
};

export enum BookmarkStoryCouponIssueStatus {
  EXHAUSTED = 'EXHAUSTED',
  ISSUABLE = 'ISSUABLE',
  ISSUED = 'ISSUED',
  ISSUED_COUPON_EXPIRED = 'ISSUED_COUPON_EXPIRED',
  ISSUE_EXPIRED = 'ISSUE_EXPIRED',
}

export type BookmarkStoryCouponList = {
  item_list?: Maybe<Array<BookmarkStoryCoupon>>;
  total_count: Scalars['Int'];
};

export enum BookmarkStoryCouponRegistrationType {
  BOOKMARK = 'BOOKMARK',
  OTHER = 'OTHER',
  STORY = 'STORY',
}

export type BrandFashionAttribute = {
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected?: Maybe<Scalars['Boolean']>;
};

export type BrandFashionAttributeGroup = {
  attribute_list: Array<BrandFashionAttribute>;
  header?: Maybe<BrandFashionHeader>;
};

export type BrandFashionBrandList = {
  header: UxCommonText;
  item?: Maybe<Array<UxBrand>>;
};

export type BrandFashionHeader = {
  bottom_title?: Maybe<Scalars['String']>;
  main_title?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
};

export type BrandFashionOnboardingInput = {
  id_list?: InputMaybe<Array<Scalars['String']>>;
  user_account_id?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type BrandFashionOnboardingUser = {
  header?: Maybe<BrandFashionHeader>;
  image_url?: Maybe<Array<Maybe<Scalars['String']>>>;
  total_count: Scalars['Int'];
};

export type BrandFashionRankingInfo = {
  end_cursor?: Maybe<Scalars['Int']>;
  filter_list: Array<UxFilterItem>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxShopRankingCardItem>;
};

export type BrandFashionShop = {
  id: Scalars['ID'];
  name: Scalars['String'];
  product_count: Scalars['Int'];
  shop_main_domain: Scalars['String'];
  typical_image_url: Scalars['String'];
  url: Scalars['String'];
};

export type BrandFashionThemeList = {
  header: UxCommonText;
  item?: Maybe<Array<UxBrand>>;
};

/** Braze User Matching 데이터 */
export type BrazeUserMatching = {
  /** Braze Device Id */
  braze_device_id?: Maybe<Scalars['String']>;
  /** 생성일시 */
  date_created: Scalars['CrTimestamp'];
  /** BrazeUserMap Id */
  id: Scalars['ID'];
  /** 유저 디바이스 ID(User.uuid) */
  user_uuid?: Maybe<Scalars['String']>;
};

/** 브라우저 타입 */
export enum BrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type BrowsingTypeInfo = {
  type: PdpBrowsingType;
};

export enum Catalog_Carousel_Style {
  PRODUCT = 'PRODUCT',
  SHOP = 'SHOP',
}

export type CachedProductDiscountResponse = {
  /** 할인액 */
  discount_amount: Scalars['Int'];
  /** 할인 종료일 */
  end_at: Scalars['CrTimestamp'];
  /** 할인 시작일 */
  start_at: Scalars['CrTimestamp'];
};

export type CachedProductImageResponse = {
  /** 이미지 높이 */
  height?: Maybe<Scalars['Int']>;
  /** 이미지 타입 */
  image_type: ProductImageType;
  /** zigzag CDN으로 제공하는 원본 이미지. 필요하면 url값에 params로 다이나믹 리사이징 속성을 추가. https://www.notion.so/croquis/Dynamic-Image-Resizing-9726f519b5b040d8892ff96dbfd0e1e6 참고 */
  image_url: Scalars['String'];
  /** 이미지 key */
  key: Scalars['String'];
  /** 이미지 너비 */
  width?: Maybe<Scalars['Int']>;
};

export type CachedProductLanguageResponse = {
  /** 상품명 */
  name: CachedProductNameLanguageResponse;
};

export type CachedProductLanguagesResponse = {
  en?: Maybe<CachedProductLanguageResponse>;
  ja?: Maybe<CachedProductLanguageResponse>;
  ko?: Maybe<CachedProductLanguageResponse>;
};

export type CachedProductListInput = {
  id_list: Array<Scalars['ID']>;
};

export type CachedProductListResponse = {
  product_list: Array<CachedProductResponse>;
};

export type CachedProductNameLanguageResponse = {
  /** 상품명 */
  value: Scalars['String'];
};

export type CachedProductResponse = {
  /** 브랜드 ID */
  brand_id?: Maybe<Scalars['ID']>;
  /** 상품의 생성 일자 */
  created_at: Scalars['CrTimestamp'];
  /** 상품 노출 상태 */
  display_status: ProductDisplayStatus;
  /** 필수정보제공고시 code */
  essential_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 다국어 */
  languages: CachedProductLanguagesResponse;
  /** 상품 카테고리 */
  managed_category?: Maybe<ManagedCategoryResponse>;
  /** 대표 상점 ID */
  parent_shop_id?: Maybe<Scalars['Int']>;
  /** 카탈로그의 상품 코드 */
  product_code?: Maybe<Scalars['String']>;
  /** 상품의 이미지 리스트 */
  product_image_list: Array<CachedProductImageResponse>;
  /** 상품 타입 */
  product_type: ProductType;
  /** 상품 판매 상태 */
  sales_status: ProductSalesStatus;
  /** 배송비 타입 */
  shipping_fee_type: ShippingFeeType;
  /** 상점 ID */
  shop_id: Scalars['Int'];
  /** 사이트 컨트리별 데이터 */
  site_country_list: Array<CachedProductSiteCountryResponse>;
  /** 상품의 이미지 리스트 */
  trait_list: Array<CachedProductTraitResponse>;
  /** 상품의 수정 일자 */
  updated_at: Scalars['CrTimestamp'];
};

export type CachedProductSiteCountryResponse = {
  /** country code */
  country: CountryCode;
  /** 가격할인 리스트 */
  discount_list?: Maybe<Array<CachedProductDiscountResponse>>;
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 판매가능여부 */
  sellable: Scalars['Boolean'];
  /** site ID */
  site_id: Scalars['Int'];
  /** Z결제 할인액 */
  zpay_discount_amount: Scalars['Int'];
};

export type CachedProductTraitResponse = {
  auditor: Scalars['String'];
  created_at: Scalars['CrTimestamp'];
  trait_type: ProductTraitType;
};

export type CalculatedMileageResult = {
  /** 예상 적립 마일리지 금액 */
  expected_mileage_amount: Scalars['Int'];
  /** 마일리지 적립 타입 */
  mileage_type: MileageType;
  /** 타이틀 */
  title: Scalars['String'];
};

/** CancelAndRefundOrder의 입력 */
export type CancelAndRefundOrderInput = {
  /** 주문번호 */
  order_number: Scalars['String'];
};

/** cancelNotTransferredOrder의 입력 */
export type CancelNotTransferredOrderInput = {
  /** 주문 번호 */
  order_number: Scalars['String'];
};

/** Carousel 형태로 보여지는 섹션 */
export type Carousel = UiSection & {
  /** Carousel 섹션의 아이템 리스트 */
  item_list: Array<CarouselItem>;
  /** Carousel 의 키워드 타입 */
  keyword_type: CarouselKeywordType;
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

/** Carousel 아이템 데이터 */
export type CarouselItem = {
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 검색 키워드 정보 */
  keyword: Scalars['String'];
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** 제목 */
  title: Scalars['String'];
};

/** Carousel 섹션에서 사용하는 키워드 타입 */
export enum CarouselKeywordType {
  LATEST_POPULAR = 'LATEST_POPULAR',
  LATEST_TRENDY = 'LATEST_TRENDY',
}

/** 피쳐드 쇼핑몰의 각 태그 아이템 */
export type CarouselManagedShops = {
  /** 디테일 설명 */
  description: Scalars['String'];
  /** 피쳐드 쇼핑몰의 각 태그에 속하는 쇼핑몰 목록 */
  shop_list: Array<CarouselManagedShopsItem>;
  /** 제목 */
  title: Scalars['String'];
};

/** 피쳐드 쇼핑몰의 각 태그에 속하는 쇼핑몰 */
export type CarouselManagedShopsItem = {
  /** 이미지 URL */
  image_url: Scalars['String'];
  /** 사용자 로그 */
  log: Scalars['String'];
  /** 주 도메인 */
  main_domain: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

/** 쿠폰 정책별 허들금액 정보 */
export type CartCouponPolicyInfo = {
  /** 달성액 */
  achieved_amount: Scalars['Int'];
  /** 쿠폰 할인 금액 */
  coupon_discount_amount: Scalars['Int'];
  /** 쿠폰 할인율 */
  coupon_discount_rate: Scalars['Int'];
  /** 쿠폰 정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 쿠폰 사용 까지의 잔여액 */
  insufficient_amount: Scalars['Int'];
  /** 쿠폰 정책 정보 */
  policy_info: UserAccountCouponPolicy;
  /** 쿠폰 사용 여부 상태값, USED: 사용 완료, EXPIRED: 기간 만료, ISSUABLE: 발급 가능, AVAILABLE: 사용 가능, UNAVAILABLE: 금액 미달 */
  status: Scalars['String'];
};

/** 쿠폰 정책별 허들금액 정보 목록 */
export type CartCouponPolicyInfoList = {
  item_list: Array<CartCouponPolicyInfo>;
};

/** cart_coupon_policy_info_list의 input */
export type CartCouponPolicyInfoListInput = {
  /** 쿠폰 정책 ID 목록 */
  coupon_policy_id_list: Array<Scalars['ID']>;
  /** 상품 아이템 정보 목록 */
  product_info_list?: InputMaybe<Array<ProductItemInfoInput>>;
  /** 장바구니 캐시사용 여부 */
  use_cart_cache?: InputMaybe<Scalars['Boolean']>;
  /** 상품 아이템 정보 목록으로 직접 넘긴 인자 활용 여부 */
  use_product_info_list?: InputMaybe<Scalars['Boolean']>;
  /** 프라이싱 상품가 및 상태 캐시사용 여부 */
  use_promotion_cache?: InputMaybe<Scalars['Boolean']>;
};

/** 사용자의 장바구니 아이템 */
export type CartItem = {
  /** 상품의 추가 옵션 정보 리스트(연동형 옵션/추가입력 옵션) */
  additional_option_detail_list?: Maybe<Array<ProductAdditionalOption>>;
  /** 조합형 상품을 제외한 상품의 정보(연동형 옵션/추가입력 옵션)로 만든 카트 아이템 해시 문자열 */
  cart_item_hash?: Maybe<Scalars['String']>;
  /** 카탈로그 상품 아이템 정보 */
  catalog_product_item: ProductItem;
  /** 마지막으로 장바구니가 추가된 시각 */
  date_last_added: Scalars['CrTimestamp'];
  /** 퍼널 정보 */
  funnel_info?: Maybe<FunnelInfo>;
  /** 상품 정보 유효 여부 */
  is_valid_product: Scalars['Boolean'];
  /** 상품 최종가격 정보 */
  item_final_price?: Maybe<CartItemFinalPriceInfo>;
  /** zonly 내 원천 쇼핑몰 정보 */
  origin_shop?: Maybe<CartZonlyOriginShop>;
  /** 상품 아이템 정보 */
  product_item: CartProductItem;
  /** 장바구니 아이템의 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 상품 프로모션 정보 */
  promotion_applied_info?: Maybe<CartItemPromotionAppliedInfo>;
  /** 프로모션 그룹 식별자 */
  promotion_item_group_key?: Maybe<Scalars['String']>;
  /** 장바구니 아이템 수량 */
  quantity: Scalars['Int'];
  /** 쇼핑몰 ID */
  shop_id?: Maybe<Scalars['String']>;
};

/** 국가 정보 code(KR:한국, US:미국, JP:일본, CA:캐나다) */
export enum CartItemCountry {
  CA = 'CA',
  JP = 'JP',
  KR = 'KR',
  US = 'US',
}

/** 장바구니 상품 최종가격 정보 */
export type CartItemFinalPriceInfo = {
  /** 적용된 할인 목록 */
  discount_list: Array<ProductDiscountInfo>;
  /** 최대 할인 금액 */
  final_discount_amount: Scalars['Int'];
  /** 최대 할인률 */
  final_discount_rate_bp: Scalars['Int'];
  /** 최대 할인 적용가 */
  final_price: Scalars['Int'];
  /** 아이템 실제 판매가 */
  item_discount_price: Scalars['Int'];
  /** 아이템 프로모션 할인가 (ZPAY 대체, 적용 가능한 기간 할인 가격이 없으면 null) */
  item_promotion_discount_price?: Maybe<Scalars['Int']>;
  /** 아이템 시중 판매가 */
  item_sales_price: Scalars['Int'];
  /** 상품 시중 판매가 */
  product_discount_price: Scalars['Int'];
  /** 상품 시중 판매가 */
  product_original_price: Scalars['Int'];
  /** 프로모션 적용가 (적용 가능한 프로모션이 없으면 null) */
  promotion_price?: Maybe<Scalars['Int']>;
};

/** CartItem 식별자(상품 아이템 레코드 ID, 추가옵션 해시) 입력값 */
export type CartItemIdentifierInput = {
  /** 조합형 상품을 제외한 상품의 정보(연동형 옵션/추가입력 옵션)로 만든 카트 아이템 해시 문자열 */
  cart_item_hash?: InputMaybe<Scalars['String']>;
  /** 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 프로모션 아이템 그룹 식별자 */
  promotion_item_group_key?: InputMaybe<Scalars['String']>;
};

/** 장바구니 아이템의 프로모션 정보 */
export type CartItemPromotionAppliedInfo = {
  /** BOGO 프로모션 적용 수량 (1+N 에서 N값을 담고있다) */
  applied_quantity?: Maybe<Scalars['Int']>;
  /** 프로모션 쿠폰 적용 가능 여부 */
  is_coupon_usable: Scalars['Boolean'];
  /** 최소 주문 금액 (첫구매 시 필요) */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 프로모션 할인 적용 가격 (단일 가격 * quantity) */
  promotion_applied_discount_price: Scalars['Int'];
  /** 프로모션 할인 적용 단일 가격 */
  promotion_applied_item_discount_price: Scalars['Int'];
  /** 프로모션 아이디 */
  promotion_id: Scalars['ID'];
  /** 프로모션 상품 ID */
  promotion_product_id?: Maybe<Scalars['ID']>;
  /** 프로모션 아이템 ID */
  promotion_product_item_id?: Maybe<Scalars['ID']>;
  /**
   * 프로모션 타입
   * - BOGO / FIRST_ORDER / RAFFLE
   */
  promotion_type: PromotionType;
};

export type CartProduct = {
  /** 출고지, 반품지 ID 정보 */
  address?: Maybe<ProductAddressResponse>;
  /** 브라우저 타입 */
  browsing_type: BrowsingType;
  /** 묶음배송 타입 */
  bundle_type: ProductShippingBundleType;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 카탈로그 상품 타입 */
  catalog_product_type?: Maybe<CatalogProductType>;
  /** 카테고리 */
  category?: Maybe<CatalogProductCategory>;
  /** 발송기한 */
  due_date_in_transit: Scalars['CrTimestamp'];
  /** 빠른 배송 타입 */
  fast_delivery_type?: Maybe<FastDeliveryType>;
  /** 상품 ID */
  id: Scalars['ID'];
  /** 상품 대표 이미지 정보 */
  image: CatalogProductImage;
  /** 빠른 배송 여부 */
  is_fast_delivery: Scalars['Boolean'];
  /** z결제 할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 최대 주문 수량 - 0이면 제한 없음 */
  maximum_quantity: Scalars['Int'];
  /** 최소 주문 수량 */
  minimum_quantity: Scalars['Int'];
  /** 최소구매수량 타입 */
  minimum_quantity_type: MinimumOrderQuantityType;
  /** 상품 이름 */
  name: Scalars['String'];
  /** 상품의 옵션 목록 */
  option_list: Array<CartProductOption>;
  option_type: ProductOptionType;
  /** pdp 랜딩 url */
  pdp_landing_url?: Maybe<Scalars['String']>;
  /** 프리오더 관련 정보 */
  preorder?: Maybe<CartProductPreOrder>;
  /** 상품 가격 */
  price: Scalars['Int'];
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no: Scalars['String'];
  /** 상품별 배송비 정보 */
  shipping_fee?: Maybe<ProductShippingFee>;
  /** 배송비 종류 */
  shipping_fee_type?: Maybe<ProductShippingFeeType>;
  /** 상점 ID */
  shop_id: Scalars['ID'];
  text_options: Array<TextOption>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 상품 특성 정보 */
  trait_list: Array<Scalars['String']>;
  /** 상품의 source url */
  url?: Maybe<Scalars['String']>;
  /** Zpay 상태 */
  zpay_status: ZpayStatus;
};

/** 상품 아이템 */
export type CartProductItem = {
  /** 삭제 시간 */
  date_deleted?: Maybe<Scalars['CrTimestamp']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 품목명 */
  name: Scalars['String'];
  /** 옵션 목록 */
  option_list: Array<CartProductItemOption>;
  /**
   * 옵션 타입
   * BASIC
   * ADDITIONAL
   */
  option_type: ProductItemOptionType;
  /**
   * 아이템 가격
   * product_id에 해당하는 상품의 가격(할인가가 있을시 할인가, 없을 시 정상가) + 추가/차감 금액
   */
  price: Scalars['Int'];
  /**
   * 아이템의 추가/차감 금액
   * - e.g. 3000, -5000
   */
  price_delta: Scalars['Int'];
  /** 상품 */
  product: CartProduct;
  /**
   * 옵션의 상태
   * NORMAL
   * SOLD_OUT
   */
  status: ProductItemStatus;
};

/** 상품 아이템 옵션 */
export type CartProductItemOption = {
  /** 옵션값 */
  value: Scalars['String'];
};

/** 상품 옵션 정보 */
export type CartProductOption = {
  /** 연동형 상품 주문에 쓰이는 option key */
  code: Scalars['String'];
  /** 필수로 선택해야 하는 옵션인지 */
  is_required: Scalars['Boolean'];
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션 값 목록 */
  value_set: Array<LegacyProductOptionValue>;
};

export type CartProductPreOrder = {
  /** 프리오더 종료일 */
  date_end: Scalars['String'];
  /** 프리오더 시작일 */
  date_start: Scalars['String'];
  /** 프리오더 예약발송일 */
  reserved_date_in_transit: Scalars['String'];
};

/** 사용자의 장바구니 쇼핑몰별 모음 */
export type CartShippingGroup = {
  /** 장바구니 모음 */
  cart_item_list: Array<CartItem>;
  /** 장바구니 배송비 그룹 정보 */
  shipping_info: CartShippingGroupShippingInfo;
};

/** 장바구니 배송비 그룹 배송비 정책 */
export type CartShippingGroupShippingInfo = {
  /** 배송유형 */
  shipping_type: ShippingType;
};

/** 사용자의 장바구니 쇼핑몰별 모음 */
export type CartShop = {
  /** 장바구니 모음 */
  cart_item_list: Array<CartItem>;
  /** zonly 여부 */
  is_zonly: Scalars['Boolean'];
  /** 장바구니 모음 */
  shipping_group_list: Array<CartShippingGroup>;
  /** 쇼핑몰 정보 */
  shop: CartShopInfo;
};

/** 장바구니 쇼핑몰 정보 */
export type CartShopInfo = {
  /** 고객센터 번호 */
  customer_center_tel: Scalars['String'];
  /** 쇼핑몰 ID */
  id: Scalars['ID'];
  /** 메인 도메인 */
  main_domain: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 쇼핑몰 유형 */
  type: ShopType;
  /** 쇼핑몰 대표이미지 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 쇼핑몰 URL */
  url: Scalars['String'];
  /** Z결제 상태 */
  zpay_status?: Maybe<ShopZpayStatus>;
};

/** 사용자의 장바구니 쇼핑몰별 모음 목록 */
export type CartShopList = {
  item_list: Array<CartShop>;
};

/** 사용자의 장바구니 쇼핑몰별 모음 목록 (직진배송 cart_shop 독립적으로 분리) */
export type CartShopListV2 = {
  item_list: Array<CartShop>;
};

export type CartTooltip = {
  /** 주문 마감 시간(tooltip_message 메시지 내 치환 목적으로 사용) */
  order_cutoff_datetime?: Maybe<Scalars['CrTimestamp']>;
  /** 툴팁 메시지 */
  tooltip_message: Scalars['String'];
};

/** 직진배송 원천 쇼핑몰 정보 */
export type CartZonlyOriginShop = {
  /** 쇼핑몰 ID */
  id: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

/** 상품 문의 답변 */
export type CatalogAnswer = {
  /** 답변자/수정자 */
  auditor: Scalars['String'];
  /** 상품 문의 응답 내용 */
  contents: Scalars['String'];
  date_created: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 상품 문의 ID */
  question_id: Scalars['ID'];
};

export type CatalogCategory = {
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 카테고리 positon 정보 */
  position: CatalogCategoryPositionType;
};

export enum CatalogCategoryPositionType {
  /** child를 갖고 있는 중간 NODE */
  INTER_NODE = 'INTER_NODE',
  /** child가 없는 최하위 NODE */
  LEAF_NODE = 'LEAF_NODE',
  /** 최상위 ROOT NODE */
  ROOT_NODE = 'ROOT_NODE',
}

export type CatalogCustomInputOption = {
  id?: Maybe<Scalars['ID']>;
  /** 값 입력 제한 */
  max_length?: Maybe<Scalars['Int']>;
  /** 상품 옵션명 */
  name: Scalars['String'];
  /** 필수 여부 */
  required: Scalars['Boolean'];
};

export enum CatalogDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export enum CatalogEntryType {
  CRAWLING_API = 'CRAWLING_API',
  DIRECT = 'DIRECT',
  SCRAPPING = 'SCRAPPING',
}

export type CatalogHierarchyCategory = {
  /** 자식 카테고리 목록 */
  children?: Maybe<CatalogHierarchyCategory>;
  /** id */
  id: Scalars['ID'];
  /** 카테고리 KEY */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
};

export type CatalogItem = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 품목 노출 상태 */
  display_status: CatalogDisplayStatus;
  id: Scalars['ID'];
  /** 품목의 부가 속성 리스트 */
  item_attribute_list: Array<CatalogItemAttribute>;
  /** 품목 코드 */
  item_code?: Maybe<Scalars['String']>;
  /** 품목의 재고 */
  item_inventory?: Maybe<CatalogItemInventory>;
  /** 품목명 */
  name: Scalars['String'];
  /** 품목 값 */
  price: Scalars['Int'];
  /** 품목 판매 상태 */
  sales_status: CatalogSalesStatus;
};

export type CatalogItemAttribute = {
  /** 속성 명 */
  name: Scalars['String'];
  /** 속성 번호 */
  product_item_attribute_id: Scalars['ID'];
  /** 속성 값 번호 */
  product_item_attribute_value_id: Scalars['ID'];
  /** 속성 값 */
  value: Scalars['String'];
};

export type CatalogItemInventory = {
  /** 판매된 재고 누적량 */
  assigned_quantity: Scalars['Int'];
  deleted: Scalars['Boolean'];
  /** 총 재고 */
  total_quantity: Scalars['Int'];
};

export enum CatalogPaymentType {
  /** 외부 결제 */
  EXTERNAL = 'EXTERNAL',
  /** Z-PAY */
  ZPAY = 'ZPAY',
}

export type CatalogProduct = {
  /** 상품 수정자 */
  auditor?: Maybe<Scalars['String']>;
  /** 카테고리 리스트 */
  category_list?: Maybe<Array<CatalogProductCategory>>;
  /** 상품의 생성 일자 */
  created_at?: Maybe<Scalars['CrTimestamp']>;
  /** 사용자 입력 옵션 리스트 */
  custom_input_option_list: Array<CatalogCustomInputOption>;
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 상품 노출 상태 */
  display_status: CatalogDisplayStatus;
  /** 상품정보제공 고시 */
  essentials: Scalars['String'];
  /** 배송 예측일 정보 */
  estimated_shipping_date?: Maybe<EstimatedShippingDate>;
  external_product_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 상품의 품목 리스트 */
  item_list: Array<CatalogItem>;
  /** 상품명 */
  name: Scalars['String'];
  /** 결제 타입 */
  payment_type: CatalogPaymentType;
  /** 상품 준비 기간 */
  preparation_period?: Maybe<Scalars['Int']>;
  /** 상품 부가 속성 리스트 */
  product_attribute_list: Array<CatalogProductAttribute>;
  /** 굿즈 id */
  product_id_mapping?: Maybe<CatalogProductIdMapping>;
  /** 상품의 이미지 리스트 */
  product_image_list: Array<CatalogProductImage>;
  /** 상품 품목 부가 속성 리스트 */
  product_item_attribute_list: Array<CatalogProductItemAttribute>;
  /** 상품의 가격 정보 */
  product_price: CatalogProductPrice;
  /** 상품 타입 */
  product_type: CatalogProductType;
  /** 최신 상품을 판단하기위한 실제 날짜 */
  renewed_at?: Maybe<Scalars['CrTimestamp']>;
  /** 상품 판매 상태 */
  sales_status: CatalogSalesStatus;
  /** 배송비 타입 */
  shipping_fee_type: CatalogShippingFeeType;
  /** 상점 ID */
  shop_id: Scalars['Int'];
  /** 상점의 도메인 */
  shop_main_domain: Scalars['String'];
  /** 상품의 수정 일자 */
  updated_at?: Maybe<Scalars['CrTimestamp']>;
};

export type CatalogProductAttribute = {
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 속성 값 */
  value_list: Array<Scalars['String']>;
};

export type CatalogProductCategory = {
  /** 카테고리 full name */
  category_full_name: Scalars['String'];
  /** category DB의 category id */
  category_id: Scalars['ID'];
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /**
   * 카테고리 계층
   * @deprecated hierarchy_list 사용
   */
  hierarchy?: Maybe<CatalogHierarchyCategory>;
  /** 카테고리 계층 - ROOT_NODE, INTER_NODE, LEAF_NODE 순서 */
  hierarchy_list: Array<CatalogCategory>;
  id: Scalars['ID'];
};

export type CatalogProductDetailPage = {
  /** 상품 피팅 모델 정보 */
  fitting_model_list?: Maybe<UxProductFittingModelList>;
  /** 추천탭 라벨 */
  recommendation_tab_label: Scalars['String'];
  /**
   * 연관상품 추천 컴포넌트.
   * UxTextTitle + UxGoodsCarousel + UxTextTitle + UxGoodsCarousel + ... 형식으로 구성
   * 추후에 뷰가 어떻게 바뀔지 알 수 없기에 UxComponent 리스트로 처리.
   */
  related_item_list: Array<UxComponent>;
  /** 사이즈 추천 정보 */
  size_recommendation?: Maybe<UxProductSize>;
  /** 브랜드 홈 정보 */
  ux_brand_home?: Maybe<UxShop>;
  /** 입점형 내재화 상품정보 */
  ux_catalog_product: UxCatalogProduct;
  /** 공지 정보 */
  ux_notice?: Maybe<UxPdpNotice>;
  /** 쇼핑몰 정보 */
  ux_shop: UxShop;
};

export type CatalogProductIdMapping = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** Goods ID */
  goods_id?: Maybe<Scalars['Int']>;
  /** 기존 Product ID */
  legacy_product_id?: Maybe<Scalars['Int']>;
  /** Zonly item ID */
  zonly_item_id?: Maybe<Scalars['Int']>;
};

export type CatalogProductImage = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 이미지 타입 */
  image_type: CatalogProductImageType;
  /** zigzag CDN으로 제공하는 원본 이미지. 필요하면 url값에 params로 다이나믹 리사이징 속성을 추가. https://www.notion.so/croquis/Dynamic-Image-Resizing-9726f519b5b040d8892ff96dbfd0e1e6 참고 */
  image_url: Scalars['String'];
  /** 이미지 URL(도메인 포함) */
  origin_url: Scalars['String'];
  /** 이미지 경로 */
  path?: Maybe<Scalars['String']>;
  /** 썸네일 경로 */
  thumbnail_path?: Maybe<Scalars['String']>;
  /** 이미지 URL(도메인 제외) */
  url?: Maybe<Scalars['String']>;
};

export enum CatalogProductImageType {
  CONTENT = 'CONTENT',
  MAIN = 'MAIN',
  SUB = 'SUB',
}

export type CatalogProductItemAttribute = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 순서 */
  order: Scalars['Int'];
  /** 속성 값 */
  value_list: Array<CatalogProductItemAttributeValue>;
};

export type CatalogProductItemAttributeValue = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소 */
  image_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
};

export type CatalogProductList = {
  product_list: Array<CatalogProduct>;
  total_count: Scalars['Int'];
};

export type CatalogProductListInput = {
  id_list: Array<Scalars['ID']>;
};

export type CatalogProductPrice = {
  /** 삭제여부 */
  deleted: Scalars['Boolean'];
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 정상가 */
  original_price: Scalars['Int'];
};

export enum CatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

/** 상품 문의 */
export type CatalogQuestion = {
  /** 상품 문의 답변 */
  answer?: Maybe<CatalogAnswer>;
  /** 상품 문의 답변 리스트 */
  answer_list: Array<CatalogAnswer>;
  /** 문의 응답 일자 */
  answered_date_at?: Maybe<Scalars['CrTimestamp']>;
  catalog_product: CatalogProduct;
  /** 상품 문의 내용 */
  contents: Scalars['String'];
  /** 생성 일자 */
  date_created: Scalars['CrTimestamp'];
  /** 삭제 시간 */
  date_deleted?: Maybe<Scalars['CrTimestamp']>;
  /** 수정 일자 */
  date_updated: Scalars['CrTimestamp'];
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 노출 상태 */
  display_status: CatalogQuestionDisplayStatus;
  id: Scalars['ID'];
  /** 카탈로그 상품 ID */
  product_id: Scalars['ID'];
  /** 문의 상품 유형 */
  product_type: CatalogQuestionProductType;
  /** 문의 상태 */
  question_status: CatalogQuestionStatus;
  /** 문의 유형 */
  question_type: CatalogQuestionType;
  shop: Shop;
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  user_account: UserAccount;
  /** 유저 Account ID */
  user_account_id: Scalars['ID'];
};

/** 노출 상태 */
export enum CatalogQuestionDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type CatalogQuestionInput = {
  /** 상품 문의 내용 */
  contents: Scalars['String'];
  /** 카탈로그 상품 ID */
  product_id: Scalars['ID'];
  /** 문의 상품 유형 */
  product_type: CatalogQuestionProductType;
  /** 문의 유형 */
  question_type: CatalogQuestionType;
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
};

export type CatalogQuestionList = {
  item_list: Array<CatalogQuestion>;
  total_count: Scalars['Int'];
};

export type CatalogQuestionListInput = {
  date_created_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_created_lte?: InputMaybe<Scalars['CrTimestamp']>;
  /** 삭제 여부 */
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 문의 상품 유형 */
  product_type?: InputMaybe<CatalogQuestionProductType>;
  /** 문의 상태 */
  question_status?: InputMaybe<CatalogQuestionStatus>;
  /** 문의 유형 */
  question_type?: InputMaybe<CatalogQuestionType>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

/** 문의 상품 유형 */
export enum CatalogQuestionProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-only */
  ZONLY = 'ZONLY',
}

/** 문의 상태 */
export enum CatalogQuestionStatus {
  /** 응답완료 */
  ANSWERED = 'ANSWERED',
  /** 처리중 */
  IN_PROGRESS = 'IN_PROGRESS',
  /** 미응답 */
  UNANSWERED = 'UNANSWERED',
}

/** 문의 유형 */
export enum CatalogQuestionType {
  /** 기타 문의 */
  OTHER = 'OTHER',
  /** 상품 문의 */
  PRODUCT = 'PRODUCT',
  /** 배송 문의 */
  SHIPPING = 'SHIPPING',
  /** 사이즈 문의 */
  SIZE = 'SIZE',
  /** 입고/재고 문의 */
  STOCK = 'STOCK',
}

export enum CatalogSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export enum CatalogShippingFeeType {
  /** 무료 배송 */
  FREE_SHIPPING = 'FREE_SHIPPING',
  /** SHOP의 배송정책을 따름 */
  SHOP_POLICY = 'SHOP_POLICY',
}

export enum CatalogTaxType {
  /** 면세 */
  FREE = 'FREE',
  /** 과세 */
  TAX = 'TAX',
}

export type Category = {
  doc_count: Scalars['Int'];
  key: Scalars['String'];
};

/** 카테고리 asset */
export type CategoryAsset = {
  /** 수정자 */
  auditor: Scalars['String'];
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** values */
  values: Scalars['CrJson'];
};

export type CategoryHierarchy = {
  /** 자식 카테고리 목록 */
  child?: Maybe<CategoryHierarchy>;
  /** id */
  id: Scalars['ID'];
  /** 카테고리 KEY */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
};

export type CategoryLandingRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** CLP 링크 url */
  clp_link_url: Scalars['String'];
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export enum CategoryPositionType {
  /** child를 갖고 있는 중간 NODE */
  INTER_NODE = 'INTER_NODE',
  /** child가 없는 최하위 NODE */
  LEAF_NODE = 'LEAF_NODE',
  /** 최상위 ROOT NODE */
  ROOT_NODE = 'ROOT_NODE',
}

export type CategoryRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /**
   * 노출 카테고리 아이디 목록
   * ex) ["1321", "1333", "1334"], ["1322", "1355"]
   */
  display_category_id_list: Array<Scalars['ID']>;
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export enum CategoryThemeType {
  BEST_RANKING = 'BEST_RANKING',
  BEST_SAVED_PRODUCT = 'BEST_SAVED_PRODUCT',
  FAST_DELIVERY_RANKING = 'FAST_DELIVERY_RANKING',
  INCREASE_IN_VIEW = 'INCREASE_IN_VIEW',
  NEW_RANKING = 'NEW_RANKING',
}

/** 버저닝 된 관리 카테고리 정보 조회 input */
export type CategoryVersionedManagedCategoryInput = {
  /** key */
  key: Scalars['String'];
};

/** ChangeSimplePayPassword 입력 */
export type ChangeSimplePayPasswordInput = {
  /** 새 패스워드 */
  new_password: Scalars['String'];
  /** 패스워드 */
  password: Scalars['String'];
};

/** daily mission lucky ball 당첨 결과 */
export type CheckAndRewardDailyMissionLuckyBallWinningResult = {
  /** 보상 */
  daily_mission_reward?: Maybe<DailyMissionLuckyBallReward>;
  /** 당첨일자 */
  date_ymd?: Maybe<Scalars['Int']>;
  /** 당첨 번호 목록(내림차순) */
  draw_number_list: Array<Scalars['Int']>;
  /** 유저가 선택한 당첨 번호(내림차순) */
  entry_number_list: Array<Scalars['Int']>;
  /** 당첨 여부 */
  is_winner: Scalars['Boolean'];
  /** 당첨자 수 */
  winner_count: Scalars['Int'];
};

export type CheckBadgeInput = {
  badge_id: Scalars['ID'];
};

/**
 * 사용자의 기획전 쿠폰 보유 여부 확인 결과
 * - 쿠폰팩을 보유하고 있을 경우 uid_list에 해당 쿠폰팩의 UID가 출력 된다.
 * - 쿠폰을 보유하고 있을 경우 policy_id_list에 해당 쿠폰 정책 ID가 출력 된다.
 */
export type CheckIssueExhibitionCouponsResult = {
  /** 사용자가 보유하고 있는 쿠폰 정책 ID 목록 결과 */
  policy_id_list: Array<Scalars['ID']>;
  /** 사용자가 보유하고 있는 쿠폰팩 UID 목록 결과 */
  uid_list: Array<Scalars['String']>;
};

export type CheckIssueRedeemCouponInput = {
  /** 쿠폰 code */
  code: Scalars['String'];
  /** 쿠폰 적용할 상품 정보 */
  product_info_list: Array<IssueCouponProductInfo>;
};

/** checkKakaoStudentPresentProofSubmission 응답값 */
export type CheckKakaoStudentPresentProofSubmissionResult = {
  /** 성공이 아닌경우 메시지 */
  message?: Maybe<Scalars['String']>;
  /**
   * 제출 동선 상태
   * REQUESTED -> 요청
   * ABANDONED -> 실패
   * PRESENTED -> 성공
   * PENDING -> 처리중
   */
  state: Scalars['String'];
};

/** check_kakaobank_authentication의 응답값 */
export type CheckKakaobankAuthenticationResult = {
  /** 본인인증 ID */
  authenticate_id?: Maybe<Scalars['String']>;
  /** 본인인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key?: Maybe<Scalars['String']>;
  /** 인증한 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 인증한 휴대폰 번호 */
  mobile_tel?: Maybe<Scalars['String']>;
  /** 본인인증 결과 */
  result: Scalars['Boolean'];
};

/** CheckSimpleBankCert의 입력 */
export type CheckSimpleBankCertInput = {
  /** 계좌 정보 인증 ID */
  simple_bank_cert_id: Scalars['ID'];
};

export type CheckUpdateInUserAccountMetadataInput = {
  category: UserAccountMetadataCategory;
};

export type CheckUserProfileNicknameValidityInput = {
  /** 사용자 프로필 닉네임 */
  nickname: Scalars['String'];
};

/** OrderGragh 내 Checkout 정보 */
export type CheckoutInOrder = {
  /** 체크아웃 당일배송 관련 정보 */
  same_day_shipping_info?: Maybe<CheckoutSameDayShippingInfo>;
  /** 체크아웃 당일배송 관련 정보 */
  wms_shipping_info?: Maybe<CheckoutWmsShippingInfo>;
};

/** 주문할 상품의 정보 */
export type CheckoutOrderItemInput = {
  /** 상품 아이템의 추가 옵션 정보(연동형 옵션/추가입력 옵션) 입력 리스트 */
  additional_option_detail_list?: InputMaybe<Array<ProductAdditionalOptionInput>>;
  /** 카트 아이템 해시 문자열(카트에서 생성되었을 경우 전송) */
  cart_item_hash?: InputMaybe<Scalars['String']>;
  /** 퍼널 정보 */
  funnel_info?: InputMaybe<FunnelInfoInput>;
  /** 가격 */
  price: Scalars['Int'];
  /** 주문할 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 상품에 적용된 프로모션 정보 */
  promotion_applied_info?: InputMaybe<PromotionAppliedInfoInput>;
  /** 수량 */
  quantity: Scalars['Int'];
};

/** 주문자 정보 입력 */
export type CheckoutOrdererInput = {
  /** 이메일 */
  email: Scalars['String'];
  /** 휴대폰 번호 */
  mobile_tel: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

/** 쇼핑몰상품쿠폰 사용한 아이템 입력 */
export type CheckoutProductItemCouponListInput = {
  /** 주문한 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 유저 쿠폰 레코드 ID */
  user_account_coupon_id: Scalars['ID'];
};

/** 체크아웃시 생성되는 당일 배송비 정보 */
export type CheckoutSameDayShippingInfo = {
  /** 배송 타입 */
  delivery_type: OrderDeliveryType;
  /** 배송보장일시 */
  oneday_due_date: Scalars['CrTimestamp'];
};

/** 배송비쿠폰 사용한 아이템 입력 */
export type CheckoutShippingFeeCouponListInput = {
  /** 배송비 쿠폰 적용 아이템 레코드 ID */
  product_item_id_list: Array<Scalars['ID']>;
  /** 유저 쿠폰 레코드 ID */
  user_account_coupon_id: Scalars['ID'];
};

/** 체크아웃시 생성되는 물류 정보 */
export type CheckoutWmsShippingInfo = {
  /** 배송 타입 */
  delivery_type: OrderDeliveryType;
  /** 배송보장일시 */
  shipping_due_date: Scalars['CrTimestamp'];
};

export enum ChipButtonType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type ClaimImageUploadLinkInfo = {
  /** key */
  key: Scalars['String'];
  /** presigned url */
  presigned_url: Scalars['String'];
};

/** 상품주문 단위 클레임 보류 */
export type ClaimItemDefer = {
  /** 보류 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 보류 사유 */
  deferred_reason?: Maybe<Scalars['String']>;
};

export type ClpTopComponentList = {
  category_list: UxClpCategoryList;
  ui_item_list: Array<UxComponent>;
};

export type CmsBanner = {
  /** 활성화 */
  activated: Scalars['Boolean'];
  /** 배너 노출 날짜 */
  banner_imp_list: Array<CmsBannerImpOrderDate>;
  /** 컨텐츠 URL */
  content_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 URL */
  img_url?: Maybe<Scalars['String']>;
  /** landing URL (브라우저 타입 + 컨텐츠 URL) */
  landing_url?: Maybe<Scalars['String']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
};

export type CmsBannerImpOrderDate = {
  /** 배너노출순서 */
  banner_order?: Maybe<Scalars['Int']>;
  /** 노출 종료날짜 */
  date_ended: Scalars['CrTimestamp'];
  /** 노출 시작날짜 */
  date_started: Scalars['CrTimestamp'];
};

export enum CmsBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type CmsCampaignCatalogRankingDataApiInfoInput = {
  campaign_id?: InputMaybe<Scalars['String']>;
  campaign_tag_id_list?: InputMaybe<Array<Scalars['String']>>;
  display_category_id?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  shop_department?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['String']>;
  window?: InputMaybe<Scalars['String']>;
};

export type CmsCampaignCatalogRankingInfo = {
  catalog_id: Scalars['ID'];
  ranking_changed?: Maybe<Scalars['Int']>;
};

export type CmsCampaignCatalogRankingInfoList = {
  item_list: Array<CmsCampaignCatalogRankingInfo>;
  updated_at?: Maybe<Scalars['CrTimestamp']>;
};

export type CmsCampaignStoreRankingAndCatalogInfo = {
  catalog_id_list: Array<Scalars['ID']>;
  ranking_changed?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
};

export type CmsCampaignStoreRankingDataApiInfoInput = {
  age_groups?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  department_id?: InputMaybe<Scalars['ID']>;
  is_new?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['String']>;
  styles?: InputMaybe<Array<Scalars['String']>>;
};

export type CmsCampaignStoreRankingIdList = {
  item_list: Array<CmsCampaignStoreRankingAndCatalogInfo>;
  updated_at?: Maybe<Scalars['CrTimestamp']>;
};

export enum CmsCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

export type CmsContentEditorShareInfo = {
  button_title?: Maybe<Scalars['String']>;
  content_short_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  image_src?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum CmsCountry {
  CA = 'CA',
  JP = 'JP',
  KR = 'KR',
  US = 'US',
}

export enum CmsCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type CmsDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  issue_end_date?: Maybe<Scalars['CrTimestamp']>;
  title?: Maybe<Scalars['String']>;
};

export type CmsDisplayBadge = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_size?: Maybe<CmsImageSize>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_size?: Maybe<CmsImageSize>;
  small_image_url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum CmsExtraNoticeType {
  CATALOG_ALL = 'CATALOG_ALL',
  CATALOG_NORMAL = 'CATALOG_NORMAL',
  CATALOG_ZIGZIN = 'CATALOG_ZIGZIN',
  ZIGZIN = 'ZIGZIN',
}

export type CmsImageSize = {
  /** 이미지 높이 크기 */
  height: Scalars['Int'];
  /** 이미지 너비 크기 */
  width: Scalars['Int'];
};

export enum CmsLanguage {
  EN = 'en',
  JA = 'ja',
  KO = 'ko',
}

export enum CmsPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type CmsPriceWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: CmsPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

export type CmsProductCardItemListInput = {
  /**
   * 키로 활용될 필드
   *
   * 조회의 영향이 가진 않으니, 기준이 될 키를 넘겨주면 됩니다.
   * ex) 콘텐츠 에디터 uuid, 모듈 uuid 등
   */
  key: Scalars['String'];
  /** 상품 ID 목록 */
  product_id_list: Array<Scalars['String']>;
  /**
   * 세션 데이터 옵션
   * - 기획전에서 해당 언어 / 통화로 적용되어야 하는 경우 사용합니다.
   */
  session_data_options?: InputMaybe<CmsProductsPresetSessionOptions>;
};

export type CmsProductPresetCategoryWithNameInput = {
  /** 1 뎁스 카테고리 이름 */
  depth1: Scalars['String'];
  /** 2 뎁스 카테고리 이름 */
  depth2?: InputMaybe<Scalars['String']>;
  /** 3 뎁스 카테고리 이름 */
  depth3?: InputMaybe<Scalars['String']>;
};

export type CmsProductPresetItemCountInfo = {
  group_index?: Maybe<Scalars['String']>;
  total_count: Scalars['Int'];
  uuid: Scalars['String'];
};

export enum CmsProductPresetProductOrderType {
  /** 최초 생성일 오름차순 */
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  /** 최초 생성일 내림차순 */
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  /** 상품 아이디 오름차순 */
  ID_ASC = 'ID_ASC',
  /** 아이디 내림차순 */
  ID_DESC = 'ID_DESC',
  /** 신상품 정렬 */
  LATEST = 'LATEST',
  /** 관리 카테고리명 오름차순 */
  MANAGED_CATEGORY_NAME_ASC = 'MANAGED_CATEGORY_NAME_ASC',
  /** 관리 카테고리명 내림차순 */
  MANAGED_CATEGORY_NAME_DESC = 'MANAGED_CATEGORY_NAME_DESC',
  /** 상품명 오름차순 */
  NAME_ASC = 'NAME_ASC',
  /** 상품명 내림차순 */
  NAME_DESC = 'NAME_DESC',
  /** 저가순 정렬 */
  PRICE_ASC = 'PRICE_ASC',
  /** 고가순 정렬 */
  PRICE_DESC = 'PRICE_DESC',
  /** 리뷰 많은순 */
  REVIEW_COUNT_DESC = 'REVIEW_COUNT_DESC',
  /** 인기순 정렬 */
  SCORE_DESC = 'SCORE_DESC',
  /** Z결제 할인율 오름차순 */
  ZPAY_DISCOUNT_RATE_ASC = 'ZPAY_DISCOUNT_RATE_ASC',
  /** Z결제 할인율 내림차순 */
  ZPAY_DISCOUNT_RATE_DESC = 'ZPAY_DISCOUNT_RATE_DESC',
  /** 원가에 대한 Z결제 할인율 오름차순 */
  ZPAY_PRICE_OVER_ORIGINAL_PRICE_ASC = 'ZPAY_PRICE_OVER_ORIGINAL_PRICE_ASC',
  /** 원가에 대한 Z결제 할인율 내림차순 */
  ZPAY_PRICE_OVER_ORIGINAL_PRICE_DESC = 'ZPAY_PRICE_OVER_ORIGINAL_PRICE_DESC',
}

export enum CmsProductsPresetExposureOrderType {
  /** 선택 불가능 */
  NOT_SELECTABLE = 'NOT_SELECTABLE',
  /** 선택 가능 */
  SELECTABLE = 'SELECTABLE',
}

export type CmsProductsPresetFilterOption = {
  managed_category_name_list?: InputMaybe<Array<CmsProductPresetCategoryWithNameInput>>;
  only_brand?: InputMaybe<Scalars['Boolean']>;
  only_free_shipping?: InputMaybe<Scalars['Boolean']>;
  only_zigzin?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<CmsProductPresetProductOrderType>;
  shop_id_list?: InputMaybe<Array<Scalars['String']>>;
  zpay_discount_rate_gte?: InputMaybe<Scalars['Int']>;
  zpay_discount_rate_lte?: InputMaybe<Scalars['Int']>;
  zpay_price_gte?: InputMaybe<Scalars['Int']>;
  zpay_price_lte?: InputMaybe<Scalars['Int']>;
};

export enum CmsProductsPresetFixedListOrderType {
  /** 할인율 높은 순 */
  DISCOUNT_RATE_DESC = 'DISCOUNT_RATE_DESC',
  /** 신상품 순 */
  LATEST = 'LATEST',
  /** MD 추천 순 */
  MD_RECOMMEND = 'MD_RECOMMEND',
  /** 낮은 가격 순 */
  PRICE_ASC = 'PRICE_ASC',
  /** 높은 가격 순 */
  PRICE_DESC = 'PRICE_DESC',
  /** 리뷰 많은 순 */
  REVIEW_COUNT_DESC = 'REVIEW_COUNT_DESC',
  /** 인기도 순 */
  SCORE_DESC = 'SCORE_DESC',
  /** 개인화 추천 순 */
  USER_RECOMMEND = 'USER_RECOMMEND',
}

export type CmsProductsPresetGroup = {
  /** @deprecated ProductsPresetGroup.item_list 는 사용하지 않습니다. */
  item_list: Array<CmsProductsPresetProductCardItem>;
  /** 그룹 이름 */
  product_group_name: Scalars['String'];
  total_count: Scalars['Int'];
};

export type CmsProductsPresetProduct = {
  aid?: Maybe<Scalars['String']>;
  badge_list?: Maybe<Array<CmsDisplayBadge>>;
  bookmarked: Scalars['Boolean'];
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<CmsDisplayBadge>>;
  bridge?: Maybe<Scalars['Boolean']>;
  browsing_type: CmsBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  catalog_product_id?: Maybe<Scalars['ID']>;
  catalog_product_type?: Maybe<CmsCatalogProductType>;
  column_count?: Maybe<Scalars['Int']>;
  discount_info?: Maybe<CmsDiscountInfo>;
  discount_rate?: Maybe<Scalars['Int']>;
  final_price: Scalars['Int'];
  final_price_with_currency: CmsPriceWithCurrency;
  free_shipping?: Maybe<Scalars['Boolean']>;
  goods_id?: Maybe<Scalars['String']>;
  has_coupon: Scalars['Boolean'];
  image_ratio?: Maybe<Scalars['Float']>;
  image_url?: Maybe<Scalars['String']>;
  is_brand: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  max_price: Scalars['Int'];
  max_price_with_currency: CmsPriceWithCurrency;
  /** 가로형 상품카드 메타데이터 영역용 엠블렘 */
  metadata_emblem_badge_list?: Maybe<Array<CmsDisplayBadge>>;
  origin_id?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  product_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  review_count?: Maybe<Scalars['Int']>;
  review_score?: Maybe<Scalars['Float']>;
  sales_status?: Maybe<CmsProductsPresetSearchedProductSalesStatus>;
  sellable_status: CmsSellableStatus;
  shop_id: Scalars['ID'];
  shop_product_no?: Maybe<Scalars['String']>;
  status?: Maybe<CmsUxGoodsStatus>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<CmsDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<CmsDisplayBadge>>;
  title?: Maybe<Scalars['String']>;
  type: CmsUxComponentType;
  uuid?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  webp_image_url?: Maybe<Scalars['String']>;
  zpay?: Maybe<Scalars['Boolean']>;
  zpay_price?: Maybe<Scalars['Int']>;
};

export type CmsProductsPresetProductCardItem = {
  product: CmsProductsPresetProduct;
  shop: CmsProductsPresetShop;
};

export type CmsProductsPresetProductCardItemList = {
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  date_started?: Maybe<Scalars['CrTimestamp']>;
  end_cursor?: Maybe<Scalars['String']>;
  exposure_order_type: CmsProductsPresetExposureOrderType;
  group_list_type?: Maybe<Scalars['String']>;
  groups: Array<CmsProductsPresetGroup>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<CmsProductsPresetProductCardItem>;
  server_time?: Maybe<Scalars['CrTimestamp']>;
  total_count: Scalars['Int'];
  type?: Maybe<CmsProductsPresetType>;
};

export type CmsProductsPresetProductCardItemListSearchInput = {
  after?: InputMaybe<Scalars['String']>;
  /**
   * 사용자의 앱 버전
   * - 특정 앱 아래에서 umd API를 호출하지 않기 위해 추가되었다
   */
  app_version?: InputMaybe<Scalars['String']>;
  custom_search_option?: InputMaybe<CmsProductsPresetFilterOption>;
  group_index?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  /**
   * 정렬 타입
   * 프프 내 노출 정렬 타입이 선택 가능한 타입이어야 동작한다.
   */
  order_type?: InputMaybe<CmsProductsPresetFixedListOrderType>;
  products_preset_uuid: Scalars['String'];
  sales_status_list?: InputMaybe<Array<CmsProductsPresetSearchedProductSalesStatus>>;
  /**
   * 세션 데이터 옵션
   * - 기획전에서 해당 언어 / 통화로 적용되어야 하는 경우 사용합니다.
   */
  session_data_options?: InputMaybe<CmsProductsPresetSessionOptions>;
  simple?: InputMaybe<Scalars['Boolean']>;
  store_unique?: InputMaybe<Scalars['Boolean']>;
  use_ad_image?: InputMaybe<Scalars['Boolean']>;
};

export enum CmsProductsPresetSearchedProductSalesStatus {
  /** 삭제 */
  DELETED = 'DELETED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

/** 프로덕트 프리셋 세션 옵션 */
export type CmsProductsPresetSessionOptions = {
  /**
   * 배송지
   * 'KR'
   */
  country?: InputMaybe<Scalars['String']>;
  /**
   * 환율
   * 'KRW'
   */
  currency?: InputMaybe<Scalars['String']>;
  /**
   * 언어 ko
   * ---
   * 'ko'
   */
  language?: InputMaybe<Scalars['String']>;
  /**
   * site_id
   * ---
   * '1'
   */
  site_id?: InputMaybe<Scalars['ID']>;
};

export type CmsProductsPresetShop = {
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum CmsProductsPresetType {
  /** 상품 변동형 */
  FILTER = 'Filter',
  /** 상품 고정형 */
  ID_LIST = 'IdList',
  /** 쇼핑몰 기획전 연동형 */
  SHOP_PROMOTION = 'ShopPromotion',
  /** 스토어픽 */
  STORE_PICK = 'StorePick',
}

export type CmsPromotionRankCard = {
  card_item: CmsProductsPresetProductCardItem;
  /** 순위 변동 */
  ranking_changed?: Maybe<Scalars['Int']>;
};

export type CmsPromotionRankCardResponse = Pagination & {
  page: Scalars['Int'];
  page_count?: Maybe<Scalars['Int']>;
  page_size: Scalars['Int'];
  /** 프로모션 노출 카테고리 목록 */
  promotion_display_categories: Array<PromotionDisplayCategory>;
  /** 프로모션 랭킹 카드 리스트 */
  promotion_rank_cards: Array<CmsPromotionRankCard>;
  total_count: Scalars['Int'];
  /** 갱신 시각 */
  updated_at: Scalars['CrTimestamp'];
};

export type CmsPromotionRankInput = {
  display_category_key?: InputMaybe<Scalars['String']>;
  pagination: PaginationInput;
  rank_department_type?: InputMaybe<CmsRankDepartmentType>;
  site_id: Scalars['String'];
};

/** 랭크 디파트먼트 타입 */
export enum CmsRankDepartmentType {
  /** 전체 */
  ALL = 'ALL',
  /** 뷰티 */
  BEAUTY = 'BEAUTY',
  /** 브랜드패션 */
  BRAND_FASHION = 'BRAND_FASHION',
  /** 라이프 */
  LIFE = 'LIFE',
  /** 소호패션 */
  SOHO_FASHION = 'SOHO_FASHION',
}

export enum CmsSellableStatus {
  CLOSED = 'CLOSED',
  NOT_SUPPORT_COUNTRY = 'NOT_SUPPORT_COUNTRY',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type CmsStorePresetShop = {
  /** 스토어 ID */
  id: Scalars['Int'];
  /** image_url */
  image_url: Scalars['String'];
  /** main_domain */
  main_domain: Scalars['String'];
  /** 스토어 이름 */
  name: Scalars['String'];
};

export type CmsStorePresetShopList = {
  /** 스토어 목록 */
  shop_list: Array<CmsStorePresetShop>;
  /** 스토어 프리셋 UUID */
  uuid: Scalars['String'];
};

export type CmsStoreRankCard = {
  products: Array<CmsProductsPresetProductCardItem>;
  store_rank_info: CmsStoreRankInfo;
};

export type CmsStoreRankInfo = {
  main_domain?: Maybe<Scalars['String']>;
  /** 최대 할인 금액 */
  max_discount_amount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** 순위 변동 */
  ranking_changed?: Maybe<Scalars['Int']>;
  shop_id: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
};

/** 스토어 리스트 랭킹 카드 응답 */
export type CmsStoresRankCardResponse = Pagination & {
  page: Scalars['Int'];
  page_count?: Maybe<Scalars['Int']>;
  page_size: Scalars['Int'];
  /** 스토어 랭킹 카드 리스트 */
  store_rank_cards: Array<CmsStoreRankCard>;
  total_count: Scalars['Int'];
  /** 갱신 시각 */
  updated_at: Scalars['CrTimestamp'];
};

export type CmsStoresRankInput = {
  pagination: PaginationInput;
  rank_department_type: CmsRankDepartmentType;
  site_id: Scalars['String'];
};

export enum CmsUxComponentType {
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

export enum CmsUxGoodsStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

/** 상품 수거 방법 */
export enum CollectingType {
  SELLER_COLLECT_REQUIRED = 'SELLER_COLLECT_REQUIRED',
  USER_ALREADY_SENT = 'USER_ALREADY_SENT',
  USER_WILL_SEND = 'USER_WILL_SEND',
}

export type ColorSetInfo = {
  button_color?: Maybe<Scalars['String']>;
  button_dark_color?: Maybe<Scalars['String']>;
  text_color?: Maybe<Scalars['String']>;
};

export type CompleteDailyMissionAttendanceResult = DailyMissionReward & {
  additional_item_name?: Maybe<Scalars['String']>;
  /**
   * attendance_type
   * --
   * ATTENDANCE_D1: 1일 출석
   * ATTENDANCE_D15: 15일 출석
   * ATTENDANCE_M1: 1달 출석
   * RE_ATTENDANCE: 재출석
   */
  attendance_type: Scalars['String'];
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

/** up/down 게임 완료 input */
export type CompleteDailyMissionUpDownInput = {
  /** 티켓 id */
  daily_mission_game_ticket_id: Scalars['ID'];
  /** 어뷰징 방지를 위한 토큰 */
  token: Scalars['String'];
  /** 암호화된 게임 플레이 기록 리스트 문자 */
  updown_game_play_history_list: Array<DailyMissionUpDownGamePlayHistoryInput>;
};

/** up/down 게임 완료 */
export type CompleteDailyMissionUpDownResult = {
  /** 순위권 도달 여부 */
  is_winner: Scalars['Boolean'];
  /** 최종 도달 라운드 */
  last_success_round: Scalars['Int'];
  /** 나의 순위 */
  ranking: Scalars['Int'];
  /**
   * 나의 리워드
   * - is_winner가 true인 경우에만 값이 전달.
   */
  reward?: Maybe<DailyMissionUpDownWinnerReward>;
};

export type ConfirmOrderItemListAndIssueRepurchaseCouponListInput = {
  /** 구매확정 할 주문 아이템의 번호 목록 */
  order_item_number_list: Array<Scalars['String']>;
  /** 구매확정 할 쇼핑몰 레코드 ID 목록 */
  shop_id_list: Array<Scalars['ID']>;
};

/** markOrderItemListAsConfirmed의 결과 */
export type ConfirmOrderItemListAndIssueRepurchaseCouponListInputResult = {
  /** 쿠폰이 발급된 쇼핑몰 레코드 ID 목록 */
  issued_coupon_shop_id_list: Array<Scalars['ID']>;
  /** 성공 여부 */
  succeed: Scalars['Boolean'];
};

export type ConnectAppleUserAccountInput = {
  /** 애플 Bundle Id */
  bundle_id: Scalars['String'];
  /** 로그인 인증 코드 */
  code: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
};

/** connectAppleWithAuth 의 입력 */
export type ConnectAppleWithAuthInput = {
  /** 애플 Bundle Id */
  bundle_id: Scalars['String'];
  /** 로그인 인증 코드 */
  code: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 야간 혜택 알림 수신 동의 여부 */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 제 3자 제공 동의 여부 */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
};

export type ConnectFacebookUserAccountInput = {
  /** 로그인 인증 토큰 */
  access_token: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
};

/** connectFacebookWithAuth 의 입력 */
export type ConnectFacebookWithAuthInput = {
  /** 로그인 인증 토큰 */
  access_token: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 야간 혜택 알림 수신 동의 여부 */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 제 3자 제공 동의 여부 */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
};

export type ConnectGoogleUserAccountInput = {
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  /** 로그인 인증 토큰 */
  token: Scalars['String'];
};

/** connectGoogleWithAuth 의 입력 */
export type ConnectGoogleWithAuthInput = {
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 야간 혜택 알림 수신 동의 여부 */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 제 3자 제공 동의 여부 */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 로그인 인증 토큰 */
  token: Scalars['String'];
};

export type ConnectKakaoUserAccountInput = {
  /** 사용자 카카오 계정 로그인 후 SDK 로부터 받은 접근 토큰 */
  access_token: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  /** 사용자 카카오 계정 로그인 후 SDK 로부터 받은 갱신 토큰 */
  refresh_token: Scalars['String'];
};

/** connectKakaoWithAuth 의 입력 */
export type ConnectKakaoWithAuthInput = {
  access_token: Scalars['String'];
  /** 다른 사용자 계정에 연결된 경우 해당 계정으로 연동 대체 여부 */
  disconnect_other_account?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
  refresh_token: Scalars['String'];
};

/** connectSocialWithEmailAuthentication 의 입력 */
export type ConnectSocialWithEmailAuthenticationInput = {
  /** 인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key: Scalars['String'];
  /**
   * 소셜 구분
   * - KAKAO, APPLE, GOOGLE, FACEBOOK
   */
  social_type: Scalars['String'];
};

export type ContentEditor = {
  /** 컨텐츠 데이터 json */
  content_editor_json?: Maybe<Scalars['String']>;
  /** 배송국가 */
  country?: Maybe<CmsCountry>;
  /** 통화 */
  currency?: Maybe<CmsCurrency>;
  /** 종료 일시 */
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  /** 시작 일시 */
  date_started?: Maybe<Scalars['CrTimestamp']>;
  /** 언어 */
  language?: Maybe<CmsLanguage>;
  /** 공유 정보 */
  share_info?: Maybe<CmsContentEditorShareInfo>;
  /** 지원하는 agent 리스트 */
  support_agent_list: Array<ContentEditorAgent>;
  /** 콘텐츠 명 */
  title?: Maybe<Scalars['String']>;
  /** 컨텐츠 타입 */
  type?: Maybe<Scalars['String']>;
  /** uuid */
  uuid: Scalars['String'];
  /** 웹 렌더링을 위한 메타데이터 */
  web_rendering_metadata?: Maybe<ContentEditorWebRenderingMetadata>;
};

export enum ContentEditorAgent {
  APP = 'APP',
  WEB = 'WEB',
}

export type ContentEditorGroup = {
  content_list: Array<ContentEditor>;
  id: Scalars['ID'];
  operation_title: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ContentEditorLimitedOrderCouponInfo = {
  /** 쿠폰 발급 종료일 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 쿠폰 발급 시작일 */
  date_issue_start: Scalars['CrTimestamp'];
  /** 쿠폰 발급 중지일 - 쿠폰이 중지된 경우 값이 세팅됨 */
  date_issue_stop?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 정책 ID */
  id: Scalars['ID'];
  /** 쿠폰 주문 가능 수량(선착순) */
  order_limit_count: Scalars['Int'];
  /** 실시간 쿠폰 사용 가능 개수 (선착순 쿠폰인 경우에만 반환됨, 이외의 경우 0) */
  usable_coupon_count: Scalars['Int'];
};

export type ContentEditorWebRenderingMetadata = {
  /** 상품 카드 노출 케이스 정보 (plp v2 여부) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  product_preset_item_count: Array<CmsProductPresetItemCountInfo>;
};

export type CountOfTypes = {
  /** 장바구니 쿠폰 개수 */
  order: Scalars['Int'];
  /** 상품쿠폰 개수 */
  order_item: Scalars['Int'];
  /** 스토어 쿠폰 개수 */
  shop: Scalars['Int'];
  /** 스토어 그룹쿠폰 개수 */
  shop_group: Scalars['Int'];
  /** 직진배송 대상 쿠폰 개수 */
  zigzin: Scalars['Int'];
};

export enum CountryCode {
  CA = 'CA',
  JP = 'JP',
  KR = 'KR',
  US = 'US',
}

export enum CountryType {
  CA = 'CA',
  JP = 'JP',
  KR = 'KR',
  US = 'US',
}

/** 쿠폰정보 & 추가정보 */
export type CouponAndDiscountInfo = {
  /** 실제 할인될 금액(정률이라면 계산된 금액) */
  calculated_discount_amount: Scalars['Int'];
  /**
   * 쿠폰 code
   * @deprecated 해당 필드를 사용하여 처리하는 부분이 없음
   */
  code?: Maybe<Scalars['String']>;
  /** 쿠폰 발행일 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 삭제일 */
  date_deactived?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 만료일 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 지급일 */
  date_issued?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 수정일 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 사용일 */
  date_used?: Maybe<Scalars['CrTimestamp']>;
  /** 기존 쿠폰 id */
  deactived_user_account_coupon_id: Scalars['Float'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 패널티받은 스토어 여부 */
  is_penalty: Scalars['Boolean'];
  /** 사이트 ID (지그재그와 다른 사이트 구분을 위해 사용) */
  site_id: Scalars['Int'];
  /** 쿠폰 상태 */
  status?: Maybe<UserAccountCouponStatus>;
  /** 사용한 사이트 */
  used_site_id?: Maybe<Scalars['Int']>;
  /** 쿠폰 정책 */
  user_account_coupon_policy: UserAccountCouponPolicy;
  /** 쿠폰 정책 레코드 ID */
  user_account_coupon_policy_id: Scalars['ID'];
  /** 사용자 계정 레코드 ID */
  user_account_id: Scalars['ID'];
};

/** 스토어별 쿠폰 발급 상태 */
export type CouponAvailableInfo = {
  /** 원본스토어 ID */
  origin_shop_id?: Maybe<Scalars['ID']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 쿠폰 발급 상태 */
  status: CouponAvailableStatus;
};

/** 스토어별 쿠폰 발급 상태 리스트 */
export type CouponAvailableInfoList = {
  item_list: Array<CouponAvailableInfo>;
};

/** 한 상품에 대한 쿠폰 발급 상태 */
export enum CouponAvailableStatus {
  COUPON_AVAILABLE = 'COUPON_AVAILABLE',
  COUPON_ISSUED = 'COUPON_ISSUED',
  COUPON_UNAVAILABLE = 'COUPON_UNAVAILABLE',
}

export type CouponAvailableStatusListInput = {
  /** 마이픽쿠폰 포함 여부 */
  include_my_pick?: InputMaybe<Scalars['Boolean']>;
  /** 발급 상태를 체크할 대상 목록 */
  target_list: Array<CouponAvailableTarget>;
};

export type CouponAvailableTarget = {
  /** Origin 스토어 ID */
  origin_shop_id?: InputMaybe<Scalars['ID']>;
  /** Product ID List */
  product_id_list: Array<Scalars['ID']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 쿠폰 뱃지 표시 타입 */
export enum CouponBadgeType {
  MY_PICK_COUPON = 'MY_PICK_COUPON',
  NONE = 'NONE',
}

/** 쿠폰함 */
export type CouponBox = {
  /** 쿠폰함의 아이템 */
  item_list: Array<CouponBoxItem>;
  /** 총 개수 */
  total_count: Scalars['Float'];
  /** 쿠폰함의 UI 아이템 */
  ui_item_list: Array<CouponBoxComponent>;
};

export type CouponBoxComponent = {
  /** UI 섹션 타이틀 */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: CouponBoxComponentType;
};

/** 쿠폰 component type */
export enum CouponBoxComponentType {
  COUPON_GROUP_HEADER = 'COUPON_GROUP_HEADER',
  COUPON_HEADER = 'COUPON_HEADER',
  COUPON_ITEM = 'COUPON_ITEM',
  COUPON_LINE = 'COUPON_LINE',
  COUPON_TITLE_BAR = 'COUPON_TITLE_BAR',
}

/** 쿠폰함 아이템 */
export type CouponBoxItem = {
  /** 쿠폰 button title */
  button_title?: Maybe<Scalars['String']>;
  /** 쿠폰 PACK TYPE */
  coupon_box_item_type: CouponBoxItemStatus;
  /** 쿠폰 message 1 */
  coupon_message1?: Maybe<Scalars['String']>;
  /** 쿠폰 message 2 */
  coupon_message2?: Maybe<Scalars['String']>;
  /** 쿠폰 code tag */
  coupon_tag?: Maybe<Scalars['String']>;
  /** 쿠폰 title */
  coupon_title: Scalars['String'];
  /** 쿠폰 type backgroun color */
  coupon_type_background_color: Scalars['Float'];
  /** 쿠폰 type discount */
  coupon_type_discount: Scalars['String'];
  /** 쿠폰 type text color */
  coupon_type_text_color: Scalars['Float'];
  /** 쿠폰 type title */
  coupon_type_title: Scalars['String'];
  /** 쿠폰 link title */
  link_title?: Maybe<Scalars['String']>;
  /** 쿠폰 link url(deep link) */
  link_url?: Maybe<Scalars['String']>;
  /** 쿠폰의 상태 */
  status?: Maybe<UserAccountCouponStatus>;
  /** 쿠폰 PACK id */
  user_account_coupon_pack_id?: Maybe<Scalars['String']>;
  /** 쿠폰 정책 id */
  user_account_coupon_policy_id?: Maybe<Scalars['String']>;
};

/** 쿠폰 상태 */
export enum CouponBoxItemStatus {
  COUPON_PACK = 'COUPON_PACK',
  NORMAL_COUPON = 'NORMAL_COUPON',
}

/** 상품(카탈로그) 정보(catalog wrapping) */
export type CouponCatalogInfo = {
  /** 호스팅사 상품 번호 */
  external_code?: Maybe<Scalars['ID']>;
  /** 상품ID(카탈로그) */
  id: Scalars['ID'];
  /** 상품명 */
  name: Scalars['String'];
  /** 상품의 이미지 리스트 */
  product_image_list: Array<CouponCatalogProductImage>;
  /** 상품의 가격 정보 */
  product_price: CouponCatalogProductPrice;
  /** 상점 ID */
  shop_id: Scalars['Int'];
};

/** 상품의 이미지 정보 */
export type CouponCatalogProductImage = {
  /** 이미지 타입 */
  image_type: CouponCatalogProductImageType;
  /** 원본 이미지 */
  image_url: Scalars['String'];
};

/** 이미지 타입 */
export enum CouponCatalogProductImageType {
  MAIN = 'MAIN',
  SUB = 'SUB',
}

/** 상품 가격 정보 */
export type CouponCatalogProductPrice = {
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 다통화 할인가 */
  discount_price_with_currency: CouponPriceWithCurrency;
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 다통화 정상가 */
  original_price_with_currency: CouponPriceWithCurrency;
};

/** 쿠폰 Code TYPE */
export enum CouponCodeType {
  EVENT = 'EVENT',
  GENERAL = 'GENERAL',
  NONE = 'NONE',
  REDEEM = 'REDEEM',
  UNIQUE = 'UNIQUE',
}

/** 쿠폰 모아보기 쿠폰 제공 카루셀 - 찜한 상품 쿠폰 받기 UX 에서 사용 */
export type CouponCollectionCouponCarousel = {
  /** 카루셀 리스트 */
  coupon_list?: Maybe<CouponCollectionCouponCarouselList>;
};

/** 쿠폰 모아보기 쿠폰 제공 카루셀 아이템 - 찜한 상품 쿠폰 받기 UX 에서 사용 */
export type CouponCollectionCouponCarouselItem = {
  /** 할인가 표시 */
  discount_label: Scalars['String'];
  /** 상품 관련 쿠폰 전체 발급 여부 */
  is_issued_all: Scalars['Boolean'];
  /**
   * 실제 서비스 노출용이 아니라 내부적인 관리에 쓰이는 용도의 shop_id
   * 쿠폰 전부 발급 되었는지 실시간 체크 시 shop_id 에 넣어야하는 값을 위한 필드(직진배송 상품의 경우 직진배송 임시 스토어 id 가 있어야함)
   * 직진배송 임시 스토어 id - prod : 9038, alpha : 20017
   */
  managed_shop_id: Scalars['ID'];
  /** 관련 상품 정보 */
  product: UxGoodsCardItem;
  /**
   * 관련 shop 정보
   * 서비스에서 노출, 랜딩 등에 이용되는 shop
   */
  shop: UxCouponCollectionShopInfo;
  /** 아이템 썸네일 */
  thumbnail: UxCommonImage;
};

/** 쿠폰 모아보기 쿠폰 제공 카루셀 아이템 리스트 - 찜한 상품 쿠폰 받기 UX 에서 사용 */
export type CouponCollectionCouponCarouselList = {
  /** 리스트 내용 */
  item_list?: Maybe<Array<CouponCollectionCouponCarouselItem>>;
  /** 전체 갯수 */
  total_count: Scalars['Int'];
};

/** 쿠폰 모아보기 쿠폰 정책 TARGET */
export type CouponCollectionCouponTarget = {
  /** 쿠폰 target model의 ID */
  target_uid: Scalars['String'];
};

/**
 * 쿠폰 모아보기 지면 내 쿠폰 발급 에러 내용
 * (기존 user-account-coupon의 IssueUserAccountShopCouponList 의 에러 응답과 유사한 형태)
 */
export type CouponCollectionError = {
  extensions?: Maybe<CouponCollectionErrorExtension>;
  message?: Maybe<Scalars['String']>;
};

/**
 * 쿠폰 모아보기 지면 내 쿠폰 발급 에러 내용 세부 내용
 * (기존 user-account-coupon의 IssueUserAccountShopCouponList 의 에러 응답과 유사한 형태)
 */
export type CouponCollectionErrorExtension = {
  /**
   * 에러 코드
   * (ex. already_issued_coupon, exhausted_coupon, expired_coupon, invalid_coupon_policy_id, stopped_coupon_policy, not_satisfied_issue_coupon_condition, throttle_max_request_exceeded 등)
   */
  code?: Maybe<Scalars['String']>;
};

/** 최대 할인 적용 가능한 쿠폰조합 정보 */
export type CouponCombination = {
  /** 조합 가능한 쿠폰 목록 */
  coupon_id_list: Array<Scalars['Int']>;
};

/** 쿠폰 적용 가능 상품 배송 타입 */
export enum CouponDeliveryType {
  NORMAL = 'NORMAL',
  ZONLY = 'ZONLY',
}

/** 쿠폰 적용 불가 상태 조회 결과 */
export type CouponExcludeStatus = {
  /** 쿠폰 제외 대상 여부 */
  disabled: Scalars['Boolean'];
  /** 쿠폰 제외 대상 ID */
  product_id: Scalars['ID'];
  /** 쿠폰 제외 대상 스토어 ID */
  shop_id: Scalars['ID'];
};

export type CouponExcludeTargetInput = {
  /** 상품 ID */
  product_id?: InputMaybe<Scalars['ID']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 쿠폰함 그룹 헤더 */
export type CouponGroupHeader = CouponBoxComponent & {
  /** coupon header id */
  coupon_header_id?: Maybe<Scalars['Int']>;
  /** image_url */
  image_url: Scalars['String'];
  /** 쿠폰 link url(deep link) */
  link_url?: Maybe<Scalars['String']>;
  /** UX position */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** 헤더 title */
  title: Scalars['String'];
  /** UX TYPE */
  type: CouponBoxComponentType;
};

/** 쿠폰함 헤더 */
export type CouponHeader = CouponBoxComponent & {
  /** coupon header id */
  id: Scalars['Int'];
  /** UX position */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** 헤더 title */
  title: Scalars['String'];
  /** UX TYPE */
  type: CouponBoxComponentType;
};

export type CouponIssueStatusResult = {
  /** 쿠폰팩 발급 여부 (쿠폰팩 ID를 요청하지 않으면 false, 쿠폰팩에 포함된 모든 정책을 발급 받았는지를 반환합니다.) */
  is_issued_coupon_pack: Scalars['Boolean'];
  /** 발급한 정책 ID 목록 */
  issued_policy_id_list: Array<Scalars['ID']>;
  /** 발급하지 않은 정책 ID 목록 */
  not_issued_policy_id_list: Array<Scalars['ID']>;
};

/** 쿠폰함 아이템 */
export type CouponItem = CouponBoxComponent & {
  /** 쿠폰 badge */
  coupon_badge?: Maybe<Scalars['String']>;
  /** coupon header id */
  coupon_header_id?: Maybe<Scalars['Int']>;
  /** 쿠폰 message 1 */
  coupon_message1?: Maybe<Scalars['String']>;
  /** 쿠폰 message 2 */
  coupon_message2?: Maybe<Scalars['String']>;
  /** 쿠폰 tag */
  coupon_tag?: Maybe<Scalars['String']>;
  /** 쿠폰 tag text color */
  coupon_tag_text_color?: Maybe<Scalars['Float']>;
  /** 쿠폰 title */
  coupon_title: Scalars['String'];
  /** 쿠폰 title text color */
  coupon_title_text_color: Scalars['Float'];
  /** 쿠폰 type discount */
  coupon_type_discount: Scalars['String'];
  /** 쿠폰 type discount text color */
  coupon_type_discount_text_color: Scalars['Float'];
  /** 왼쪽의 line 존재 여부 */
  has_line: Scalars['Boolean'];
  /** zpay icon 존재 여부 */
  has_zpay_icon: Scalars['Boolean'];
  /** item 의 icon image url */
  icon_image_url?: Maybe<Scalars['String']>;
  /** item 의 icon text */
  icon_text?: Maybe<Scalars['String']>;
  /** UX position */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** 쿠폰의 상태 */
  status?: Maybe<UserAccountCouponStatus>;
  /** UX TYPE */
  type: CouponBoxComponentType;
  /** 쿠폰 정책 id */
  user_account_coupon_policy_id?: Maybe<Scalars['String']>;
};

/** 상품 정보 */
export type CouponItemInfo = {
  /** 정책에 속한 대상(상품) 전체 개수 */
  count: Scalars['Int'];
  /** 정책에 속한 대상(상품)의 첫번째 상품ID(카탈로그) */
  product_id: Scalars['String'];
};

/** 쿠폰함 line */
export type CouponLine = CouponBoxComponent & {
  /** 쿠폰함 line color */
  color: Scalars['Float'];
  /** 쿠폰함 height */
  height: Scalars['Float'];
  /** 쿠폰함 line margin */
  margin: CouponMargin;
  /** UX position */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** UX TYPE */
  type: CouponBoxComponentType;
};

/** 쿠폰함 line margin */
export type CouponMargin = {
  /** 쿠폰함 top margin */
  bottom: Scalars['Float'];
  /** 쿠폰함 top margin */
  left: Scalars['Float'];
  /** 쿠폰함 top margin */
  right: Scalars['Float'];
  /** 쿠폰함 top margin */
  top: Scalars['Float'];
};

export enum CouponPolicyDiscountType {
  AMOUNT_DISCOUNT = 'AMOUNT_DISCOUNT',
  RATE_DISCOUNT = 'RATE_DISCOUNT',
}

/** 쿠폰 policy의 리스트 */
export type CouponPolicyList = {
  item_list: Array<UserAccountCouponPolicy>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 정책에 포함된 상품들의 카테고리 정보 (상품그룹쿠폰에서만 활용) */
export type CouponPolicyProductCategory = {
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 카테고리에 속한 상품 ID 목록 */
  product_id_list: Array<Scalars['ID']>;
};

/** policy별 target_list */
export type CouponPolicyTarget = {
  /** 쿠폰 정책 target_list */
  target_list: Array<UserAccountCouponTarget>;
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

/** policy별 target_list */
export type CouponPolicyTargetList = {
  /** 쿠폰 정책 ID */
  item_list: Array<CouponPolicyTarget>;
};

/** 다통화 가격 정보 */
export type CouponPriceWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환 */
  currency: Scalars['String'];
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

export type CouponProductInfo = {
  /** 상품단가 */
  amount: Scalars['Int'];
  /** Origin 스토어 ID */
  origin_shop_id?: InputMaybe<Scalars['ID']>;
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 옵션 ID */
  product_item_id: Scalars['ID'];
  /** 상품수량 */
  quantity: Scalars['Int'];
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 쿠폰 발급 유저 타입 */
export enum CouponRegistrationType {
  BOOKMARK = 'BOOKMARK',
  GENERAL = 'GENERAL',
  INACTIVE_ACCOUNT = 'INACTIVE_ACCOUNT',
  MEMBERSHIP = 'MEMBERSHIP',
  MESSAGE = 'MESSAGE',
  NEW = 'NEW',
  REFERER_NAVER = 'REFERER_NAVER',
  REPURCHASE = 'REPURCHASE',
  REVIEW = 'REVIEW',
  STORY = 'STORY',
  TARGETING = 'TARGETING',
}

export type CouponShippingInfo = {
  /** 배송비 */
  amount: Scalars['Int'];
  /** 배송비 쿠폰 적용할 상품 옵션 ID LIST */
  product_item_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 쇼핑몰 정보 */
export type CouponShopInfo = {
  /** 스토어 카테고리 정보 */
  category?: Maybe<Scalars['Int']>;
  /** 쇼핑몰 id */
  id: Scalars['String'];
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 직진배송 여부 */
  is_zonly: Scalars['Boolean'];
  /** 주도메인 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 이름 */
  name: Scalars['String'];
  /** 스토어 스타일 정보 */
  style?: Maybe<Scalars['String']>;
  /** shop 타입 */
  type: CouponShopType;
  /** 쇼핑몰 대표 이미지 url */
  typical_image_url?: Maybe<Scalars['String']>;
  /** shop url */
  url: Scalars['String'];
};

/** Shop 타입 */
export enum CouponShopType {
  META = 'META',
  STORE = 'STORE',
  ZPAY = 'ZPAY',
}

/** 쿠폰 Target TYPE */
export enum CouponTargetType {
  CATEGORY = 'CATEGORY',
  DEPARTMENT = 'DEPARTMENT',
  ORDER = 'ORDER',
  ORDER_ITEM = 'ORDER_ITEM',
  ORDER_ITEM_GROUP = 'ORDER_ITEM_GROUP',
  POINT = 'POINT',
  SHIPPING_FEE = 'SHIPPING_FEE',
  SHOP = 'SHOP',
  SHOP_GROUP = 'SHOP_GROUP',
  SHOP_GROUP_JUST_ZONLY = 'SHOP_GROUP_JUST_ZONLY',
  SHOP_GROUP_WITH_ZONLY = 'SHOP_GROUP_WITH_ZONLY',
  SHOP_SHIPPING_FEE = 'SHOP_SHIPPING_FEE',
  SHOP_WITH_ZONLY = 'SHOP_WITH_ZONLY',
}

/** 쿠폰함 line */
export type CouponTitleBar = CouponBoxComponent & {
  /** UX position */
  position: Scalars['Int'];
  /** shop_id */
  shop_id?: Maybe<Scalars['String']>;
  /** title */
  title: Scalars['String'];
  /** UX TYPE */
  type: CouponBoxComponentType;
};

export enum CouponUseStatus {
  ALL = 'ALL',
  EMPTY = 'EMPTY',
  PARTIAL = 'PARTIAL',
}

export type CreateAffiliateDeepLinkInput = {
  /** 앱내 브라우저 타입 full_screen_browser 사용 여부. 기본값: simple_browser */
  is_fullscreen_browser?: InputMaybe<Scalars['Boolean']>;
  page_url: Scalars['String'];
};

export type CreateAffiliateShareLinkInput = {
  deep_link: Scalars['String'];
  product_id?: InputMaybe<Scalars['ID']>;
  product_no?: InputMaybe<Scalars['String']>;
  share_type: ShareType;
  shop_id?: InputMaybe<Scalars['String']>;
};

/** Braze User Map 데이터 생성 Input */
export type CreateBrazeUserInput = {
  /** Braze Device Id */
  braze_device_id: Scalars['String'];
  /**
   * 유저 계정 ID(UserAccounts.id)
   * ---
   * @description
   * 바로 전달이 가능할 경우 채워 넣어주세요.
   * ---
   * @default null
   */
  user_account_id?: InputMaybe<Scalars['ID']>;
  /**
   * 유저 계정 UUID(UserAccounts.uuid)
   * ---
   * @description
   * 바로 전달이 가능할 경우 채워 넣어주세요.
   * ---
   * @default null
   */
  user_account_uuid?: InputMaybe<Scalars['String']>;
  /** 유저 디바이스 ID(User.uuid) */
  user_uuid: Scalars['String'];
};

/** createCheckout의 입력 */
export type CreateCheckoutInput = {
  /** 국내 배송비 */
  domestic_shipping_fee?: InputMaybe<Scalars['Int']>;
  /** 해외 배송비 */
  international_shipping_fee?: InputMaybe<Scalars['Int']>;
  /** 당일 배송 여부 */
  is_same_day_shipping?: InputMaybe<Scalars['Boolean']>;
  /** 주문할 상품 목록 */
  order_item_list: Array<CheckoutOrderItemInput>;
  /** 주문서 UUID */
  order_sheet_uuid: Scalars['String'];
  /** 주문자 정보 */
  orderer: CheckoutOrdererInput;
  /** 사용 포인트 */
  points_used?: InputMaybe<Scalars['Int']>;
  /** 쇼핑몰상품쿠폰 사용시 적용한 아이템과의 매칭정보 입력 */
  product_item_coupon_list?: InputMaybe<Array<CheckoutProductItemCouponListInput>>;
  /** 수령자 정보 */
  receiver: ReceiverInput;
  /** zpay 서비스 */
  required_agree_zpay_service: Scalars['Boolean'];
  /** 사용자 계정 정보 업데이트 필요 여부 */
  required_update_user_account: Scalars['Boolean'];
  /** 당일 배송비 금액 */
  same_day_shipping_fee?: InputMaybe<Scalars['Int']>;
  /** 배송비쿠폰 사용시 적용한 아이템과의 매칭정보 입력 */
  shipping_fee_coupon_list?: InputMaybe<Array<CheckoutShippingFeeCouponListInput>>;
  /** 배송 메모(deprecated - user_shipping_memo로 대체) */
  shipping_memo?: InputMaybe<Scalars['String']>;
  /** 총 상품 할인 가 */
  total_discount_item_amount: Scalars['Int'];
  /** 총 배송비 할인 가 */
  total_discount_shipping_fee: Scalars['Int'];
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 사용 마일리지 */
  total_mileage_amount?: InputMaybe<Scalars['Int']>;
  /** 총 결제 금액 */
  total_payment_amount: Scalars['Int'];
  /** 총 배송비(deprecated - domestic_shipping_fee로 대체) */
  total_shipping_fee?: InputMaybe<Scalars['Int']>;
  /** 유저 쿠폰 레코드 ID */
  user_account_coupon_id_list: Array<Scalars['ID']>;
  /** 배송 주소록 아이디 */
  user_shipping_address_book_id?: InputMaybe<Scalars['ID']>;
  /** 사용자가 작성한 배송메모 입력 */
  user_shipping_memo?: InputMaybe<UserShippingMemoInput>;
  /** 장바구니를 통한 주문 여부 */
  via_cart?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDailyMissionEventLinkInput = {
  event_type: Scalars['String'];
};

/** 오늘의 혜택 게임 카카오톡 초대 링크 */
export type CreateDailyMissionGameInvitationLinkInput = {
  /**
   * 게임 타입
   * [
   *   UPDOWN,
   * ]
   */
  game_type: Scalars['String'];
  /**
   * 카카오톡 초대인지 여부
   * - false: 일반 링크
   * - true: 카카오톡 초대
   */
  is_kakao_talk: Scalars['Boolean'];
};

/** 오늘의 혜택 게임 초대 링크 결과 */
export type CreateDailyMissionGameInvitationLinkResult = {
  /** 초대 링크 */
  invitation_link: Scalars['String'];
};

/** 게임 초대 보상 리워드 지급 input */
export type CreateDailyMissionGameInvitationRewardInput = {
  /**
   * 게임 타입
   * [
   *   UPDOWN,
   * ]
   */
  game_type: Scalars['String'];
  /** 초대 코드 */
  invite_code: Scalars['String'];
};

/** - deprecated(reason: "랜덤박스 링크 공유가 완전 교체된경우 삭제 예정, CreateDailyMissionInvitationRewardV2Result 사용") */
export type CreateDailyMissionInvitationRewardResult = {
  point: Scalars['Int'];
  random_box_count: Scalars['Int'];
};

/** 오늘의 혜택 초대 보상 요청 input */
export type CreateDailyMissionInvitationRewardV2Input = {
  /** 초대 토큰 */
  invite_token: Scalars['String'];
};

/** 오늘의 혜택 초대 보상 결과 */
export type CreateDailyMissionInvitationRewardV2Result = {
  /** 랜덤박스 수 */
  random_box_count: Scalars['Int'];
};

/** 즐겨찾기 지역 저장 input */
export type CreateDailyMissionLikedRegionInput = {
  /** 검색한 도로명 주소 */
  address: Scalars['String'];
};

export type CreateEPickPostInput = {
  description?: InputMaybe<Scalars['String']>;
  image_list?: InputMaybe<Array<Scalars['String']>>;
  is_ad: Scalars['Boolean'];
  product_list?: InputMaybe<Array<EPickPostProductInput>>;
  style_tag?: InputMaybe<Scalars['String']>;
  style_tag_id_list: Array<Scalars['ID']>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  youtube_url?: InputMaybe<Scalars['String']>;
};

/** 에픽 포스트 생성 input V2 */
export type CreateEPickPostInputV2 = {
  /** 글 */
  description?: InputMaybe<Scalars['String']>;
  /** 컨텐츠 리스트 */
  image_content_input_list: Array<EPickPostImageContentInput>;
  /** 광고 여부 */
  is_ad: Scalars['Boolean'];
  /** 스타일 태그 id 리스트 */
  style_tag_id_list: Array<Scalars['ID']>;
  /** 태그 리스트 */
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
};

export type CreateEPickPostResponse = {
  info_link?: Maybe<InfoLink>;
  post_count_by_user: EPickPostedMetaByUser;
  post_id: Scalars['String'];
  posting_info_text_list: Array<InfoText>;
  posting_info_type: PostingInfoType;
  redirect_url?: Maybe<Scalars['String']>;
};

/** 에픽 포스트 생성 응답 V2 */
export type CreateEPickPostResponseV2 = {
  /** 유저별 게시글 메타 정보 */
  post_count_by_user: EPickPostedMetaByUser;
  /** 게시글 ID */
  post_id: Scalars['String'];
  /** 게시글 작성 후 앱 내부에 노출할 안내 텍스트 리스트 */
  posting_info_text_list: Array<InfoText>;
  /** 포스팅 정보 타입 */
  posting_info_type: PostingInfoType;
  /** 작성한 글의 redirect url */
  redirect_url?: Maybe<Scalars['String']>;
};

export type CreateEPickReReplyInput = {
  description: Scalars['String'];
  parent_reply_id: Scalars['ID'];
  post_id: Scalars['ID'];
  to_profile_id: Scalars['ID'];
};

export type CreateEPickReplyInput = {
  description: Scalars['String'];
  post_id: Scalars['ID'];
};

export type CreateEventContentReplyInput = {
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 이벤트 댓글 메타데이터 uuid */
  event_content_reply_metadata_uuid: Scalars['String'];
};

export type CreateMyPickCouponUserRequestInput = {
  /** 신청 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 주문 문의 입력 */
export type CreateOrderItemInquiryInput = {
  /** 첨부파일 URL 목록 */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 문의 내용 */
  content: Scalars['String'];
  /** 주문 품목 번호 */
  order_item_number: Scalars['String'];
  /** 주문 상품 문의 타입 */
  type: OrderItemInquiryType;
};

/** 주문 문의 입력 결과 */
export type CreateOrderItemInquiryResult = {
  /** 생성된 주문 문의 레코드 ID */
  id?: Maybe<Scalars['ID']>;
};

/** createOrderSheet의 입력 */
export type CreateOrderSheetInput = {
  /** 당일 배송 여부 */
  is_same_day_shipping?: InputMaybe<Scalars['Boolean']>;
  /** 상품 정보의 배열 */
  order_item_list: Array<CreateOrderSheetOrderItemInput>;
  /** 주문서 임시데이터에 미리 선택한 쿠폰 id list */
  selected_user_account_coupon_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

/** createOrderSheet의 상품정보 */
export type CreateOrderSheetOrderItemInput = {
  /** 상품 아이템의 추가 옵션 정보(연동형 옵션/추가입력 옵션) 입력 리스트 */
  additional_option_detail_list?: InputMaybe<Array<ProductAdditionalOptionInput>>;
  /** 카트 아이템 해시 문자열(카트에서 생성되었을 경우 전송) */
  cart_item_hash?: InputMaybe<Scalars['String']>;
  /** 퍼널 정보 */
  funnel_info?: InputMaybe<FunnelInfoInput>;
  /** grouping 적용 수량 (2+1 상품의 경우, 할당되어야 하는 값은 3) */
  grouping_required_quantity?: InputMaybe<Scalars['Int']>;
  /** 가격 */
  price: Scalars['Int'];
  /** 주문할 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 상품에 적용된 프로모션 정보 */
  promotion_applied_info?: InputMaybe<PromotionAppliedInfoInput>;
  /** 수량 */
  quantity: Scalars['Int'];
};

/** 상품리뷰 첨부파일 생성 입력 */
export type CreateProductReviewAttachmentInput = {
  /** S3 Object Key */
  object_key: Scalars['String'];
};

/** createProductReview의 입력 */
export type CreateProductReviewInput = {
  /** 상품리뷰 첨부파일 목록 */
  attachment_list?: InputMaybe<Array<CreateProductReviewAttachmentInput>>;
  /**
   * 리뷰 사진 URL 목록
   * deprecated: attachment_list 사용
   */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 상품리뷰 특성 평가의 배열 */
  attribute_list: Array<CreateProductReviewInputAttribute>;
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 상품주문 번호 */
  order_item_number: Scalars['String'];
  /** 리뷰 평점 */
  rating: Scalars['Int'];
};

export type CreateProductReviewInputAttribute = {
  /** 특성 평가 답변 */
  answer: Scalars['String'];
  /** 특성 평가 질문 */
  question: Scalars['String'];
};

/** createProductReview의 결과 */
export type CreateProductReviewResult = {
  /** 생성된 상품 리뷰의 레코드 ID */
  id: Scalars['ID'];
  /** 첫 리뷰 작성 여부 */
  is_first_review: Scalars['Boolean'];
  /** 발급된 마일리지 */
  mileage_issued: Scalars['Int'];
  /** 발급된 포인트 */
  point_issued: Scalars['Int'];
  /** 상품 리뷰 */
  product_review: ProductReview;
  /** 상품 리뷰 첨부파일 유형 */
  type: ProductReviewType;
};

export type CreateProductReviewResultInfo = {
  /** 이벤트 안내 배너 정보 */
  banner?: Maybe<ProductReviewBanner>;
  /** 문구 안내 정보 */
  content?: Maybe<ProductReviewResultContent>;
};

/** CreateProductReviewReply 의 입력 */
export type CreateProductReviewUserReplyInput = {
  /** 상품리뷰 댓글 내용 */
  contents: Scalars['String'];
  /** 상품리뷰 댓글 ID */
  parent_reply_id?: InputMaybe<Scalars['ID']>;
  /** 상품리뷰 ID */
  product_review_id: Scalars['ID'];
};

export type CreateProductReviewUserReplyResult = {
  alarm_bottom_sheet: ProductReviewUserReplyAlarmBottomSheet;
  product_review_user_reply: ProductReviewUserReply;
};

export type CreateQuickProductReviewInput = {
  /** 상품주문 번호 */
  order_item_number: Scalars['String'];
  /** 리뷰 평점 */
  rating: Scalars['Int'];
};

export type CreateQuickProductReviewResult = {
  /** 생성된 상품 리뷰의 레코드 ID */
  id: Scalars['ID'];
  /** 첫 리뷰 작성 여부 */
  is_first_review: Scalars['Boolean'];
  /** 발급된 마일리지 */
  mileage_issued: Scalars['Int'];
  /** 발급된 포인트 */
  point_issued: Scalars['Int'];
  /** 상품 리뷰 첨부파일 유형 */
  type: ProductReviewType;
};

/** createRewardUserAccount 의 input */
export type CreateRewardUserAccountInput = {
  /** 채널 목록 */
  channel_list: Array<RewardUserAccountChannelInput>;
  /** 초대자 레퍼럴 코드 */
  inviter_referral_code?: InputMaybe<Scalars['String']>;
};

/** CreateSimpleBankCert 입력 */
export type CreateSimpleBankCertInput = {
  /** 계좌번호 */
  bank_account_number: Scalars['String'];
  /** 은행 코드 */
  bank_code: Scalars['String'];
};

/** 계좌 인증 정보 생성 결과 */
export type CreateSimpleBankCertResult = {
  /** 계좌 인증 정보 ID */
  simple_bank_cert_id: Scalars['ID'];
};

/** CreateSimpleBankCert 입력 */
export type CreateSimpleBankUserAuthInput = {
  /** 본인 인증 uid */
  authenticate_id: Scalars['String'];
};

export type CreateStoryInput = {
  document: Scalars['String'];
  thumbnail_url: Scalars['String'];
  type: StoryType;
};

export type CreateStoryResponse = {
  story_id: Scalars['ID'];
};

export type CreateTalkLoungePollInput = {
  /** 복수 선택 허용여부 */
  allow_multiple_answer: Scalars['Boolean'];
  option_list: Array<CreateTalkLoungePollOptionInput>;
};

export type CreateTalkLoungePollOptionInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CreateTalkLoungeReplyInput = {
  image_url_list?: InputMaybe<Array<Scalars['String']>>;
  parent_id?: InputMaybe<Scalars['ID']>;
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  talk_id: Scalars['ID'];
  text: Scalars['String'];
};

export type CreateTalkLoungeTalkInput = {
  category_id: Scalars['ID'];
  content: Scalars['String'];
  image_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 골라줘 사용 여부 */
  is_pick_one: Scalars['Boolean'];
  /** 포인트 걸고 질문하기 금액 (deprecated) */
  point_amount?: InputMaybe<Scalars['Int']>;
  poll?: InputMaybe<CreateTalkLoungePollInput>;
  /** 연동 상품 목록 */
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 포인트 or 마일리지 걸고 질문하기 금액 */
  reward_amount?: InputMaybe<Scalars['Int']>;
  /** 보상 걸고 질문하기 타입 (POINT, MILEAGE) */
  reward_type?: InputMaybe<Scalars['String']>;
  /** 연동 대상 */
  target_map?: InputMaybe<CreateTalkLoungeTargetMapInput>;
  title: Scalars['String'];
};

export type CreateTalkLoungeTalkResponse = {
  redirect_url: Scalars['String'];
  talk_id: Scalars['ID'];
};

/** 톡라운지 타겟맵 input */
export type CreateTalkLoungeTargetMapInput = {
  /**
   * target_type에 따라 target_id 정보가 달라짐
   * RAFFLE : raffle 당첨 id
   */
  target_id: Scalars['ID'];
  /**
   * target_type
   * [
   * RAFFLE : 래플 당첨
   * ]
   */
  target_type: Scalars['String'];
};

export type CreateUploadLinkInput = {
  /** 업로드 링크 범주 */
  category: UploadLinkCategory;
  /** 링크 만료 시각(초 단위). 입력하지 않는 경우 3600초(1시간) 가 기본값 */
  expire_seconds?: InputMaybe<Scalars['Int']>;
};

/** 기본 배송지 등록의 입력 */
export type CreateUserDefaultShippingAddressBookInput = {
  /** 배송 주소록 아이디 */
  user_shipping_address_book_id: Scalars['ID'];
};

/** CreateUserRefundAccountInput 입력 */
export type CreateUserRefundAccountInput = {
  /** 계좌주 명 */
  account_holder: Scalars['String'];
  /** 계좌번호 */
  account_number: Scalars['String'];
  /** 은행코드 */
  bank_code: Scalars['String'];
};

/** CreateUserRefundAccountResult 결과 */
export type CreateUserRefundAccountResult = {
  /** 환불 계좌 ID */
  refund_account_id: Scalars['ID'];
};

/** 배송 주소록 입력 */
export type CreateUserShippingAddressBookInput = {
  /** 주소지 */
  address: Scalars['String'];
  /** 배송지 명 */
  address_name: Scalars['String'];
  /** 도시 */
  city?: InputMaybe<Scalars['String']>;
  /** 연락처 */
  contract_number: Scalars['String'];
  /** 국가 코드 */
  country?: InputMaybe<CountryType>;
  /** 국가번호 */
  country_number?: InputMaybe<Scalars['String']>;
  /** 상세주소지 */
  detail_address?: InputMaybe<Scalars['String']>;
  /** 수령인(이름) */
  first_name: Scalars['String'];
  /** 기본 배송지 여부 */
  is_default: Scalars['Boolean'];
  /** 수령인(성) */
  last_name: Scalars['String'];
  /** 우편번호 */
  post_code: Scalars['String'];
  /** 지역 */
  state?: InputMaybe<Scalars['String']>;
};

export type Creative = {
  ad_id: Scalars['ID'];
  creative_id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  sub_title?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CrmRecommendItem = CrmRecommendItemProduct | CrmRecommendItemStore;

export enum CrmRecommendItemBrowsingType {
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type CrmRecommendItemDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CrmRecommendItemPriceWithCurrency = {
  /**
   * 통화 정보
   * Accept-Language 헤더로로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: CrmRecommendPaymentCurrency;
  /** 소숫점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen 등) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

/** 아이템 목록에 들어갈 상품 정보 */
export type CrmRecommendItemProduct = {
  item_type: CrmRecommendItemType;
  product: CrmRecommendItemProductInfo;
  shop: CrmRecommendItemShopInfo;
};

export type CrmRecommendItemProductInfo = {
  /** 브라우저 타입 */
  browsing_type: CrmRecommendItemBrowsingType;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 카테고리 리스트 */
  category_list?: Maybe<Array<Scalars['String']>>;
  /** 상품 플래그, 쿠폰할인가, Z할인가 정보 */
  discount_info?: Maybe<CrmRecommendItemDiscountInfo>;
  /** 상품 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 최종 할인가 */
  final_price?: Maybe<Scalars['Int']>;
  /** 최종 할인가 통화 정보 */
  final_price_with_currency: CrmRecommendItemPriceWithCurrency;
  /** 무료 배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** 상품 쿠폰 존재 여부 */
  has_coupon?: Maybe<Scalars['Boolean']>;
  /** 원본 상품 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 상품 여부 */
  is_brand?: Maybe<Scalars['Boolean']>;
  /** zonly 상품 여부 */
  is_zonly?: Maybe<Scalars['Boolean']>;
  /** zpay 할인 여부 */
  is_zpay_discount?: Maybe<Scalars['Boolean']>;
  /** 최대 가격 통화 정보 */
  max_price_with_currency: CrmRecommendItemPriceWithCurrency;
  /** 상품 가격 */
  price?: Maybe<Scalars['Int']>;
  /** 상품 URL */
  product_url?: Maybe<Scalars['String']>;
  /** 리뷰 개수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 리뷰 스코어 */
  review_score?: Maybe<Scalars['Float']>;
  sellable_status: CrmRecommendSellableStatus;
  /** 샵 아이디 */
  shop_id?: Maybe<Scalars['ID']>;
  /** 쇼핑몰의 상품 ID */
  shop_product_no?: Maybe<Scalars['String']>;
  /** 상품명 */
  title?: Maybe<Scalars['String']>;
  /** 상품 URL */
  url?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
};

export type CrmRecommendItemShopInfo = {
  id: Scalars['ID'];
  main_domain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum CrmRecommendItemStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

/** 아이템 목록에 들어갈 스토어 정보 */
export type CrmRecommendItemStore = CrmRecommendItemStoreInfo & {
  id: Scalars['ID'];
  item_type: CrmRecommendItemType;
  main_domain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product_list: Array<CrmRecommendItemProductInfo>;
  trait: CrmShopTrait;
  typical_image_url?: Maybe<Scalars['String']>;
};

export type CrmRecommendItemStoreInfo = {
  id: Scalars['ID'];
  main_domain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  trait: CrmShopTrait;
  typical_image_url?: Maybe<Scalars['String']>;
};

export enum CrmRecommendItemType {
  /** 상품 목록 타입 */
  PRODUCT = 'PRODUCT',
  /** 스토어 목록 타입 */
  STORE = 'STORE',
}

export type CrmRecommendMetadataHeaderItem = CrmRecommendMetadataProduct | CrmRecommendMetadataStore;

/** 헤더에 들어갈 상품 정보 */
export type CrmRecommendMetadataProduct = {
  item_type: CrmRecommendItemType;
  product: CrmRecommendItemProductInfo;
  shop: CrmRecommendItemShopInfo;
};

/** 헤더에 들어갈 스토어 정보 */
export type CrmRecommendMetadataStore = CrmRecommendItemStoreInfo & {
  id: Scalars['ID'];
  item_type: CrmRecommendItemType;
  main_domain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  trait: CrmShopTrait;
  typical_image_url?: Maybe<Scalars['String']>;
};

/** 통화 정보 */
export enum CrmRecommendPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

/** 상품 판매 가능 여부 */
export enum CrmRecommendSellableStatus {
  CLOSED = 'CLOSED',
  NOT_SUPPORT_COUNTRY = 'NOT_SUPPORT_COUNTRY',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type CrmShopTrait = {
  category?: Maybe<Scalars['Int']>;
  category_list: Array<Scalars['String']>;
  category_name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export type CropBox = {
  max_x: Scalars['Int'];
  max_y: Scalars['Int'];
  min_x: Scalars['Int'];
  min_y: Scalars['Int'];
};

export type CropCenter = {
  x: Scalars['Int'];
  y: Scalars['Int'];
};

/** 실시간 통화 정보 */
export type CurrencyMeta = {
  /** 원화에 곱하는 값 (원화 x converted_rate = display 통화) */
  converted_rate: Scalars['Float'];
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: PaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
};

export enum CurrencyType {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type CurrentMyPickCouponSetInfoResult = {
  /** 플로팅 버튼 클릭시 이동할 마이픽쿠폰 딥링크 주소 Ex) zigzag:///full_screen_simple_browser?url=... */
  floating_button_link: Scalars['String'];
  /** 플로팅 버튼 텍스트 */
  floating_button_text: Scalars['String'];
  /** 진행중인 Set 정보 */
  my_pick_coupon_set: MyPickCouponSet;
  /** 유저의 마이픽쿠폰 수령 여부 */
  user_issued: Scalars['Boolean'];
};

export type CurrentUserReward = {
  /** 기준 월 시작일시 */
  date_stats_gte: Scalars['CrTimestamp'];
  /** 기준 월 종료일시 */
  date_stats_lte: Scalars['CrTimestamp'];
  /** 기준 월 (yyyy-MM) */
  date_stats_ym: Scalars['String'];
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /** @deprecated use date_stats_gte */
  ts_stats_gte: Scalars['CrTimestamp'];
  /** @deprecated use date_stats_lte */
  ts_stats_lte: Scalars['CrTimestamp'];
};

export type CustomFilterTitleInfo = {
  filter_id: Scalars['ID'];
  title: Scalars['String'];
};

export type CustomInputOption = {
  id?: Maybe<Scalars['ID']>;
  /** 값 입력 제한 */
  max_length?: Maybe<Scalars['Int']>;
  /** 상품 옵션명 */
  name: Scalars['String'];
  /** 필수 여부 */
  required: Scalars['Boolean'];
};

export enum DdpProductRecommendGroupStyle {
  BUTTON = 'BUTTON',
  PAGINATION = 'PAGINATION',
}

/** 출석체크 현황 */
export type DailyMissionAttendance = {
  /**
   * 출석 요일 리스트
   *   deprecated: 총 출석일로만 관리할 예정
   */
  date_attendance_list: Array<Scalars['CrTimestamp']>;
  /**
   * 룰렛 특별 보상 아이템
   *   deprecated: 룰렛 보상 이미지를 전달하는데, 룰렛을 사용하지 않으므로 제거 예정.
   */
  representative_item?: Maybe<DailyMissionRouletteRepresentativeItem>;
  /**
   * 여러개일 경우 룰렛 생성일 순으로 보낸다.
   *   deprecated: 룰렛 미사용 예정.
   */
  roulette: Array<DailyMissionRoulette>;
  /**
   * 출석 요일 리스트 길이
   *   deprecated: 총 출석일로만 관리할 예정
   */
  total_count: Scalars['Int'];
};

/** 출석체크 현황 ( 새로운 배전 ) */
export type DailyMissionAttendanceV2 = {
  /** 오늘 출석체크 여부 */
  is_today_attendance: Scalars['Boolean'];
  /** 다음번 출석체크 시 받을 수 있는 보상 */
  next_attendance_reward: DailyMissionNextAttendanceReward;
  /** 총 연속 출석일 */
  total_attendance_day: Scalars['Int'];
};

/** 오늘의 혜택 받을수 있는 리워드 */
export type DailyMissionAvailableReward = {
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /**
   * 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
};

export type DailyMissionBehavior = {
  /** 행동 횟수 */
  behavior_count: Scalars['Int'];
  /** 행동 여부 */
  is_behaved: Scalars['Boolean'];
  /** 포인트 지급 여부 */
  is_completed: Scalars['Boolean'];
  /**
   * 목표 행동 횟수
   * - default: 1
   */
  target_behavior_count: Scalars['Int'];
};

/**
 * 보상 가능한 포인트 및 마일리지, 오퍼월 리워드
 * - deprecated(reason: "use DailyMissionAvailableReward")
 */
export type DailyMissionBoard = {
  /** 내가 받을 수 있는 마일리지 (오퍼월 미포함) */
  my_savable_mileage: Scalars['Int'];
  /** (deprecated) 내가 받을 수 있는 포인트 (오퍼월 미포함) */
  my_savable_point: Scalars['Int'];
  /** 오늘 적립 받은 마일리지 (오퍼월 미포함) */
  today_my_saved_mileage: Scalars['Int'];
  /** (deprecated) 오늘 적립 받은 포인트 (오퍼월 포함) */
  today_my_saved_point: Scalars['Int'];
  /** (deprecated) 전체 유저 대상 지급 된 총 포인트 (오퍼월 미포함) */
  total_saved_point: Scalars['Int'];
  /** 전체 유저 대상 지급 된 총 리워드 금액 (오퍼월 미포함) */
  total_saved_reward_amount: Scalars['Int'];
};

export type DailyMissionCard = {
  background_color: AffCommonColor;
  icon_url: AffCommonImageUrl;
  is_completed: Scalars['Boolean'];
  landing_url: Scalars['String'];
  main_title: AffCommonText;
  mission_type: Scalars['String'];
  sub_title: AffCommonText;
};

/** 데일리 미션 정보와 리워드 */
export type DailyMissionCardAndReward = {
  /** 데일리 미션 카드 */
  daily_mission_card: DailyMissionCard;
  /**
   * 예상 데일리 미션 보상
   * ( 참여전, 참여후 모두 포함 )
   */
  expected_daily_mission_reward: ExpectedDailyMissionReward;
};

/** 오늘의 혜택 미션 카드 목록 */
export type DailyMissionCardList = {
  /** 미션 카드 목록 */
  item_list: Array<DailyMissionCard>;
};

export type DailyMissionCheerSellerContent = {
  content_editor_uuid: Scalars['String'];
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  /** 샵 즐겨찾기 여부 */
  is_bookmarked: Scalars['Boolean'];
  /** 포인트 지급 여부 */
  is_completed: Scalars['Boolean'];
  /** 기획전 확인 여부 */
  is_viewed: Scalars['Boolean'];
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
  title: Scalars['String'];
};

export type DailyMissionCheerSellerContentList = {
  item_list: Array<DailyMissionCheerSellerContent>;
};

/** 날씨 정보 */
export type DailyMissionDailyWeather = {
  /** 기준 날짜 (년월일) */
  date_ymd: Scalars['Int'];
  /**
   * 강수 확률
   * %단위로 사용, 소수점 현재는 표기하지 않음.
   */
  precipitation_probability: Scalars['Float'];
  /** 오늘 최고 온도 */
  temperature_max: Scalars['Float'];
  /** 오늘 최저 온도 */
  temperature_min: Scalars['Float'];
  /**
   * 날씨 타입 이름
   *  - 맑음, 흐림, 구름 많이 등등
   * Deprecated(reason: use weather_condition_meta_am, weather_condition_meta_pm)
   * @deprecated use weather_condition_meta_am, weather_condition_meta_pm
   */
  weather_condition: Scalars['String'];
  /** 오전 - 날씨 컨디션과 아이콘 이미지 url */
  weather_condition_meta_am: DailyMissionWeatherConditionMeta;
  /** 오후 - 날씨 컨디션과 아이콘 이미지 url */
  weather_condition_meta_pm: DailyMissionWeatherConditionMeta;
  /**
   * 날씨별 아이콘 이미지 url
   * Deprecated(reason: use weather_condition_meta_am, weather_condition_meta_pm)
   * @deprecated use weather_condition_meta_am, weather_condition_meta_pm
   */
  weather_icon_image_url: AffCommonImageUrl;
};

/** 일별 날씨 정보 목록 */
export type DailyMissionDailyWeatherList = {
  /** 날씨 목록 */
  item_list: Array<DailyMissionDailyWeather>;
};

/** 받을수 있는 시간 및 보유 현황 */
export type DailyMissionGameTicketSummary = {
  /**
   * 티켓을 받을수있는 시간
   * - 보유한 게임권 최대 보유수량보다 적게 소유할 때 값 전달
   */
  date_generatable?: Maybe<Scalars['CrTimestamp']>;
  /** 보유한 게임 티켓 수 */
  game_ticket_count: Scalars['Int'];
  /** 보유 게임 티켓 제한 수 */
  game_ticket_count_limit: Scalars['Int'];
  /** 게임 타입 */
  game_type: Scalars['String'];
  /**
   * 티켓을 받을수 있을때까지 남은 초
   * - 보유한 게임권 최대 보유수량 소유했을 때 값 전달
   */
  remain_second?: Maybe<Scalars['Int']>;
};

/** 날씨 정보 */
export type DailyMissionHourlyWeather = {
  /** 기준 날짜 (년월일) */
  date_ymd: Scalars['Int'];
  /**
   * 예보 시간
   * ex. 0, 1, 2,..22, 23
   */
  hour: Scalars['Int'];
  /**
   * 강수 확률
   * %단위로 사용, 소수점 현재는 표기하지 않음.
   */
  precipitation_probability: Scalars['Float'];
  /** 기온 */
  temperature: Scalars['Float'];
  /**
   * 날씨 타입 이름
   *  - 맑음, 흐림, 구름 많이 등등
   * Deprecated(reason: use weather_condition_meta)
   * @deprecated use weather_condition_meta
   */
  weather_condition: Scalars['String'];
  /** 날씨 컨디션과 아이콘 이미지 url */
  weather_condition_meta: DailyMissionWeatherConditionMeta;
  /**
   * 날씨별 아이콘 이미지 url
   * Deprecated(reason: use weather_condition_meta)
   * @deprecated use weather_condition_meta
   */
  weather_icon_image_url: AffCommonImageUrl;
};

export type DailyMissionInvitation = {
  is_inviter: Scalars['Boolean'];
  mobile_tel: Scalars['String'];
  reward?: Maybe<DailyMissionInvitationReward>;
};

export type DailyMissionInvitationList = {
  item_list: Array<DailyMissionInvitation>;
};

export type DailyMissionInvitationReward = {
  point: Scalars['Int'];
  random_box_count: Scalars['Int'];
};

export type DailyMissionLike = {
  /** 포인트 지급 여부 */
  is_completed: Scalars['Boolean'];
  /** 좋아요 수 */
  like_count: Scalars['Int'];
};

/** 유저가 저장한 지역 정보 */
export type DailyMissionLikedRegion = {
  /** 유저가 검색한 도로명 주소 */
  address: Scalars['String'];
  /** 유저가 저장한 지역정보 id */
  id: Scalars['ID'];
  /** 행정 단위별 지역 정보 */
  region: DailyMissionRegion;
};

/** 유저별 최근 검색한 지역 목록 */
export type DailyMissionLikedRegionList = {
  /** 유저별 최근 검색한 지역 목록 */
  item_list: Array<DailyMissionLikedRegion>;
};

/** 럭키볼 참여 내역 */
export type DailyMissionLuckyBallParticipateHistory = {
  /** 보상 정보 */
  daily_mission_reward?: Maybe<DailyMissionLuckyBallReward>;
  /** 당첨일자 */
  date_ymd: Scalars['Int'];
  /** 당첨 번호 목록(내림차순) */
  draw_number_list: Array<Scalars['Int']>;
  /** 응모 번호 */
  entry_number_list?: Maybe<Array<Scalars['Int']>>;
  /** 보상 지급 여부 */
  is_rewarded: Scalars['Boolean'];
  /** 당첨 여부 */
  is_winner: Scalars['Boolean'];
  /** 같은 번호 응모자 수 */
  same_entry_number_participant_count: Scalars['Int'];
  /** 당첨자 숫자 */
  winner_count: Scalars['Int'];
};

/** 럭키볼 2일간 참여 내역 목록 */
export type DailyMissionLuckyBallParticipateHistoryList = {
  /** 참여 내역 리스트 */
  item_list: Array<DailyMissionLuckyBallParticipateHistory>;
};

export type DailyMissionLuckyBallReward = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export type DailyMissionMenu = {
  item_list: Array<DailyMissionCard>;
  landing_url: Scalars['String'];
  title: AffCommonText;
};

/** 오늘의 혜택 미션 스탬프 */
export type DailyMissionMissionStamp = {
  /** 미션 완료 목표 */
  daily_mission_mission_stamp_goal: DailyMissionMissionStampGoal;
  /** 아이콘 이미지 url */
  icon_url: AffCommonImageUrl;
  /** 완료 여부 */
  is_completed: Scalars['Boolean'];
  /** landing url */
  landing_url: Scalars['String'];
  /** 메인 제목 */
  main_title: AffCommonText;
  /** 미션 타입 */
  mission_type: Scalars['String'];
};

/** 오늘의 혜택 미션 스탬프 세부 목표 */
export type DailyMissionMissionStampGoal = {
  /** 미션별 수행 가능한 최대 횟수 */
  maximum_count: Scalars['Int'];
  /** 미션별 오늘 완료한 미션 횟수 */
  participated_count: Scalars['Int'];
};

/** 오늘의 혜택 미션 스탬프 목록 */
export type DailyMissionMissionStampList = {
  /** 미션 스탬프 리워드 */
  daily_mission_mission_stamp_reward: DailyMissionMissionStampReward;
  /** 미션 스탬프 목록 */
  item_list: Array<DailyMissionMissionStamp>;
};

/** 오늘의 혜택 미션 스탬프 리워드 */
export type DailyMissionMissionStampReward = {
  /** 리워드 */
  reward_amount: Scalars['Int'];
  /**
   * 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
};

/** 출석체크 리워드 */
export type DailyMissionNextAttendanceReward = {
  /** 다음 출석시 보상 받을수 있는 날짜 */
  date_ymd: Scalars['Int'];
  /** 리워드 */
  reward: Scalars['Int'];
  /** 리워드 타입 [ POINT, MILEAGE, GIFT ] */
  reward_type: Scalars['String'];
};

export type DailyMissionQuiz = {
  /** 퀴즈 정답(정답을 맞췄을때만 전달) */
  answer?: Maybe<Scalars['String']>;
  hint_url: Scalars['String'];
  id: Scalars['ID'];
  /** 이벤트 참여여부 */
  is_completed: Scalars['Boolean'];
  /** 퀴즈미션 내 장바구니 조건 포함여부 */
  is_including_cart_condition: Scalars['Boolean'];
  /** 데일리 미션 퀴즈 참여율 현황 */
  participation_stats: DailyMissionQuizParticipationStats;
  /** 퀴즈 질문 */
  question: Scalars['String'];
  reward: DailyMissionQuizReward;
  shop?: Maybe<DailyMissionQuizShop>;
  /**
   * 이벤트 상태
   * --
   * DISPLAYED (노출중)
   * EARLY_FINISHED (선착순 완료)
   */
  status: Scalars['String'];
};

export type DailyMissionQuizList = {
  item_list: Array<DailyMissionQuiz>;
  total_count: Scalars['Int'];
};

/** 데일리 미션 퀴즈 참여율 현황 */
export type DailyMissionQuizParticipationStats = {
  /** 현재까지 참여한 인원 수 */
  current_participation_count: Scalars['Int'];
  /** 남은 퀴즈 참여 가능 인원 수 */
  participation_remaining_count: Scalars['Int'];
};

export type DailyMissionQuizReward = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export type DailyMissionQuizShop = {
  bookmark_count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_saved_shop: Scalars['Boolean'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
};

export type DailyMissionRandomBoxCount = {
  new_box_count: Scalars['Int'];
  openable_box_count: Scalars['Int'];
};

/** 오늘의 혜택 랜덤박스 초대 횟수 별 리워드 */
export type DailyMissionRandomBoxInviteCountReward = {
  /** 리워드 받는 초대 횟수 */
  invite_count: Scalars['Int'];
  /** 리워드 수령 여부 */
  is_received: Scalars['Boolean'];
  /** 리워드 */
  reward_amount: Scalars['Int'];
  /** 리워드 타입 */
  reward_type: Scalars['String'];
};

/** 오늘의 혜택 랜덤박스 리워드 */
export type DailyMissionRandomBoxReward = {
  /** 리워드 */
  reward_amount: Scalars['Int'];
  /** 리워드 타입 */
  reward_type: Scalars['String'];
};

/**
 * 오늘의 혜택 랜덤박스 요약
 * - 오늘자 기준 데이터를 전달
 */
export type DailyMissionRandomBoxSummary = {
  /** 랜덤박스 요약 정보 기준 일자 (당일) */
  date_ymd: Scalars['Int'];
  /** 랜덤박스 초대 횟수 */
  invite_random_box_count: Scalars['Int'];
  /** 오픈 할 수 있는 랜덤박스 */
  openable_random_box_count: Scalars['Int'];
  /** 오픈 한 랜덤박스 수 */
  opened_random_box_count: Scalars['Int'];
  /**
   * 랜덤박스 초대 횟수별 리워드 상태 목록
   * - 1, 3, 5, 10 회차 리워드 정보가 내려감
   */
  random_box_invite_count_reward_list: Array<DailyMissionRandomBoxInviteCountReward>;
  /**
   * 오늘받은 랜덤박스 오픈 리워드 + 랜덤박스 초대 보상 리워드
   * MILEAGE, POINT 각각 내려감
   */
  random_box_reward_list: Array<DailyMissionRandomBoxReward>;
};

/** 추가 리워드 지급 미션에 필요한 총 리워드와 받은 리워드 */
export type DailyMissionReachedToTargetRewardStat = {
  /** 추가 리워드 지급 미션의 목표 리워드 */
  goal_reward: Scalars['Int'];
  /** 오늘자 추가 리워드 지급 미션 완료 여부 */
  is_completed: Scalars['Boolean'];
  /** 추가 리워드 지급 미션에 포함된 미션을 모두 달성했는지 여부 */
  is_completed_target_missions: Scalars['Boolean'];
  /** 추가 리워드 지급 미션에 적용되는 총 받은 리워드 */
  total_reward: Scalars['Int'];
};

/** 오늘의 혜택 오늘 받은 리워드 */
export type DailyMissionReceivedReward = {
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /**
   * 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
};

/**
 * 행정 단위별 지역 정보
 * - 1단계: 시, 도
 * - 2단계: 구, 군, 시
 * - 3단계: 동, 면, 읍
 */
export type DailyMissionRegion = {
  /**
   * 1단계 지역 이름 ( 시, 도 )
   * ex. 서울특별시, 강원도
   */
  address_1: Scalars['String'];
  /**
   * 2단계 지역 이름 ( 구, 군, 시)
   * ex. 강남구, 충주시, 단양군
   */
  address_2: Scalars['String'];
  /**
   * 3단계 지역 이름 ( 동, 면, 읍 )
   * ex. 장충동, 강화읍, 월곶면
   */
  address_3: Scalars['String'];
  id: Scalars['ID'];
};

/** 지역 조회 결과 목록 */
export type DailyMissionRegionList = {
  /** 지역 목록 */
  item_list: Array<DailyMissionRegion>;
  /** 지역 수 */
  total_count: Scalars['Int'];
};

export type DailyMissionReward = {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export enum DailyMissionRewardType {
  GIFT = 'GIFT',
  MILEAGE = 'MILEAGE',
  POINT = 'POINT',
}

export type DailyMissionRoulette = {
  /** 응모권 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 응모권 만료일 */
  date_expired: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  is_played: Scalars['Boolean'];
  /**
   * roulette_type
   * --
   * ATTENDANCE_D15: 15일 출석
   * ATTENDANCE_M1: 1달 출석
   */
  roulette_type: Scalars['String'];
};

export type DailyMissionRouletteRepresentativeItem = {
  image_url: Scalars['String'];
  name: Scalars['String'];
};

export type DailyMissionRouletteReward = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

/**
 * 유저별 최근 검색한 지역 목록
 * - deprecated(reason: "use DailyMissionLikedRegionList")
 */
export type DailyMissionSavedRegionList = {
  /** 유저별 최근 검색한 지역 목록 */
  item_list: Array<DailyMissionRegion>;
};

/** 스타일링 정보 */
export type DailyMissionStyling = {
  /** 그라데이션으로 사용할 색상 */
  foreground_color: Scalars['String'];
  /** 스타일링 id */
  styling_id: Scalars['ID'];
  /** 스타일링 image url */
  styling_image_url: Scalars['String'];
};

/** 오늘의 혜택 세팅값 */
export type DailyMissionSystemConfig = {
  /**
   * 현재 브라우저 타입
   *   [ simple_browser, full_screen_simple_browser]
   */
  current_browser_type: Scalars['String'];
};

/** up/down 전광판 */
export type DailyMissionUpDownBoard = {
  /**
   * 게임 실제 종료 시간
   * 종료 날짜가 내려가는 케이스
   * - 당첨자가 모두 나온 경우
   * - 정책에서 정한 게임 종료일자가 지난 경우
   */
  date_completed?: Maybe<Scalars['CrTimestamp']>;
  /**
   * up/down 정책이 활성화된 일자
   * - 오늘자 정책 시간전에는 어제의 정책 date ymd 반환
   * - 오늘자 정책 시작 시간~내일자 정책 시작 시간 전까지는 오늘자 정책 date ymd 반환
   */
  date_ymd: Scalars['Int'];
  /** up/down 정책의 참여한 유저수 */
  participation_count: Scalars['Int'];
  /** up/down 당첨자 리스트 */
  updown_winner_list: Array<DailyMissionUpDownWinner>;
  /** up/down 정책의 당첨자 수 */
  winner_count: Scalars['Int'];
};

/** up/down 라운드별 플레이 기록 */
export type DailyMissionUpDownGamePlayHistoryInput = {
  /** 라운드 종료 일시 */
  date_round_ended: Scalars['CrTimestamp'];
  /** 라운드 시작 일시 */
  date_round_started: Scalars['CrTimestamp'];
  /** 뒤집혀진 카드에 적힌 번호 */
  hidden_number: Scalars['Int'];
  /**
   * 유저가 선택한 up/down
   * hidden_number > shown_number -> true
   * hidden_number < shown_number -> false
   */
  is_up: Scalars['Boolean'];
  /** 플레이 라운드 */
  round: Scalars['Int'];
  /** 보여진 카드에 적힌 번호 */
  shown_number: Scalars['Int'];
};

/** up/down 정책 */
export type DailyMissionUpDownPolicy = {
  /** up/down 게임 실제 종료 시간 */
  date_completed?: Maybe<Scalars['CrTimestamp']>;
  /** up/down 게임 종료 시간 */
  date_end: Scalars['CrTimestamp'];
  /** up/down 게임 시작시간 */
  date_start: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  /** UP/DOWN 최종 라운드 */
  last_round: Scalars['Int'];
  /** UP/DOWN 승자 제한 수 */
  winner_count_limit: Scalars['Int'];
  /** UP/DOWN 승자 보상 정책 목록 */
  winner_reward_policy_list: Array<DailyMissionUpDownWinnerRewardPolicy>;
};

/** UP/DOWN 리워드 지급 히스토리 */
export type DailyMissionUpDownRewardHistory = {
  /** 리워드 지급된 일시 */
  date_rewarded?: Maybe<Scalars['CrTimestamp']>;
  /** 리워드, 순위권에 들어 리워드 지급 대상일 경우에만 전달 */
  reward: DailyMissionUpDownWinnerReward;
  /** up down 정책 ID */
  updown_policy_id: Scalars['ID'];
};

/** up/down 당첨자 */
export type DailyMissionUpDownWinner = {
  /**
   * user_account 의 profile.nickname
   * 마스킹 처리 없이 전달
   */
  nickname: Scalars['String'];
  /** 활성화된 오늘자 up/down 정책 회차의 게임 플레이 횟수 */
  play_count: Scalars['Int'];
  /** 순위 */
  ranking: Scalars['Int'];
  /** 리워드 */
  reward: DailyMissionUpDownWinnerReward;
};

/** up/down 당첨 리워드 */
export type DailyMissionUpDownWinnerReward = {
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /**
   * 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
};

/** UP/DOWN 게임 순위별 리워드 정책 */
export type DailyMissionUpDownWinnerRewardPolicy = {
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /**
   * 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
  /** 승자 등수 */
  winner_rank: Scalars['Int'];
};

/** 오늘의 혜택 사용자 서류 */
export type DailyMissionUserDocument = {
  /** 연락처 - 외국인 유저인 경우 필수 */
  contact_tel?: Maybe<Scalars['String']>;
  /** 국정 - 외국인 유저인 경우 필수 */
  country?: Maybe<Scalars['String']>;
  /**
   * 내국인 : 주민등록증
   * 외국인 : 외국인 등록증
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  identification_file?: Maybe<Scalars['String']>;
  /** 검수 반려 사유 */
  inspection_reject_reason?: Maybe<Scalars['String']>;
  /**
   * WAITING,
   * REJECTED,
   * APPROVED
   */
  inspection_status: Scalars['String'];
  /**
   * 검수받는 유저 타입
   * [
   * NORMAL, // 일반 유저
   * UNDER_AGE, // 미성년자
   * FOREIGNER, // 외국인
   * ]
   */
  inspection_user_type: Scalars['String'];
  /**
   * 법정 대리인 동의서 - 미성년자인 경우 필수
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  legal_representative_agreement_file?: Maybe<Scalars['String']>;
  /**
   * 법정 대리인 증명 서류 - 미성년자인 경우 필수
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  proof_of_legal_representative_agreement_file?: Maybe<Scalars['String']>;
  /**
   * 내국인 : 주민등록번호
   * 외국인 : 외국인 등록 번호
   */
  resident_registration_number: Scalars['String'];
  /** 사용자 이름 */
  user_name: Scalars['String'];
};

/** 날씨 상태와 아이콘 */
export type DailyMissionWeatherConditionMeta = {
  /**
   * 날씨 타입 이름
   *  - 맑음, 흐림, 구름 많이 등등
   */
  weather_condition: Scalars['String'];
  /** 날씨별 아이콘 이미지 url */
  weather_icon_image_url: AffCommonImageUrl;
};

/**
 * 날씨미션 알림 스케쥴
 * - 시간, 분
 */
export type DailyMissionWeatherNotificationSchedule = {
  /**
   * 시간
   * 0~23시
   */
  hour: Scalars['Int'];
  /**
   * 분
   * - 0~59, 10분단위 설정
   */
  minute: Scalars['Int'];
};

/** 투표 정보 */
export type DailyMissionWeatherStylingPoll = {
  /** 투표 날짜 */
  date_ymd: Scalars['Int'];
  /** 해당 날짜에 지역 상관없이 투표 참가했는지 여부 */
  is_participated_poll: Scalars['Boolean'];
  /** 투표 선택지 목록 */
  option_list: Array<DailyMissionWeatherStylingPollOption>;
  /** poll ID */
  poll_id: Scalars['ID'];
};

/** 날씨 투표 선택지 */
export type DailyMissionWeatherStylingPollOption = {
  /** 현재 사용자계정이 선택한 옵션인지 여부 */
  is_selected: Scalars['Boolean'];
  /** 투표완료 갯수 */
  poll_count: Scalars['Int'];
  /** 스타일링 목록 */
  styling_list: Array<DailyMissionStyling>;
  /** 태그 ID */
  styling_tag_id: Scalars['ID'];
  /** 태그 명 */
  styling_tag_name: Scalars['String'];
};

/** 날씨 정보 메인 */
export type DailyMissionWeatherSummary = {
  /**
   * 미세먼지 상태
   * (오늘날짜에서만 나감)
   */
  air_pollution_condition?: Maybe<Scalars['String']>;
  /** 기준 날짜 (년월일) */
  date_ymd: Scalars['Int'];
  /** 시간대별 날씨 */
  hourly_weather_list: Array<DailyMissionHourlyWeather>;
  /** 스타일링 연령대 태그 id 목록 */
  styling_age_tag_id_list: Array<Scalars['ID']>;
  /** 스타일링 목록 */
  styling_list: Array<DailyMissionStyling>;
  /**
   * 기온
   * (오늘날짜에서만 나감)
   */
  temperature?: Maybe<Scalars['Float']>;
  /** 오전의 평균 기온 */
  temperature_average_am: Scalars['Float'];
  /** 오후의 평균 기온 */
  temperature_average_pm: Scalars['Float'];
  /** 오늘 최고 온도 */
  temperature_max: Scalars['Float'];
  /** 오늘 최저 온도 */
  temperature_min: Scalars['Float'];
  /** 날씨 배경 이미지 url */
  weather_background_image_url: AffCommonImageUrl;
  /**
   * 현재 시간 날씨와 어제 같은 시간 날씨를 비교후 전달하는 코멘트
   * @deprecated use weather_condition_meta_list
   */
  weather_comment: Scalars['String'];
  /**
   * 해당 날짜의 날씨 설명
   * - 맑음, 흐림,구름 많이 등등
   */
  weather_condition: Scalars['String'];
  /**
   * 해당 날짜의 날씨 설명 및 아이콘 url
   * - 오늘날짜인 경우 현재시간의 기온과 날씨 정보 ( 아이콘은 시간대에 맞게 )
   * - 내일,모레인 경우 오전, 오후 날씨 정보 모두 전달.
   */
  weather_condition_meta_list: Array<DailyMissionWeatherConditionMeta>;
  /**
   * 체감 온도
   * (오늘날짜에서만 나감)
   */
  wind_chill_temperature?: Maybe<Scalars['Float']>;
};

/** 데일리 미션 요약 목록 */
export type DailyMissionWeatherSummaryList = {
  item_list: Array<DailyMissionWeatherSummary>;
};

export type DailyMissionWinner = {
  mission_type: Scalars['String'];
  reward: Scalars['String'];
  reward_type: DailyMissionRewardType;
  winner_name: Scalars['String'];
};

/** 오늘의 혜택 당첨자 목록 및 요약 */
export type DailyMissionWinnerList = {
  /** 오늘의 혜택 당첨자 목록 */
  item_list: Array<DailyMissionWinner>;
  /**
   * 럭키볼 당첨자 요약
   * - 이번주 또는 지난주 당첨자 요약을 반환
   */
  lucky_ball_winner_summary?: Maybe<DailyMissionWinnerSummary>;
};

/** 오늘의 혜택 당첨자 요약 */
export type DailyMissionWinnerSummary = {
  /** 기간 종료 일시 */
  date_end: Scalars['CrTimestamp'];
  /** 기간 시작일시 */
  date_start: Scalars['CrTimestamp'];
  /**
   * 기간 시작일시 / 종료일시 관련 text
   * 예: 이번주, 지난주
   */
  duration_text: Scalars['String'];
  /** 미션 종류 */
  mission_type: Scalars['String'];
  /** 기간 내 총 보상 금액 */
  total_reward_amount: Scalars['Int'];
  /** 기간 내 총 당첨자 수 */
  total_winner_count: Scalars['Int'];
};

/** 소요일에 따른 배송 확률 */
export type DailyShippingProbability = {
  /** 소요일 */
  day: Scalars['Int'];
  /** 확률 */
  probability: Scalars['Float'];
};

export type DateRange = {
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
};

export type DdpBandBanner = {
  icon_image?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  landing_url?: Maybe<Scalars['String']>;
  shop_name?: Maybe<UxCommonText>;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpBanner = {
  badge_types?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  image: DdpImage;
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  shop_id?: Maybe<Scalars['ID']>;
  subtitle?: Maybe<DdpText>;
  title_first: DdpText;
  title_second?: Maybe<DdpText>;
  ubl?: Maybe<UxUbl>;
  use_dim?: Maybe<Scalars['Boolean']>;
};

export type DdpBannerCatalogListGroup = {
  after?: Maybe<Scalars['String']>;
  category_list: Array<DdpCategory>;
  header?: Maybe<DdpHeaderComponent>;
  item: DdpCatalogCarousel;
  total_count?: Maybe<Scalars['Int']>;
};

export type DdpBasicCarouselBasic = {
  button?: Maybe<DdpButton>;
  header?: Maybe<DdpHeaderComponent>;
  item_list?: Maybe<Array<UxGoodsCardItem>>;
};

export type DdpBeautyProductRecommendGroup = DdpData & {
  ddp_product_recommend_group_style: DdpProductRecommendGroupStyle;
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  refresh_button?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpBetaTesterGroup = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<BetaTestItem>;
  type: DdpComponentType;
};

export type DdpBookmarkNewItemShop = {
  /** 샵 아이디 */
  id: Scalars['ID'];
  /** 샵 메인도메인 */
  main_domain: Scalars['String'];
  /** 샵 이름 */
  name: Scalars['String'];
  /** 샵 이미지 */
  typical_image_url: Scalars['String'];
  /** 샵 도메인 */
  url: Scalars['String'];
};

export type DdpBookmarkProductCardGroup = DdpData & {
  after?: Maybe<Scalars['String']>;
  caption_title?: Maybe<UxCommonText>;
  category_list?: Maybe<Array<DdpCategory>>;
  filter_list?: Maybe<Array<DdpFilterItem>>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpProductCardItem>>;
  more_button?: Maybe<UxCommonButton>;
  sorting_list?: Maybe<Array<DdpFilterItem>>;
  type: DdpComponentType;
};

export type DdpBookmarkProductCardGroupInput = {
  filter_list?: InputMaybe<Array<Scalars['ID']>>;
  selection_id?: InputMaybe<Scalars['ID']>;
  sorting_id?: InputMaybe<Scalars['ID']>;
};

/** 즐겨찾기 스토어 캐러셀 */
export type DdpBookmarkShopCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpBookmarkShopItem>>;
  more_button?: Maybe<UxCommonButton>;
  sorting_list: Array<DdpFilterItem>;
  total_count?: Maybe<Scalars['Int']>;
  type: DdpComponentType;
};

export type DdpBookmarkShopFilterInput = {
  filter_id?: InputMaybe<Scalars['ID']>;
  selection_id?: InputMaybe<Scalars['ID']>;
  sorting_id?: InputMaybe<Scalars['ID']>;
};

export type DdpBookmarkShopItem = {
  has_story?: Maybe<HasStoryResponse>;
  logo: UxCommonImage;
  name: Scalars['String'];
  new_item_count: Scalars['Int'];
  shop_id: Scalars['ID'];
  shop_main_domain: Scalars['String'];
  shop_status?: Maybe<DdpBookmarkShopStatus>;
  tag_list?: Maybe<Array<Scalars['String']>>;
  url: Scalars['String'];
};

export type DdpBookmarkShopList = DdpData & {
  end_cursor: Scalars['String'];
  has_next?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpBookmarkShopItem>>;
  sorting_list: Array<DdpFilterItem>;
  total_count: Scalars['Int'];
  type: DdpComponentType;
};

/** 신상 몰아보기 엔트리 */
export type DdpBookmarkShopNewItemsEntry = DdpData & {
  header?: Maybe<DdpHeader>;
  /** 템플릿 ID */
  id: Scalars['ID'];
  landing_url?: Maybe<Scalars['String']>;
  /** 스토어 리스트 */
  store_list?: Maybe<Array<DdpBookmarkNewItemShop>>;
  /** 신상품 갯수 */
  total_count?: Maybe<Scalars['Int']>;
  type: DdpComponentType;
};

export enum DdpBookmarkShopStatus {
  BAD_WORKING = 'BAD_WORKING',
  DELETION_REQUEST = 'DELETION_REQUEST',
  DORMANCY = 'DORMANCY',
  NORMAL = 'NORMAL',
  NOT_DEFINED = 'NOT_DEFINED',
  RENEWAL = 'RENEWAL',
  STOPPED = 'STOPPED',
}

export type DdpBottomSheet = {
  grid_item_list?: Maybe<Array<DdpIconCategory>>;
  title: UxCommonText;
  top_item_list?: Maybe<Array<DdpIconCategory>>;
};

export type DdpBrandCatalogThumbnailBannerGroup = DdpData & {
  id: Scalars['ID'];
  item?: Maybe<Array<DdpBanner>>;
  title: UxCommonText;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpBrandCoverImage = {
  brand_name: Scalars['String'];
  cover_image_url: Scalars['String'];
  schedule: DdpDateRange;
};

export type DdpBrandEntryMenu = {
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  thumbnail_image_url: Scalars['String'];
  title: Scalars['String'];
};

export type DdpBrandEntryMenuGroup = DdpData & {
  header: DdpHeader;
  id: Scalars['ID'];
  item: Array<DdpBrandEntryMenu>;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpBrandFilterRecommendGroup = DdpData & {
  header: DdpHeader;
  id: Scalars['ID'];
  item: Array<DdpShop>;
  type: DdpComponentType;
};

export type DdpBrandItem = {
  icon?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
  url?: Maybe<Scalars['String']>;
};

export type DdpBrandProductRecommendGroup = DdpData & {
  attribute_id?: Maybe<Scalars['String']>;
  brand_list?: Maybe<Array<DdpBrandItem>>;
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpBrandRankingGroup = DdpData & {
  header: DdpHeader;
  id: Scalars['ID'];
  item?: Maybe<Array<DdpShop>>;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpBrandRecommendGroup = DdpData & {
  filter_list: Array<DdpFilterItem>;
  header: DdpHeader;
  id: Scalars['ID'];
  item?: Maybe<Array<DdpShop>>;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpBrandSaleInput = {
  after?: InputMaybe<Scalars['Int']>;
  category_id?: InputMaybe<Scalars['ID']>;
  filter_list?: InputMaybe<Array<Scalars['ID']>>;
  limitCount?: InputMaybe<Scalars['Int']>;
  sort_type?: InputMaybe<Scalars['String']>;
};

export enum DdpBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type DdpButton = {
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type DdpCalendarItem = {
  /** 타이틀 */
  description: DdpText;
  /** 판매 상태 */
  discount_rate?: Maybe<DdpText>;
  id: Scalars['ID'];
  /** 아이템 ID */
  image: DdpImage;
  /** 상품 배너 이미지 */
  landing_url: Scalars['String'];
  /** 설명글 */
  sale_schedule: DdpDateRange;
  /** 판매 전략 */
  sale_status: DdpSelectedSaleStatus;
  /** 판매 기간 */
  sale_strategy: DdpText;
  /** 랜딩 페이지 url */
  title: DdpText;
  /** 할인 율 텍스트 */
  ubl?: Maybe<UxUbl>;
};

export type DdpCalendarItemGroup = DdpData & {
  button?: Maybe<UxCommonButton>;
  /** 그룹 ID */
  header: DdpHeaderComponent;
  id: Scalars['ID'];
  /** 컴포 넌트 전달 */
  item_list: Array<DdpCalendarItem>;
  type: DdpComponentType;
};

export type DdpCatalogCarousel = {
  column_count?: Maybe<Scalars['Float']>;
  item_list: Array<DdpProductCardItem>;
  more_button?: Maybe<UxCommonButton>;
};

export type DdpCatalogCarouselBasic = DdpData & {
  button?: Maybe<DdpButton>;
  category?: Maybe<Array<DdpCategory>>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  style?: Maybe<Catalog_Carousel_Style>;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpCatalogCarouselBrandDeal = DdpData & {
  brand_list?: Maybe<Array<DdpFilterItem>>;
  carousel: DdpCatalogCarouselSchedule;
  cover_image?: Maybe<DdpBrandCoverImage>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  type: DdpComponentType;
};

export type DdpCatalogCarouselBrandSale = {
  category_list?: Maybe<Array<DdpCategory>>;
  end_cursor: Scalars['Int'];
  filter_list?: Maybe<Array<DdpFilterItem>>;
  has_next: Scalars['Boolean'];
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  sorting_list?: Maybe<Array<DdpFilterItem>>;
  type: DdpComponentType;
};

export type DdpCatalogCarouselBrandTimeDeal = DdpData & {
  band_banner?: Maybe<DdpBandBanner>;
  button?: Maybe<UxCommonButton>;
  carousel?: Maybe<Array<DdpProductCardItemWithSchedule>>;
  expose_notification_button: Scalars['Boolean'];
  header: DdpHeader;
  id: Scalars['ID'];
  schedule: DdpDateRange;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpCatalogCarouselDeal = DdpData & {
  button?: Maybe<DdpButton>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  is_alarm_button: Scalars['Boolean'];
  item: DdpCatalogCarouselSchedule;
  style?: Maybe<Catalog_Carousel_Style>;
  timer: DdpTimer;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpCatalogCarouselIcon = DdpData & {
  header?: Maybe<DdpHeaderComponent>;
  icon_list: Array<DdpCatalogCarouselIconItem>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselIconItem = {
  catalog_product_id: Scalars['ID'];
  thumbnail: DdpImage;
  ubl?: Maybe<UxUbl>;
};

export type DdpCatalogCarouselImage = DdpData & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  banner: DdpBanner;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselImageBrand = DdpData & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  banner: DdpBanner;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  logo_image_url: Scalars['String'];
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpCatalogCarouselImageVertical = DdpData & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  banner: DdpBanner;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselImageVerticalGroup = DdpData & {
  button?: Maybe<DdpButton>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item_list: Array<DdpCatalogCarouselImageVertical>;
  type: DdpComponentType;
};

export type DdpCatalogCarouselMosaic = DdpData & {
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselNew = DdpData & {
  button?: Maybe<DdpButton>;
  category: Array<DdpCategory>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselRanking = DdpData & {
  age_filter_list?: Maybe<Array<DdpFilterItem>>;
  button?: Maybe<DdpButton>;
  category: Array<DdpCategory>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpCatalogCarouselSchedule = {
  column_count?: Maybe<Scalars['Float']>;
  item_list: Array<DdpProductCardItemWithSchedule>;
  more_button?: Maybe<UxCommonButton>;
};

export type DdpCategorizedProduct = {
  button: UxCommonButton;
  category: DdpCategory;
  item_list: Array<DdpProductCardItem>;
};

export type DdpCategorizedProductList = DdpData & {
  category_group_list: Array<Maybe<DdpCategorizedProduct>>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  type: DdpComponentType;
};

export type DdpCategory = {
  category_id?: Maybe<Scalars['ID']>;
  category_key?: Maybe<Scalars['String']>;
  landing_url?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  title: DdpText;
  ubl?: Maybe<UxUbl>;
};

export type DdpCategoryGroup = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpIconCategory>>;
  type: DdpComponentType;
};

export type DdpCategoryList = DdpData & {
  id: Scalars['ID'];
  item_list: Array<DdpCategory>;
  type: DdpComponentType;
};

export type DdpChip = {
  image_url?: Maybe<UxCommonImage>;
  selected?: Maybe<Scalars['Boolean']>;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpChipButtonCarousel = DdpData & {
  category_group_list: Array<Maybe<DdpChipProduct>>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  type: DdpComponentType;
};

export type DdpChipProduct = {
  category: DdpChip;
  id: Scalars['ID'];
  item_list: Array<DdpProductCardItem>;
  ubl?: Maybe<UxUbl>;
};

export type DdpCommonButton = {
  link_url: Scalars['String'];
  text: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export enum DdpComponentType {
  BEAUTY_PRODUCT_RECOMMEND_GROUP = 'BEAUTY_PRODUCT_RECOMMEND_GROUP',
  BETA_TESTER = 'BETA_TESTER',
  BOOKMARK_SHOPS_CAROUSEL = 'BOOKMARK_SHOPS_CAROUSEL',
  BOOKMARK_SHOPS_PRODUCTS_GROUP = 'BOOKMARK_SHOPS_PRODUCTS_GROUP',
  BOOKMARK_SHOP_NEW_ITEMS_ENTRY = 'BOOKMARK_SHOP_NEW_ITEMS_ENTRY',
  BRAND_ENTRY_MENU = 'BRAND_ENTRY_MENU',
  BRAND_FILTER_RECOMMEND_GROUP = 'BRAND_FILTER_RECOMMEND_GROUP',
  BRAND_PRODCT_RECOMMEND_GROUP = 'BRAND_PRODCT_RECOMMEND_GROUP',
  BRAND_RANKING_GROUP = 'BRAND_RANKING_GROUP',
  BRAND_RECOMMEND_GROUP = 'BRAND_RECOMMEND_GROUP',
  BRAND_THEME_GROUP = 'BRAND_THEME_GROUP',
  BRAND_TIME_DEAL = 'BRAND_TIME_DEAL',
  CATALOG_CAROUSEL = 'CATALOG_CAROUSEL',
  CATALOG_CAROUSEL_BASIC_A = 'CATALOG_CAROUSEL_BASIC_A',
  CATALOG_CAROUSEL_BASIC_AUTO = 'CATALOG_CAROUSEL_BASIC_AUTO',
  CATALOG_CAROUSEL_BASIC_B = 'CATALOG_CAROUSEL_BASIC_B',
  CATALOG_CAROUSEL_BASIC_B_AUTO = 'CATALOG_CAROUSEL_BASIC_B_AUTO',
  CATALOG_CAROUSEL_BASIC_C = 'CATALOG_CAROUSEL_BASIC_C',
  CATALOG_CAROUSEL_BRAND_DEAL = 'CATALOG_CAROUSEL_BRAND_DEAL',
  CATALOG_CAROUSEL_BRAND_IMAGE = 'CATALOG_CAROUSEL_BRAND_IMAGE',
  CATALOG_CAROUSEL_BRAND_SALE = 'CATALOG_CAROUSEL_BRAND_SALE',
  CATALOG_CAROUSEL_DAILY_DEAL = 'CATALOG_CAROUSEL_DAILY_DEAL',
  CATALOG_CAROUSEL_GROUP = 'CATALOG_CAROUSEL_GROUP',
  CATALOG_CAROUSEL_ICON = 'CATALOG_CAROUSEL_ICON',
  CATALOG_CAROUSEL_IMAGE = 'CATALOG_CAROUSEL_IMAGE',
  CATALOG_CAROUSEL_IMAGE_VERTICAL = 'CATALOG_CAROUSEL_IMAGE_VERTICAL',
  CATALOG_CAROUSEL_IMAGE_VERTICAL_GROUP = 'CATALOG_CAROUSEL_IMAGE_VERTICAL_GROUP',
  CATALOG_CAROUSEL_IMAGE_VERTICAL_V2 = 'CATALOG_CAROUSEL_IMAGE_VERTICAL_V2',
  CATALOG_CAROUSEL_MOSAIC = 'CATALOG_CAROUSEL_MOSAIC',
  CATALOG_CAROUSEL_NEW = 'CATALOG_CAROUSEL_NEW',
  CATALOG_CAROUSEL_RANKING = 'CATALOG_CAROUSEL_RANKING',
  CATALOG_CAROUSEL_TIME_DEAL = 'CATALOG_CAROUSEL_TIME_DEAL',
  CATALOG_HOME_CAROUSEL_IMAGE_VERTICAL_GROUP = 'CATALOG_HOME_CAROUSEL_IMAGE_VERTICAL_GROUP',
  CATALOG_HOME_CAROUSEL_IMAGE_VERTICAL_GROUP_BOTTOM = 'CATALOG_HOME_CAROUSEL_IMAGE_VERTICAL_GROUP_BOTTOM',
  CATALOG_HOME_CAROUSEL_TIME_DEAL = 'CATALOG_HOME_CAROUSEL_TIME_DEAL',
  CATALOG_MDS_PICK_GROUP = 'CATALOG_MDS_PICK_GROUP',
  CATALOG_STORE_COLLECTION_CAROUSEL = 'CATALOG_STORE_COLLECTION_CAROUSEL',
  CATALOG_THUMBNAIL_BANNER_GROUP = 'CATALOG_THUMBNAIL_BANNER_GROUP',
  CATEGORY_GROUP = 'CATEGORY_GROUP',
  CHIP_BUTTON_CAROUSEL = 'CHIP_BUTTON_CAROUSEL',
  DDP_CALENDAR_CATALOG = 'DDP_CALENDAR_CATALOG',
  DEPARTMENT_ENTRY_MENU = 'DEPARTMENT_ENTRY_MENU',
  DISCOVERY_MENU = 'DISCOVERY_MENU',
  EPICK_CAROUSEL = 'EPICK_CAROUSEL',
  GRID_CATEGORY = 'GRID_CATEGORY',
  HOME_QUICK_MENU = 'HOME_QUICK_MENU',
  HOURLY_PRODUCT_BEST_GROUP = 'HOURLY_PRODUCT_BEST_GROUP',
  ICON_CATEGORY = 'ICON_CATEGORY',
  IMAGE_CAROUSEL = 'IMAGE_CAROUSEL',
  LINE_BANNER = 'LINE_BANNER',
  MAGAZINE_CARD_GROUP = 'MAGAZINE_CARD_GROUP',
  MAGAZINE_CARD_ITEM = 'MAGAZINE_CARD_ITEM',
  MAGAZINE_CAROUSEL = 'MAGAZINE_CAROUSEL',
  MAIN_HOME_RECOMMEND_STORE_GROUP = 'MAIN_HOME_RECOMMEND_STORE_GROUP',
  ONBOARDING = 'ONBOARDING',
  PRODUCT_CARD_GROUP = 'PRODUCT_CARD_GROUP',
  PRODUCT_RANKING_GROUP = 'PRODUCT_RANKING_GROUP',
  PRODUCT_RECOMMEND_GROUP = 'PRODUCT_RECOMMEND_GROUP',
  PROMOTION_FLOATING_BANNER = 'PROMOTION_FLOATING_BANNER',
  PROMOTION_TC = 'PROMOTION_TC',
  QUICK_MENU = 'QUICK_MENU',
  QUICK_MENU_BANNER_LIST = 'QUICK_MENU_BANNER_LIST',
  QUICK_MENU_BOTTOM_SHEET = 'QUICK_MENU_BOTTOM_SHEET',
  QUICK_MENU_CIRCLE = 'QUICK_MENU_CIRCLE',
  QUICK_MENU_CIRCLE_B = 'QUICK_MENU_CIRCLE_B',
  QUICK_MENU_CIRCLE_BASIC = 'QUICK_MENU_CIRCLE_BASIC',
  QUICK_MENU_CIRCLE_BRANDFASHION = 'QUICK_MENU_CIRCLE_BRANDFASHION',
  QUICK_MENU_GRID = 'QUICK_MENU_GRID',
  QUICK_MENU_GRID_2_X3 = 'QUICK_MENU_GRID_2X3',
  QUICK_MENU_GRID_CIRCLE = 'QUICK_MENU_GRID_CIRCLE',
  RANKING_RECOMMEND_SHOP_CAROUSEL = 'RANKING_RECOMMEND_SHOP_CAROUSEL',
  RECOMMEND_SHOP_CAROUSEL = 'RECOMMEND_SHOP_CAROUSEL',
  RECOMMEND_SHOP_LIST = 'RECOMMEND_SHOP_LIST',
  RECOMMEND_STYLE_ITEM_TAG_SHOP = 'RECOMMEND_STYLE_ITEM_TAG_SHOP',
  RECOMMEND_TPO_ITEM_TAG_SHOP = 'RECOMMEND_TPO_ITEM_TAG_SHOP',
  ROLLING_BAND_BANNER = 'ROLLING_BAND_BANNER',
  ROLLING_IMAGE_BANNER = 'ROLLING_IMAGE_BANNER',
  ROLLING_ITEMS_ENTRY = 'ROLLING_ITEMS_ENTRY',
  SEARCH = 'SEARCH',
  SELECTED_BRAND = 'SELECTED_BRAND',
  SELECTED_PRODUCT_CARD_GROUP = 'SELECTED_PRODUCT_CARD_GROUP',
  SELECTED_PRODUCT_RECOMMEND_GROUP = 'SELECTED_PRODUCT_RECOMMEND_GROUP',
  SELECTED_ROLLING_BANNER = 'SELECTED_ROLLING_BANNER',
  SELECTION_AND_SORTING = 'SELECTION_AND_SORTING',
  SHORT_FORM_GROUP = 'SHORT_FORM_GROUP',
  SHORT_FORM_ITEM = 'SHORT_FORM_ITEM',
  SINGLE_BANNER = 'SINGLE_BANNER',
  SNACK_TREND_CARD_GROUP = 'SNACK_TREND_CARD_GROUP',
  SNACK_TREND_ITEM = 'SNACK_TREND_ITEM',
  SPECIAL_EXHIBITION = 'SPECIAL_EXHIBITION',
  STICKY_CATEGORY = 'STICKY_CATEGORY',
  STICKY_PRODUCT_CARD_GROUP = 'STICKY_PRODUCT_CARD_GROUP',
  STICKY_ROLLING_IMAGE_BANNER = 'STICKY_ROLLING_IMAGE_BANNER',
  STORE_COLLECTION_DISPLAY_CAROUSEL = 'STORE_COLLECTION_DISPLAY_CAROUSEL',
  STORY_SHOP_INFO_CAROUSEL = 'STORY_SHOP_INFO_CAROUSEL',
  STORY_SHOP_INFO_CAROUSEL_EMPTY = 'STORY_SHOP_INFO_CAROUSEL_EMPTY',
  STYLING_CARD_GROUP = 'STYLING_CARD_GROUP',
  STYLING_CARD_ITEM = 'STYLING_CARD_ITEM',
  ZIGZIN_PRODUCT_RECOMMEND_GROUP = 'ZIGZIN_PRODUCT_RECOMMEND_GROUP',
  ZIGZIN_RECOMMEND_CAROUSEL = 'ZIGZIN_RECOMMEND_CAROUSEL',
}

export type DdpData = {
  id: Scalars['ID'];
  type: DdpComponentType;
};

export type DdpDateRange = {
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
};

export enum DdpDeleteAction {
  FORCE_REMOVE = 'FORCE_REMOVE',
  NONE = 'NONE',
  POPUP = 'POPUP',
}

export type DdpDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DdpDiscoveryMenuGroup = DdpData & {
  id: Scalars['ID'];
  item_list: Array<DdpDiscoveryMenuGroupItem>;
  type: DdpComponentType;
};

export type DdpDiscoveryMenuGroupItem = {
  image: UxCommonImage;
  landing_url: Scalars['String'];
  lottie_image?: Maybe<UxCommonImageUrl>;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpEntryMenu = {
  id: Scalars['ID'];
  item_list?: Maybe<Array<UxCommonButton>>;
  type: Scalars['String'];
};

export type DdpEpickCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpEpickCarouselItem>>;
  type: DdpComponentType;
};

export type DdpEpickCarouselItem = {
  id: Scalars['ID'];
  image: UxCommonImage;
  landing_url: Scalars['String'];
  nickname: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpFilterInput = {
  filter_item_id?: InputMaybe<Scalars['ID']>;
  filter_type: DdpListFilterType;
};

export type DdpFilterItem = {
  icon?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
};

export type DdpFollowBookmarkGroup = {
  component_list: Array<Maybe<DdpData>>;
};

export type DdpFollowBookmarkGroupInput = {
  /** 북마크 캐러셀 정렬 필터 filter */
  bookmark_filter?: InputMaybe<DdpBookmarkShopFilterInput>;
  /** 상품 모아보기 input */
  bookmark_product_input?: InputMaybe<DdpBookmarkProductCardGroupInput>;
  /** 추천스토어 기반 shop id 리스트 */
  shop_id_list: Array<Scalars['ID']>;
};

export type DdpGridCategory = DdpData & {
  id: Scalars['ID'];
  item_list: Array<DdpCategory>;
  type: DdpComponentType;
};

export type DdpHeader = {
  button?: Maybe<UxCommonButton>;
  image?: Maybe<UxCommonImage>;
  subtitle?: Maybe<UxCommonText>;
  title: UxCommonText;
};

export type DdpHeaderComponent = {
  button?: Maybe<DdpButton>;
  image?: Maybe<DdpImage>;
  subtitle?: Maybe<DdpText>;
  title: DdpText;
};

export type DdpHomeQuickMenu = {
  id: Scalars['ID'];
  is_animated: Scalars['Boolean'];
  menu_items?: Maybe<Array<DdpHomeQuickMenuItem>>;
  sub_items?: Maybe<Array<DdpHomeQuickMenuItem>>;
  type: DdpComponentType;
};

export type DdpHomeQuickMenuItem = {
  dot_info?: Maybe<UxOneOffNotificationInfo>;
  id: Scalars['ID'];
  image_url: UxCommonImage;
  landing_url?: Maybe<Scalars['String']>;
  name: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpHourlyProductBestGroup = DdpData & {
  filter_list: Array<DdpFilterItem>;
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpIconCategory = {
  icon: UxCommonImage;
  key?: Maybe<Scalars['String']>;
  landing_url: Scalars['String'];
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpIconCategoryList = DdpData & {
  id: Scalars['ID'];
  item_list: Array<DdpIconCategory>;
  type: DdpComponentType;
};

export type DdpIconItem = {
  emoji?: Maybe<Scalars['String']>;
  image?: Maybe<UxCommonImage>;
};

export type DdpIconText = {
  id: Scalars['ID'];
  thumbnail: UxCommonImage;
  title?: Maybe<UxCommonText>;
};

export type DdpImage = {
  url: Scalars['String'];
  webp_url?: Maybe<Scalars['String']>;
};

export type DdpImageCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<DdpImageCarouselItem>;
  type: DdpComponentType;
};

export type DdpImageCarouselItem = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type DdpItemListInfo = {
  after?: Maybe<Scalars['String']>;
  item_list: Array<DdpData>;
};

export type DdpItemTagRecommendShop = {
  item_list: Array<DdpProductCardItem>;
  logo_url: Scalars['String'];
  more_button?: Maybe<UxCommonButton>;
  name: Scalars['String'];
  shop_id: Scalars['ID'];
  shop_main_domain: Scalars['String'];
  url: Scalars['String'];
};

export type DdpItemTagRecommendShopList = {
  item_list: Array<DdpItemTagRecommendShop>;
};

export type DdpLayout = {
  margin?: Maybe<DdpSpace>;
};

export type DdpLineBanner = DdpData & {
  bg_color?: Maybe<UxCommonColor>;
  id: Scalars['ID'];
  image: UxCommonImage;
  landing_url?: Maybe<Scalars['String']>;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpListFilter = {
  filters: Array<DdpListFilterItem>;
};

export type DdpListFilterItem = {
  id?: Maybe<Scalars['ID']>;
  selected?: Maybe<Scalars['Boolean']>;
  title?: Maybe<UxCommonText>;
  ubl?: Maybe<UxUbl>;
};

export enum DdpListFilterType {
  MAGAZINE = 'MAGAZINE',
  SNACK_TREND = 'SNACK_TREND',
  STYLING = 'STYLING',
}

export type DdpMagazineCardGroup = DdpData & {
  after?: Maybe<Scalars['String']>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<DdpMagazineCardGroupItem>;
  type: DdpComponentType;
};

export type DdpMagazineCardGroupItem = {
  content_type: DdpMagazineContentType;
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  magazine_thumbnail: DdpMagazineThumbnail;
  subtitle: UxCommonText;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpMagazineCardItem = DdpData & {
  content_type: DdpMagazineContentType;
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  magazine_thumbnail: DdpMagazineThumbnail;
  subtitle: UxCommonText;
  title: UxCommonText;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpMagazineCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpMagazineCardGroupItem>>;
  type: DdpComponentType;
};

export type DdpMagazineContentType = {
  name: UxCommonText;
  value: Scalars['String'];
};

export type DdpMagazineMainThumbnail = {
  image: UxCommonImage;
  ratio: UxCommonImageRatio;
};

export type DdpMagazineThumbnail = {
  component_thumbnail: UxCommonImage;
  discover_thumbnail: UxCommonImage;
  main_thumbnail: DdpMagazineMainThumbnail;
};

export type DdpMyProductCardList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<DdpProductCardItem>>;
};

export type DdpOnboarding = DdpData & {
  button: DdpButton;
  icon_text_list: Array<DdpIconText>;
  id: Scalars['ID'];
  main_title: UxCommonText;
  sub_title: UxCommonText;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpPageInfo = {
  id: Scalars['ID'];
  page_name: Scalars['String'];
  page_type: Scalars['String'];
};

export enum DdpPaymentType {
  EXTERNAL = 'EXTERNAL',
  ZPAY = 'ZPAY',
}

export type DdpProduct = {
  catalog_product_id: Scalars['ID'];
  external_product_id?: Maybe<Scalars['ID']>;
  image_url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  payment_type: DdpPaymentType;
  sales_status: DdpSalesStatus;
  shipping_fee_type: DdpShippingFeeType;
  shop_id: Scalars['ID'];
  title_line_number?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type DdpProductCardGroup = DdpData & {
  after?: Maybe<Scalars['String']>;
  caption_title?: Maybe<UxCommonText>;
  category_list: Array<DdpCategory>;
  filter_list: Array<DdpFilterItem>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item_list: Array<DdpProductCardItem>;
  sorting_list: Array<DdpFilterItem>;
  type: DdpComponentType;
};

export type DdpProductCardGroupFilterInput = {
  filter_id?: InputMaybe<Scalars['ID']>;
  selection_id?: InputMaybe<Scalars['ID']>;
  sorting_id?: InputMaybe<Scalars['ID']>;
  sub_selection_id?: InputMaybe<Scalars['ID']>;
};

export type DdpProductCardItem = {
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  browsing_type: DdpBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  discount_info?: Maybe<DdpDiscountInfo>;
  discount_rate: Scalars['Int'];
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  is_brand: Scalars['Boolean'];
  is_display_not_zpay: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  is_saved_product: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<UxCatalogProductCategory>>;
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  /** 가로형 상품카드 메타데이터 영역용 엠블렘 */
  metadata_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 당일 배송 */
  one_day_delivery?: Maybe<UxCommonText>;
  product: DdpProduct;
  ranking?: Maybe<Scalars['Int']>;
  review_summary?: Maybe<ReviewSummary>;
  sellable_status: UxSellableStatus;
  shop: DdpShop;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  ubl?: Maybe<UxUbl>;
  viewed_product_badge?: Maybe<ViewedProductBadge>;
};

export type DdpProductCardItemWithSchedule = {
  item: DdpProductCardItem;
  product_status_description?: Maybe<DdpProductStatusDescription>;
  sale_status?: Maybe<ProductSalesStatus>;
  schedule: DdpDateRange;
};

export type DdpProductRankingGroup = DdpData & {
  button?: Maybe<DdpButton>;
  category_list: Array<DdpCategory>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  type: DdpComponentType;
};

export type DdpProductRankingListGroup = {
  after?: Maybe<Scalars['String']>;
  category_list: Array<DdpCategory>;
  header?: Maybe<DdpHeaderComponent>;
  item: DdpCatalogCarousel;
  sub_category_list: Array<DdpCategory>;
  total_count?: Maybe<Scalars['Int']>;
};

export type DdpProductRecommendGroup = DdpData & {
  ddp_product_recommend_group_style: DdpProductRecommendGroupStyle;
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  refresh_button?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

/** 상품 상태 설명 */
export type DdpProductStatusDescription = {
  /** 딤위에 가로 상품카드 노출 여부 */
  should_show_product: Scalars['Boolean'];
  subtitle: DdpText;
  title: DdpText;
};

export type DdpPromotionFloatingBanner = {
  background_color: UxCommonColor;
  button: UxCommonButton;
  icon?: Maybe<UxCommonImageUrl>;
  id: Scalars['ID'];
  is_lottie_icon: Scalars['Boolean'];
  key_color: UxCommonColor;
  landing_url: Scalars['String'];
  nudge: PromotionFloatingBannerNudge;
  title: UxCommonText;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpQuickMenu = DdpData & {
  display_item_count?: Maybe<Scalars['Float']>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  is_animated?: Maybe<Scalars['Boolean']>;
  item_list: Array<DdpQuickMenuItem>;
  type: DdpComponentType;
};

export type DdpQuickMenuItem = {
  id: Scalars['ID'];
  image: DdpImage;
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  subtitle?: Maybe<DdpText>;
  title: DdpText;
  ubl?: Maybe<UxUbl>;
};

export type DdpRankingFilterItem = {
  icon?: Maybe<DdpIconItem>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
};

export type DdpRankingRecommendShop = {
  has_bookmark: Scalars['Boolean'];
  logo: UxCommonImage;
  product_list: Array<DdpRecommendProduct>;
  shop: UxShopCardItem;
  sub_title?: Maybe<UxCommonText>;
};

export type DdpRankingRecommendedShopCarousel = DdpData & {
  filter_list: Array<DdpRankingFilterItem>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<DdpRankingRecommendShop>;
  type: DdpComponentType;
};

export type DdpRecommendItemTagShop = DdpData & {
  filters: Array<DdpListFilterItem>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  type: DdpComponentType;
};

export type DdpRecommendProduct = {
  browsing_type: UxBrowsingType;
  external_code?: Maybe<Scalars['ID']>;
  image_url: Scalars['String'];
  product_id: Scalars['ID'];
  shop_id: Scalars['ID'];
  url: Scalars['String'];
};

export type DdpRecommendShopCard = {
  background_color: UxCommonColor;
  category?: Maybe<Scalars['String']>;
  logo: UxCommonImage;
  product_list: Array<DdpRecommendProduct>;
  shop: UxShopCardItem;
  style_list: Array<Maybe<DdpShopStyle>>;
};

/** 추천 스토어 캐러셀 */
export type DdpRecommendShopCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpRecommendShopCard>>;
  total_count?: Maybe<Scalars['Int']>;
  type: DdpComponentType;
};

/** 추천 스토어 리스트 */
export type DdpRecommendShopList = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpRecommendShopCard>>;
  total_count?: Maybe<Scalars['Int']>;
  type: DdpComponentType;
};

export type DdpRollingBandBanner = DdpData & {
  id: Scalars['ID'];
  item_list: Array<DdpBandBanner>;
  type: DdpComponentType;
};

export type DdpRollingImageBanner = DdpData & {
  aspect_ratio: Scalars['Float'];
  id: Scalars['ID'];
  item_list: Array<DdpBanner>;
  show_button?: Maybe<Scalars['Boolean']>;
  type: DdpComponentType;
};

export type DdpRollingItemsEntry = DdpData & {
  bg_image?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  label: UxCommonImage;
  landing_url?: Maybe<Scalars['String']>;
  main_title: UxCommonText;
  sub_title?: Maybe<UxCommonText>;
  thumbnail_list: Array<UxCommonImage>;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export enum DdpSalesStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENDED = 'SUSPENDED',
}

export type DdpSearch = DdpData & {
  category_id?: Maybe<Scalars['ID']>;
  ddp_type?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  page_id: Scalars['String'];
  search_placeholder: DdpText;
  type: DdpComponentType;
};

export type DdpSelectedProductCardGroup = DdpData & {
  after?: Maybe<Scalars['String']>;
  caption_title?: Maybe<UxCommonText>;
  category_list: Array<DdpCategory>;
  filter_list: Array<DdpFilterItem>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  item_list: Array<DdpProductCardItem>;
  sorting_list: Array<DdpFilterItem>;
  sub_category_list: Array<DdpCategory>;
  total_count: Scalars['Int'];
  type: DdpComponentType;
};

export enum DdpSelectedSaleStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARED = 'PREPARED',
}

export enum DdpShippingFeeType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  SHOP_POLICY = 'SHOP_POLICY',
}

export type DdpShop = {
  age_list?: Maybe<Array<Scalars['String']>>;
  bookmark_count?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  customer_center_tel?: Maybe<Scalars['String']>;
  delete_action: DdpDeleteAction;
  id: Scalars['ID'];
  is_free_shipping: Scalars['Boolean'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  shop_main_domain: Scalars['String'];
  status: DdpShopStatus;
  style_list?: Maybe<Array<DdpShopStyle>>;
  url: Scalars['String'];
  zpay_status: DdpShopZpayStatus;
};

export type DdpShopRanking = {
  item_list: Array<DdpProductCardItem>;
  shop: DdpShop;
};

export type DdpShopRankingInfo = DdpData & {
  ddp_order_list: Array<DdpFilterItem>;
  ddp_shop_ranking_list: Array<DdpShopRanking>;
  header?: Maybe<DdpHeaderComponent>;
  id: Scalars['ID'];
  type: DdpComponentType;
};

export enum DdpShopStatus {
  BAD_WORKING = 'BAD_WORKING',
  DELETION_REQUEST = 'DELETION_REQUEST',
  DORMANCY = 'DORMANCY',
  NORMAL = 'NORMAL',
  NOT_DEFINED = 'NOT_DEFINED',
  RENEWAL = 'RENEWAL',
  STOPPED = 'STOPPED',
}

export type DdpShopStyle = {
  color: Scalars['String'];
  value: Scalars['String'];
};

export enum DdpShopZpayStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

/** 숏폼 리스트 그룹 */
export type DdpShortFormGroup = DdpData & {
  /** 헤더 영역 */
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  /** 아이템별 세로/가로 비율 */
  item_aspect_ratio: Scalars['Float'];
  /** 화면 너비에 들어가는 아이템 개수 */
  item_column_count?: Maybe<Scalars['Float']>;
  /** 아이템 리스트 */
  item_list?: Maybe<Array<DdpShortFormItem>>;
  type: DdpComponentType;
  update_interval?: Maybe<Scalars['Int']>;
};

/** 숏폼 아이템 정보 */
export type DdpShortFormItem = DdpData & {
  id: Scalars['ID'];
  /** 숏폼에 매칭된 상품 리스트 */
  mapping_item_list?: Maybe<Array<DdpProductCardItem>>;
  /** 썸네일 이미지 URL */
  thumbnail_image_url: Scalars['String'];
  /** 동영상 제목 */
  title: Scalars['String'];
  type: DdpComponentType;
  /** 로그 */
  ubl?: Maybe<UxUbl>;
  /** 동영상 URL */
  video_url: Scalars['String'];
};

export type DdpSingleBanner = DdpData & {
  id: Scalars['ID'];
  item: DdpBanner;
  type: DdpComponentType;
};

export type DdpSnackTrendBody = {
  /** 본문 이미지 설명 */
  description: UxCommonText;
  /** 본문 이미지 */
  image: UxCommonImage;
  /** 본문 이미지에 연결된 상품 ID */
  product_id_list?: Maybe<Array<Scalars['ID']>>;
  /** 본문 이미지 타이틀 */
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpSnackTrendBodyBottomSheet = {
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
  wearing_product_list?: Maybe<Array<DdpProductCardItem>>;
};

/** 스낵 트랜드 카드 그룹 */
export type DdpSnackTrendCardGroup = DdpData & {
  /** 태그 필터 리스트 */
  filter_list?: Maybe<Array<DdpListFilterItem>>;
  /** 헤더 영역 */
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  /** 아이템 리스트 */
  item_list?: Maybe<Array<DdpSnackTrendItem>>;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpSnackTrendCover = {
  /** 커버 이미지 */
  image: UxCommonImage;
  /** 스타일링 숏컷 이미지 */
  styling_shortcut_image_url: UxCommonImage;
  /** 이미지 타이틀 첫번째 라인 */
  title_first: UxCommonText;
  /** 이미지 타이틀 두번째 라인 */
  title_second?: Maybe<UxCommonText>;
  ubl?: Maybe<UxUbl>;
};

/** 스낵 트랜드 */
export type DdpSnackTrendItem = DdpData & {
  /** 스낵 트랜드 바디 영역 */
  body_list?: Maybe<Array<DdpSnackTrendBody>>;
  /** 스낵 트랜드 커버 영역 */
  cover: DdpSnackTrendCover;
  id: Scalars['ID'];
  /** 스낵 트랜드 스타일링 영역 */
  styling_list?: Maybe<Array<DdpSnackTrendStyling>>;
  /** 스낵 트랜드 타이틀 */
  title: UxCommonText;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpSnackTrendStyling = {
  /** 스타일링 이미지 */
  image: UxCommonImage;
  /** 스타일링 */
  styling?: Maybe<DdpStylingCardItem>;
  /** 스타일링 ID */
  styling_id: Scalars['ID'];
  /** 스타일링 설명 */
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpSpace = {
  bottom: Scalars['Float'];
  left: Scalars['Float'];
  right: Scalars['Float'];
  top: Scalars['Float'];
};

export type DdpSpecialExhibition = {
  image_url: UxCommonImage;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  sub_title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpSpecialExhibitionCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpSpecialExhibition>>;
  type: DdpComponentType;
};

export type DdpStoreCollection = {
  item_list: Array<DdpProductCardItem>;
  more_button?: Maybe<UxCommonButton>;
};

/** 4.0 모자이크 캐러셀 리턴 타입(ddp_catalog_store_collection_carousel) */
export type DdpStoreCollectionCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  store_list: Array<DdpStoreCollection>;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpStoreCollectionDetail = {
  product_list: Array<DdpProductCardItem>;
};

export type DdpStoreCollectionDisplay = {
  display_type: StoreCollectionDisplayType;
  id: Scalars['ID'];
  image_url_list: Array<UxCommonImage>;
  landing_url?: Maybe<Scalars['String']>;
  shop_collection: DdpStoreCollectionDetail;
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
  store_title: UxCommonText;
  sub_title?: Maybe<UxCommonText>;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type DdpStoreCollectionDisplayCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list?: Maybe<Array<DdpStoreCollectionDisplay>>;
  total_count?: Maybe<Scalars['Int']>;
  type: DdpComponentType;
};

export type DdpStoryShopInfoCarousel = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  is_author: Scalars['Boolean'];
  shop_info?: Maybe<Array<StoryShopInfo>>;
  story_icon?: Maybe<Array<StoryIcon>>;
  type: DdpComponentType;
};

export type DdpStylingCardGroup = DdpData & {
  filter_list?: Maybe<Array<DdpListFilterItem>>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<DdpStylingCardItem>;
  partition_size: Scalars['Int'];
  refresh_button: UxCommonButton;
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpStylingCardItem = DdpData & {
  id: Scalars['ID'];
  is_saved_styling: Scalars['Boolean'];
  product_tags: Array<DdpStylingProductTag>;
  shop_id?: Maybe<Scalars['ID']>;
  store_type: StylingStoreType;
  style_tag: DdpStylingStyleTag;
  thumbnail_ratio: UxCommonImageRatio;
  thumbnail_url: Scalars['String'];
  type: DdpComponentType;
  ubl?: Maybe<UxUbl>;
};

export type DdpStylingProductTag = {
  coordinate_x: Scalars['Float'];
  coordinate_y: Scalars['Float'];
  item: DdpProductCardItem;
  product_id: Scalars['ID'];
  ubl?: Maybe<UxUbl>;
};

export type DdpStylingStyleTag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DdpTemplate = {
  id: Scalars['ID'];
  layout?: Maybe<DdpLayout>;
  type: DdpComponentType;
};

export type DdpText = {
  color?: Maybe<Scalars['String']>;
  is_html?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

export type DdpTimer = {
  /** 타이머 잔여 시간 */
  due_date?: Maybe<Scalars['CrTimestamp']>;
  status: DdpTimerStatus;
  /** ubl */
  ubl?: Maybe<UxUbl>;
};

/** DdpTimer 상태 */
export enum DdpTimerStatus {
  /** 활성화 */
  ACTIVATED = 'ACTIVATED',
  /** 만료됨 */
  EXPIRED = 'EXPIRED',
}

export type DdpZigzinProductRecommendGroup = DdpData & {
  filter_list: Array<DdpFilterItem>;
  header: DdpHeader;
  id: Scalars['ID'];
  item: DdpCatalogCarousel;
  landing_url?: Maybe<UxCommonButton>;
  type: DdpComponentType;
};

export type DdpZigzinRecommendProduct = DdpData & {
  category: Array<DdpCategory>;
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  item_list: Array<DdpProductCardItem>;
  type: DdpComponentType;
};

export type DecimalUnitNumber = {
  decimal: Scalars['Int'];
  display_unit: Scalars['String'];
  is_unit_prefix: Scalars['Boolean'];
  number_without_decimal: Scalars['Int'];
  unit: Scalars['String'];
};

export type DecimalUnitNumberInput = {
  decimal: Scalars['Int'];
  display_unit: Scalars['String'];
  is_unit_prefix: Scalars['Boolean'];
  number_without_decimal: Scalars['Int'];
  unit: Scalars['String'];
};

export type DefaultAddressGuide = {
  /** 배송지 등록 앞에 들어갈 보조 텍스트 */
  pre_text?: Maybe<Scalars['String']>;
  /** 배송지 등록 텍스트 */
  register_default_address_text: Scalars['String'];
  /** 배송지 등록 링크 */
  register_default_address_url: Scalars['String'];
};

/** 장바구니 삭제할 아이템의 입력 */
export type DeleteCartItemListInput = {
  /** CartItem 식별자 리스트 */
  cart_item_identifier_list: Array<CartItemIdentifierInput>;
};

/** 즐겨찾기 지역 삭제 input */
export type DeleteDailyMissionLikedRegionInput = {
  /** 즐겨찾기한 지역 id */
  daily_mission_liked_region_id: Scalars['ID'];
};

export type DeleteEventContentReplyInput = {
  /** 이벤트 댓글 메타데이터 uuid */
  event_content_reply_metadata_uuid: Scalars['String'];
  /** 이벤트 댓글 ID */
  reply_id: Scalars['ID'];
};

/** deleteOrder 의 입력 */
export type DeleteOrderInput = {
  /** 주문 번호 */
  order_number: Scalars['String'];
};

/** 주문 상품 문의 레코드 삭제 입력 */
export type DeleteOrderItemInquiryInput = {
  /** 주문 상품 문의 또는 댓글 레코드 id */
  id: Scalars['ID'];
};

/** DeleteProductReviewReply 의 입력 */
export type DeleteProductReviewUserReplyInput = {
  /** 상품리뷰 댓글 ID */
  id: Scalars['ID'];
};

/** deleteUserAccount의 입력 */
export type DeleteUserAccountInput = {
  /** 탈퇴 사유 */
  reason: Scalars['String'];
};

export type DeleteUserAccountProfileInput = {
  /** 유저 프로필 레코드 ID */
  id: Scalars['ID'];
};

/** 삭제 예정 계정 정보 */
export type DeleteUserAccountReservation = {
  /** 계정 삭제 예정 일자 */
  date_scheduled_ymd: Scalars['Int'];
};

/** deleteUserAccount의 출력 */
export type DeleteUserAccountResult = {
  /** 성공여부 */
  success: Scalars['Boolean'];
};

export type DeleteUserAccountShopNotificationListInput = {
  /** 스토어 ID 목록 */
  shop_id_list: Array<Scalars['ID']>;
};

export type DeleteUserNotificationInput = {
  /** 알림 ID */
  id: Array<Scalars['ID']>;
};

/** 배송 주소록 삭제의 입력 */
export type DeleteUserShippingAddressBookInput = {
  /** 주소록 아이디 */
  id: Scalars['ID'];
};

/** 입금 처리 환불 내역 */
export type DepositRefund = {
  /** 환불 진행 상태 */
  status: DepositRefundStatus;
};

/** 환불 진행 상태 */
export enum DepositRefundStatus {
  CANCELLING = 'CANCELLING',
  COMPLETED_BY_MANUAL = 'COMPLETED_BY_MANUAL',
  COMPLETED_BY_PG = 'COMPLETED_BY_PG',
  REFUNDED = 'REFUNDED',
  REFUND_ACCOUNT_CHANGE_WAITING = 'REFUND_ACCOUNT_CHANGE_WAITING',
  WAITING = 'WAITING',
}

/** DeregisterSimpleBank 입력 */
export type DeregisterSimpleBankInput = {
  /** 계좌 간편 ID */
  simple_bank_uuid: Scalars['ID'];
};

export type DisappearingBenefit = {
  /** 포인트 보유 수량 (전체 사이트) */
  amount_of_points: Scalars['Int'];
  /** 쿠폰 보유 수량 (전체 사이트) */
  count_of_coupons: Scalars['Int'];
  /** 찜한 상품 개수 (전체 사이트) */
  count_of_saved_products: Scalars['Int'];
  /** 즐겨찾기 개수 (전체 사이트) */
  count_of_saved_shops: Scalars['Int'];
  /** 화면표기 사용자 이름 */
  display_name?: Maybe<Scalars['String']>;
};

export type DiscountedSavedProduct = {
  discount_info?: Maybe<DrDiscountInfo>;
  discount_rate: Scalars['Int'];
  discounted_price: Scalars['Int'];
  display_discounted_price: DrText;
  final_price: Scalars['Int'];
  max_price: Scalars['Int'];
  product: DrProduct;
  sellable_status: UxSellableStatus;
  shop: DrShop;
};

export enum DisplayCurrencyType {
  OFFICIAL = 'OFFICIAL',
  SIMPLE = 'SIMPLE',
}

/** 카테고리 목록 */
export type DisplayManagedCategory = {
  /** asset 목록 */
  asset_list?: Maybe<Array<CategoryAsset>>;
  /** 자식 카테고리 목록 */
  children?: Maybe<Array<DisplayManagedCategory>>;
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
  /** 동일 depth에서의 순서 */
  sibling_order: Scalars['Int'];
  /** values */
  values?: Maybe<Scalars['CrJson']>;
};

export type DisplayPdpBanner = {
  aspect_ratio: Scalars['Float'];
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
};

export type DisplayPdpCategory = {
  doc_count: Scalars['Int'];
  key: Scalars['String'];
};

export type DisplayPdpRollingImageBanner = {
  id: Scalars['ID'];
  item_list: Array<DisplayPdpBanner>;
};

export type DisplayPdpSearchedShop = {
  age_list: Array<Scalars['String']>;
  alias_list?: Maybe<Array<Scalars['String']>>;
  bookmark_count: Scalars['Int'];
  bookmarked: Scalars['Boolean'];
  brand?: Maybe<PdpSearchedProductBrand>;
  brand_home_brand_id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_list: Array<DisplayPdpCategory>;
  delete_action: Scalars['Int'];
  department_type?: Maybe<Scalars['String']>;
  display_category_with_most_products?: Maybe<Scalars['String']>;
  event_list: Array<PdpEvent>;
  is_brand: Scalars['Boolean'];
  is_free_shipping: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay: Scalars['Boolean'];
  keyword_list: Array<Maybe<Scalars['String']>>;
  keywords: Scalars['String'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  popular_goods_list: Array<PdpPopularGoods>;
  shop_id: Scalars['ID'];
  state: Scalars['Int'];
  style_list: Array<PdpShopStyle>;
  typical_image_url: Scalars['String'];
  url: Scalars['String'];
};

export type DisplayProductDetailPage = {
  recommended_item_group_list: Array<PdpRecommendedItemGroup>;
  ux_goods: PdpGoods;
  ux_product: PdpProduct;
  ux_shop: PdpShop;
};

/** 정책 노출 영역 타입 */
export enum DisplayType {
  INHOUSE = 'INHOUSE',
  PERSONALIZE = 'PERSONALIZE',
}

export type DrAvailableWithCouponRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 쿠폰 목록 */
  coupon_list: Array<DrCoupon>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export enum DrBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type DrCategory = {
  category_id?: Maybe<Scalars['ID']>;
  title: DrText;
};

export type DrCategoryRecommendationComponent = DrComponent & {
  /** 카테고리 목록 */
  category_list: Array<DrCategory>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 상품카드 목록 */
  item_list: Array<DrProductCardItem>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
  /** 변경 시각 */
  updated_at: DrUpdatedTime;
};

export type DrCategoryRecommendationItemList = {
  /** 상품카드 목록 */
  item_list: Array<DrProductCardItem>;
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrComponent = {
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export enum DrComponentType {
  /** 쿠폰으로 구매 가능한 상품 추천 */
  AVAILABLE_WITH_COUPON = 'AVAILABLE_WITH_COUPON',
  /** 카테고리 내 상품 추천 */
  CATEGORY = 'CATEGORY',
  /** 가격 인하 찜 상품의 유사 상품 추천 */
  DISCOUNTED_SAVED_PRODUCT = 'DISCOUNTED_SAVED_PRODUCT',
  /** 최근 구매 상품의 연관 상품 추천 */
  RECENTLY_PURCHASED_PRODUCT = 'RECENTLY_PURCHASED_PRODUCT',
  /** 최근 검색한 키워드 상품 추천 */
  RECENTLY_SEARCHED_KEYWORD = 'RECENTLY_SEARCHED_KEYWORD',
  /** 최근 본 상품의 유사 상품 추천 */
  RECENTLY_VIEWED_PRODUCT = 'RECENTLY_VIEWED_PRODUCT',
  /** 찜한 상품의 유사 상품 추천 */
  SAVED_PRODUCT = 'SAVED_PRODUCT',
  /** 장바구니 내 상품의 연관 상품 추천 */
  SHOPPING_CART = 'SHOPPING_CART',
}

export type DrCoupon = {
  coupon_id: Scalars['ID'];
  coupon_name: Scalars['String'];
  coupon_price: Scalars['Int'];
  date_deadline: Scalars['CrTimestamp'];
  image_url: Scalars['String'];
  terms_of_use: Scalars['String'];
};

export type DrCouponRecommendationCarousel = {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DrDiscountedSavedProductRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 할인 찜 상품 목록 */
  discounted_saved_product_list: Array<DiscountedSavedProduct>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrExposureRecommendationComponent = {
  /** 노출 예정 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 노출 예정 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrHeaderComponent = {
  image?: Maybe<DrImage>;
  subtitle?: Maybe<DrText>;
  title: DrText;
};

export type DrIcon = {
  product_id: Scalars['ID'];
  thumbnail: DrImage;
};

export type DrImage = {
  url: Scalars['String'];
  webp_url?: Maybe<Scalars['String']>;
};

export type DrKeyword = {
  keyword: DrText;
};

export type DrKeywordRecommendationCarousel = {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrProduct = {
  catalog_product_id: Scalars['ID'];
  image_url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sales_status: DrSalesStatus;
  shipping_fee_type: DrShippingFeeType;
  shop_product_no?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type DrProductCardItem = {
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  browsing_type: DrBrowsingType;
  discount_info?: Maybe<DrDiscountInfo>;
  discount_rate: Scalars['Int'];
  display_category_list?: Maybe<Array<DrCategory>>;
  display_review_count: Scalars['String'];
  final_price: Scalars['Int'];
  free_shipping?: Maybe<Scalars['Boolean']>;
  is_brand: Scalars['Boolean'];
  is_display_not_zpay: Scalars['Boolean'];
  is_saved_product: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  max_price: Scalars['Int'];
  product: DrProduct;
  product_badge?: Maybe<UxDisplayBadge>;
  review_score: Scalars['String'];
  sellable_status: UxSellableStatus;
  shop: DrShop;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  ubl?: Maybe<UxUbl>;
};

export type DrProductCarousel = {
  column_count?: Maybe<Scalars['Float']>;
  item_list: Array<DrProductCardItem>;
};

export type DrProductRecommendationCarousel = {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrRecentlyPurchasedProductRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 아이콘 목록 */
  icon_list: Array<DrIcon>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrRecentlySearchedKeywordRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 키워드 목록 */
  keyword_list: Array<DrKeyword>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrRecentlyViewedProductRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 아이콘 목록 */
  icon_list: Array<DrIcon>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export enum DrSalesStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENDED = 'SUSPENDED',
}

export type DrSavedProductRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 아이콘 목록 */
  icon_list: Array<DrIcon>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export enum DrShippingFeeType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  SHOP_POLICY = 'SHOP_POLICY',
}

export type DrShop = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DrShoppingCartRecommendationComponent = DrComponent & {
  /** 캐러셀 */
  carousel: DrProductCarousel;
  /** 캐러셀 제목 */
  carousel_title?: Maybe<DrText>;
  /** 헤더 */
  header: DrHeaderComponent;
  /** 아이콘 목록 */
  icon_list: Array<DrIcon>;
  /** 컴포넌트 위치 */
  position: Scalars['Int'];
  /** 컴포넌트 유형 */
  type: DrComponentType;
};

export type DrText = {
  color?: Maybe<Scalars['String']>;
  is_html?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
};

export type DrUpdatedTime = {
  updated_time: DrText;
};

export type DropshippingShopManager = {
  id: Scalars['ID'];
};

export type DropshippingShopManagerList = {
  item_list: Array<DropshippingShopManager>;
  total_count: Scalars['Int'];
};

/** 에픽 CMS 배너 */
export type EPickCmsBanner = {
  /** 활성화 */
  activated: Scalars['Boolean'];
  /** 배너 노출 날짜 */
  duration: Array<EpickCmsBannerImpDuration>;
  id: Scalars['ID'];
  /** 이미지 URL */
  img_url: Scalars['String'];
  /** landing URL (브라우저 타입 + 컨텐츠 URL) */
  landing_url: Scalars['String'];
  /** 배너 정렬 */
  order?: Maybe<Scalars['Int']>;
  /** 우선순위 */
  priority?: Maybe<Scalars['Int']>;
  /** 제목 */
  title: Scalars['String'];
};

/** 에픽 CMS 배너 리스트 */
export type EPickCmsBannerList = {
  item_list?: Maybe<Array<EPickCmsBanner>>;
};

/** 상품 카드 PLP 뱃지 */
export type EPickDisplayBannerBadge = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_size?: Maybe<EPickDisplayBannerBadgeImageSize>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_size?: Maybe<EPickDisplayBannerBadgeImageSize>;
  small_image_url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** 상품 카드 PLP 뱃지 이미지 사이즈 */
export type EPickDisplayBannerBadgeImageSize = {
  /** 이미지 높이 크기 */
  height: Scalars['Int'];
  /** 이미지 너비 크기 */
  width: Scalars['Int'];
};

export type EPickEditingInfo = {
  notice_list: Array<InfoText>;
  point_info_list: Array<InfoText>;
  style_category_list: Array<EPickStyleCategory>;
  /** 에픽 업로드 v2 에 노출될 버튼 텍스트 */
  upload_button_text: EPickUploadButtonText;
  upload_guide_link?: Maybe<InfoLink>;
};

/** 에픽 팔로우 */
export type EPickFollow = {
  id: Scalars['ID'];
  is_follow: Scalars['Boolean'];
  is_me: Scalars['Boolean'];
  profile?: Maybe<EPickSimpleProfile>;
  profile_id: Scalars['ID'];
  profile_status: EPickProfileStatus;
};

export type EPickLike = {
  id: Scalars['ID'];
  post_id: Scalars['ID'];
};

/** SNS URL LIST */
export type EPickLinkList = {
  instagram?: Maybe<Scalars['String']>;
  naver_blog?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export enum EPickPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

/** 에픽 게시글 */
export type EPickPost = {
  /** catalog_product ID list */
  catalog_product_id_list?: Maybe<Array<Scalars['ID']>>;
  /** catalog product type list */
  catalog_product_type_list?: Maybe<Array<EPickProductType>>;
  /** 등록일 */
  date_created: Scalars['CrBigInt'];
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** v2 에 노출되는 이미지 및 상품 정보 */
  image_content_list: Array<EPickPostImageContent>;
  /** 게시글의 이미지 목록 */
  image_list?: Maybe<Array<Scalars['String']>>;
  /** 광고협찬 여부 */
  is_ad: Scalars['Boolean'];
  /** 게시글 좋아요여부 */
  is_like: Scalars['Boolean'];
  /** 본인게시글 여부 */
  is_mine: Scalars['Boolean'];
  /** 가장최신댓글 */
  latest_reply?: Maybe<EPickReply>;
  /** 좋아요 수 */
  like_count: Scalars['Int'];
  /** 게시글의 상품 목록 */
  product_list: Array<EPickProduct>;
  /** 게시글의 간단 상품 목록 (v2: 통합 상품카드) */
  product_list_v2: Array<ProductCard>;
  /** 등록자의 간단 프로필 */
  profile: EPickSimpleProfile;
  /** 등록한 유저 프로필 ID */
  profile_id: Scalars['ID'];
  /** 총 댓글 수 */
  reply_count: Scalars['Int'];
  /** 상태 */
  status: EPickPostStatus;
  /** 스타일 카테고리 리스트 */
  style_category_list: Array<EPickStyleCategory>;
  /** 스타일 태그 (deprecated) */
  style_tag: Scalars['String'];
  /** 등록된 태그 목록 */
  tag_list?: Maybe<Array<EPickTag>>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  type: EPickPostType;
  /** 버전 정보 (v1, v2) */
  version: Scalars['String'];
  /** youtube_url */
  youtube_url?: Maybe<Scalars['String']>;
};

export type EPickPostImage = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
};

/** 에픽 게시글 V2에 노출될 컨텐츠 */
export type EPickPostImageContent = {
  /** image id */
  image_id: Scalars['ID'];
  /** 게시글의 이미지 */
  image_url: Scalars['String'];
  /** 상품 list */
  product_list: Array<EPickProduct>;
  /** 게시글의 간단 상품 목록 (v2: 통합 상품카드) */
  product_list_v2: Array<ProductCard>;
  /** 상품 핀 list */
  product_pin_list: Array<EPickProductPin>;
};

export type EPickPostImageContentInput = {
  /** 이미지 url */
  image_url: Scalars['String'];
  /** 상품 리스트 */
  product_list?: InputMaybe<Array<EPickPostProductInputV2>>;
};

export type EPickPostProductInput = {
  /** 상품 ID */
  catalog_product_id: Scalars['String'];
  /** 착용 여부 */
  post_product_type?: InputMaybe<EPickPostProductType>;
  /** shop ID */
  shop_id: Scalars['String'];
};

/** 에픽 포스트 V2에 노출될 상품 input */
export type EPickPostProductInputV2 = {
  /** 착용 여부 */
  post_product_type: Scalars['String'];
  /** 상품 ID */
  product_id: Scalars['String'];
  /** 상품 핀 */
  product_pin?: InputMaybe<EPickProductPinInput>;
  /** shop ID */
  shop_id: Scalars['String'];
};

export enum EPickPostProductType {
  /** 유사한 상품 */
  SIMILAR = 'SIMILAR',
  /** 착용 상품 */
  WORN = 'WORN',
}

/** 에픽 게시글 status */
export enum EPickPostStatus {
  /** 미노출중 */
  CLOSE = 'CLOSE',
  /** 삭제 */
  DELETE = 'DELETE',
  /** 노출중 */
  OPEN = 'OPEN',
}

/** 에픽 게시글 상품정보 status */
export enum EPickPostType {
  IMAGE_LIST = 'IMAGE_LIST',
  YOUTUBE = 'YOUTUBE',
}

export type EPickPostedMetaByUser = {
  is_first: Scalars['Boolean'];
  today_created_count: Scalars['Int'];
};

export type EPickPriceWithCurrency = {
  /** 통화 정보 */
  currency: EPickPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

/** 에픽 게시글 상품정보 */
export type EPickProduct = {
  /** PLP 뱃지 */
  badge_list?: Maybe<Array<Maybe<EPickDisplayBannerBadge>>>;
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<EPickDisplayBannerBadge>>;
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 */
  browsing_type: EPickProductBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  /** catalog_product_id */
  catalog_product_id: Scalars['ID'];
  /** 할인가 표기 정보 */
  discount_info?: Maybe<EPickProductDiscountInfo>;
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 최종 가격 with 통화 */
  final_price_with_currency?: Maybe<EPickPriceWithCurrency>;
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** 쿠폰 할인 여부 */
  has_coupon: Scalars['Boolean'];
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  /** 찜한 상품 여부 */
  is_saved_product?: Maybe<Scalars['Boolean']>;
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /**
   * log
   * @deprecated ux 서비스 CrJson 포맷팅 이슈 해결 이후 server_log 로 변경 필요
   */
  log?: Maybe<Scalars['String']>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /** 연동된 가격중 가장 높은 가격 */
  max_price_with_currency?: Maybe<EPickPriceWithCurrency>;
  /** 가로형 상품카드 메타 엠블렘 */
  metadata_emblem_badge_list?: Maybe<Array<EPickDisplayBannerBadge>>;
  /** 게시글 연동 상품 타입 */
  post_product_type?: Maybe<EPickPostProductType>;
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** Catalog 상품의 상태 (판매중, 품절) */
  sales_status?: Maybe<EPickProductSalesStatus>;
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name?: Maybe<Scalars['String']>;
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<EPickDisplayBannerBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<EPickDisplayBannerBadge>>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price?: Maybe<Scalars['Int']>;
};

export enum EPickProductBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type EPickProductDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EPickProductMapping = {
  /** 상품 id */
  product_id: Scalars['ID'];
  /** 상품 매핑 타입 (WORN, SIMILAR) */
  product_mapping_type?: Maybe<Scalars['String']>;
};

/** 상품 핀 */
export type EPickProductPin = {
  /** x 좌표 (1~100) */
  coordinate_x: Scalars['Int'];
  /** y 좌표 (1~100) */
  coordinate_y: Scalars['Int'];
  /** 상품 ID */
  product_id: Scalars['ID'];
};

/** 에픽 포스트 V2에 노출될 상품 핀 input */
export type EPickProductPinInput = {
  /** x 좌표 */
  coordinate_x: Scalars['Int'];
  /** y 좌표 */
  coordinate_y: Scalars['Int'];
};

export enum EPickProductSalesStatus {
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
}

export type EPickProductType = {
  post_product_type?: Maybe<EPickPostProductType>;
  product_id: Scalars['ID'];
};

/** 에픽 프로필 */
export type EPickProfile = {
  /** 팔로우 카운트 */
  count: EPickProfileCount;
  /** 프로필홈 Deeplink URL */
  deeplink_url: Scalars['String'];
  /** 프로필이미지 없을경우 노출될 default image url */
  default_profile_image_url: Scalars['String'];
  /** 자기소개 */
  description?: Maybe<Scalars['String']>;
  /** 팔로워 수(deprecated, count 이용) */
  follow_count: Scalars['Int'];
  /** 팔로잉 수(deprecated, count 이용) */
  following_count: Scalars['Int'];
  id: Scalars['ID'];
  /** 운영자 여부 */
  is_admin: Scalars['Boolean'];
  /** 한번이라도 자기소개 변경 여부 */
  is_edit_description: Scalars['Boolean'];
  /** 한번이라도 닉네임 수정 여부 */
  is_edit_nickname: Scalars['Boolean'];
  /** 한번이라도 프로필 이미지 수정 여부 */
  is_edit_profile_image_url: Scalars['Boolean'];
  /** 팔로우 여부 */
  is_follow: Scalars['Boolean'];
  /** 본인 여부 */
  is_me: Scalars['Boolean'];
  /** SNS URL LIST */
  link_list?: Maybe<EPickLinkList>;
  /** 닉네임 */
  nickname: Scalars['String'];
  /** 프로필이미지URL */
  profile_image_url?: Maybe<Scalars['String']>;
  /** 프로필 설정 Deeplink URL */
  setting_deeplink_url: Scalars['String'];
  /** 상태 */
  status: EPickProfileStatus;
  /** user ID */
  user_account_id: Scalars['String'];
};

export type EPickProfileCount = {
  follower: Scalars['Int'];
  following: Scalars['Int'];
};

/** 에픽 프로필 status */
export enum EPickProfileStatus {
  /** 게시글 등록가능 */
  CREATOR = 'CREATOR',
  /** 탈퇴 */
  DELETE = 'DELETE',
  /** 일반 */
  NORMAL = 'NORMAL',
}

/** 에픽 추천 게시글 */
export type EPickRecommendPost = {
  /** post id */
  post_id: Scalars['ID'];
  /** (UBL용) 서버 로그 */
  server_log?: Maybe<Scalars['CrJson']>;
};

/** 에픽 추천 게시글 목록 및 요약 */
export type EPickRecommendPostList = {
  /** 에픽 추천 게시글 목록 */
  item_list: Array<EPickRecommendPost>;
};

/** 에픽 댓글 */
export type EPickReply = {
  /** 작성일 */
  date_created: Scalars['CrBigInt'];
  /** 댓글내용 */
  description: Scalars['String'];
  /** 추가된 답글 여부 */
  has_re_reply: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 작성자 본인 여부 */
  is_mine: Scalars['Boolean'];
  /** 신고되어진 댓글 여부 */
  is_reported: Scalars['Boolean'];
  /** 답글일경우 부모 reply ID */
  parent_id?: Maybe<Scalars['ID']>;
  /** 댓글이 추가된 게시글 ID */
  post_id: Scalars['ID'];
  /** 작성자 epick profile ID */
  profile_id: Scalars['ID'];
  /** 작성자 epick simple profile */
  simple_profile: EPickSimpleProfile;
  /** 댓글 상태 */
  status: EPickReplyStatus;
  /** 누구에게 답글 달았는지 */
  to_profile_id?: Maybe<Scalars['String']>;
  to_simple_profile?: Maybe<EPickSimpleProfile>;
};

/** 에픽 댓글 status */
export enum EPickReplyStatus {
  /** 어드민에 의한 삭제 */
  DELETE_BY_ADMIN = 'DELETE_BY_ADMIN',
  /** 작성자에 의한 삭제 */
  DELETE_BY_USER = 'DELETE_BY_USER',
  /** 노출 */
  OPEN = 'OPEN',
}

/** 신고된 게시물 */
export type EPickReportPost = {
  /** 게시물 */
  post: EPickPost;
  /** 신고된 수 */
  reported_count: Scalars['Int'];
};

/** 에픽서비스 검색 결과 */
export type EPickSearched = {
  /** 이름 */
  keyword: Scalars['String'];
  /** 하이라이팅이 들어간 이름 ex)<em>가</em>나다 */
  keyword_highlighted: Scalars['String'];
  /** 검색결과 thumbnail image */
  thumbnail_image?: Maybe<Scalars['String']>;
  /** 검색결과type (TAG, PROFILE) */
  type: Scalars['String'];
  /** 검색결과type에 대한 ID */
  type_id: Scalars['ID'];
};

export type EPickSearchedListResponse = {
  item_list: Array<EPickSearched>;
};

/** 에픽 프로필 (심플) */
export type EPickSimpleProfile = {
  /** 프로필 ID */
  id: Scalars['ID'];
  /** 운영자 여부 */
  is_admin: Scalars['Boolean'];
  /** 팔로우 여부 */
  is_follow: Scalars['Boolean'];
  /** 닉네임 */
  nickname: Scalars['String'];
  /** 프로필 이미지 URL */
  profile_image_url?: Maybe<Scalars['String']>;
};

export type EPickStyleCategory = {
  id: Scalars['ID'];
  name: Scalars['String'];
  style_tag_list: Array<EPickStyleTag>;
};

export type EPickStyleTag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EPickStyleTagResponse = {
  tag: Scalars['String'];
  text: Scalars['String'];
};

/** 에픽 게시물 태그 */
export type EPickTag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** (임시) 에픽 연관 post */
export type EPickTempRelatedPost = {
  /** post id */
  post_id: Scalars['ID'];
  /** (UBL용) 서버 로그 */
  server_log?: Maybe<Scalars['CrJson']>;
};

/** (임시) 주어진 post ID 와 연관된 post 목록 및 정보 */
export type EPickTempRelatedPostList = {
  /** 컴포넌트 텍스트 */
  component_text?: Maybe<Scalars['String']>;
  /** 연관 post 목록 */
  item_list: Array<EPickTempRelatedPost>;
};

export type EPickTrendKeywordTagResponse = {
  order_by: PostOrder;
  tag: Scalars['String'];
  tag_id: Scalars['String'];
};

export type EPickUploadButtonText = {
  /** 버튼 하단에 노출될 문구 */
  button_title: Scalars['String'];
  /** 추가 설명 */
  description: Scalars['String'];
  /** 버튼 상단에 노출될 문구 */
  title: Scalars['String'];
};

export enum EditorMode {
  /** HTML */
  HTML = 'HTML',
  /** WYSIWYG */
  WYSIWYG = 'WYSIWYG',
}

/** 공동현관 출입 유형 */
export enum EntranceType {
  NO_PASSWORD = 'NO_PASSWORD',
  PASSWORD = 'PASSWORD',
}

export enum EntryType {
  CRAWLING_API = 'CRAWLING_API',
  DIRECT = 'DIRECT',
}

export type EpickCmsBannerImpDuration = {
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
};

export type EstimatedDate = {
  /** 종료일 */
  end: Scalars['CrTimestamp'];
  /** 시작일 */
  start: Scalars['CrTimestamp'];
};

export type EstimatedDay = {
  /** 종료일 */
  end: Scalars['Int'];
  /** 시작일 */
  start: Scalars['Int'];
};

export type EstimatedGlobalShippingCostInput = {
  country_code: OrderCountryType;
  estimated_payment_price: Scalars['Int'];
  product_item_list: Array<InputMaybe<EstimatedGlobalShippingCostInputItem>>;
};

export type EstimatedGlobalShippingCostInputItem = {
  product_item_id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type EstimatedGlobalShippingCostResponse = {
  country_code: OrderCountryType;
  general_info: EstimatedGlobalShippingInfo;
};

export type EstimatedGlobalShippingInfo = {
  buffer_price: Scalars['Int'];
  delivery_price: Scalars['Int'];
  operation_price: Scalars['Int'];
  sum_weight_volume?: Maybe<ItemTotalWeightAndVolume>;
};

/** 배송 예측일 */
export type EstimatedShippingDate = {
  display_estimate?: Maybe<DailyShippingProbability>;
  /** 소요일 별 확률 */
  estimates?: Maybe<Array<DailyShippingProbability>>;
  /** 상품 ID */
  product_id?: Maybe<Scalars['ID']>;
  /** 가장 높은 확률 */
  repr_estimate?: Maybe<DailyShippingProbability>;
};

export type EstimatedShippingGroupRefundAmountsInfo = {
  /** 총 차감 내역 */
  deduction_cost: RefundDeductionCosts;
  /** 차감 배송비 상세 */
  deduction_shipping_fee_detail?: Maybe<RefundDeductionShippingFeeDetail>;
  /** 동봉/입금 예정 금액 */
  enclose_amount: RefundEncloseCosts;
  /** 환불 예상 금액. (= 환불 요청금액 - 차감금액) */
  estimated_refund_amounts: RefundTotalAmounts;
  /** 소멸 예상 금액 */
  expired_amounts: ExpiredAmounts;
  /** 해지되는 할인 정보 목록 */
  order_discount_revoked_list: Array<OrderDiscountRevoked>;
  /** 총 프로모션 할인가 (상품금액-프로모션적용가 이므로 할인가에 바로 노출되는 가격) */
  promotion_applied_discount_price?: Maybe<Scalars['Int']>;
  /** 환불 요청 금액 */
  requested_amounts: RefundRequestedAmounts;
  /** 해지되는 할인액 */
  revoked_discount_amount: Scalars['Int'];
  /** 해지되는 카드사 즉시할인 금액 */
  revoked_discount_payment_amount?: Maybe<Scalars['Int']>;
  /** 환불 에정인 당일배송비 금액 */
  same_day_shipping_fee_refund_amount: ShippingFeeRefundTotalAmounts;
};

/** 이벤트 콘텐츠 댓글 */
export type EventContentReply = {
  /** 이벤트 콘텐츠 댓글 내용 */
  content: Scalars['String'];
  /** 이벤트 댓글 메타데이터 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 이벤트 댓글 메타데이터 수정일 */
  date_updated: Scalars['CrTimestamp'];
  /** 이벤트 댓글 메타데이터 UUID */
  event_content_reply_metadata_uuid: Scalars['String'];
  /** 이벤트 콘텐츠 댓글 ID */
  id: Scalars['ID'];
  /** 이벤트 댓글 작성자 여부 */
  is_mine?: Maybe<Scalars['Boolean']>;
  /** 이벤트 콘텐츠 댓글 상태 */
  status: EventContentReplyStatus;
  /** 이벤트 콘텐츠 댓글 작성자 user_account_id */
  user_account_id: Scalars['ID'];
  /** 댓글 작성자 user_account_profile ID */
  user_account_profile_id: Scalars['ID'];
  /** 댓글 작성자 user_account_profile 닉네임 */
  user_account_profile_nickname: Scalars['String'];
};

/** 이벤트 댓글 메타데이터 */
export type EventContentReplyMetadata = {
  /** 이벤트 댓글 작성가능 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 이벤트 댓글 작성가능 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /** 이벤트 댓글 메타데이터 UUID */
  uuid: Scalars['String'];
};

export enum EventContentReplyReportCommentType {
  /** 광고 및 홍보성 내용 */
  AD = 'AD',
  /** 개인정보 노출 위험 */
  PRIVACY = 'PRIVACY',
  /** 콘텐츠와 무관한 내용 */
  UNRELATED = 'UNRELATED',
  /** 욕설, 음란 등 부적절한 내용 */
  WORD = 'WORD',
}

export enum EventContentReplyStatus {
  /** 삭제 */
  DELETED = 'DELETED',
  /** 백오피스 삭제 */
  DELETED_BY_ADMIN = 'DELETED_BY_ADMIN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 신고 */
  REPORTED = 'REPORTED',
}

export type EventCoupon = {
  /** 만료 일시 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 만료 기간 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

export type EventCouponPack = {
  /** 쿠폰팩 쿠폰 정보 */
  coupon_pack_items: Array<EventCoupon>;
  /** 쿠폰팩 unique ID */
  uid: Scalars['String'];
};

/** 이벤트 메타데이터 */
export type EventMetadata = {
  /** 클라이언트 처리 사전 참여 조건 */
  client_pre_condition?: Maybe<Array<EventMetadataPreCondition>>;
  /** 이벤트 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 이벤트 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /** 콘텐츠에디터 UUID */
  event_content_uuid?: Maybe<Scalars['String']>;
  /**
   * 이벤트 메타데이터의 이벤트 진행상태 코드
   * 이벤트기간 이전인 경우 waiting
   * 이벤트기간 중인 경우는 open
   * 이벤트기간이 지난 경우는 close
   */
  event_status_code: EventMetadataEventStatusCode;
  /** 참여제한 건수, -1인 경우 무제한 */
  limit_participation: Scalars['Int'];
  /** 이벤트 지급 리워드 정보 */
  reward: Array<EventMetadataReward>;
  /** 이벤트 리워드 제공 사용 여부 */
  reward_enabled: Scalars['Boolean'];
  /** 이벤트 참여건수, 기본값 0, 이벤트가 종료되면 최종 업데이트됨 */
  total_participation: Scalars['Int'];
  /** 총 참여 포인트(@deprecated 삭제예정 호출하지 말것) */
  total_point?: Maybe<Scalars['Int']>;
  /** 이벤트 메타데이터 UUID */
  uuid: Scalars['String'];
};

/** EventMetadataAddToCart202001 결과 */
export type EventMetadataAddToCart202001Result = {
  /** 참여한 포인트의 총합 */
  total_participated_points: Scalars['Int'];
};

export type EventMetadataClientPreConditionFailingMessage = {
  /** 결과 알림 타입 */
  alert_type: ParticipateEventMetadataResultAlertType;
  /** 결과 팝업 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 링크 타이틀 */
  link_title?: Maybe<Scalars['String']>;
  /** 링크 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 결과 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 결과 팝업 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 결과 타입 */
  type: Scalars['String'];
};

/** 이벤트 메타데이터의 이벤트 진행상태 코드 */
export enum EventMetadataEventStatusCode {
  /** 이벤트 종료 */
  CLOSE = 'CLOSE',
  /** 이벤트 기간중 */
  OPEN = 'OPEN',
  /** 이벤트 조기종료 */
  TERMINATED = 'TERMINATED',
  /** 이벤트 시작전 */
  WAITING = 'WAITING',
}

/** 이벤트 메타데이터 사전조건 논리 연산 타입 */
export enum EventMetadataLogicalOperatorType {
  AND = 'AND',
  OR = 'OR',
}

export type EventMetadataPreCondition = {
  condition: Scalars['CrJson'];
  logical_operator: EventMetadataLogicalOperatorType;
  message_on_failure?: Maybe<EventMetadataClientPreConditionFailingMessage>;
  type: EventMetadataPreConditionType;
};

/** 사전 조건 타입 */
export enum EventMetadataPreConditionType {
  /** 마케팅 수신 동의 여부 */
  AD_AGREEMENT = 'AD_AGREEMENT',
  /** 장바구니 */
  CART = 'CART',
  /** 오늘의 혜택 */
  DAILY_MISSION = 'DAILY_MISSION',
  /** 에픽 팔로잉 수 */
  EPICK_FOLLOWING_COUNT = 'EPICK_FOLLOWING_COUNT',
  /** 에픽 좋아요 수 */
  EPICK_LIKE_COUNT = 'EPICK_LIKE_COUNT',
  /** 텍스트 제출 */
  ESSAY_QUIZ = 'ESSAY_QUIZ',
  /** 이벤트 참여 */
  EVENT_PARTICIPATION = 'EVENT_PARTICIPATION',
  /** 첫 구매 사용자 */
  FIRST_ORDER_USER = 'FIRST_ORDER_USER',
  /** 카카오톡 채널 연결 */
  KAKAO_CHANNEL_ADDED = 'KAKAO_CHANNEL_ADDED',
  /** 카카오 연동 여부 */
  KAKAO_CONNECTED = 'KAKAO_CONNECTED',
  /** 카카오톡 톡학생증 연동 */
  KAKAO_TALK_STUDENT_ID_CONNECTED = 'KAKAO_TALK_STUDENT_ID_CONNECTED',
  /** 멤버쉽 등급 */
  MEMBERSHIP_LEVEL = 'MEMBERSHIP_LEVEL',
  /** 객관식 퀴즈 */
  MULTIPLE_CHOICE_QUIZ = 'MULTIPLE_CHOICE_QUIZ',
  /** 유저액션 찜 */
  MY_PRODUCT = 'MY_PRODUCT',
  /** 온보딩 */
  ONBOARDING = 'ONBOARDING',
  /** 누적 주문 금액 / 횟수 / 수량 합 */
  ORDER_INFO = 'ORDER_INFO',
  /** 누적 주문 금액 합 */
  ORDER_PRICE_SUM = 'ORDER_PRICE_SUM',
  /**
   * 퀴즈
   * @deprecated
   */
  QUIZ = 'QUIZ',
  /** 셀러 즐겨찾기 (umd) */
  SAVED_SHOP_UMD = 'SAVED_SHOP_UMD',
  /** 셀러 즐겨찾기 */
  SELLER_BOOKMARK = 'SELLER_BOOKMARK',
  /** SHOP_DEPARTMENT 첫구매 */
  SHOP_DEPARTMENT_FIRST_ORDER_USER = 'SHOP_DEPARTMENT_FIRST_ORDER_USER',
  /** 유저액션 즐겨찾기 */
  SHOP_FAVORITE = 'SHOP_FAVORITE',
  /** 주관식 퀴즈 */
  SHORT_ANSWER_QUIZ = 'SHORT_ANSWER_QUIZ',
  /** 유저 가입 일 */
  USER_CREATED_AT = 'USER_CREATED_AT',
}

/** 이벤트 메타데이터 조회 결과 */
export type EventMetadataResult = {
  metadata: EventMetadata;
  server_time: Scalars['CrTimestamp'];
};

/** 이벤트 참여 결과 팝업 버튼 */
export type EventMetadataResultButton = {
  action: EventMetadataResultButtonAction;
  background_color: Scalars['String'];
  color: Scalars['String'];
  text: Scalars['String'];
};

/** 이벤트 참여 결과 팝업 버튼 클릭 액션 */
export type EventMetadataResultButtonAction = {
  option?: Maybe<EventMetadataResultButtonActionOption>;
  type: EventMetadataResultButtonActionType;
};

/** 이벤트 참여 결과 팝업 버튼 클릭 딥링크 액션 카카오공유 */
export type EventMetadataResultButtonActionKakaoShare = {
  data?: Maybe<Scalars['CrJson']>;
};

/** 이벤트 참여 결과 팝업 버튼 액션 옵션 정보 */
export type EventMetadataResultButtonActionOption =
  | EventMetadataResultButtonActionKakaoShare
  | EventMetadataResultButtonActionOptionDeepLink;

/** 이벤트 참여 결과 팝업 버튼 클릭 딥링크 액션 */
export type EventMetadataResultButtonActionOptionDeepLink = {
  zigzag_link: Scalars['String'];
};

export enum EventMetadataResultButtonActionType {
  /** 딥링크 */
  DEEP_LINK = 'DEEP_LINK',
  /** 카카오 공유하기 */
  KAKAO_SHARE = 'KAKAO_SHARE',
}

/** 이벤트 메타데이터 리워드 지급 정보 */
export type EventMetadataReward = {
  /** 이벤트 참여 결과 팝업 버튼 목록 */
  button_list?: Maybe<Array<EventMetadataResultButton>>;
  type: EventMetadataRewardType;
  value: EventMetadataRewardValue;
};

/** 이벤트 메타데이터 리워드 비동기 쿠폰 */
export type EventMetadataRewardAsyncCoupon = {
  /** 쿠폰 만료일(지정된 일시에 만료) */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 만료일(쿠폰 발급일로부터 +n일) */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰 사용가능 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 정책 ID */
  policy_id: Scalars['String'];
  /** 쿠폰 타이틀 */
  title?: Maybe<Scalars['String']>;
};

/** 이벤트 메타데이터 리워드 비동기 쿠폰팩 */
export type EventMetadataRewardAsyncCouponPack = {
  /** 쿠폰팩 ID */
  id: Scalars['String'];
  /** 쿠폰팩 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 쿠폰팩 UID */
  uid: Scalars['String'];
};

/** 이벤트 메타데이터 리워드 쿠폰 */
export type EventMetadataRewardCoupon = {
  /** 쿠폰 만료일(지정된 일시에 만료) */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 만료일(쿠폰 발급일로부터 +n일) */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰 사용가능 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 정책 ID */
  policy_id: Scalars['String'];
  /** 쿠폰 타이틀 */
  title?: Maybe<Scalars['String']>;
};

/** 이벤트 메타데이터 리워드 쿠폰팩 */
export type EventMetadataRewardCouponPack = {
  /** 쿠폰팩에 포함된 쿠폰 정책 목록 */
  item_list: Array<EventMetadataRewardCoupon>;
  /** 쿠폰팩 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 쿠폰팩 UID */
  uid: Scalars['String'];
};

/** 이벤트 메타데이터 리워드 메뉴얼 */
export type EventMetadataRewardManual = {
  /** 결과 알림 타입 */
  alert_type: ParticipateEventMetadataResultAlertType;
  /** 결과 팝업 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 버튼 명 */
  link_title?: Maybe<Scalars['String']>;
  /** DEEP LINK URL 이미지 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 결과 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 결과 팝업 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 결과 타입 */
  type: Scalars['String'];
};

/** 이벤트 메타데이터 리워드 마일리지 */
export type EventMetadataRewardMileage = {
  /** 지급한 마일리지 */
  mileage_amount: Scalars['Int'];
  /** 마일리지 유효기간(지급일로 부터 +n일) */
  mileage_expire_days?: Maybe<Scalars['Int']>;
  /** 마일리지 지급시 노출 메시지 */
  mileage_name?: Maybe<Scalars['String']>;
};

/** 이벤트 메타데이터 리워드 포인트 */
export type EventMetadataRewardPoint = {
  /** 지급한 포인트 */
  amount: Scalars['Int'];
  /** 포인트 유효기간(발급일로 부터 +n일) */
  days_expire?: Maybe<Scalars['Int']>;
  /** 포인트 지급시 노출 메시지 */
  description?: Maybe<Scalars['String']>;
};

/** 이벤트 메타데이터 리워드 랜덤 */
export type EventMetadataRewardRandom = {
  value?: Maybe<Scalars['CrJson']>;
};

/** 이벤트 메테ㅏ데이터 리워드 지급 타입 */
export enum EventMetadataRewardType {
  /** 비동기 쿠폰 */
  ASYNC_COUPON = 'ASYNC_COUPON',
  /** 비동기 쿠폰팩 */
  ASYNC_COUPON_PACK = 'ASYNC_COUPON_PACK',
  /** 쿠폰 */
  COUPON = 'COUPON',
  /** 쿠폰팩 */
  COUPON_PACK = 'COUPON_PACK',
  /** 메뉴얼 */
  MANUAL = 'MANUAL',
  /** 마일리지 */
  MILEAGE = 'MILEAGE',
  /** 리워드 없음 */
  NO_REWARD = 'NO_REWARD',
  /** 포인트 */
  POINT = 'POINT',
  /** 랜덤 */
  RANDOM = 'RANDOM',
  /** 사용자 선택 */
  USER_SELECTED = 'USER_SELECTED',
}

/** 이벤트 메타데이터 리워드 사용자 선택 */
export type EventMetadataRewardUserSelected = {
  value?: Maybe<Scalars['CrJson']>;
};

/** 이벤트 메타데이터 리워드 Value */
export type EventMetadataRewardValue =
  | EventMetadataRewardAsyncCoupon
  | EventMetadataRewardAsyncCouponPack
  | EventMetadataRewardCoupon
  | EventMetadataRewardCouponPack
  | EventMetadataRewardManual
  | EventMetadataRewardMileage
  | EventMetadataRewardPoint
  | EventMetadataRewardRandom
  | EventMetadataRewardUserSelected;

/** EventParticipatedAddToCart202001 결과 */
export type EventParticipatedAddToCart202001Result = {
  /** 상태 */
  status: Scalars['Boolean'];
};

/** 이벤트 참여 결과 */
export type EventParticipationResult = {
  /** 이벤트 결과 목록 */
  result_list?: Maybe<Array<EventResult>>;
  /** 이벤트 상태 */
  status?: Maybe<Scalars['String']>;
  /** 이벤트 상태 코드 */
  status_code?: Maybe<Scalars['String']>;
};

export type EventPoint = {
  /** 포인트 금액 */
  amount: Scalars['Int'];
  /** 포인트 만료일 */
  days_expire: Scalars['Int'];
};

/**
 * 상세 데이터는 CrJson 형태로 제공됩니다.
 * https://www.notion.so/croquis/EventPromotionMetadata-80b95ca613ea4a3e9ad6cd28f5d08272 를 참고하세요.
 */
export type EventPromotionMetadata = {
  data: Scalars['CrJson'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  name: Scalars['String'];
  server_time: Scalars['CrTimestamp'];
  status: EventPromotionMetadataStatus;
  type: Scalars['String'];
  uuid: Scalars['String'];
};

export enum EventPromotionMetadataStatus {
  /** 이벤트 종료 */
  CLOSE = 'CLOSE',
  /** 이벤트 기간중 */
  OPEN = 'OPEN',
  /** 이벤트 조기종료 */
  TERMINATED = 'TERMINATED',
  /** 이벤트 시작전 */
  WAITING = 'WAITING',
}

export type EventRecommendedSearchKeyword = RecommendedSearchKeyword & {
  event_link_url: Scalars['String'];
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

/** 이벤트 결과 */
export type EventResult = {
  /** 이벤트 리워드 */
  event_reward: EventReward;
  /** 이벤트 참여 여부, true: 참여, false: 미참여 또는 아직 이벤트가 진행되지 않은 경우 */
  is_participate: Scalars['Boolean'];
  /** 서브 이벤트 타입, 단일 이벤트일 경우 null */
  sub_event_type?: Maybe<Scalars['String']>;
};

/** 유저의 이벤트 결과 목록 */
export type EventResultForUserStatus = {
  /** 이벤트 리워드 */
  event_reward: EventReward;
  /** 이벤트 참여 여부 */
  is_participate: Scalars['Boolean'];
  /** 연계된 다음 이벤트 정보 */
  next_event_info?: Maybe<NextEventInfo>;
  /** 서브 이벤트 타입, 단일 이벤트일 경우 null */
  sub_event_type?: Maybe<Scalars['String']>;
};

/** 이벤트 리워드 */
export type EventReward = {
  /** 이벤트 쿠폰 정보 */
  coupon?: Maybe<EventCoupon>;
  /** 이벤트 쿠폰팩 정보 */
  coupon_pack?: Maybe<EventCouponPack>;
  /** 이벤트 포인트 */
  point?: Maybe<EventPoint>;
};

/** EventStatus 결과 */
export type EventStatusResult = {
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
  /** 이벤트 참여 가능 상태(메인 이벤트) */
  status: Scalars['Boolean'];
  /** 이벤트 참여 가능 상태 코드(메인 이벤트) */
  status_code: Scalars['String'];
  /** 서브 이벤트 상태 정보 */
  sub_event_status_list?: Maybe<Array<SubEventStatus>>;
};

/** 이벤트 종류 */
export enum EventType {
  ADD_TO_CART_202001 = 'ADD_TO_CART_202001',
  ADD_TO_CART_202002 = 'ADD_TO_CART_202002',
  CARNIVAL_COUPON_202009 = 'CARNIVAL_COUPON_202009',
  CARNIVAL_POINT_202009 = 'CARNIVAL_POINT_202009',
  CARNIVAL_POINT_BONUS_202009 = 'CARNIVAL_POINT_BONUS_202009',
  EVERY_TIME_202002 = 'EVERY_TIME_202002',
  GOODBYE_EVENT_202012 = 'GOODBYE_EVENT_202012',
  INDEPENDENCE_DAY_202103_FIRST = 'INDEPENDENCE_DAY_202103_FIRST',
  INDEPENDENCE_DAY_202103_SECOND = 'INDEPENDENCE_DAY_202103_SECOND',
  LUCKY_SHOPPING_GRANT_202101 = 'LUCKY_SHOPPING_GRANT_202101',
  MASK_202003 = 'MASK_202003',
  PUMPKIN_COUPON_202010 = 'PUMPKIN_COUPON_202010',
  SUMMER_LUCKY_FESTIVAL_202007 = 'SUMMER_LUCKY_FESTIVAL_202007',
  SUMMER_VACATION_202008 = 'SUMMER_VACATION_202008',
  USER_ACCOUNT_SIGNUP = 'USER_ACCOUNT_SIGNUP',
}

/** EventUserStatus 결과 */
export type EventUserStatusResult = {
  /** 유저의 이벤트 참여 결과(아직 미진행중인 이벤트는 false) */
  result_list?: Maybe<Array<EventResultForUserStatus>>;
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
  /** 이벤트 상태 */
  status: Scalars['Boolean'];
  /** 이벤트 상태 코드 */
  status_code: Scalars['String'];
};

/** 교환 비용 정보 */
export type ExchangeAmountsInfo = {
  /** 교환 상품 비용 정보 */
  exchange_order_item_price_difference_info: ExchangeOrderItemPriceDifferenceInfo;
  /** 교환 배송 비용 정보 */
  exchange_shipping_fee_info: ExchangeShippingFeeInfo;
  /** 총 교환 비용 */
  total_amount: Scalars['Float'];
};

/** 교환 도서산간비용 (교환 전 상품 수거 도서산간비, 교환 후 새 상품 배송 도서산간비 ) */
export type ExchangeExtraShippingFee = {
  /** 교환 후 새 상품 배송 도서산간비 (없을시 0 반환) */
  exchange_new_item_shipment: Scalars['Int'];
  /** 교환 전 상품 수거 도서산간비 (없을시 0 반환) */
  exchange_previous_item_return: Scalars['Int'];
};

/** 교환 상품 비용 정보 */
export type ExchangeOrderItemPriceDifferenceInfo = {
  /** 교환 후 상품 총 상품 금액 */
  new_exchange_item_total_price: Scalars['Int'];
  /** 교환 전 상품 총 상품 금액 */
  previous_exchange_item_total_price: Scalars['Int'];
  /** 총 교환 전/후 상품 차액 */
  total_item_price_difference: Scalars['Int'];
  /** 총 교환 전/후 상품 옵션액 차액 */
  total_option_price_delta_difference: Scalars['Int'];
};

/** 교환하고자 하는 상품 주문 정보 */
export type ExchangeOrderedProductInfo = {
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** 상품 이름 */
  name: Scalars['String'];
  /** 상품 옵션 리스트 */
  option_detail_list?: Maybe<Array<ExchangeProductOptionDetail>>;
  /** 선택된 옵션값 / 로 구분 */
  options: Scalars['String'];
  /** 품목의 추가/차감 금액 */
  price_delta: Scalars['Float'];
  /** 상품 상세 페이지 주소 */
  url: Scalars['String'];
};

/** 상품 */
export type ExchangeProduct = {
  /** 상품 ID */
  id: Scalars['ID'];
  /** 상품의 아이템 목록 */
  item_list: Array<ExchangeProductItem>;
  /** 상품의 옵션 목록 */
  option_list: Array<LegacyProductOption>;
  option_type: ProductOptionType;
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no: Scalars['String'];
  text_options: Array<LegacyTextOption>;
};

/** 상품 아이템 */
export type ExchangeProductItem = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 옵션 목록 */
  option_list: Array<ProductItemOption>;
  /**
   * 아이템 가격
   * product_id에 해당하는 상품의 가격(할인가가 있을시 할인가, 없을 시 정상가) + 추가/차감 금액
   */
  price: Scalars['Int'];
  /**
   * 아이템의 추가/차감 금액
   * - e.g. 3000, -5000
   */
  price_delta: Scalars['Int'];
  /**
   * 옵션의 상태
   * NORMAL
   * SOLD_OUT
   */
  status: ProductItemStatus;
};

/** 교환하고자 하는 상품 주문 정보 */
export type ExchangeProductItemInput = {
  /** 상품에 추가적으로 필요한 옵션 정보 리스트 */
  additional_option_detail_list?: InputMaybe<Array<ProductAdditionalOptionInput>>;
  /** 교환 전 상품주문번호 */
  previous_order_item_number: Scalars['String'];
  /** 가격 */
  price: Scalars['Int'];
  /**
   * 아이템의 추가/차감 금액
   * - e.g. 3000, -5000
   */
  price_delta?: InputMaybe<Scalars['Int']>;
  /** 주문할 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 수량 */
  quantity: Scalars['Int'];
};

/** 교환요청시 필요한 상품 목록 */
export type ExchangeProductList = {
  /** 상품 목록 */
  item_list: Array<ExchangeProduct>;
};

/** 교환하고자 하는 상품 주문 정보 */
export type ExchangeProductOptionDetail = {
  /** 옵션 이름 */
  name: Scalars['String'];
  /** 옵션 값 */
  value: Scalars['String'];
};

/** 교환 요청 정보 */
export type ExchangeRequestInfo = {
  /** 교환 요청 정보 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 총 금액 (기존의 상품 단가에 새로운 옵션 차액까지 포함되어 계산된 total_amount) */
  processed_total_amount: Scalars['Int'];
  /** 교환하고자 하는 상품 주문 정보 */
  product_info: ExchangeOrderedProductInfo;
  /** 수량 */
  quantity: Scalars['Int'];
  /** 수령자 정보 */
  receiver: Receiver;
  /** 배송 메모 */
  shipping_memo?: Maybe<Scalars['String']>;
};

/** 교환 배송 비용 정보 */
export type ExchangeShippingFeeInfo = {
  /** 교환 도서산간비용 (교환 전 상품 수거 도서산간비, 교환 후 새 상품 배송 도서산간비) */
  exchange_extra_shipping_fee: ExchangeExtraShippingFee;
  /**
   * 교환 배송비용
   * - 왕복
   * - 구매 당시 셀러가 입력한 교환배송비용(왕복)을 반환
   */
  exchange_shipping_fee: Scalars['Int'];
};

/** 데일리 미션 참여 리워드 ( 참여전, 참여후 모두 포함 ) */
export type ExpectedDailyMissionReward = DailyMissionReward & {
  /** 리워드 타입에 따른 색상 */
  color: Scalars['String'];
  /** 리워드 */
  reward: Scalars['String'];
  /** [ POINT, MILEAGE, GIFT ] */
  type: DailyMissionRewardType;
};

export type ExpectedDepositMileageResult = {
  /** 마일리지 적립 대상 목록 */
  target_list: Array<ExpectedDepositMileageTargetResult>;
};

export type ExpectedDepositMileageTargetResult = {
  /** 금액 */
  amount: Scalars['Int'];
  /** 예상 적립 마일리지 금액 */
  expected_mileage_amount: Scalars['Int'];
  /** 마일리지 적립 정책 */
  mileage_accumulate_policy: MileageAccumulatePolicy;
  /** 마일리지 적립 타입 */
  mileage_type: MileageType;
  /** 마일리지 적립 대상 타입 */
  target_type: MileageTargetType;
  /** 마일리지 적립 대상 ID */
  target_uid: Scalars['ID'];
};

export type ExperienceGroupProduct = {
  /** 체험단이 진행되는 상품 정보 */
  promotion_product: PromotionProduct;
  /** 체험단 진행 일정 */
  schedule: ExperienceGroupSchedule;
};

export type ExperienceGroupProductInput = {
  /** 조회할 프로모션 상품 옵션의 ID (promotion_product.id) */
  promotion_product_id: Scalars['ID'];
};

/** 체험단 수령자 정보 */
export type ExperienceGroupReceiverInput = {
  /** 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  address_detail?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 우편번호 */
  postcode: Scalars['String'];
};

export type ExperienceGroupSchedule = {
  /** 셀러의 신청 접수 마감일시 */
  date_applicant_seller_end: Scalars['CrTimestamp'];
  /** 셀러의 신청 접수 시작일시 */
  date_applicant_seller_start: Scalars['CrTimestamp'];
  /** 유저의 신청 접수 마감일시 */
  date_applicant_user_end: Scalars['CrTimestamp'];
  /** 유저의 신청 접수 시작일시 */
  date_applicant_user_start: Scalars['CrTimestamp'];
  /** 구매 가능 기간의 종료일시 */
  date_promotion_end: Scalars['CrTimestamp'];
  /** 구매 가능 기간의 시작일시 */
  date_promotion_start: Scalars['CrTimestamp'];
  /** 리뷰 작성 마감일시 */
  date_review_end: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  /** 응모 가능한 Site ID */
  site_id: Scalars['ID'];
  /** 스케쥴의 관리 명칭 */
  title: Scalars['String'];
};

export type ExperienceGroupUserAppliedItem = {
  /** 응모 상태 */
  apply_status: PromotionUserApplyStatus;
  /** 취소된 경우, 취소 정보 */
  cancel_info?: Maybe<PromotionWinnerCanceledInfo>;
  /** 진행 스케줄 정보 */
  experience_group_schedule?: Maybe<ExperienceGroupSchedule>;
  /** 진행 스케줄 ID */
  experience_group_schedule_id: Scalars['ID'];
  /** 상품의 주문 정보 */
  order_info?: Maybe<PromotionOrderInfo>;
  /** 응모 ID */
  promotion_applicant_id: Scalars['ID'];
  /** 응모한 상품 옵션 정보 */
  promotion_product_item?: Maybe<PromotionProductItem>;
  /** 응모한 상품의 옵션 ID (promotion_product_item.id) */
  promotion_product_item_id: Scalars['ID'];
};

export type ExperienceGroupUserAppliedItemList = {
  /** 검색된 Item list */
  item_list: Array<ExperienceGroupUserAppliedItem>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

export type ExperienceGroupUserAppliedItemListInput = {
  /** 조회할 응모 상태 (응모, 당첨) */
  apply_status?: InputMaybe<PromotionUserApplyStatus>;
  /** 유저의 참여 시각 >= date_applied_gte */
  date_applied_gte: Scalars['CrTimestamp'];
  /** 유저의 참여 시각 <= date_applied_loe */
  date_applied_lte?: InputMaybe<Scalars['CrTimestamp']>;
  /** Limit 수량, 기본 50 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** Skip(Offset) 수량, 기본 0 */
  skip_count?: InputMaybe<Scalars['Int']>;
};

export enum ExperienceScheduleType {
  APPLICANT_USER = 'APPLICANT_USER',
  RETRY_ORDER = 'RETRY_ORDER',
  REVIEW = 'REVIEW',
}

export type ExpiredAmounts = {
  /** 소멸된 마일리지 */
  mileage_amount: Scalars['Int'];
  /** 소멸된 포인트 */
  point_amount: Scalars['Int'];
};

export type ExternalProductOptionDetail = {
  /** 옵션 명 */
  name: Scalars['String'];
  /** 옵션 값 */
  value: Scalars['String'];
};

export type ExternalProductReview = {
  /** 첨부파일 */
  attachment_list: Array<ExternalProductReviewAttachment>;
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 식별 키 */
  id: Scalars['ID'];
  /** "상품 옵션 */
  option_detail_list?: Maybe<Array<ExternalProductOptionDetail>>;
  /** 평점 */
  rating?: Maybe<Scalars['Int']>;
  /** 요청한 사용자별 정보 */
  requested_user?: Maybe<ExternalProductReviewRequestedUser>;
};

export type ExternalProductReviewAttachment = {
  /** 상위 리뷰 키 */
  external_review_id: Scalars['ID'];
  /** 원본 URL */
  original_url: Scalars['String'];
  /** 썸네일 URL */
  thumbnail_url: Scalars['String'];
};

export type ExternalProductReviewList = {
  item_list: Array<ExternalProductReview>;
  source: Scalars['String'];
  total_count: Scalars['Int'];
};

/** 요청한 사용자별 정보 */
export type ExternalProductReviewRequestedUser = {
  is_abuse_reported: Scalars['Boolean'];
};

/**
 * 추가금액 지불 방식
 * - NOT_REQUIRED: 추가 금액 지불 불필요
 * - USER_WILL_DEPOSIT_PRIVATELY: 추가 금액을 사용자가 셀러 계좌로 입금
 * - USER_WILL_ENCLOSE_WITH_PACKAGE: 추가 금액을 사용자가 배송 박스에 동봉
 */
export enum ExtraAdditionalFeeChargeMethod {
  NOT_REQUIRED = 'NOT_REQUIRED',
  USER_WILL_DEPOSIT_PRIVATELY = 'USER_WILL_DEPOSIT_PRIVATELY',
  USER_WILL_ENCLOSE_WITH_PACKAGE = 'USER_WILL_ENCLOSE_WITH_PACKAGE',
}

export enum FastDeliveryType {
  SETTING = 'SETTING',
  STATISTICS = 'STATISTICS',
}

export type FbkActiveNewProductListByMdPick = {
  /** 쿠폰 정책 ID */
  coupon_policy_id?: Maybe<Scalars['ID']>;
  item_list: Array<FbkProduct>;
  total_count: Scalars['Int'];
};

export enum FbkAgeRecommendedKeywordAgeType {
  AGE_10 = 'AGE_10',
  AGE_20 = 'AGE_20',
  AGE_30 = 'AGE_30',
  ALL = 'ALL',
}

export type FbkAgeRecommendedKeywordGroup = {
  age: Scalars['String'];
  age_type: FbkAgeRecommendedKeywordAgeType;
  recommended_keyword_list: Array<Maybe<Scalars['String']>>;
};

export type FbkAgeRecommendedKeywordInfo = {
  age_recommended_keyword_group_list: Array<Maybe<FbkAgeRecommendedKeywordGroup>>;
  title?: Maybe<Scalars['String']>;
};

/** 초대 링크 생성에 필요한 inviter_code 생성 결과 */
export type FbkAttendanceEventInviterCodeResult = {
  /** 초대 유저 코드 */
  inviter_code: Scalars['String'];
};

/** 출석 체크 현황 */
export type FbkAttendanceEventLogSummary = {
  /** 출석권 */
  attendance_ticket_count: Scalars['Int'];
  /** 마지막으로 출석한 날짜 */
  date_last_attended: Scalars['CrTimestamp'];
  /** 경품 응모권 */
  raffle_apply_ticket_count: Scalars['Int'];
  /** 오늘 지급받은 포인트 */
  today_rewarded_point: Scalars['Int'];
  /** 출석 일수 */
  total_attendance_day: Scalars['Int'];
  /** 총 지급받은 포인트 */
  total_reward_point: Scalars['Int'];
};

/** 당첨된 유저 정보 */
export type FbkAttendanceEventRaffleUserAccount = {
  /** 이메일 */
  masked_email: Scalars['String'];
};

/** 출석체크 30일 이상 달성 유저중 추첨된 유저 리스트 */
export type FbkAttendanceEventRaffleUserAccountList = {
  /** 추첨 날짜 */
  date_raffled: Scalars['CrTimestamp'];
  /** 추첨 유저 리스트 */
  item_list: Array<FbkAttendanceEventRaffleUserAccount>;
};

/** 패바카 앱/웹에 노출되는 뱃지 */
export type FbkBadge = {
  /**
   * 뱃지 타입
   * [RED_DOT]
   */
  badge_type: Scalars['String'];
  /** 뱃지 노출 종료 시간 */
  date_ended: Scalars['CrTimestamp'];
  /** 뱃지 노출 시작 시간 */
  date_started: Scalars['CrTimestamp'];
  /** 뱃지 설명 */
  description: Scalars['String'];
  /**
   * 노출 대상
   * [PERSONAL, ALL]
   */
  exposure_target_type: Scalars['String'];
  /** 뱃지의 id */
  id: Scalars['ID'];
  /** 뱃지가 노출되어야하는 탭의 id */
  tab_id: Scalars['ID'];
};

/** 페바카 배너 */
export type FbkBanner = {
  /** 배너 노출 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 배너 노출 시작일 */
  date_start: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  /** 이미지 url */
  image_url: Scalars['String'];
  /** 배너 랜딩 url */
  landing_url: Scalars['String'];
  /** 배너 타이틀 */
  title: Scalars['String'];
};

export type FbkBannerAdDisplay = {
  banner_image_url: Scalars['String'];
  id: Scalars['ID'];
  /** 배너광고 클릭시 이동하는 링크 URL */
  link_url: Scalars['String'];
  /** 배너광고 상태: ENUM (ACTIVE, INACTIVE) */
  status: Scalars['String'];
  title: Scalars['String'];
};

/** 페바카 배너 리스트 */
export type FbkBannerList = {
  item_list: Array<FbkBanner>;
  total_count: Scalars['Int'];
};

export type FbkBaseContent = {
  /** 베이스 컨텐츠에 포함된 미디어 정보 */
  base_content_media?: Maybe<FbkBaseContentMedia>;
  /** id */
  id: Scalars['ID'];
  /** landing url */
  landing_url: Scalars['String'];
  /** 베이스 컨텐츠에 포함된 패바카 상품 리스트 정보 */
  product_list: Array<FbkProduct>;
  /** 베이스 컨텐츠에 포함된 product preset 정보. */
  product_preset_uuid?: Maybe<Scalars['String']>;
  /** 본문 */
  text_content: Scalars['String'];
  /** 컨텐츠 노출 타이틀 */
  title: Scalars['String'];
};

export type FbkBaseContentList = {
  item_list: Array<FbkBaseContent>;
  total_count: Scalars['Int'];
};

export type FbkBaseContentMedia = {
  /** image url */
  image_url: Scalars['String'];
  /** media type [ IMAGE, VIDEO ] */
  media_type: Scalars['String'];
  /** video url */
  video_url?: Maybe<Scalars['String']>;
};

/** 패바카 부스트 샵 쿠폰 정책 */
export type FbkBoostShopCouponPolicy = {
  /** 쿠폰 할인율 (1% 인 경우 100, 100% 인 경우 10000 반환) */
  coupon_discount_rate_bp: Scalars['Int'];
  /** 쿠폰 정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 상품 목록 (최대 10개) */
  product_list: Array<FbkProduct>;
  /** 샵 */
  shop: FbkShop;
};

/** 페바카 캐러셀 컴포넌트 */
export type FbkCarousel = {
  /** benefit section ID */
  benefit_section_id: Scalars['ID'];
  /** 상품 목록 */
  carousel_product_list: Array<FbkProduct>;
  /**
   * 캐러셀 타입
   * [TREND_TAB_LIST, TREND_TAB_CAROUSEL, ZIGZIN_CAROUSEL, KIDS_CAROUSEL]
   */
  carousel_type?: Maybe<Scalars['String']>;
  /** 메인 타이틀 */
  main_title: Scalars['String'];
  /** 서브 타이틀 */
  sub_title?: Maybe<Scalars['String']>;
};

/** 패바카 쿠폰 정책 */
export type FbkCouponPolicy = {
  /**
   * 쿠폰 타겟 종류 (쿠폰서비스 제공 enum 을 bypass 함)
   * ---
   * ORDER
   * SHOP
   * SHOP_WITH_ZONLY
   * SHOP_GROUP
   * ORDER_ITEM
   * ORDER_ITEM_GROUP
   * CATEGORY
   * SHIPPING_FEE (미사용)
   * POINT (미사용)
   */
  coupon_target_type: Scalars['String'];
  /** 만료 일시 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 발급 종료일시 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 쿠폰 발급 시작일시 */
  date_issue_start: Scalars['CrTimestamp'];
  /** 만료 기간 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인율 */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /**
   * 정책 할인 타입 (쿠폰서비스 제공 enum 을 bypass 함)
   * ---
   * AMOUNT_DISCOUNT
   * RATE_DISCOUNT
   */
  discount_type: Scalars['String'];
  /**
   * 화면에 노출할 할인율 (정액형일 경우 계산, 정률형일 경우 bypass)
   * ---
   * (1% 인 경우 100, 100% 인 경우 10000 반환)
   */
  display_discount_rate_bp: Scalars['Int'];
  /** 쿠폰 정책 ID */
  id: Scalars['ID'];
  /** 쿠폰이 최대로 할인해줄 수 있는 금액 */
  max_discount_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
  /**
   * 샵 목록
   * ---
   * (쿠폰 타겟 유형이 SHOP, SHOP_GROUP, SHOP_WITH_ZONLY 일 경우)
   */
  shop_list: Array<FbkShop>;
  /** 쿠폰 title */
  title?: Maybe<Scalars['String']>;
  /**
   * 사용자계정 쿠폰
   * ---
   * 로그인 하지 않은 경우, 쿠폰발급받지 않은 경우 null
   */
  user_account_coupon?: Maybe<FbkUserAccountCoupon>;
};

/** 패바카 쿠폰 정책 목록 */
export type FbkCouponPolicyList = {
  /** 쿠폰 정책 목록 */
  item_list: Array<FbkCouponPolicy>;
  /** 총 목록 갯수 */
  total_count: Scalars['Int'];
};

/** 세일탭 할인된 신상품 리스트 */
export type FbkDiscountedNewProductItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page?: Maybe<Scalars['Boolean']>;
  item_list: Array<FbkProduct>;
  /** 상품 개수 */
  total_count: Scalars['Int'];
};

/** 패바카 노출 카테고리 */
export type FbkDisplayCategory = {
  /** 노출 카테고리 아이콘 URL */
  icon_url: Scalars['String'];
  /** 노출 카테고리 ID */
  id: Scalars['ID'];
  /** 노출 카테고리 명 */
  name: Scalars['String'];
  /** 하위 카테고리 목록 */
  sub_category_list: Array<FbkDisplayCategory>;
};

export type FbkEvent = {
  banner_image_url?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  date_event_end?: Maybe<Scalars['CrTimestamp']>;
  date_event_start?: Maybe<Scalars['CrTimestamp']>;
  id: Scalars['ID'];
  main_html?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type FbkEventDetail = {
  active_event_list?: Maybe<FbkEventList>;
  banner_image_url?: Maybe<Scalars['String']>;
  date_event_end?: Maybe<Scalars['CrTimestamp']>;
  date_event_start?: Maybe<Scalars['CrTimestamp']>;
  id: Scalars['ID'];
  main_html?: Maybe<Scalars['String']>;
  section_list?: Maybe<Array<FbkEventDetailSection>>;
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type FbkEventDetailSection = {
  id: Scalars['ID'];
  item_list: Array<FbkProduct>;
  title?: Maybe<Scalars['String']>;
};

export type FbkEventList = {
  item_list?: Maybe<Array<Maybe<FbkEvent>>>;
  total_count: Scalars['Int'];
};

/** 패바카 이벤트 모달 */
export type FbkEventModal = {
  /** 딥링크 URL */
  deeplink_url: Scalars['String'];
  /** 이벤트 모달 ID */
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url: Scalars['String'];
  /**
   * 랜딩 URL
   * @deprecated use deeplink_url
   */
  landing_url: Scalars['String'];
};

/** 패바카 이벤트 모달 목록 및 요약 */
export type FbkEventModalList = {
  /** 이벤트 모달 목록 */
  item_list: Array<FbkEventModal>;
};

export type FbkEventType = {
  description: Scalars['String'];
  event_type: Scalars['String'];
};

export type FbkEventTypeList = {
  item_list: Array<FbkEventType>;
  total_count: Scalars['Int'];
};

/** 피처 메뉴 */
export type FbkFeatureMenu = {
  /**
   * [피처 메뉴] 타입 정의 (IMAGE, LOTTIE)
   * DEFAULT: IMAGE
   */
  icon_type: Scalars['String'];
  /** [피처 메뉴]에 노출될 아이콘의 url */
  icon_url: Scalars['String'];
  /** [피처 메뉴]를 선택했을 때, 이동될 url */
  landing_url: Scalars['String'];
  /**
   * [피처 메뉴]가 LOTTIE 타입일때, loop 를 돌릴것인지 여부
   * DEFAULT: 0
   */
  loop_count: Scalars['Int'];
  /** [피처 메뉴]의 하단에 표시될 이름 */
  name: Scalars['String'];
};

/** 피처 메뉴 그룹 */
export type FbkFeatureMenuGroup = {
  /** [피처 메뉴] 리스트 */
  feature_menu_list: Array<FbkFeatureMenu>;
};

/** 홈지면 광고 ID 또는 상품 ID */
export type FbkHomeAdOrProductId = {
  /** AD ID */
  ad_id?: Maybe<Scalars['ID']>;
  /** 상품 ID */
  product_id?: Maybe<Scalars['ID']>;
};

/** 홈지면 광고 ID 또는 상품 ID 목록 및 요약 데이터 */
export type FbkHomeAdOrProductIdList = {
  /** 홈지면 광고 ID 또는 상품 ID 목록 */
  item_list: Array<FbkHomeAdOrProductId>;
};

/** 패바카 홈지면 광고 상품 목록 및 요약 */
export type FbkHomeAdProductList = {
  /**
   * 다음 페이지 데이터 조회를 위한 end_cursor
   * @deprecated unsupported field
   */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
  /** 총 갯수 */
  total_count: Scalars['Int'];
};

export type FbkHomeCategoryChip = {
  /**
   * category_key
   * [ OUTER, TOP, ONE_PIECE_SET, PANTS, LINGERIE_PAJAMAS, SHOES, SKIRT, BAG, ACCESSORY, ETC_WEAR, FASHION_ITEMS ]
   */
  category_key: Scalars['String'];
  /**
   * 카테고리 이름
   * [ 아우터, 상의, 원피스/세트, 바지, 란제리/파자마, 슈즈, 스커트, 가방, 액세서리, 기타의류, 패션잡화 ]
   */
  category_name: Scalars['String'];
};

/** 패바카 이미지검색 프리셋 상품목록 및 요약 데이터 */
export type FbkImageSearchPresetProductList = {
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
};

/** 페바카 이미지검색 상품목록 및 요약 데이터 */
export type FbkImageSearchProductList = {
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
};

/** FbkssueCouponPack의 입력 */
export type FbkIssueCouponPackInput = {
  /** 쿠폰 팩 ID */
  user_account_coupon_pack_id: Scalars['ID'];
};

/** FbkIssueCouponPack의 결과 */
export type FbkIssueCouponPackResult = {
  /** 쿠폰 팩 아이디 */
  id: Scalars['ID'];
  /**
   * 쿠폰 팩 발급 결과 상태
   * ---
   * SUCCEEDED: 쿠폰 발급에 성공한 경우
   * FAILED: 쿠폰 발급에 실패한 경우
   * ALREADY_ISSUED: 이미 쿠폰을 발급받은 경우
   * NOT_FOUND: 존재하지 않는 쿠폰인 경우
   * NOT_LOGGED_IN: 로그인하지 않은 사용자인 경우
   * EXPIRED: 만료된 쿠폰인 경우
   * EXHAUSTED: 발급 가능 수량이 초과된 쿠폰인 경우
   */
  status: Scalars['String'];
};

/** 패바카 사용자가 좋아요한 상품 목록 및 요약 */
export type FbkLikeProductList = {
  /** 다음 페이지 데이터 조회를 위한 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
  /** 총 갯수 */
  total_count: Scalars['Int'];
};

/** 패바카 사용자가 좋아요한 샵의 최근 상품 목록 및 요약 */
export type FbkLikeShopLatestProductList = {
  /** 다음 페이지 데이터 조회를 위한 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
  /** 총 갯수 */
  total_count: Scalars['Int'];
};

/** 띠 배너 */
export type FbkLineBanner = {
  /** 배경 색상 */
  background_color: Scalars['String'];
  /**
   * 띠 배너에 노출되는 최종 문구
   * api2 로 전달할 때는 <span> 첫구매라면, <b>990원</b>에 구매하세요 </span> 형태로 전달.
   */
  banner_text_content: Scalars['String'];
  /** 생성자 */
  created_by: Scalars['String'];
  /** 노출 종료일 */
  date_ended: Scalars['CrTimestamp'];
  /** 노출 시작일 */
  date_started: Scalars['CrTimestamp'];
  /** 강조 문구 색상 */
  highlight_text_color: Scalars['String'];
  id: Scalars['ID'];
  /** 활성화 여부 */
  is_active: Scalars['Boolean'];
  /** 랜딩 url */
  landing_url: Scalars['String'];
  /** 문구 색상 */
  text_color: Scalars['String'];
  /** backoffice title */
  title: Scalars['String'];
  /** 수정자 */
  updated_by: Scalars['String'];
};

/** 패바카 멤버쉽 */
export type FbkMembership = {
  /** 연월 (YYYYMM) */
  date_ym: Scalars['Int'];
  /** 패바카 멤버쉽 등급 */
  membership_level: FbkMembershipLevel;
  /** 현금결제 금액 (최근 4개월) */
  paid_cash_amount: Scalars['Int'];
};

/** 패바카 멤버쉽 등급 */
export type FbkMembershipLevel = {
  /**
   * 멤버십 등급 코드
   * LV_1, LV_2, LV_3, LV_4, LV_5
   */
  code: Scalars['String'];
  /**
   * 멤버십 등급 노출명
   * 핑크, 실버, 골드, 다이아, VIP
   */
  display_name: Scalars['String'];
  /** 현금결제금액 gte */
  paid_cash_amount_gte: Scalars['Int'];
};

/** 페바카 메타데이터 */
export type FbkMetadata = {
  /** 탭 관련 설정값 */
  native_app_tab_config?: Maybe<FbkNativeAppTabConfig>;
  /** 리뷰중인 IOS 버전 */
  reviewing_ios_version?: Maybe<Scalars['String']>;
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
  /** 샵라이브 방송중인 캠페인 키 */
  shoplive_on_air_campaign_key?: Maybe<Scalars['String']>;
  /** 현재 운영중인 스플래시 이미지 */
  splash_image?: Maybe<FbkSplashImage>;
};

/** FBK 네이티브 탭 정보 */
export type FbkNativeAppTab = {
  /** 활성/비활성 탭 아이콘 url */
  icon?: Maybe<FbkTabIcon>;
  id: Scalars['ID'];
  /** 로드시 기본 노출탭으로 보여줄지 여부 */
  is_default_tab: Scalars['Boolean'];
  /** 탭이 랜딩될 url */
  landing_url?: Maybe<Scalars['String']>;
  /** 탭 이름 */
  name: Scalars['String'];
  /** 활성/비활성 탭 name 색상(ex, #FF0000) */
  name_color: FbkTabNameColor;
  /** 페이지 key */
  page_key?: Maybe<Scalars['String']>;
  /** 서브탭 */
  tab_list: Array<FbkNativeAppTab>;
  /**
   * 탭 타입
   * [MAIN, SUB]
   */
  tab_type: Scalars['String'];
};

/** 패바카 네이티브 탭 관련 설정값 */
export type FbkNativeAppTabConfig = {
  /** 홈탭 이동 주기 ( 초단위 ) */
  tab_home_navigation_interval_sec: Scalars['Int'];
  /** 탭 갱신 타이머 주기 ( 초단위 ) */
  tab_refresh_duration_sec: Scalars['Int'];
};

export type FbkNewProductCouponPolicy = {
  /** 쿠폰 할인율 (1% 인 경우 100, 100% 인 경우 10000 반환) */
  coupon_discount_rate_bp: Scalars['Int'];
  /** 쿠폰 정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 쿠폰 사용 종료일 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 쿠폰 사용 시작일 */
  date_issue_start: Scalars['CrTimestamp'];
  /** 쿠폰 다운로드 여부 확인 ( 미 로그인시 false ) */
  is_issued: Scalars['Boolean'];
};

export type FbkNewProductListByCategory = {
  item_list: Array<FbkProduct>;
  /** 쿠폰 정책 */
  new_product_coupon_policy: FbkNewProductCouponPolicy;
  total_count: Scalars['Int'];
};

/** 많이 찾는 카테고리 */
export type FbkPopularDisplayCategory = {
  /** 1차 카테고리 id */
  category_1_depth_id: Scalars['ID'];
  /** 2차 카테고리 id */
  category_2_depth_id: Scalars['ID'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 순위 */
  ranking: Scalars['Int'];
};

export type FbkPopularDisplayCategoryList = {
  /** 많이 찾는 카테고리 업데이트된 일시. */
  date_updated: Scalars['CrTimestamp'];
  item_list: Array<FbkPopularDisplayCategory>;
};

/**
 * 패바카 상품 타입
 * UxGoodsCardItem 에서 페바카에서 사용하는 파라미터만 추출.
 */
export type FbkProduct = {
  /** 광고 id */
  aid?: Maybe<Scalars['String']>;
  /**
   * 패바카 상품 뱃지 목록
   * - 20230831 기준 UxGoodsCardItem 과 schema 를 맞추기 위해 우선 빠른배송 뱃지 용 으로만 사용
   * - 향후 지속 사용여부 미정
   */
  badge_list: Array<FbkProductBadge>;
  /** catalog_product_id */
  catalog_product_id: Scalars['ID'];
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** 쿠폰 사용 여부 */
  has_coupon: Scalars['Boolean'];
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 여부 */
  is_ad: Scalars['Boolean'];
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 빠른 배송 여부 */
  is_fast_delivery: Scalars['Boolean'];
  /** 신상품 표기 */
  is_new?: Maybe<Scalars['Boolean']>;
  /** 찜 상품 여부 */
  is_saved_product: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** log */
  log?: Maybe<Scalars['String']>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price?: Maybe<Scalars['Int']>;
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** 상품의 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 점수 */
  review_score?: Maybe<Scalars['Float']>;
  /**
   * 상품 판매 상태 (catalog 서비스 상태값을 bypass)
   * ---
   * PREPARING (준비중)
   * ON_SALE (판매중)
   * CLOSED (판매종료)
   * SUSPENDED (판매중단)
   * SOLD_OUT (품절)
   */
  sales_status: Scalars['String'];
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name?: Maybe<Scalars['String']>;
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** 패바카 프로모션 뱃지 목록 */
  thumbnail_emblem_badge_list: Array<FbkProductBadge>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
};

/**
 * 패바카 상품 뱃지
 * - 20230831 기준 UxGoodsCardItem 과 schema 를 맞추기 위해 우선 빠른배송 뱃지 용 으로만 사용
 * - 향후 지속 사용여부 미정
 */
export type FbkProductBadge = {
  /**
   * 이미지 URL
   * - UxDisplayBadge 와 스키마를 동일하게 맞춤
   * - 빠른배송 뱃지가 필요한 경우 FAST_DELIVERY 문자를 반환함.
   * @deprecated use type field
   */
  image_url: Scalars['String'];
  /** 상품 뱃지 타입 */
  type: Scalars['String'];
};

/** 상품 리뷰 리스트 */
export type FbkProductBestReviewList = {
  /**
   * 베스트 특성평가
   * - 응답률 80% 이상인 평가
   */
  best_attribute?: Maybe<FbkProductReviewAttribute>;
  /** 리뷰 리스트 */
  item_list: Array<FbkProductReview>;
  /** 평균 리뷰 점수 */
  ratings_average: Scalars['Float'];
  /** 총 리뷰 개수 */
  total_count: Scalars['Int'];
};

/** 상품 배송 예상 */
export type FbkProductDeliveryEstimate = {
  /** 몇일 내에 도착하는지 */
  day: Scalars['Int'];
  /** 도착 확률 */
  probability: Scalars['Float'];
};

/** 상품 배송 예상 요약 */
export type FbkProductDeliveryEstimateSummary = {
  /**
   * 배송 예상 목록
   * 1,2,3,4 일까지 나올 수 있음.
   */
  estimate_list: Array<FbkProductDeliveryEstimate>;
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 예상 도착 정보 */
  repr_estimate?: Maybe<FbkProductDeliveryEstimate>;
  /**
   * 응답 타입
   * 1순위) product (해당 상품 예측치)
   * 2순위) store_category (해당 스토어의, 해당 카테고리의 예측치)
   * 3순위) store (해당 스토어의 예측치)
   */
  response_type: Scalars['String'];
};

/** 패바카 상품 배송 요약 */
export type FbkProductDeliverySummary = {
  /** 배송 지연 이력 여부 */
  has_delivery_delayed_log: Scalars['Boolean'];
  /** 상품 배송 예상 요약 */
  product_delivery_estimate_summary?: Maybe<FbkProductDeliveryEstimateSummary>;
  /**
   * 상품 배송유형
   * -----
   * GENERAL (일반배송, catalog 서비스 제공)
   * INSTALLATION (설치배송, catalog 서비스 제공)
   * INTERNATIONAL (해외배송, catalog 서비스 제공)
   * MAKE_TO_ORDER (주문 제작 후 배송, catalog 서비스 제공)
   */
  product_shipping_type?: Maybe<Scalars['String']>;
};

/** 상품 ID 기반 패바카 상품목록 및 요약 */
export type FbkProductListByIdList = {
  /**
   * 다음 페이지 데이터 조회를 위한 end_cursor
   * @deprecated unsupported field
   */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
  /** 총 갯수 */
  total_count: Scalars['Int'];
};

/** 패바카 상품 최대 혜택 */
export type FbkProductMaxBenefit = {
  /** 최대 쿠폰 할인 */
  max_coupon_discount?: Maybe<FbkProductMaxCouponDiscount>;
  /** 사용자계정 포인트 */
  user_account_point?: Maybe<FbkUserAccountPoint>;
};

/** 패바카 상품 최대 쿠폰 할인 */
export type FbkProductMaxCouponDiscount = {
  /** 쿠폰정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 할인금액 */
  discount_amount: Scalars['Int'];
  /**
   * 할인 타입 (정액, 정률)
   * ---
   * AMOUNT_DISCOUNT
   * RATE_DISCOUNT
   */
  discount_type: Scalars['String'];
  /**
   * 쿠폰발급여부
   * ---
   * ISSUED
   * ISSUEABLE
   * ISSUABLE
   * USED
   * EXPIRED
   * UNISSUABLE
   */
  issue_status: Scalars['String'];
  /**
   * 쿠폰 발급 유저 타입
   * ---
   * GENERAL
   * NEW
   * REPURCHASE
   * BOOKMARK
   * INACTIVE_ACCOUNT
   * MEMBERSHIP
   * REVIEW
   * TARGETING
   * STORY
   * MESSAGE
   * REFERER_NAVER
   */
  registration_type: Scalars['String'];
  /** 쿠폰정책 타이틀 */
  title: Scalars['String'];
};

/** 패바카 상품 랭킹 목록 및 요약 */
export type FbkProductRankingList = {
  /** 마지막으로 업데이트된 시간 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 패바카 상품 목록 */
  item_list: Array<FbkProduct>;
};

/** 상품 리뷰 정보 */
export type FbkProductReview = {
  /** 상품 리뷰 첨부파일 목록 */
  attachment_list: Array<FbkProductReviewAttachment>;
  /** 베스트 점수 */
  best_score: Scalars['Int'];
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 평점 */
  rating: Scalars['Int'];
};

/** 상품 리뷰 첨부 파일 */
export type FbkProductReviewAttachment = {
  /** 원본 파일 url */
  original_url: Scalars['String'];
  /** 썸네일 파일 url */
  thumbnail_url: Scalars['String'];
};

/** 상품 리뷰 특성 평가 */
export type FbkProductReviewAttribute = {
  /** 최다 답변 */
  most_answer?: Maybe<FbkProductReviewAttributeAnswer>;
  /** 질문 */
  question: FbkProductReviewAttributeQuestion;
};

/** 상품 리뷰 특성 답변 */
export type FbkProductReviewAttributeAnswer = {
  /** 특성 답변 응답수 */
  count: Scalars['Int'];
  /**
   * 한글 답변
   * ex) 화면과 비슷해요, 아주 만족해요
   */
  label: Scalars['String'];
  /** 답변 비율 */
  percent?: Maybe<Scalars['Int']>;
  /** 평점 */
  ratio: Scalars['Float'];
  /**
   * 답변 키 값
   * ex) VERY_GOOD, GOOD
   */
  value: Scalars['String'];
};

/** 상품 리뷰 특성 질문 */
export type FbkProductReviewAttributeQuestion = {
  /**
   * 질문 분류
   * ex) 퀄리티, 사이즈
   */
  category: Scalars['String'];
  /**
   * 질문 상세
   * ex) 퀄리티는 어때요?, 화면과 비교해 색감이 어때요?
   */
  label: Scalars['String'];
  /**
   * 질문 키 값
   * ex) FINISHING_TOUCH, COLOR, SIZE
   */
  value: Scalars['String'];
};

/** 활성화된 프로모션 뱃지의 id 리스트와 뱃지 이미지 url, 타입 */
export type FbkPromotionBadge = {
  /** 노출할 badge image url */
  badge_image_url: Scalars['String'];
  /** shop 혹은 product id list */
  id: Scalars['ID'];
  /**
   * 프로모션 뱃지 타입
   * [ SHOP, PRODUCT ]
   */
  type: Scalars['String'];
};

/** 활성화된 프로모션 뱃지 목록 */
export type FbkPromotionBadgeList = {
  item_list: Array<FbkPromotionBadge>;
};

/** 최근 본 상품과 연관된 상품 목록 */
export type FbkRecentViewProduct = {
  /**
   * 최근 본 상품과 연관 + 비슷한 상품 list
   * list 는 6개 혹은 12개로 전달.
   */
  product_list: Array<FbkProduct>;
  /** 최근 본 상품 정보 */
  recent_view_product: FbkProduct;
};

/** 패바카 최근 본 상품 목록 및 요약 */
export type FbkRecentVisitProductList = {
  /**
   * 다음 페이지 데이터 조회를 위한 end_cursor
   * @deprecated unsupported field
   */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 목록 */
  item_list: Array<FbkProduct>;
  /** 총 갯수 */
  total_count: Scalars['Int'];
};

/** 패바카 추천 스토어 */
export type FbkRecommendShop = {
  /** 노출 순서 */
  display_order: Scalars['Int'];
  /**
   * 그룹 이름
   * - SPORTY : 스포티
   * - CASUAL : 캐주얼
   * - ROMANTIC : 로맨틱
   * - MODERN_CHIC : 모던시크
   * - UNIQUE : 유니크
   */
  group_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 수동 업로드 이미지 */
  manual_uploaded_image_url?: Maybe<Scalars['String']>;
  /** 상품 리스트 */
  product_list: Array<FbkProduct>;
  /** shop의 id */
  shop_id: Scalars['ID'];
  /** shop 메인 도메인 */
  shop_main_domain: Scalars['String'];
  /** shop 이름 */
  shop_name: Scalars['String'];
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** shop 대표 이미지 */
  typical_image_url: Scalars['String'];
};

/** 패바카 추천 스토어 리스트 */
export type FbkRecommendShopList = {
  item_list: Array<FbkRecommendShop>;
  total_count: Scalars['Int'];
};

/** 최근 본 상품과 연관된 상품 정보 */
export type FbkRelatedProductListByRecentView = {
  /** 메인 타이틀 */
  main_title: Scalars['String'];
  /**
   * 최근 본 상품 리스트
   * 최근본 상품이 1개 이상일 때만 전달.
   */
  recent_view_product_list: Array<FbkRecentViewProduct>;
  /** 서브 타이틀 */
  sub_title: Scalars['String'];
};

export type FbkSearchKeywordSuggestion = {
  keywords: Array<Scalars['String']>;
  keywords_title: Scalars['String'];
  recent_searches_title: Scalars['String'];
};

/** 페바카 검색조건 */
export type FbkSearchProductInput = {
  /**
   * 노출카테고리 ID 목록
   * ----
   * 입력한 display_category_id_list 에 해당하는 상품을 반환한다. (최대 100개 요청 가능)
   * 대분류, 중분류, 소분류는 ;로 구분한다
   * 예) display_category_id_list: ["1388;1390;1397"]
   */
  display_category_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 빠른출발 여부 */
  only_fast_delivery?: InputMaybe<Scalars['Boolean']>;
  /** 직진배송 여부 */
  only_zigzin?: InputMaybe<Scalars['Boolean']>;
  /**
   * 정렬 순서
   * [
   * SCORE_DESC,
   * LATEST,
   * REVIEW_COUNT_DESC,
   * PRICE_DESC,
   * PRICE_ASC
   * ]
   */
  order_type?: InputMaybe<Scalars['String']>;
  /** 샵 id 리스트 */
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

/** FBK 검색 상품 목록 */
export type FbkSearchProductList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next: Scalars['Boolean'];
  /** 상품 리스트 */
  item_list: Array<FbkProduct>;
  total_count: Scalars['Int'];
};

/** 패바카 샵 */
export type FbkShop = {
  /** 샵 속성 목록 */
  attribute_list: Array<FbkShopAttribute>;
  /**
   * 북마크 갯수
   * @deprecated 미사용, 0으로 반환됨
   */
  bookmark_count?: Maybe<Scalars['Int']>;
  /** 샵 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  /** shop 도메인 이름 */
  main_domain: Scalars['String'];
  /** shop 이름 */
  name: Scalars['String'];
  /** shop ID */
  shop_id: Scalars['ID'];
  /** 스타일 목록 */
  style_list: Array<Scalars['String']>;
  /** 대표 이미지 url */
  typical_image_url?: Maybe<Scalars['String']>;
};

/** 패바카 샵 속성 */
export type FbkShopAttribute = {
  /** 속성 key */
  key: Scalars['String'];
  /** 속성 value */
  value: Scalars['String'];
};

/** 패바카 샵 목록 */
export type FbkShopList = {
  item_list: Array<FbkShop>;
};

/** 패바카 샵 메타 카테고리 */
export type FbkShopMetaCategory = {
  /** 샵 메타 카테고리 ID */
  id: Scalars['ID'];
  /** 샵 메타 카테고리 명 */
  name: Scalars['String'];
};

/** 패바카 샵 메타 카테고리 목록 및 요약 */
export type FbkShopMetaCategoryList = {
  item_list: Array<FbkShopMetaCategory>;
};

/** 패바카 샵 랭킹 */
export type FbkShopRanking = {
  /** 베스트 상품 목록 */
  best_product_list: Array<FbkProduct>;
  /** 북마크 갯수 */
  bookmark_count?: Maybe<Scalars['Int']>;
  /** 샵 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  /** shop 이름 */
  name: Scalars['String'];
  /** 랭킹 */
  ranking: Scalars['Int'];
  /** shop_id */
  shop_id: Scalars['ID'];
  /** 스타일 목록 */
  style_list: Array<Scalars['String']>;
  /** 대표 이미지 url */
  typical_image_url?: Maybe<Scalars['String']>;
};

/** 패바카 샵 랭킹 목록 및 요약 */
export type FbkShopRankingList = {
  /** 마지막으로 업데이트된 시간 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 다음 페이지 데이터 조회를 위한 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 패바카 샵 랭킹 목록 */
  item_list: Array<FbkShopRanking>;
};

/** 패바카 샵라이브 캠페인 */
export type FbkShopliveCampaign = {
  /** background image url */
  background_image_url?: Maybe<Scalars['String']>;
  /** 캠페인 키 */
  campaign_key: Scalars['String'];
  /** 캠페인 시작예정일시 */
  date_start_scheduled: Scalars['CrTimestamp'];
  /** 캠페인 설명 */
  description?: Maybe<Scalars['String']>;
  /** 로고 이미지 url */
  logo_image_url?: Maybe<Scalars['String']>;
  /** post campaign image url */
  post_campaign_image_url?: Maybe<Scalars['String']>;
  /** pre campaign image url */
  pre_campaign_image_url?: Maybe<Scalars['String']>;
  /** pre campaign video url */
  pre_campaign_video_url?: Maybe<Scalars['String']>;
  /** 샵라이브 플레이어 URL */
  shoplive_player_url: Scalars['String'];
  /**
   * 캠페인 상태
   * READY, ONAIR, ENDED
   */
  status: Scalars['String'];
  /** stream live url */
  stream_live_url: Scalars['String'];
  /** stream preview live url */
  stream_preview_live_url: Scalars['String'];
  /** stream preview replay live url */
  stream_preview_replay_live_url?: Maybe<Scalars['String']>;
  /** stream replay live url */
  stream_replay_live_url?: Maybe<Scalars['String']>;
  /** 캠페인 제목 */
  title: Scalars['String'];
};

/** 패바카 샵라이브 캠페인 목록 및 요약 */
export type FbkShopliveCampaignList = {
  /** 패바카 샵라이브 캠페인 목록 */
  item_list: Array<FbkShopliveCampaign>;
};

/** 패바카 스플래시 이미지 */
export type FbkSplashImage = {
  /** AOS 이미지 타입(image, video, gif, lottie) */
  android_image_type?: Maybe<Scalars['String']>;
  /** 안드로이드 이미지 URL */
  android_image_url: Scalars['String'];
  /** 스플래시 이미지 종료 시간 */
  date_end: Scalars['CrTimestamp'];
  /** 스플래시 이미지 시작 시간 */
  date_start: Scalars['CrTimestamp'];
  /**
   * 스플래시 이미지 ID
   * (cms banner id)
   */
  id: Scalars['ID'];
  /** IOS 이미지 타입(image, video, gif, lottie) */
  ios_image_type?: Maybe<Scalars['String']>;
  /** IOS 이미지 URL */
  ios_image_url: Scalars['String'];
};

export type FbkStorePickBanner = {
  /** 할인율 정보 */
  discount_enabled: Scalars['Boolean'];
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 쿠폰태그 */
  tag_coupon: Scalars['Boolean'];
  /** 무료배송태그 */
  tag_free_shipping: Scalars['Boolean'];
  /** 상품할인율태그 */
  tag_product_discount?: Maybe<Scalars['Int']>;
  /** 배너제목 */
  title: Scalars['String'];
};

export type FbkStorePickItem = {
  banner: FbkStorePickBanner;
  /**
   * 생성된 content_short_url
   * @description
   * ex) https://s.zigzag.kr/sp_c4kmcabi1k
   * 승인된경우에만 전달되며 승인이 안된경우 null
   */
  content_short_url?: Maybe<Scalars['String']>;
  /**
   * content page url
   * @description
   * id=${store_pick_id},${store_id}
   * 승인된경우에만 전달되며 승인이 안된경우 null
   */
  content_url?: Maybe<Scalars['String']>;
  shop: UxShopRanking;
  store_pick_id: Scalars['Int'];
};

export type FbkStorePickItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<FbkStorePickItem>;
};

export type FbkStyleContent = {
  banner_image_url?: Maybe<Scalars['String']>;
  date_event_end?: Maybe<Scalars['CrTimestamp']>;
  date_event_start?: Maybe<Scalars['CrTimestamp']>;
  event_type: Scalars['String'];
  id: Scalars['ID'];
  main_html?: Maybe<Scalars['String']>;
  related_product_list: Array<FbkProduct>;
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type FbkStyleTab = {
  content_list: Array<FbkStyleContent>;
  event_type_list: FbkEventTypeList;
};

/** 활성/비활성 탭 아이콘 url */
export type FbkTabIcon = {
  /** 선택 됐을 때, 아이콘 url */
  active_icon_url: Scalars['String'];
  /** 선택 해제 됐을 때, 아이콘 url */
  inactive_icon_url: Scalars['String'];
};

/** 활성/비활성 탭 name 색상(ex, #FF0000) */
export type FbkTabNameColor = {
  /** 선택 됐을 때, 색상 */
  active_name_color: Scalars['String'];
  /** 선택 해제 됐을 때, 색상 */
  inactive_name_color: Scalars['String'];
};

export type FbkTimeSaleCardItem = {
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  product: FbkProduct;
};

export type FbkTimeSaleCardItemList = {
  item_list: Array<FbkTimeSaleCardItem>;
};

/** 패바카 (임시) pdp category 연관 상품 목록 및 요약 데이터 */
export type FbkTmpPdpCategoryRelatedProductList = {
  /** 광고 카테고리 명 (결과값이 없는 경우 null) */
  ad_category_name?: Maybe<Scalars['String']>;
  /** 상품목록 */
  item_list: Array<FbkProduct>;
};

/** 인기 키워드 */
export type FbkTrendKeyword = {
  image_url?: Maybe<Scalars['String']>;
  keyword: Scalars['String'];
};

/** 인기 키워드 리스트 */
export type FbkTrendKeywordList = {
  item_list: Array<FbkTrendKeyword>;
};

/** 패바카 사용자계정 쿠폰 */
export type FbkUserAccountCoupon = {
  /** 쿠폰 ID */
  id: Scalars['ID'];
  /**
   * 쿠폰상태 (쿠폰서비스 값 bypass)
   * ---
   * USED: 사용완료
   * EXPIRED: 소멸
   * ISSUED: 보유중
   * UNKNOWN: 알수 없음
   */
  status: Scalars['String'];
};

/** 패바카 사용자계정 포인트 */
export type FbkUserAccountPoint = {
  /** 사용가능한 포인트 */
  available_point: Scalars['Int'];
};

export type FbkZigzinSavedShopItems = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  ui_item_list: Array<UxComponent>;
};

/** Deprecated API Object */
export type FeaturedShop = {
  /** 연령대 */
  age: Array<Scalars['String']>;
  /** 배너 이미지 URL */
  image_url: Scalars['String'];
  /** 사용자 로그 */
  log: Scalars['String'];
  /** 쇼핑몰 주 도메인 */
  shop_main_domain: Scalars['String'];
  /** 쇼핑몰 이름 */
  shop_name: Scalars['String'];
  /** 스타일 */
  style: Array<Scalars['String']>;
  /** 태그 */
  tags: Array<FeaturedShopTag>;
  /** 제목 */
  title: Scalars['String'];
};

/** Deprecated API */
export type FeaturedShopList = {
  /** Deprecated API */
  item_list: Array<FeaturedShop>;
};

/** Deprecated API Object */
export type FeaturedShopTag = {
  /** 태그 레코드 ID */
  id: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

export enum FinancialCompanyCode {
  B_BUSAN = 'B_BUSAN',
  B_CITY = 'B_CITY',
  B_DAEGU = 'B_DAEGU',
  B_GWANGJU = 'B_GWANGJU',
  B_GYEONGNAM = 'B_GYEONGNAM',
  B_HANA = 'B_HANA',
  B_HSBC = 'B_HSBC',
  B_IBK = 'B_IBK',
  B_JEJU = 'B_JEJU',
  B_JEONBUK = 'B_JEONBUK',
  B_KAKAOBANK = 'B_KAKAOBANK',
  B_KB = 'B_KB',
  B_KBANK = 'B_KBANK',
  B_KDB = 'B_KDB',
  B_KEB = 'B_KEB',
  B_NH = 'B_NH',
  B_POST = 'B_POST',
  B_SC = 'B_SC',
  B_SHINHAN = 'B_SHINHAN',
  B_SHINHYUP = 'B_SHINHYUP',
  B_SUHYUP = 'B_SUHYUP',
  B_WOORI = 'B_WOORI',
  C_BC = 'C_BC',
  C_CITY = 'C_CITY',
  C_GWANGJU = 'C_GWANGJU',
  C_HANA = 'C_HANA',
  C_HYUNDAI = 'C_HYUNDAI',
  C_JEJU = 'C_JEJU',
  C_JEONBUK = 'C_JEONBUK',
  C_KAKAOBANK = 'C_KAKAOBANK',
  C_KB = 'C_KB',
  C_KBANK = 'C_KBANK',
  C_KDB = 'C_KDB',
  C_LOTTE = 'C_LOTTE',
  C_NH = 'C_NH',
  C_POST = 'C_POST',
  C_SAMSUNG = 'C_SAMSUNG',
  C_SHINHAN = 'C_SHINHAN',
  C_SHINHYUP = 'C_SHINHYUP',
  C_SUHYUP = 'C_SUHYUP',
  C_WOORI = 'C_WOORI',
  UNKNOWN = 'UNKNOWN',
}

/** 본인인증으로 계정찾기에 대한 응답값 */
export type FindAccountWithAuthenticationResult = {
  /** 애플 계정 연동여부 */
  apple_connected: Scalars['Boolean'];
  /**
   * 이메일 정보
   * - 휴대폰 점유인증일경우 마스킹된 값 반환
   */
  email: Scalars['String'];
  /** 페이스북 계정 연동 여부 */
  facebook_connected: Scalars['Boolean'];
  /** 구글 계정 연동 여부 */
  google_connected: Scalars['Boolean'];
  /** 비밀번호 유무 */
  has_password: Scalars['Boolean'];
  /** 카카오 계정 연동여부 */
  kakao_connected: Scalars['Boolean'];
};

export type FollowItem = {
  /** 리스트의 각 섹션별 ui type명 */
  type: FollowItemType;
  /**
   * 각 섹션에서 사용될 ID
   * ex)
   * post: post ID
   * recommend_profile: profile ID
   */
  type_id: Scalars['String'];
};

export enum FollowItemType {
  POST = 'POST',
  RECOMMEND_PROFILE = 'RECOMMEND_PROFILE',
}

export type FollowListResponse = {
  item_list: Array<EPickFollow>;
};

export type FollowingListResponse = {
  item_list: Array<EPickFollow>;
};

export type FriendInvitationResult = {
  is_success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

/** 퍼널 정보 */
export type FunnelInfo = {
  /** 퍼널 구분 타입 */
  type: Scalars['String'];
};

/** 퍼널 정보의 입력 */
export type FunnelInfoInput = {
  /** 퍼널 구분 타입 */
  type: Scalars['String'];
};

export enum GaugeBarPage {
  CART = 'CART',
  EXHIBITION = 'EXHIBITION',
  HOME = 'HOME',
  PDP = 'PDP',
  SRP = 'SRP',
  STORE = 'STORE',
}

export enum GaugeStatus {
  COMPLETE = 'COMPLETE',
  DONE = 'DONE',
  PROGRESS = 'PROGRESS',
  READY = 'READY',
  TERMINATED = 'TERMINATED',
}

/** 오늘의 혜택 초대 링크 생성 input */
export type GenerateDailyMissionInvitationLinkInput = {
  /** 카카오톡 공유 링크인지 여부 */
  is_kakao_talk?: InputMaybe<Scalars['Boolean']>;
  /**
   * 초대링크 생성할 미션 타입
   * default: OPENED_RANDOM_BOX
   * ---------------
   * [
   *   OPENED_RANDOM_BOX, // 랜덤박스
   *   GAME_UP_DOWN, // 업다운 게임
   * ]
   */
  mission_type: Scalars['String'];
  /** 휴대 전화 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
};

/** 오늘의 혜택 미션 초대 링크 결과 */
export type GenerateDailyMissionInvitationLinkResult = {
  /** 초대 링크 */
  invitation_link: Scalars['String'];
};

/** PDP 공유하기 모달에서 사용할 메타 정보 */
export type GetAffiliatePdpModalMeta = {
  /** 어필레이트 대상자 여부 */
  is_member: Scalars['Boolean'];
  /**
   * 어필레이트 대상자일 때, 노출될 모달 컴포넌트
   * (is_member 가 true 일 때만 값이 존재)
   */
  member_component?: Maybe<AffiliatePdpMemberComponent>;
  /**
   * 어필레이트 대상자가 아닐 때, 노출될 모달 컴포넌트
   * (is_member 가 false 일 때만 값이 존재)
   */
  non_member_component?: Maybe<AffiliatePdpNonMemberComponent>;
};

export type GetAffiliateProductIdList = {
  /** 다음 페이지의 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 상품 id list */
  item_list: Array<Scalars['ID']>;
};

export type GetAffiliatePromotionShopList = {
  /** 브랜드 샵 list */
  brand_shop_list: AffiliatePromotionShopList;
  /** 소호 샵 list */
  soho_shop_list: AffiliatePromotionShopList;
};

export type GetBannerListResult = {
  item_list: Array<BannerItem>;
  title: Scalars['String'];
  total_count: Scalars['Int'];
};

export type GetBenefitBannerListResult = {
  item_list: Array<BenefitBanner>;
  total_count: Scalars['Int'];
};

export type GetCrmRecommendItemListResult = {
  item_list: Array<CrmRecommendItem>;
  item_type: CrmRecommendItemType;
  next_token?: Maybe<Scalars['String']>;
  total_count: Scalars['Int'];
};

export type GetCrmRecommendMetadataResult = {
  /** 헤더 이미지 */
  header_image?: Maybe<Scalars['String']>;
  /** 헤더 상품/스토어 정보 */
  header_item: Array<CrmRecommendMetadataHeaderItem>;
  /** 헤더 타이틀 */
  header_title: Scalars['String'];
  /** 메타데이터 타입 */
  metadata_type: Scalars['String'];
  /** 아이템 목록 타입 */
  recommend_type: Scalars['String'];
};

/**
 * 참여상태에 따른 데일리 미션 메뉴
 *   * 친구초대, 톡라운지 댓글 미션은 미노출
 */
export type GetDailyMissionAvailableCardAndRewardList = {
  /** 참여 가능한 데일리 미션 */
  available_daily_mission_card_and_reward_list: Array<DailyMissionCardAndReward>;
  /** 모든 미션에 참여했는지 여부 */
  is_completed_all_mission: Scalars['Boolean'];
  /** 최근 참여한 데일리 미션 */
  latest_completed_daily_mission_and_reward?: Maybe<DailyMissionCardAndReward>;
};

/** 럭키볼 참여 가능 상태 */
export type GetDailyMissionLuckyBallPlayableStatus = {
  /**
   * 현재 보유 마일리지
   * - point_mileage_wallet 의 mileage 매핑
   */
  current_mileage: Scalars['Int'];
  /**
   * 현재 보유 포인트
   * - UserAccountPointInfo 의 available_point_of_use 매핑
   * @deprecated 현재 보유한 마일리지가 내려가므로, current_mileage 사용 필요.
   */
  current_point: Scalars['Int'];
  /** 참여 가능 여부 */
  is_playable: Scalars['Boolean'];
  /**
   * 같은 번호 응모자 수
   * (응모전인 경우 -1로 전달)
   */
  same_entry_number_participant_count: Scalars['Int'];
};

/** 럭키볼 주간 통계(월~일) */
export type GetDailyMissionLuckyBallWeeklyStats = {
  /** 주별 월요일 ymd */
  date_ymd_start_week: Scalars['Int'];
  /** 이번주 당첨자 (월~일) */
  weekly_winner_count: Scalars['Int'];
  /** 이번주 누적 당첨 금액 (월~일) */
  weekly_winner_total_reward: Scalars['Int'];
};

/**
 * 주간 통계 목록
 *  - 최대 2개의 주간 통계목록 내려감 ( 이번주, 저번주 )
 */
export type GetDailyMissionLuckyBallWeeklyStatsList = {
  /** 주간 통계 목록 */
  item_list: Array<GetDailyMissionLuckyBallWeeklyStats>;
};

/**
 * 오늘의 혜택 메인화면에서 노출하는 받은 리워드
 * - 받은 리워드
 * - 추가리워드 지급 미션에 필요한 총 리워드와 받은 리워드 정보
 */
export type GetDailyMissionReceivedRewardSummary = {
  /** 추가 리워드 지급 미션에 필요한 총 리워드와 받은 리워드 */
  reached_to_target_reward_stat: DailyMissionReachedToTargetRewardStat;
  /**
   * 받은 리워드 목록
   * - 포인트, 마일리지 두개의 받은 리워드 값이 전달
   */
  received_reward_list: Array<DailyMissionReceivedReward>;
};

/** 미션 공유하기 링크 */
export type GetDailyMissionShareLink = {
  /** 미션 이미지 */
  image_url: Scalars['String'];
  /** 공유 링크 */
  share_link: Scalars['String'];
  /** 미션 공유 소개 제목 */
  title: Scalars['String'];
};

/** 활성화된 셀러 상품 찜하기 미션의 shop 값 */
export type GetDailyMissionShopProductLikedTargetShop = {
  /**
   * 샵 도메인
   * - landing url 생성시 필요한 정보
   */
  shop_domain: Scalars['String'];
  /** 샵 id */
  shop_id: Scalars['ID'];
  /** 샵 이미지 url */
  shop_image_url: Scalars['String'];
  /** 샵 이름 */
  shop_name: Scalars['String'];
};

/** UP/DOWN 정책의 최초 패배 보상 지급 현황 */
export type GetDailyMissionUpDownFirstLoseRewardStatus = {
  /** UP/DOWN 정책의 최초 패배 보상 수령 가능 여부 */
  is_rewardable: Scalars['Boolean'];
  /** UP/DOWN 정책의 최초 패배 보상 지급 여부 */
  is_rewarded: Scalars['Boolean'];
};

/** up/down 게임 참여 기록 */
export type GetDailyMissionUpDownRecord = {
  /** 최고 도달한 라운드 */
  highest_round: Scalars['Int'];
  /**
   * user_account 의 profile.nickname
   * 마스킹 처리 없이 전달
   */
  nickname: Scalars['String'];
  /** 활성화된 오늘자 up/down 정책 회차의 게임 플레이 횟수 */
  play_count: Scalars['Int'];
  /** 등수, 순위권에 들어 리워드 지급 대상일 경우에만 전달 */
  ranking?: Maybe<Scalars['Int']>;
  /** 리워드, 순위권에 들어 리워드 지급 대상일 경우에만 전달 */
  reward?: Maybe<DailyMissionUpDownWinnerReward>;
};

/** 제세공과금 심사 상태 */
export type GetDailyMissionUserDocumentInspectionResult = {
  /**
   * [
   *   WAITING, // 대기
   *   REJECTED, // 반려
   *   APPROVED // 승인
   * ]
   */
  inspected_status: Scalars['String'];
  /** 반려사유 */
  inspection_reject_reason?: Maybe<Scalars['String']>;
};

export type GetDisplayStorePickListByOrderTypeResult = {
  item_list: Array<StorePickDisplayData>;
};

export type GetDisplayStorePickListResult = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<StorePickDisplayData>;
};

export type GetEPickPostListByPostIdResponse = {
  /** 유저의 최신 게시글 id */
  item_list: Array<Maybe<Scalars['ID']>>;
  /** 등록한 유저의 nickname, imagePurl */
  profile: EPickSimpleProfile;
  /** 등록한 유저의 profile_id */
  profile_id: Scalars['ID'];
};

/** deprecated */
export type GetEPickPostListByStyleCategoryResponse = {
  item_list?: Maybe<Array<PostIdListWithCategory>>;
};

export type GetEPickPostListByStyleTagResponse = {
  /** 다음목록 호출시 after 필드에 전달 */
  end_cursor?: Maybe<Scalars['String']>;
  /** ID 리스트 */
  post_id_list?: Maybe<Array<GridViewItem>>;
};

export type GetEPickPostListByTagResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  post_id_list: Array<Scalars['ID']>;
};

export type GetEPickReReplyListByReplyIdResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<EPickReply>;
};

export type GetEPickReplyListByPostIdResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<EPickReply>;
};

export type GetEventContentReplyListResult = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<EventContentReply>;
  total_count: Scalars['Int'];
};

/**
 * 트랜드탭 노출 부스팅 상품 목록
 * ( 개인화 추천 + 광고 상품 )
 */
export type GetFbkHomeAdAndPersonalizedProductList = {
  item_list: Array<FbkProduct>;
};

/** getFbkLatestUserAccountMembership 의 결과 */
export type GetFbkLatestUserAccountMembershipResult = {
  /** 기준 연월 (YYYYMM) */
  date_ym: Scalars['Int'];
  /** 마지막 업데이트 연월일 (YYYYMMDD) */
  date_ymd_latest_updated: Scalars['Int'];
  /** 비 로그인이거나, 기준 연월에 패바카 멤버십이 없는 경우, null 을 반환한다. */
  membership?: Maybe<FbkMembership>;
};

/** 신상품이 있는 shop 정보 타입 */
export type GetFbkShopListHasNewProduct = {
  item_list: Array<FbkShop>;
};

/** getFbkUserAccountMembershipEstimation 의 결과 */
export type GetFbkUserAccountMembershipEstimationResult = {
  /** 마지막 갱신일시 */
  date_latest_updated: Scalars['CrTimestamp'];
  /** 기준 연월일 (YYYYMMDD) */
  date_ymd: Scalars['Int'];
  /**
   * 예상 멤버십 등급
   * 구매확정금액이 없는 경우 null
   */
  estimated_membership_level?: Maybe<FbkMembershipLevel>;
  /** 목표 등급이 기존 등급보다 높은지 여부 */
  is_minimum_target_membership_level_higher: Scalars['Boolean'];
  /** 최소 목표 등급 */
  minimum_target_membership_level: FbkMembershipLevel;
  /**
   * 다음 목표 멤버십 등급
   * 최소 목표 등급보다 같거나 크다.
   * 예상 멤버십 등급이 달성할 수 있는 최고등급일 경우 다음 멤버십 등급은 null 이다.
   */
  next_target_membership_level?: Maybe<FbkMembershipLevel>;
  /** 현금결제 금액 (최근 4개월) */
  paid_cash_amount: Scalars['Int'];
};

export type GetFollowListResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<FollowItem>>;
};

export type GetFollowingCountResponse = {
  count: Scalars['Int'];
};

export type GetMileageResult = {
  /** 마일리지 적립률 */
  accumulation_rate_bp: Scalars['Int'];
  /** 마일리지 적립금 계산액 */
  actual_mileage_calculated_list: Array<MileageCalculatedResult>;
};

export type GetPresignedStoryImageUrlListResponse = {
  item_list: Array<PresignedUrlInfo>;
};

export type GetPresignedUserPickPostImageUrlListResponse = {
  item_list: Array<PresignedUrlInfo>;
};

export type GetProductsPresetBannerItemResult = {
  banner_item_id: Scalars['Int'];
  banner_item_uuid: Scalars['String'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  products_preset_uuid: Scalars['String'];
  title: Scalars['String'];
};

export type GetProfileAndPostListByTopLikeResponse = {
  /** 팔로워 수 */
  follow_count: Scalars['Int'];
  /** 팔로우 여부 */
  is_follow: Scalars['Boolean'];
  nickname: Scalars['String'];
  /** 인기 포스트 이미지 list */
  post_image_list?: Maybe<Array<EPickPostImage>>;
  profile_id: Scalars['ID'];
  profile_image_url?: Maybe<Scalars['String']>;
};

export type GetProfileDefaultImageListResponse = {
  item_list?: Maybe<Array<GetProfileDefaultImageListResponseItem>>;
};

export type GetProfileDefaultImageListResponseItem = {
  url: Scalars['String'];
};

export type GetProfileImagePresignedUrlResponse = {
  /** 이미지 업로드시 사용할 presigned url */
  presigned_url: Scalars['String'];
  /** 업로드후 이미지 저장시 사용할 url */
  uploaded_url: Scalars['String'];
};

export type GetRecommendTagListResponse = {
  item_list: Array<PostTag>;
};

/** getReviewUploadPresignedUrl의 입력 */
export type GetReviewUploadPresignedUrlInput = {
  /** 업로드할 파일의 확장자명 */
  extension: Scalars['String'];
};

/** getReviewUploadPresignedUrlList의 응답 */
export type GetReviewUploadPresignedUrlListResponse = {
  item_list: Array<GetReviewUploadPresignedUrlResponse>;
};

/** 리뷰 업로드용 PresignedUrl 발급 응답 */
export type GetReviewUploadPresignedUrlResponse = {
  /** cf Url */
  cf_url: Scalars['String'];
  /** S3 Object Key */
  object_key: Scalars['String'];
  /** 발행된 Pre-Signed Url */
  presigned_url: Scalars['String'];
};

/** 리워드 사용자 월별 통계 및 정산 */
export type GetRewardUserMonthlyStatsAndSettlement = {
  /** 정산금 지급 계좌 번호 */
  bank_account_number?: Maybe<Scalars['String']>;
  /** 정산금 지급 계좌 은행 코드 */
  bank_code?: Maybe<Scalars['String']>;
  /** 정산금 소멸 예정일 */
  date_settlement_expired?: Maybe<Scalars['CrTimestamp']>;
  /** 정산금 지급 예정일 / 지급일 */
  date_settlement_scheduled?: Maybe<Scalars['CrTimestamp']>;
  /** 기준 월 (yyyy-MM) */
  date_ym: Scalars['String'];
  /** 리워드 사용자 월별 통계 */
  reward_user_monthly_stats: RewardUserMonthlyStats;
  /** 리워드 사용자 정산 */
  reward_user_settlement?: Maybe<RewardUserSettlement>;
};

/** 리워드 사용자 월별 통계, 정산 목록 및 요약 */
export type GetRewardUserMonthlyStatsAndSettlementList = {
  /** 리워드 사용자 월별 통계 및 정산 목록 */
  item_list: Array<GetRewardUserMonthlyStatsAndSettlement>;
  /** 총 목록 갯수 */
  total_count: Scalars['Int'];
};

export type GetStorePickDataResult = {
  /** 배너정보 */
  banner: StorePickBannerData;
  /**
   * 생성된 content_short_url
   * @description
   * ex) https://s.zigzag.kr/sp_c4kmcabi1k
   * 승인된경우에만 전달되며 승인이 안된경우 null
   */
  content_short_url?: Maybe<Scalars['String']>;
  /** 상세정보 */
  detail: StorePickDetailData;
  /** Products_preset 정보 */
  products_preset: StorePickProductsPresetData;
  /** Store 정보 */
  store: StorePickStoreData;
};

export type GetStorePresetResult = {
  shop_list: Array<StorePresetStoreItemResponse>;
  /** store_preset */
  uuid: Scalars['String'];
};

export type GetUserMembershipMileageResult = {
  /** 멤버십 */
  membership: GetUserMembershipResult;
  /** 마일리지 */
  mileage: GetMileageResult;
};

export type GetUserMembershipResult = {
  /**
   * 이번달 등급 산정 여부
   * @description
   * true: 이달 레벨 산정중
   * false: 산정 완료
   */
  calculation_level: Scalars['Boolean'];
  /**
   * 최근 5개월 확정 금액 합산
   * @description
   * 등급 반영달을 기준으로 최근 5개월간의 총 확정 금액
   * @default 0
   */
  confirmed_5_month_amount: Scalars['Int'];
  /**
   * 최근 6개월 확정 금액 합산
   * @description
   * 등급 반영달을 기준으로 최근 6개월간의 총 확정 금액
   * @default 0
   */
  confirmed_amount: Scalars['Int'];
  /**
   * 이달 확정 금액 합산
   * @description
   * 등급 반영달을 기준으로 등급 반영달 어제까지의 총 확정금액과 최근 2개월간의 총 확정 금액
   * 예) 현재 4월 20일인 경우 4월 19일까지의 확정금액 합산 금액 + 3월, 2월의 확정 금액
   * @default 0
   */
  confirmed_amount_now: Scalars['Int'];
  /**
   * 등급산정 년/월
   * @description
   * `YYYYMM` 형식
   */
  date_applied_ym: Scalars['Int'];
  /** 멤버십 정보 생성일시 */
  date_created: Scalars['CrTimestamp'];
  /** 멤버십 정보 업데이트 일시 */
  date_updated: Scalars['CrTimestamp'];
  /**
   * 혜택의 존재 여부
   * @description
   * true: 혜택 존재
   * false: 혜택 없음
   * PINK레벨은 혜택 없음이 기본이다.
   */
  exists_benefit: Scalars['Boolean'];
  /** 다음달 예상 레벨 (현재 나의 구매금액에 따른 예측치) */
  expected_next_month_level: MembershipLevel;
  /** ID */
  id: Scalars['ID'];
  /** 멤버십 레벨 정보 */
  membership_level: MembershipLevel;
  /**
   * 다음달 예상 레벨 상태코드
   * @description
   * `required_amount` 금액이 현재 등급 유지를 위한 경우 STAY, 다음 등급을 위함이면 UP, 더이상 레벨업이 불가능 한 경우 LAST
   */
  next_level_status: GetUserMembershipResult_NextLevelStatus;
  /**
   * 다음 등급 레벨명
   * @deprecated
   * @deprecated 아래 다음 레벨
   */
  next_level_title: Scalars['String'];
  /** 다음 등급의 레벨 조회 */
  next_membership_level: MembershipLevel;
  /**
   * 이달 혜택 수령 여부
   * @description
   * 혜택을 수령한 경우 true를 리턴한다, PINK레벨은 무조건 false
   * @default false
   */
  received_benefit: Scalars['Boolean'];
  /**
   * 현재 등급을 유지 하거나 다음 등급을 얻기 위해 필요한 금액
   * @default 0
   */
  required_amount: Scalars['Int'];
  /** 사용자 ID */
  user_account_id: Scalars['ID'];
};

export enum GetUserMembershipResult_NextLevelStatus {
  /** 최고 등급을 달성하여 더이상 레벨업이 불가능한 경우 */
  LAST = 'LAST',
  /** 현재 등급을 유지할 경우 */
  STAY = 'STAY',
  /** 다음달에 등급업이 예상될 경우 */
  UP = 'UP',
}

/** 유저레포트 조회 결과 */
export type GetUserReportResult = {
  data: Array<UserReportComponent>;
};

export type GetUxBenefitBannerListResult = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<UxBenefitBanner>;
  total_count: Scalars['Int'];
};

export type GlobalEstimatedShippingDate = {
  estimated_date: EstimatedDate;
  /** 해외주문 배송 예정일자 */
  estimated_day: EstimatedDay;
};

export type GnpQuickCategoryInfo = {
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<UxCommonImage>;
  landing_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GnpSidebarCategoryInfo = {
  display_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** 상품 */
export type Goods = {
  /** 브라우저 타입 */
  browsing_type?: Maybe<BrowsingType>;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 카탈로그 상품 타입 */
  catalog_product_type?: Maybe<CatalogProductType>;
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 최종 할인가 */
  final_price: Scalars['Int'];
  /** 무료 배송 여부 */
  free_shipping: Scalars['Boolean'];
  /** 상품 쿠폰 존재 여부 */
  has_coupon: Scalars['Boolean'];
  /** Goods ID */
  id: Scalars['String'];
  /** 상품 이미지 높이 */
  image_height?: Maybe<Scalars['Int']>;
  /** 상품 이미지 url */
  image_url: Scalars['String'];
  /** 상품 이미지 너비 */
  image_width?: Maybe<Scalars['Int']>;
  /** brand 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 신상품 여부 */
  is_new: Scalars['Boolean'];
  /** zolny 상품유무 */
  is_zonly: Scalars['Boolean'];
  /** Z페이 즉시 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 상품 최대 가격 */
  max_price: Scalars['Int'];
  /** 상품 가격 */
  price: Scalars['Int'];
  /** 자사몰 상품 번호 */
  product_no: Scalars['String'];
  /** 상품상세 url */
  product_url: Scalars['String'];
  /** 소비자가 */
  retail_price?: Maybe<Scalars['Int']>;
  /** 샵정보조회 */
  shop: ShopDetail;
  /** 쇼핑몰 레코드 ID - internal */
  shop_id: Scalars['String'];
  /** 쇼핑몰 정보 (deprecated shop -> main_domain 사용으로 교체후 제거) */
  shop_main_domain: Scalars['String'];
  /** 쇼핑몰의 상품 ID */
  shop_product_no?: Maybe<Scalars['ID']>;
  /** 상품명 */
  title: Scalars['String'];
  /** 상품 url */
  url: Scalars['String'];
  /** zpay 상품인지 여부 */
  zpay: Scalars['Boolean'];
  /** Z페이 할인액 */
  zpay_discount_price?: Maybe<Scalars['Int']>;
  /** Z페이 할인 적용 된 금액 */
  zpay_price?: Maybe<Scalars['Int']>;
};

export type GoodsCardItem = {
  /** 상품 카드의 타입 */
  type: GoodsCardItemType;
};

/** GoodsCardList 섹션의 하위 Item 타입들 */
export enum GoodsCardItemType {
  AD_DISPLAY_CARD_ITEM = 'AD_DISPLAY_CARD_ITEM',
  KEYWORD_CARD_ITEM = 'KEYWORD_CARD_ITEM',
}

/** KeywordCardItem 키워드의 추천 타입 */
export enum GoodsCardKeywordType {
  AGE_FAVORITE = 'AGE_FAVORITE',
  AGE_POPULAR = 'AGE_POPULAR',
  LATEST_POPULAR = 'LATEST_POPULAR',
  LATEST_TRENDY = 'LATEST_TRENDY',
  SEARCHED = 'SEARCHED',
}

/** 상품 카드 리스트 섹션 */
export type GoodsCardList = UiSection & {
  /** 한 로우에 들어가는 Card 개수 */
  columns_count?: Maybe<Scalars['Int']>;
  /** CardList 데이터 */
  item_list: Array<GoodsCardItem>;
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

export type GoodsCategory = {
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  properties: Scalars['Boolean'];
  sub_category_list: Array<GoodsCategory>;
};

export type GoodsCategoryCount = {
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type GoodsColor = {
  image_url: Scalars['String'];
  name: Scalars['String'];
  text_color: Scalars['Int'];
};

export type GoodsColorCount = {
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type GoodsDisplayCategory = {
  id: Scalars['ID'];
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  properties: Scalars['Boolean'];
  sub_category_list: Array<GoodsDisplayCategory>;
};

export type GoodsDisplayCategoryCount = {
  count: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GoodsFilter = {
  icon?: Maybe<SearchFilterIcon>;
  image_url?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  selected_count: Scalars['Int'];
};

export type GoodsFilterOptionInput = {
  /** 입력한 속성 조건에 해당하는 상품을 반환한다. */
  attribute_list?: InputMaybe<Array<SearchedProductAttributeListInput>>;
  /** 입력한 브랜드 ID에 해당하는 상품을 반환한다. */
  brand_id?: InputMaybe<Scalars['ID']>;
  /** 주어진 색상을 포함하는 상품 */
  color_list?: InputMaybe<Array<Scalars['String']>>;
  /**
   * 노출용 카테고리 ID
   * 대분류, 중분류, 소분류는 ;로 구분한다
   * 예) display_category_id_list: ["1388;1390;1397"]
   */
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  /** 선택된 색상의 맞춤 이미지를 가진 상품 */
  is_selected_color_image_only?: InputMaybe<Scalars['Boolean']>;
  /** 목록 갯수 제한 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 모델 사이즈 필터 */
  model_size?: InputMaybe<SearchedGoodsModelSizeInput>;
  /** 브랜드 상품 여부 */
  only_brand?: InputMaybe<Scalars['Boolean']>;
  /** 소호 상품 여부 */
  only_soho?: InputMaybe<Scalars['Boolean']>;
  /** 가격 범위 */
  price_range?: InputMaybe<SearchedGoodsPriceRange>;
  /** 상품 타입 필터 (BRAND, SOHO, ...) */
  product_group_list?: InputMaybe<Array<Scalars['String']>>;
  /** 주어진 속성을 모두 만족하는 상품 */
  properties?: InputMaybe<SearchedGoodsPropertiesInput>;
  /**
   * 주요 구매자의 연령대 조건을 하나라도 만족하는 상품
   * 10대, 20대, 30대
   */
  purchase_age_list?: InputMaybe<Array<Scalars['String']>>;
  /** 입력한 shop_id 목록에 해당하는 상품을 반환한다. (최대 100개 요청 가능) */
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 입력한 스타일 조건에 해당하는 상품을 반환한다 */
  style_list?: InputMaybe<Array<SearchedProductStyleInput>>;
  /** 해당 질의를 만족하는 상품 */
  title?: InputMaybe<Scalars['String']>;
};

/** 상품 프리셋 */
export type GoodsPreset = {
  /** 배경 색상 */
  background_color: Scalars['Int'];
  /** 프리셋 그룹 목록 */
  group_list: Array<GoodsPresetGroup>;
  /** html */
  main_html?: Maybe<Scalars['String']>;
  /** 상품 프리셋 주 이미지 */
  main_image?: Maybe<GoodsPresetMainImage>;
  /** 링크 */
  main_link?: Maybe<Scalars['String']>;
  section_header_height: Scalars['Int'];
  /** 프리셋 타입 */
  type: GoodsPresetType;
};

/** 프리셋 그룹 */
export type GoodsPresetGroup = {
  /** 배경 색상 */
  background_color: Scalars['Int'];
  /** 상품 목록 */
  goods_list: Array<Goods>;
  /** 선택되었을 때 배경 색상 */
  selected_background_color: Scalars['Int'];
  /** 선택되었을 때 텍스트 색상 */
  selected_text_color: Scalars['Int'];
  /** 텍스트 색상 */
  text_color: Scalars['Int'];
  /** 그룹 타이틀 */
  title: Scalars['String'];
};

/** 상품 프리셋 주 이미지 */
export type GoodsPresetMainImage = {
  /** 이미지 높이 */
  image_height: Scalars['Int'];
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 이미지 너비 */
  image_width: Scalars['Int'];
};

/** 상품 프리셋 타입 */
export enum GoodsPresetType {
  GROUP = 'GROUP',
  NONE = 'NONE',
  TAB = 'TAB',
}

export type GoodsPriceAndZpay = {
  /** 브라우저 타입 */
  browsing_type?: Maybe<BrowsingType>;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 최종 할인가 */
  final_price: Scalars['Int'];
  /** 무료 배송 유무 */
  free_shipping: Scalars['Boolean'];
  /** 상품 쿠폰 존재 여부 */
  has_coupon: Scalars['Boolean'];
  /** brand 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** Z페이 즉시 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 최대 가격 */
  max_price: Scalars['Int'];
  /** 가격 */
  p: Scalars['Int'];
  /** 상품상세 url */
  product_url: Scalars['String'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 쇼핑몰의 상품 ID */
  shop_product_no?: Maybe<Scalars['ID']>;
  /** zpay 상품 여부 */
  zp: Scalars['Boolean'];
};

export type GoodsPriceDistribution = {
  /**
   * 가격 분포, counts[0]은 interval*min ~ interval*(min+1)-1 사이의 가격을 가진 상품 개수를 나타낸다.
   * 높은 가격대의 수가 적은 상품은 포함되지 않는다.
   * 따라서 길이는 max-min+1 보다 작거나 같고, 값의 합은 total_count 보다 작거나 같다.
   */
  count_list: Array<Scalars['Int']>;
  /** 가격 간격 */
  interval: Scalars['Int'];
  interval_with_currency: PriceWithCurrency;
  /** 최고 가격(interval 기준) */
  max: Scalars['Int'];
  /** 최소 가격(interval 기준, 예를 들어 interval=1000, min=5이면 최소 가격은 5000원) */
  min: Scalars['Int'];
};

export type GoodsPropertiesDistribution = {
  /** 디테일 */
  detail: Array<GoodsPropertyValueCount>;
  /** 핏 */
  fit: Array<GoodsPropertyValueCount>;
  /** 사이즈/기장 */
  length: Array<GoodsPropertyValueCount>;
  /** 소재 */
  material: Array<GoodsPropertyValueCount>;
  /** 패턴 */
  pattern: Array<GoodsPropertyValueCount>;
  /** 소매기장 */
  sleeve_length: Array<GoodsPropertyValueCount>;
};

export type GoodsPropertyValueCount = {
  count: Scalars['Int'];
  value: Scalars['String'];
};

export enum GoodsStatEventType {
  CLICK = 'CLICK',
  VIDEO_VIEW = 'VIDEO_VIEW',
  VIEW = 'VIEW',
}

export enum GridSectionGridType {
  TWO_TIMES_TWO = 'TwoTimesTwo',
}

export type GridViewItem = {
  data?: Maybe<GridViewItemData>;
  /** 타입 post */
  type: Scalars['String'];
  type_id: Scalars['String'];
};

export type GridViewItemData = {
  banner_type: Scalars['String'];
};

export type HasStory = {
  /** 스토리 존재 여부 */
  has_story: Scalars['Boolean'];
  /** 새 스토리 여부 */
  is_new: Scalars['Boolean'];
};

export type HasStoryCoupon = {
  has_coupon: Scalars['Boolean'];
  issuable: Scalars['Boolean'];
};

export type HasStoryResponse = {
  has_story: Scalars['Boolean'];
  is_new: Scalars['Boolean'];
};

/** Image 로 구성된 Banner 정보 */
export type ImageBanner = {
  /** 이벤트 종료 날짜 */
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  /** 이벤트 시작 날짜 */
  date_started?: Maybe<Scalars['CrTimestamp']>;
  /** ImageBanner 고유 ID */
  id: Scalars['ID'];
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 로그 수집 정보 */
  log?: Maybe<Scalars['String']>;
  /** 포지션 정보 */
  position?: Maybe<Scalars['Int']>;
};

export type ImageBannerData = {
  aos_version_lte?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  ios_version_lte?: Maybe<Scalars['String']>;
  landing_url: Scalars['String'];
  type: BannerItemType;
};

/** ImageBannerGroup 섹션 */
export type ImageBannerGroup = UiSection & {
  /** UI 가로 / 세로 비율 */
  aspect_ratio: Scalars['Float'];
  /** ImageBannerGroup 고유 ID */
  id: Scalars['ID'];
  /** 자동 넘김 활성화/비활성화 */
  is_auto_rolling: Scalars['Boolean'];
  /** ImageBanner 리스트 */
  item_list: Array<ImageBanner>;
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
  /** 갱신 주기 (초) */
  update_interval: Scalars['Int'];
};

export type ImageFarmUploadResponse = {
  /** cf host */
  cf_host: Scalars['String'];
  /** S3 Key */
  key: Scalars['String'];
  /** 발행된 Pre-Signed Url */
  pre_signed_url: Scalars['String'];
};

export type InfoLink = {
  deep_link: Scalars['String'];
  highlight?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
};

export type InfoText = {
  highlight?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

/** 게임 초기화 input */
export type InitializeDailyMissionGameTicketGenerateMachineInput = {
  /**
   * 게임 타입
   * [
   *   UPDOWN,
   * ]
   */
  game_type: Scalars['String'];
};

export type InitializeDailyMissionRandomBoxResult = {
  /** 초대해서 받은 박스 수 */
  invitation_count: Scalars['Int'];
  /** 최종 진입 이후 생긴 박스 수 */
  new_box_count: Scalars['Int'];
  /** 열 수 있는 박스 수 */
  openable_random_box_count: Scalars['Int'];
};

/** 무이자할부목록 목업 */
export type InterestFree = {
  /** 할부개월목록 */
  installment_month_list: Array<Scalars['Int']>;
  /** 최소금액 */
  min_amount: Scalars['Int'];
};

/** 글로벌 무료배송비 띠배너 이미지 정보 */
export type InternationalFreeShippingFeeHurdleImageBanner = {
  /** 기준 금액(country기준으로 해당 국가의 통화 적용) */
  amount?: Maybe<Scalars['Int']>;
  /** 노출 이미지 주소(다크모드) */
  dark_image_url?: Maybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 노출 이미지 주소 */
  image_url?: Maybe<Scalars['String']>;
  /** 무료배송제공안함 여부(true면 무료배송제공 안함, false면 무료배송제공) */
  is_free_shipping_not_provided: Scalars['Boolean'];
};

/** 해외 배송비 정보 */
export type InternationalOrderShippingFee = {
  /** 해외 배송비 */
  amount: Scalars['Float'];
  /** 해외 배송비 */
  type: OrderShippingFeeType;
};

export type IsIssuableCouponByShopResult = {
  /** 버튼의 html title */
  html_title?: Maybe<Scalars['String']>;
  /** 해당 쇼핑몰에서 쿠폰을 발행 가능한지 여부 */
  issuable: Scalars['Boolean'];
  /** deep link */
  link_url?: Maybe<Scalars['String']>;
  /** 정책 id */
  user_account_coupon_policy_id?: Maybe<Scalars['ID']>;
};

/** isUserAccountPasswordResetTokenValid의 입력 */
export type IsUserAccountPasswordResetTokenValidInput = {
  /** 비밀번호 초기화 토큰 */
  token: Scalars['String'];
};

export type IsUserInviteEnableInput = {
  /**
   * promotion_type : 프로모션 타입
   * ---
   * FRIEND_INVITE // 친구 초대
   */
  promotion_type?: InputMaybe<Scalars['String']>;
};

export type IsValidNicknameResponse = {
  message?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** 발행 가능한 포인트금액 */
export type IssuablePoint = {
  /** 첫구매시 지급될 포인트 */
  first_purchase: Scalars['Int'];
  /** 일반구매시 지급될 포인트 */
  normal_purchase: Scalars['Int'];
  /** 상품ID */
  product_id: Scalars['ID'];
  /** 총 포인트 금액 */
  total_point: Scalars['Int'];
};

/** 발행 가능한 포인트금액 목록 */
export type IssuablePointList = {
  /** 정책 목록 */
  item_list: Array<IssuablePoint>;
};

/** 발급 가능한 쇼핑몰 쿠폰 정보 목록 */
export type IssuableShopCouponInfoList = {
  /** 발급 가능한 쇼핑몰 쿠폰 정보 목록 */
  item_list: Array<IssuableShopCouponInfoListItem>;
};

/** 쿠폰 발급이 가능한지 정보들 */
export type IssuableShopCouponInfoListItem = {
  description: Scalars['String'];
  /** 최대 할인 금액 */
  max_discount_amount: Scalars['Int'];
  /** 쇼핑몰 레코드 ID */
  shop_id: Scalars['ID'];
  /** 쿠폰 정책 레코드 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

export type IssuableShopCouponInfoV2 = {
  /** 쿠폰 정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 쿠폰을 발급받았을 경우 해당 쿠폰의 만료 일시 */
  date_issued_expire?: Maybe<Scalars['CrTimestamp']>;
  /** SHOP ID */
  shop_id: Scalars['ID'];
  /** SITE ID */
  site_id: Scalars['Int'];
  /** 쿠폰 정책 */
  user_account_coupon_policy: UserAccountCouponPolicy;
};

/** 쿠폰 발급이 가능한지 정보들 V2 */
export type IssuableShopCouponInfoV2List = {
  /** shop별 rank가 가장 높은 쿠폰 정책 정보 리스트 */
  item_list: Array<IssuableShopCouponInfoV2>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** issueCouponAsync 입력 */
export type IssueCouponAsyncInput = {
  /** 발급 할 쿠폰팩 정책 레코드 ID */
  user_account_coupon_pack_id?: InputMaybe<Scalars['ID']>;
  /** 발급 할 쿠폰 정책 레코드 ID */
  user_account_coupon_policy_id?: InputMaybe<Scalars['ID']>;
};

/** IssueCouponByCodeInput의 입력 */
export type IssueCouponByCodeInput = {
  /** 쿠폰 코드 */
  code: Scalars['String'];
};

export type IssueCouponByCouponPackInput = {
  /** 쿠폰 PACK uniq id */
  uid?: InputMaybe<Scalars['String']>;
};

/** IssueCouponByIdListInput 입력 */
export type IssueCouponByIdListInput = {
  /** user_account_pack id list */
  user_account_coupon_pack_id_list: Array<Scalars['String']>;
  /** user_account_policy id list */
  user_account_coupon_policy_id_list: Array<Scalars['String']>;
};

export type IssueCouponByIdListResult = {
  /** 쿠폰 pack ID list */
  user_account_coupon_pack_id_list?: Maybe<Array<Scalars['ID']>>;
  /** 쿠폰 정책 레코드 ID list */
  user_account_coupon_policy_id_list?: Maybe<Array<Scalars['ID']>>;
};

/** 발급할 리딤 쿠폰 입력 정보 */
export type IssueCouponByRedeemInput = {
  /** 쿠폰 code */
  code: Scalars['String'];
  /** 쿠폰 적용할 상품 정보 */
  product_info_list: Array<IssueCouponProductInfo>;
};

/** 쿠폰 모아보기 지면 쿠폰 발급 input */
export type IssueCouponCollectionCouponInput = {
  /** 암호화된 쿠폰 정책 id */
  id: Scalars['ID'];
};

/** 쿠폰 모아보기 지면 쿠폰 발급 결과 */
export type IssueCouponCollectionCouponResult = {
  /** 발급 실패 시 원인 */
  errors?: Maybe<Array<Maybe<CouponCollectionError>>>;
  /** 발급 성공 여부 */
  success: Scalars['Boolean'];
};

export type IssueCouponProductInfo = {
  /** 상품 판매 금액 */
  amount: Scalars['Int'];
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 옵션 ID */
  product_item_id?: InputMaybe<Scalars['ID']>;
  /** 상품 갯수 */
  quantity: Scalars['Int'];
  /** 판매 상품 스토어 ID */
  shop_id: Scalars['ID'];
};

/** 기획전 쿠폰 발급 요청 Agrs */
export type IssueExhibitionCouponsInput = {
  /** 쿠폰 정책 ID 목록 */
  policy_id_list: Array<Scalars['ID']>;
  /** 쿠폰팩 UID 목록 */
  uid_list: Array<Scalars['String']>;
};

/** 기획전 쿠폰 발급 요청 결과 */
export type IssueExhibitionCouponsResult = {
  /** 발급 성공 쿠폰 정책 ID 목록 */
  policy_id_list: Array<Scalars['ID']>;
  /** 발급 성공 쿠폰팩 UID 목록 */
  uid_list: Array<Scalars['String']>;
};

export type IssueFailedCouponPolicy = {
  /** 오류 코드 */
  error_code: Scalars['String'];
  /** 발급에 실패한 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

/** issueShopEventCouponCode의 오류 */
export enum IssueShopEventCouponCodeError {
  /** 이미 발급받은 사용자 */
  ALREADY_ISSUED = 'ALREADY_ISSUED',
  /** 모든 쿠폰이 소진됨 */
  EXHAUSTED = 'EXHAUSTED',
  /** 진행중인 아닌 이벤트 */
  NOT_ONGOING = 'NOT_ONGOING',
  /** 알 수 없음 */
  UNKNOWN = 'UNKNOWN',
}

/** issueShopEventCouponCode의 입력 */
export type IssueShopEventCouponCodeInput = {
  /** 쇼핑몰 이벤트 UUID */
  shop_event_uuid: Scalars['String'];
};

/** issueShopEventCouponCode의 출력 */
export type IssueShopEventCouponCodeResult = {
  /** 발행된 코드 */
  code?: Maybe<Scalars['String']>;
  /** 실행 오류 */
  error?: Maybe<IssueShopEventCouponCodeError>;
};

/** issueShopliveCoupon의 입력 */
export type IssueShopliveCouponInput = {
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

/** issueShopliveCouponPack의 입력 */
export type IssueShopliveCouponPackInput = {
  /** 쿠폰 팩 ID */
  user_account_coupon_pack_id: Scalars['ID'];
};

/** issueShopliveCouponPack의 결과 */
export type IssueShopliveCouponPackResult = {
  /** 쿠폰 팩 아이디 */
  id: Scalars['ID'];
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 쿠폰 팩 상태 */
  status: ShopliveCouponStatus;
  /** 쿠폰 리스트 */
  user_account_coupon_list: Array<ShopliveUserAccountCoupon>;
};

/** issueShopliveCoupon의 결과 */
export type IssueShopliveCouponResult = {
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 쿠폰 */
  user_account_coupon: ShopliveUserAccountCoupon;
};

export type IssueUserAccountCouponByPolicyIdListInput = {
  /** 쿠폰 정책 레코드 ID 목록 */
  user_account_coupon_policy_id_list: Array<Scalars['ID']>;
};

/** issueUserAccountCouponByPolicyIdList 결과 */
export type IssueUserAccountCouponByPolicyIdListResult = {
  /** 이미 발급 받은 쿠폰 목록 */
  already_issued_coupon_list: Array<UserAccountCoupon>;
  /** 쿠폰 발급 실퍄 목록 */
  failed_item_list: Array<IssueFailedCouponPolicy>;
  /** 쿠폰 목록 */
  item_list: Array<UserAccountCoupon>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 발급한 리딤 쿠폰 정보 */
export type IssueUserAccountCouponByRedeemResult = {
  /** 쿠폰 정책 */
  policy: UserAccountCouponPolicy;
  /** 상품별 적용 가능한 쿠폰정보 목록 */
  product_list: Array<UsableCoupon>;
  /** 사용 불가능한 쿠폰 목록 */
  unusable_coupon_list: Array<UsableCouponCheckProduct>;
  /** 사용 가능한 쿠폰 목록 */
  usable_coupon_list: Array<UsableCouponCheckProduct>;
  /** 쿠폰 정책 */
  user_account_coupon: UserAccountCoupon;
};

/** issueUserAccountShopCouponList 입력 */
export type IssueUserAccountShopCouponListInput = {
  /** Z-Only 정책 포함 여부 (기본값: true, 쇼핑몰 ID 중 Z-Only 활성화 스토어가 있을 경우에만 동작함) */
  include_zonly?: InputMaybe<Scalars['Boolean']>;
  /** 발급 가능한 쿠폰만 발급할지 여부 (false인 경우 기발급/수량에 따라 실패할 수 있음) */
  only_issuable?: InputMaybe<Scalars['Boolean']>;
  /** 상품 ID 목록 */
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 쇼핑몰 레코드 ID 목록 */
  shop_id_list: Array<Scalars['ID']>;
  /** 발급 할 쿠폰 정책 레코드 ID 목록 */
  user_account_coupon_policy_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

/** issueUserAccountShopCouponList 결과 */
export type IssueUserAccountShopCouponListResult = {
  /** 발급된 쿠폰 목록 */
  coupon_list: Array<UserAccountCoupon>;
  /** 쿠폰이 발급된 쇼핑몰 레코드 ID 목록 */
  shop_id_list: Array<Scalars['ID']>;
};

/**
 * 직잭위크 쿠폰 다운로드 입력값
 * `seller_shop_id` 또는 `seller_shop_id_list` 중 1개는 반드시 존재 해야 한다.
 * 없을 경우 다음 에러 코드가 전달 된다.
 * [Error Code]
 * - required_seller_shop_id ; `seller_shop_id` and `seller_shop_id_list` 모두 값이 없을 경우
 */
export type IssueZigzagWeekCouponInput = {
  /** 직잭위크 메타 코드 */
  meta_code: Scalars['String'];
  /** 셀러 Shop ID */
  seller_shop_id?: InputMaybe<Scalars['ID']>;
  /** 셀러 Shop ID List */
  seller_shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

/** 직잭위크 쿠폰 발급 결과 */
export type IssueZigzagWeekCouponResult = {
  /** 리워드별 요청 결과 */
  reward_list: Array<Maybe<IssueZigzagWeekReward>>;
  /** 다운로한 쿠폰팩을 지급한 셀러의 shop id 목록 */
  seller_shop_id_list: Array<Scalars['ID']>;
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
};

/** 직잭위크 리워드 정보 */
export type IssueZigzagWeekReward = ZigzagWeekUserReward & {
  /** 단일 쿠폰 정보 */
  coupon_info?: Maybe<ZigzagWeekCouponInfo>;
  /** 쿠폰팩 정보 */
  coupon_pack_info?: Maybe<ZigzagWeekCouponPackInfo>;
  /** 셀러 쿠폰 타입 */
  coupon_type: ZigzagWeekCouponType;
  /** 발급 결과 */
  result: Scalars['Boolean'];
};

export type ItemAttribute = {
  /** 다국어 */
  languages: ItemAttributeLanguages;
  /** 속성 명 */
  name: Scalars['String'];
  /** 속성 번호 */
  option_id: Scalars['ID'];
  /** 속성 값 번호 */
  option_value_id: Scalars['ID'];
  /** 속성 값 */
  value: Scalars['String'];
};

export type ItemAttributeLanguage = {
  /** 속성 명 */
  name: Scalars['String'];
  /** 속성 값 */
  value: Scalars['String'];
};

export type ItemAttributeLanguages = {
  en?: Maybe<ItemAttributeLanguage>;
  ja?: Maybe<ItemAttributeLanguage>;
  ko?: Maybe<ItemAttributeLanguage>;
};

/** 상품 최종가격 정보 */
export type ItemFinalPriceInfo = {
  /** 적용된 할인 목록 */
  discount_list: Array<OrderProductDiscountInfo>;
  /** 최대 할인 금액 */
  final_discount_amount: Scalars['Int'];
  /** 최대 할인률 */
  final_discount_rate_bp: Scalars['Int'];
  /** 최대 할인 적용가 */
  final_price: Scalars['Int'];
  /** 아이템 실제 판매가(자사몰 할인 적용가) */
  item_discount_price: Scalars['Int'];
  /** 아이템 프로모션 할인가 (ZPAY 대체, 적용 가능한 기간 할인 가격이 없으면 null */
  item_promotion_discount_price?: Maybe<Scalars['Int']>;
  /** 아이템 시중 판매가(자사몰 할인 미적용가) */
  item_sales_price: Scalars['Int'];
  /** 상품 실제 판매가 */
  product_discount_price: Scalars['Int'];
  /** 상품 시중 판매가 */
  product_original_price: Scalars['Int'];
  /** 프로모션 적용가 (적용 가능한 프로모션이 없으면 null) */
  promotion_price?: Maybe<Scalars['Int']>;
};

export type ItemForProduct = {
  /** 품목의 부가 속성 리스트 */
  attribute_list: Array<ItemAttribute>;
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 품목 노출 상태 */
  display_status: ProductDisplayStatus;
  id: Scalars['ID'];
  /** 품목 코드 */
  item_code?: Maybe<Scalars['String']>;
  /** 품목의 재고 */
  item_inventory?: Maybe<ItemInventory>;
  /** 품목명 */
  name: Scalars['String'];
  /** 옵션 타입 */
  option_type: ItemProductOptionType;
  /** 품목 판매 상태 */
  sales_status: ProductSalesStatus;
  /** site_country 별 아이템 정보 */
  site_country_list: Array<ItemSiteCountry>;
  /** 도매 Item ID */
  wholesale_item_id?: Maybe<Scalars['ID']>;
};

export type ItemInventory = {
  /** 판매된 재고 누적량 */
  assigned_quantity: Scalars['Int'];
  deleted: Scalars['Boolean'];
  /** 총 재고 */
  total_quantity: Scalars['Int'];
};

export type ItemList = {
  item_list?: Maybe<Array<ProductItem>>;
};

export type ItemListInput = {
  id_list: Array<Scalars['ID']>;
};

export enum ItemProductOptionType {
  ADDITIONAL = 'ADDITIONAL',
  BASIC = 'BASIC',
}

/** 아이템의 프로모션 정보 */
export type ItemPromotionAppliedInfo = {
  /** BOGO 프로모션 적용 수량 (1+N 에서 N값을 담고있다) */
  applied_quantity?: Maybe<Scalars['Int']>;
  /** 프로모션 쿠폰 적용 가능 여부 */
  is_coupon_usable: Scalars['Boolean'];
  /** 최소 주문 금액 (첫구매 시 필요) */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 프로모션 할인 적용 가격 (단일 가격 * quantity) */
  promotion_applied_discount_price: Scalars['Int'];
  /** 프로모션 할인 적용 단일 가격 */
  promotion_applied_item_discount_price: Scalars['Int'];
  /** 프로모션 아이디 */
  promotion_id: Scalars['ID'];
  /** 프로모션 상품 ID */
  promotion_product_id?: Maybe<Scalars['ID']>;
  /** 프로모션 아이템 ID */
  promotion_product_item_id?: Maybe<Scalars['ID']>;
  /** 프로모션 타입 */
  promotion_type: PromotionType;
};

export type ItemSiteCountry = {
  /** country code */
  country: CountryCode;
  /** 할인이 적용된 품목 판매가격 */
  discount_price: Scalars['Int'];
  /**
   * 정상가
   * @deprecated sales_price 를 사용
   */
  original_price: Scalars['Int'];
  /** 품목 할인가 */
  sales_price: Scalars['Int'];
  /** site_id */
  site_id: Scalars['Int'];
};

export type ItemTotalWeightAndVolume = {
  volume?: Maybe<Scalars['Int']>;
  volume_price?: Maybe<Scalars['Int']>;
  weight: Scalars['Int'];
  weight_price?: Maybe<Scalars['Int']>;
};

/** 주소 정보 */
export type JapanAddress = {
  /** 주소 (도도부현 + 시정촌) */
  address: Scalars['String'];
  /** 시정촌 */
  city: Scalars['String'];
  /** 우편번호 */
  postcode: Scalars['String'];
  /** 도도부현 */
  state: Scalars['String'];
};

/** 검색 Keyword 가 포함된 상품 카드 */
export type KeywordCardItem = GoodsCardItem & {
  /** 백그라운드 이미지 주소 */
  image_url: Scalars['String'];
  /** 검색 키워드 정보 */
  keyword: Scalars['String'];
  /** 키워드 추천 타입 */
  keyword_type: GoodsCardKeywordType;
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** 카드에 표시할 텍스트 배열 */
  texts: Array<Scalars['String']>;
  /** 상품 카드의 타입 */
  type: GoodsCardItemType;
};

/** Kitto 카테고리 */
export type KittoCategory = {
  first: Scalars['String'];
  first_display_name: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  second: Scalars['String'];
  second_display_name: Scalars['String'];
};

/** Kitto 카테고리 인풋 */
export type KittoCategoryInput = {
  first: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  second: Scalars['String'];
};

export type KittoContent = {
  /** 카테고리 */
  category: KittoCategory;
  /** 콘텐츠 타입 */
  content_type: KittoContentType;
  /** 에디터명 */
  editor: KittoEditor;
  /** 해시태그 목록 */
  hash_tags?: Maybe<Array<Scalars['String']>>;
  /** Kitto Content ID */
  id: Scalars['ID'];
  /** 대표 이미지 url */
  image_url: Scalars['String'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 발행 일시 */
  published_at: Scalars['CrTimestamp'];
  /** 좋아요 이모지 */
  reaction?: Maybe<KittoReaction>;
  /** 콘텐츠 타이틀 */
  title: Scalars['String'];
  /** 조회 수 */
  view_count: Scalars['Int'];
};

export type KittoContentDetail = {
  /** 카테고리 */
  category: KittoCategory;
  /** 콘텐츠 타입 */
  content_type: KittoContentType;
  /** 콘텐츠 본문 */
  description: Scalars['String'];
  /** 에디터명 */
  editor: KittoEditor;
  /** 해시태그 목록 */
  hash_tags?: Maybe<Array<Scalars['String']>>;
  /** Kitto Content ID */
  id: Scalars['ID'];
  /** 대표 이미지 url */
  image_url: Scalars['String'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 발행 일시 */
  published_at: Scalars['CrTimestamp'];
  /** 좋아요 이모지 */
  reaction?: Maybe<KittoReaction>;
  /** seo tags */
  seo_tags?: Maybe<Array<Scalars['String']>>;
  /** 콘텐츠 타이틀 */
  title: Scalars['String'];
  /** 조회 수 */
  view_count: Scalars['Int'];
};

export type KittoContentListResult = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next: Scalars['Boolean'];
  item_list: Array<KittoContent>;
  total_count: Scalars['Int'];
};

export type KittoContentPage = KittoPage & {
  item_list?: Maybe<Array<KittoContent>>;
  page: Scalars['Int'];
  page_count: Scalars['Int'];
  page_size: Scalars['Int'];
  total_count: Scalars['Int'];
};

/** Kitto 컨텐츠 타입 */
export enum KittoContentType {
  NORMAL = 'NORMAL',
  PLANNED = 'PLANNED',
}

export type KittoCreateQuestionInput = {
  /** 질문 내용 */
  content: Scalars['String'];
  /** 언어 */
  language?: InputMaybe<Scalars['String']>;
  /** 닉네임 */
  nickname: Scalars['String'];
};

export type KittoEditor = {
  /** 에디터 이력 */
  background: Scalars['String'];
  /** Kitto Editor ID */
  id: Scalars['ID'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 에디터명 */
  name: Scalars['String'];
  /** 에디터 프로필 이미지 */
  profile_image_url: Scalars['String'];
};

export type KittoEditorDetail = {
  /** 이력 */
  background: Scalars['String'];
  /** 상세소개 */
  description: Scalars['String'];
  /** 에디터 태그 */
  editor_tags?: Maybe<Array<Scalars['String']>>;
  /** Kitto Editor ID */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 프로필 이미지 */
  profile_image_url: Scalars['String'];
  /** SNS 링크 */
  sns_links: SnsLink;
};

/** 1차 카테고리 */
export type KittoFirstCategory = {
  display_name: Scalars['String'];
  image_url: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type KittoHashTag = {
  content_count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type KittoNewContentInput = {
  first_category: Scalars['String'];
  page_input: KittoPageInput;
};

/** 페이지 처리를 위한 기본 객체 */
export type KittoPage = {
  page: Scalars['Int'];
  page_count: Scalars['Int'];
  page_size: Scalars['Int'];
  total_count: Scalars['Int'];
};

export type KittoPageInput = {
  /** 페이지 번호 */
  page: Scalars['Int'];
  /** 페이지 사이즈 */
  page_size: Scalars['Int'];
};

export type KittoRankingEditor = {
  /** 콘텐츠 리스트 */
  content_list?: Maybe<Array<KittoContent>>;
  /** 콘텐트 총 수 */
  content_total: Scalars['Int'];
  /** 에디터 */
  editor: KittoEditorDetail;
};

export type KittoRankingEditorPage = KittoPage & {
  item_list?: Maybe<Array<KittoRankingEditor>>;
  page: Scalars['Int'];
  page_count: Scalars['Int'];
  page_size: Scalars['Int'];
  total_count: Scalars['Int'];
};

export type KittoReaction = {
  /** 좋아요 총 수 */
  count: Scalars['Int'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 리액션 정보 */
  reactions: Array<KittoReactionItem>;
  recent_reactions: Array<KittoReactionType>;
};

export type KittoReactionItem = {
  /** 리액션 수 */
  count: Scalars['Int'];
  type: KittoReactionType;
};

export enum KittoReactionType {
  ANTICIPATED = 'ANTICIPATED',
  CLAP = 'CLAP',
  CLOVER = 'CLOVER',
  EMPATHIZE = 'EMPATHIZE',
  FIRE = 'FIRE',
  HEART = 'HEART',
  HELPFUL = 'HELPFUL',
  HUNDRED = 'HUNDRED',
  LIKE = 'LIKE',
}

export type KittoRecommendEditor = {
  /** 콘텐츠 리스트 */
  content_list?: Maybe<Array<KittoContent>>;
  /** 에디터 */
  editor: KittoEditorDetail;
};

export enum LanguageRegistrationType {
  SELLER = 'SELLER',
  SYSTEM = 'SYSTEM',
}

/** 언어 코드 */
export enum LanguageType {
  EN = 'en',
  JA = 'ja',
  KO = 'ko',
}

/** 상품 옵션 정보 */
export type LegacyProductOption = {
  /** 연동형 상품 주문에 쓰이는 option key */
  code: Scalars['String'];
  /** 필수로 선택해야 하는 옵션인지 */
  is_required: Scalars['Boolean'];
  /** 옵션명 */
  name: Scalars['String'];
  /** 상품 아이템 옵션 타입 */
  option_type: ProductItemOptionType;
  /**
   * 옵션 리스트
   * @deprecated Use value_set
   */
  value_list: Array<Scalars['String']>;
  /** 옵션 값 목록 */
  value_set: Array<LegacyProductOptionValue>;
};

/** option 선택 값 */
export type LegacyProductOptionValue = {
  /** 연동형 상품에서 해당 옵션값 선택 시 추가금액 */
  additional_price: Scalars['Int'];
  /** 연동형 상품 주문에 쓰이는 option value key */
  code: Scalars['String'];
  name: Scalars['String'];
};

export type LegacyTextOption = {
  is_required: Scalars['Boolean'];
  max_length: Scalars['Int'];
  name: Scalars['String'];
};

export type LikeTalkLoungeReplyInput = {
  enabled: Scalars['Boolean'];
  reply_id: Scalars['ID'];
  talk_id: Scalars['ID'];
};

export type LikeTalkLoungeReplyResponse = {
  like_count: Scalars['Int'];
};

export type LikeTalkLoungeTalkInput = {
  enabled: Scalars['Boolean'];
  talk_id: Scalars['ID'];
};

export type LikeTalkLoungeTalkResponse = {
  like_count: Scalars['Int'];
};

export type LikedPostListResponse = {
  item_list: Array<EPickLike>;
};

export type LocaleCode = {
  aos_code: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['ID'];
  ios_code: Scalars['String'];
  label: Scalars['String'];
  type: LocaleCodeType;
};

/** Locale code 목록 */
export type LocaleCodeList = {
  item_list: Array<LocaleCode>;
  total_count: Scalars['Int'];
};

/** Locale code type */
export enum LocaleCodeType {
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  LANGUAGE = 'LANGUAGE',
}

/** loginApple 의 입력 */
export type LoginAppleInput = {
  /** 애플 Bundle Id */
  bundle_id: Scalars['String'];
  code: Scalars['String'];
  full_name?: InputMaybe<Scalars['String']>;
};

export type LoginAppleResult = {
  /** 사용자 계정 정보 */
  user_account: UserAccount;
};

/**
 * 로그인 버튼 노출 순서
 * EMAIL, KAKAO, APPLE, FACEBOOK, GOOGLE, MOBILE
 */
export type LoginButtonListResult = {
  common_item_list?: Maybe<Array<Scalars['String']>>;
  highlight_item_list?: Maybe<Array<Scalars['String']>>;
};

/** 로그인 공통 결과 */
export type LoginCommonResult = {
  /** 사용자 계정 정보 */
  user_account: UserAccount;
};

export type LoginFacebookInput = {
  /** 로그인 인증 토큰 */
  access_token: Scalars['String'];
};

export type LoginFacebookResult = {
  /** 사용자 계정 정보 */
  user_account: UserAccount;
};

export type LoginGoogleInput = {
  /** 로그인 인증 토큰 */
  token: Scalars['String'];
};

export type LoginGoogleResult = {
  /** 사용자 계정 정보 */
  user_account: UserAccount;
};

/** login의 입력 */
export type LoginInput = {
  /** 이메일 */
  email: Scalars['String'];
  /**
   * 자동 로그인 여부
   * - 웹에서 사용
   */
  enable_auto_login?: InputMaybe<Scalars['Boolean']>;
  /** 암호 */
  password: Scalars['String'];
};

/** loginKakao 의 입력 */
export type LoginKakaoInput = {
  access_token: Scalars['String'];
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 점유 뺏어오기 여부 */
  is_occupied_mobile_tel?: InputMaybe<Scalars['Boolean']>;
  /** 인증된 사용자 핸드폰 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
  refresh_token: Scalars['String'];
};

export type LoginKakaoResult = {
  /** 사용자 이메일 */
  email: Scalars['String'];
  /** 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 인증 시점에 계정이 생성됐는지 여부 */
  is_created?: Maybe<Scalars['Boolean']>;
  /** 가입 완료 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 계정 정보 */
  user_account?: Maybe<UserAccount>;
  /** 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다 */
  uuid: Scalars['String'];
};

/** loginMobile의 입력 */
export type LoginMobileInput = {
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 휴대폰 번호 인증 토큰 */
  token: Scalars['String'];
};

/** 휴대폰 로그인 2차인증 공통 입력 */
export type LoginMobileWith2FaInput = {
  /** 본인인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key: Scalars['String'];
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
};

/** login의 출력 */
export type LoginResult = {
  /**
   * 에러 코드
   * - auth_authentication_failed: 이메일 또는 비밀번호가 틀립니다
   * - auth_inactive_user_account: 사용자 계정이 휴면 상태입니다
   */
  error_code?: Maybe<Scalars['String']>;
  /** 에러 발생시 추가 정보 */
  error_info?: Maybe<LoginResultErrorInfo>;
  /** 에러 메시지 */
  error_message?: Maybe<Scalars['String']>;
  /** 로그인한 사용자 이름 (로그인 성공시) */
  full_name?: Maybe<Scalars['String']>;
  /** 성공여부 */
  success: Scalars['Boolean'];
  /** 계정 정보 */
  user_account?: Maybe<UserAccount>;
  /** 로그인한 사용자의 UUID (로그인 성공시) */
  uuid?: Maybe<Scalars['String']>;
};

/** 에러 발생시 추가 정보 */
export type LoginResultErrorInfo = {
  /**
   * authenticate_with_social, user_account_regiseterd_with_social 발생시 가입한 소셜 정보
   * GENERAL, KAKAO, APPLE, GOOGLE, FACEBOOK
   */
  init_type?: Maybe<Scalars['String']>;
  /**
   * 비밀번호 인증 에러 카운트
   * - too_many_verify_password_warning
   * - too_many_verify_password_blocked
   * - auth_authentication_failed
   */
  password_error_count?: Maybe<Scalars['Int']>;
};

export type MainBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type MainBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type MainHomeLimitedTimeSaleGroup = DdpData & {
  id: Scalars['ID'];
  more_button?: Maybe<UxButton>;
  products?: Maybe<Array<DdpProductCardItem>>;
  title_html: UxCommonHtmlText;
  type: DdpComponentType;
};

export type MainHomeLimitedTimeSaleGroupInternal = {
  date_created?: Maybe<Scalars['CrTimestamp']>;
  date_updated: Scalars['CrTimestamp'];
  end_time: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  landing_type: Scalars['String'];
  landing_url?: Maybe<Scalars['String']>;
  last_modifier: Scalars['String'];
  products?: Maybe<Array<Scalars['ID']>>;
  start_time: Scalars['CrTimestamp'];
  time_text?: Maybe<UxCommonText>;
  title_first?: Maybe<Scalars['String']>;
  title_second?: Maybe<Scalars['String']>;
  title_third?: Maybe<Scalars['String']>;
};

export type MainHomePromotionBanner = {
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  ratio?: Maybe<Scalars['Float']>;
  thumbnail_image_url: Scalars['String'];
};

export type MainHomePromotionBannerGroup = DdpData & {
  ddp_promotion_banners?: Maybe<Array<MainHomePromotionBanner>>;
  id: Scalars['ID'];
  title: UxCommonText;
  type: DdpComponentType;
};

export type MainHomePromotionBannerGroupInternal = {
  date_created?: Maybe<Scalars['CrTimestamp']>;
  date_updated: Scalars['CrTimestamp'];
  ddp_promotion_banners?: Maybe<Array<MainHomePromotionBannerInternal>>;
  end_date: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  is_activated: Scalars['Boolean'];
  last_modifier: Scalars['String'];
  start_date: Scalars['CrTimestamp'];
  title: UxCommonText;
};

export type MainHomePromotionBannerInternal = {
  date_created: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  is_deleted: Scalars['Boolean'];
  landing_url: Scalars['String'];
  last_modifier: Scalars['String'];
  thumbnail_image_url: Scalars['String'];
};

export type MainHomeRecommendStore = {
  background_color: UxCommonColor;
  landing_url?: Maybe<Scalars['String']>;
  products?: Maybe<Array<DdpProductCardItem>>;
  store_name: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type MainHomeRecommendStoreGroup = DdpData & {
  header?: Maybe<DdpHeader>;
  id: Scalars['ID'];
  store_list?: Maybe<Array<Maybe<MainHomeRecommendStore>>>;
  type: DdpComponentType;
};

export type ManagedCategoryResponse = {
  /** 카테고리 계층 */
  hierarchy?: Maybe<CategoryHierarchy>;
  /** category DB의 category id */
  id: Scalars['ID'];
};

/** 피쳐드 쇼핑몰의 각 태그 아이템 */
export type ManagedShops = {
  /** 디테일 설명 */
  description: Scalars['String'];
  /** 피쳐드 쇼핑몰의 각 태그에 속하는 쇼핑몰 목록 */
  shops: Array<ManagedShopsItem>;
  /** 제목 */
  title: Scalars['String'];
  /** UI 유형 */
  type: UiType;
};

/** 쇼핑몰에 속하는 상품 */
export type ManagedShopsGoods = {
  /** 상품 이미지 URL */
  image_url: Scalars['String'];
  /** 사용자 로그 */
  log: Scalars['String'];
  /** 상품 URL */
  url: Scalars['String'];
};

/** 피쳐드 쇼핑몰의 각 태그에 속하는 쇼핑몰 */
export type ManagedShopsItem = {
  /** 연령대 */
  age: Array<Scalars['String']>;
  /** 쇼핑몰의 상품 목록 */
  goods: Array<ManagedShopsGoods>;
  /** 이미지 URL */
  image_url: Scalars['String'];
  /** 사용자 로그 */
  log: Scalars['String'];
  /** 주 도메인 */
  main_domain: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 스타일 */
  style: Array<Scalars['String']>;
};

/** 피쳐드 쇼핑몰의 태그 리스트 */
export type ManagedShopsList = {
  /** 피쳐드 쇼핑몰 태그 목록 */
  item_list: Array<ManagedShops>;
};

/** markCartItemListAsDeleted의 입력 */
export type MarkCartItemListAsDeletedInput = {
  /** CartItem 식별자 리스트 */
  cart_item_identifier_list: Array<CartItemIdentifierInput>;
};

/** 읽음 표시 입력 */
export type MarkUserNotificationAsReadInput = {
  /** 알림 목록 내용 */
  notification_id_list: Array<Scalars['ID']>;
  /** 스토어 ID */
  shop_id?: InputMaybe<Scalars['ID']>;
};

/** 최대 할인으로 적용된 쿠폰 정보 */
export type MaxAppliedCoupon = {
  /** 쿠폰ID */
  coupon_id: Scalars['ID'];
  /** 할인금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰에 적용된 product_item_id 목록 */
  product_item_id_list: Array<Scalars['ID']>;
  /** 쿠폰에 적용된 shop_id 목록 */
  shop_id_list: Array<Scalars['ID']>;
};

/** 최대 할인 적용 가능한 쿠폰조합 정보 */
export type MaxDiscountInfo = {
  /** 적용된 쿠폰 목록 */
  applied_coupon_list: Array<MaxAppliedCoupon>;
  /** 사용 가능한 쿠폰 조합 목록 */
  coupon_combination_list: Array<CouponCombination>;
  /** 총 할인금액 */
  total_discount_amount: Scalars['Int'];
};

/** 쿠폰 사용한 아이템 입력 */
export type MaximumUsableMileageCouponInfoInput = {
  /** 주문한 아이템 레코드 ID(상품쿠폰 사용시 입력) */
  product_item_id?: InputMaybe<Scalars['ID']>;
  /** 유저 쿠폰 레코드 ID */
  user_account_coupon_id: Scalars['ID'];
};

/** 마일리지 최대 사용금액 조회를 위한 상품 정보 */
export type MaximumUsableMileageProductInfoInput = {
  /** 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 프로모션 적용 유무 */
  promotion_applied: Scalars['Boolean'];
  /** 수량 */
  quantity: Scalars['Int'];
};

export type MembershipBenefitByLevel = {
  /** 등급 코드 */
  code: Scalars['String'];
  /** 혜택으로 지급 되는 쿠폰 목록 */
  coupon_benefit_list: Array<MembershipBenefitCouponPolicy>;
  date_created: Scalars['CrTimestamp'];
  date_deleted?: Maybe<Scalars['CrTimestamp']>;
  date_updated: Scalars['CrTimestamp'];
  /** 등급 설명 */
  description: Scalars['String'];
  /** 등급 기준 종료 금액, -1 무제한 */
  end_level_amount: Scalars['Int'];
  /** 맴버십 마일리지 적립 금액 조회 */
  getMembershipMileageDepositList: MembershipMileageDepositList;
  getTotalCalculatedMileage: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  /** 혜택 정책 목록 */
  membership_benefit_policy_list?: Maybe<Array<MembershipBenefitPolicy>>;
  /** Membership Level UI 스타일 세트 */
  membership_level_ui_style_set?: Maybe<MembershipLevelUiStyleSet>;
  /** 마일리지 적립률 */
  mileage_accumulation_rate_bp: Scalars['Int'];
  /** 우선순위, 숫자가 높을 수록 높은 우선순위 */
  priority: Scalars['Int'];
  /** 등급 기준 시작 금액 */
  start_level_amount: Scalars['Int'];
  /** 등급 이름 */
  title: Scalars['String'];
};

export type MembershipBenefitByLevelGetMembershipMileageDepositListArgs = {
  target_id_list: Array<Scalars['ID']>;
};

export type MembershipBenefitByLevelGetTotalCalculatedMileageArgs = {
  shop_actual_payment_amount_list: Array<ShopActualPaymentAmount>;
};

export type MembershipBenefitByLevelList = {
  /** 아이템 목록 */
  item_list: Array<MembershipBenefitByLevel>;
};

/** @deprecated */
export type MembershipBenefitCouponPackPolicy = {
  /** 쿠폰 정책 목록 */
  membership_benefit_coupon_policy_list: Array<MembershipBenefitCouponPolicy>;
  /** 쿠폰팩 Title */
  title: Scalars['String'];
  /** 쿠폰팩 uid Prefix */
  uid_prefix: Scalars['String'];
};

/** @deprecated */
export type MembershipBenefitCouponPolicy = {
  /** 쿠폰 정책 생성 수, 0인 경우 생성 하지 않음 */
  coupon_policy_issued_quantity: Scalars['Int'];
  /** 쿠폰 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰이 최대로 할인 해줄 수 있는 금액 */
  max_discount_amount: Scalars['Int'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 이름 */
  title: Scalars['String'];
};

export type MembershipBenefitPolicy = {
  date_created: Scalars['CrTimestamp'];
  date_deleted?: Maybe<Scalars['CrTimestamp']>;
  /** 혜택 적용 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 혜택 적용 시작 일시 */
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  /** 혜택 설명 */
  description?: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['ID'];
  /** 쿠폰 생성을 위한 생성 정책 */
  membership_benefit_coupon_pack_policy?: Maybe<MembershipBenefitCouponPackPolicy>;
  /** 멤버십 레벨 정보 */
  membership_level: MembershipLevel;
  /** 혜택 정책 명 */
  title: Scalars['String'];
  /** 혜택 타입 */
  type: MembershipBenefitType;
};

export enum MembershipBenefitType {
  ADD = 'ADD',
  DEFAULT = 'DEFAULT',
}

/** 멤버십 쿠폰팩 관련정보 */
export type MembershipCouponPackResult = {
  /** 수령 여부 */
  is_issued: Scalars['Boolean'];
  /** 멤버십 이미지 url */
  membership_list_icon_url: Scalars['String'];
  /** 유저 멤버십 레벨 코드 */
  user_membership_level_code: Scalars['String'];
  /** 유저 멤버십 레벨 이름 */
  user_membership_level_title: Scalars['String'];
};

export type MembershipLevel = {
  /** 등급 코드 */
  code: Scalars['String'];
  date_created: Scalars['CrTimestamp'];
  date_deleted?: Maybe<Scalars['CrTimestamp']>;
  date_updated: Scalars['CrTimestamp'];
  /** 등급 설명 */
  description: Scalars['String'];
  /** 등급 기준 종료 금액, -1 무제한 */
  end_level_amount: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  /**
   * 혜택 정책 목록
   * @deprecated
   * @deprecated 멤버십 혜택을 정책을 통한 생성이 아닌 매핑형식으로 변경
   */
  membership_benefit_policy_list?: Maybe<Array<MembershipBenefitPolicy>>;
  /** Membership Level UI 스타일 세트 */
  membership_level_ui_style_set: MembershipLevelUiStyleSet;
  /** 마일리지 적립률 */
  mileage_accumulation_rate_bp: Scalars['Int'];
  /** 우선순위, 숫자가 높을 수록 높은 등급 */
  priority: Scalars['Int'];
  /** 등급 기준 시작 금액 */
  start_level_amount: Scalars['Int'];
  /** 등급 이름 */
  title: Scalars['String'];
};

export type MembershipLevelUiStyleSet = {
  /**
   * Membership Level Benefit Url
   * Web 멤버십 레벨에서 이동할 Url
   */
  benefit_url?: Maybe<Scalars['String']>;
  /**
   * Membership Level Color
   * Web 멤버십 레벨 목록에서 사용될 color code
   */
  color_code: Scalars['String'];
  /**
   * Membership Level List Icon
   * Web 멤버십 레벨 목록에 노출
   */
  list_icon_url: Scalars['String'];
  /**
   * Membership Main Lottie Icon
   * Web 유저 멤버십 정보에 노출
   */
  main_icon_url: Scalars['String'];
  /**
   * Membership Level Sub Color
   * Web 멤버십 레벨 목록에서 그라데이션으로 사용될 color code
   */
  sub_color_code?: Maybe<Scalars['String']>;
  /**
   * Membership Sub Lottie Icon
   * 5탭 유저 멤버십 정보에 노출
   */
  sub_icon_url: Scalars['String'];
};

export type MembershipMileageDeposit = {
  /** 맴버십 마일리지 적립 금액 */
  amount: Scalars['Int'];
  /** 맴버십 마일리지 적립 대상 */
  target_id: Scalars['ID'];
};

export type MembershipMileageDepositList = {
  item_list: Array<MembershipMileageDeposit>;
};

export type MetaCatalogProductInfo = {
  browsing_type: UxBrowsingType;
  id: Scalars['ID'];
  is_able_to_buy: Scalars['Boolean'];
  is_link_visible: Scalars['Boolean'];
  name: Scalars['String'];
  option_count_diff_list: Array<UxOptionCountDiff>;
  option_list: Array<UxCatalogProductOption>;
  pdp_url: Scalars['String'];
};

/** 메타데이터 */
export type Metadata = {
  /** 배너 데이터 */
  banner?: Maybe<MetadataBanner>;
  /** 활성화 기능 목록 */
  feature_list: Array<Scalars['String']>;
  /** 주입 스크립트 */
  inject_script: MetadataInjectScript;
  /** 아이템 탭 배지 데이터 */
  items_tab_badge?: Maybe<MetadataItemsTabBadge>;
  /** 카카오 싱크 유도 버튼 문구 */
  kakao_connect_encourage_message: MetadataKakaoConnectEncourageMessage;
  /** 연결 스크립트 */
  link_script: MetadataLinkScript;
  /** 최소 동작 버전 */
  minimum_version: Scalars['String'];
  /** 공지사항 데이터 */
  notice: MetadataNotice;
  /** 상품 검색 추천 데이터 */
  search_goods_suggestion: MetadataSearchGoodsSuggestion;
  /** 서버시간 */
  server_time: Scalars['CrTimestamp'];
  /** 쇼핑몰 데이터 */
  shop: MetadataShop;
  /** epick profile 노출 여부 */
  show_epick_profile: Scalars['Boolean'];
  /** 서포트 페이지 데이터 */
  support: MetadataSupport;
  /** URL 패턴 데이터 */
  url_pattern: MetadataUrlPattern;
  /** Z결제 데이터 */
  zpay: MetadataZpay;
};

/** 배너 데이터 메타테이터 */
export type MetadataBanner = {
  /** HTML URL */
  html_url?: Maybe<Scalars['String']>;
  /** 이미지/HTML 높이 */
  image_height: Scalars['Int'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 이미지/HTML 너비 */
  image_width: Scalars['Int'];
  /** 연결 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 제목 */
  title: Scalars['String'];
};

/** 주입 스크립트 메타테이터 */
export type MetadataInjectScript = {
  /** at_doc_end 갱신일자 */
  at_doc_end_date_updated: Scalars['CrTimestamp'];
  /** at_doc_end URL */
  at_doc_end_url: Scalars['String'];
  /** at_doc_start 갱신일자 */
  at_doc_start_date_updated: Scalars['CrTimestamp'];
  /** at_doc_start URL */
  at_doc_start_url: Scalars['String'];
  /** at_load_finish 갱신일자 */
  at_load_finish_date_updated: Scalars['CrTimestamp'];
  /** at_doc_start URL */
  at_load_finish_url: Scalars['String'];
};

/** 아이템 탭 배지 데이터 메타테이터 */
export type MetadataItemsTabBadge = {
  /** 데이터 갱신일자 */
  date_updated: Scalars['CrTimestamp'];
  /** 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 종류 - event, hot, new, play */
  type?: Maybe<Scalars['String']>;
};

/** 카카오 싱크 유도 메세지 문구 */
export type MetadataKakaoConnectEncourageMessage = {
  my_page?: Maybe<Scalars['String']>;
};

/** 연결 스크립트 메타테이터 */
export type MetadataLinkScript = {
  /** delivery 갱신일자 */
  delivery_date_updated: Scalars['CrTimestamp'];
  /** delivery URL */
  delivery_url: Scalars['String'];
  /** login 갱신일자 */
  login_date_updated: Scalars['CrTimestamp'];
  /** login URL */
  login_url: Scalars['String'];
  /** order 갱신일자 */
  order_date_updated: Scalars['CrTimestamp'];
  /** order URL */
  order_url: Scalars['String'];
};

/** 공지사항 데이터 메타테이터 */
export type MetadataNotice = {
  /** 마지막 공지사항 ID */
  last_id: Scalars['Int'];
  /** 데이터 URL */
  url: Scalars['String'];
};

/** 상품 검색 추천 데이터 메타테이터 */
export type MetadataSearchGoodsSuggestion = {
  /** 데이터 갱신일자 */
  date_updated: Scalars['CrTimestamp'];
  /** 데이터 URL */
  url: Scalars['String'];
};

/** 쇼핑몰 데이터 메타테이터 */
export type MetadataShop = {
  /** 상세 데이터 URL 접두사 */
  detail_url_prefix: Scalars['String'];
  /** 목록 데이터 갱신일자 */
  list_date_updated: Scalars['CrTimestamp'];
  /** 목록 데이터 URL */
  list_url: Scalars['String'];
  /** 로고 파일 URL 접두사 */
  logo_url_prefix: Scalars['String'];
};

/** 서포트 페이지 메타테이터 */
export type MetadataSupport = {
  /** 개인정보 수집 및 이용 동의 URL */
  consent_privacy_url: Scalars['String'];
  /** FAQ URL */
  faq_url: Scalars['String'];
  /** 개인정보 처리방침 URL */
  privacy_policy_url: Scalars['String'];
  /** 자동 로그인 이용약관 URL */
  terms_of_service_shop_link_url: Scalars['String'];
  /** 이용약관 URL */
  terms_of_service_url: Scalars['String'];
};

/** URL 패턴 데이터 메타테이터 */
export type MetadataUrlPattern = {
  /** 데이터 갱신일자 */
  date_updated: Scalars['CrTimestamp'];
  /** 데이터 URL */
  url: Scalars['String'];
};

/** Z결제 페이지 메타테이터 */
export type MetadataZpay = {
  /** 장바구니 URL */
  cart_url: Scalars['String'];
  /** 주문 URL 접두사 */
  order_detail_url_prefix: Scalars['String'];
  /** 주문 문의 URL */
  order_inquiry_url: Scalars['String'];
  /** 포인트 URL */
  point_url: Scalars['String'];
};

export type MileageAccumulatePolicy = {
  /** 적립 금액 */
  accumulate_amount?: Maybe<Scalars['Int']>;
  /** 적립률 */
  accumulate_rate_bp?: Maybe<Scalars['Int']>;
  /** 적립 타입 */
  accumulate_type?: Maybe<MileageAccumulateType>;
  /** 적립 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 적립 시작일 */
  date_start: Scalars['CrTimestamp'];
  /** ID */
  id: Scalars['ID'];
  /** 마일리지 type */
  mileage_type: MileageType;
  /** 마일리지 적립 대상 타입 */
  target_type: MileageTargetType;
  /** 마일리지 적용 대상 ID */
  target_uid: Scalars['ID'];
  /** 타이틀 */
  title: Scalars['String'];
};

export enum MileageAccumulateType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export type MileageCalculatedResult = {
  actual_payment_amount: Scalars['Int'];
  calculated_mileage_amount: Scalars['Int'];
};

export enum MileageTargetType {
  SHOP = 'SHOP',
}

export enum MileageType {
  MEMBERSHIP = 'MEMBERSHIP',
  PROMOTION = 'PROMOTION',
}

/** 최소 주문 금액 정보 */
export type MinOrderAmountInfo = {
  /** 통화 코드 */
  currency: CurrencyType;
  /** 최소 주문 금액 */
  min_order_amount: Scalars['Int'];
};

/** 최소구매수량 타입 */
export enum MinimumOrderQuantityType {
  ITEM = 'ITEM',
  PRODUCT = 'PRODUCT',
}

export enum ModelDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export enum ModelRegistrationType {
  SELLER = 'SELLER',
  SYSTEM = 'SYSTEM',
}

export type Models = {
  /** 상품의 모델 정보 */
  data: Array<ProductModel>;
};

export type Mutation = {
  /** 톡라운지 톡 포인트 걸고 질문하기 채택 */
  acceptTalkLoungeRewardedQuestion: Scalars['Boolean'];
  /** 휴면 계정을 활성화한다. */
  activateInactiveUserAccount?: Maybe<ActivateInactiveUserAccountResult>;
  /**
   * 어필레이트 리워드 사용자계정을 활성화한다.
   * ---
   * [error_code]
   * AFFILIATE_USER_ACCOUNT_NOT_FOUND - 어필레이트 리워드 사용자계정을 찾을 수 없습니다.
   */
  activeRewardUser: Scalars['Boolean'];
  /** 스토리에 상품, 쿠폰 등 매핑 */
  addAttributeToStory: Scalars['Boolean'];
  /** 담은 이후 30일이 지난 오래된 장바구니 상품을 다시 담기한다. */
  addBackOldCartItemList: Scalars['Boolean'];
  /**
   * 장바구니 아이템을 추가한다.
   * [error_code]
   * - order_unavailable: (세션 내 zigzag_shop_id가 없는 경우) 주문 기능을 더 이상 사용할 수 없습니다.
   */
  addCartItemList: Scalars['Boolean'];
  /** 글로벌 오픈 알림 받기 */
  addGlobalOpenAlertWaitingList: Scalars['String'];
  /**
   * 카카오 채널에 친구추가한다
   * [error_list]
   * - kakao_account_not_found: 해당 사용자 계정에 연동된 카카오 계정이 없는경우
   * - add_plus_friends_kakao_channel_failed: 채널 친구추가 카카오 api 응답이 성공적이지 않은경우
   * - added_plus_friends_kakao_channel_not_found: 채널 친구추가 카카오 api 응답에 친구추가된 채널목록이 없는경우
   */
  addPlusFriendsKakaoChannel: Scalars['Boolean'];
  /**
   * 뷰티페스타 알림 수신 동의 참여
   * deprecated(reason: "뷰티페스타 알림 동의 증대를 위한 임시 mutation 이므로 24.04.15 이후에 삭제.")
   * @deprecated 뷰티페스타 알림 동의 증대를 위한 임시 mutation 이므로 24.04.15 이후에 삭제.
   */
  agreeDailyMissionBeautyFestaNoti: Scalars['Boolean'];
  /** 카테고리 동의 여부를 받는다. */
  agreePushCategory: Scalars['Boolean'];
  /** 사용자 계정 약관 동의 정보를 생성한다 */
  agreeUserAccountTerm: AgreeUserAccountTermResult;
  /** 톡라운지 톡 투표 */
  answerTalkLoungePoll: TalkLoungePollAnswerResult;
  /** 체험단 프로모션 신청 (M1) */
  applyPromotionExperienceGroup: Scalars['Boolean'];
  /** 체험단 프로모션 신청 */
  applyPromotionExperienceGroupProductApplicant: Scalars['Boolean'];
  /** 래플 프로모션에 응모한다 */
  applyRaffle: Scalars['Boolean'];
  /** @deprecated Use verifyMobileAuthenticationToken */
  authorizeAndGetEmails: AuthorizeAndGetEmailsResult;
  /** 휴대폰을 인증한다. */
  authorizeMobileToken: Scalars['Boolean'];
  /**
   * 이벤트용 휴대폰 번호를 인증한다.
   *
   * [error_code]
   * - not_found_user_account: 해당 id로 유저 계정을 찾을 수 없는 경우
   * - token_is_not_valid: 유효하지 않은 토큰일 경우
   */
  authorizeMobileTokenForEvent: Scalars['Boolean'];
  /** 오늘의 혜택 행동 미션 */
  behaveDailyMission: Scalars['Boolean'];
  /** 톡라운지 유저 차단 */
  blockTalkLoungeUser: Scalars['Boolean'];
  /** 주문 전체 취소 처리 한다. */
  cancelAndRefundOrder: Scalars['Boolean'];
  /**
   * 미입금 주문을 취소한다
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없습니다.
   * - order_invalid_status: 주문의 상태를 다시 확인해주세요.
   * - order_cancel_failed: 주문 취소에 실패하였습니다.
   */
  cancelNotTransferredOrder: Scalars['Boolean'];
  /** 비밀번호 변경한다. (기존 비밀번호로 인증 성공시에만 가능) */
  changeSimplePayPassword: Scalars['Boolean'];
  /**
   * 럭키볼 당첨 결과를 확인한다.
   * 럭키볼 당첨이 된 경우, 보상이 지급된다.
   * 럭키볼 당첨이 안된 경우, 보상 등 상태변경이 없다.
   * [error_code]
   *   lucky_ball_already_rewarded: 이미 당첨 보상을 받았습니다.
   */
  checkAndRewardDailyMissionLuckyBallWinningResult: CheckAndRewardDailyMissionLuckyBallWinningResult;
  /**
   * 뱃지 확인
   * [error_code]
   * - user_not_found : 유저를 특정할 수 없습니다. (user_uuid or user_account_id를 확인할 수 없는 경우)
   * - badge_not_found : 뱃지를 찾을 수 없습니다.
   */
  checkFbkBadge: Scalars['Boolean'];
  /** 계좌 유효성 검사를 한다 */
  checkPaymentBankAccount: PaymentBankAccountResponseGraph;
  /** 사용자가 경고 조치를 확인한 일자를 갱신한다 */
  checkProductReviewUserAccountWarning: Scalars['Boolean'];
  /** 계좌 인증 확인한다. */
  checkSimpleBankCert: Scalars['Boolean'];
  /** 업데이트 사항이 발생한 UserAccountMetaData를 확인 처리 한다. */
  checkUpdateInUserAccountMetadata?: Maybe<Scalars['Boolean']>;
  /** 닉네임을 저장하기 전 클라이언트에서 정책을 참고할 수 있다. */
  checkUserProfileNicknameValidity: Scalars['Boolean'];
  /**
   * 오늘의 미션 출석 미션 완료
   *   - 24년3월1일부터 미사용
   *   error_code
   *     deprecated: 2024-02-29 23:59 이전까지 정상 작동
   * @deprecated participateDailyMissionAttendance
   */
  completeDailyMissionAttendance: CompleteDailyMissionAttendanceResult;
  /**
   * up/down 게임 완료
   * -----
   * [error_code]
   * - DAILY_MISSION_INVALID_GAME_PLAY: 유효하지 않은 플레이가 감지됐습니다.
   * - DAILY_MISSION_INVALID_TICKET_OWNER: 티켓 소유자가 아닙니다.
   * - DAILY_MISSION_DUPLICATED_GAME_COMPLETE: 중복 게임 완료 요청 에러
   */
  completeDailyMissionUpDown: CompleteDailyMissionUpDownResult;
  /** 쿠폰함 확인여부 저장 */
  confirmCouponBox: Scalars['Boolean'];
  /** 구매확정 및 재구매 쿠폰을 발급한다 */
  confirmOrderItemListAndIssueRepurchaseCouponList: ConfirmOrderItemListAndIssueRepurchaseCouponListInputResult;
  /**
   * 지그재그 계정을 애플 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - auth_invalid_access: 잘못된 접근입니다
   * - apple_account_already_connected: 이미 연동된 애플 계정이 있습니다
   */
  connectAppleUserAccount: Scalars['Boolean'];
  /**
   * 지그재그 계정을 애플 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - auth_invalid_access: 잘못된 접근입니다
   */
  connectAppleWithAuth: UserAccount;
  /**
   * 지그재그 계정을 페이스북 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - facebook_account_already_connected: 이미 연결된 페이스북 계정이 있는 경우
   */
  connectFacebookUserAccount: Scalars['Boolean'];
  /**
   * 지그재그 계정을 페이스북 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - auth_authentication_failed: 로그인이 실패한 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - facebook_account_already_connected: 이미 연결된 페이스북 계정이 있는 경우
   */
  connectFacebookWithAuth: UserAccount;
  /**
   * 지그재그 계정을 구글 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - google_account_already_connected: 이미 연동된 애플 계정이 있습니다
   */
  connectGoogleUserAccount: Scalars['Boolean'];
  /**
   * 지그재그 계정을 구글 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - auth_authentication_failed: 로그인이 실패한 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   */
  connectGoogleWithAuth: UserAccount;
  /**
   * 지그재그 계정을 카카오 계정에 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 연동 실패했습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   * - kakao_account_already_connected: 이미 연동된 카카오 계정이 있습니다
   */
  connectKakaoUserAccount: Scalars['Boolean'];
  /**
   * 지그재그 계정에 카카오 계정을 연결한다
   * [error_list]
   * - user_account_not_found: 해당 사용자 계정을 찾을 수 없습니다
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 연동 실패했습니다
   * - social_account_already_connect_other_user_account: 타계정에 연동되어 있습니다
   */
  connectKakaoWithAuth: UserAccount;
  /** 이메일 인증으로 소셜 정보 연동 */
  connectSocialWithEmailAuthentication: LoginCommonResult;
  /** 어필레이트 공유 링크 생성 M2 */
  createAffiliateDeepLink?: Maybe<AffiliateShareLink>;
  /** 어필레이트 공유 링크 */
  createAffiliateShareLink?: Maybe<AffiliateShareLink>;
  /**
   * Braze User Matching 데이터 생성
   * ---
   * @description
   * APP에서 직접 mkt-braze로 Braze User Matching 데이터 생성을 요청
   * Braze SDK를 통해 전달 받은 device_id를 전달해야합니다.
   * 동일 요청을 전달할 경우 기존에 생성된 데이터를 리턴
   * ---
   * @throw {bad_request_exception} - 입력 값에 오류가 발생 했을 경우
   */
  createBrazeUser?: Maybe<BrazeUserMatching>;
  /** 상품 문의 등록 */
  createCatalogQuestion: Scalars['ID'];
  /**
   * 체크아웃을 생성한다.
   * [error_code]
   * - checkout_has_invalid_product: 주문할 수 없는 상품이 포함되어 있습니다.
   * - checkout_has_invalid_amount: 주문서의 금액 정보가 올바르지 않습니다. 다시 주문해주세요.
   * - checkout_update_failed: 주문 정보 업데이트에 실패했습니다.
   * - checkout_invalid_total_discount_amount: 할인 금액이 올바르지 않습니다.
   * - invalid_arguments: 잘못된 파라미터입니다.
   * - insufficient_product_quantity: 최소주문수량 미만인 상품이 있습니다.
   * - order_item_product_item_soldout: 선택한 상품의 재고가 부족하거나 상품 정보가 변경되어 주문이 불가능합니다.
   * - exceeded_product_quantity: 최대주문수량을 초과한 상품이 있습니다.
   * - invalid_promotion_order_amount: 첫구매 최소주문금액을 충족하지 못했습니다.
   * - invalid_promotion_order_item_quantity: 이벤트 최대/최소 주문 수량을 초과하는 상품이 있습니다.
   * - bogo_promotion_not_running: 1+1 이벤트가 종료되었습니다.
   * - first_order_promotion_not_running: 첫구매 이벤트가 종료 되었습니다.
   * - invalid_promotion_user_condition: 이벤트 이용 기간이 지났습니다.
   * - raffle_promotion_not_running: 래플 구매 기간이 지나 상품을 구매할 수 없습니다.
   * - exhausted_limited_order_coupon: 선착순 쿠폰을 쓸 수 없습니다.
   */
  createCheckout: Scalars['String'];
  /** 오늘의 혜택 이벤트 링크 발급 */
  createDailyMissionEventLink: Scalars['String'];
  /**
   * up/down 게임권 획득을 위한 초대 링크 발급
   * [error_code]
   * - DAILY_MISSION_MENU_NOT_FOUND: 링크 생성에 필요한 landing url 이 담긴 미션 메뉴 정보가 없습니다.
   */
  createDailyMissionGameInvitationLink: CreateDailyMissionGameInvitationLinkResult;
  /**
   * 게임 초대 보상 획득
   * [error_code]
   * - DAILY_MISSION_GAME_CANNOT_INVITE_SELF: 스스로를 게임에 초대 할 수 없습니다.
   * - DAILY_MISSION_GAME_ALREADY_INVITED_TODAY: 오늘 게임에 초대한 유저는 중복으로 초대 할 수 없습니다.
   * - DAILY_MISSION_GAME_TICKET_COUNT_EXCEED_LIMIT: 게임권 수령 가능한 횟수를 초과했습니다.
   */
  createDailyMissionGameInvitationReward: Scalars['Boolean'];
  /** 오늘의 혜택 초대 링크 발급 */
  createDailyMissionInvitationLink: Scalars['String'];
  /**
   *  오늘의 혜택 초대 리워드 지급
   * [error_code]
   *  - NOT_TARGET: 본인을 초대한 경우
   *  - ALREADY_EXIST: 이미 지급 된 랜덤박스일 경우
   *  - OVER_REWARD_LIMIT: 획득 가능한 박스 초과
   * @deprecated 랜덤박스 링크 공유가 완전 교체된경우 삭제 예정, createDailyMissionInvitationRewardV2 사용
   */
  createDailyMissionInvitationReward: CreateDailyMissionInvitationRewardResult;
  /**
   * 오늘의 혜택 초대 리워드 지급
   * [error_code]
   * - NOT_TARGET: 본인을 초대한 경우
   * - NOT_AUTHENTICATED: 본인인증이 안된 경우
   * - ALREADY_EXIST: 이미 지급 된 랜덤박스일 경우
   * - OVER_REWARD_LIMIT: 획득 가능한 박스 초과
   * - EXPIRED_INVITATION: 만료된 초대입니다.
   */
  createDailyMissionInvitationRewardV2: CreateDailyMissionInvitationRewardV2Result;
  /**
   * 지역 정보 저장
   * [error_code]
   *   DAILY_MISSION_NOT_VALID_REGION_ID: 유효하지 않은 region id 입니다.
   */
  createDailyMissionLikedRegion: Scalars['Boolean'];
  /**
   * Up & Down 게임 최초 패배 보상 지급
   * - 하루에 한번 보상
   * [error_code]
   * - DAILY_MISSION_UP_DOWN_POLICY_NOT_EXIST: Up & Down 정책이 없습니다.
   * - DAILY_MISSION_NOT_TARGET_FOR_UP_DOWN_FIRST_LOSE_REWARD: 오늘자 Up & Down 게임 첫 실패 보상 대상자가 아닙니다.
   */
  createDailyMissionUpDownFirstLoseReward: Scalars['Boolean'];
  /**
   * 에픽 포스트를 등록한다.
   * ---
   * [error_code]
   * NO_AUTH:
   * - 로그인이 필요합니다.
   * TOO_LITTLE_CONTENTS:
   * - 이미지는 최소 1개는 등록 해야해요.
   * - 상품은 최소 1개이상 등록 해야해요
   * TOO_MANY_CONTENTS:
   * - 이미지는 최대 10개까지만 등록 가능해요.
   * - 상품은 최대 30개까지만 등록 가능해요.
   * VALID_ERROR:
   * - 스타일 태그를 입력해주세요.
   * - 유효한 스타일 태그를 입력해주세요
   * - 카테고리 당 하나의 스타일 태그만 선택하세요
   * - 게시물 URL이 유효하지 않아요 (유튜브 URL)
   * - 입력한 스타일 태그를 찾을 수 없습니다.
   * PROFILE_STATUS_INVALID:
   * - 프로필 상태가 유효하지 않아요
   */
  createEPickPost: CreateEPickPostResponse;
  /**
   * 에픽 포스트를 등록한다.
   * ---
   * [error_code]
   * NO_AUTH:
   * - 로그인이 필요합니다.
   * TOO_LITTLE_CONTENTS:
   * - 이미지는 최소 1개는 등록 해야해요.
   * TOO_MANY_CONTENTS:
   * - 이미지는 최대 10개까지만 등록 가능해요.
   * - 이미지당 상품은 최대 10개까지만 등록 가능해요.
   * VALID_ERROR:
   * - 스타일 태그를 입력해주세요.
   * - 유효한 스타일 태그를 입력해주세요
   * - 카테고리 당 하나의 스타일 태그만 선택하세요
   * - 게시물 URL이 유효하지 않아요
   * - 입력한 스타일 태그를 찾을 수 없습니다.
   * PROFILE_STATUS_INVALID:
   * - 프로필 상태가 유효하지 않아요
   */
  createEPickPostV2: CreateEPickPostResponseV2;
  /**
   * 에픽 게시글 내 댓글의 댓글을 추가한다.
   * ---
   * NO_AUTH:
   * - 로그인이 필요합니다.
   * TOO_LONG_TEXT:
   * - 댓글은 500자까지만 등록 가능해요.
   * CANT_USE_WORD:
   * - 금칙어는 등록이 불가능해요.
   * NOT_FOUND_POST:
   * - 게시글을 찾을 수 없습니다.
   * NOT_FOUND_REPLY:
   * - 댓글을 찾을 수 없습니다.
   * PROFILE_STATUS_INVALID:
   * - 프로필 상태가 유효하지 않아요
   */
  createEPickReReply: EPickReply;
  /**
   * 에픽 게시글에 댓글을 추가한다.
   * ---
   * [error_code]
   * NO_AUTH:
   * - 로그인이 필요합니다.
   * TOO_LONG_TEXT:
   * - 댓글은 500자까지만 등록 가능해요.
   * CANT_USE_WORD:
   * - 금칙어는 등록이 불가능해요.
   * NOT_FOUND_POST:
   * - 게시글을 찾을 수 없습니다.
   * PROFILE_STATUS_INVALID:
   * - 프로필 상태가 유효하지 않아요
   */
  createEPickReply: EPickReply;
  /** 이벤트 콘텐츠 댓글 작성 */
  createEventContentReply: EventContentReply;
  /** 초대코드 생성 */
  createInviteCode: Scalars['String'];
  /** 유저의 마이픽쿠폰 희망 스토어 요청을 생성합니다. */
  createMyPickCouponUserRequest: MyPickCouponUserRequest;
  /** 온보딩 연령 정보 입력 요청(v2) */
  createOnboardingBirthYear?: Maybe<Scalars['Boolean']>;
  /** 온보딩 혜택 발급 요청(v2) */
  createOnboardingInfo?: Maybe<Scalars['Boolean']>;
  /** 온보딩 포인트 발급 요청 */
  createOnboardingPoints?: Maybe<ParticipateOnboardingPointResult>;
  /** 온보딩 연령 정보 입력 */
  createOnboardingUser?: Maybe<Scalars['Boolean']>;
  /** 상품 주문 문의를 생성한다. */
  createOrderItemInquiry: CreateOrderItemInquiryResult;
  /** 주문서 임시데이터를 생성한다 */
  createOrderSheet: Scalars['String'];
  /**
   * 상품 리뷰를 생성한다
   * [error_code]
   * - invalid_product_review_user_account_info_status: 사용자계정의 상태가 상품리뷰 작성이 불가능한 경우
   * - invalid_user_account: 입력된 user_account_id가 상품주문의 사용자계정 id가 다른 경우
   * - invalid_order_item_status: 상품리뷰 작성이 불가능한 상품주문 상태의 경우
   * - invalid_order_item_total_amount: 상품리뷰 작성가능한 상품주문의 최소금액 미만인 경우
   * - product_review_create_due_over: 상품리뷰 작성기한을 넘긴 경우
   * - contents_length_below_minimum_limit: 리뷰 내용의 길이가 최소길이 미만인 겨우
   * - contents_length_above_maximum_limit: 리뷰 내용의 길이가 최대길이를 초과한 경우
   * - attachment_length_above_maximum_limit: 첨부파일의 갯수가 최대갯수를 초과한 경우
   * - product_review_by_order_item_number_already_created: 상품주문번호에 해당하는 상품리뷰가 이미 생성된 경우
   */
  createProductReview: CreateProductReviewResult;
  /** 상품리뷰 유저 댓글을 생성한다 */
  createProductReviewUserReply: CreateProductReviewUserReplyResult;
  /** 퀵리뷰를 작성한다 */
  createQuickProductReview: CreateQuickProductReviewResult;
  /**
   * 어필레이트 리워드 사용자계정을 생성한다.
   * ---
   * [error_code]
   * [사용자계정]
   * AFFILIATE_USER_ACCOUNT_ALREADY_EXISTED - 이미 어필레이트 리워드 사용자계정으로 등록되어있습니다.
   * AFFILIATE_INVITER_REFERRAL_CODE_INVALID - 초대자 레퍼럴 코드가 유효하지 않습니다.
   * [채널]
   * AFFILIATE_USER_ACCOUNT_CHANNEL_EMPTY - 채널목록이 비어있습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_MAXIMUM_COUNT_EXCEEDED - 채널등록은 최대 5개까지 가능합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_REPRESENTATIVE_COUNT_INVALID - 대표채널 등록을 반드시 1개 해야 합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_URL_DUPLICATED - 동일 채널 URL이 존재합니다.
   * AFFILIATE_USER_ACCOUNT_ALREADY_CREATED_CHANNEL - 사용자 계정이 이미 생성된 채널이 존재합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_URL_FORMAT_INVALID - 입력된 채널 URL format 이 올바르지 않습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_IDENTIFIER_NOT_FOUND - 입력된 채널 식별자를 찾을 수 없습니다.
   * IMAGE_CONTENT_FORMAT_INVALID - 이미지 파일 포맷이 올바르지 않습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_YOUTUBE_CHANNEL_ID_NOT_FOUND - 유튜브 채널 ID 를 찾을 수 없습니다.
   */
  createRewardUserAccount: Scalars['Boolean'];
  /**
   * 어필레이트 리워드 사용자계정을 채널등록 없이 생성한다.
   * ---
   * [error_code]
   * AFFILIATE_USER_ACCOUNT_ALREADY_EXISTED - 이미 어필레이트 리워드 사용자계정으로 등록되어있습니다.
   */
  createRewardUserAccountWithoutChannel: Scalars['Boolean'];
  createShopDetailShortUrlInAppsflyer?: Maybe<ShortUrl>;
  createShortUrlInAppsflyer?: Maybe<ShortUrl>;
  /** 계좌 인증 정보를 생성한다. */
  createSimpleBankCert: CreateSimpleBankCertResult;
  /** 계좌 본인인증 성공 여부를 저장한다. */
  createSimpleBankUserAuth: Scalars['Boolean'];
  /** 스토리 생성 */
  createStory: CreateStoryResponse;
  /** 톡라운지 댓글 생성 */
  createTalkLoungeReply: TalkLoungeReply;
  /** 톡라운지 톡 생성 */
  createTalkLoungeTalk: CreateTalkLoungeTalkResponse;
  /** 톡라운지 톡 공유링크 */
  createTalkLoungeTalkShareLink: TalkLoungeShareLink;
  createUmdSavedProductFolder: UmdSavedProductFolder;
  /** 파일 업로드 URL을 생성한다 */
  createUploadLink: UploadLink;
  /** 사용자별 기본배송지를 등록한다. */
  createUserDefaultShippingAddressBook: Scalars['Boolean'];
  /** 유저 환불 계좌를 생성한다. */
  createUserRefundAccount: CreateUserRefundAccountResult;
  /** 사용자별 배송주소록을 생성한다. */
  createUserShippingAddressBook: UserShippingAddressBook;
  /** 환불이 완료된 경우에 한해서 환불 계좌를 deactivate 시킨다. */
  deactiveRefundedUserRefundAccount: Scalars['Boolean'];
  /**
   * 어필레이트 리워드 사용자계정을 비활성화한다.
   * ---
   * [error_code]
   * AFFILIATE_USER_ACCOUNT_NOT_FOUND - 어필레이트 리워드 사용자계정을 찾을 수 없습니다.
   */
  deactiveRewardUser: Scalars['Boolean'];
  /** 환불 계좌를 deactivate 시킨다. */
  deactiveUserRefundAccount: Scalars['Boolean'];
  /** 장바구니 아이템을 삭제한다. */
  deleteCartItemList: Scalars['Boolean'];
  /** 상품 문의 삭제 */
  deleteCatalogQuestion: Scalars['Boolean'];
  /**
   * 저장한 지역 정보 삭제
   * [error_code]
   *   DAILY_MISSION_NOT_FOUND_LIKED_REGION: 즐겨찾기한 지역 정보를 찾을수 없습니다.
   */
  deleteDailyMissionLikedRegion: Scalars['Boolean'];
  /** 게시글 삭제 */
  deleteEPickPost: Scalars['Boolean'];
  /** 댓글 삭제 */
  deleteEPickReply: Scalars['Boolean'];
  /** 이벤트 콘텐츠 댓글 삭제 */
  deleteEventContentReply: Scalars['Boolean'];
  /**
   * 해당 주문을 구매 내역에서 삭제한다.
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없습니다.
   * - order_cannot_deleted: 선택하신 주문 상품이 배송, 결제, 환불완료 되지 않았습니다. 주문상태 확인 후 재시도해주세요.
   */
  deleteOrder: Scalars['Boolean'];
  /**
   * 해당 주문 문의를 삭제 처리한다.
   * [error_code]
   * - order_item_inquiry_not_found: 해당 조건의 주문 문의를 찾을 수 없는 경우
   * - order_item_inquiry_already_deleted: 이미 삭제된 경우
   */
  deleteOrderItemInquiry: Scalars['Boolean'];
  /** 상품리뷰 유저 댓글을 삭제한다 */
  deleteProductReviewUserReply: Scalars['Boolean'];
  /** 스토리 삭제 */
  deleteStory: Scalars['Boolean'];
  /** 톡라운지 댓글 삭제 */
  deleteTalkLoungeReply: Scalars['Boolean'];
  /** 톡라운지 톡 삭제 */
  deleteTalkLoungeTalk: Scalars['Boolean'];
  /** 사용자 계정을 삭제한다 */
  deleteUserAccount: DeleteUserAccountResult;
  /** 알림함에 있는 스토어별 알림 전체 삭제를 하고, date_deleted를 채운다. */
  deleteUserAccountShopNotificationList: Scalars['Boolean'];
  /** 알림함에 있는 알림 개별 삭제를 하고, date_deleted를 채운다. */
  deleteUserNotification: Scalars['Boolean'];
  /** 사용자별 배송주소록을 삭제한다. */
  deleteUserShippingAddressBook: Scalars['Boolean'];
  /** 계좌 간편 해지한다. */
  deregisterSimpleBank: Scalars['Boolean'];
  /** 간편결제를 해지한다. */
  deregisterSimplePay: Scalars['Boolean'];
  /** 지그재그 계정과 애플 계정의 연결을 끊는다 */
  disconnectAppleUserAccount: Scalars['Boolean'];
  /** 지그재그 계정과 페이스북 계정의 연결을 끊는다 */
  disconnectFacebookUserAccount: Scalars['Boolean'];
  /** 지그재그 계정과 구글 계정의 연결을 끊는다 */
  disconnectGoogleUserAccount: Scalars['Boolean'];
  /** 지그재그 계정과 카카오 계정의 연결을 끊는다 */
  disconnectKakaoUserAccount: Scalars['Boolean'];
  /** 쿠폰 발행 ( 중복 발행 방어 ) */
  fbkIssueCouponPack: FbkIssueCouponPackResult;
  /** FBK Shoplive 쿠폰을 발급한다 */
  fbkShopliveIssueCoupon: IssueShopliveCouponResult;
  /** FBK Shoplive 쿠폰 팩을 발급한다 */
  fbkShopliveIssueCouponPack: IssueShopliveCouponPackResult;
  /**
   * 출석체크 초대 링크에 필요한 아이디 생성
   * [error_code]
   * not_login - site id 혹은, user account id가 없는 경우
   */
  findOrCreateFbkAttendanceEventInviterCode: FbkAttendanceEventInviterCodeResult;
  /**
   * 오늘의 혜택 초대 링크를 생성한다
   * - mission_type
   * [
   * OPEND_RANDOM_BOX,
   * GAME_UP_DOWN,
   * ]
   */
  generateDailyMissionInvitationLink: GenerateDailyMissionInvitationLinkResult;
  /**
   * 패바카 샵라이브 사용을 위한 JWT 토큰을 생성한다.
   * [error_code]
   * - route_not_logged_in: 로그인되지 않은 경우
   */
  generateFbkShopliveJwtToken: Scalars['String'];
  /**
   * 게임 화면에 진입시 기본으로 호출
   * - UPDOWN: 최초 진입할 경우, 기본으로 게임티켓생성머신 생성
   */
  initializeDailyMissionGameTicketGenerateMachine: Scalars['Boolean'];
  /**
   * 오늘의 혜택 랜덤박스 초기화
   * @deprecated 랜덤박스 일별 기본 지급 정책 삭제 예정
   */
  initializeDailyMissionRandomBox: InitializeDailyMissionRandomBoxResult;
  /** 친구초대 가능 여부 */
  isFriendInvitationEnable: Scalars['Boolean'];
  /** 계좌 간편 등록 시 본인인증 필요 여부를 체크한다. */
  isRequiredSimpleBankUserAuth: Scalars['Boolean'];
  /** 계정 비밀번호 초기화 토큰이 유효한지 확인한다 */
  isUserAccountPasswordResetTokenValid: Scalars['Boolean'];
  /**
   * 친구초대 가능 여부
   * @deprecated use isFriendInvitationEnable
   */
  isUserInviteEnable: Scalars['Boolean'];
  /** 닉네임 사용가능 여부 체크 */
  isValidNickname: IsValidNicknameResponse;
  /**
   * 쿠폰을 비동기로 발급처리한다.
   * [error_code]
   * - 공통 에러코드
   * - invalid_arguments: 인자값이 올바르지 않은 경우
   * - 쿠폰 발급의 경우
   * - already_issued_coupon: 이미 발급 받은 경우
   * - exhausted_coupon: 발급 가능 수량 초과
   * - expired_coupon: 쿠폰 발급 기간 종료
   * - coupon_policy_not_found: 해당하는 쿠폰 정책을 찾을 수 없음
   * - stopped_coupon_policy: 발급이 중지됨
   * - 쿠폰팩 발급의 경우
   * - coupon_pack_not_found: 해당하는 쿠폰팩을 찾을 수 없음
   * - already_issued_coupon_pack: 이미 쿠폰팩을 받은 경우
   */
  issueCouponAsync: Scalars['Boolean'];
  /** 쿠폰을 code로 발급한다 */
  issueCouponByCode: Scalars['Boolean'];
  /** 쿠폰 팩으로 쿠폰을 발급한다 */
  issueCouponByCouponPack: Scalars['Boolean'];
  /** 쿠폰을 ID list로 발급한다 */
  issueCouponByIdList: IssueCouponByIdListResult;
  /** 입력한 리딤 코드값으로 쿠폰을 발급한다 */
  issueCouponByRedeem: IssueUserAccountCouponByRedeemResult;
  /** 쿠폰 모아보기 쿠폰 발급 */
  issueCouponCollectionCoupon?: Maybe<IssueCouponCollectionCouponResult>;
  /**
   * 기획전 쿠폰 발급 요청
   * 발급에 성공하면 입력한 쿠폰&쿠폰팩 ID를 리턴 한다.
   * [error_code]
   * - already_issued_coupon - 발급 요청한 쿠폰 또는 쿠폰팩을 이미 가지고 있는 경우
   */
  issueExhibitionCoupons: IssueExhibitionCouponsResult;
  /**
   * 쿠폰 코드를 발행한다
   * @deprecated Field no longer supported
   */
  issueShopEventCouponCode?: Maybe<IssueShopEventCouponCodeResult>;
  /** Shoplive 쿠폰을 발급한다 */
  issueShopliveCoupon: IssueShopliveCouponResult;
  /** Shoplive 쿠폰 팩을 발급한다 */
  issueShopliveCouponPack: IssueShopliveCouponPackResult;
  /** 스토리 쿠폰 다운로드 */
  issueStoryCoupon: Scalars['Boolean'];
  /**
   * 쿠폰 정책 목록으로 쿠폰을 발급한다.
   * 단 다운로드 형식의 쿠폰만 가능
   *
   * [error_code]
   * - exhausted_coupon: 쿠폰정책 발급 가능 수량 초과
   * - expired_coupon: 쿠폰정책 발급 기간 종료
   * - coupon_policy_not_found: 해당 쿠폰 정책을 찾을 수 없음
   * - stopped_coupon_policy: 발급이 중지된 쿠폰 정책
   * - already_issued_coupon: 이미 발급된 쿠폰
   * - failed_to_issue_coupon: 쿠폰 발급 실패
   */
  issueUserAccountCouponByPolicyIdList: IssueUserAccountCouponByPolicyIdListResult;
  /**
   * 쇼핑몰 쿠폰을 발급한다
   *
   * [error_code]
   * - not_supported: 지원하지 않는 타입을 요청할때(휴면고객 발급)
   * - not_satisfied_issue_coupon_condition: 발급 조건이 맞지 않음
   * - exhausted_coupon: 쿠폰정책 발급 가능 수량 초과
   * - expired_coupon: 쿠폰정책 발급 기간 종료
   * - coupon_policy_not_found: 해당 쿠폰 정책을 찾을 수 없음
   * - stopped_coupon_policy: 발급이 중지된 쿠폰 정책
   * - already_issued_coupon: 이미 발급된 쿠폰
   * - failed_to_issue_coupon: 쿠폰 발급 실패
   */
  issueUserAccountShopCouponList: IssueUserAccountShopCouponListResult;
  /** 직잭위크 쿠폰 발급 */
  issueZigzagWeekCoupon: IssueZigzagWeekCouponResult;
  /** kitto 컨텐츠 좋아요 */
  kitto_content_like: Scalars['Boolean'];
  /** kitto 질문 생성 */
  kitto_question_create: Scalars['ID'];
  /** 톡라운지 댓글 좋아요 */
  likeTalkLoungeReply: LikeTalkLoungeReplyResponse;
  /** 톡라운지 톡 좋아요 */
  likeTalkLoungeTalk: LikeTalkLoungeTalkResponse;
  /** 지그재그 서비스에 로그인한다. */
  login: LoginResult;
  /**
   * 애플 로그인
   * [error_list]
   * - invalid_apple_code: 잘못된 애플 코드입니다
   * - user_account_email_exists: 이미 애플 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - auth_invalid_access: 잘못된 접근입니다
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginApple: LoginAppleResult;
  /**
   * 페이스북 로그인
   * [error_list]
   * - social_invalid_token: access_token이 잘못된 경우
   * - user_account_invalid_email: 연동된 정보가 없고 이메일정보가 없는 페이스북 계정인 경우
   * - user_account_email_exists: 이미 페이스북 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - inactive_user_account: 휴면계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginFacebook: LoginFacebookResult;
  /**
   * 구글 로그인
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_exists: 이미 구글 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_not_found: 해당 계정이 없을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginGoogle: LoginGoogleResult;
  /**
   * 지그재그 서비스에 카카오 계정으로 로그인한다
   * [error_list]
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 로그인 실패
   * - kakao_email_not_found: 카카오 계정에 이메일 정보가 없을 경우
   * - user_account_email_exists: 이미 카카오 계정의 이메일로 가입한 계정이 있을 경우
   * - auth_inactive_user_account: 사용자계정이 비활성화 계정인 경우
   * - social_login_error_multiple_times: 소셜로그인 N회 이상 실패한 경우
   */
  loginKakao: LoginKakaoResult;
  /**
   * 휴대폰 인증을 통해 로그인한다.
   * [error_list]
   * - mobile_authentication_token_expired: 휴대폰 인증 토큰이 만료된 경우
   * - mobile_phone_token_authentication_failed: 휴대폰 번호와 인증번호가 알맞지 않는경우
   * - mobile_authentication_required_recertification: 2차 인증이 필요한 경우
   */
  loginMobile: LoginCommonResult;
  /** 휴대폰 로그인 시 2차 인증(본인인증)을 하여 로그인한다. */
  loginMobileWithAuthentication: LoginCommonResult;
  /** 휴대폰 로그인 시 2차 인증(이메일)을 하여 로그인한다. */
  loginMobileWithEmail: LoginCommonResult;
  /**
   * 휴대폰 로그인 시 2차 인증(비밀번호)을 하여 로그인한다.
   * - verifyPasswordInLoginMobile로 비밀번호 인증 후 authenticated_key를 받아서 사용한다.
   */
  loginMobileWithPassword: LoginCommonResult;
  /** 지그재그 서비스에서 로그아웃 한다. */
  logout: Scalars['Boolean'];
  /** 스토리에 상품 매핑 */
  mappingProductToStory?: Maybe<Scalars['Boolean']>;
  /** product_item_id에 해당하는 CartItem중 상태가 ADDED인 아이템들의 상태를 DELETED로 갱신한다. */
  markCartItemListAsDeleted: Scalars['Boolean'];
  /** 읽음 표시를 설정한다. */
  markUserNotificationAsRead: UserNotificationMetadata;
  mergeSavedStyling: Scalars['Boolean'];
  moveUmdSavedProductFolder: Scalars['Boolean'];
  /**
   * 오늘의 혜택 랜덤박스 오픈
   * [error_code]
   * - OVER_REWARD_LIMIT: 획득 가능한 포인트 초과
   * @deprecated 24.09.01부터 미사용 use participateDailyMissionRandomBoxOpen instead
   */
  openDailyMissionRandomBox: OpenDailyMissionRandomBoxResult;
  /**
   * 카카오뱅크 본인인증 요청으로 덮어쓰기 한다.
   * [error_code]
   * - kakaobank_authentcation_timeout: 본인인증 시간 초과
   * - kakaobank_authentcation_error: 본인인증 에러
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - user_account_authentication_invalid_input: 올바른 인증 정보가 아닌 경우
   * - user_account_authentication_under_14_years_old: 14세 이하는 덮어쓰기 불가
   * - user_account_already_authed: 이미 인증된 계정인 경우
   * - user_account_not_found: 인증된 계정이 없거나 인증 할 계정을 찾을 수 없는 경우
   */
  overwriteKakaobankAuthentication: OverwriteKakaobankAuthenticationResult;
  /**
   * 다른 계정의 본인 인증 데이터를 덮어쓰기한다.
   * @deprecated 사용하지 않는 기능이며, 호출에 대한 응답만 있는 상태
   */
  overwriteUserAccountAuthentication: OverwriteUserAccountAuthenticationResult;
  /**
   * 202001 장바구니 추가 이벤트에 참여한다.
   *
   * [error_code]
   * - event_terminated: 이벤트가 종료됐을 경우
   * - exceeded_the_point_limit: 총 응모포인트가 한도에 도달했을 경우
   * - total_amount_of_cart_less_than_target: 장바구니에 목표금액만큼의 상품이 채워지지 않은 경우
   * - failed_to_get_point: 포인트 발급에 실패했을 경우
   * - mobile_number_already_participated: 이미 참여한 이력이 있는 경우 (휴대폰)
   */
  participateAddToCart202001Event: ParticipateAddToCart202001EvenResult;
  /**
   * 오늘의 혜택 출석 미션 참석
   *   - 연속출석
   *   error_code
   *     not_active: 2024-03-01 00:00 이후 활성화 됩니다.
   */
  participateDailyMissionAttendance: ParticipateDailyMissionAttendanceResult;
  /**
   * 오늘의 혜택 행동 미션 참여
   * --
   * behavior_type:
   * - CLICK_PRODUCT_BEAUTY_ON_SALE : 뷰티 특가 상품 클릭
   * - CLICK_PRODUCT_BRAND_ON_SALE : 브랜드 특가 상품 클릭
   * - VIEW_SELLER_CONTENT : 셀러 기획전 확인
   * - CLICK_PRODUCT_BLACK_WEEK : 블랙위크 특가 상품 클릭
   * - VISIT_COUPANG : 쿠팡 방문
   * - CLICK_VIDEO_AD : 영상 광고 클릭
   */
  participateDailyMissionBehavior: ParticipateDailyMissionBehaviorResult;
  /** 오늘의 혜택 셀러 기획전 응원 미션 완료 */
  participateDailyMissionCheerSellerContent: ParticipateDailyMissionCheerSellerContentResult;
  /**
   * 오늘의 혜택 좋아요 미션 참여
   * ---
   * type: EPICK, PRODUCT_BEAUTY, STORE
   */
  participateDailyMissionLike: ParticipateDailyMissionLikeResult;
  /**
   * 럭키볼 참여
   * [error_code]
   *   lucky_ball_policy_not_exist_in_condition: 활성화된 럭키볼 정책이 없습니다.
   *   lucky_ball_cannot_played_many_time: 럭키볼은 하루에 여러번 구매 할 수 없습니다
   */
  participateDailyMissionLuckyBall: ParticipateDailyMissionLuckyBallResult;
  /**
   * 오늘의 혜택 - 미션 스탬프 미션 참여
   * [error_code]
   *   daily_mission_not_completed: 미션을 완료하지 않았습니다.
   */
  participateDailyMissionMissionStamp: ParticipateDailyMissionMissionStampResult;
  /**
   * 오늘의 혜택 퀴즈 미션 참여
   * --
   * [ERROR_CODE]
   * - EARLY_FINISHED: 선착순 마감
   * - WRONG_ANSWER: 오답
   * - INSUFFICIENT_CART_PRODUCT: 장바구니 상품 부족
   */
  participateDailyMissionQuiz: ParticipateDailyMissionQuizResult;
  /**
   * 랜덤박스 공유한 횟수 별 리워드 수령
   * - 1, 3, 5, 10회 공유한 횟수별 리워드 수령
   * [error_code]
   * - DAILY_MISSION_NOT_INVITED_FOR_TARGET_INVITE_COUNT_REWARD: 초대 횟수별 리워드만큼의 초대를 하지 않았습니다.
   * - DAILY_MISSION_ALREADY_RECEIVED_INVITE_COUNT_REWARD: 이미 해당 공유 횟수 리워드를 받았습니다.
   */
  participateDailyMissionRandomBoxInviteCount: Scalars['Boolean'];
  /**
   * 오늘의 혜택 랜덤박스 오픈 M2 버전
   * 24.09.01 부터 활성화
   * [error_code]
   * - DAILY_MISSION_RANDOM_BOX_HAS_MAX_COUNT: 획득 가능한 포인트 초과
   */
  participateDailyMissionRandomBoxOpen: ParticipateDailyMissionRandomBoxOpenResult;
  /** 오늘의 혜택 룰렛 이벤트 참여 */
  participateDailyMissionRouletteEvent: ParticipateDailyMissionRouletteEventResult;
  /**
   * 오늘의 혜택 - 사용자가 특정지역 날씨상황에 따른 스타일링을 투표한다
   * [error_code]
   * - DAILY_MISSION_WEATHER_STYLING_POLL_ALREADY_PARTICIPATED: 이미 투표에 참여했습니다.
   * - DAILY_MISSION_WEATHER_STYLING_POLL_NOT_EXIST: 투표 정보가 없습니다.
   * - DAILY_MISSION_WEATHER_STYLING_POLL_CANNOT_PARTICIPATED_TIME: 투표 가능한 시간이 아닙니다.
   */
  participateDailyMissionTomorrowWeatherStylingPoll: Scalars['Boolean'];
  /**
   * up/down 게임 시작
   * ------
   * [error_code]
   * - DAILY_MISSION_NOT_ENOUGH_GAME_TICKET: 보유한 게임티켓이 없습니다.
   * - DAILY_MISSION_GAME_COMPLETED: 게임이 종료됐습니다.
   * - DAILY_MISSION_ALREADY_WIN: 오늘 게임 당첨자이므로 추가 게임을 진행할 수 없습니다.
   */
  participateDailyMissionUpDown: ParticipateDailyMissionUpDownResult;
  /**
   * 이벤트에 참여한다.
   * 참여가 완료되면 서버시간과 응모 결과를 반환한다.
   *
   * [error_code]
   * - event_terminated: 이벤트가 종료됐을 경우
   * - event_is_preparing: 이벤트가 대기중(오픈 전)일 경우
   * - not_found_user_account: 해당 id로 유저 계정을 찾을 수 없는 경우
   * - mobile_number_required: 해당 유저 계정에 핸드폰 번호가 없는 경우
   * - already_participated: 이미 참여한 이력이 있는 경우
   *
   * - event_ends_on_weekend: 주말에는 이벤트가 오픈되지 않음
   * - exceeded_the_participants_limit: 응모자 수가 한도에 도달했을 경우
   */
  participateEvent: ParticipateEventResult;
  /** 이벤트 메타데이터 이벤트 참여 */
  participateEventMetadata: ParticipateEventMetadataResult;
  /**
   * 출석체크 진행
   * [error_code]
   * not_login - site id 혹은, user account id가 없는 경우
   * already_participated_attendance - 유저가 하루에 직접 출석체크를 2회이상 요청 요청했을 경우 ( 출석권을 통한 출석체크는 제외 )
   */
  participateFbkAttendanceEvent: Scalars['Boolean'];
  /**
   * 출석체크 친구초대 참석
   * [error_code]
   * not_login - site id 혹은, user account id가 없는 경우
   * not_found_kakao_provider_id -> 로그인한 유저 정보로 카카오 계정 정보를 찾지 못한 경우
   * not_valid_participate_invite_link -> 초대 코드가 유효하지 않은 경우
   */
  participateFbkAttendanceEventInviteLink: Scalars['Boolean'];
  /** 패바카에서 진행하는 이벤트에 참가요청 */
  participateFbkEvent: Scalars['Boolean'];
  /**
   * 패바카에서 이벤트 메타 데이터를 사용한 이벤트에 참가요청
   * [error_code]
   * - not_event_target : 탈퇴 후 재가입한 유저의 요청시 or 기구매 이력이 있는 유저의 요청시 or 이미 혜택을 받은 유저가 다시 혜택을 수여하려 재시도하는 요청시
   * - exceeded_the_participants_limit : 이벤트가 조기 마감 되었을 경우 (준비한 쿠폰의 조기 소진 등)
   * - fail_to_event_reward : 이벤트 발급에 실패했을 경우
   */
  participateFbkEventWithEventMetadata: Scalars['Boolean'];
  /** 친구초대 참여 */
  participateFriendInvitation: FriendInvitationResult;
  /** 이벤트에 참여한다. */
  participateZibetEvent: Scalars['String'];
  /** PDP Event 참여 처리 */
  pdp_participate_event_metadata: PdpParticipateEventMetadataResult;
  /** GoodsStats이벤트를 처리 한다 (for web) */
  processGoodsStatEventsForWeb: Scalars['Boolean'];
  /**
   * KMC 본인인증 완료
   * - 응답값으로 계정찾기, 비밀번호재설정 등 사용
   */
  processKmcAuthentication: ProcessKmcAuthenticationResult;
  /**
   * 본인 인증 결과를 처리한다
   * [error_code]
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - user_account_authentication_invalid_input: 올바른 인증 정보가 아닌 경우
   * - user_account_authentication_different_user: 이미 본인인증 한 상태이고, 다른 명의로 본인인증 하는 경우
   * - user_account_authentication_already_exist: 다른 계정에 본인인증이 된 경우
   * - user_account_authentication_under_14_years_old: 14세 이하 본인인증 불가
   * - failed_adult_certification: 성인인증에 실패하였습니다
   */
  processUserAccountAuthenticationResponse: ProcessUserAccountAuthenticationResponseResult;
  /** 스토리 읽음 기록 */
  readStory: Scalars['Boolean'];
  /**
   * 자동 생성된 게임티켓 수령
   * - UPDOWN:
   * [error_code]
   * - DAILY_MISSION_CANNOT_RECEIVED_GAME_TICKET: 게임권을 받을 수 없는 상태입니다.
   */
  receiveDailyMissionGameTicket: Scalars['Boolean'];
  /** 멤버십 혜택 받기 */
  receiveMembershipBenefit: Scalars['Boolean'];
  /** 마이픽쿠폰을 수령합니다. */
  receiveMyPickCoupon: Scalars['Boolean'];
  /**
   * 직잭랭킹페스타(2021년 4월 프로모션)
   * 2탭 셀러쿠폰 다운로드 요청
   * [결과 응답]
   * true - 셀러가 발행한 쿠폰중 유저가 발급받지 않은 나머지 모든 쿠폰을 다운로드 완료
   * false - 셀러가 발행한 쿠폰을 유저가 모두 발급 받은 상태여서 더이상 다운로드 받을 쿠폰이 없을 경우
   *
   * [에러 응답]
   * 에러 메시지 or 코드에 따라 쿠폰 다운로드가 실패 한 경우
   * - 재시도를 통해 다시 다운로드 할 수 있거나, 장애로 인하여 차후 다시 시도해야 할 수 있음
   */
  receivePromotion202104SellerCoupon: Scalars['Boolean'];
  /**
   * 애플인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationApple: RecertificationResponse;
  /**
   * 페이스북인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationFacebook: RecertificationResponse;
  /**
   * 구글인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationGoogle: RecertificationResponse;
  /**
   * 카카오인증으로 사용자의 자격을 검증한다.
   * [error_code]
   * kakao_connect_failed: 카카오 계정 연결에 실패하였습니다.
   * failed_recertification: 계정으로 재인증에 실패했어요. (extensions/masked_email 참고)
   */
  recertificationKakao: RecertificationResponse;
  /**
   * 사용자가 입력한 비밀번호가 맞는지 자격검증한다.
   * [error_code]
   * - failed_recertification_password: 비밀번호를 다시 확인해주세요.
   */
  recertificationPassword: RecertificationResponse;
  /**
   * 어필레이트 리워드 사용자계정의 채널과 초대자를 등록한다.
   * ---
   * [error code]
   * [사용자계정]
   * AFFILIATE_USER_ACCOUNT_NOT_FOUND - 어필레이트 리워드 사용자계정을 찾을 수 없습니다.
   * AFFILIATE_USER_ACCOUNT_STATUS_INVALID - 어필레이트 리워드 사용자계정의 상태가 올바르지 않습니다. (status 가 CHANNEL_UNCERTIFIED 상태가 아닌 경우)
   * AFFILIATE_INVITER_REFERRAL_CODE_INVALID - 초대자 레퍼럴 코드가 유효하지 않습니다.
   * [채널]
   * AFFILIATE_USER_ACCOUNT_CHANNEL_EMPTY - 채널목록이 비어있습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_MAXIMUM_COUNT_EXCEEDED - 채널등록은 최대 5개까지 가능합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_REPRESENTATIVE_COUNT_INVALID - 대표채널 등록을 반드시 1개 해야 합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_URL_DUPLICATED - 동일 채널 URL이 존재합니다.
   * AFFILIATE_USER_ACCOUNT_ALREADY_CREATED_CHANNEL - 사용자 계정이 이미 생성된 채널이 존재합니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_URL_FORMAT_INVALID - 입력된 채널 URL format 이 올바르지 않습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_IDENTIFIER_NOT_FOUND - 입력된 채널 식별자를 찾을 수 없습니다.
   * IMAGE_CONTENT_FORMAT_INVALID - 이미지 파일 포맷이 올바르지 않습니다.
   * AFFILIATE_USER_ACCOUNT_CHANNEL_YOUTUBE_CHANNEL_ID_NOT_FOUND - 유튜브 채널 ID 를 찾을 수 없습니다.
   */
  registerRewardUserAccountChannel: Scalars['Boolean'];
  /** 계좌 간편 등록을 한다. */
  registerSimpleBank: RegisterSimpleBankResult;
  /** 비밀번호를 등록한다. */
  registerSimplePayPassword: Scalars['Boolean'];
  /** 유저 대상 물류 notification 저장 */
  registerWmsUserNotification?: Maybe<WmsUserNotification>;
  registerZibetLinkPriceOrder: Scalars['Boolean'];
  /** 스토리에 매핑된 쿠폰 정보 삭제 */
  removeCouponFromStory: Scalars['Boolean'];
  /** 스토리에 매핑된 상품 정보 삭제 */
  removeProductFromStory: Scalars['Boolean'];
  removeSaveStyling: Scalars['Boolean'];
  removeUmdSavedProduct: Scalars['Boolean'];
  removeUmdSavedProductFolder: Scalars['Boolean'];
  removeUmdSavedProductFolderById: Array<Scalars['ID']>;
  removeUmdSavedShop: Scalars['Boolean'];
  removeUmdShopTagList: Scalars['Boolean'];
  removeUmdViewedProducts: Scalars['Boolean'];
  removeWebUmdSavedProduct: Scalars['Boolean'];
  renameUmdSavedProductFolder: Scalars['Boolean'];
  /** 퀵 리뷰를 일반리뷰로 전환한다 */
  renewQuickProductReview: RenewQuickProductReviewResult;
  reorderUmdSavedProduct: Scalars['Boolean'];
  reorderUmdSavedProductFolder: Scalars['Boolean'];
  reorderUmdSavedShop: Scalars['Boolean'];
  /** 게시글 신고하기 */
  reportEPickPost: ReportEPickPostResponse;
  /** 게시글 신고하기 */
  reportEPickReply: ReportEPickReplyResponse;
  /** 이벤트 콘텐츠 댓글 신고 */
  reportEventContentReply: Scalars['Boolean'];
  /** 외부 상품리뷰를 신고한다 */
  reportExternalProductReviewAbuse: Scalars['Boolean'];
  /**
   * 상품리뷰를 신고한다
   * [error_code]
   * - product_review_not_found: 상품리뷰를 찾을 수 없는 경우
   * - product_review_abuse_report_reported_reason_detail_required: 신고 상세사유를 입력해야 하는데 입력하지 않은 경우
   */
  reportProductReviewAbuse: Scalars['Boolean'];
  /** 상품리뷰 유저 댓글을 신고한다 */
  reportProductReviewUserReply: Scalars['Boolean'];
  /** 스토리 신고 */
  reportStory: Scalars['Boolean'];
  /**
   * 톡라운지 댓글 신고
   * [error_code]
   * - ALREADY_EXIST: 이미 신고한 경우
   */
  reportTalkLoungeReply: Scalars['Boolean'];
  /**
   * 톡라운지 톡 신고
   * [error_code]
   * - ALREADY_EXIST: 이미 신고한 경우
   */
  reportTalkLoungeTalk: Scalars['Boolean'];
  /** 지그재그 광고상품 노출 요청 */
  requestAdProductExposure: Scalars['Boolean'];
  /** 지그재그 광고상품 비노출 요청 */
  requestAdProductNonExposure: Scalars['Boolean'];
  /**
   * 이벤트용 휴대폰 인증번호를 요청한다.
   *
   * [error_code]
   * - mobile_number_already_participated: 이미 참여한 이력이 있는 경우 (휴대폰)
   * - not_found_user_account: 해당 id로 유저 계정을 찾을 수 없는 경우
   * - mobile_number_already_exist: 같은 번호로 인증받은 휴대폰 번호가 존재할 경우
   * - mobile_phone_token_send_failed: 문자 메세지 전송에 실패한 경우
   * - mobile_phone_too_many_send_token: 같은 번호로 n분동안 5회 이상 전송한 경우
   */
  requestAuthenticationTokenForEvent: RequestAuthenticationTokenForEventResult;
  /** 이메일 인증 과정에 필요한 값을 요청한다. */
  requestEmailAuthentication: RequestEmailAuthenticationResult;
  /**
   * 해당 상품주문의 교환 요청한다.
   * [error_code]
   * - order_item_request_failed: 요청에 실패하였습니다. 주문을 다시 확인해주세요.
   * - duplicated_logen_invoice_info: 택배사 사정으로 주문당 한번의 교환 접수만 가능합니다. 추가 교환접수를 원하실 경우, 쇼핑몰에 문의해주시기 바랍니다.
   */
  requestExchangeOrderItemList: Scalars['Boolean'];
  /**
   * 본인인증으로 인증된 사용자에게 비밀번호 재설정을 위한 정보를 전달한다.
   * [의도된 error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  requestForResetPasswordWithAuthentication: RequestForResetPasswordWithAuthenticationResult;
  /**
   * 이메일인증으로 인증된 사용자에게 비밀번호 재설정을 위한 정보를 전달한다.
   * [error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  requestForResetPasswordWithEmailAuthentication: RequestForResetPasswordWithAuthenticationResult;
  /**
   * 이메일과 휴대폰 인증으로 비밀번호 재설정이 가능한지 검증한다.
   * [error_code]
   * - mobile_phone_token_authentication_failed: 휴대폰 번호와 인증번호가 알맞지 않는경우
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  requestForResetPasswordWithMobileAuthentication: RequestForResetPasswordWithAuthenticationResult;
  /**
   * 카카오 학생증 인증 요청
   * [error_code]
   * - route_not_logged_in: 로그인이 되어있지 않은 경우
   * - authentication_required: 본인인증 정보가 없거나 현재 계정이 본인인증 된 상태가 아닌 경우
   * - authentication_sync_required: 본인인증 정보와 계정정보가 상이한 경우
   * - kakao_invalid_account: 사용자 정보로 카카오톡 계정이 존재하지 않는 경우
   * - kakao_present_proof_request_failed: 카카오 톡학생증 제출요청 API 요청이 실패한 경우
   * - kakao_present_proof_invalid_result: 알수없는 이유로 카카오 톡학생증 제출요청 API 결과값이 비정상인 경우
   */
  requestKakaoStudentPresentProof: RequestKakaoStudentPresentProofResult;
  /** 카카오뱅크 본인인증 요청을 한다. */
  requestKakaobankAuthentication: RequestKakaobankAuthenticationResult;
  /**
   * 휴대폰 인증 번호를 전송하며, 토큰 유효한 시간을 반환한다.
   * [error_code]
   * - mobile_phone_token_send_failed: 문자 메세지 전송에 실패한 경우
   * - too_many_request: 같은 ip로 n분동안 5회 이상 전송한 경우
   */
  requestMobileAuthentication: RequestMobileAuthenticationResult;
  /**
   * 반품 요청 한다.
   * [error_code]
   * - order_item_request_failed: 요청에 실패하였습니다. 주문을 다시 확인해주세요.
   * - duplicated_logen_invoice_info: 택배사 사정으로 주문당 한번의 반품 접수만 가능합니다. 추가 반품접수를 원하실 경우, 쇼핑몰에 문의해주시기 바랍니다.
   * - unclassified_internal_problem: 일시적으로 문제가 발생했습니다. 이용에 불편을 드린 점 사과드립니다. 문제가 계속될 경우 고객센터로 문의해주세요. (tel. 02-1670-8050).
   * - refund_additional_catalog_item_required: 해당 상품을 반품할 경우, 기본상품과 추가상품을 함께 반품 요청해야 합니다.
   */
  requestReturnOrder: Scalars['Boolean'];
  /** 계좌 인증 요청한다. */
  requestSimpleBankCert: RequestSimpleBankCertResult;
  /**
   * 본인 인증 과정에 필요한 값을 요청한다
   * [error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - invalid_authentication_purpose_type: purpose_type을 잘 못 입력한 경우
   */
  requestUserAccountAuthentication: RequestUserAccountAuthenticationResult;
  /** 계정의 비밀번호 초기화를 요청한다 */
  requestUserAccountPasswordReset: Scalars['Boolean'];
  /** 결제를 요청한다. (사용 가능 수단: 포인트) */
  requestUserPay: RequestUserPayResult;
  /** user_account_profile(nickname, profile_image_url) 초기화 (cs-admin) */
  resetEPickProfile: Scalars['Boolean'];
  /**
   * 인증된 이메일로 비밀번호를 재설정한다.
   * [error_code]
   * - user_account_password_reset_invalid_token (ignorable): 토큰이 유효하지 않은 경우
   * - user_account_not_found: 해당 사용자 계정이 존재하지 않음
   */
  resetPasswordWithEmail: Scalars['Boolean'];
  /** 비밀번호 변경한다. (기존 본인인증 정보와 일치해야만 가능) */
  resetSimplePayPassword: Scalars['Boolean'];
  /**
   * 계정 비밀번호를 초기화한다
   * [error_code]
   * - user_account_password_reset_invalid_token (ignorable): 토큰이 유효하지 않은 경우
   * - user_account_not_found: 해당 사용자 계정이 존재하지 않음
   */
  resetUserAccountPassword: Scalars['Boolean'];
  /**
   * 피초대인 리워드 지급
   * @deprecated use participateFriendInvitation
   */
  rewardInvitee: RewardInviteeResult;
  /**
   * 초대인 리워드 지급
   * @deprecated 사용 안함
   */
  rewardInviter: RewardInviterResult;
  /** 상품 퍼널을 저장한다 */
  saveProductFunnelInfoList: Scalars['Boolean'];
  saveStyling: UxSavedStyling;
  /** 상품 찜 추가 */
  saveUmdProduct: UmdSavedProduct;
  saveUmdShop: UmdSavedShop;
  saveUmdShopList: Scalars['Boolean'];
  saveUmdViewedProducts: Scalars['Boolean'];
  /** 찜 / 새로운 가격 하락 상품 확인 */
  sawSavedProductDroppedPrice: Scalars['Boolean'];
  sendAppWebViewLog: Scalars['Boolean'];
  /** @deprecated Use sendMobileAuthenticationToken */
  sendMobileAuth: SendMobileAuthResult;
  /**
   * 휴대폰 인증 번호를 전송한다.
   * [error_code]
   * - mobile_phone_token_send_failed: 문자 메세지 전송에 실패한 경우
   * - too_many_request: 같은 ip로 n분동안 5회 이상 전송한 경우
   * @deprecated requestMobileAuthentication 사용 권장
   */
  sendMobileAuthenticationToken: Scalars['Boolean'];
  /** 사용자 계정 신체정보를 신규생성하거나 수정한다. */
  setUserAccountBody: Scalars['Boolean'];
  /** sms 수신 동의 여부를 세팅한다 */
  setUserAccountEmailReceptionAgreed: Scalars['Boolean'];
  /** 야간 혜택 알림 수신 동의 여부를 세팅한다 */
  setUserAccountNightlyAdNotiAgreed: Scalars['Boolean'];
  /** 오퍼월 동의 여부를 세팅한다 */
  setUserAccountOfferWallAgreed: Scalars['Boolean'];
  /**
   * 스토어 알림 Push 수신 동의 여부
   * @description
   * 토글 형태로 호출에 따라 Push 수신 동의 여부를 설정 한다.
   * 단, push_agreed 값을 전달 할 경우 지정된 값으로 강제 설정 된다.
   * user_account_id, site_id의 경우 session의 값을 사용한다.
   * 단, user_account_id, site_id 가 입력된 경우 입력된 값을 사용한다.
   */
  setUserAccountShopNotificationPushAgreed: Scalars['Boolean'];
  /**
   * 스토어 알림 구독 여부 설정
   * @description
   * 토글 형태로 호출에 따라 구독 여부를 설정 한다.
   * 단, is_subscribed 값을 전달 할 경우 지정된 값으로 강제 설정 된다.
   * user_account_id, site_id의 경우 session의 값을 사용한다.
   * 단, user_account_id, site_id 가 입력된 경우 입력된 값을 사용한다.
   */
  setUserAccountShopSubscribed: Scalars['Boolean'];
  /** email 수신 동의 여부를 세팅한다 */
  setUserAccountSmsReceptionAgreed: Scalars['Boolean'];
  /** 뉴티아라 제3자 제공 동의 여부를 세팅한다 */
  setUserAccountThirdProvideAgreed: Scalars['Boolean'];
  /** Users 속성 추가/업데이트 */
  setUserAttribute: Scalars['Boolean'];
  /** 알림 푸시 수신을 카테고리별로 설정한다. */
  setUserNotificationPushAgreed: Scalars['Boolean'];
  /**
   * 회원 가입
   * [error]
   * - auth_duplicated_email: 이미 존재하는 이메일입니다. (일반계정)
   * - auth_invalid_email: 잘못된 이메일입니다.
   * - auth_invalid_password_format: 비밀번호는 6자리 이상입니다.
   * - user_account_email_exists: user_account_email_exists (휴면계정)
   */
  signup: SignupResult;
  /**
   * 애플 로그인 및 지그재그 계정 가입
   * [error_list]
   * - invalid_apple_code: 잘못된 애플 코드입니다
   * - user_account_email_exists: 이미 애플 계정의 이메일로 가입한 계정이 있을 경우
   * - user_account_email_is_required: 사용자의 이메일 정보가 필요합니다.
   */
  signupApple: SignUpAppleResult;
  /**
   * 페이스북 로그인 및 지그재그 계정 가입
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_is_required: 사용자의 이메일 정보가 없는 경우
   * - user_account_email_exists: 사용자의 이메일 정보로 이미 가입된 계정이 있을 경우
   * - facebook_account_already_connected: 이미 연결된 페이스북 계정이 있는 경우
   */
  signupFacebook: SignUpFacebookResult;
  /**
   * 구글 로그인 및 지그재그 계정 가입
   * [error_list]
   * - social_invalid_token: token이 잘못된 경우
   * - user_account_email_exists: 이미 구글 계정의 이메일로 가입한 계정이 있을 경우
   */
  signupGoogle: SignUpGoogleResult;
  /**
   * 지그재그 서비스에 카카오 계정으로 회원가입한다.
   * [error_list]
   * - kakao_connect_failed: 알 수 없는 이유로 카카오 로그인 실패
   * - kakao_email_not_found: 카카오 계정에 이메일 정보가 없을 경우
   * - user_account_email_exists: 이미 카카오 계정의 이메일로 가입한 계정이 있을 경우
   * - kakao_mobile_tel_not_found: 휴대폰번호가 없는 카카오 계정일 경우
   * - user_account_mobile_tel_exists: 이미 가입된 휴대폰번호로 요청한 경우
   * - user_account_invalid_email: 유효하지 않은 이메일 형식인 경우
   * - deleted_user_account_within_the_period: 재가입 제한 기간 내 재가입 요청한 경우
   */
  signupKakao: SignUpKakaoResult;
  /** @deprecated Field no longer supported */
  story_service_reaction: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  story_service_view: Scalars['Boolean'];
  /** FCM 토픽을 구독한다 */
  subscribeFcmRegistrationTokenToTopic: Scalars['Boolean'];
  /** 톡라운지 관심 키워드 구독 */
  subscribeTalkLoungeKeyword: Scalars['Boolean'];
  /** 사용자 계정의 상품리뷰 도움안돼요 상태를 변경한다. */
  toggleProductReviewDislike: ToggleProductReviewDislikeResult;
  /** 사용자 계정의 상품리뷰 도움돼요 상태를 변경한다. */
  toggleProductReviewLike: ToggleProductReviewLikeResult;
  /** 톡라운지 관심 키워드 구독 해제 */
  unsubscribeTalkLoungeKeyword: Scalars['Boolean'];
  /** 유저 apps flyer conversion 데이터를 업데이트 한다. */
  updateAppsFlyerConversionData: Scalars['Boolean'];
  /** 오늘의 혜택 세금 서류 등록 */
  updateDailyMissionUserDocument: Scalars['Boolean'];
  /** 팔로우, 언팔로우 처리 */
  updateEPickFollow: Scalars['Boolean'];
  /** 좋아요 활성/비활성 처리 */
  updateEPickLike: Scalars['Boolean'];
  /** post version 이 V1인 post 를 수정한다 */
  updateEPickPost: Scalars['Boolean'];
  /** 게시글 노출/미노출 처리 (backoffice) */
  updateEPickPostStatusForBackoffice: Scalars['Boolean'];
  /** post version 이 V2인 post 를 수정한다 */
  updateEPickPostV2: Scalars['Boolean'];
  /** 프로필 수정 */
  updateEPickProfile: UpdateEPickProfileResponse;
  /** 프로필 이미지 수정 */
  updateEPickProfileImageUrl: Scalars['Boolean'];
  /** 프로필 status 수정 (backoffice) */
  updateEPickProfileStatus: Scalars['Boolean'];
  /** 이벤트 콘텐츠 댓글 수정 */
  updateEventContentReply: Scalars['Boolean'];
  /** 이벤트 메타데이터 광고 수신동의 */
  updateEventMetadataNotification: Scalars['Boolean'];
  /** FCM 등록 토큰을 갱신한다 */
  updateFcmRegistrationToken: Scalars['Boolean'];
  updateLastItemsDate: Scalars['Boolean'];
  /**
   * 주문서의 수령자 정보를 변경한다(V2 필요한 인자만 받아서 변경)
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없는 경우
   * - order_item_invalid_status: 주문완료/배송준비중 외의 주문 상태를 갖는 품목이 있는 경우
   */
  updateOrderReceiverV2: Scalars['Boolean'];
  /**
   * 주문의 배송메모를 변경한다.
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없습니다.
   */
  updateOrderShippingMemo: Scalars['Boolean'];
  /** 상품 리뷰를 수정한다 */
  updateProductReview: Scalars['Boolean'];
  /** 리뷰 프로필 저장 */
  updateProductReviewProfile: Scalars['Boolean'];
  /** 상품리뷰 유저 댓글을 수정한다 */
  updateProductReviewUserReply: Scalars['Boolean'];
  /** 퀵리뷰를 수정한다 */
  updateQuickProductReview: UpdateQuickProductReviewResult;
  /** 주문의 수령자 정보를 변경한다. */
  updateReceiverExchangeRequestInfo: Scalars['Boolean'];
  /** 추천 태그 등록/해제 */
  updateRecommendTag: Scalars['Boolean'];
  /** 정산계좌 업데이트 */
  updateRewardUserBankAccount: Scalars['Boolean'];
  /** 정산서류 등록/수정 */
  updateRewardUserSettlementDocument: Scalars['Boolean'];
  /** 스토리 수정 */
  updateStory: Scalars['Boolean'];
  updateUmdShopTagList: Scalars['Boolean'];
  /** 사용자 계정 정보를 갱신한다 */
  updateUserAccount: Scalars['Boolean'];
  /** 사용자의 주문문의 확인 날짜를 갱신한다 */
  updateUserAccountDateReadOrderItemInquiries: Scalars['Boolean'];
  /**
   * 사용자 계정의 비밀번호를 수정한다.
   * [error_code]
   * - user_account_invalid_password: 기존 암호가 일치하지 않습니다.
   */
  updateUserAccountPassword: Scalars['Boolean'];
  /** 유저 프로필을 수정한다. */
  updateUserAccountProfile: Scalars['Boolean'];
  /** 유저 프로필 이미지 수정한다. */
  updateUserAccountProfileImage: Scalars['Boolean'];
  /** 사용자 광고 푸시 동의 상태를 갱신한다 */
  updateUserAdNotiStatus: UpdateUserAdNotiStatusResult;
  /** 사용자 앱 푸시 동의 상태를 갱신한다 */
  updateUserAppNotiStatus: UpdateUserAppNotiStatusResult;
  /**
   * 사용자 로케일 정보를 업데이트한다
   * [error_code]
   * - invalid_params: 국가/언어/통화 코드가 모두 없는 경우
   * - user_locale_not_found: 이미 생성된 사용자 로케일 정보가 없는 경우
   */
  updateUserLocale: UserLocale;
  /** 최근 알림 확인 일을 업데이트 한다. */
  updateUserNotificationSeenDate: Scalars['Boolean'];
  updateUserSaleNotiStatus?: Maybe<SaleTabNotificationUpdateResult>;
  /** 사용자별 배송주소록을 수정한다. */
  updateUserShippingAddressBook: UserShippingAddressBook;
  /** 브랜드패션 온보딩 선택 */
  uploadBrandFashionOnboardingUser?: Maybe<Scalars['Boolean']>;
  /** DDP 온보딩 스타일 선택 */
  uploadDdpOnboardingStyle: Scalars['Boolean'];
  /** 제세공과금 서류 제출 생성/수정 */
  upsertDailyMissionUserDocument: Scalars['Boolean'];
  /**
   * 날씨 알림 스케쥴 생성 및 수정
   * [error_code]
   * - DAILY_MISSION_WEATHER_NOTIFICATION_HOUR_MINUTE_INVALID: 시간, 분이 유효하지 않습니다.
   */
  upsertDailyMissionWeatherNotificationSchedule: Scalars['Boolean'];
  /** 개인통관고유부호를 저장한다. */
  upsertPersonalClearanceCode: PersonalClearanceCode;
  /**
   * 출석권 사용
   * [error_code]
   * not_login - site id 혹은, user account id가 없는 경우
   * not_exist_usable_attendance_ticket - 사용 가능한 출석권이 없는 경우.
   */
  useFbkAttendanceTicket: Scalars['Boolean'];
  /**
   * 주어진 쿠폰 코드를 사용한 것으로 표시한다
   * @deprecated Field no longer supported
   */
  useShopEventCouponCode?: Maybe<UseShopEventCouponCodeResult>;
  /**
   * 상품주문 목록의 취소를 요청 후 환불이 가능한 상태이면 환불처리한다.
   * [error_code]
   * - order_not_found: 해당하는 조건의 주문을 찾을 수 없는 경우
   * - order_item_not_found: 해당하는 조건의 상품주문을 찾을 수 없는 경우
   * - order_item_invalid_status: 상품주문의 상태가 요청 할 수 없는 상태인 경우
   * - order_item_already_requested: 이미 다른 타입의 요청이 진행중인 상품주문이 존재하는 경우
   */
  userRequestCancelAndRefundOrderItemList: Scalars['Boolean'];
  uxSavedProduct: UxSavedProduct;
  /**
   * 발송된 인증코드로 이메일 인증을 진행한다.
   * [error_code]
   * - email_authentication_token_expired: 인증 번호 입력 유효 시간이 지났어요. 다시 인증해주세요.
   * - email_authentication_failed: 올바른 인증번호를 입력해주세요.
   */
  verifyEmailAuthentication: VerifyEmailAuthenticationResult;
  /**
   * 휴대폰 인증 후 이메일을 반환한다.
   * [error_code]
   * - mobile_phone_token_authentication_failed: 휴대폰 번호와 인증번호가 알맞지 않는경우
   */
  verifyMobileAuthenticationToken: VerifyMobileAuthenticationTokenResult;
  /**
   * 휴대폰 로그인 진행시 비밀번호 검증을 진행한다.
   * [error_code]
   * - user_account_invalid_password: 비밀번호가 일치하지 않는 경우
   * - too_many_verify_password_blocked_with_2fa: 비밀번호 n번이상 실패시 제한 경고
   * - too_many_verify_password_warning_with_2fa: 비밀번호 n번이상 실패시 제한
   */
  verifyPasswordInLoginMobile: VerifyPasswordInLoginMobileResult;
  /**
   * 사용자의 자격이 검증되었는지 확인한다.
   * [error_code]
   * need_recertification: 사용자 재인증이 필요합니다.
   */
  verifyRecertification: RecertificationResponse;
  /** 비밀번호를 확인한다. */
  verifySimplePayPassword: VerifySimplePayPasswordOutput;
  /** 유저별 스타일 탭 접근 체크 */
  visitStyleTab: Scalars['Boolean'];
  /** web 찜 */
  web_saveUmdProduct: UmdSavedProduct;
  /**
   * 해당 주문 아이템 요청을 철회한다.
   * [error_code]
   * - order_item_not_found: 해당 주문 품목을 찾을 수 없습니다.
   * - order_item_request_withdraw_failed: 철회에 실패하였습니다. 주문을 다시 확인해주세요.
   */
  withdrawOrderItemRequest: Scalars['Boolean'];
  /**
   * 해당 상품주문 요청 목록을 철회한다.
   * [error_code]
   * - order_item_not_found: 해당 상품주문이 없는 경우
   * - order_item_not_requested: 요청된 상품주문이 아닌 경우
   * - order_item_invalid_status: 철회 가능한 상태가 아닌 경우 (요청 처리 완료 - 취소 완료/반품 완료)
   */
  withdrawOrderItemRequestList: Scalars['Boolean'];
};

export type MutationAcceptTalkLoungeRewardedQuestionArgs = {
  input: AcceptTalkLoungeRewardedQuestionInput;
};

export type MutationAddAttributeToStoryArgs = {
  input: AddAttributeToStoryInput;
};

export type MutationAddBackOldCartItemListArgs = {
  input: AddBackOldCartItemListInput;
};

export type MutationAddCartItemListArgs = {
  input: AddCartItemListInput;
};

export type MutationAgreePushCategoryArgs = {
  input: AgreePushCategoryInput;
};

export type MutationAgreeUserAccountTermArgs = {
  input: AgreeUserAccountTermInput;
};

export type MutationAnswerTalkLoungePollArgs = {
  input: AnswerTalkLoungePollInput;
};

export type MutationApplyPromotionExperienceGroupArgs = {
  input: ApplyPromotionExperienceGroupInput;
};

export type MutationApplyPromotionExperienceGroupProductApplicantArgs = {
  input: ApplyPromotionExperienceGroupProductApplicantInput;
};

export type MutationApplyRaffleArgs = {
  input: ApplyRaffleInput;
};

export type MutationAuthorizeAndGetEmailsArgs = {
  input: AuthorizeAndGetEmailsInput;
};

export type MutationAuthorizeMobileTokenArgs = {
  input: AuthorizeMobileTokenInput;
};

export type MutationAuthorizeMobileTokenForEventArgs = {
  input: AuthorizeMobileTokenForEventInput;
};

export type MutationBehaveDailyMissionArgs = {
  input: BehaveDailyMissionInput;
};

export type MutationBlockTalkLoungeUserArgs = {
  input: BlockTalkLoungeUserInput;
};

export type MutationCancelAndRefundOrderArgs = {
  input: CancelAndRefundOrderInput;
};

export type MutationCancelNotTransferredOrderArgs = {
  input: CancelNotTransferredOrderInput;
};

export type MutationChangeSimplePayPasswordArgs = {
  input: ChangeSimplePayPasswordInput;
};

export type MutationCheckFbkBadgeArgs = {
  input: CheckBadgeInput;
};

export type MutationCheckPaymentBankAccountArgs = {
  input: PaymentBankAccountCheckInput;
};

export type MutationCheckSimpleBankCertArgs = {
  input: CheckSimpleBankCertInput;
};

export type MutationCheckUpdateInUserAccountMetadataArgs = {
  input: CheckUpdateInUserAccountMetadataInput;
};

export type MutationCheckUserProfileNicknameValidityArgs = {
  input: CheckUserProfileNicknameValidityInput;
};

export type MutationCompleteDailyMissionUpDownArgs = {
  input: CompleteDailyMissionUpDownInput;
};

export type MutationConfirmOrderItemListAndIssueRepurchaseCouponListArgs = {
  input: ConfirmOrderItemListAndIssueRepurchaseCouponListInput;
};

export type MutationConnectAppleUserAccountArgs = {
  input: ConnectAppleUserAccountInput;
};

export type MutationConnectAppleWithAuthArgs = {
  input: ConnectAppleWithAuthInput;
};

export type MutationConnectFacebookUserAccountArgs = {
  input: ConnectFacebookUserAccountInput;
};

export type MutationConnectFacebookWithAuthArgs = {
  input: ConnectFacebookWithAuthInput;
};

export type MutationConnectGoogleUserAccountArgs = {
  input: ConnectGoogleUserAccountInput;
};

export type MutationConnectGoogleWithAuthArgs = {
  input: ConnectGoogleWithAuthInput;
};

export type MutationConnectKakaoUserAccountArgs = {
  input: ConnectKakaoUserAccountInput;
};

export type MutationConnectKakaoWithAuthArgs = {
  input: ConnectKakaoWithAuthInput;
};

export type MutationConnectSocialWithEmailAuthenticationArgs = {
  input: ConnectSocialWithEmailAuthenticationInput;
};

export type MutationCreateAffiliateDeepLinkArgs = {
  input: CreateAffiliateDeepLinkInput;
};

export type MutationCreateAffiliateShareLinkArgs = {
  input: CreateAffiliateShareLinkInput;
};

export type MutationCreateBrazeUserArgs = {
  input: CreateBrazeUserInput;
};

export type MutationCreateCatalogQuestionArgs = {
  input: CatalogQuestionInput;
};

export type MutationCreateCheckoutArgs = {
  input: CreateCheckoutInput;
};

export type MutationCreateDailyMissionEventLinkArgs = {
  input: CreateDailyMissionEventLinkInput;
};

export type MutationCreateDailyMissionGameInvitationLinkArgs = {
  input?: InputMaybe<CreateDailyMissionGameInvitationLinkInput>;
};

export type MutationCreateDailyMissionGameInvitationRewardArgs = {
  input: CreateDailyMissionGameInvitationRewardInput;
};

export type MutationCreateDailyMissionInvitationLinkArgs = {
  is_kakao_talk: Scalars['Boolean'];
  mobile_tel?: InputMaybe<Scalars['String']>;
};

export type MutationCreateDailyMissionInvitationRewardArgs = {
  invite_code: Scalars['String'];
};

export type MutationCreateDailyMissionInvitationRewardV2Args = {
  input: CreateDailyMissionInvitationRewardV2Input;
};

export type MutationCreateDailyMissionLikedRegionArgs = {
  input: CreateDailyMissionLikedRegionInput;
};

export type MutationCreateEPickPostArgs = {
  input: CreateEPickPostInput;
};

export type MutationCreateEPickPostV2Args = {
  input: CreateEPickPostInputV2;
};

export type MutationCreateEPickReReplyArgs = {
  input: CreateEPickReReplyInput;
};

export type MutationCreateEPickReplyArgs = {
  input: CreateEPickReplyInput;
};

export type MutationCreateEventContentReplyArgs = {
  input: CreateEventContentReplyInput;
};

export type MutationCreateMyPickCouponUserRequestArgs = {
  input: CreateMyPickCouponUserRequestInput;
};

export type MutationCreateOnboardingBirthYearArgs = {
  birth_year: Scalars['String'];
};

export type MutationCreateOnboardingPointsArgs = {
  user_account_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type MutationCreateOnboardingUserArgs = {
  birth_year: Scalars['Int'];
  uuid?: InputMaybe<Scalars['String']>;
};

export type MutationCreateOrderItemInquiryArgs = {
  input: CreateOrderItemInquiryInput;
};

export type MutationCreateOrderSheetArgs = {
  input: CreateOrderSheetInput;
};

export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput;
};

export type MutationCreateProductReviewUserReplyArgs = {
  input: CreateProductReviewUserReplyInput;
};

export type MutationCreateQuickProductReviewArgs = {
  input: CreateQuickProductReviewInput;
};

export type MutationCreateRewardUserAccountArgs = {
  input: CreateRewardUserAccountInput;
};

export type MutationCreateShopDetailShortUrlInAppsflyerArgs = {
  shop_main_domain: Scalars['String'];
};

export type MutationCreateShortUrlInAppsflyerArgs = {
  catalog_product_id?: InputMaybe<Scalars['String']>;
  deep_link: Scalars['String'];
  shop_id?: InputMaybe<Scalars['ID']>;
  shop_product_no?: InputMaybe<Scalars['String']>;
};

export type MutationCreateSimpleBankCertArgs = {
  input: CreateSimpleBankCertInput;
};

export type MutationCreateSimpleBankUserAuthArgs = {
  input: CreateSimpleBankUserAuthInput;
};

export type MutationCreateStoryArgs = {
  input: CreateStoryInput;
};

export type MutationCreateTalkLoungeReplyArgs = {
  input: CreateTalkLoungeReplyInput;
};

export type MutationCreateTalkLoungeTalkArgs = {
  input: CreateTalkLoungeTalkInput;
};

export type MutationCreateTalkLoungeTalkShareLinkArgs = {
  talk_id: Scalars['ID'];
};

export type MutationCreateUmdSavedProductFolderArgs = {
  folder_name: Scalars['String'];
};

export type MutationCreateUploadLinkArgs = {
  input: CreateUploadLinkInput;
};

export type MutationCreateUserDefaultShippingAddressBookArgs = {
  input: CreateUserDefaultShippingAddressBookInput;
};

export type MutationCreateUserRefundAccountArgs = {
  input: CreateUserRefundAccountInput;
};

export type MutationCreateUserShippingAddressBookArgs = {
  input: CreateUserShippingAddressBookInput;
};

export type MutationDeleteCartItemListArgs = {
  input: DeleteCartItemListInput;
};

export type MutationDeleteCatalogQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDailyMissionLikedRegionArgs = {
  input: DeleteDailyMissionLikedRegionInput;
};

export type MutationDeleteEPickPostArgs = {
  post_id: Scalars['ID'];
};

export type MutationDeleteEPickReplyArgs = {
  reply_id: Scalars['ID'];
};

export type MutationDeleteEventContentReplyArgs = {
  input: DeleteEventContentReplyInput;
};

export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

export type MutationDeleteOrderItemInquiryArgs = {
  input: DeleteOrderItemInquiryInput;
};

export type MutationDeleteProductReviewUserReplyArgs = {
  input: DeleteProductReviewUserReplyInput;
};

export type MutationDeleteStoryArgs = {
  story_id: Scalars['ID'];
};

export type MutationDeleteTalkLoungeReplyArgs = {
  reply_id: Scalars['ID'];
};

export type MutationDeleteTalkLoungeTalkArgs = {
  talk_id: Scalars['ID'];
};

export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccountInput;
};

export type MutationDeleteUserAccountShopNotificationListArgs = {
  input: DeleteUserAccountShopNotificationListInput;
};

export type MutationDeleteUserNotificationArgs = {
  input: DeleteUserNotificationInput;
};

export type MutationDeleteUserShippingAddressBookArgs = {
  input: DeleteUserShippingAddressBookInput;
};

export type MutationDeregisterSimpleBankArgs = {
  input: DeregisterSimpleBankInput;
};

export type MutationFbkIssueCouponPackArgs = {
  input: FbkIssueCouponPackInput;
};

export type MutationFbkShopliveIssueCouponArgs = {
  input: IssueShopliveCouponInput;
};

export type MutationFbkShopliveIssueCouponPackArgs = {
  input: IssueShopliveCouponPackInput;
};

export type MutationGenerateDailyMissionInvitationLinkArgs = {
  input: GenerateDailyMissionInvitationLinkInput;
};

export type MutationInitializeDailyMissionGameTicketGenerateMachineArgs = {
  input: InitializeDailyMissionGameTicketGenerateMachineInput;
};

export type MutationIsUserAccountPasswordResetTokenValidArgs = {
  input: IsUserAccountPasswordResetTokenValidInput;
};

export type MutationIsUserInviteEnableArgs = {
  input?: InputMaybe<IsUserInviteEnableInput>;
};

export type MutationIsValidNicknameArgs = {
  nickname: Scalars['String'];
};

export type MutationIssueCouponAsyncArgs = {
  input: IssueCouponAsyncInput;
};

export type MutationIssueCouponByCodeArgs = {
  input: IssueCouponByCodeInput;
};

export type MutationIssueCouponByCouponPackArgs = {
  input: IssueCouponByCouponPackInput;
};

export type MutationIssueCouponByIdListArgs = {
  input: IssueCouponByIdListInput;
};

export type MutationIssueCouponByRedeemArgs = {
  input: IssueCouponByRedeemInput;
};

export type MutationIssueCouponCollectionCouponArgs = {
  input: IssueCouponCollectionCouponInput;
};

export type MutationIssueExhibitionCouponsArgs = {
  input: IssueExhibitionCouponsInput;
};

export type MutationIssueShopEventCouponCodeArgs = {
  input: IssueShopEventCouponCodeInput;
};

export type MutationIssueShopliveCouponArgs = {
  input: IssueShopliveCouponInput;
};

export type MutationIssueShopliveCouponPackArgs = {
  input: IssueShopliveCouponPackInput;
};

export type MutationIssueStoryCouponArgs = {
  coupon_id: Scalars['ID'];
  story_id: Scalars['ID'];
  with_save_store?: InputMaybe<Scalars['Boolean']>;
};

export type MutationIssueUserAccountCouponByPolicyIdListArgs = {
  input: IssueUserAccountCouponByPolicyIdListInput;
};

export type MutationIssueUserAccountShopCouponListArgs = {
  input: IssueUserAccountShopCouponListInput;
};

export type MutationIssueZigzagWeekCouponArgs = {
  input: IssueZigzagWeekCouponInput;
};

export type MutationKitto_Content_LikeArgs = {
  id: Scalars['ID'];
  reactionType?: InputMaybe<KittoReactionType>;
};

export type MutationKitto_Question_CreateArgs = {
  create_input: KittoCreateQuestionInput;
};

export type MutationLikeTalkLoungeReplyArgs = {
  input: LikeTalkLoungeReplyInput;
};

export type MutationLikeTalkLoungeTalkArgs = {
  input: LikeTalkLoungeTalkInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLoginAppleArgs = {
  input: LoginAppleInput;
};

export type MutationLoginFacebookArgs = {
  input: LoginFacebookInput;
};

export type MutationLoginGoogleArgs = {
  input: LoginGoogleInput;
};

export type MutationLoginKakaoArgs = {
  input: LoginKakaoInput;
};

export type MutationLoginMobileArgs = {
  input: LoginMobileInput;
};

export type MutationLoginMobileWithAuthenticationArgs = {
  input: LoginMobileWith2FaInput;
};

export type MutationLoginMobileWithEmailArgs = {
  input: LoginMobileWith2FaInput;
};

export type MutationLoginMobileWithPasswordArgs = {
  input: LoginMobileWith2FaInput;
};

export type MutationMappingProductToStoryArgs = {
  product_id_list: Array<Scalars['String']>;
  story_id: Scalars['ID'];
};

export type MutationMarkCartItemListAsDeletedArgs = {
  input: MarkCartItemListAsDeletedInput;
};

export type MutationMarkUserNotificationAsReadArgs = {
  input: MarkUserNotificationAsReadInput;
};

export type MutationMoveUmdSavedProductFolderArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
  product_info?: InputMaybe<UmdProductInfoInput>;
};

export type MutationOverwriteKakaobankAuthenticationArgs = {
  input: OverwriteKakaobankAuthenticationInput;
};

export type MutationOverwriteUserAccountAuthenticationArgs = {
  input: OverwriteUserAccountAuthenticationInput;
};

export type MutationParticipateDailyMissionBehaviorArgs = {
  behavior_type: Scalars['String'];
};

export type MutationParticipateDailyMissionCheerSellerContentArgs = {
  shop_id: Scalars['ID'];
};

export type MutationParticipateDailyMissionLikeArgs = {
  type: Scalars['String'];
};

export type MutationParticipateDailyMissionLuckyBallArgs = {
  input: ParticipateDailyMissionLuckyBallInput;
};

export type MutationParticipateDailyMissionQuizArgs = {
  answer: Scalars['String'];
  quiz_id: Scalars['ID'];
};

export type MutationParticipateDailyMissionRandomBoxInviteCountArgs = {
  input: ParticipateDailyMissionRandomBoxInviteCountInput;
};

export type MutationParticipateDailyMissionRouletteEventArgs = {
  input: ParticipateDailyMissionRouletteEventInput;
};

export type MutationParticipateDailyMissionTomorrowWeatherStylingPollArgs = {
  input: ParticipateDailyMissionTomorrowWeatherStylingPollInput;
};

export type MutationParticipateEventArgs = {
  input: ParticipateEventInput;
};

export type MutationParticipateEventMetadataArgs = {
  input: ParticipateEventMetadataInput;
};

export type MutationParticipateFbkAttendanceEventInviteLinkArgs = {
  input: ParticipateFbkAttendanceEventInviteLinkInput;
};

export type MutationParticipateFbkEventArgs = {
  input: ParticipateFbkEventInput;
};

export type MutationParticipateFbkEventWithEventMetadataArgs = {
  input: ParticipateFbkEventWithEventMetadataInput;
};

export type MutationParticipateFriendInvitationArgs = {
  input: ParticipateFriendInvitationInput;
};

export type MutationParticipateZibetEventArgs = {
  input: ParticipateZibetEventInput;
};

export type MutationPdp_Participate_Event_MetadataArgs = {
  input: PdpParticipateEventMetadataInput;
};

export type MutationProcessGoodsStatEventsForWebArgs = {
  input: ProcessGoodsStatEventsForWebInput;
};

export type MutationProcessKmcAuthenticationArgs = {
  input: ProcessKmcAuthenticationInput;
};

export type MutationProcessUserAccountAuthenticationResponseArgs = {
  input: ProcessUserAccountAuthenticationResponseInput;
};

export type MutationReadStoryArgs = {
  story_ids: Array<Scalars['ID']>;
};

export type MutationReceiveDailyMissionGameTicketArgs = {
  input: ReceiveDailyMissionGameTicketInput;
};

export type MutationReceiveMyPickCouponArgs = {
  input: ReceiveMyPickCouponInput;
};

export type MutationReceivePromotion202104SellerCouponArgs = {
  shop_id: Scalars['ID'];
};

export type MutationRecertificationAppleArgs = {
  input: RecertificationAppleInput;
};

export type MutationRecertificationFacebookArgs = {
  input: RecertificationSocialInput;
};

export type MutationRecertificationGoogleArgs = {
  input: RecertificationSocialInput;
};

export type MutationRecertificationKakaoArgs = {
  input: RecertificationSocialInput;
};

export type MutationRecertificationPasswordArgs = {
  input: RecertificationPasswordInput;
};

export type MutationRegisterRewardUserAccountChannelArgs = {
  input: RegisterRewardUserAccountChannelInput;
};

export type MutationRegisterSimpleBankArgs = {
  input: RegisterSimpleBankInput;
};

export type MutationRegisterSimplePayPasswordArgs = {
  input: RegisterSimplePayPasswordInput;
};

export type MutationRegisterWmsUserNotificationArgs = {
  input?: InputMaybe<WmsUserNotificationInput>;
};

export type MutationRegisterZibetLinkPriceOrderArgs = {
  input: RegisterZibetLinkPriceOrderInput;
};

export type MutationRemoveCouponFromStoryArgs = {
  coupon_id: Scalars['ID'];
  story_id: Scalars['ID'];
};

export type MutationRemoveProductFromStoryArgs = {
  product_id: Scalars['String'];
  story_id: Scalars['ID'];
};

export type MutationRemoveSaveStylingArgs = {
  styling_id_list: Array<Scalars['ID']>;
};

export type MutationRemoveUmdSavedProductArgs = {
  product_info?: InputMaybe<UmdProductInfoInput>;
};

export type MutationRemoveUmdSavedProductFolderArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveUmdSavedProductFolderByIdArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveUmdSavedShopArgs = {
  shop_id: Scalars['String'];
};

export type MutationRemoveUmdShopTagListArgs = {
  tag_list: Array<Scalars['String']>;
};

export type MutationRemoveUmdViewedProductsArgs = {
  id_list: Array<Scalars['String']>;
};

export type MutationRemoveWebUmdSavedProductArgs = {
  product_info?: InputMaybe<UmdProductInfoInput>;
};

export type MutationRenameUmdSavedProductFolderArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationRenewQuickProductReviewArgs = {
  input: RenewQuickProductReviewInput;
};

export type MutationReorderUmdSavedProductArgs = {
  position: UmdSavedProductPosition;
  product_info?: InputMaybe<UmdProductInfoInput>;
};

export type MutationReorderUmdSavedProductFolderArgs = {
  from_index: Scalars['Int'];
  to_index: Scalars['Int'];
};

export type MutationReorderUmdSavedShopArgs = {
  from_index: Scalars['Int'];
  to_index: Scalars['Int'];
};

export type MutationReportEPickPostArgs = {
  category?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  post_id: Scalars['ID'];
};

export type MutationReportEPickReplyArgs = {
  category?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  reply_id: Scalars['ID'];
};

export type MutationReportEventContentReplyArgs = {
  input: ReportEventContentReplyInput;
};

export type MutationReportExternalProductReviewAbuseArgs = {
  input: ReportExternalProductReviewAbuseInput;
};

export type MutationReportProductReviewAbuseArgs = {
  input: ReportProductReviewAbuseInput;
};

export type MutationReportProductReviewUserReplyArgs = {
  input: ReportProductReviewUserReplyInput;
};

export type MutationReportStoryArgs = {
  report_type: StoryReportType;
  story_id: Scalars['ID'];
};

export type MutationReportTalkLoungeReplyArgs = {
  input: ReportTalkLoungeReplyInput;
};

export type MutationReportTalkLoungeTalkArgs = {
  input: ReportTalkLoungeTalkInput;
};

export type MutationRequestAdProductExposureArgs = {
  input: RequestAdProductExposureInput;
};

export type MutationRequestAdProductNonExposureArgs = {
  input: RequestAdProductNonExposureInput;
};

export type MutationRequestAuthenticationTokenForEventArgs = {
  input: RequestAuthenticationTokenForEventInput;
};

export type MutationRequestEmailAuthenticationArgs = {
  input: RequestEmailAuthenticationInput;
};

export type MutationRequestExchangeOrderItemListArgs = {
  input: RequestExchangeOrderItemListInput;
};

export type MutationRequestForResetPasswordWithAuthenticationArgs = {
  input: RequestForResetPasswordWithAuthenticationInput;
};

export type MutationRequestForResetPasswordWithEmailAuthenticationArgs = {
  input: RequestForResetPasswordWithEmailAuthenticationInput;
};

export type MutationRequestForResetPasswordWithMobileAuthenticationArgs = {
  input: RequestForResetPasswordWithMobileAuthenticationInput;
};

export type MutationRequestKakaoStudentPresentProofArgs = {
  input: RequestKakaoStudentPresentProofInput;
};

export type MutationRequestKakaobankAuthenticationArgs = {
  input: RequestKakaobankAuthenticationInput;
};

export type MutationRequestMobileAuthenticationArgs = {
  input: RequestMobileAuthenticationInput;
};

export type MutationRequestReturnOrderArgs = {
  input: RequestReturnOrderInput;
};

export type MutationRequestSimpleBankCertArgs = {
  input: RequestSimpleBankCertInput;
};

export type MutationRequestUserAccountAuthenticationArgs = {
  input?: InputMaybe<RequestUserAccountAuthenticationInput>;
};

export type MutationRequestUserAccountPasswordResetArgs = {
  input: RequestUserAccountPasswordResetInput;
};

export type MutationRequestUserPayArgs = {
  input: RequestUserPayInput;
};

export type MutationResetEPickProfileArgs = {
  user_account_id: Scalars['ID'];
};

export type MutationResetPasswordWithEmailArgs = {
  input: ResetPasswordWithEmailInput;
};

export type MutationResetSimplePayPasswordArgs = {
  input: ResetSimplePayPasswordInput;
};

export type MutationResetUserAccountPasswordArgs = {
  input: ResetUserAccountPasswordInput;
};

export type MutationRewardInviteeArgs = {
  input: RewardInviteeInput;
};

export type MutationRewardInviterArgs = {
  input: RewardInviterInput;
};

export type MutationSaveProductFunnelInfoListArgs = {
  input: SaveProductFunnelInfoListInput;
};

export type MutationSaveStylingArgs = {
  styling_id: Scalars['ID'];
};

export type MutationSaveUmdProductArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
  product_ids: UmdProductIdentifiersInput;
};

export type MutationSaveUmdShopArgs = {
  shop_id: Scalars['String'];
};

export type MutationSaveUmdShopListArgs = {
  shop_id_list: Array<Scalars['String']>;
};

export type MutationSaveUmdViewedProductsArgs = {
  product_id: Scalars['String'];
};

export type MutationSendAppWebViewLogArgs = {
  input: AppWebViewLogInput;
};

export type MutationSendMobileAuthArgs = {
  input: SendMobileAuthInput;
};

export type MutationSendMobileAuthenticationTokenArgs = {
  input: SendMobileAuthenticationTokenInput;
};

export type MutationSetUserAccountBodyArgs = {
  input: SetUserAccountBodyInput;
};

export type MutationSetUserAccountEmailReceptionAgreedArgs = {
  input: SetUserAccountEmailReceptionAgreedInput;
};

export type MutationSetUserAccountNightlyAdNotiAgreedArgs = {
  input: SetUserAccountNightlyAdNotiAgreedInput;
};

export type MutationSetUserAccountOfferWallAgreedArgs = {
  input: SetUserAccountOfferWallAgreedInput;
};

export type MutationSetUserAccountShopNotificationPushAgreedArgs = {
  input: SetUserAccountShopNotificationPushAgreedInput;
};

export type MutationSetUserAccountShopSubscribedArgs = {
  input: SetUserAccountShopSubscribedInput;
};

export type MutationSetUserAccountSmsReceptionAgreedArgs = {
  input: SetUserAccountSmsReceptionAgreedInput;
};

export type MutationSetUserAccountThirdProvideAgreedArgs = {
  input: SetUserAccountThirdProvideAgreedInput;
};

export type MutationSetUserAttributeArgs = {
  input: SetUserAttributeInput;
};

export type MutationSetUserNotificationPushAgreedArgs = {
  input: SetUserNotificationPushAgreedInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationSignupAppleArgs = {
  input: SignupAppleInput;
};

export type MutationSignupFacebookArgs = {
  input: SignupFacebookInput;
};

export type MutationSignupGoogleArgs = {
  input: SignupGoogleInput;
};

export type MutationSignupKakaoArgs = {
  input: SignupKakaoInput;
};

export type MutationStory_Service_ReactionArgs = {
  input: UxStoryReactionInput;
};

export type MutationStory_Service_ViewArgs = {
  input: UxStoryViewInput;
};

export type MutationSubscribeFcmRegistrationTokenToTopicArgs = {
  input: SubscribeFcmRegistrationTokenToTopicInput;
};

export type MutationSubscribeTalkLoungeKeywordArgs = {
  input: SubscribeTalkLoungeKeywordInput;
};

export type MutationToggleProductReviewDislikeArgs = {
  input: ToggleProductReviewDislikeInput;
};

export type MutationToggleProductReviewLikeArgs = {
  input: ToggleProductReviewLikeInput;
};

export type MutationUnsubscribeTalkLoungeKeywordArgs = {
  input: UnsubscribeTalkLoungeKeywordInput;
};

export type MutationUpdateAppsFlyerConversionDataArgs = {
  input: UpdateAppsFlyerConversionDataInput;
};

export type MutationUpdateDailyMissionUserDocumentArgs = {
  input: UpdateDailyMissionUserDocumentInput;
};

export type MutationUpdateEPickFollowArgs = {
  input: UpdateFollowInput;
};

export type MutationUpdateEPickLikeArgs = {
  enabled: Scalars['Boolean'];
  post_id: Scalars['ID'];
};

export type MutationUpdateEPickPostArgs = {
  input: UpdateEPickPostInput;
};

export type MutationUpdateEPickPostStatusForBackofficeArgs = {
  enabled: Scalars['Boolean'];
  post_id: Scalars['ID'];
};

export type MutationUpdateEPickPostV2Args = {
  input: UpdateEPickPostInputV2;
};

export type MutationUpdateEPickProfileArgs = {
  input: UpdateEPickProfileInput;
};

export type MutationUpdateEPickProfileImageUrlArgs = {
  input?: InputMaybe<UpdateProfileImageUrlInput>;
};

export type MutationUpdateEPickProfileStatusArgs = {
  profile_id?: InputMaybe<Scalars['ID']>;
  status: EPickProfileStatus;
  user_account_id?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateEventContentReplyArgs = {
  input: UpdateEventContentReplyInput;
};

export type MutationUpdateEventMetadataNotificationArgs = {
  input: UpdateEventMetadataNotificationInput;
};

export type MutationUpdateFcmRegistrationTokenArgs = {
  input: UpdateFcmRegistrationTokenInput;
};

export type MutationUpdateLastItemsDateArgs = {
  is_visit?: InputMaybe<Scalars['Boolean']>;
  shop_id: Scalars['String'];
};

export type MutationUpdateOrderReceiverV2Args = {
  input: UpdateOrderReceiverV2Input;
};

export type MutationUpdateOrderShippingMemoArgs = {
  order_number: Scalars['String'];
  shipping_memo: Scalars['String'];
};

export type MutationUpdateProductReviewArgs = {
  input: UpdateProductReviewInput;
};

export type MutationUpdateProductReviewProfileArgs = {
  input: UpdateProductReviewProfileInput;
};

export type MutationUpdateProductReviewUserReplyArgs = {
  input: UpdateProductReviewUserReplyInput;
};

export type MutationUpdateQuickProductReviewArgs = {
  input: UpdateQuickProductReviewInput;
};

export type MutationUpdateReceiverExchangeRequestInfoArgs = {
  input: UpdateReceiverExchangeRequestInfoInput;
};

export type MutationUpdateRecommendTagArgs = {
  enabled: Scalars['Boolean'];
  tag_id: Scalars['ID'];
};

export type MutationUpdateRewardUserBankAccountArgs = {
  input: UpdateRewardUserBankAccountInput;
};

export type MutationUpdateRewardUserSettlementDocumentArgs = {
  input: UpdateRewardUserSettlementDocumentInput;
};

export type MutationUpdateStoryArgs = {
  input: UpdateStoryInput;
};

export type MutationUpdateUmdShopTagListArgs = {
  shop_id: Scalars['String'];
  tag_list: Array<Scalars['String']>;
};

export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
};

export type MutationUpdateUserAccountPasswordArgs = {
  input: UpdateUserAccountPasswordInput;
};

export type MutationUpdateUserAccountProfileArgs = {
  input: UpdateUserAccountProfileInput;
};

export type MutationUpdateUserAccountProfileImageArgs = {
  input: UpdateUserAccountProfileImageInput;
};

export type MutationUpdateUserAdNotiStatusArgs = {
  input: UpdateUserAdNotiStatusInput;
};

export type MutationUpdateUserAppNotiStatusArgs = {
  input: UpdateUserAppNotiStatusInput;
};

export type MutationUpdateUserLocaleArgs = {
  input: UpdateUserLocaleInput;
};

export type MutationUpdateUserSaleNotiStatusArgs = {
  input: UpdateUserSaleNotiStatusInput;
};

export type MutationUpdateUserShippingAddressBookArgs = {
  input: UpdateUserShippingAddressBookInput;
};

export type MutationUploadBrandFashionOnboardingUserArgs = {
  input?: InputMaybe<BrandFashionOnboardingInput>;
};

export type MutationUploadDdpOnboardingStyleArgs = {
  style: Array<Scalars['String']>;
};

export type MutationUpsertDailyMissionUserDocumentArgs = {
  input: UpsertDailyMissionUserDocumentInput;
};

export type MutationUpsertDailyMissionWeatherNotificationScheduleArgs = {
  input: UpsertDailyMissionWeatherNotificationScheduleInput;
};

export type MutationUpsertPersonalClearanceCodeArgs = {
  input: UpsertPersonalClearanceCodeInput;
};

export type MutationUseShopEventCouponCodeArgs = {
  input: UseShopEventCouponCodeInput;
};

export type MutationUserRequestCancelAndRefundOrderItemListArgs = {
  input: UserRequestCancelAndRefundOrderItemListInput;
};

export type MutationUxSavedProductArgs = {
  input: SavedUxProductInput;
};

export type MutationVerifyEmailAuthenticationArgs = {
  input: VerifyEmailAuthenticationInput;
};

export type MutationVerifyMobileAuthenticationTokenArgs = {
  input: VerifyMobileAuthenticationTokenInput;
};

export type MutationVerifyPasswordInLoginMobileArgs = {
  input: VerifyPasswordInLoginMobileInput;
};

export type MutationVerifyRecertificationArgs = {
  input: VerifyRecertificationInput;
};

export type MutationVerifySimplePayPasswordArgs = {
  input: VerifySimplePayPasswordInput;
};

export type MutationVisitStyleTabArgs = {
  shop_id: Scalars['ID'];
};

export type MutationWeb_SaveUmdProductArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
  product_ids: UmdProductIdentifiersInput;
};

export type MutationWithdrawOrderItemRequestArgs = {
  input: WithdrawOrderItemRequestInput;
};

export type MutationWithdrawOrderItemRequestListArgs = {
  input: WithdrawOrderItemRequestListInput;
};

export type MyCounponItem = {
  color: MyCouponItemColor;
  coupon: BenefitCoupon;
  store: BenefitShop;
};

export type MyCouponItemColor = {
  bg: Scalars['String'];
  dash: Scalars['String'];
  text: Scalars['String'];
};

export type MyCouponSectionData = {
  coupons: Array<MyCounponItem>;
};

export type MyCouponSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

/** 마이페이지 추가 메뉴 섹션 */
export type MyPageExtraMenu = {
  /** 해당 섹션의 메뉴 리스트 */
  items: Array<MyPageMenuItem>;
  /** 섹션 타이틀 */
  section_title: Scalars['String'];
};

export type MyPageMembership = {
  /** 이번달 등급 산정 여부(true: 이달 레벨 산정중, false: 산정 완료) */
  calculation_level: Scalars['Boolean'];
  /** 혜택의 존재 여부(true: 혜택 존재, false: 혜택 없음) */
  exists_benefit: Scalars['Boolean'];
  /** 현재 등급의 code */
  level_code: Scalars['String'];
  /** 등급 유지 및 달성에 필요한 안내 문구 */
  level_guidance_text?: Maybe<UxCommonText>;
  /** 현재 등급의 아이콘 url */
  level_icon_url: Scalars['String'];
  /** 현재 등급에 따른 마일리지 적립률 안내 문구 */
  mileage_guidance_text?: Maybe<UxCommonText>;
  /** 이달 혜택 수령 여부 */
  received_benefit: Scalars['Boolean'];
};

/** 마이페이지 추가 메뉴 */
export type MyPageMenuItem = {
  is_new?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  ubl?: Maybe<UxUbl>;
  url: Scalars['String'];
};

export type MyPageMileage = {
  /** 로그인 유저의 사용 가능한 마일리지 */
  available_mileage: Scalars['Int'];
};

/** 내가 참여한 이벤트 메타데이터 Result */
export type MyParticipateEventMetadataResult = {
  is_participate: Scalars['Boolean'];
  reward: Array<EventMetadataReward>;
};

/** 마이픽쿠폰 신청 데이터 */
export type MyPickCouponApplication = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 승인된 마이픽쿠폰 Set 정보 */
  my_pick_coupon_set?: Maybe<MyPickCouponSet>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 신청한 스토어 타입 */
  shop_type: MyPickCouponShopType;
  /** 신청 상태 */
  status: MyPickCouponApplicationStatus;
};

/** 마이픽쿠폰 약관 타입 */
export enum MyPickCouponApplicationStatus {
  CANCELED = 'CANCELED',
  DISPLAYING = 'DISPLAYING',
  ENDED = 'ENDED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_DISPLAY = 'PENDING_DISPLAY',
  REJECTED = 'REJECTED',
}

export enum MyPickCouponDiscountAmountDisplayType {
  DISCOUNT_RATE_BP = 'DISCOUNT_RATE_BP',
  MAX_DISCOUNT_AMOUNT = 'MAX_DISCOUNT_AMOUNT',
}

/** 마이픽쿠폰 진입점 */
export enum MyPickCouponEntryType {
  FLOATING_BUTTON = 'FLOATING_BUTTON',
  MY_PAGE = 'MY_PAGE',
  WEB = 'WEB',
}

export type MyPickCouponListSectionData = {
  /** 전체 보기 URL */
  deeplink_url: Scalars['String'];
  /** 마이픽 쿠폰 발급 정보 */
  issued_coupon_info?: Maybe<BenefitMyPickCouponListIssuedInfo>;
  /** shop 정보 목록 4개 */
  shop_list: Array<BenefitBannerMyPickCouponItem>;
  /** 혜택 섹션 타입 */
  type: BenefitSectionType;
};

export type MyPickCouponListSectionProps = {
  /** 앱 노출 타이틀 ko */
  title: Scalars['String'];
};

/** 마이픽쿠폰 Set (매 회차 진행될 마이픽쿠폰의 정보) */
export type MyPickCouponSet = {
  /** 생성 일시 */
  date_created: Scalars['CrTimestamp'];
  /** 노출 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 노출 시작일 */
  date_start: Scalars['CrTimestamp'];
  /** 업데이트 일시 */
  date_updated: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 마이픽쿠폰 Set에 포함된 혜택 목록 */
  set_item_list?: Maybe<Array<MyPickCouponSetItem>>;
  /** 상태 */
  status: MyPickCouponSetStatus;
  /** Set 제목 */
  title: Scalars['String'];
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 마이픽쿠폰 Set에 포함된 혜택 정보 */
export type MyPickCouponSetItem = {
  /** 신청 정보 */
  application?: Maybe<MyPickCouponApplication>;
  /** 신청 ID */
  application_id: Scalars['ID'];
  /** 쿠폰팩 ID */
  coupon_pack_id?: Maybe<Scalars['ID']>;
  /** 생성된 마이픽 쿠폰팩 정책 목록 */
  coupon_policy_list?: Maybe<Array<UserAccountCouponPolicy>>;
  /** 할인 금액 표시 타입 */
  discount_amount_display_type: MyPickCouponDiscountAmountDisplayType;
  /** 노출 우선 순위 */
  display_priority: Scalars['Int'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** Set ID */
  my_pick_coupon_set_id: Scalars['ID'];
  /** 스토어홈 배너 이미지 주소 */
  shop_banner_image_url?: Maybe<Scalars['String']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 스토어 정보 */
  shop_info: CouponShopInfo;
  /** 스토어 이번주 배스트 상품 ID, 데이터 API로부터 올바른 응답을 받지 못할 경우 빈 배열로 내려갑니다. */
  store_best_product_id_list: Array<Scalars['ID']>;
  /** 유저가 신청한 스토어 여부 (개인화 요청에만 반환됩니다, 다른 요청일 경우 null) */
  user_requested?: Maybe<Scalars['Boolean']>;
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 마이픽쿠폰 Set 상태 */
export enum MyPickCouponSetStatus {
  ENDED = 'ENDED',
  PENDING = 'PENDING',
  PROGRESSIBLE = 'PROGRESSIBLE',
  PROGRESSING = 'PROGRESSING',
}

/** 마이픽쿠폰 신청 스토어 타입 */
export enum MyPickCouponShopType {
  BEAUTY = 'BEAUTY',
  BRAND = 'BRAND',
  CELEB = 'CELEB',
  LIFE = 'LIFE',
  SOHO = 'SOHO',
}

/** 유저의 마이픽쿠폰 요청 스토어 데이터 */
export type MyPickCouponUserRequest = {
  /** 생성 일시 */
  date_created: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** Soft Delete 여부 */
  is_deleted: Scalars['Boolean'];
  /** 스토어의 최근 마이픽쿠폰 신청 */
  shop_application?: Maybe<MyPickCouponApplication>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 유저 레코드 ID */
  user_account_id: Scalars['ID'];
};

export type MyPickCouponUserRequestList = {
  /** 유저의 신청 데이터 리스트 */
  item_list: Array<MyPickCouponUserRequest>;
  /** 유저의 총 신청 개수 */
  total_count: Scalars['Int'];
};

/** 작성한 상품 리뷰 목록 정보 */
export type MyProductReviewList = {
  after_key?: Maybe<Scalars['ID']>;
  item_list: Array<ProductReview>;
  total_count: Scalars['Int'];
};

export type NearbyMinRequiredAmountCouponInfo = {
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

export type NearbyMinRequiredAmountCouponInfoInput = {
  /** 체크할 대상 상품 정보 목록 */
  product_info_list: Array<NearbyProductInfo>;
};

export type NearbyProductInfo = {
  /** 상품 판매 금액 */
  amount: Scalars['Int'];
  /** 조회할 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 개수 */
  quantity: Scalars['Int'];
};

/** 현재 이벤트와 연결되어 있는 다음 이벤트에 대한 정보 */
export type NextEventInfo = {
  /** 이벤트 타입 */
  event_type: EventType;
  /** 다음 이벤트 참여 가능 상태 */
  status: Scalars['Boolean'];
  /** 다음 이벤트 참여 가능 상태 코드 */
  status_code: Scalars['String'];
  /** 서브 이벤트 타입, 단일 이벤트일 경우 null */
  sub_event_type?: Maybe<Scalars['String']>;
};

export type OnboardingBenefitInfo = {
  coupon_info?: Maybe<OnboardingCouponInfo>;
  mileage_info?: Maybe<Scalars['String']>;
};

export type OnboardingBirthYearInfo = {
  benefit_info?: Maybe<OnboardingBenefitInfo>;
  benefit_messages: Array<Maybe<OnboardingMessageInfo>>;
  birth_year?: Maybe<Scalars['String']>;
  button_message: UxCommonText;
  main_message: UxCommonText;
};

export type OnboardingCouponInfo = {
  count: Scalars['String'];
  percent: Scalars['String'];
};

export type OnboardingInfo = {
  birth_year?: Maybe<Scalars['String']>;
  point_info?: Maybe<OnboardingPointInfo>;
};

export type OnboardingMessageInfo = {
  icon: UxCommonImageUrl;
  message: UxCommonText;
};

export type OnboardingPointInfo = {
  message?: Maybe<UxCommonText>;
  point?: Maybe<Scalars['String']>;
};

export type OnboardingProduct = {
  image_url: Scalars['String'];
  product_id: Scalars['ID'];
  related_item_list?: Maybe<Array<OnboardingProduct>>;
  webp_image_url?: Maybe<Scalars['String']>;
};

export type OnboardingProductList = {
  category_id?: Maybe<Scalars['ID']>;
  category_name?: Maybe<Scalars['String']>;
  item_list: Array<OnboardingProduct>;
};

export type OnboardingResult = {
  card_item_list: Array<OnboardingStylingCardItem>;
  description?: Maybe<Scalars['String']>;
  point_info?: Maybe<OnboardingPointInfo>;
  shop_list: Array<OnboardingShop>;
  title?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type OnboardingShop = {
  bookmark_count?: Maybe<Scalars['String']>;
  goods_image_list: Array<StyleGoodsImage>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
  style?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type OnboardingShopList = {
  item_list: Array<OnboardingShop>;
};

export type OnboardingStyleInput = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
  is_recommended?: InputMaybe<Scalars['Boolean']>;
  selected_id?: InputMaybe<Scalars['ID']>;
  step?: InputMaybe<Scalars['Int']>;
};

export type OnboardingStylingCardGroup = {
  current_step?: Maybe<Scalars['Int']>;
  item_list: Array<OnboardingStylingCardItem>;
  point_info?: Maybe<OnboardingPointInfo>;
  step_total_count?: Maybe<Scalars['Int']>;
};

export type OnboardingStylingCardItem = {
  id: Scalars['ID'];
  shop_id?: Maybe<Scalars['ID']>;
  thumbnail_url: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type OnboardingUser = {
  birth_year?: Maybe<Scalars['Int']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  product_id_list?: Maybe<Array<Scalars['String']>>;
  shop_id_list?: Maybe<Array<Scalars['String']>>;
  style?: Maybe<Scalars['String']>;
  style_tag_id_list?: Maybe<Array<Scalars['String']>>;
  user_id: Scalars['String'];
  uuid: Scalars['String'];
};

export type OneDayDeliveryBanner = {
  background_color?: Maybe<UxCommonColor>;
  common_title: UxCommonText;
  remain_time?: Maybe<Scalars['CrTimestamp']>;
};

export type OpenDailyMissionRandomBoxResult = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export type OptionMatchingValidation = {
  /** 옵션 매칭 성공/실패 상세 정보 */
  detail: OptionMatchingValidationList;
  /** 옵션 매칭 성공 여부 */
  result: Scalars['Boolean'];
};

export type OptionMatchingValidationItem = {
  /** 검증 대상(ex. FROM 상품의 penalty_status != NONE 여부) */
  key: Scalars['String'];
  /** 검증 결과(ex. false, 실패, 통과, pass 등) */
  value?: Maybe<Scalars['String']>;
};

export type OptionMatchingValidationList = {
  /** 검증 대상, 검증 결과 목록 */
  item_list: Array<OptionMatchingValidationItem>;
  total_count: Scalars['Int'];
};

/** 주문서 */
export type Order = {
  /** 유저의 구매내역 비노출 설정 가능 여부 */
  available_to_user_hidden: Scalars['Boolean'];
  /** Checkout 정보 (Checkout 만료시 삭제되므로 nullable) */
  checkout?: Maybe<CheckoutInOrder>;
  /** 국가 정보 code */
  country: CountryType;
  /** 통화 정보 code */
  currency: CurrencyType;
  /** 주문 시점의 환율 정보 */
  currency_meta: OrderCurrencyMeta;
  /** 주문서 생성일자 */
  date_created: Scalars['CrTimestamp'];
  /** 카드사 즉시할인 금액 */
  discount_payment_amount: Scalars['Int'];
  /** 국내 배송비(초기 결제값) */
  domestic_shipping_fee?: Maybe<Scalars['Int']>;
  /** 결제 시 환율 정보 */
  exchange_rate: Scalars['String'];
  /** 소멸된 마일리지 금액 */
  expired_mileage_amount: Scalars['Int'];
  /** 소멸된 포인트 금액 */
  expired_point_amount: Scalars['Int'];
  /** 해외주문 배송 예정일자 */
  global_estimated_shipping_date: GlobalEstimatedShippingDate;
  /** Record ID */
  id: Scalars['ID'];
  /** 해외 배송비 정보 목록(주문, 전체취소) */
  international_order_shipping_fee_list: Array<InternationalOrderShippingFee>;
  /** 해외 배송비(초기 결제값) */
  international_shipping_fee?: Maybe<Scalars['Int']>;
  /** 언어 코드 */
  language: LanguageType;
  /** 국기 표시용 URL */
  national_flag_url: Scalars['String'];
  /** 주문 할인 정보 */
  order_discount_list: Array<OrderDiscount>;
  /** 주문 내 상품주문 목록 */
  order_item_list: Array<OrderItem>;
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 주문 프로모션 적용 정보 목록 */
  order_promotion_list: Array<OrderPromotion>;
  /** 수령 정보 */
  order_receiver?: Maybe<OrderReceiver>;
  /** 주문자 정보 */
  orderer: Orderer;
  /** 결제 정보 */
  pay_info?: Maybe<Scalars['CrJson']>;
  /** 결제 수단 */
  payment_method: PaymentMethod;
  /** 결제 상태 */
  payment_status: PaymentStatus;
  /** 사용 포인트 */
  points_used: Scalars['Int'];
  /** 환불 계좌 필요 여부 */
  refund_bank_account_required: Scalars['Boolean'];
  /** 남은 유상(실결제) 금액 */
  remaining_cash_amount: Scalars['Int'];
  /** 남은 마일리지 금액 */
  remaining_mileage_amount: Scalars['Int'];
  /** 남은 포인트 금액 */
  remaining_point_amount: Scalars['Int'];
  /** 배송 메모(deprecated - OrderReceiver.memo 로 대체) */
  shipping_memo?: Maybe<Scalars['String']>;
  /** 상품 할인 금액 */
  total_discount_item_amount: Scalars['Int'];
  /** 배송비 할인 금액 */
  total_discount_shipping_fee: Scalars['Int'];
  /** 최종 실결제 금액 */
  total_final_payment_amount: Scalars['Int'];
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 사용 마일리지 */
  total_mileage_amount: Scalars['Int'];
  /** 실결제 금액 */
  total_payment_amount: Scalars['Int'];
  /** 총 배송비 */
  total_shipping_fee: Scalars['Int'];
  /** 사용자 연령대 정보 */
  user_age_group?: Maybe<UserAgeGroup>;
  /** 가상계좌 입금 정보 */
  vbank_pay_info?: Maybe<VbankPayInfo>;
  /** 주문 버젼 (V2 : 1/n 로직 적용 이후) */
  version: OrderVersionType;
};

/** 주문 관련 계정 정보 */
export type OrderAccountInfo = {
  /** 계정 분류 */
  account_type?: Maybe<OrderAccountType>;
};

export enum OrderAccountType {
  CONSIGNMENT = 'CONSIGNMENT',
  CROQUIS = 'CROQUIS',
  CS = 'CS',
  OPENAPI = 'OPENAPI',
  SELLER = 'SELLER',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
}

/** 주문 첨부파일 */
export type OrderAttachment = {
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 첨부파일 원본 URL */
  original_url: Scalars['String'];
  /** 첨부파일 썸네일 URL */
  thumbnail_url: Scalars['String'];
  /** 상품주문 요청 첨부파일 유형 */
  type: OrderAttachmentType;
};

/** 문의 첨부파일 유형 */
export enum OrderAttachmentType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
}

/** 카테고리 */
export type OrderCatalogCategory = {
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 카테고리 positon 정보 */
  position: CatalogCategoryPositionType;
};

/** 카테고리 */
export type OrderCatalogProductCategory = {
  /** 카테고리 full name */
  category_full_name: Scalars['String'];
  /** category DB의 category id */
  category_id: Scalars['ID'];
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 카테고리 계층 - ROOT_NODE, INTER_NODE, LEAF_NODE 순서 */
  hierarchy_list: Array<OrderCatalogCategory>;
  /** id */
  id: Scalars['ID'];
};

export enum OrderCountryType {
  CA = 'CA',
  JP = 'JP',
  KR = 'KR',
  UNKNOWN = 'UNKNOWN',
  US = 'US',
}

/** 실시간 통화 정보 */
export type OrderCurrencyMeta = {
  /** 원화에 곱하는 값 (원화 x converted_rate = display 통화) */
  converted_rate: Scalars['Float'];
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: CurrencyType;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
};

/** 직진배송 타입 */
export enum OrderDeliveryType {
  ONE_DAY = 'ONE_DAY',
  TODAY = 'TODAY',
  ZONLY = 'ZONLY',
}

/** 쿠폰 할인정보 */
export type OrderDiscount = {
  /** 분담 타입 */
  allotment_type?: Maybe<UserAccountCouponAllotmentType>;
  /** 지그재그 분담률 */
  coupon_target_type: CouponTargetType;
  /** 쿠폰사용상태 */
  coupon_use_status?: Maybe<CouponUseStatus>;
  /** 할인정보 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 총 할인금액 */
  discount_amount: Scalars['Int'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** order.id */
  order_id: Scalars['ID'];
  /** order_item id 목록 */
  order_item_id_list: Array<Scalars['ID']>;
  /** 적용 순서 */
  priority: Scalars['Int'];
  /** 남은 할인금액 */
  remaining_discount_amount: Scalars['Int'];
  /** 셀러 분담금액 */
  seller_alloted_amount: Scalars['Int'];
  /** 셀러 분담률 */
  seller_rate_bp?: Maybe<Scalars['Int']>;
  /** ShippingGroup.id(배송비 그룹 ID) */
  shipping_group_id?: Maybe<Scalars['ID']>;
  /** ShippingGroup.id(배송비 그룹 ID) 목록 */
  shipping_group_id_list: Array<Scalars['ID']>;
  /** shop_id(셀러쿠폰 전용) */
  shop_id?: Maybe<Scalars['Int']>;
  /** 할인 대상 */
  target: OrderDiscountTarget;
  /** 쿠폰 id */
  user_account_coupon_id: Scalars['ID'];
  /** 사용된 쿠폰 정보 */
  user_account_coupon_info: UsedUserAccountCouponInfo;
  /** 지그재그 분담금액 */
  zigzag_alloted_amount: Scalars['Int'];
  /** 지그재그 분담률 */
  zigzag_rate_bp?: Maybe<Scalars['Int']>;
};

/** 쇼핑몰 주문 할인 차감 정보 */
export type OrderDiscountRevoked = {
  /** 차감후 남은 할인액 */
  after_discount_amount?: Maybe<Scalars['Int']>;
  /** 차감전 남아있는 할인액 */
  before_discount_amount?: Maybe<Scalars['Int']>;
  /** 할인 차감 정보 생성일 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 할인 정보 */
  order_discount: OrderDiscount;
  /** 귀책 구분 */
  refund_responsibility?: Maybe<OrderRefundResponsibility>;
  /** 차감된 할인액 */
  revoked_discount_amount: Scalars['Int'];
};

export enum OrderDiscountTarget {
  ITEM = 'Item',
  SHIPPING_FEE = 'ShippingFee',
}

/** 주문의 품목 아이템 */
export type OrderItem = {
  /** 적립 예정 포인트 */
  accumulative_point: Scalars['Int'];
  /** 활성화된 요청 */
  active_request?: Maybe<OrderItemRequest>;
  /** 요청 가능한 타입 목록 (취소/반품/교환) */
  available_request_type_list: Array<OrderItemRequestType>;
  /** 브랜드 정보 */
  brand?: Maybe<OrderItemBrand>;
  /** 브라우저 타입 */
  browsing_type: BrowsingType;
  /** 카탈로그 상품 레코드 ID */
  catalog_product_id: Scalars['ID'];
  /** 구매확정 가능 여부 */
  confirmable: Scalars['Boolean'];
  /** 국가 정보 code */
  country: CountryType;
  /** 구매 확정일 */
  date_confirmed?: Maybe<Scalars['CrTimestamp']>;
  /** 결제 완료일 */
  date_paid?: Maybe<Scalars['CrTimestamp']>;
  /** 환불된 날짜 */
  date_refunded?: Maybe<Scalars['CrTimestamp']>;
  /** 배송 완료일 */
  date_shipped?: Maybe<Scalars['CrTimestamp']>;
  /** 배송 기한 */
  due_date_in_transit?: Maybe<Scalars['CrTimestamp']>;
  /** 교환 후 상품 주문 */
  exchange_new_order_item?: Maybe<OrderItem>;
  /** 직전 교환 요청 상품주문 */
  exchange_previous_order_item?: Maybe<OrderItem>;
  /** 상품주문 ID */
  id: Scalars['ID'];
  /** 운송장 번호 */
  invoice_number?: Maybe<Scalars['String']>;
  /** 빠른 출발 여부 */
  is_fast_delivery: Scalars['Boolean'];
  /** OrderItem의 직잭메이트 판매샵 여부 */
  is_zigzag_mate: Scalars['Boolean'];
  /** OrderItem의 zonly 여부 */
  is_zonly: Scalars['Boolean'];
  /** 요청 타입 별 가장 마지막 요청이 철회된 이력이 있다면, 셀러에 의해 철회되었는지 여부 */
  last_request_number_withdrawn_by_seller_per_type: WithdrawnRequestType;
  /** 주문서 */
  order: Order;
  /** 상품 주문 날짜 추적 정보 */
  order_item_date_trace: OrderItemDateTrace;
  /** 주문 아이템 번호 */
  order_item_number: Scalars['String'];
  /** 상품주문 정보 */
  order_item_product: OrderItemProduct;
  /** 수령 정보 */
  order_item_receiver: OrderItemReceiver;
  /** 상품 프로모션 적용 정보 */
  order_promotion_item?: Maybe<OrderPromotionItem>;
  /** 해당 상품주문에 대한 1/N 결제 분배 금액 정보 */
  payment_amount?: Maybe<OrderItemPaymentAmount>;
  /** 상품상세 랜딩 url */
  pdp_landing_url: Scalars['String'];
  /** 프리오더 정보 */
  preorder?: Maybe<OrderItemProductPreorder>;
  /** html이 입혀진 프리오더 발송예정일 정보 텍스트 */
  preorder_html_date_shipping_info?: Maybe<Scalars['String']>;
  /** 카테고리 */
  product_category?: Maybe<OrderCatalogProductCategory>;
  /** 상품 레코드 ID */
  product_id: Scalars['ID'];
  /** 주문할 상품 정보(deprecated - OrderItemProduct 로 대체) */
  product_info: OrderedProductInfo;
  /** 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  product_review_create_info: ProductReviewCreateInfo;
  /** 수량 */
  quantity: Scalars['Int'];
  /** 수령자 정보(deprecated - OrderItemReceiver 로 대체) */
  receiver: Receiver;
  /** 상품주문의 최근 상태변경 보류 */
  recent_defer?: Maybe<OrderItemDefer>;
  /** 가장 최근의 취소/반품 요청 거부 정보 */
  recent_reject_request?: Maybe<OrderItemRequest>;
  /** 해당 상품주문에 대한 모든 요청 목록 (deactivated된 것 포함) */
  request_list: Array<OrderItemRequest>;
  /** 택배사 */
  shipping_company?: Maybe<Scalars['String']>;
  /** 배송비 그룹 정보 */
  shipping_group: ShippingGroup;
  /** 배송비 그룹 레코드 ID */
  shipping_group_id: Scalars['ID'];
  /** 배송 메모(deprecated - OrderItemReceiver.memo 로 대체) */
  shipping_memo?: Maybe<Scalars['String']>;
  /** 배송연기 정보 */
  shipping_schedule_delay_info: OrderItemScheduleDelayInfo;
  /** 배송 현황 */
  shipping_tracking_detail_history_list: OrderShippingTrackingDetailHistoryList;
  /** 배송추적 URL */
  shipping_tracking_url?: Maybe<Scalars['String']>;
  /** 쇼핑몰 정보 */
  shop: OrderItemShop;
  /** 쇼핑몰 아이디 */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name: Scalars['String'];
  /** 샵별 추가 금액 결제 계좌 정보 */
  shop_shipping_user_extra_payment?: Maybe<OrderShopShippingUserExtraPayment>;
  /** site ID */
  site_id: Scalars['ID'];
  /** 상태 */
  status: OrderItemStatus;
  /** 총 상품 결제금액(zpay, 프로모션 할인가 적용) */
  total_amount: Scalars['Int'];
  /** total_amount 다통화 금액 */
  total_amount_with_currency: OrderPriceWithCurrency;
  /** 총 상품 금액 */
  total_product_price: Scalars['Int'];
  /** OrderItem의 zonly 정보 */
  zonly_info?: Maybe<OrderItemZonlyInfo>;
};

/** 주문의 품목 아이템 */
export type OrderItemRequest_ListArgs = {
  exclude_user_withdrawn_request?: InputMaybe<Scalars['Boolean']>;
  order_item_request_number?: InputMaybe<Scalars['String']>;
};

/** 브랜드 */
export type OrderItemBrand = {
  /** 브랜드 ID */
  id: Scalars['ID'];
  /** 브랜드명 */
  name: Scalars['String'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
};

/** 상품 주문 날짜 추적 정보 */
export type OrderItemDateTrace = {
  /** 구매 확정 날짜 */
  date_confirmed?: Maybe<Scalars['CrTimestamp']>;
  /** 발송예정일 */
  date_expected_in_transit?: Maybe<Scalars['CrTimestamp']>;
  /** 결제 완료일 */
  date_paid?: Maybe<Scalars['CrTimestamp']>;
  /** 배송 완료일 */
  date_shipped?: Maybe<Scalars['CrTimestamp']>;
};

/** 상품주문 상태변경 보류 */
export type OrderItemDefer = {
  /** 보류 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 보류 철회 날짜 */
  date_withdrawn?: Maybe<Scalars['CrTimestamp']>;
  /** 보류 사유 */
  deferred_reason: Scalars['String'];
  /** 보류 철회 사유 */
  withdrawn_reason?: Maybe<Scalars['String']>;
};

/** 상품주문 상태변경 일정 연기 사유 카테고리 */
export enum OrderItemDelayReasonCategory {
  CONFIRMED_DEFECTIVE_PRODUCT = 'CONFIRMED_DEFECTIVE_PRODUCT',
  CONFIRMED_DIFFERENT_FROM_PRODUCT_INFO = 'CONFIRMED_DIFFERENT_FROM_PRODUCT_INFO',
  CONFIRMED_ETC = 'CONFIRMED_ETC',
  CONFIRMED_NOT_SHIPPED = 'CONFIRMED_NOT_SHIPPED',
  CONFIRMED_SHIPPED_WRONG_OR_LATE = 'CONFIRMED_SHIPPED_WRONG_OR_LATE',
  IN_TRANSIT_AWAITING_PRODUCT = 'IN_TRANSIT_AWAITING_PRODUCT',
  IN_TRANSIT_CUSTOMER_REQUEST = 'IN_TRANSIT_CUSTOMER_REQUEST',
  IN_TRANSIT_CUSTOM_ORDER = 'IN_TRANSIT_CUSTOM_ORDER',
  IN_TRANSIT_ETC = 'IN_TRANSIT_ETC',
  IN_TRANSIT_RESERVE_SHIPMENT = 'IN_TRANSIT_RESERVE_SHIPMENT',
  IN_TRANSIT_UPDATE_EXPECTED_IN_TRANSIT = 'IN_TRANSIT_UPDATE_EXPECTED_IN_TRANSIT',
}

/** 주문 문의 */
export type OrderItemInquiry = {
  /** 상품주문문의 첨부파일 목록 */
  attachment_list: Array<UserAccountInquiryAttachment>;
  /** 문의 내용 */
  content: Scalars['String'];
  /** 생성 날짜 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 답변 날짜 */
  date_replied?: Maybe<Scalars['CrTimestamp']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 주문 아이템 */
  order_item: OrderItem;
  /** 주문 아이템 번호 */
  order_item_number: Scalars['String'];
  /** 답변 목록 */
  reply_list: Array<OrderItemInquiryReply>;
  /** 쇼핑몰 정보 */
  shop: Shop;
  /** 쇼핑몰 레코드 ID */
  shop_id?: Maybe<Scalars['ID']>;
  /** 문의 타입 */
  type: OrderItemInquiryType;
};

/** 이미지 업로드 시 S3 버킷의 Pre-Signed-Url을 발행하여 반환한다. */
export type OrderItemInquiryImageUploadLinkInfo = {
  /** S3 ACL */
  acl: Scalars['String'];
  /** S3 signed policy and form data */
  fields: Scalars['CrJson'];
  /** S3 Key */
  key: Scalars['String'];
  /** presigned url */
  presigned_url: Scalars['String'];
};

/** 주문 상품 문의 내역 */
export type OrderItemInquiryList = {
  /** 주문 상품 문의 내역 */
  item_list: Array<OrderItemInquiry>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 주문 문의 답변 */
export type OrderItemInquiryReply = {
  /** 계정 타입 */
  account_type: OrderItemInquiryReplyAccountType;
  /** 내용 */
  content: Scalars['String'];
  /** 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 답변 확인 날짜 */
  date_read?: Maybe<Scalars['CrTimestamp']>;
};

/** 계정 분류 (회원/판매자/CS) */
export enum OrderItemInquiryReplyAccountType {
  CS = 'CS',
  SELLER = 'SELLER',
  USER = 'USER',
}

/** 주문 상품 문의 분류 (상품/배송/취소/환불/교환/기타) */
export enum OrderItemInquiryType {
  CANCEL = 'CANCEL',
  ETC = 'ETC',
  EXCHANGE = 'EXCHANGE',
  PRODUCT = 'PRODUCT',
  RETURN = 'RETURN',
  SHIPMENT = 'SHIPMENT',
}

/** 주문의 품목 아이템 목록 및 요약 데이터 */
export type OrderItemList = {
  /** 주문의 품목 아이템 목록 */
  item_list: Array<OrderItem>;
};

/** 상품 주문 분배 금액 */
export type OrderItemPaymentAmount = {
  /** 쿠폰 결제 분배 금액 */
  coupon_discount_amount: Scalars['Int'];
  /** 현금 결제 분배 금액 */
  paid_cash_amount: Scalars['Int'];
  /** 마일리지 결제 분배 금액 */
  paid_mileage_amount: Scalars['Int'];
  /** 포인트 결제 분배 금액 */
  paid_point_amount: Scalars['Int'];
};

/** 상품주문 정보 */
export type OrderItemProduct = {
  /** 예약발송일 */
  date_reserved_shipping?: Maybe<Scalars['CrTimestamp']>;
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** 상품 이름(한국어) */
  kr_name: Scalars['String'];
  /**
   *   선택된 옵션값 / 로 구분(한국어)
   * - 세트상품 상품주문의 경우, 선택된 구성상품의 옵션값들이 저장된다.
   * - 세트상품 예시) [{name: '구성상품1 이름', value: '블랙/프리'}, {name: '구성상품2 이름', value: '블랙/S'}] => '블랙/프리/블랙/S'
   */
  kr_options: Scalars['String'];
  /** 상품 이름(글로벌) */
  name: Scalars['String'];
  /**
   *   선택된 옵션 상세 정보 목록
   * - 세트상품 상품주문의 경우, 구성상품명과 선택된 구성상품의 옵션들이 저장된다.
   * - 세트상품 예시) [{name: '구성상품1 이름', value: '블랙/프리'}, {name: '구성상품2 이름', value: '블랙/S'}]
   */
  option_detail_list?: Maybe<Array<ProductOptionDetail>>;
  /** 상품 옵션 타입 */
  option_type?: Maybe<OrderItemProductOptionType>;
  /**
   *   선택된 옵션값 / 로 구분(글로벌)
   * - 세트상품 상품주문의 경우, 선택된 구성상품의 옵션값들이 저장된다.
   * - 세트상품 예시) [{name: '구성상품1 이름', value: '블랙/프리'}, {name: '구성상품2 이름', value: '블랙/S'}] => '블랙/프리/블랙/S'
   */
  options: Scalars['String'];
  /** 품목 가격 (상품의 가격 + 추가/차감 금액) */
  price: Scalars['Int'];
  /** 품목의 추가/차감 금액 */
  price_delta: Scalars['Int'];
  /** 상품 아이템 상세 정보 */
  product_detail_info?: Maybe<ProductDetailInfo>;
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no?: Maybe<Scalars['String']>;
  /** 과세 타입 (default: TAX) */
  tax_type: CatalogTaxType;
  /** 상품 주소 */
  url: Scalars['String'];
};

/** 상품주문 정보 */
export type OrderItemProductNameArgs = {
  abbreviate_limit_length?: InputMaybe<Scalars['Int']>;
};

/** 상품 옵션 타입 */
export enum OrderItemProductOptionType {
  ADDITIONAL = 'ADDITIONAL',
  BASIC = 'BASIC',
}

export type OrderItemProductPreorder = {
  /** 프리오더 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 프리오더 예약발송일 */
  date_shipping: Scalars['CrTimestamp'];
  /** 프리오더 시작일 */
  date_start: Scalars['CrTimestamp'];
};

/** 상품주문 수령정보 */
export type OrderItemReceiver = {
  /** 주소 */
  address: Scalars['String'];
  /** 도시 */
  city?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  contract_number: Scalars['String'];
  /** 국가번호 */
  country_number: Scalars['String'];
  /** 상세 주소 */
  detail_address?: Maybe<Scalars['String']>;
  /** 공동현관 비밀번호 */
  entrance_password?: Maybe<Scalars['String']>;
  /** 수령인(이름) */
  first_name: Scalars['String'];
  /** 수령인(성) */
  last_name: Scalars['String'];
  /** 마스킹 처리된 주소 */
  masked_address: Scalars['String'];
  /** 마스킹 처리된 상세주소 */
  masked_detail_address?: Maybe<Scalars['String']>;
  /** 마스킹 처리된 휴대폰 번호 */
  masked_mobile_tel: Scalars['String'];
  /** 마스킹 처리된 이름 */
  masked_name: Scalars['String'];
  /** 마스킹 처리된 개인통관고유부호 */
  masked_personal_clearance_code?: Maybe<Scalars['String']>;
  /** 배송 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 개인통관고유부관호 */
  personal_clearance_code?: Maybe<Scalars['String']>;
  /** 우편번호 */
  postcode: Scalars['String'];
  /** 샵ID */
  shop_id: Scalars['ID'];
  /** 지역 */
  state?: Maybe<Scalars['String']>;
};

/** 주문 아이템 요청 */
export type OrderItemRequest = {
  /** 취소/반품 ClaimItem 의 현재 보류 */
  active_claim_item_defer?: Maybe<ClaimItemDefer>;
  /** 취소/반품 요청의 현재 보류 */
  active_defer?: Maybe<OrderItemRequestDefer>;
  /** 상품주문 요청 첨부파일 목록 */
  attachment_list: Array<OrderAttachment>;
  /** 교환 전 상품 수거 방법 */
  collecting_type?: Maybe<CollectingType>;
  /** 요청 완료 날짜 */
  date_completed?: Maybe<Scalars['CrTimestamp']>;
  /** 셀러의 교환 수거 완료 날짜 */
  date_marked_exchange_collected?: Maybe<Scalars['CrTimestamp']>;
  /** 셀러의 교환 수거 시작 날짜 */
  date_marked_exchange_collecting?: Maybe<Scalars['CrTimestamp']>;
  /** 셀러의 반품 수거 완료 날짜 */
  date_marked_return_collected?: Maybe<Scalars['CrTimestamp']>;
  /** 셀러의 반품 수거 시작 날짜 */
  date_marked_return_collecting?: Maybe<Scalars['CrTimestamp']>;
  /** 요청 날짜 */
  date_requested: Scalars['CrTimestamp'];
  /** 요청 철회일 */
  date_withdrawn?: Maybe<Scalars['CrTimestamp']>;
  /** 교환 비용 정보 */
  exchange_amounts_info?: Maybe<ExchangeAmountsInfo>;
  /** 교환 요청 정보 */
  exchange_request_info?: Maybe<ExchangeRequestInfo>;
  /** 추가금액 지불 방식 정보 */
  extra_additional_fee_charge_method?: Maybe<ExtraAdditionalFeeChargeMethod>;
  /** 상품주문요청 히스토리 목록 */
  history_list: Array<OrderItemRequest>;
  /** Record ID */
  id: Scalars['ID'];
  /** 운송장 번호 */
  invoice_number?: Maybe<Scalars['String']>;
  /** 요청에 해당하는 주문 */
  order: Order;
  /** 요청에 해당하는 상품 주문 */
  order_item: OrderItem;
  /** 상품주문요청 번호. 함께 생성된 상품주문요청은 같은 번호를 가진다. */
  order_item_request_number?: Maybe<Scalars['String']>;
  /** 요청 사유 변경이력 목록 */
  order_item_request_reason_list: Array<OrderItemRequestReason>;
  /** 요청 당시의 payment_uuid */
  payment_uuid_at_request?: Maybe<Scalars['String']>;
  /** 상품주문의 최근 상태변경 보류 */
  recent_defer?: Maybe<OrderItemRequestDefer>;
  /** 환불 방법 */
  refund_payment?: Maybe<RefundPayment>;
  /** 해외 배송비 환불 정보 */
  refunded_international_order_shipping_fee_amounts?: Maybe<ShippingFeeRefundTotalAmounts>;
  /** 클레임으로 환불된 당일 배송비 */
  refunded_same_day_shipping_fee?: Maybe<SameDayShippingFee>;
  /** 해외 배송비 환불 총액 */
  requested_international_order_shipping_fee_total_amount?: Maybe<Scalars['Int']>;
  /** 요청 사유 */
  requested_reason?: Maybe<Scalars['String']>;
  /**
   * 요청 사유 카테고리
   * - 100번대: [취소] 구매자 귀책
   * - 200번대: [취소] 판매자 귀책
   * - 300번대: [반품] 구매자 귀책
   * - 400번대: [반품] 판매자 귀책
   */
  requested_reason_category: RequestedReasonCategory;
  /** 배송 현황 */
  return_shipping_tracking_detail_history_list: OrderShippingTrackingDetailHistoryList;
  /** 택배사 */
  shipping_company?: Maybe<Scalars['String']>;
  /** 배송비 추가 지불방식 정보 */
  shipping_fee_additional_charge_method?: Maybe<ShippingFeeAdditionalChargeMethod>;
  /** 쇼핑몰 주문 환불 정보 */
  shipping_group_refund?: Maybe<ShippingGroupRefund>;
  /** 반품/교환 관련 배송 추적 ID */
  shipping_tracking_id?: Maybe<Scalars['String']>;
  /** 요청 상태 */
  status: OrderItemRequestStatus;
  /** 요청 타입 */
  type: OrderItemRequestType;
  /** 상품주문 요청 철회 계정 정보 */
  withdrawn_account_info?: Maybe<OrderAccountInfo>;
  /** 철회 사유 */
  withdrawn_reason?: Maybe<Scalars['String']>;
};

/** 상품주문 상태변경 보류 */
export type OrderItemRequestDefer = {
  /** 보류 생성 시간 */
  date_created: Scalars['CrTimestamp'];
  /** 보류 해지 시간 */
  date_withdrawn?: Maybe<Scalars['CrTimestamp']>;
};

/** 요청 목록 아이템의 입력 */
export type OrderItemRequestListItemInput = {
  /** 요청할 주문 아이템 번호 */
  order_item_number: Scalars['String'];
  /** 요청 사유 */
  requested_reason?: InputMaybe<Scalars['String']>;
  /** 요청 사유 카테고리 */
  requested_reason_category: RequestedReasonCategory;
};

/** 클레임 사유 변경 이력 */
export type OrderItemRequestReason = {
  /** 변경자 정보 */
  account_info: OrderAccountInfo;
  /** 변경 일자 */
  date_created: Scalars['CrTimestamp'];
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 클레임 아이디 */
  order_item_request_id: Scalars['ID'];
  /** 클레임 상태 */
  order_item_request_status: OrderItemRequestStatus;
  /** 변경 상세 사유 */
  reason?: Maybe<Scalars['String']>;
  /** 변경 사유 */
  reason_category: RequestedReasonCategory;
  /** 반품비용 추가 지불방식 정보 */
  shipping_fee_additional_charge_method?: Maybe<ShippingFeeAdditionalChargeMethod>;
};

/** 주문 아이템의 요청 상태 */
export enum OrderItemRequestStatus {
  CANCELLED = 'CANCELLED',
  CANCELLED_AFTER_CONFIRMED = 'CANCELLED_AFTER_CONFIRMED',
  CANCELLING = 'CANCELLING',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  EXCHANGED = 'EXCHANGED',
  EXCHANGE_COLLECTED = 'EXCHANGE_COLLECTED',
  EXCHANGE_COLLECTING = 'EXCHANGE_COLLECTING',
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED',
  RETURNED = 'RETURNED',
  RETURN_COLLECTED = 'RETURN_COLLECTED',
  RETURN_COLLECTING = 'RETURN_COLLECTING',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
}

/** 주문 아이템 요청 타입 */
export enum OrderItemRequestType {
  CANCEL = 'CANCEL',
  CANCEL_AFTER_CONFIRM = 'CANCEL_AFTER_CONFIRM',
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN',
}

/** 상품 주문 반품 배송 상세 정보 */
export type OrderItemReturnShippingTrackingDetail = {
  /** 상품 주문 클레임 정보 */
  active_request?: Maybe<ShippingOrderActiveRequest>;
  /** receiver_name */
  receiver_name: Scalars['String'];
  /** 배송추적 상세 정보 목록 */
  shipping_tracking_detail_history_list: Array<ShippingTrackingDetailHistory>;
  /** shop_name */
  shop_name: Scalars['String'];
  /** (영문)shop_name */
  shop_name_en: Scalars['String'];
};

/** 상품주문 상태변경 일정 연기 */
export type OrderItemScheduleDelay = {
  /** 연기하는 일정 */
  date_scheduled_ymd: Scalars['Float'];
  /** 연기 사유 */
  delay_reason?: Maybe<Scalars['String']>;
  /** 연기 사유 카테고리 */
  delay_reason_category: OrderItemDelayReasonCategory;
};

/** 상품주문 연장 정보 */
export type OrderItemScheduleDelayInfo = {
  /** 연장 횟수 */
  count: Scalars['Int'];
  /** 최근 연장 레코드 */
  recent_delay?: Maybe<OrderItemScheduleDelay>;
};

/** 상품 주문 배송 상세 정보 */
export type OrderItemShippingTrackingDetail = {
  /** 현지 송장 번호(글로벌 일본) */
  arrival_invoice_number?: Maybe<Scalars['String']>;
  /** 현지 택배사명(글로벌 일본) */
  arrival_shipping_company?: Maybe<Scalars['String']>;
  /** 현지 택배사 배송 트래킹 URL(글로벌 일본) */
  arrival_shipping_tracking_url?: Maybe<Scalars['String']>;
  /** 국가 코드 */
  country: CountryType;
  /** 도착 예정 일시 */
  date_delivery_due?: Maybe<Scalars['CrTimestamp']>;
  /** 배송 타입 */
  delivery_type: ShippingDeliveryType;
  /** 송장 번호 */
  invoice_number?: Maybe<Scalars['String']>;
  /** 택배사명 */
  shipping_company?: Maybe<Scalars['String']>;
  /** 배송추적 상세 정보 목록 */
  shipping_tracking_detail_history_list: Array<ShippingTrackingDetailHistory>;
  /** 배송 트래킹 URL */
  shipping_tracking_url?: Maybe<Scalars['String']>;
  /** shop_name */
  shop_name: Scalars['String'];
  /** (영문)shop_name */
  shop_name_en: Scalars['String'];
  /** 상품 주문 상태 */
  status?: Maybe<ShippingOrderItemStatus>;
};

/** 쇼핑몰 정보 */
export type OrderItemShop = {
  /** 고객 문의 전화번호 */
  customer_center_tel?: Maybe<Scalars['String']>;
  /** 쇼핑몰 Record ID */
  id: Scalars['ID'];
  /** 직진배송 여부 */
  is_zonly: Scalars['Boolean'];
  /** 쇼핑몰 도메인 정보 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 이름 */
  name: Scalars['String'];
  /** 쇼핑몰 상태 */
  state: Scalars['Int'];
  /** 대표 이미지 */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 쇼핑몰 URL */
  url: Scalars['String'];
};

/** 주문 아이템의 상태 */
export enum OrderItemStatus {
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  BEFORE_TRANSFER = 'BEFORE_TRANSFER',
  CANCELLED = 'CANCELLED',
  CANCELLED_AFTER_CONFIRMED = 'CANCELLED_AFTER_CONFIRMED',
  CANCELLED_BEFORE_TRANSFER = 'CANCELLED_BEFORE_TRANSFER',
  CANCELLING = 'CANCELLING',
  CANCEL_DEFERRED = 'CANCEL_DEFERRED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CONFIRMED = 'CONFIRMED',
  DOMESTIC_IN_TRANSIT = 'DOMESTIC_IN_TRANSIT',
  EXCHANGED = 'EXCHANGED',
  EXCHANGE_COLLECTED = 'EXCHANGE_COLLECTED',
  EXCHANGE_COLLECTING = 'EXCHANGE_COLLECTING',
  EXCHANGE_DEFERRED = 'EXCHANGE_DEFERRED',
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED',
  INTERNATIONAL_AWAITING_SHIPMENT = 'INTERNATIONAL_AWAITING_SHIPMENT',
  INTERNATIONAL_IN_TRANSIT = 'INTERNATIONAL_IN_TRANSIT',
  IN_TRANSIT = 'IN_TRANSIT',
  NEW_ORDER = 'NEW_ORDER',
  RETURNED = 'RETURNED',
  RETURN_COLLECTED = 'RETURN_COLLECTED',
  RETURN_COLLECTING = 'RETURN_COLLECTING',
  RETURN_DEFERRED = 'RETURN_DEFERRED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  SHIPMENT_PROCESS_REQUESTED = 'SHIPMENT_PROCESS_REQUESTED',
  SHIPPED = 'SHIPPED',
  SHIPPING_DEFERRED = 'SHIPPING_DEFERRED',
}

/** OrderItem의 zonly 정보 */
export type OrderItemZonlyInfo = {
  /** zonly 내 원래 쇼핑몰 정보 */
  origin_shop?: Maybe<ZonlyOriginShop>;
  /** 원천 쇼핑몰의 레코드 ID */
  origin_shop_id: Scalars['ID'];
  /** 원천 쇼핑몰 이름 */
  origin_shop_name: Scalars['String'];
};

/** 주문서 목록 및 요약 데이터 */
export type OrderList = {
  /** 주문서 목록 */
  item_list: Array<Order>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 쇼핑몰 정보 */
export type OrderPriceWithCurrency = {
  /** 쇼핑몰 Record ID */
  currency: CurrencyType;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

/** 적용된 할인 목록 정보 */
export type OrderProductDiscountInfo = {
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 할인 정책 타입 (PROMOTION, PROMOTION_DISCOUNT_PRICE) */
  discount_policy_type: Scalars['String'];
  /** 할인률 (할인 정책 기준) */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 할인 타입 */
  discount_type: PromotionProductDiscountType;
  /** 노출 할인률 (product_original_price 기준) */
  display_discount_rate_bp: Scalars['Int'];
  /** 상품 프로모션 정보 */
  promotion_applied_item?: Maybe<OrderSheetOrderItemPromotionAppliedInfo>;
};

/** 주문 프로모션 */
export type OrderPromotion = {
  /** 프로모션 적용 수량 (N) */
  applied_quantity?: Maybe<Scalars['Int']>;
  /** 최수 주문 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 최소 주문 수량 (N+1) */
  min_required_quantity?: Maybe<Scalars['Int']>;
  /** 상품주문 프로모션 적용 정보 목록 */
  order_promotion_item_list: Array<OrderPromotionItem>;
  /** 프로모션 아이디 */
  promotion_id: Scalars['ID'];
  /** 프로모션명 */
  promotion_title: Scalars['String'];
  /** 프로모션 타입 */
  promotion_type: PromotionType;
  /** 프로모션별 적용된 총 금액 */
  total_promotion_price: Scalars['Int'];
};

/** 상품 프로모션 적용 정보 */
export type OrderPromotionItem = {
  /** 상품주문 아이디 */
  order_item_id: Scalars['ID'];
  /** 주문 프로모션 정보 */
  order_promotion: OrderPromotion;
  /** 주문 프로모션 아이디 */
  order_promotion_id: Scalars['ID'];
  /** 주문 프로모션 아이템 그룹 아이디 */
  order_promotion_item_group_id?: Maybe<Scalars['ID']>;
  /** 프로모션 전체 적용가 */
  promotion_applied_discount_price: Scalars['Int'];
  /** 프로모션 개별 적용가 */
  promotion_applied_item_discount_price: Scalars['Int'];
};

export enum OrderPromotionValidateType {
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  CANCEL_AFTER_CONFIRMED_COMPLETE = 'CANCEL_AFTER_CONFIRMED_COMPLETE',
  CANCEL_AFTER_CONFIRMED_REQUEST = 'CANCEL_AFTER_CONFIRMED_REQUEST',
  CANCEL_COMPLETE = 'CANCEL_COMPLETE',
  CANCEL_DEFER = 'CANCEL_DEFER',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CANCEL_WITHDRAW = 'CANCEL_WITHDRAW',
  CANCEL_WITHDRAW_DEFER = 'CANCEL_WITHDRAW_DEFER',
  IN_TRANSIT = 'IN_TRANSIT',
  RETURN_COLLECT = 'RETURN_COLLECT',
  RETURN_COLLECTING = 'RETURN_COLLECTING',
  RETURN_COMPLETE = 'RETURN_COMPLETE',
  RETURN_DEFER = 'RETURN_DEFER',
  RETURN_REQUEST = 'RETURN_REQUEST',
  RETURN_WITHDRAW = 'RETURN_WITHDRAW',
  RETURN_WITHDRAW_DEFER = 'RETURN_WITHDRAW_DEFER',
  SHIPPED = 'SHIPPED',
  SHIPPING_DEFERRED = 'SHIPPING_DEFERRED',
  SHIPPING_WITHDRAW_DEFER = 'SHIPPING_WITHDRAW_DEFER',
}

/** 주문서_수령정보 */
export type OrderReceiver = {
  /** 주소 */
  address: Scalars['String'];
  /** 도시 */
  city?: Maybe<Scalars['String']>;
  /** 휴대폰 번호 */
  contract_number: Scalars['String'];
  /** 국가번호 */
  country_number: Scalars['String'];
  /** 상세 주소 */
  detail_address?: Maybe<Scalars['String']>;
  /** 수령인(이름) */
  first_name: Scalars['String'];
  /** 수령인(성) */
  last_name: Scalars['String'];
  /** 마스킹 처리된 주소 */
  masked_address: Scalars['String'];
  /** 마스킹 처리된 상세주소 */
  masked_detail_address?: Maybe<Scalars['String']>;
  /** 마스킹 처리된 휴대폰 번호 */
  masked_mobile_tel: Scalars['String'];
  /** 마스킹 처리된 이름 */
  masked_name: Scalars['String'];
  /** 마스킹 처리된 개인통관고유부호 */
  masked_personal_clearance_code?: Maybe<Scalars['String']>;
  /** 배송 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 개인통관고유부관호 */
  personal_clearance_code?: Maybe<Scalars['String']>;
  /** 우편번호 */
  postcode: Scalars['String'];
  /** 지역 */
  state?: Maybe<Scalars['String']>;
};

/** 상품별 추천상품 맵핑 정보 */
export type OrderRecommendEachProduct = {
  /** 상품 아이디 */
  product_id: Scalars['String'];
  /** 추천 상품 목록 */
  recommend_products: Array<OrderRecommendProduct>;
};

/** 상품별 추천 상품 목록 정보 */
export type OrderRecommendEachProductList = {
  /** 상품별 추천상품 맵핑 목록 */
  item_list: Array<OrderRecommendEachProduct>;
  /** 추천 타이틀 */
  recommend_title: OrderRecommendProductTitle;
};

/** 추천 상품 정보 */
export type OrderRecommendProduct = {
  /** 광고 정보 */
  advertisement_info?: Maybe<RecommendProductAdvertisementInfo>;
  /** 상품 하단 표기할 배지 목록 */
  badge_list: Array<RecommendProductBadge>;
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list: Array<RecommendProductBadge2>;
  /** 앱 내 브라우징 타입 */
  browsing_type: BrowsingType;
  /** 상품 ID (product_id) */
  catalog_product_id: Scalars['ID'];
  /** 타임특가 마감시간 */
  deadline_date?: Maybe<Scalars['CrTimestamp']>;
  /** 할인 정보 */
  discount_info?: Maybe<RecommendProductDiscountInfo>;
  /** 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인 적용 후 최종 가격 */
  final_price: Scalars['Int'];
  /** 무료배송 여부 */
  free_shipping: Scalars['Boolean'];
  /** 퍼널 타입 정보 */
  funnel_type: Scalars['String'];
  /** 쿠폰할인가 여부 */
  has_coupon: Scalars['Boolean'];
  /** 상품 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 이미 유저가 구매한 상품 여부 */
  is_ordered_product: Scalars['Boolean'];
  /** 직진배송 여부 */
  is_zonly: Scalars['Boolean'];
  /** Z할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 연동된 가격 중 최고가 */
  max_price: Scalars['Int'];
  /** 가로형 상품카드 메타 데이터 영역용 엠블렘 */
  metadata_emblem_badge_list: Array<RecommendProductBadge2>;
  /** 상품 pdp URL */
  pdp_landing_url?: Maybe<Scalars['String']>;
  /** 추천 모델 정보 */
  recommend_model: RecommendModel;
  /** 리뷰 개수 */
  review_count: Scalars['Int'];
  /** 리뷰 점수 */
  review_score?: Maybe<Scalars['String']>;
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name: Scalars['String'];
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** 상품 서브 이미지 URL(ex. 스타일링 컨텐츠 대표 이미지 */
  sub_image_url?: Maybe<Scalars['String']>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list: Array<RecommendProductBadge2>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list: Array<RecommendProductBadge2>;
  /** 상품 이름 */
  title: Scalars['String'];
  /** 상품 webp 이미지 URL */
  webp_image_url?: Maybe<Scalars['String']>;
};

/** 추천 상품 목록 정보 */
export type OrderRecommendProductList = {
  /** 추천 상품 목록 */
  item_list: Array<OrderRecommendProduct>;
  /** 추천 지면 타이틀 */
  recommend_title: OrderRecommendProductTitle;
};

/** 추천 지면 타이틀 정보 */
export type OrderRecommendProductTitle = {
  /** 추천 지면 메인 문구 */
  main_title: Scalars['String'];
  /** 추천 지면 서브 문구 */
  sub_title?: Maybe<Scalars['String']>;
};

export enum OrderRefundResponsibility {
  SELLER = 'SELLER',
  USER = 'USER',
}

/** 주문서 */
export type OrderSheet = {
  /** 국내 배송비 */
  domestic_shipping_fee?: Maybe<Scalars['Int']>;
  /** 해외주문 배송 예정일자 */
  global_estimated_shipping_date: GlobalEstimatedShippingDate;
  /** 당일 배송 여부 */
  is_same_day_shipping?: Maybe<Scalars['Boolean']>;
  /** 주문서에 표시할 이벤트 */
  order_sheet_event: OrderSheetEvent;
  /** 주문서 임시데이터 쇼핑몰 주문의 목록 */
  order_shop_list: Array<OrderSheetShop>;
  /** 리뷰작성시 예상 지급마일리지 최대 */
  product_review_issue_mileage_maximum: Scalars['Int'];
  /** 리뷰작성시 예상 지급포인트 최대 */
  product_review_issue_point_maximum: Scalars['Int'];
  /** 주문서 임시데이터에 미리 선택한 쿠폰 id list */
  selected_user_account_coupon_id_list?: Maybe<Array<Scalars['ID']>>;
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 총 배송비(국내+해외) */
  total_shipping_fee: Scalars['Int'];
  /** 계정 정보 */
  user_account?: Maybe<UserAccount>;
  /** UUID */
  uuid: Scalars['String'];
};

/** 상품 아이템의 추가 옵션정보(연동형 옵션/추가입력 옵션) */
export type OrderSheetAdditionalOption = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션 구분자(추가입력 옵션은 code 없음) */
  option_code?: Maybe<Scalars['String']>;
  /** 옵션값 */
  value: Scalars['String'];
  /** 옵션값 구분자(추가입력 옵션은 code 없음) */
  value_code?: Maybe<Scalars['String']>;
};

/** 브랜드 */
export type OrderSheetBrand = {
  /** 브랜드 ID */
  id: Scalars['ID'];
  /** 브랜드명 */
  name: Scalars['String'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
};

export type OrderSheetCatalogHierarchyCategory = {
  /** 자식 카테고리 목록 */
  children?: Maybe<OrderSheetCatalogHierarchyCategory>;
  /** id */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
};

export type OrderSheetCatalogHierarchyCategoryV2 = {
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 이름 */
  position: CatalogCategoryPositionType;
};

export type OrderSheetCatalogProductCategory = {
  /**
   * 카테고리 계층
   * @deprecated hierarchy_list 사용
   */
  hierarchy?: Maybe<OrderSheetCatalogHierarchyCategory>;
  /** 카테고리 계층 */
  hierarchy_list?: Maybe<Array<Maybe<OrderSheetCatalogHierarchyCategoryV2>>>;
};

/** 주문서에 표기할 이벤트 */
export type OrderSheetEvent = {
  /** 주문할 상품 정보의 배열 */
  html: Scalars['String'];
  /** 이벤트 정보 */
  pay_type: Array<Scalars['String']>;
};

/** 주문서의 상품주문 정보 */
export type OrderSheetOrderItem = {
  /** 상품 아이템의 추가 옵션 정보(연동형 옵션/추가입력 옵션) 입력 리스트 */
  additional_option_detail_list?: Maybe<Array<OrderSheetAdditionalOption>>;
  /** 브랜드 정보 */
  brand?: Maybe<OrderSheetBrand>;
  /** 카트 아이템 해시 문자열(카트에서 생성되었을 경우 전송) */
  cart_item_hash?: Maybe<Scalars['String']>;
  /** 발송기한 */
  due_date_in_transit: Scalars['CrTimestamp'];
  /** 빠른 배송 타입 */
  fast_delivery_type?: Maybe<FastDeliveryType>;
  /** 무료배송 여부 */
  free_shipping: Scalars['Boolean'];
  /** 퍼널 정보 */
  funnel_info?: Maybe<FunnelInfo>;
  /** 빠른 배송 여부 */
  is_fast_delivery: Scalars['Boolean'];
  /** OrderSheetOrderItem의 직잭메이트 판매샵 여부 */
  is_zigzag_mate: Scalars['Boolean'];
  /** 상품 최종가격 정보 */
  item_final_price?: Maybe<ItemFinalPriceInfo>;
  /** 품목명 */
  name: Scalars['String'];
  /** 상품 아이템 옵션 타입 */
  option_type?: Maybe<OrderItemProductOptionType>;
  /** zonly 내 원천 쇼핑몰 정보 */
  origin_shop?: Maybe<OrderSheetZonlyOriginShop>;
  /** 주문할 상품 정보 */
  product_info: OrderSheetProductInfo;
  /** 상품 조합 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 상품 프로모션 정보 */
  promotion_applied_info?: Maybe<OrderSheetOrderItemPromotionAppliedInfo>;
  /** 프로모션 그룹 식별자 */
  promotion_item_group_key?: Maybe<Scalars['String']>;
  /** 수량 */
  quantity: Scalars['Int'];
  /** 총 금액 (프로모션 적용가) */
  total_amount: Scalars['Int'];
  /** 총 Z결제 할인가 적용 전 금액 */
  total_before_zpay_discount_price?: Maybe<Scalars['Int']>;
  /** 총 상품 금액 */
  total_product_price: Scalars['Int'];
  /** 상품의 특성관련 정보 */
  trait_list: Array<Scalars['String']>;
};

/** 주문서 아이템의 프로모션 정보 */
export type OrderSheetOrderItemPromotionAppliedInfo = {
  /** BOGO 프로모션 적용 수량 (1+N 에서 N값을 담고있다) */
  applied_quantity?: Maybe<Scalars['Int']>;
  /** 프로모션 쿠폰 적용 가능 여부 */
  is_coupon_usable: Scalars['Boolean'];
  /** 최소 주문 금액 (첫구매 시 필요) */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 프로모션 할인 적용 가격 (단일 가격 * quantity) */
  promotion_applied_discount_price: Scalars['Int'];
  /** 프로모션 할인 적용 단일 가격 */
  promotion_applied_item_discount_price: Scalars['Int'];
  /** 프로모션 아이디 */
  promotion_id: Scalars['ID'];
  /** 프로모션 상품 ID */
  promotion_product_id?: Maybe<Scalars['ID']>;
  /** 프로모션 아이템 ID */
  promotion_product_item_id?: Maybe<Scalars['ID']>;
  /** 프로모션 타입 */
  promotion_type: PromotionType;
};

/** 주문서 상품 정보 */
export type OrderSheetProductInfo = {
  /** 출고지, 반품지 ID 정보 */
  address?: Maybe<ProductAddressResponse>;
  /** 묶음배송 타입 */
  bundle_type: ProductShippingBundleType;
  /** 카테고리 */
  category?: Maybe<OrderSheetCatalogProductCategory>;
  /** 상품 ID */
  id: Scalars['ID'];
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** z결제 할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 상품 이름 */
  name: Scalars['String'];
  /** 선택된 옵션값 / 로 구분 */
  options: Scalars['String'];
  /** 프리오더 관련 정보 */
  preorder?: Maybe<OrderSheetProductPreOrder>;
  /** 상품 가격 (상품가+옵션추가금) */
  price: Scalars['Int'];
  /** 품목의 추가/차감 금액 */
  price_delta: Scalars['Int'];
  /** 판매자가 부여한 품목 코드 */
  product_item_code?: Maybe<Scalars['String']>;
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no?: Maybe<Scalars['String']>;
  /** 상품별 배송비 정보 */
  shipping_fee?: Maybe<ProductShippingFee>;
  /** 상품의 과세 타입 */
  tax_type: ProductTaxType;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 상품 주소 */
  url: Scalars['String'];
};

/** 주문서 상품 프리오더 정보 */
export type OrderSheetProductPreOrder = {
  /** 프리오더 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 프리오더 시작일 */
  date_start: Scalars['CrTimestamp'];
  /** 프리오더 예약발송일 */
  reserved_date_in_transit: Scalars['CrTimestamp'];
};

/** 주문서 배송비 그룹 정보 */
export type OrderSheetShippingGroup = {
  /** 주문할 상품 정보의 배열 */
  order_item_list: Array<OrderSheetOrderItem>;
  /** 주문서 배송비 그룹 배송비 정책 */
  shipping_info: OrderSheetShippingGroupShippingInfo;
  /** 총 상품 결제 금액 (프로모션 적용가) */
  total_item_amount: Scalars['Int'];
  /** 총 상품 금액 */
  total_product_price: Scalars['Int'];
  /** 총 배송비 */
  total_shipping_fee: Scalars['Int'];
};

/** 주문서 배송비 그룹 배송 정보 */
export type OrderSheetShippingGroupShippingInfo = {
  /** 2권역 배송비 */
  extra_shipping_fee2?: Maybe<Scalars['Int']>;
  /** 3권역 배송비 */
  extra_shipping_fee3?: Maybe<Scalars['Int']>;
  /** 조건부 배송비 무료 총 금액 */
  free_shipping_amount: Scalars['Int'];
  /** 기본 배송비 */
  shipping_fee?: Maybe<Scalars['Int']>;
  /** 배송유형 */
  shipping_type: ShippingType;
};

/** 주문서 임시데이터의 쇼핑몰 별 주문 */
export type OrderSheetShop = {
  /** 주문할 상품 정보의 배열 */
  order_item_list: Array<OrderSheetOrderItem>;
  /** 주문서 배송비 그룹 리스트 */
  shipping_group_list: Array<OrderSheetShippingGroup>;
  /** 쇼핑몰 상세 정보 */
  shop: OrderSheetShopDetail;
  /** 쇼핑몰 ID */
  shop_id?: Maybe<Scalars['ID']>;
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 총 상품 금액 */
  total_product_price: Scalars['Int'];
  /** 총 배송비 */
  total_shipping_fee: Scalars['Int'];
};

/** 쇼핑몰 상세 정보 */
export type OrderSheetShopDetail = {
  /** 쇼핑몰 ID */
  id: Scalars['ID'];
  /** 쇼핑몰의 zonly 여부 */
  is_zonly: Scalars['Boolean'];
  /** 쇼핑몰 메인도메인 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 명 */
  name: Scalars['String'];
  /** 쇼핑몰 이미지 */
  typical_image_url?: Maybe<Scalars['String']>;
};

/** 주문서 V2 - 직진배송을 order_sheet_shop 으로 독립적으로 분리 */
export type OrderSheetV2 = {
  /** 국내 배송비 */
  domestic_shipping_fee?: Maybe<Scalars['Int']>;
  /** 당일 배송 여부 */
  is_same_day_shipping?: Maybe<Scalars['Boolean']>;
  /** 주문서에 표시할 이벤트 */
  order_sheet_event: OrderSheetEvent;
  /** 주문서 임시데이터 쇼핑몰 주문의 목록 */
  order_shop_list: Array<OrderSheetShop>;
  /** 리뷰작성시 예상 지급마일리지 최대 */
  product_review_issue_mileage_maximum: Scalars['Int'];
  /** 리뷰작성시 예상 지급포인트 최대 */
  product_review_issue_point_maximum: Scalars['Int'];
  /** 주문서 임시데이터에 미리 선택한 쿠폰 id list */
  selected_user_account_coupon_id_list?: Maybe<Array<Scalars['ID']>>;
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 총 배송비(국내+해외) */
  total_shipping_fee: Scalars['Int'];
  /** 계정 정보 */
  user_account?: Maybe<UserAccount>;
  /** UUID */
  uuid: Scalars['String'];
};

/** 직진배송 내 원천 쇼핑몰 정보 */
export type OrderSheetZonlyOriginShop = {
  /** ID */
  id: Scalars['ID'];
  /** 쇼핑몰 명 */
  name: Scalars['String'];
};

/** 주문 배송비 타입 */
export enum OrderShippingFeeType {
  INITIAL = 'INITIAL',
}

/** 배송 추적 상세 정보 */
export type OrderShippingTrackingDetailHistory = {
  /** 상호명 */
  brand_name: Scalars['String'];
  /** 실행 시간 */
  date_processed: Scalars['CrTimestamp'];
  /** 택배 기사 이름 */
  delivery_driver_name?: Maybe<Scalars['String']>;
  /** 택배 기사 휴대폰 번호 */
  delivery_driver_phone_number?: Maybe<Scalars['String']>;
  /** 추적 정보 순서 */
  sequence_number: Scalars['Int'];
  /** 배송 상태 */
  status: Scalars['String'];
};

/** 배송 추적 정보 */
export type OrderShippingTrackingDetailHistoryList = {
  /** 배송추적 상세 정보 목록 */
  item_list: Array<OrderShippingTrackingDetailHistory>;
  /** api 호출의 성공여부 */
  success: Scalars['Boolean'];
};

/** 유저 추가 금액 결제 계좌 (교환/반품비 배송비) */
export type OrderShopShippingUserExtraPayment = {
  /** 계좌 예금주명 */
  bank_account_holder: Scalars['String'];
  /** 계좌 입금 계좌e */
  bank_account_number: Scalars['String'];
  /** 은행 코드 */
  bank_code: Scalars['String'];
};

export enum OrderVersionType {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export type OrderWmsDeliveryInfo = {
  date_delivery_due?: Maybe<Scalars['CrTimestamp']>;
  delay_days_in_case_of_delay_area?: Maybe<Scalars['Int']>;
  delivery_type?: Maybe<WmsDeliveryType>;
  has_preorder: Scalars['Boolean'];
};

/** 직진 배송 정보 응답 */
export type OrderZigzinShippingBaseInfo = {
  /** 상품 번호 */
  catalog_product_id: Scalars['ID'];
  /** 요청 세션의 기본 배송지 존재 유무 (비로그인 = false, 로그인이더라도 기본 배송지 없는 경우 = false) */
  has_default_address: Scalars['Boolean'];
  /** 당일 및 새벽 배송 정보 */
  oneday_shipping?: Maybe<ZigzinEstimateShippingItemInfo>;
  /** 직진 배송 정보 */
  zigzin_shipping: ZigzinEstimateShippingItemInfo;
};

/** OrderZigzinShippingBaseInfo의 입력 */
export type OrderZigzinShippingBaseInfoInput = {
  /** 상품 번호 */
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  /** 상품의 최종가 (oneday_shipping.shipping_fee_text 를 호출하지 않는 경우 final_price 는 null 로 전달해도 무관합니다) */
  final_price?: InputMaybe<Scalars['Int']>;
  /** 우편번호 (별도로 요청되지 않는 경우 요청 세션에서 user_account_id 를 조회한 후 user segment 레디스에서 조회된 우편번호를 사용합니다) */
  postcode?: InputMaybe<Scalars['String']>;
};

/** 직진 배송 정보 다건 호출용 */
export type OrderZigzinShippingBaseInfoList = {
  /** 직진 배송 정보 응답 리스트 */
  item_list: Array<OrderZigzinShippingBaseInfo>;
  /** 직진 배송 정보 응답 개수 */
  total_count: Scalars['Int'];
};

/** OrderZigzinShippingBaseInfoList의 입력 */
export type OrderZigzinShippingBaseInfoListInput = {
  /** OrderZigzinShippingBaseInfo의 입력 */
  item_list: Array<OrderZigzinShippingBaseInfoInput>;
};

/** 주문한 상품 정보 */
export type OrderedProductInfo = {
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** 상품 이름 */
  name: Scalars['String'];
  /** 선택된 옵션 상세 정보 목록 */
  option_detail_list?: Maybe<Array<ProductOptionDetail>>;
  /** 선택된 옵션값 / 로 구분 */
  options: Scalars['String'];
  /** 상품 가격 (상품가+옵션추가금) */
  price: Scalars['Int'];
  /** 품목의 추가/차감 금액 */
  price_delta: Scalars['Int'];
  /** 상품 아이템 상세 정보 */
  product_detail_info?: Maybe<ProductDetailInfo>;
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no?: Maybe<Scalars['String']>;
  /** 상품 주소 */
  url: Scalars['String'];
};

/** 주문자 정보 */
export type Orderer = {
  /** 이메일 주소 */
  email: Scalars['String'];
};

/** N일 이전에 담은 장바구니 아이템 */
export type OutdatedCartItem = {
  /** 상품의 추가 옵션 정보 리스트(연동형 옵션/추가입력 옵션) */
  additional_option_detail_list?: Maybe<Array<ProductAdditionalOption>>;
  /** 조합형 상품을 제외한 상품의 정보(연동형 옵션/추가입력 옵션)로 만든 카트 아이템 해시 문자열 */
  cart_item_hash?: Maybe<Scalars['String']>;
  /** 마지막으로 장바구니에 추가된 시각 */
  date_last_added: Scalars['CrTimestamp'];
  /** 할인율 */
  discount_rate: Scalars['Int'];
  /** 상품 정보 유효 여부 */
  is_valid_product: Scalars['Boolean'];
  /** 직진배송 여부 */
  is_zonly: Scalars['Boolean'];
  /** 상품 최종가격 정보 */
  item_final_price?: Maybe<CartItemFinalPriceInfo>;
  /** 상품 아이템 정보 */
  product_item: CartProductItem;
  /** 장바구니 아이템의 상품 아이템 레코드 ID */
  product_item_id: Scalars['ID'];
  /** 상품 프로모션 정보 */
  promotion_applied_info?: Maybe<CartItemPromotionAppliedInfo>;
  /** 프로모션 그룹 식별자 */
  promotion_item_group_key?: Maybe<Scalars['String']>;
  /** 장바구니 아이템 수량 */
  quantity: Scalars['Int'];
  /** 쇼핑몰 정보 */
  shop: CartShopInfo;
  /** 쇼핑몰 ID */
  shop_id?: Maybe<Scalars['String']>;
};

/** 사용자의 N일 이전에 장바구니 아이템 목록 */
export type OutdatedCartItemList = {
  item_list: Array<OutdatedCartItem>;
};

/** 카카오뱅크 본인인증 덮어쓰기 진행 시 필요 값 */
export type OverwriteKakaobankAuthenticationInput = {
  /** 트랜잭션 키 (본인인증 결과 체크시 사용) */
  trx_key: Scalars['String'];
};

/** 카카오뱅크 본인인증 덮어쓰기 완료 결과 */
export type OverwriteKakaobankAuthenticationResult = {
  /** 본인인증 ID */
  authenticate_id?: Maybe<Scalars['String']>;
  /** 인증한 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 인증한 휴대폰 번호 */
  mobile_tel?: Maybe<Scalars['String']>;
  /** 본인인증 결과 */
  result: Scalars['Boolean'];
};

/** overwriteUserAccountAuthentication의 입력 */
export type OverwriteUserAccountAuthenticationInput = {
  cert_num: Scalars['String'];
  rec_cert: Scalars['String'];
};

/** overwriteUserAccountAuthentication의 결과 */
export type OverwriteUserAccountAuthenticationResult = {
  /** 인증 ID */
  authenticate_id: Scalars['String'];
  /** 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 사용자 휴대폰 번호 */
  mobile_tel?: Maybe<Scalars['String']>;
};

export type PdpRecommendGroup = PdpComponent & {
  /** PDP 하단 추천 영역 리스트 */
  bottom_area: Array<PdpComponent>;
  /** PDP 하단 추천 영역 리스트 사이즈 */
  bottom_area_size: Scalars['Int'];
  /** PDP 첫 구매 상품 리스트 */
  first_order: Array<PdpComponent>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  /** PDP 셀러 코디 상품 리스트 */
  seller_coordi: Array<PdpComponent>;
  /** PDP 상단 추천 영역 리스트 */
  top_area: Array<PdpComponent>;
  type: PdpComponentType;
};

export type PdpRecommendedItem = {
  ux_goods: PdpGoods;
  ux_product: PdpProduct;
};

export type PdpRecommendedItemGroup = {
  /** 추천 아이템 목록 */
  item_list: Array<PdpRecommendedItem>;
  /** 추천 제목. ex) 연관 상품 추천 */
  title: Scalars['String'];
};

/** 페이지 처리를 위한 기본 객체 */
export type Pagination = {
  page: Scalars['Int'];
  page_count?: Maybe<Scalars['Int']>;
  page_size: Scalars['Int'];
  total_count: Scalars['Int'];
};

export type PaginationInput = {
  /** 페이지 번호 */
  page: Scalars['Int'];
  /** 페이지 사이즈 */
  page_size: Scalars['Int'];
};

/** ParticipateAddToCart202001Event 결과 */
export type ParticipateAddToCart202001EvenResult = {
  /** 응모 결과 포인트 */
  result: Scalars['Int'];
};

/** 출석체크 참여 완료 리워드 */
export type ParticipateDailyMissionAttendanceResult = {
  /** 리워드 값 */
  reward_amount: Scalars['Int'];
  /** 리워드 타입 [ POINT, MILEAGE, GIFT ] */
  reward_type: Scalars['String'];
  /** 총 연속 출석일 */
  total_attendance_day: Scalars['Int'];
};

export type ParticipateDailyMissionBehaviorResult = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export type ParticipateDailyMissionCheerSellerContentResult = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

export type ParticipateDailyMissionLikeResult = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

/** 럭키볼 참여 input */
export type ParticipateDailyMissionLuckyBallInput = {
  /** 응모 번호 */
  entry_number_list: Array<Scalars['Int']>;
};

/** 럭키볼 참여 결과 */
export type ParticipateDailyMissionLuckyBallResult = {
  /** 응모 번호 */
  entry_number_list: Array<Scalars['Int']>;
  /** 같은 번호 응모자 수 */
  same_entry_number_participant_count: Scalars['Int'];
};

/** 미션스탬프 참여 결과 */
export type ParticipateDailyMissionMissionStampResult = {
  /** 리워드 */
  reward_amount: Scalars['Int'];
  /**
   * 미션 리워드 타입
   * [POINT, MILEAGE]
   */
  reward_type: Scalars['String'];
};

export type ParticipateDailyMissionQuizResult = DailyMissionReward & {
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

/** 랜덤박스 공유한 횟수 별 리워드 input */
export type ParticipateDailyMissionRandomBoxInviteCountInput = {
  /** 리워드를 받으려는 초대 횟수 */
  invite_count: Scalars['Int'];
};

/** 오늘의 혜택 랜덤박스 오픈 결과 */
export type ParticipateDailyMissionRandomBoxOpenResult = {
  /** 열수있는 랜덤박수 수 */
  openable_random_box_count: Scalars['Int'];
  /** 랜덤박스 리워드 */
  reward: DailyMissionRandomBoxReward;
};

export type ParticipateDailyMissionRouletteEventInput = {
  daily_mission_roulette_id: Scalars['ID'];
};

export type ParticipateDailyMissionRouletteEventResult = DailyMissionReward & {
  image_url?: Maybe<Scalars['String']>;
  reward: Scalars['String'];
  type: DailyMissionRewardType;
};

/** 투표 참여 input */
export type ParticipateDailyMissionTomorrowWeatherStylingPollInput = {
  /** 참여하려는 투표 id */
  poll_id: Scalars['ID'];
  /** 선택지 styling tag id */
  styling_tag_id: Scalars['ID'];
};

/** up/down 게임 참여 시작 응답값 */
export type ParticipateDailyMissionUpDownResult = {
  /** 사용한 게임 티켓 ID */
  daily_mission_game_ticket_id: Scalars['ID'];
  /** 어뷰징 방지를 위한 토큰 */
  token: Scalars['String'];
};

/** 이벤트 참여 입력 */
export type ParticipateEventInput = {
  /** 이벤트 타입 */
  event_type: EventType;
  /** 서브 이벤트 타입 */
  sub_event_type?: InputMaybe<Scalars['String']>;
};

/** 이벤트 메타데이터 이벤트 참여 Input */
export type ParticipateEventMetadataInput = {
  /** 컨텐츠 에디터 UUID */
  event_content_uuid: Scalars['String'];
  /** 이벤트 메타데이터 UUID */
  event_metadata_uuid: Scalars['String'];
  /** 카카오 채널추가 정보제공 동의 시도 여부 */
  plusfriends_consent_retried?: InputMaybe<Scalars['Boolean']>;
  /** 사용자 입력값 */
  user_input?: InputMaybe<Scalars['String']>;
};

/** 이벤트 메타데이터 이벤트 참여 Result */
export type ParticipateEventMetadataResult = {
  /** 결과 알림 타입 */
  alert_type: ParticipateEventMetadataResultAlertType;
  /** 결과 팝업 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 링크 타이틀 */
  link_title?: Maybe<Scalars['String']>;
  /** 링크 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 결과 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 참여하여 받은 리워드 정보 */
  reward: Array<Maybe<EventMetadataReward>>;
  /** 결과 팝업 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** 결과 타입 */
  type: Scalars['String'];
};

/** 사전 조건 결과 ALERT 타입 */
export enum ParticipateEventMetadataResultAlertType {
  /** 팝업 창 */
  POPUP = 'POPUP',
  /** 토스트 */
  TOAST = 'TOAST',
}

/** ParticipateEvent 결과 */
export type ParticipateEventResult = {
  /** 응모 결과 */
  result: EventReward;
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
};

/** 출석초대 친구초대 링크 참석 정보 입력 */
export type ParticipateFbkAttendanceEventInviteLinkInput = {
  /** 초대 코드 */
  inviter_code: Scalars['String'];
};

/** participateFbkEvent 의 input */
export type ParticipateFbkEventInput = {
  /** 패바카 이벤트 uuid */
  fbk_event_uuid: Scalars['String'];
};

/** participateFbkEventWithEventMetaData 의 input */
export type ParticipateFbkEventWithEventMetadataInput = {
  /** 이벤트 콘텐츠 uuid */
  event_content_uuid: Scalars['String'];
  /** 이벤트 메타데이터 uuid */
  event_metadata_uuid: Scalars['String'];
  /** 패바카 이벤트 uuid */
  fbk_event_uuid: Scalars['String'];
};

export type ParticipateFriendInvitationInput = {
  /** 초대 코드 */
  invite_code: Scalars['String'];
};

export type ParticipateOnboardingPointResult = {
  message?: Maybe<Scalars['String']>;
};

export type ParticipateZibetEventInput = {
  uuid: Scalars['ID'];
};

/** 결제 정보 */
export type Payment = {
  /** 수기 환불 내역 */
  deposit_refund?: Maybe<DepositRefund>;
  /** 주문 번호 */
  order_number?: Maybe<Scalars['String']>;
  /** 환불 계좌 번호 */
  refund_bank_account?: Maybe<UserPayRefundBankAccount>;
};

export type PaymentBadge = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 결제 뱃지 문구 리스트 */
  payment_badge_content_list: Array<PaymentBadgeContent>;
  /** 결제 수단 */
  payment_method: PaymentMethod;
};

export type PaymentBadgeContent = {
  /** 내용 */
  content: Scalars['String'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 언어 코드 */
  language_code: Scalars['String'];
};

export type PaymentBadgeList = {
  /** 결제 뱃지 정보 목록 */
  item_list: Array<PaymentBadge>;
};

/** PaymentBankAccount 검증 입력 */
export type PaymentBankAccountCheckInput = {
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
  bank_holder: Scalars['String'];
};

export type PaymentBankAccountResponseGraph = {
  /** 검증 결과 */
  is_matched: Scalars['Boolean'];
};

export enum PaymentCardBrandType {
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  JCB = 'JCB',
  LOCAL = 'LOCAL',
  MASTER = 'MASTER',
  UNIONPAY = 'UNIONPAY',
  VISA = 'VISA',
}

export type PaymentCardMetaDataResponse = {
  /** 카드 브랜드 */
  brand: PaymentCardBrandType;
  /** 카드 발행 국가 코드 */
  issuer_country_code?: Maybe<Scalars['String']>;
  /** 카드 번호 최대 길이 */
  max_number_length: Scalars['Int'];
  /** 카드 번호 최소 길이 */
  min_number_length: Scalars['Int'];
};

/** 발행정보 타입 */
export enum PaymentCashReceiptIdentityType {
  CARD = 'CARD',
  MOBILE_NUMBER = 'MOBILE_NUMBER',
  REGISTER_BUSINESS = 'REGISTER_BUSINESS',
  RESIDENT_REGISTRATION = 'RESIDENT_REGISTRATION',
}

/** 현금영수증 정보 */
export type PaymentCashReceiptInput = {
  /** 발행정보 */
  identity: Scalars['String'];
  /** 발행정보 타입 */
  identity_type: PaymentCashReceiptIdentityType;
  /** 발행용도 */
  purpose: PaymentCashReceiptPurpose;
};

/** 발행용도 */
export enum PaymentCashReceiptPurpose {
  DEDUCTION = 'DEDUCTION',
  EXPENDITURE = 'EXPENDITURE',
}

export enum PaymentCountry {
  JP = 'JP',
  KR = 'KR',
  US = 'US',
}

/** 환율 enum */
export enum PaymentCurrency {
  AUD = 'AUD',
  CAD = 'CAD',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  KRW = 'KRW',
  NZD = 'NZD',
  USD = 'USD',
}

export type PaymentEvent = {
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 이벤트 타입 */
  event_type: VendorEventType;
  /** 금융사 코드 */
  financial_company_code?: Maybe<FinancialCompanyCode>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 최소 금액 */
  min_amount: Scalars['Int'];
  /** 결제수단 */
  payment_method: PaymentMethod;
  /** 간편결제(페이류) 타입 */
  payment_method_type?: Maybe<Scalars['String']>;
  /** 벤더 설정 ID */
  vendor_config_id: Scalars['ID'];
  /** 벤더 타입 */
  vendor_type: VendorType;
};

export type PaymentEventDetail = {
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 최소 금액 */
  min_amount: Scalars['Int'];
};

export type PaymentEventForDisplayGraph = {
  /** 국가 코드 */
  country_code: Scalars['String'];
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 종료 날짜 */
  date_ended: Scalars['CrTimestamp'];
  /** 시작 날짜 */
  date_started: Scalars['CrTimestamp'];
  /** 이벤트 타입 */
  event_type: VendorEventType;
  /** 금융사 코드 */
  financial_company_code?: Maybe<FinancialCompanyCode>;
  /** 결제 이벤트 상세 정보 목록 */
  payment_event_detail_list: Array<PaymentEventDetail>;
  /** 결제수단 */
  payment_method: PaymentMethod;
  /** 간편결제(페이류) 타입 */
  payment_method_type?: Maybe<Scalars['String']>;
  /** 노출 순서 */
  priority: Scalars['Int'];
  /** 결제 이벤트 대상 타입 */
  target_type: PaymentEventTargetType;
  /** 벤더 설정 ID */
  vendor_config_id: Scalars['ID'];
  /** 벤더 타입 */
  vendor_type: VendorType;
};

export type PaymentEventForDisplayListGraph = {
  /** 결제 이벤트 정보 목록 */
  item_list: Array<PaymentEventForDisplayGraph>;
};

/** 결제 이벤트 정보 */
export type PaymentEventInput = {
  /** 결제 이벤트 상세 ID */
  payment_event_detail_id: Scalars['String'];
  /** 결제 이벤트 대상 정보 */
  payment_event_target_list?: InputMaybe<Array<PaymentEventTargetInput>>;
};

export type PaymentEventList = {
  /** 결제 이벤트 정보 목록 */
  item_list: Array<PaymentEvent>;
};

/** 결제 이벤트 대상 정보 */
export type PaymentEventTargetInput = {
  /** shop ID */
  shop_id: Scalars['String'];
};

export enum PaymentEventTargetType {
  ALL_SELLER = 'ALL_SELLER',
  GLOBAL = 'GLOBAL',
  SELLER = 'SELLER',
}

export type PaymentExchangeRate = {
  /** converted 환율 */
  converted_rate: Scalars['Float'];
  /** 통화 */
  currency: PaymentCurrency;
  /** 환율 */
  rate: Scalars['Float'];
};

/** 환율 정보 */
export type PaymentExchangeRateInformation = {
  /** 기준 통화 */
  base: PaymentCurrency;
  /** 환율 고시일 */
  date_published: Scalars['CrTimestamp'];
  /** 환율 목록 */
  exchange_rate_list: Array<PaymentExchangeRate>;
  id: Scalars['ID'];
};

export type PaymentFinancialCompany = {
  /** 결제 금융사 코드 */
  financial_company_code: FinancialCompanyCode;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 무이자 할부 리스트 */
  interest_free_list: Array<PaymentVendorInterestFreeResponse>;
  /** 최대 할부 개월 수 */
  max_installment_month: Scalars['Int'];
  /** 결제 금융사 점검 시간 리스트 */
  payment_financial_company_maintenance_list: Array<PaymentFinancialCompanyMaintenanceTime>;
  /** 결제 수단 */
  payment_method: PaymentMethod;
  /** 노출 순서 */
  priority: Scalars['Int'];
};

export type PaymentFinancialCompanyList = {
  /** 결제 금융사 정보 목록 */
  item_list: Array<PaymentFinancialCompany>;
};

export type PaymentFinancialCompanyMaintenanceTime = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 노출 여부 */
  is_active: Scalars['Boolean'];
  /** 결제 금융사 ID */
  payment_financial_company_id: Scalars['ID'];
};

/** PG 구분 */
export enum PaymentGatewayVendor {
  EXIMBAY = 'EXIMBAY',
  GALAXIA = 'GALAXIA',
  INICIS = 'INICIS',
  KAKAOPAY = 'KAKAOPAY',
  MOBILIANS = 'MOBILIANS',
  NICEPAY = 'NICEPAY',
  PAYMENT = 'PAYMENT',
  SETTLE_BANK = 'SETTLE_BANK',
  TOSSPAY = 'TOSSPAY',
  ZIGZAG = 'ZIGZAG',
}

/** 결제 수단 */
export enum PaymentMethod {
  BANK = 'BANK',
  CARD = 'CARD',
  CONVENIENCE_STORE = 'CONVENIENCE_STORE',
  CONVENIENCE_STORE_JP = 'CONVENIENCE_STORE_JP',
  GLOBAL_CARD = 'GLOBAL_CARD',
  KAKAOPAY = 'KAKAOPAY',
  PHONE = 'PHONE',
  POINT = 'POINT',
  SIMPLE_BANK = 'SIMPLE_BANK',
  SIMPLE_CARD = 'SIMPLE_CARD',
  TOSSPAY = 'TOSSPAY',
  UNDEFINED_PAYMENT_METHOD = 'UNDEFINED_PAYMENT_METHOD',
  VBANK = 'VBANK',
}

export type PaymentMethodInformationListResponse = {
  /** 결제 수단 정보 목록 */
  item_list: Array<PaymentMethodInformationResponse>;
};

export type PaymentMethodInformationResponse = {
  /** 결제 수단 계층 단계 */
  depth: Scalars['Int'];
  /** 결제 수단 점검시 노출 메시지 */
  disabled_message?: Maybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 결제 수단 점검시 disabled 여부 */
  is_disabled: Scalars['Boolean'];
  /** 이름 */
  name: Scalars['String'];
  /** 결제수단 */
  payment_method: PaymentMethod;
  /** 노출 순서 */
  priority: Scalars['Int'];
};

export type PaymentNotification = {
  /** 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 결제 금융사 */
  financial_company_code?: Maybe<FinancialCompanyCode>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 최소 금액 */
  min_amount?: Maybe<Scalars['Int']>;
  /** 알림 타입 */
  notification_type: PaymentNotificationType;
  /** 결제 수단 */
  payment_method: PaymentMethod;
  /** 결제 알림 문구 리스트 */
  payment_notification_content_list: Array<PaymentNotificationContent>;
};

export type PaymentNotificationContent = {
  /** 내용 */
  content: Scalars['String'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 언어 코드 */
  language_code: Scalars['String'];
};

export type PaymentNotificationList = {
  /** 결제 알림 정보 목록 */
  item_list: Array<PaymentNotification>;
};

export enum PaymentNotificationType {
  EVENT = 'EVENT',
  GUIDE = 'GUIDE',
  NOTICE = 'NOTICE',
}

/** 상품 유형 */
export enum PaymentProductType {
  OVERSEAS_PURCHASE_AGENCY = 'OVERSEAS_PURCHASE_AGENCY',
  PREORDER = 'PREORDER',
  TAX_FREE = 'TAX_FREE',
}

export enum PaymentStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  WAITING_PAY = 'WAITING_PAY',
}

export enum PaymentType {
  /** 외부 결제 */
  EXTERNAL = 'EXTERNAL',
  /** Z-PAY */
  ZPAY = 'ZPAY',
}

export type PaymentVendorInterestFree = {
  /** 금융사 코드 */
  financial_company_code: FinancialCompanyCode;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 할부 개월 */
  installment_month: Scalars['Int'];
  /** 최소 금액 */
  min_amount: Scalars['Int'];
  /** 벤더 타입 */
  vendor_type: VendorType;
};

export type PaymentVendorInterestFreeList = {
  /** 무이자 할부 정보 목록 */
  item_list: Array<PaymentVendorInterestFree>;
};

export type PaymentVendorInterestFreeResponse = {
  /** 금융사 코드 */
  financial_company_code: FinancialCompanyCode;
  /** 할부 개월 리스트 */
  installment_month_list: Array<Scalars['Int']>;
  /** 최소 금액 */
  min_amount: Scalars['Int'];
};

export type PdpAdVideoImage = {
  black_bg_image_url?: Maybe<Scalars['String']>;
  white_bg_image_url?: Maybe<Scalars['String']>;
};

export type PdpAgeRecommendedGoodsInfo = {
  recommended_goods_list: Array<Maybe<PdpGoodsCardItem>>;
  title?: Maybe<Scalars['String']>;
};

export enum PdpAgeRecommendedKeywordAgeType {
  AGE_10 = 'AGE_10',
  AGE_20 = 'AGE_20',
  AGE_30 = 'AGE_30',
  ALL = 'ALL',
}

export type PdpAgeRecommendedKeywordGroup = {
  age: Scalars['String'];
  age_type: PdpAgeRecommendedKeywordAgeType;
  recommended_keyword_list: Array<Maybe<Scalars['String']>>;
};

export type PdpAgeRecommendedKeywordInfo = {
  age_recommended_keyword_group_list: Array<Maybe<PdpAgeRecommendedKeywordGroup>>;
  title?: Maybe<Scalars['String']>;
};

export type PdpAreaShippingFee = {
  /** 제주 외 도서산간 추가배송비 */
  isolated_fee: Scalars['Int'];
  /** 제주 추가배송비 */
  jeju_fee: Scalars['Int'];
};

export type PdpAuthorizeAndGetEmailsResult = {
  email_list: Array<Maybe<Scalars['String']>>;
  membership_benefits_url: Scalars['String'];
};

export type PdpBadge = {
  /** atf 상품명 위쪽에 위치하는 뱃지 */
  above_atf_title_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** atf 가격 아래쪽에 위치하는 뱃지 */
  below_atf_price_badge_list?: Maybe<Array<UxDisplayBadge>>;
};

export type PdpBadgeNormal = {
  background_color: Scalars['String'];
  background_opacity: Scalars['Float'];
  border?: Maybe<PdpBorder>;
  /** 폰트 굵기(REGULAR, MEDIUM, SEMI_BOLD, BOLD) */
  font_weight: Scalars['String'];
  size: PdpBadgeNormalSize;
  text: Scalars['String'];
  text_color: Scalars['String'];
};

export enum PdpBadgeNormalSize {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
}

export type PdpBannerItem = PdpComponent & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  component_list?: Maybe<Array<PdpComponent>>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  label_color?: Maybe<Scalars['Float']>;
  landing_link?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  shop_main_domain?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: PdpComponentType;
};

export type PdpBannerList = PdpComponent & {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  item_list: Array<PdpComponent>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpBaseInfoInput = {
  /** 상품 번호 */
  catalog_product_id: Scalars['ID'];
  /** 이전 유입 경로 ex) PDP_NAVER_EP_BANNER(배너를 통해 들어왔는지), PDP_NAVER_EP_BUTTON(버튼을 통해 들어왔는지) */
  entry_source_type?: InputMaybe<Scalars['String']>;
  /** 네이버 등 제휴사를 통해 유입된 캠페인 코드 */
  source?: InputMaybe<Scalars['String']>;
  /** 웹 uuid */
  web_uuid?: InputMaybe<Scalars['String']>;
};

export type PdpBeautyIngredient = {
  /** 배합 목적 list */
  combination_purpose_list: Array<Scalars['String']>;
  /** 성분 */
  name: Scalars['String'];
  /** 주요 성분 설명 list */
  primary_description_list: Array<PdpBeautyIngredientDescription>;
  /** 주의 성분 설명 list */
  warning_description_list: Array<PdpBeautyIngredientDescription>;
};

export type PdpBeautyIngredientDescription = {
  count: Scalars['Int'];
  /** 성분 설명 */
  description: Scalars['String'];
};

export type PdpBeautyIngredientDetail = {
  /** 성분 리스트 */
  ingredient_list: Array<PdpBeautyIngredient>;
  /** 주요 성분 그룹 */
  primary_ingredient_group: Array<PdpBeautyIngredientGroup>;
  /** 주의 성분 그룹 */
  warning_ingredient_group: Array<PdpBeautyIngredientGroup>;
};

/** PDP 뷰티 성분 그룹 */
export type PdpBeautyIngredientGroup = {
  /** 설명 */
  description: Scalars['String'];
  /** 성분 이름 list */
  ingredient_name_list: Array<Scalars['String']>;
};

export type PdpBeautyIngredientSummary = {
  /** 주요 성분 설명 */
  primary_description_list: Array<PdpBeautyIngredientDescription>;
  /** 주의 성분 설명 */
  warning_description_list: Array<PdpBeautyIngredientDescription>;
};

/** 뷰티 한줄평 리뷰 - 최다 답변된 리뷰 질문 */
export type PdpBeautyMostAnsweredReviewQuestion = {
  /** 답변 */
  answered: Scalars['String'];
  /** 퍼센트 */
  percent: Scalars['Int'];
  /** 질문 */
  question: Scalars['String'];
};

/** 뷰티 한줄평 리뷰 */
export type PdpBeautyOneLineReview = {
  /** 최다 답변된 리뷰 질문 */
  most_answered_review_question?: Maybe<PdpBeautyMostAnsweredReviewQuestion>;
  /** 주문자 특성 list */
  orderer_attribute_list: Array<PdpBeautyOrdererAttribute>;
};

/** 뷰티 한줄평 리뷰 - 구매자 특성 */
export type PdpBeautyOrdererAttribute = {
  /** 맞춤 정보에 노출될 html 형식의 content */
  content_html: Scalars['String'];
  /** 주문 비율 */
  order_percent: Scalars['Int'];
};

export type PdpBeautyProductOptionColorChip = {
  /** 색상코드 */
  color_code?: Maybe<Scalars['String']>;
  /** 노출 유형(IMAGE, COLOR_CODE) */
  display_type: Scalars['String'];
  /** 확장 영역 */
  expanded_area: PdpBeautyProductOptionColorChipExpandedArea;
  /** 이미지 url */
  image_url?: Maybe<Scalars['String']>;
  /** 품절 여부 */
  is_soldout: Scalars['Boolean'];
  /** 상품의 옵션 이름 */
  name: Scalars['String'];
  /** 상품 옵션의 id */
  option_value_id: Scalars['String'];
};

/** 컬러칩 선택 시, 노출되는 확장 영역 */
export type PdpBeautyProductOptionColorChipExpandedArea = {
  /** 색상코드 */
  color_code?: Maybe<Scalars['String']>;
  /** 노출 유형(IMAGE, COLOR_CODE) */
  display_type: Scalars['String'];
  /** 이미지 url */
  image_url?: Maybe<Scalars['String']>;
};

export enum PdpBogoDiscountType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export type PdpBorder = {
  color: Scalars['String'];
  /** 테두리 둥글기(px) */
  radius: Scalars['Float'];
  /** 테두리 스타일 (ex. solid, dashed) */
  style: Scalars['String'];
  /** 테두리 두께(px) */
  width: Scalars['Float'];
};

export type PdpBrandRankingInfo = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<PdpShopRankingCardItem>;
};

export type PdpBrandRankingList = {
  has_more: Scalars['Boolean'];
  item_list: Array<PdpShopRanking>;
  updated_at?: Maybe<Scalars['CrTimestamp']>;
};

/** 브랜드관 TC 디자인 타입 */
export enum PdpBrandTargetContentDesignType {
  /** 텍스트가 가운데 정렬되어있는 타입 */
  COVER_CENTER = 'COVER_CENTER',
  /** 텍스트가 왼쪽 정렬되어있는 타입 */
  COVER_LEFT = 'COVER_LEFT',
  /** 메거진 컨텐츠 형태의 타입 */
  MAGAZINE = 'MAGAZINE',
}

/** 브랜드관 TC 메인 배너 */
export type PdpBrandTargetContentMainBanner = {
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  /** 컨텐츠 픽셀 비율(가로픽셀에 대한 세로픽셀) */
  ratio?: Maybe<Scalars['Float']>;
  /** 타입이 영상일 경우 스틸이미지 포함 */
  still_image_url?: Maybe<Scalars['String']>;
  /** TC 배너 ID */
  tc_banner_id?: Maybe<Scalars['ID']>;
  type: PdpBrandTargetContentMainBannerType;
  url?: Maybe<Scalars['String']>;
};

/** 브랜드관 TC 메인 배너 타입 */
export enum PdpBrandTargetContentMainBannerType {
  GOODS_ONLY = 'GOODS_ONLY',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

/** 브랜드관 테마뷰타입(타겟컨텐츠) */
export type PdpBrandThemeContent = PdpComponent & {
  /** 액션 버튼 */
  action_button?: Maybe<PdpButton>;
  /** 디자인 타입 */
  design_type: PdpBrandTargetContentDesignType;
  /** 브랜드관 TC 기획전 네이밍 */
  exhibition_name?: Maybe<PdpText>;
  /** 상품 캐로셀 */
  goods_carousel?: Maybe<PdpGoodsCarousel>;
  /** 사용 목적 타입 */
  group_type: Scalars['String'];
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 메인 배너 */
  main_banner: PdpBrandTargetContentMainBanner;
  /** 메인 타이틀 */
  main_title?: Maybe<PdpText>;
  position: Scalars['Int'];
  /** 서브 타이틀 */
  sub_title?: Maybe<PdpText>;
  /** TC ID */
  tc_id: Scalars['ID'];
  type: PdpComponentType;
};

export enum PdpBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

/** 버튼 컴포넌트 */
export type PdpButton = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  /** 딥링크 URL */
  link_url: Scalars['String'];
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  /** 버튼 스타일 */
  style?: Maybe<PdpButtonStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: PdpComponentType;
  ubl?: Maybe<UxUbl>;
};

/** PdpButton 스타일 */
export enum PdpButtonStyle {
  BUTTON_DEFAULT = 'BUTTON_DEFAULT',
  BUTTON_DEFAULT_NUM = 'BUTTON_DEFAULT_NUM',
  BUTTON_SMALL = 'BUTTON_SMALL',
  BUTTON_SMALL_NUM = 'BUTTON_SMALL_NUM',
  DEFAULT_L = 'DEFAULT_L',
  DEFAULT_M = 'DEFAULT_M',
  DEFAULT_S = 'DEFAULT_S',
  EXTENDED_TEXT_S_ARROW = 'EXTENDED_TEXT_S_ARROW',
  PRIMARY_L = 'PRIMARY_L',
  PRIMARY_M = 'PRIMARY_M',
  PRIMARY_S = 'PRIMARY_S',
  SECONDARY_L = 'SECONDARY_L',
  SECONDARY_M = 'SECONDARY_M',
  SECONDARY_S = 'SECONDARY_S',
  SQUARE_DEFAULT_L = 'SQUARE_DEFAULT_L',
  SQUARE_SECONDARY_L = 'SQUARE_SECONDARY_L',
  TOGGLE_M = 'TOGGLE_M',
  TOGGLE_S = 'TOGGLE_S',
}

export type PdpCatalogCroppedEssentials = {
  height?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  info_index: Scalars['Int'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type PdpCatalogCustomInputOption = {
  id?: Maybe<Scalars['ID']>;
  /** 값 입력 제한 */
  max_length?: Maybe<Scalars['Int']>;
  /** 상품 옵션명 */
  name: Scalars['String'];
  /** 필수 여부 */
  required: Scalars['Boolean'];
};

export enum PdpCatalogDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type PdpCatalogEssentials = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum PdpCatalogFulfillmentType {
  /** 무형 상품 배송 */
  DIGITAL_DELIVERY = 'DIGITAL_DELIVERY',
  /** 스토어 배송 */
  MERCHANT = 'MERCHANT',
  /** 직진 배송 */
  ZIGZIN = 'ZIGZIN',
}

export type PdpCatalogItem = {
  /** 배송 타입 */
  delivery_type?: Maybe<Scalars['String']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<PdpGoodsCardDiscountInfo>;
  /** 품목 노출 상태 */
  display_status: PdpCatalogItemDisplayStatus;
  /** 배송 날짜 문구 */
  expected_delivery_date?: Maybe<Scalars['String']>;
  /** 배송 날짜 epochTime */
  expected_delivery_time?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰, Z할인 등 모든 할인가가 반영된 값 */
  final_price: Scalars['Int'];
  id: Scalars['ID'];
  /** 직진 여부 */
  is_zigzin?: Maybe<Scalars['Boolean']>;
  /** 품목의 부가 속성 리스트 */
  item_attribute_list: Array<PdpCatalogItemAttribute>;
  /** 품목 코드 */
  item_code?: Maybe<Scalars['String']>;
  /** 품목의 재고 */
  item_inventory?: Maybe<PdpCatalogItemInventory>;
  /** 품목명 */
  name: Scalars['String'];
  /** 옵션 종류  (BASIC, ADDITIONAL) */
  option_type?: Maybe<Scalars['String']>;
  /** 품목 값 */
  price: Scalars['Int'];
  /** 품목 값 차액 */
  price_delta: Scalars['Int'];
  /** 품목 값 차액 with 통화 */
  price_delta_with_currency: PdpPriceWithCurrency;
  /** 품목 값 with 통화 */
  price_with_currency: PdpPriceWithCurrency;
  /** 남은 재고 */
  remain_stock?: Maybe<Scalars['Int']>;
  /** 품목 판매 상태 */
  sales_status: PdpCatalogSalesStatus;
  /** 재입고 알림 여부 */
  wms_notification_info?: Maybe<PdpWmsNotificationInfo>;
};

export type PdpCatalogItemAttribute = {
  /** 대응되는 상품 옵션 ID */
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 속성 값 */
  value: Scalars['String'];
  /** 속성 값 번호 */
  value_id: Scalars['ID'];
};

export enum PdpCatalogItemDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type PdpCatalogItemInventory = {
  /** 판매된 재고 누적량 */
  assigned_quantity: Scalars['Int'];
  /** 총 재고 */
  total_quantity: Scalars['Int'];
};

export enum PdpCatalogPaymentType {
  /** 외부 결제 */
  EXTERNAL = 'EXTERNAL',
  /** Z-PAY */
  ZPAY = 'ZPAY',
}

export type PdpCatalogProduct = {
  /** 상품의 추가 품목 리스트 */
  additional_item_list?: Maybe<Array<PdpCatalogItem>>;
  /** 이벤트 배너 */
  app_benefit_banner?: Maybe<PdpCommonBanner>;
  /** 뱃지 리스트 */
  badge: PdpBadge;
  /**
   * 뱃지 리스트 (legacy)
   * @deprecated badge 필드 사용
   */
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 뷰티 정보 */
  beauty_info?: Maybe<BeautyInfo>;
  /** 브랜드 id */
  brand_id?: Maybe<Scalars['ID']>;
  /** 브라우저 타입 */
  browsing_type: PdpBrowsingType;
  /** 카테고리 key */
  category_key?: Maybe<Scalars['String']>;
  /** 카테고리 리스트 */
  category_list?: Maybe<Array<PdpCatalogProductCategory>>;
  /** 파트너센터에서 등록한 상품 color 정보 */
  color_image_list: Array<PdpGoodsColorImage>;
  /** 쿠폰 상태 */
  coupon_available_status: PdpCouponAvailableStatus;
  /** 상품의 생성 일자 */
  created_at?: Maybe<Scalars['CrTimestamp']>;
  /** 상품의 크리마핏 정보 */
  crema_fit_url_list: Array<Scalars['String']>;
  /** 상품정보제공 고시 crop 이미지 */
  cropped_essentials?: Maybe<Array<PdpCatalogCroppedEssentials>>;
  /** 다른 셀러간의 동일한 상품 개수 */
  cross_seller_product_count?: Maybe<Scalars['Int']>;
  /** 사용자 입력 옵션 리스트 */
  custom_input_option_list: Array<PdpCatalogCustomInputOption>;
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 상단에 노출되는 배너 */
  description_top_banner?: Maybe<PdpDescriptionTopBanner>;
  /** 글로벌 대응 한글 설명 */
  detail_info?: Maybe<Scalars['String']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<PdpGoodsCardDiscountInfo>;
  /** 상품 노출 상태 */
  display_status: PdpCatalogDisplayStatus;
  /** 입점 타입 */
  entry_type: PdpProductEntryType;
  /** 상품정보제공 고시 */
  essentials: Array<PdpCatalogEssentials>;
  /** 예측 배송기간 정보(from order service) */
  estimated_shipping_date?: Maybe<PdpEstimatedShippingDate>;
  /** 이벤트 메타 리스트 */
  event_meta_list?: Maybe<PdpEventMetadataList>;
  /** 솔루션사 관리코드 */
  external_code?: Maybe<Scalars['String']>;
  /** 원본 상품 url (등록형 상품인 경우 null) */
  external_product_url?: Maybe<Scalars['String']>;
  /** 연동 상품의 기타 정보 */
  extra_description_list?: Maybe<Array<PdpExtraDescription>>;
  /** CMS 공지 배너 */
  extra_notice_banner?: Maybe<PdpProductExtraNoticeBanner>;
  /** 첫구매 유저 혜택 배너 */
  first_order_benefit_banner?: Maybe<PdpFirstOrderBenefitBanner>;
  /** 배송 타입 */
  fulfillment_type: PdpCatalogFulfillmentType;
  /** 쿠폰 할인 여부 */
  has_coupon: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 유저가 선택한 언어와 상품의 언어가 같은지 */
  is_language_match?: Maybe<Scalars['Boolean']>;
  /**
   * 샘플 상품 여부
   * @deprecated trait_list 필드 사용
   */
  is_sample: Scalars['Boolean'];
  /** 찜 상품 여부 */
  is_saved_product: Scalars['Boolean'];
  /** 썸네일 이미지 비율 ABT */
  is_thumbnail_ABT?: Maybe<Scalars['Boolean']>;
  /** 매칭상품과 위너 관계 */
  is_winner?: Maybe<Scalars['Boolean']>;
  /** z결제 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 상품의 품목 리스트 */
  item_list: Array<PdpCatalogItem>;
  /** 최저가 도전 툴팁 */
  lowest_price_tool_tip?: Maybe<PdpLowestPriceToolTip>;
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<PdpCatalogProductCategory>>;
  /** 직진배송에 매칭되는 상품 정보 */
  matching_catalog_product_info?: Maybe<PdpMetaCatalogProductInfo>;
  /** 최대구매수량 */
  maximum_order_quantity?: Maybe<Scalars['Int']>;
  /** 메타 옵션 링크 정보 */
  meta_catalog_product_info?: Maybe<PdpMetaCatalogProductInfo>;
  /** 최소구매수량 */
  minimum_order_quantity: Scalars['Int'];
  /** 최소구매수량타입 (PRODUCT, ITEM) */
  minimum_order_quantity_type?: Maybe<Scalars['String']>;
  /** 상품명 */
  name: Scalars['String'];
  /** 병행 수입 배너 이미지 URL */
  parallel_imported_banner_image_url?: Maybe<Scalars['String']>;
  /** 결제 타입 */
  payment_type: PdpCatalogPaymentType;
  /** PDP 이미지 배너 */
  pdp_image_rolling_banner?: Maybe<DisplayPdpRollingImageBanner>;
  /** 품절 임박 문구 */
  pre_sold_out_text?: Maybe<Scalars['String']>;
  /** 프리오더 정보 */
  preorder?: Maybe<PdpProductPreorder>;
  /** 상품 준비 기간 */
  preparation_period?: Maybe<Scalars['Int']>;
  /** 상품 품목 부가 속성 리스트 */
  product_additional_option_list?: Maybe<Array<PdpCatalogProductOption>>;
  /** 상품의 매핑 정보 */
  product_id_mapping?: Maybe<PdpCatalogProductIdMapping>;
  /** 상품의 이미지 리스트 */
  product_image_list: Array<PdpCatalogProductImage>;
  /** 상품의 이미지 비율 */
  product_image_ratio: Scalars['Float'];
  /** 상품 품목 부가 속성 리스트 */
  product_item_attribute_list: Array<PdpCatalogProductItemAttribute>;
  /** 상품 품목 부가 속성 리스트 */
  product_option_list: Array<PdpCatalogProductOption>;
  /** 상품의 가격 정보 */
  product_price: PdpCatalogProductPrice;
  /** 상품 타입 */
  product_type: PdpCatalogProductType;
  /** 상품 url */
  product_url: Scalars['String'];
  /** 상품 프로모션 정보 */
  promotion_info?: Maybe<PdpPromotionInfo>;
  /**
   * 프로모션 메세지. 배송기간 정보가 없을 때 해당 메세지를 보여준다
   * @deprecated PdpPromotionInfo.reward_message 필드로 대체
   */
  promotion_message: Scalars['String'];
  /** 신뢰도 정보 */
  reliability?: Maybe<PdpProductReliability>;
  /** 상품 판매 상태 */
  sales_status: PdpCatalogSalesStatus;
  /** 배송사 */
  shipping_company?: Maybe<PdpShippingCompany>;
  /** 배송비 */
  shipping_fee?: Maybe<PdpShippingFee>;
  /**
   * 배송비 타입
   * @deprecated shipping_fee 필드 사용 요청
   */
  shipping_fee_type: PdpCatalogShippingFeeType;
  /** 예측 배송기간 정보(from order-shipment service) */
  shipping_send_date?: Maybe<PdpShippingSendDate>;
  /** 상점 ID */
  shop_id: Scalars['Int'];
  /** 상점의 도메인 */
  shop_main_domain: Scalars['String'];
  /** 원본 상품 url with tracking param (등록형 상품인 경우 null) */
  source_product_url?: Maybe<Scalars['String']>;
  /** 상단 영역 알림 문구 */
  top_summary_notice_text?: Maybe<Scalars['String']>;
  /** 카페24 데이터 전송용 tracking url */
  tracking_product_url?: Maybe<Scalars['String']>;
  /** 상품의 특성관련 정보 */
  trait_list?: Maybe<Array<PdpCatalogProductTrait>>;
  /** 상품의 수정 일자 */
  updated_at?: Maybe<Scalars['CrTimestamp']>;
  /** Z-ONLY 상품 도착예정정보 */
  zonly_estimate_shipping_info?: Maybe<ZigzinEstimateShippingInfo>;
};

export type PdpCatalogProductAttribute = {
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 속성 값 */
  value_list: Array<Scalars['String']>;
};

export type PdpCatalogProductCategory = {
  /** category DB의 category id */
  category_id: Scalars['Int'];
  /** 카테고리 1차 또는 2차 또는 3차 또는 4차 */
  depth: Scalars['Int'];
  id: Scalars['ID'];
  /** 카테고리 키 */
  key: Scalars['String'];
  /** 카테고리 이름 */
  value: Scalars['String'];
};

export type PdpCatalogProductDetailPage = {
  /** 브랜드 홈 정보 */
  brand_home?: Maybe<PdpShop>;
  /** 입점형 내재화 상품정보 */
  catalog_product: PdpCatalogProduct;
  /** 연관된 에픽 정보 */
  epick_list: Array<PdpEpick>;
  /** 상품 피팅 모델 정보 */
  fitting_model_list?: Maybe<PdpProductFittingModelList>;
  /** PDP 노출 여부 판단 BOOLEAN 값 들 */
  flags: PdpFlags;
  /** 플로팅 배너 */
  floating_banner?: Maybe<PdpFloatingBanner>;
  /** PDP 전체적인 컨셉에 활용되는 Key Color */
  key_color: PdpKeyColor;
  /** 공지 정보 */
  notice?: Maybe<PdpNotice>;
  /** 정책 및 약관 */
  policy_list: Array<PdpZigzagPolicy>;
  /** 추천탭 라벨 */
  recommendation_tab_label: Scalars['String'];
  /**
   * 연관상품 추천 컴포넌트.
   * PdpTextTitle + PdpGoodsCarousel + PdpTextTitle + PdpGoodsCarousel + ... 형식으로 구성
   * 추후에 뷰가 어떻게 바뀔지 알 수 없기에 PdpComponent 리스트로 처리.
   */
  related_item_list: Array<PdpComponent>;
  /** 쇼핑몰 정보 */
  shop: PdpShop;
  /** 사이즈 추천 정보 */
  size_recommendation?: Maybe<PdpProductSize>;
  /**
   * PDP 내에서 유저의 혜택 정보
   * @deprecated 더 이상 제공되지 않음. 하위 필드마다 deprecated 사유 확인 요청
   */
  user_benefit?: Maybe<PdpUserBenefit>;
};

export type PdpCatalogProductFinalPriceDiscountInfo = {
  /** 할인률 (max_price 기준으로 final_price 를 나누어 할인율이 계산됩니다. 다만 final_price_highlight 값이 존재할 경우 final_price 보다 먼저 선택되어 계산됩니다.) */
  discount_rate: Scalars['Int'];
  /** 할인률 color */
  discount_rate_color: UxCommonColor;
  /** 노출 최종가 */
  final_price: PdpCatalogProductPriceInfo;
  /** 노출 최종가 (3중가 표기시 활용) */
  final_price_additional?: Maybe<PdpCatalogProductPriceInfo>;
};

export type PdpCatalogProductIdMapping = {
  /** Goods ID */
  goods_id?: Maybe<Scalars['Int']>;
  /** 기존 Product ID */
  legacy_product_id?: Maybe<Scalars['Int']>;
};

export type PdpCatalogProductImage = {
  id?: Maybe<Scalars['ID']>;
  /** 이미지 타입 */
  image_type: PdpCatalogProductImageType;
  /** 원본 이미지 height */
  origin_height?: Maybe<Scalars['Int']>;
  /** 원본 이미지 URL(도메인 포함) */
  origin_url: Scalars['String'];
  /** 원본 이미지 width */
  origin_width?: Maybe<Scalars['Int']>;
  /** pdp 저화질 이미지 URL */
  pdp_low_quality_url?: Maybe<Scalars['String']>;
  /** pdp static image url. gif 이미지 로딩속도 개선을 위함 */
  pdp_static_image_url?: Maybe<Scalars['String']>;
  /** pdp 썸네일 URL(도메인 포함) */
  pdp_thumbnail_url?: Maybe<Scalars['String']>;
  /** plp 썸네일 URL(도메인 포함) */
  plp_thumbnail_url?: Maybe<Scalars['String']>;
  /** 이미지 URL */
  url?: Maybe<Scalars['String']>;
};

export enum PdpCatalogProductImageType {
  CONTENT = 'CONTENT',
  MAIN = 'MAIN',
  SUB = 'SUB',
}

export type PdpCatalogProductItemAttribute = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 순서 */
  order: Scalars['Int'];
  /** 필수여부 */
  required: Scalars['Boolean'];
  /** 속성 값 */
  value_list: Array<PdpCatalogProductItemAttributeValue>;
};

export type PdpCatalogProductItemAttributeValue = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소(키값) */
  image_key?: Maybe<Scalars['String']>;
  /** 이미지 전체 주소 */
  image_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
  /** 이미지 전체 주소(webp 형식) */
  webp_image_url?: Maybe<Scalars['String']>;
};

export type PdpCatalogProductOption = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 옵션 종류  (BASIC, ADDITIONAL) */
  option_type?: Maybe<Scalars['String']>;
  /** 순서 */
  order: Scalars['Int'];
  /** 필수여부 */
  required: Scalars['Boolean'];
  /** 속성 값 */
  value_list: Array<PdpCatalogProductOptionValue>;
};

export type PdpCatalogProductOptionValue = {
  /** 추가금액 */
  additional_amount?: Maybe<Scalars['Int']>;
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소 */
  image_key?: Maybe<Scalars['String']>;
  /** 정적 이미지 URL(jpeg type) */
  jpeg_url?: Maybe<Scalars['String']>;
  /** 상품 속성 값 순서 */
  order?: Maybe<Scalars['Int']>;
  /** 정적 이미지 URL(webp type) */
  static_url?: Maybe<Scalars['String']>;
  /** 썸네일 URL */
  thumbnail_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
};

export type PdpCatalogProductPrice = {
  /** BOGO 프로모션 할인 정보 */
  bogo_promotion_discount_info?: Maybe<PdpCatalogProductPriceDiscountInfo>;
  /**
   * 쿠폰 할인양
   * @deprecated coupon_discount_info.discount_amount 사용
   */
  coupon_discount_amount?: Maybe<Scalars['Int']>;
  /**
   * 쿠폰 할인양 with 통화
   * @deprecated coupon_discount_info.coupon_discount_amount_with_currency 사용
   */
  coupon_discount_amount_with_currency?: Maybe<PdpPriceWithCurrency>;
  /** 쿠폰 할인 정보 */
  coupon_discount_info?: Maybe<PdpCatalogProductPriceCouponDiscountInfo>;
  /**
   * 할인양
   * @deprecated 구체적인 목적에 따라 final_discount_info, store_discount_info 등 사용 요청
   */
  discount_amount: Scalars['Int'];
  /**
   * 할인가
   * @deprecated 구체적인 목적에 따라 final_discount_info, store_discount_info 등 사용 요청
   */
  discount_price: Scalars['Int'];
  /**
   * 할인률
   * @deprecated 구체적인 목적에 따라 final_discount_info, store_discount_info 등 사용 요청
   */
  discount_rate: Scalars['Int'];
  /** atf 노출용 최종가 정보 */
  display_final_price: PdpCatalogProductFinalPriceDiscountInfo;
  /** 최종 할인 정보 */
  final_discount_info: PdpCatalogProductPriceDiscountInfo;
  /**
   * 최종 가격
   * @deprecated 일반적인 경우 final_discount_info 사용 요청. PDP ATF 노출 목적으로는 display_final_price 사용 요청.
   */
  final_price: Scalars['Int'];
  /**
   * 최종 가격 with 통화
   * @deprecated 일반적인 경우 final_discount_info 사용 요청. PDP ATF 노출 목적으로는 display_final_price 사용 요청.
   */
  final_price_with_currency: PdpPriceWithCurrency;
  /** 첫구매가 혜택 */
  first_order_discount?: Maybe<PdpFirstOrderDiscount>;
  /** 쿠폰 할인가 유무 */
  has_coupon: Scalars['Boolean'];
  /** 할인가 자세히 보기 버튼 노출 여부 */
  is_visible_discount_detail_button: Scalars['Boolean'];
  /**
   * 최대 가격
   * @deprecated max_price_info 사용 요청
   */
  max_price: Scalars['Int'];
  /** 노출 원가 정보 */
  max_price_info: PdpCatalogProductPriceInfo;
  /**
   * 최대 가격 with 통화
   * @deprecated max_price_info 사용 요청
   */
  max_price_with_currency: PdpPriceWithCurrency;
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 정상가 with 통화 */
  original_price_with_currency: PdpPriceWithCurrency;
  /** 지그재그 추가 할인 정보(구. 제트할인가) */
  product_promotion_discount_info?: Maybe<PdpCatalogProductPriceDiscountInfo>;
  /**
   * 자사몰 할인 정보(=셀러측 할인) - deprecated
   * @deprecated store_discount_info 사용 요청
   */
  seller_discount_info: PdpCatalogProductPriceDiscountInfo;
  /** 자사몰 할인 정보(=셀러측 할인) */
  store_discount_info?: Maybe<PdpCatalogProductPriceDiscountInfo>;
  /**
   * z결제 할인양
   * @deprecated product_promotion_discount_info 사용 요청
   */
  zpay_discount_amount?: Maybe<Scalars['Int']>;
  /**
   * z결제 할인양 with 통화
   * @deprecated product_promotion_discount_info 사용 요청
   */
  zpay_discount_amount_with_currency?: Maybe<PdpPriceWithCurrency>;
};

export type PdpCatalogProductPriceCouponDiscountInfo = {
  /** 할인양 */
  discount_amount: Scalars['Int'];
  /** 할인 금액 (정액 쿠폰의 할인 금액) */
  discount_amount_of_amount_coupon?: Maybe<Scalars['Int']>;
  /** 할인양 with 통화 */
  discount_amount_with_currency: PdpPriceWithCurrency;
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 할인가 with 통화 */
  discount_price_with_currency: PdpPriceWithCurrency;
  /** 할인률 (전시될 할인률 max_price 를 기준으로 할인율이 계산됩니다.) */
  discount_rate: Scalars['Int'];
  /** 할인률 (정률 쿠폰의 할인률) */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 할인 타입 */
  discount_type: PdpCouponDiscountType;
  /** 최대 할인 금액 */
  max_discount_amount?: Maybe<Scalars['Int']>;
  /** 최소 주문 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 타겟 타입 (ORDER, SHOP, SHOP_WITH_ZONLY, SHOP_GROUP, SHOP_GROUP_WITH_ZONLY, ORDER_ITEM, ORDER_ITEM_GROUP, CATEGORY, DEPARTMENT, SHIPPING_FEE, POINT) */
  target_type?: Maybe<Scalars['String']>;
};

export type PdpCatalogProductPriceDiscountInfo = {
  /** 할인양 */
  discount_amount: Scalars['Int'];
  /** 할인양 with 통화 */
  discount_amount_with_currency: PdpPriceWithCurrency;
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 할인가 color */
  discount_price_color: UxCommonColor;
  /** 할인가 with 통화 */
  discount_price_with_currency: PdpPriceWithCurrency;
  /** 할인률 (max_price 를 기준으로 할인율이 계산됩니다.) */
  discount_rate: Scalars['Int'];
  /** 할인률 color */
  discount_rate_color: UxCommonColor;
};

export type PdpCatalogProductPriceInfo = {
  /** 가격 뱃지 (ex. 쿠폰할인가, 첫구매가, 직잭위크 등 CMS 정보 등) */
  badge?: Maybe<UxCommonText>;
  /** color */
  color: UxCommonColor;
  /** 가격 */
  price: Scalars['Int'];
  /** 가격 with 통화 */
  price_with_currency: PdpPriceWithCurrency;
};

export type PdpCatalogProductSize = {
  image_url?: Maybe<Scalars['String']>;
  value_list: Array<Array<Scalars['String']>>;
};

export type PdpCatalogProductSizeList = {
  item_list: Array<PdpCatalogProductSize>;
};

export type PdpCatalogProductTrait = {
  /** trait type : EXCLUSIVE, SAMPLE, RAFFLE */
  type?: Maybe<Scalars['String']>;
};

export enum PdpCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** ZONLY 상품 */
  ZONLY = 'ZONLY',
}

export enum PdpCatalogSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export enum PdpCatalogShippingFeeType {
  /** 무료 배송 */
  FREE_SHIPPING = 'FREE_SHIPPING',
  /** SHOP의 배송정책을 따름 */
  SHOP_POLICY = 'SHOP_POLICY',
}

export type PdpCategory = PdpComponent & {
  filter_item_list: Array<PdpFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
  ux_category_style?: Maybe<PdpCategoryStyle>;
};

/** 카테고리 정보 */
export type PdpCategoryInfo = {
  ID: Scalars['ID'];
  /** 카테고리 아이콘 URL */
  icon_url: Scalars['String'];
  /** 카테고리 명 */
  name: Scalars['String'];
  /** 하위 카테고리 정보 */
  sub_category_list: Array<PdpSubCategoryInfo>;
};

/** 카테고리 목록 */
export type PdpCategoryInfoList = {
  /** 하위 카테고리 정보 */
  item_list: Array<PdpCategoryInfo>;
};

export enum PdpCategoryStyle {
  CATEGORY_ROUNDED = 'CATEGORY_ROUNDED',
  CATEGORY_TEXT = 'CATEGORY_TEXT',
}

export type PdpCheckButtonAndSorting = PdpComponent & {
  check_button_item_list: Array<PdpFilterItem>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  sorting_item_list: Array<PdpFilterItem>;
  type: PdpComponentType;
};

export type PdpClpCategory = {
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  sub_category_list?: Maybe<Array<PdpClpSubCategory>>;
};

export type PdpClpCategoryList = PdpComponent & {
  category_list?: Maybe<Array<PdpClpCategory>>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpClpSubCategory = {
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type PdpCmParam = {
  auxiliaryPercent?: Maybe<Scalars['Int']>;
  awsPercent?: Maybe<Scalars['Int']>;
  cardMaxCount?: Maybe<Scalars['Int']>;
  contentInfo?: Maybe<PdpCmParamContentInfo>;
  defaultColumn?: Maybe<Scalars['Int']>;
  imageRatio?: Maybe<Scalars['Float']>;
  isBlockProductList?: Maybe<Scalars['Boolean']>;
  isRecommendationShuffle?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  prefixItemCount?: Maybe<Scalars['Int']>;
  prefixZoneCount?: Maybe<Scalars['Int']>;
  prefixZoneSize?: Maybe<Scalars['Int']>;
  recommendationOrder?: Maybe<Array<PdpRecommendationType>>;
  size?: Maybe<Scalars['Int']>;
  sortTitle?: Maybe<PdpCmParamSortTitle>;
  specialColumn?: Maybe<Array<PdpCmParamColumnPosition>>;
  titleInfo?: Maybe<PdpCmParamTitleInfo>;
};

export type PdpCmParamColumnPosition = {
  column: Scalars['Int'];
  position: Scalars['Int'];
};

export type PdpCmParamContentInfo = {
  itemLimit?: Maybe<Scalars['Int']>;
  primaryText: Scalars['String'];
  secondaryText?: Maybe<Scalars['String']>;
  targetIdList?: Maybe<Array<Scalars['String']>>;
};

export type PdpCmParamSortTitle = {
  desc: Scalars['String'];
  name: Scalars['String'];
};

export type PdpCmParamTitleInfo = {
  desc: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleTemplate: Scalars['String'];
};

export type PdpCommonBanner = {
  /** 배너 노출 문구 */
  content: UxCommonText;
  /** 배너 이미지 url */
  image_url?: Maybe<Scalars['String']>;
  /** 배너 랜딩 url */
  landing_url?: Maybe<Scalars['String']>;
  /** 배너 노출 문구 */
  title: UxCommonText;
};

export type PdpCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type PdpCommonHtmlText = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

/** 이미지 */
export type PdpCommonImage = {
  url: PdpCommonImageUrl;
  webp_url?: Maybe<PdpCommonImageUrl>;
};

/** 이미지 비율 */
export type PdpCommonImageRatio = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

/** 이미지 URL */
export type PdpCommonImageUrl = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

/** 텍스트 */
export type PdpCommonText = {
  color?: Maybe<PdpCommonTextColor>;
  html?: Maybe<PdpCommonHtmlText>;
  text: Scalars['String'];
};

/** 텍스트 Color */
export type PdpCommonTextColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type PdpComponent = {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpComponentMethod = {
  componentType?: Maybe<PdpComponentType>;
  id: Scalars['ID'];
  methodType?: Maybe<PdpMethodType>;
  params?: Maybe<PdpCmParam>;
};

export enum PdpComponentType {
  UX_AD_DISPLAY_BANNER_GROUP = 'UX_AD_DISPLAY_BANNER_GROUP',
  UX_AD_DISPLAY_LARGE_BANNER = 'UX_AD_DISPLAY_LARGE_BANNER',
  UX_AD_DISPLAY_MID_BANNER = 'UX_AD_DISPLAY_MID_BANNER',
  UX_AD_DISPLAY_SMALL_BANNER = 'UX_AD_DISPLAY_SMALL_BANNER',
  UX_AD_DISPLAY_X_LARGE_BANNER = 'UX_AD_DISPLAY_X_LARGE_BANNER',
  UX_AD_DISPLAY_X_MID_BANNER = 'UX_AD_DISPLAY_X_MID_BANNER',
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BRAND_THEME_CONTENT = 'UX_BRAND_THEME_CONTENT',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_CHECK_BUTTON_AND_SORTING = 'UX_CHECK_BUTTON_AND_SORTING',
  UX_CLP_CATEGORY = 'UX_CLP_CATEGORY',
  UX_ENTRY_BANNER = 'UX_ENTRY_BANNER',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_FILTER_LIST = 'UX_GOODS_FILTER_LIST',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_IMAGE_GOODS_CARD_ITEM = 'UX_IMAGE_GOODS_CARD_ITEM',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_MULTILINE_RANKING_CAROUSEL = 'UX_MULTILINE_RANKING_CAROUSEL',
  UX_NO_RESULTS = 'UX_NO_RESULTS',
  UX_PDP_RECOMMEND_CARD_GROUP = 'UX_PDP_RECOMMEND_CARD_GROUP',
  UX_PDP_RECOMMEND_GROUP = 'UX_PDP_RECOMMEND_GROUP',
  UX_QUICK_MENU = 'UX_QUICK_MENU',
  UX_SEARCHED_SHOP_CAROUSEL = 'UX_SEARCHED_SHOP_CAROUSEL',
  UX_SEGMENT_TAB = 'UX_SEGMENT_TAB',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_EXPANDABLE_LIST = 'UX_SHOP_EXPANDABLE_LIST',
  UX_SHOP_GROUP = 'UX_SHOP_GROUP',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SMALL_TEXT_TITLE = 'UX_SMALL_TEXT_TITLE',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_MORE_BUTTON = 'UX_TEXT_AND_MORE_BUTTON',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

export enum PdpCouponAvailableStatus {
  /** 유저가 받을 수 있는 쿠폰이 있음 */
  COUPON_AVAILABLE = 'COUPON_AVAILABLE',
  /** 발급받을 수 있는 쿠폰을 모두 받음 */
  COUPON_ISSUED = 'COUPON_ISSUED',
  /** 쿠폰 적용 불가 상품 */
  COUPON_NOT_APPLICABLE = 'COUPON_NOT_APPLICABLE',
  /** 받을 수 있는 쿠폰이 없음 */
  COUPON_UNAVAILABLE = 'COUPON_UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum PdpCouponDiscountType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export enum PdpCouponRegistrationType {
  BOOKMARK = 'BOOKMARK',
  MEMBERSHIP = 'MEMBERSHIP',
}

export type PdpCrossSellerProduct = {
  /** 브라우저 타입 */
  browsing_type?: Maybe<PdpBrowsingType>;
  /** 배송 타입 뱃지, 일반 배송인 경우 null */
  delivery_badge?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image_url?: Maybe<Scalars['String']>;
  is_current_product: Scalars['Boolean'];
  price: Scalars['Int'];
  product_url: Scalars['String'];
  /** 상품이 위너인 경우 추천 뱃지 전달 */
  recommend_badge?: Maybe<Scalars['String']>;
  review_count?: Maybe<Scalars['Int']>;
  review_score?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['String']>;
  shop_name: Scalars['String'];
  shop_product_no?: Maybe<Scalars['String']>;
};

export type PdpDailyShippingProbability = {
  day: Scalars['Int'];
  probability: Scalars['Float'];
};

export type PdpDescriptionTopBanner = {
  image_url?: Maybe<Scalars['String']>;
  landing_url?: Maybe<Scalars['String']>;
};

export type PdpEntryBanner = PdpComponent & {
  deeplink: Scalars['String'];
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  lottie_json?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: PdpComponentType;
};

export type PdpEpick = {
  id: Scalars['ID'];
  /** 게시글 이미지 */
  image_url: Scalars['String'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 에픽 유저 닉네임 */
  nickname: Scalars['String'];
};

export type PdpEstimatedShippingDate = {
  estimate_list?: Maybe<Array<PdpDailyShippingProbability>>;
  repr_estimate?: Maybe<PdpDailyShippingProbability>;
};

/** 이벤트 정보 */
export type PdpEvent = {
  /** 이벤트 이름 */
  name: Scalars['String'];
};

export type PdpEventDisplayPopup = {
  /** 버튼 */
  button_list: Array<PdpEventDisplayPopupButton>;
  /** 내용 */
  content?: Maybe<UxCommonText>;
  /** 이벤트 타입 NAVER_EP_GUIDE, NAVER_EP_CONGRATS, NAVER_EP_SUCCESS */
  event_popup_type?: Maybe<Scalars['String']>;
  /** 타이틀 */
  title: UxCommonText;
  /** ubl */
  ubl?: Maybe<UxUbl>;
};

export type PdpEventDisplayPopupButton = {
  /** 팝업 버튼 정보 */
  button: UxCommonButton;
  /** 이벤트 메타 ID */
  event_metadata_uuid_list: Array<Scalars['String']>;
};

export type PdpEventMetadataList = {
  /** 진행 중인 이벤트 목록 */
  item_list: Array<PdpEventDisplayPopup>;
  /** 진행 중인 이벤트의 총 개수 */
  total_count: Scalars['Int'];
};

export type PdpExtraDescription = {
  /** 연동필드 구분자 */
  key?: Maybe<Scalars['String']>;
  /** 연동필드(ex. html 코드 형태) */
  value?: Maybe<Scalars['String']>;
};

export type PdpFastShipping = {
  /** 빠른 배송 여부 */
  is_fast_type: Scalars['Boolean'];
  /** 빠른 배송 알림 배너 */
  notice_banner?: Maybe<PdpFastShippingBanner>;
  /** 빠른 배송 유형 (ex. 판매자 지정, 배송 예측) */
  type_text: Scalars['String'];
};

export type PdpFastShippingBanner = {
  /** 배너 이미지 주소 */
  image_url: Scalars['String'];
  /** 배너 클릭시 연결 링크 */
  landing_url?: Maybe<Scalars['String']>;
};

export type PdpFilterItem = {
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  str_id: Scalars['ID'];
};

export type PdpFirstOrderBenefitBanner = {
  /** 배경 색 */
  background_color?: Maybe<Scalars['String']>;
  /** 랜딩 url 텍스트 */
  guide_text?: Maybe<Scalars['String']>;
  /** 배너 랜딩 url */
  landing_url?: Maybe<Scalars['String']>;
  /** 배너 노출 문구 */
  title: Scalars['String'];
};

export type PdpFirstOrderDiscount = {
  discount_amount: Scalars['Int'];
  /** 할인 금액 (첫구매 할인 금액) */
  discount_amount_of_amount_coupon?: Maybe<Scalars['Int']>;
  /** 할인률 (첫구매 할인률) */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 할인 타입 */
  discount_type: PdpCouponDiscountType;
  guidance_image_url?: Maybe<Scalars['String']>;
  min_required_amount: Scalars['Int'];
  price: Scalars['Int'];
  promotion_id: Scalars['String'];
};

export type PdpFlags = {
  /** PDP 매칭 옵션 상품 적용 여부 */
  is_matching_option?: Maybe<Scalars['Boolean']>;
  /** PDP 가격 노출 개선된 ui */
  is_new_price_ui?: Maybe<Scalars['Boolean']>;
  /** 당일/새벽배송 유료화 프로젝트 M2 UI 적용 여부 */
  is_new_zigzin_ui?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 상품 카드 개편 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  /** 단수상품(한번에 하나만 구매할 수 있는 상품) */
  is_purchase_only_one_at_time?: Maybe<Scalars['Boolean']>;
  /** 셀렉티드 상품인지 아닌지 */
  is_selected_shop?: Maybe<Scalars['Boolean']>;
  /** PDP 셀러존 노출 여부 */
  is_seller_zone?: Maybe<Scalars['Boolean']>;
  show_review_summary_best_carousel: Scalars['Boolean'];
};

export type PdpFloatBannerInfo = {
  /** 배너 배경 컬러 */
  background_color: UxCommonColor;
  /** 배너 텍스트 */
  banner_text: UxCommonText;
  /** 배너 노출 시 CTA 버튼에 적용하게될 추가적인 문구 */
  cta_description_text?: Maybe<UxCommonText>;
  /** 배너 노출 시 CTA 버튼에 적용하게될 divider 색상 */
  cta_divider_color?: Maybe<UxCommonColor>;
  /**
   * 차액
   * @deprecated banner_text 필드 사용 요청
   */
  diff_amount_text?: Maybe<Scalars['String']>;
  /** 남은 시간 */
  end_time?: Maybe<Scalars['CrTimestamp']>;
  /**
   * 아이콘
   * @deprecated icon_url 필드 사용 요청
   */
  icon?: Maybe<Scalars['String']>;
  /** icon url */
  icon_url?: Maybe<UxCommonImageUrl>;
  /**
   * 텍스트
   * @deprecated banner_text 필드 사용 요청
   */
  text?: Maybe<Scalars['String']>;
};

export type PdpFloatingBanner = {
  /** 클릭시 연결되는 deep link url */
  deeplink_url?: Maybe<Scalars['String']>;
  /** 배너 내용 앞쪽에 위치시킬 아이콘 유형 */
  icon_type?: Maybe<PdpFloatingBannerIconType>;
  /** 클릭시 연결되는 url */
  landing_url?: Maybe<Scalars['String']>;
  /** 배너 내용 */
  text: Scalars['String'];
};

export enum PdpFloatingBannerIconType {
  ZIGZIN = 'ZIGZIN',
}

export type PdpFreeShippingBannerInfo = {
  background_color?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  theme: PdpFreeShippingBannerInfoTheme;
};

export enum PdpFreeShippingBannerInfoTheme {
  DARK = 'DARK',
  NORMAL = 'NORMAL',
}

export type PdpGoods = {
  backup_image_url: Scalars['String'];
  category_list: Array<Scalars['String']>;
  color_image_list: Array<PdpGoodsColorImage>;
  date_created: Scalars['Float'];
  date_deleted?: Maybe<Scalars['Float']>;
  date_last_in_main: Scalars['Float'];
  /** 상세화면을 위한 디테일 정보. 필드가 요청될 시에만 내부적으로 goods_detail 쿼리를 호출한다. */
  detail?: Maybe<PdpGoodsDetail>;
  /** 외부 솔루션의 상품 업데이트 시각 */
  external_date_updated?: Maybe<Scalars['Float']>;
  free_shipping: Scalars['Boolean'];
  id: Scalars['ID'];
  image_height?: Maybe<Scalars['Int']>;
  image_url: Scalars['String'];
  image_width?: Maybe<Scalars['Int']>;
  original_image_url: Scalars['String'];
  product_no: Scalars['String'];
  properties?: Maybe<PdpGoodsProperties>;
  /** 상품 점수 상세 내역 */
  raw_score?: Maybe<Scalars['String']>;
  /** 상품 점수 */
  score?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  status: PdpGoodsStatus;
  style_list: Array<PdpGoodsStyle>;
  thumbnail_image_url: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  webp_image_url: Scalars['String'];
  /** zpay 상품인지 여부 */
  zpay: Scalars['Boolean'];
  zpay_status?: Maybe<PdpGoodsZpayStatus>;
};

export type PdpGoodsAgeFilterOption = {
  age: Scalars['String'];
  count: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type PdpGoodsAgeFilterOptionList = PdpGoodsFilterOption & {
  age_option_list: Array<PdpGoodsAgeFilterOption>;
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsAttributeFilterOption = {
  attribute_item_option_list: Array<PdpGoodsAttributeItemFilterOption>;
  /** 속성 키 (ex. length, fit, material 등) */
  key: Scalars['String'];
  name: Scalars['String'];
};

export type PdpGoodsAttributeFilterOptionList = PdpGoodsFilterOption & {
  attribute_option_list: Array<PdpGoodsAttributeFilterOption>;
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsAttributeItemFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type PdpGoodsBrandFilterOption = PdpGoodsFilterOption & {
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['CrTimestamp']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type PdpGoodsCardItem = PdpComponent & {
  /** 광고 id */
  aid?: Maybe<Scalars['String']>;
  /** 뱃지 리스트 */
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: Scalars['Boolean'];
  /** 브랜드 여부 */
  brand_id?: Maybe<Scalars['String']>;
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge?: Maybe<Scalars['Boolean']>;
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 */
  browsing_type: PdpBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  /** catalog_product_id */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 상품의 type */
  catalog_product_type?: Maybe<PdpCatalogProductType>;
  /** 상품 옵션 컬러칩 리스트 */
  color_option_list?: Maybe<Array<UxGoodsCardColorChip>>;
  /** 상품이 한줄에 몇개 표기할지 */
  column_count?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인양 */
  coupon_discount_amount?: Maybe<Scalars['Int']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<PdpGoodsCardDiscountInfo>;
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 갯수 (#,### 형태) */
  display_review_count?: Maybe<Scalars['String']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 모든 할인이 적용된 최종 가격 */
  final_price_with_currency: PdpPriceWithCurrency;
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** goods_id */
  goods_id?: Maybe<Scalars['String']>;
  /** 쿠폰 사용 여부 */
  has_coupon: Scalars['Boolean'];
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 이미지 비율 */
  image_ratio?: Maybe<Scalars['Float']>;
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 여부 */
  is_ad: Scalars['Boolean'];
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  /** 단독 상품 뱃지 여부 */
  is_exclusive: Scalars['Boolean'];
  /** 신상품 표기 */
  is_new?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  /** 찜 상품 여부 */
  is_saved_product: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 이미지 경량화(still) jpeg_image_url */
  jpeg_image_url?: Maybe<Scalars['String']>;
  /** log */
  log?: Maybe<Scalars['String']>;
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<PdpCatalogProductCategory>>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /** 연동된 가격중 가장 높은 가격 */
  max_price_with_currency: PdpPriceWithCurrency;
  /** 가로형 상품 카드용 신규 뱃지 */
  metadata_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 당일 배송 */
  one_day_delivery?: Maybe<PdpCommonText>;
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id?: Maybe<Scalars['String']>;
  /** 정상가 */
  original_price?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price?: Maybe<Scalars['Int']>;
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price_with_currency?: Maybe<PdpPriceWithCurrency>;
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** 상품 카드에 숫자를 표기하기 위한 필드 */
  ranking?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 점수 */
  review_score?: Maybe<Scalars['Float']>;
  /** Catalog 상품의 상태 (판매중, 품절) */
  sales_status?: Maybe<PdpSearchedProductSalesStatus>;
  /** 판매 여부 */
  sellable_status: PdpSellableStatus;
  /** shop 정보 */
  shop?: Maybe<PdpGoodsCardShopInfo>;
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name?: Maybe<Scalars['String']>;
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** 비슷한 상품 검색 여부 */
  similar_search?: Maybe<Scalars['Boolean']>;
  /** Goods 상품의 상태 (판매중, 품절) */
  status?: Maybe<PdpGoodsStatus>;
  /** 상품 재고, 쵀대 50개의 상품목록을 요청할 때 사용 가능합니다. 재고 수량은 1분간 캐싱 됩니다. */
  stock?: Maybe<Scalars['Int']>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 컴포넌트 type */
  type: PdpComponentType;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid?: Maybe<Scalars['String']>;
  /** 상품 카드에 비디오 형식으로 이미지 노출 (광고팀에서 사용) */
  video_url?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price?: Maybe<Scalars['Int']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price_with_currency?: Maybe<PdpPriceWithCurrency>;
};

export type PdpGoodsCardList = PdpComponent & {
  columns_count?: Maybe<Scalars['Int']>;
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  item_list: Array<PdpComponent>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpGoodsCardShopInfo = {
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['Int'];
  zpay_status?: Maybe<Scalars['Int']>;
};

export type PdpGoodsCarousel = PdpComponent & {
  /** 상품 카드 컴포넌트 리스트 */
  component_list: Array<PdpGoodsCardItem>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 컬럼 수 */
  item_column_count?: Maybe<Scalars['Float']>;
  /** 캐로셀 라인 수 */
  line_count?: Maybe<Scalars['Int']>;
  /** 더보기 버튼 */
  more_button?: Maybe<PdpButton>;
  position: Scalars['Int'];
  /** GoodsCarousel 스타일 */
  style?: Maybe<PdpGoodsCarouselStyle>;
  type: PdpComponentType;
};

/** PdpGoodsCarousel 스타일 */
export enum PdpGoodsCarouselStyle {
  DEFAULT = 'DEFAULT',
  WITHOUT_SHOP_NAME = 'WITHOUT_SHOP_NAME',
}

export type PdpGoodsCategoryFilterOption = {
  count: Scalars['Int'];
  id: Scalars['ID'];
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  properties: Scalars['Boolean'];
  selected: Scalars['Boolean'];
  sub_category_list: Array<PdpGoodsCategoryFilterOption>;
};

export type PdpGoodsCategoryFilterOptionList = PdpGoodsFilterOption & {
  category_option_list: Array<PdpGoodsCategoryFilterOption>;
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsColorFilterOption = {
  count: Scalars['Int'];
  image_url: Scalars['String'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  text_color: Scalars['Int'];
};

export type PdpGoodsColorFilterOptionList = PdpGoodsFilterOption & {
  color_option_list: Array<PdpGoodsColorFilterOption>;
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsColorImage = {
  color_list: Array<Scalars['String']>;
  id: Scalars['ID'];
  image_height?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  image_width?: Maybe<Scalars['Int']>;
  is_main: Scalars['Boolean'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type PdpGoodsDetail = {
  /** 상세 화면 추가이미지 */
  additional_image_list?: Maybe<Array<Scalars['String']>>;
  /** 상품 카테고리 리스트 */
  category_list?: Maybe<Array<PdpGoodsDetailCategoryResult>>;
  /** 상품 상세정보 */
  description?: Maybe<Scalars['String']>;
  /** 예측 배송기간 정보 */
  estimated_shipping_date?: Maybe<PdpEstimatedShippingDate>;
  /** 교환 정보 */
  exchange_info?: Maybe<Scalars['String']>;
  /** 상품 아이디 */
  goods_id: Scalars['ID'];
  /** 결제 정보 */
  payment_info?: Maybe<Scalars['String']>;
  /** 프로모션 메세지. 배송기간 정보가 없을 때 해당 메세지를 보여준다 */
  promotion_message: Scalars['String'];
  /** CS 등 서비스 정보 */
  service_info?: Maybe<Scalars['String']>;
  /** 배송 정보 */
  shipping_info?: Maybe<Scalars['String']>;
};

export type PdpGoodsDetailCategoryResult = {
  depth?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  no: Scalars['String'];
};

export type PdpGoodsFilter = {
  key: Scalars['String'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  selected_count: Scalars['Int'];
};

export type PdpGoodsFilterList = PdpComponent & {
  filter_list: Array<PdpGoodsFilter>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpGoodsFilterOption = {
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsFilterOptionList = {
  item_list: Array<PdpGoodsFilterOption>;
};

export enum PdpGoodsFilterOptionType {
  UX_GOODS_AGE_FILTER_OPTION_LIST = 'UX_GOODS_AGE_FILTER_OPTION_LIST',
  UX_GOODS_ATTRIBUTE_FILTER_OPTION_LIST = 'UX_GOODS_ATTRIBUTE_FILTER_OPTION_LIST',
  UX_GOODS_BRAND_FILTER_OPTION = 'UX_GOODS_BRAND_FILTER_OPTION',
  UX_GOODS_CATEGORY_FILTER_OPTION_LIST = 'UX_GOODS_CATEGORY_FILTER_OPTION_LIST',
  UX_GOODS_COLOR_FILTER_OPTION_LIST = 'UX_GOODS_COLOR_FILTER_OPTION_LIST',
  UX_GOODS_MODEL_HEIGHT_FILTER_OPTION = 'UX_GOODS_MODEL_HEIGHT_FILTER_OPTION',
  UX_GOODS_PRICE_DISTRIBUTION_FILTER_OPTION = 'UX_GOODS_PRICE_DISTRIBUTION_FILTER_OPTION',
  UX_GOODS_PRODUCT_TYPE_FILTER_OPTION_LIST = 'UX_GOODS_PRODUCT_TYPE_FILTER_OPTION_LIST',
  UX_GOODS_PROPERTY_FILTER_OPTION_LIST = 'UX_GOODS_PROPERTY_FILTER_OPTION_LIST',
  UX_GOODS_STYLE_FILTER_OPTION_LIST = 'UX_GOODS_STYLE_FILTER_OPTION_LIST',
}

/** 상품 묶음 컴포넌트 */
export type PdpGoodsGroup = PdpComponent & {
  /** 액션 버튼 */
  action_button?: Maybe<PdpButton>;
  /** 상품 캐로셀 */
  goods_carousel: PdpGoodsCarousel;
  /** 사용 목적 타입 */
  group_type: Scalars['String'];
  /** TC ID 또는 추천 타입 ID */
  id: Scalars['ID'];
  /** 메인 이미지 */
  image?: Maybe<PdpImage>;
  /** 광고 캐러셀 여부 */
  is_ad: Scalars['Boolean'];
  /** 메인 타이틀 */
  main_title: PdpText;
  /** 더보기 버튼 */
  more_button?: Maybe<PdpButton>;
  position: Scalars['Int'];
  /** 서브 타이틀 */
  sub_title?: Maybe<PdpText>;
  type: PdpComponentType;
};

export type PdpGoodsModelHeightFilterOption = PdpGoodsFilterOption & {
  /** 키 필터 간격 */
  interval: Scalars['Int'];
  /** 모델의 최대 키 */
  max_height: Scalars['Float'];
  /** 선택된 모델 키 */
  max_height_selected?: Maybe<Scalars['Float']>;
  /** 모델의 최소 키 */
  min_height: Scalars['Float'];
  /** 선택된 모델 키 */
  min_height_selected?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** UX_GOODS_MODEL_HEIGHT_FILTER_OPTION */
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsPriceDistributionFilterOption = PdpGoodsFilterOption & {
  count_list: Array<Scalars['Int']>;
  interval: Scalars['Int'];
  interval_with_currency: PdpPriceWithCurrency;
  max: Scalars['Int'];
  max_selected?: Maybe<Scalars['Int']>;
  max_with_currency: PdpPriceWithCurrency;
  min: Scalars['Int'];
  min_selected?: Maybe<Scalars['Int']>;
  min_with_currency: PdpPriceWithCurrency;
  name: Scalars['String'];
  type: PdpGoodsFilterOptionType;
};

export enum PdpGoodsProductType {
  BRAND = 'BRAND',
  SOHO = 'SOHO',
}

export type PdpGoodsProductTypeFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  type: PdpGoodsProductType;
};

export type PdpGoodsProductTypeFilterOptionList = PdpGoodsFilterOption & {
  name: Scalars['String'];
  product_type_option_list: Array<PdpGoodsProductTypeFilterOption>;
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsProperties = {
  detail: Array<Scalars['String']>;
  fit: Array<Scalars['String']>;
  length: Array<Scalars['String']>;
  material: Array<Scalars['String']>;
  pattern: Array<Scalars['String']>;
  sleeve_length: Array<Scalars['String']>;
};

export type PdpGoodsPropertyFilterOption = {
  name: Scalars['String'];
  property_item_option_list: Array<PdpGoodsPropertyItemFilterOption>;
  type: PdpGoodsPropertyType;
};

export type PdpGoodsPropertyFilterOptionList = PdpGoodsFilterOption & {
  name: Scalars['String'];
  property_option_list: Array<PdpGoodsPropertyFilterOption>;
  type: PdpGoodsFilterOptionType;
};

export type PdpGoodsPropertyItemFilterOption = {
  count: Scalars['Int'];
  property: Scalars['String'];
  selected: Scalars['Boolean'];
};

export enum PdpGoodsPropertyType {
  DETAIL = 'DETAIL',
  FIT = 'FIT',
  LENGTH = 'LENGTH',
  MATERIAL = 'MATERIAL',
  PATTERN = 'PATTERN',
  SLEEVE_LENGTH = 'SLEEVE_LENGTH',
}

/** 상품 상태 */
export enum PdpGoodsStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

export type PdpGoodsStyle = {
  /** 상품 스타일 색상 */
  color: Scalars['String'];
  /** 상품 스타일 명 */
  value: Scalars['String'];
};

export type PdpGoodsStyleFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type PdpGoodsStyleFilterOptionList = PdpGoodsFilterOption & {
  name: Scalars['String'];
  style_option_list: Array<PdpGoodsStyleFilterOption>;
  type: PdpGoodsFilterOptionType;
};

/** zpay 상태 */
export enum PdpGoodsZpayStatus {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NOT_ZPAY_SHOP = 'NOT_ZPAY_SHOP',
  SELLABLE = 'SELLABLE',
  SUSPENDED = 'SUSPENDED',
}

/** 이미지 컴포넌트 */
export type PdpImage = PdpComponent & {
  /** 이미지 비율 */
  aspect_ratio?: Maybe<Scalars['Float']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url: Scalars['String'];
  /** 이미지 WEBP URL */
  image_webp_url?: Maybe<Scalars['String']>;
  /** 딥링크 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpImageAndTextTitle = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  image_landing_url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  info?: Maybe<PdpTitleInfo>;
  is_html_text: Scalars['Boolean'];
  position: Scalars['Int'];
  sub_text?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: PdpComponentType;
};

export type PdpInfluencerLink = {
  idx: Scalars['ID'];
  short_link: Scalars['String'];
  web_link: Scalars['String'];
};

export type PdpInfluencerLinkList = {
  item_list: Array<PdpInfluencerLink>;
};

/** 정수의 [min, max] 구간 */
export type PdpIntRange = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type PdpIssuableMileage = {
  /** 프로모션 마일리지 적립 정보 */
  promotion?: Maybe<PdpPromotionIssuableMileage>;
  /** 리뷰 작성 시 마일리지 적립 정보 */
  review?: Maybe<PdpReviewIssuableMileage>;
};

export type PdpIssuablePoint = {
  /** 구매 액션에 따른 적립 정보 */
  purchase?: Maybe<PdpPurchaseIssuablePoint>;
  /** 리뷰 작성 시 포인트 적립 정보 */
  review?: Maybe<PdpReviewIssuablePoint>;
};

export type PdpKeyColor = {
  /** 구매하기 버튼 */
  buy_button: PdpKeyColorItem;
  /** 상단 네비게이션 바에 위치하고 있는 장바구니 버튼 색상 */
  cart_button_of_navigation_bar: Scalars['String'];
  /** atf 영역 쿠폰 받기, 받은 쿠폰에 적용되는 색상 */
  coupon_button_of_atf: Scalars['String'];
  /** atf 영역 할인 정보에 적용되는 색상 */
  discount_info_of_atf: Scalars['String'];
};

export type PdpKeyColorDetail = {
  /** 비활성화 케이스에 대한 키 컬러 */
  disabled: Scalars['String'];
  /** 활성화 케이스에 대한 키 컬러 */
  enabled: Scalars['String'];
};

export type PdpKeyColorItem = {
  /** 배경 키 컬러 */
  background: PdpKeyColorDetail;
  /** 텍스트 키 컬러 */
  text: PdpKeyColorDetail;
};

export enum PdpLegacyCatalogProductType {
  GENERAL = 'GENERAL',
  ZONLY = 'ZONLY',
}

export enum PdpLegacyProductStatus {
  NORMAL = 'NORMAL',
  SOLD_OUT = 'SOLD_OUT',
}

export enum PdpLegacyShippingFeeType {
  COD = 'COD',
  FREE = 'FREE',
  PRE_PAYMENT = 'PRE_PAYMENT',
}

export enum PdpLegacyZpayStatus {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NOT_ZPAY_SHOP = 'NOT_ZPAY_SHOP',
  SELLABLE = 'SELLABLE',
  SUSPENDED = 'SUSPENDED',
}

export type PdpLine = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpLineWithMargin = PdpComponent & {
  background_color?: Maybe<PdpCommonColor>;
  color?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  margin?: Maybe<PdpMargin>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpLowestPriceToolTip = {
  image?: Maybe<PdpCommonImage>;
  text: PdpCommonText;
};

export type PdpMargin = {
  bottom: Scalars['Int'];
  left: Scalars['Int'];
  right: Scalars['Int'];
  top: Scalars['Int'];
};

export type PdpMaxCouponDiscount = {
  discount_amount: Scalars['Int'];
  discount_type: Scalars['String'];
  issue_status: Scalars['String'];
  registration_type?: Maybe<PdpCouponRegistrationType>;
  title: Scalars['String'];
  user_account_coupon_policy_id: Scalars['String'];
};

export type PdpMaxUserBenefit = {
  /** 상황별 마일리지 적립 정보 */
  issuable_mileage?: Maybe<PdpIssuableMileage>;
  /** 상황별 포인트 적립 정보 */
  issuable_point?: Maybe<PdpIssuablePoint>;
};

export type PdpMetaCatalogProductInfo = {
  browsing_type: PdpBrowsingType;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<PdpGoodsCardDiscountInfo>;
  /** 솔루션사 관리코드 */
  external_code?: Maybe<Scalars['String']>;
  /** @deprecated external_code 필드 사용 요청 */
  external_product_id?: Maybe<Scalars['String']>;
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** 상품 이미지 */
  image_url?: Maybe<Scalars['String']>;
  is_able_to_buy: Scalars['Boolean'];
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 매칭 상품 노출 여부 */
  is_display: Scalars['Boolean'];
  is_link_visible: Scalars['Boolean'];
  /** 위너 상품인지 여부 */
  is_winner: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** 상품의 품목 리스트 */
  item_list: Array<PdpCatalogItem>;
  /** 상품명 */
  name: Scalars['String'];
  option_count_diff_list: Array<PdpOptionCountDiff>;
  option_list: Array<PdpCatalogProductOption>;
  /** 결제 타입 */
  payment_type: PdpCatalogPaymentType;
  /** 상품 url */
  pdp_url: Scalars['String'];
  /** 상품의 가격 정보 */
  product_price: PdpCatalogProductPrice;
  /** 배송비 */
  shipping_fee?: Maybe<PdpShippingFee>;
  /**
   * 배송비 타입
   * @deprecated shipping_fee 필드 사용 요청
   */
  shipping_fee_type: PdpCatalogShippingFeeType;
};

export type PdpMetaPdpProduct = {
  catalog_product_id?: Maybe<Scalars['String']>;
  catalog_product_type?: Maybe<PdpLegacyCatalogProductType>;
  discount_amount?: Maybe<Scalars['Int']>;
  discount_amount_with_currency?: Maybe<PdpPriceWithCurrency>;
  discount_info?: Maybe<PdpGoodsCardDiscountInfo>;
  discount_rate?: Maybe<Scalars['Int']>;
  final_price: Scalars['Int'];
  final_price_with_currency: PdpPriceWithCurrency;
  free_shipping: Scalars['Boolean'];
  has_coupon: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  image_url: Scalars['String'];
  is_saved_product: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  max_price: Scalars['Int'];
  max_price_with_currency: PdpPriceWithCurrency;
  name: Scalars['String'];
  price: Scalars['Int'];
  price_with_currency: PdpPriceWithCurrency;
  product_no?: Maybe<Scalars['String']>;
  retail_price: Scalars['Int'];
  retail_price_with_currency: PdpPriceWithCurrency;
  reviews?: Maybe<PdpMetaPdpProductReview>;
  select_option_url: Scalars['String'];
  shipping_fee_type?: Maybe<PdpLegacyShippingFeeType>;
  shop_id: Scalars['String'];
  shop_product_no?: Maybe<Scalars['String']>;
  status?: Maybe<PdpLegacyProductStatus>;
  zpay_discount_price?: Maybe<Scalars['Int']>;
  zpay_discount_price_with_currency?: Maybe<PdpPriceWithCurrency>;
  zpay_status: PdpLegacyZpayStatus;
};

export type PdpMetaPdpProductReview = {
  photo_count?: Maybe<Scalars['Int']>;
  ratings_average?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Int']>;
};

export enum PdpMethodType {
  AD_DISPLAY = 'AD_DISPLAY',
  AD_PRODUCT = 'AD_PRODUCT',
  BEST_PRODUCT = 'BEST_PRODUCT',
  BUY_TOGETHER = 'BUY_TOGETHER',
  FULL_WIDTH_IMAGE_BANNER = 'FULL_WIDTH_IMAGE_BANNER',
  NEW_PRODUCT = 'NEW_PRODUCT',
  NONE = 'NONE',
  PERSONALIZE = 'PERSONALIZE',
  SALE_PRODUCT = 'SALE_PRODUCT',
  USER_NAME_HTML_TITLE = 'USER_NAME_HTML_TITLE',
  ZIGZIN_ENTRY = 'ZIGZIN_ENTRY',
}

export enum PdpMileageAccumulateType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export type PdpModelBadge = {
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type PdpMultilineRankingCarousel = PdpComponent & {
  action_button?: Maybe<PdpButton>;
  category?: Maybe<PdpCategory>;
  component_list: Array<PdpGoodsCardItem>;
  id: Scalars['ID'];
  log: Scalars['String'];
  main_title?: Maybe<PdpText>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpNewBrandList = {
  item_list: Array<PdpShopRanking>;
};

export type PdpNewProductInfo = {
  id?: Maybe<Scalars['ID']>;
  is_new?: Maybe<Scalars['Boolean']>;
  renewed_ad?: Maybe<Scalars['CrTimestamp']>;
};

export type PdpNewShopList = {
  item_list: Array<PdpShopRanking>;
};

export type PdpNoResults = PdpComponent & {
  /** 종횡비 */
  aspect_ratio?: Maybe<Scalars['Float']>;
  /** 다크모드 이미지 URL */
  dark_image_url?: Maybe<Scalars['String']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 메인 타이틀 */
  no_results_main_title?: Maybe<PdpText>;
  /** 서브 타이틀 */
  no_results_sub_title?: Maybe<PdpText>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpNotice = {
  /** 내용 */
  content?: Maybe<Scalars['String']>;
  /** 공지 아이디 */
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /**
   * 공지 유형
   * - VACATION
   * - DELIVERY
   * - NORMAL
   * - IMAGE
   */
  notice_type: Scalars['String'];
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
};

export type PdpOptionCountDiff = {
  /** 속성 옵션 수 차이 */
  count_diff: Scalars['Int'];
  /** 속성명 */
  name: Scalars['String'];
};

/** PDP 이벤트 메타데이터 이벤트 참여 Input */
export type PdpParticipateEventMetadataInput = {
  /** 이벤트 메타데이터 UUID */
  event_metadata_uuid_list: Array<Scalars['String']>;
};

export type PdpParticipateEventMetadataResult = {
  /** 이벤트 참여 결과 */
  item_list: Array<PdpParticipateEventMetadataResultItem>;
  /** 결과 메세지 */
  result_message?: Maybe<Scalars['String']>;
  /** 참여 상태 */
  status: Scalars['String'];
  /** 응답 총 개수 */
  total_count: Scalars['Int'];
  ubl?: Maybe<UxUbl>;
};

export type PdpParticipateEventMetadataResultItem = {
  /** 이벤트 메타데이터 UUID */
  event_metadata_uuid: Scalars['String'];
  /** 참여 성공 여부 */
  is_success: Scalars['Boolean'];
  /** 참여 상태 */
  status: Scalars['String'];
};

export enum PdpPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type PdpPopularGoods = {
  goods_id: Scalars['String'];
  image_url: Scalars['String'];
  shop_product_no: Scalars['String'];
  url: Scalars['String'];
};

export type PdpPriceWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: PdpPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

export type PdpProduct = {
  /** 브라우저 타입 */
  browsing_type: PdpBrowsingType;
  /** catalog product ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 할인액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 할인된 가격 */
  discount_price?: Maybe<Scalars['Int']>;
  /** 할인율 - 0~100 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 최종 가격 */
  final_price: Scalars['Int'];
  /** 쿠폰 유무 */
  has_coupon: Scalars['Boolean'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 브랜드 상품 유무 */
  is_brand: Scalars['Boolean'];
  /** zonly 상품 유무 */
  is_zonly: Scalars['Boolean'];
  /** zpay 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 최대 가격 */
  max_price: Scalars['Int'];
  /** 판매 준비 기간 */
  preparation_period?: Maybe<Scalars['Int']>;
  /** 상품 가격 */
  price: Scalars['Int'];
  /** 상품 번호(cafe24등에서 관리하는 상품ID) */
  product_no: Scalars['String'];
  /** PDP Url */
  product_url: Scalars['String'];
  /** 배송비 */
  shipping_fee?: Maybe<Scalars['Int']>;
};

export enum PdpProductEntryType {
  CRAWLING_API = 'CRAWLING_API',
  DIRECT = 'DIRECT',
  SCRAPPING = 'SCRAPPING',
}

export type PdpProductExtraNoticeBanner = {
  notice_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PdpProductFittingModel = {
  badge?: Maybe<PdpModelBadge>;
  fitting_size_list?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  profile_image_url: Scalars['String'];
  size_text_list: Array<Scalars['String']>;
};

export type PdpProductFittingModelList = {
  item_list: Array<PdpProductFittingModel>;
};

export type PdpProductOptionValueSize = {
  answer?: Maybe<PdpProductOptionValueSizeAnswer>;
  height_range?: Maybe<PdpIntRange>;
  name: Scalars['String'];
  purchase_percent?: Maybe<Scalars['Int']>;
  ranking: Scalars['Int'];
  size_with_category?: Maybe<Scalars['String']>;
  weight_range?: Maybe<PdpIntRange>;
};

export type PdpProductOptionValueSizeAnswer = {
  percent: Scalars['Int'];
  value: Scalars['String'];
};

export type PdpProductPreorder = {
  /** 프리오더 주문 종료 */
  end_at: Scalars['CrTimestamp'];
  /** 프리오더 주문 배송 예정 */
  shipping_at: Scalars['CrTimestamp'];
  /** 프리오더 주문 시작 */
  start_at: Scalars['CrTimestamp'];
};

export type PdpProductReliability = {
  icon_url?: Maybe<Scalars['String']>;
  text: PdpText;
  value: Scalars['Int'];
};

export type PdpProductSize = {
  option_value_list: Array<PdpProductOptionValueSize>;
  recommendation_type: PdpProductSizeType;
  user_account?: Maybe<PdpUserAccount>;
};

export enum PdpProductSizeType {
  /** 추천 사이즈를 제공할 수 없는 경우 */
  NONE = 'NONE',
  /** 옵션값별 최빈 구매 체형을 기반으로 제공되는 추천 사이즈 */
  OPTION = 'OPTION',
  /** 입력된 체형에 대해 구매 비중 기반으로 제공되는 추천 사이즈 */
  PURCHASE = 'PURCHASE',
  /** backfilled 데이터를 기반으로 제공되는 추천 사이즈 */
  RECOMMENDATION = 'RECOMMENDATION',
}

export enum PdpPromotionAdditionalAccumulateType {
  RATE_MULTIPLY = 'RATE_MULTIPLY',
  RATE_PLUS = 'RATE_PLUS',
}

export type PdpPromotionInfo = {
  /** N+1 프로모션 정보 */
  bogo_info?: Maybe<BogoInfo>;
  /**
   * N+1 프로모션 적용을 위해 요구되는 최소 수량 (N값)
   * @deprecated bogo_info 하위의 bogo_required_quantity 사용 해주세요.
   */
  bogo_required_quantity?: Maybe<Scalars['Int']>;
  /** 직진 쿠폰 배너 */
  discount_banner_info?: Maybe<PdpFloatingBanner>;
  /** 플롯 배너 */
  float_banner_info?: Maybe<PdpFloatBannerInfo>;
  /** 프로모션 ID */
  promotion_id?: Maybe<Scalars['ID']>;
  /** 프로모션 TYPE */
  promotion_type?: Maybe<PdpPromotionType>;
  /** 프로모션 배너 */
  remain_banner_info?: Maybe<PdpPromotionRemainBannerInfo>;
  /** 프로모션 메세지. 배송기간 정보가 없을 때 해당 메세지를 보여준다 */
  reward_message: Scalars['String'];
};

export type PdpPromotionIssuableMileage = {
  /** 적립 타입 */
  accumulate_type: PdpMileageAccumulateType;
  /** 예상 마일리지 적립액 */
  amount: Scalars['Int'];
  /** 프로모션 종료일 */
  date_end: Scalars['CrTimestamp'];
  /** 마일리지 적립률 */
  rate?: Maybe<Scalars['Float']>;
  /** 프로모션 명 */
  title: Scalars['String'];
};

export type PdpPromotionRemainBannerInfo = {
  /** 아이콘 */
  end_time?: Maybe<Scalars['CrTimestamp']>;
  /** 아이콘 */
  icon?: Maybe<Scalars['String']>;
  /** 배너 텍스트 */
  text?: Maybe<Scalars['String']>;
};

export enum PdpPromotionType {
  BOGO = 'BOGO',
  BOGO_DISCOUNT = 'BOGO_DISCOUNT',
  FIRST_ORDER = 'FIRST_ORDER',
  RAFFLE = 'RAFFLE',
}

export type PdpPurchaseIssuablePoint = {
  /** 구매 확정 시 적립 포인트 */
  decision: Scalars['Int'];
  /** 첫 구매 시 적립 포인트 */
  first_order: Scalars['Int'];
};

export type PdpRecommendAttribute = {
  key: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
};

export type PdpRecommendCardGroup = PdpComponent & {
  end_cursor?: Maybe<Scalars['String']>;
  has_next: Scalars['Boolean'];
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 광고 뱃지 여부 */
  is_ad: Scalars['Boolean'];
  item_list?: Maybe<Array<PdpGoodsCardItem>>;
  position: Scalars['Int'];
  sub_title?: Maybe<PdpText>;
  title?: Maybe<PdpText>;
  type: PdpComponentType;
};

export enum PdpRecommendationType {
  AUXILIARY = 'AUXILIARY',
  NEW = 'NEW',
  RECOMMEND = 'RECOMMEND',
}

export enum PdpRelationType {
  SAMPLE = 'SAMPLE',
  SAMPLE_ORIGINAL = 'SAMPLE_ORIGINAL',
}

export type PdpReturnShippingFee = {
  /** 부분 반품 배송비 */
  partial_fee?: Maybe<Scalars['Int']>;
  /** 전체 반품 배송비 */
  total_fee: Scalars['Int'];
};

export type PdpReviewIssuableMileage = {
  /** 베스트 리뷰 선정 시 적립 마일리지 */
  best: Scalars['Int'];
  /** 첫 리뷰 작성 시 적립 마일리지 */
  first: Scalars['Int'];
  /** 최대 적립 마일리지 */
  maximum: Scalars['Int'];
  /** 리뷰 작성 시 최대 적립 마일리지 */
  maximum_review_bonus: Scalars['Int'];
};

export type PdpReviewIssuablePoint = {
  /** 베스트 리뷰 선정 시 적립 포인트 */
  best: Scalars['Int'];
  /** 첫 리뷰 작성 시 적립 포인트 */
  first: Scalars['Int'];
  /** 최대 적립 포인트 */
  maximum: Scalars['Int'];
  /** 포토 리뷰 적립 포인트 */
  photo: Scalars['Int'];
  /** 텍스트 리뷰 적립 포인트 */
  text: Scalars['Int'];
};

export type PdpSearchedGoodsCardInfo = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list?: Maybe<Array<PdpGoodsCardItem>>;
  total_count: Scalars['Int'];
  total_zpay_count: Scalars['Int'];
};

/** 브랜드 상세 정보 */
export type PdpSearchedProductBrand = {
  id: Scalars['ID'];
  logo_image_url: Scalars['String'];
  name: Scalars['String'];
};

export enum PdpSearchedProductSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export type PdpSearchedShop = {
  alias_list?: Maybe<Array<Scalars['String']>>;
  bookmark_count: Scalars['Int'];
  /** 카테고리(의류, 가방 등) */
  category?: Maybe<Scalars['String']>;
  category_list: Array<PdpCategory>;
  department_badge?: Maybe<PdpBadgeNormal>;
  department_dark_badge?: Maybe<PdpBadgeNormal>;
  id: Scalars['ID'];
  /** 쇼핑몰 운영 중단 여부 */
  is_disabled: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  /** Z결제 지원 여부 */
  is_zpay: Scalars['Boolean'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  /** 쇼핑몰 상태(NORMAL, RENEWAL, DORMANCY, DELETION_REQUEST, STOPPED, BAD_WORKING) */
  status: Scalars['String'];
  style_list: Array<Scalars['String']>;
  typical_image_url: Scalars['String'];
};

export type PdpSearchedShopCarousel = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  /** 쇼핑몰 목록 */
  searched_shop_list: Array<PdpSearchedShop>;
  type: PdpComponentType;
};

export type PdpSearchedShopList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<DisplayPdpSearchedShop>;
  total_count: Scalars['Int'];
};

export type PdpSegmentTab = PdpComponent & {
  filter_item_list: Array<PdpFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpSelectionAndSorting = PdpComponent & {
  fixed?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  selection_item_list: Array<PdpFilterItem>;
  sorting_item_list: Array<PdpFilterItem>;
  type: PdpComponentType;
  ux_selection_sorting_style?: Maybe<PdpSelectionAndSortingStyle>;
};

export enum PdpSelectionAndSortingStyle {
  BUTTON = 'BUTTON',
  SELECTION = 'SELECTION',
}

export enum PdpSellableStatus {
  CLOSED = 'CLOSED',
  NOT_SUPPORT_COUNTRY = 'NOT_SUPPORT_COUNTRY',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type PdpSellerInfo = {
  business_license_number: Scalars['String'];
  company_address: Scalars['String'];
  company_email: Scalars['String'];
  company_name: Scalars['String'];
  domain: Scalars['String'];
  kakao_cs_url?: Maybe<Scalars['String']>;
  landline_number: Scalars['String'];
  mobile_number: Scalars['String'];
  naver_cs_url?: Maybe<Scalars['String']>;
  online_sales_license_number: Scalars['String'];
  online_sales_license_report_agency: Scalars['String'];
  privacy_policy_url: Scalars['String'];
  report_status: Scalars['String'];
  representative_name: Scalars['String'];
  selling_category: Scalars['String'];
  selling_method: Scalars['String'];
  terms_of_use_policy_url: Scalars['String'];
};

export type PdpSendMobileAuthResult = {
  expire_timestamp: Scalars['CrTimestamp'];
};

export type PdpShippingCompany = {
  base_company?: Maybe<PdpShopShippingCompany>;
  return_company?: Maybe<PdpShopShippingCompany>;
};

export type PdpShippingCost = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type PdpShippingCountry = {
  /** 국기 아이콘 */
  flag: Scalars['String'];
  /** 국가 이름 */
  name: Scalars['String'];
};

export type PdpShippingFee = {
  /** 추가 배송비 문구 */
  additional_shipping_fee_text?: Maybe<Scalars['String']>;
  /** 권역별 추가 배송비 */
  area_fee: PdpAreaShippingFee;
  /** 기본 배송비 */
  base_fee: Scalars['Int'];
  /** 교환 배송비 */
  exchange_fee: Scalars['Int'];
  /** 배송비 타입 */
  fee_type: PdpShippingFeeType;
  /** 조건부 무료 배송비 */
  minimum_free_shipping_fee?: Maybe<Scalars['Int']>;
  /** 배송비 관련 알림 문구 */
  notice_list?: Maybe<PdpShippingFeeNoticeList>;
  /** 반품 배송비 */
  return_fee: PdpReturnShippingFee;
};

export type PdpShippingFeeNoticeList = {
  /** 권역별 추가배송비 문구 */
  area_fee_text?: Maybe<Scalars['String']>;
};

export enum PdpShippingFeeType {
  /** 고정비 배송 */
  CHARGED = 'CHARGED',
  /** 조건부 무료 배송 */
  CONDITIONAL_FREE = 'CONDITIONAL_FREE',
  /** 무료 배송 */
  FREE = 'FREE',
}

export type PdpShippingInformation = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type PdpShippingSendDate = {
  /** 셀러 휴무일 존재 여부 */
  exist_shop_holidays: Scalars['Boolean'];
  /** 빠른 배송 정보 */
  fast_shipping?: Maybe<PdpFastShipping>;
  /** 발송 예정일 관련 보충 설명 텍스트 */
  guide_text?: Maybe<Scalars['String']>;
  /** 옵션 발송 예정일 관련 보충 설명 텍스트 */
  item_guide_text?: Maybe<Scalars['String']>;
  /** 발송 예정일 timestamp 버전 */
  send_date: Scalars['CrTimestamp'];
  /** 발송 예정일 텍스트 (exist_shop_holidays = true 인 경우, '판매자 배송 휴무로' 텍스트를 포함하여 응답함) */
  send_date_text: Scalars['String'];
  /** 배송 관련 툴팁 텍스트 */
  tooltip_text?: Maybe<Scalars['String']>;
};

export type PdpShop = {
  bookmark_count?: Maybe<Scalars['Int']>;
  business_license?: Maybe<PdpShopBusinessLicense>;
  coupon_description?: Maybe<Scalars['String']>;
  customer_center?: Maybe<PdpShopCustomerCenter>;
  customer_center_tel?: Maybe<Scalars['String']>;
  date_created: Scalars['Float'];
  delete_action: Scalars['Int'];
  /** @deprecated PdpCatalogProduct 의 shipping_fee 필드 사용 요청 */
  free_shipping_amount?: Maybe<Scalars['Int']>;
  goods_count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** 마일리지 적립 가능한 스토어 여부 */
  is_mileage_shop: Scalars['Boolean'];
  is_parallel_import?: Maybe<Scalars['Boolean']>;
  /** 쇼핑몰 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  keywords: Scalars['String'];
  main_contact?: Maybe<PdpShopContact>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
  need_wrap_goods_image: Scalars['Boolean'];
  online_sales_license?: Maybe<PdpShopOnlineSalesLicense>;
  parent_id?: Maybe<Scalars['ID']>;
  ranking: Scalars['Int'];
  ranking_live: Scalars['Int'];
  return_address?: Maybe<PdpShopAddress>;
  return_shipping_company?: Maybe<PdpShopShippingCompany>;
  sale_type?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<PdpShopAddress>;
  shipping_company1?: Maybe<PdpShopShippingCompany>;
  shipping_company2?: Maybe<PdpShopShippingCompany>;
  shop_business_info?: Maybe<PdpShopBusinessInfo>;
  shop_category_list: Array<PdpShopCategory>;
  solution?: Maybe<Scalars['String']>;
  state: Scalars['Int'];
  trait: PdpShopTrait;
  type: PdpShopType;
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /** 스토어 기준 상품 구매에 마일리지 사용 가능한 비율 */
  usable_mileage?: Maybe<UsableMileage>;
  zpay_status?: Maybe<PdpShopZpayStatus>;
};

export type PdpShopAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type PdpShopBusinessInfo = {
  id: Scalars['Int'];
  seller_info: PdpSellerInfo;
  shop_id: Scalars['ID'];
};

export type PdpShopBusinessLicense = {
  /** 업태 */
  business_category?: Maybe<Scalars['String']>;
  /** 종목 */
  business_subcategory?: Maybe<Scalars['String']>;
  /** 사업장소재지 */
  company_address?: Maybe<Scalars['String']>;
  /** 업체(법인)명 */
  company_name?: Maybe<Scalars['String']>;
  /** 사업자등록증 번호 */
  registration_number?: Maybe<Scalars['String']>;
  /** 대표자명 */
  representative_name?: Maybe<Scalars['String']>;
};

export type PdpShopCard = {
  is_ad: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  logo?: Maybe<PdpImage>;
  shop: PdpShopCardItem;
  thumbnail?: Maybe<PdpImage>;
};

export type PdpShopCardItem = {
  id: Scalars['ID'];
  is_bookmark: Scalars['Boolean'];
  main_domain?: Maybe<Scalars['String']>;
  main_name: Scalars['String'];
  sub_name?: Maybe<Scalars['String']>;
};

export type PdpShopCarousel = {
  component_list: Array<PdpShopCard>;
};

/** 샵 카테고리 정보(셀러홈과 동일) */
export type PdpShopCategory = {
  /** id */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 동일 depth에서의 순서 */
  sibling_order: Scalars['Int'];
  /** 카테고리 클릭시 노출될 딥링크 url(store_home) */
  store_home_deep_link?: Maybe<Scalars['String']>;
};

export type PdpShopContact = {
  email?: Maybe<Scalars['String']>;
  landline_number?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** 고객센터 정보 */
export type PdpShopCustomerCenter = {
  /** 고객센터 운영시간 */
  business_time?: Maybe<PdpTimeRange>;
  /** 고객센터 휴일 */
  closed_week_day_list?: Maybe<Array<PdpWeekDayType>>;
  /** 공휴일 휴무 여부 */
  is_closed_on_holiday?: Maybe<Scalars['Boolean']>;
  /** 고객센터 점심시간 */
  lunch_time?: Maybe<PdpTimeRange>;
  /** 담당자명 */
  manager_name?: Maybe<Scalars['String']>;
  /** 담당자 연락처 */
  manager_tel?: Maybe<Scalars['String']>;
};

export type PdpShopExpandableList = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 한 화면에 보여지는 상품 갯수 */
  item_column_count: Scalars['Float'];
  /** 아이콘 */
  logo?: Maybe<PdpImage>;
  position: Scalars['Int'];
  /** 쇼핑몰 캐로셀 */
  shop_carousel: PdpShopCarousel;
  /** 확장형 리스트의 on/off 버튼 노출 유무 */
  show_open_button: Scalars['Boolean'];
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** shop_carousel 갯수 */
  total_count: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpShopFilterInfo = {
  default_category: Scalars['String'];
  option_list: Array<PdpShopFilterOption>;
};

export type PdpShopFilterOption = {
  age_list: Array<Scalars['String']>;
  category: Scalars['String'];
  default_age_text?: Maybe<Scalars['String']>;
  default_style_text?: Maybe<Scalars['String']>;
  style_list: Array<Scalars['String']>;
};

export type PdpShopGroup = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** 한 화면에 보여지는 상품 갯수 */
  item_column_count: Scalars['Float'];
  position: Scalars['Int'];
  /** 쇼핑몰 캐로셀 */
  shop_carousel: PdpShopCarousel;
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** shop_carousel 갯수 */
  total_count: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpShopOnlineSalesLicense = {
  /** 라이센스 번호 */
  license_number?: Maybe<Scalars['String']>;
};

export type PdpShopRanking = {
  age_list: Array<Scalars['String']>;
  /** 패바카에서만 제공합니다. 호출하는 경우에만 불러옵니다. */
  best_product_list: Array<PdpGoodsCardItem>;
  bookmark_count?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  coupon_description?: Maybe<Scalars['String']>;
  date_approved?: Maybe<Scalars['CrTimestamp']>;
  goods_list: Array<PdpShopRankingGoods>;
  is_free_shipping: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay: Scalars['Boolean'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  /** 샵이 가진 상품 중 신상품이 존재하는지 확인, 호출하는 경우에만 불러옵니다. */
  new_product?: Maybe<PdpNewProductInfo>;
  ranking: Scalars['Int'];
  shop_id: Scalars['ID'];
  style_list: Array<PdpShopRankingStyle>;
  typical_image_url: Scalars['String'];
  variance?: Maybe<PdpShopRankingVariance>;
};

export type PdpShopRankingCardItem = PdpComponent & {
  action_button?: Maybe<PdpButton>;
  component_list: Array<PdpGoodsCardItem>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  is_saved_shop: Scalars['Boolean'];
  position: Scalars['Int'];
  ranking?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
  shop_typical_image_url: Scalars['String'];
  type: PdpComponentType;
  variance?: Maybe<PdpShopRankingVariance>;
};

export type PdpShopRankingFilterChip = {
  selected_item_list: Array<Scalars['String']>;
  type: PdpShopRankingFilterChipType;
  value: Scalars['String'];
};

export enum PdpShopRankingFilterChipType {
  AGE = 'AGE',
  CATEGORY = 'CATEGORY',
  STYLE = 'STYLE',
}

export type PdpShopRankingGoods = {
  image_url: Scalars['String'];
  log: Scalars['String'];
  url: Scalars['String'];
};

export type PdpShopRankingItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<PdpShopRanking>>;
};

export type PdpShopRankingList = {
  filter_list: Array<PdpShopRankingFilterChip>;
  has_filter: Scalars['Boolean'];
  has_minor: Scalars['Boolean'];
  has_more: Scalars['Boolean'];
  item_list: Array<PdpShopRanking>;
  ranking_updated: Scalars['String'];
};

export type PdpShopRankingStyle = {
  color: Scalars['String'];
  value: Scalars['String'];
};

export type PdpShopRankingVariance = {
  color: Scalars['String'];
  type?: Maybe<PdpShopRankingVarianceType>;
  value?: Maybe<Scalars['Int']>;
};

export enum PdpShopRankingVarianceType {
  DOWN = 'DOWN',
  NEW = 'NEW',
  UP = 'UP',
  ZERO = 'ZERO',
}

export enum PdpShopShippingCompany {
  CJ = 'CJ',
  ETC = 'ETC',
  HANJIN = 'HANJIN',
  ILYANG = 'ILYANG',
  LOGEN = 'LOGEN',
  LOGIS = 'LOGIS',
  LOTTE = 'LOTTE',
  POSTAL = 'POSTAL',
}

export type PdpShopStyle = {
  /** 쇼핑몰 스타일 색상 */
  color: Scalars['String'];
  /** 쇼핑몰 스타일 명 */
  value: Scalars['String'];
};

/** 카테고리, 스타일, 연령정보 등 */
export type PdpShopTrait = {
  age10?: Maybe<Scalars['Boolean']>;
  age20early?: Maybe<Scalars['Boolean']>;
  age20late?: Maybe<Scalars['Boolean']>;
  age20mid?: Maybe<Scalars['Boolean']>;
  age30early?: Maybe<Scalars['Boolean']>;
  age30late?: Maybe<Scalars['Boolean']>;
  age30mid?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['Int']>;
  category_list?: Maybe<Array<Scalars['String']>>;
  minor_category?: Maybe<Scalars['Int']>;
  minor_category_list?: Maybe<Array<Scalars['String']>>;
  minor_style?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export enum PdpShopType {
  META = 'META',
  STORE = 'STORE',
  ZPAY = 'ZPAY',
}

/** zpay 상태 */
export enum PdpShopZpayStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type PdpShortFormContentItem = {
  id: Scalars['ID'];
  thumbnail_image_url: Scalars['String'];
  title: Scalars['String'];
  video_url: Scalars['String'];
};

export type PdpShortFormContentList = {
  content_list?: Maybe<Array<PdpShortFormContentItem>>;
};

export type PdpSorting = PdpComponent & {
  filter_item_list: Array<PdpFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  info?: Maybe<PdpTitleInfo>;
  position: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpSortingItem = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  str_id: Scalars['ID'];
};

/** 표준 배송 정보 */
export type PdpStandardShip = {
  caption: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  free_shipping_over?: Maybe<PdpShippingCost>;
  shipping_cost?: Maybe<PdpShippingCost>;
  shipping_country: Array<Maybe<PdpShippingCountry>>;
  title: Scalars['String'];
};

export type PdpStyleGoodsImage = {
  image_url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type PdpStyleGroup = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
};

export type PdpStyleGroupList = {
  birth_on_year?: Maybe<Scalars['String']>;
  is_deprecated: Scalars['Boolean'];
  item_list: Array<PdpStyleGroup>;
};

export type PdpStyleGroupShop = {
  bookmark_count?: Maybe<Scalars['String']>;
  goods_image_list: Array<PdpStyleGoodsImage>;
  goods_image_url_list: Array<Scalars['String']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
};

export type PdpStyleGroupShopList = {
  item_list: Array<PdpStyleGroupShop>;
};

export type PdpSubCategoryInfo = {
  ID: Scalars['ID'];
  name: Scalars['String'];
};

/** 텍스트 컴포넌트 */
export type PdpText = PdpComponent & {
  /** 텍스트 정렬 */
  align?: Maybe<PdpTextAlign>;
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  /** 텍스트 스타일 */
  style?: Maybe<PdpTextStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: PdpComponentType;
};

/** PdpText 정렬 */
export enum PdpTextAlign {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type PdpTextAndMoreButton = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  main_title: PdpText;
  more_button?: Maybe<PdpButton>;
  position: Scalars['Int'];
  total_count: Scalars['Int'];
  type: PdpComponentType;
};

export type PdpTextAndSorting = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  main_title?: Maybe<PdpText>;
  position: Scalars['Int'];
  sorting_item_list: Array<PdpSortingItem>;
  sub_title?: Maybe<PdpText>;
  type: PdpComponentType;
};

/** PdpText 스타일 */
export enum PdpTextStyle {
  BODY_1 = 'BODY_1',
  BODY_2 = 'BODY_2',
  BODY_3 = 'BODY_3',
  CAPTION = 'CAPTION',
  CAPTION_NUM = 'CAPTION_NUM',
  SUB_TITLE_1 = 'SUB_TITLE_1',
  SUB_TITLE_1_2 = 'SUB_TITLE_1_2',
  SUB_TITLE_2 = 'SUB_TITLE_2',
  SUB_TITLE_3 = 'SUB_TITLE_3',
  TITLE_1 = 'TITLE_1',
  TITLE_2 = 'TITLE_2',
}

export type PdpTextTitle = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  info?: Maybe<PdpTitleInfo>;
  is_html: Scalars['Boolean'];
  position: Scalars['Int'];
  sub_text?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: PdpComponentType;
};

/** 시간 범위 */
export type PdpTimeRange = {
  /** 시작 시간 */
  from_hm?: Maybe<Scalars['String']>;
  /** 끝 시간 */
  to_hm?: Maybe<Scalars['String']>;
};

export type PdpTitleInfo = {
  desc?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PdpUrl = {
  type: PdpUrlType;
  url: Scalars['String'];
};

export type PdpUrlInput = {
  catalog_product_id: Scalars['ID'];
  url_type: Array<PdpUrlType>;
};

export enum PdpUrlType {
  COUPON_LIST = 'COUPON_LIST',
  DISCOUNT_DETAIL = 'DISCOUNT_DETAIL',
  INTEREST_FREE = 'INTEREST_FREE',
  MAXIMUM_BENEFIT = 'MAXIMUM_BENEFIT',
  MILEAGE = 'MILEAGE',
  SELECTED_HOME = 'SELECTED_HOME',
  SELECT_OPTION = 'SELECT_OPTION',
  SHIPPING = 'SHIPPING',
}

export type PdpUserAccount = {
  body?: Maybe<PdpUserBody>;
  name?: Maybe<Scalars['String']>;
};

export type PdpUserAccountMileage = {
  available_mileage?: Maybe<Scalars['Int']>;
};

export type PdpUserAccountPoint = {
  available_point?: Maybe<Scalars['Int']>;
};

export type PdpUserAdditionalMileage = {
  /** 추가 마일리지 적립률 */
  additional_rate: Scalars['Float'];
  /** 뱃지 텍스트 */
  badge_text: UxCommonText;
};

export type PdpUserBaseInfo = {
  /** 유저의 최대 쿠폰 할인 정보 (discount_price 파라미터를 기반으로 정보 제공) */
  pdp_max_coupon_discount?: Maybe<PdpMaxCouponDiscount>;
  /** 유저에게 제공되는 최대 혜택 정보 (final_price 파라미터를 기반으로 정보 제공) */
  pdp_max_user_benefit?: Maybe<PdpMaxUserBenefit>;
  /** 계정에 연동된 마일리지 정보 */
  pdp_user_account_mileage?: Maybe<PdpUserAccountMileage>;
  /** 계정에 연동된 포인트 정보 */
  pdp_user_account_point?: Maybe<PdpUserAccountPoint>;
  /** 추가 마일리지 정보 */
  pdp_user_additional_mileage?: Maybe<PdpUserAdditionalMileage>;
  /**
   * 유저에게 제공되는 혜택 정보
   * @deprecated 더 이상 제공되지 않음. 하위 필드마다 deprecated 사유 확인 요청
   */
  pdp_user_benefit?: Maybe<PdpUserBenefit>;
  /** 유저의 멤버십 정보 및 예상 마일리지 적립 정보 */
  pdp_user_membership?: Maybe<PdpUserMembership>;
  /** 비회원 예측 마일리지 정보 */
  pdp_user_nonmember_membership?: Maybe<PdpUserNonmemberMembership>;
};

export type PdpUserBaseInfoInput = {
  /** 상품 번호 */
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  /** 쇼핑몰 할인가와 지그재그 추가 할인가(구. 제트할인가) 중 가장 저렴한 가격 */
  discount_price?: InputMaybe<Scalars['Int']>;
  /** 최종 할인가 */
  final_price?: InputMaybe<Scalars['Int']>;
};

export type PdpUserBenefit = {
  /**
   * 첫구매가 혜택
   * @deprecated type PdpCatalogProductPrice 하위의 first_order_discount 사용 요청
   */
  first_order_discount?: Maybe<PdpFirstOrderDiscount>;
  /**
   * 최대 쿠폰 할인 정보
   * @deprecated type PdpUserBaseInfo 하위의 pdp_max_coupon_discount 사용 요청. 웹에서 호출 끊기고 나면 삭제 예정
   */
  max_coupon_discount?: Maybe<PdpMaxCouponDiscount>;
};

export type PdpUserBody = {
  /** 신장 */
  height: Scalars['Int'];
  /** 사이즈에 대한 문구, e.g. '하의 27' */
  size_text?: Maybe<Scalars['String']>;
  /** 체중 */
  weight: Scalars['Int'];
};

export type PdpUserMembership = {
  /** 멤버십 등급명 */
  grade: Scalars['String'];
  /** 멤버십 등급에 따른 마일리지 적립 정보 */
  mileage_reward: PdpUserMileageReward;
};

export type PdpUserMileageReward = {
  /** 추가 마일리지 적립 타입 */
  additional_accumulate_type?: Maybe<PdpPromotionAdditionalAccumulateType>;
  /** 추가 마일리지 적립률 */
  additional_rate?: Maybe<Scalars['Float']>;
  /** 예상 마일리지 적립액 */
  amount: Scalars['Int'];
  /** 멤버십 등급에 따른 마일리지 적립률 */
  rate: Scalars['Float'];
};

export type PdpUserNonmemberMembership = {
  amount: Scalars['Int'];
  rate: Scalars['Float'];
};

export type PdpUxShippingInformation = {
  /** 배송 정보 */
  shipping_information: PdpShippingInformation;
  /** 표준 배송 정보 */
  standard_ship: PdpStandardShip;
  /** Shipping Information */
  title: Scalars['String'];
};

export type PdpVirtualCategoryJsonInternal = {
  json: Scalars['String'];
};

/** 웹 페이지 컴포넌트 */
export type PdpWebPage = PdpComponent & {
  /** unique 컴포넌트 식별자 */
  id: Scalars['ID'];
  position: Scalars['Int'];
  type: PdpComponentType;
  /** 웹 사이트 URL */
  url: Scalars['String'];
};

/** 휴일 요일 */
export enum PdpWeekDayType {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED',
}

export type PdpWmsNotificationInfo = {
  /** 재입고 알림 여부 */
  active: Scalars['Boolean'];
};

export type PdpZigzagPolicy = {
  content_list: Array<PdpZigzagPolicyContent>;
  main_title: Scalars['String'];
};

export type PdpZigzagPolicyContent = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type PdpZigzinEntryPointBanner = {
  aspect_ratio: Scalars['Float'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
};

export type PdpZigzinEntryPointCard = {
  card_color: Scalars['String'];
  card_text?: Maybe<Scalars['String']>;
  card_title: Scalars['String'];
  header_text?: Maybe<Scalars['String']>;
  header_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  open_reserve_time?: Maybe<Scalars['CrTimestamp']>;
};

export type PdpZigzinEntryPointGroup = {
  item_list: Array<Maybe<PdpZigzinEntryPointCard>>;
  landing_url: Scalars['String'];
};

export type PdpZigzinEntryPointInfo = {
  banner?: Maybe<PdpZigzinEntryPointBanner>;
  group?: Maybe<PdpZigzinEntryPointGroup>;
};

export type PdpZigzinShippingBaseInfo = {
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_date_except_title_text?: Maybe<Scalars['String']>;
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_date_text: Scalars['String'];
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_date_title_text?: Maybe<Scalars['String']>;
  /** 직진 배송 안내 요약 문구 리스트 */
  arrival_notice_texts: Array<Maybe<Scalars['String']>>;
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_one_day_text?: Maybe<Scalars['String']>;
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_one_day_title_text?: Maybe<Scalars['String']>;
  /** @deprecated oneday_shipping 필드 사용 요청 */
  arrival_text_with_user_postcode?: Maybe<Scalars['String']>;
  /** 상품 번호 */
  catalog_product_id: Scalars['ID'];
  /** 배송지 등록 가이드 */
  default_address_guide?: Maybe<DefaultAddressGuide>;
  /** @deprecated zigzin_shipping 필드 사용 요청 */
  estimate_content: Scalars['String'];
  /** @deprecated zigzin_shipping 필드 사용 요청 */
  estimate_timestamp: Scalars['CrTimestamp'];
  /** @deprecated oneday_shipping, zigzin_shipping 필드 사용 요청 */
  free_shipping_text?: Maybe<Scalars['String']>;
  /** 요청 세션의 기본 배송지 존재 유무 (비로그인 = false, 로그인이더라도 기본 배송지 없는 경우 = false) */
  has_default_address: Scalars['Boolean'];
  /** @deprecated 사용처 없으므로 제거 */
  is_default_address?: Maybe<Scalars['Boolean']>;
  /** 당일 및 새벽 배송 정보 */
  oneday_shipping?: Maybe<ZigzinEstimateShippingItemInfo>;
  /** @deprecated 사용처 없으므로 제거 */
  product_id?: Maybe<Scalars['ID']>;
  /** 직진 배송 안내 상세 정보 */
  shipping_notify_info: ZigzinShippingNotifyInfo;
  /** 직진 배송 정보 요약 문구 */
  summary_text?: Maybe<Scalars['String']>;
  /** 직진 배송 정보 */
  zigzin_shipping: ZigzinEstimateShippingItemInfo;
};

export type PdpZigzinShippingBaseInfoInput = {
  /** 상품 번호 */
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  /** 상품의 최종가 (oneday_shipping.shipping_fee_text 를 호출하지 않는 경우 final_price 는 null 로 전달해도 무관합니다) */
  final_price?: InputMaybe<Scalars['Int']>;
  /** 우편번호 (별도로 요청되지 않는 경우 요청 세션에서 user_account_id 를 조회한 후 user segment 레디스에서 조회된 우편번호를 사용합니다) */
  postcode?: InputMaybe<Scalars['String']>;
};

export type PdpZigzinShippingBaseInfoList = {
  /** 직진 배송 정보 응답 리스트 */
  item_list: Array<PdpZigzinShippingBaseInfo>;
  /** 직진 배송 정보 응답 개수 */
  total_count: Scalars['Int'];
};

export type PdpZigzinShippingBaseInfoListInput = {
  item_list: Array<PdpZigzinShippingBaseInfoInput>;
};

export type PdpdGoodsCardItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<PdpGoodsCardItem>>;
  total_count: Scalars['Int'];
};

export type PenaltyResponse = {
  /** 판매 중단 사유 */
  comment?: Maybe<Scalars['String']>;
  /** 패널티 템플릿의 key */
  template_id?: Maybe<Scalars['ID']>;
  /** 판매 중단 상태값 PENALTY, HIDDEN, NONE, OUT_OF_SYNC */
  value: Scalars['String'];
};

export enum PenaltyStatus {
  /** Z결제 됨/ 검색 안됨 */
  HIDDEN = 'HIDDEN',
  /** 정상 상품 */
  NONE = 'NONE',
  /** Z결제 안됨/ 검색 안됨 */
  PENALTY = 'PENALTY',
  /** Z결제 됨/ 검색 안됨/ PIMS */
  UNSEARCHABLE = 'UNSEARCHABLE',
}

export type PersonalClearanceCode = {
  /** 개인통관고유부호 */
  personal_clearance_code: Scalars['String'];
};

/** 개인화된 마이픽쿠폰 정보 */
export type PersonalizedMyPickCouponInfo = {
  /** 유저의 마이픽쿠폰 수령 가능 여부 */
  is_my_pick_coupon_issuable: Scalars['Boolean'];
  /** 유저의 마이픽쿠폰 수령 횟수 */
  issued_my_pick_coupon_count: Scalars['Int'];
  /** 유저가 수령한 마이픽쿠폰 아이템 리스트 */
  issued_my_pick_coupon_item_list: Array<MyPickCouponSetItem>;
  /** 유저의 최대 마이픽쿠폰 수령 가능 횟수 */
  max_issuable_my_pick_coupon_count: Scalars['Int'];
  /** 진행중인 마이픽쿠폰 정보 */
  my_pick_coupon_set: MyPickCouponSet;
  /** 유저에게 표시되는 개인화된 마이픽쿠폰 아이템 리스트 (수령여부와 상관없이 내려감) */
  personalized_my_pick_coupon_item_list: Array<MyPickCouponSetItem>;
};

/** PickBanner 섹션 */
export type PickBanner = UiSection & {
  /** PickBanner 데이터 */
  item: PickBannerItem;
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

/** PickBanner 데이터 */
export type PickBannerItem = {
  /** 데이터 생성 시간(unix timestamp) */
  date_published: Scalars['CrTimestamp'];
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** 타이틀(Placeholder) */
  title: Scalars['String'];
  /** 이미지 주소(작은 것) */
  url: Scalars['String'];
  /** 이미지 주소(큰 것) */
  url_240: Scalars['String'];
  /** uuid 형식의 고유 ID */
  uuid: Scalars['ID'];
};

export type PickRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  pick_link_url?: Maybe<Scalars['String']>;
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
  /** 픽(프리셋) uuid */
  uuid: Scalars['String'];
};

export type PinLightBannerSlideSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type PinLightBannerSlideSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type Placeholder = {
  /** 노출 텍스트에 html 태그 포함 여부 */
  is_html: Scalars['Boolean'];
  /** 검색창 노출 텍스트 */
  text: Scalars['String'];
};

export enum PlusFriendsType {
  ADDED = 'ADDED',
  BLOCKED = 'BLOCKED',
  NONE = 'NONE',
}

export type PointExpireExpectedMileage = {
  /** 마일리지 */
  mileage: Scalars['Int'];
};

export type PointExpireExpectedMileageTransaction = {
  date_expired: Scalars['CrTimestamp'];
  date_transacted: Scalars['CrTimestamp'];
  description: Scalars['String'];
  mileage: Scalars['Int'];
  type: PointMileageTransactionType;
};

/** N일 이내 소멸 마일리지 적립 내역 조회 */
export type PointExpireExpectedMileageTransactionList = {
  /** 소멸 마일리지 적립 내역 */
  item_list: Array<PointExpireExpectedMileageTransaction>;
  /** 소멸 마일리지 적립 내역 총 개수 */
  total_count: Scalars['Int'];
};

export enum PointMileageActionType {
  ADD = 'ADD',
  USE = 'USE',
  WITHDRAW = 'WITHDRAW',
}

export enum PointMileageAmountUnit {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  FIXED_RATE = 'FIXED_RATE',
}

export type PointMileagePolicy = {
  /** 금액 단위 타입 */
  amount_unit: PointMileageAmountUnit;
  /** 금액 값 */
  amount_value: Scalars['Int'];
  /** 종료 일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 시작 일시 */
  date_started: Scalars['CrTimestamp'];
  /** 정책 설명 */
  description: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 활성화 여부 */
  is_active: Scalars['Boolean'];
  /** 정책 타겟 타입 */
  target_type: PointMileagePolicyTargetType;
  /** 정책 제목 */
  title: Scalars['String'];
  /** 정책 타입 */
  type: PointMileagePolicyType;
};

export type PointMileagePolicyForUse = {
  /** 금액 단위 타입 */
  amount_unit: PointMileageAmountUnit;
  /** 금액 값 */
  amount_value: Scalars['Int'];
  /** 상품 ID */
  product_id?: Maybe<Scalars['String']>;
  /** 상점 ID */
  shop_id?: Maybe<Scalars['String']>;
  /** 정책 타입 */
  type: PointMileagePolicyType;
};

export type PointMileagePolicyForUseList = {
  /** 사용 정책 목록 */
  item_list: Array<PointMileagePolicyForUse>;
};

export type PointMileagePolicyList = {
  /** 마일리지 정책 목록 */
  item_list: Array<PointMileagePolicy>;
  /** 마일리지 정책 총 개수 */
  total_count: Scalars['Int'];
};

export enum PointMileagePolicyTargetType {
  GLOBAL = 'GLOBAL',
}

export enum PointMileagePolicyType {
  MAXIMUM_ADD_LIMIT = 'MAXIMUM_ADD_LIMIT',
  MILEAGE_UNAVAILABLE = 'MILEAGE_UNAVAILABLE',
  MINIMUM_HOLD_FOR_USAGE = 'MINIMUM_HOLD_FOR_USAGE',
  MINIMUM_USAGE_LIMIT = 'MINIMUM_USAGE_LIMIT',
  USAGE_LIMIT = 'USAGE_LIMIT',
  USAGE_UNIT = 'USAGE_UNIT',
}

/** MileageTargetList 의 입력 */
export type PointMileageTargetListInput = {
  product_id: Scalars['String'];
  shop_id: Scalars['String'];
};

export type PointMileageTransaction = {
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 거래 날짜 */
  date_transacted: Scalars['CrTimestamp'];
  /** 마일리지 내역 설명 */
  description: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 적립된 마일리지 금액 */
  mileage: Scalars['Int'];
  mileage_transaction_detail_list: Array<PointMileageTransactionDetail>;
  /** 마일리지 내역과 연결시킬 정보 */
  site_mileage_target_id: Scalars['String'];
  /** 요청 ENUM 타입 */
  type: PointMileageTransactionType;
  /** 유저 계정 ID */
  user_account_id: Scalars['String'];
};

export type PointMileageTransactionDetail = {
  date_created: Scalars['CrTimestamp'];
  date_expired?: Maybe<Scalars['CrTimestamp']>;
  date_transacted: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  mileage: Scalars['Int'];
  mileage_transaction_id: Scalars['Int'];
  type: PointMileageTransactionType;
};

/** 마일리지 거래 내역 */
export type PointMileageTransactionList = {
  /** 마일리지 거래내역 */
  item_list: Array<PointMileageTransaction>;
  /** 마일리지 거래 내역 총 개수 */
  total_count: Scalars['Int'];
};

export enum PointMileageTransactionType {
  ADDED_ADVERTISEMENT_REWARD = 'ADDED_ADVERTISEMENT_REWARD',
  ADDED_COMPENSATION = 'ADDED_COMPENSATION',
  ADDED_CONFIRM_ORDER = 'ADDED_CONFIRM_ORDER',
  ADDED_CONTENT_REWARD = 'ADDED_CONTENT_REWARD',
  ADDED_CREATE_PRODUCT_REVIEW = 'ADDED_CREATE_PRODUCT_REVIEW',
  ADDED_CS_REFUND_COUPON = 'ADDED_CS_REFUND_COUPON',
  ADDED_CS_REFUND_DELIVERY = 'ADDED_CS_REFUND_DELIVERY',
  ADDED_CS_REFUND_NORMAL = 'ADDED_CS_REFUND_NORMAL',
  ADDED_MISSION_COMPLETE_REWARD = 'ADDED_MISSION_COMPLETE_REWARD',
  ADDED_PARTIAL_REFUND_ORDER = 'ADDED_PARTIAL_REFUND_ORDER',
  ADDED_PROMOTION_CONFIRM_ORDER = 'ADDED_PROMOTION_CONFIRM_ORDER',
  ADDED_REFUND_ORDER = 'ADDED_REFUND_ORDER',
  ADDED_REFUND_ORDER_FAILED = 'ADDED_REFUND_ORDER_FAILED',
  ADDED_REWARD = 'ADDED_REWARD',
  ADDED_SELLER = 'ADDED_SELLER',
  ADDED_SELLER_BEST_REVIEW = 'ADDED_SELLER_BEST_REVIEW',
  ADDED_SELLER_FIRST_PURCHASE = 'ADDED_SELLER_FIRST_PURCHASE',
  ADDED_SELLER_FIRST_REVIEW = 'ADDED_SELLER_FIRST_REVIEW',
  ADDED_SELLER_NORMAL_PURCHASE = 'ADDED_SELLER_NORMAL_PURCHASE',
  ADDED_TALK_LOUNGE_ANSWER_ADOPTION_REWARD = 'ADDED_TALK_LOUNGE_ANSWER_ADOPTION_REWARD',
  ADDED_TARGET_EVENT = 'ADDED_TARGET_EVENT',
  ADDED_WELFARE = 'ADDED_WELFARE',
  ADDED_ZIGZIN_COMPENSATION = 'ADDED_ZIGZIN_COMPENSATION',
  EXPIRED = 'EXPIRED',
  EXPIRED_ABUSING = 'EXPIRED_ABUSING',
  OFFSET_ADDED = 'OFFSET_ADDED',
  OFFSET_EXCESS_WITHDRAW = 'OFFSET_EXCESS_WITHDRAW',
  USED_CREATE_TALK_LOUNGE_ASK = 'USED_CREATE_TALK_LOUNGE_ASK',
  USED_ORDER = 'USED_ORDER',
  USED_PARTICIPATE_LUCKY_BALL = 'USED_PARTICIPATE_LUCKY_BALL',
  WITHDRAWN_CONFIRM_ORDER = 'WITHDRAWN_CONFIRM_ORDER',
  WITHDRAWN_MANUAL = 'WITHDRAWN_MANUAL',
  WITHDRAWN_PROMOTION_CONFIRM_ORDER = 'WITHDRAWN_PROMOTION_CONFIRM_ORDER',
}

export type PointMileageWallet = {
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 마일리지 */
  mileage: Scalars['Int'];
  /** 사이트 ID */
  site_id: Scalars['ID'];
  /** 유저 계정 ID */
  user_account_id: Scalars['String'];
};

export type PointRefundMileage = {
  /** 소멸 마일리지 */
  expire_refund_mileage: Scalars['Int'];
  /** 환불 마일리지 */
  total_refund_mileage: Scalars['Int'];
};

export enum PointRefundMileageResponsibilityType {
  SELLER = 'SELLER',
  USER = 'USER',
}

/** 다통화 포인트 정보 */
export type PointWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환 */
  currency: Scalars['String'];
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  point_without_decimal: Scalars['Int'];
};

/** 정책 칩 타입 */
export enum PolicyChipType {
  BEAUTY_BRAND = 'BEAUTY_BRAND',
  CELEB = 'CELEB',
  EVENT = 'EVENT',
  FASHION_BRAND = 'FASHION_BRAND',
  KIDS = 'KIDS',
  LIFE_BRAND = 'LIFE_BRAND',
  MEMBERSHIP = 'MEMBERSHIP',
  MY_PICK = 'MY_PICK',
  ORDER_LIMIT = 'ORDER_LIMIT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  SHIPPING_FEE = 'SHIPPING_FEE',
  SOHO = 'SOHO',
  UNLIMIT = 'UNLIMIT',
  ZONLY = 'ZONLY',
}

export enum PolicyDiscountType {
  AMOUNT_DISCOUNT = 'AMOUNT_DISCOUNT',
  RATE_DISCOUNT = 'RATE_DISCOUNT',
}

/** 정책 플랫폼 타입 */
export enum PolicyPlatform {
  ALL = 'ALL',
  APP = 'APP',
  WEB = 'WEB',
}

export type PopularKeyword = {
  /** 인기 검색어 키워드 */
  keyword: Scalars['String'];
  /** 인기 검색어 순위 */
  ranking: Scalars['Int'];
};

export type PopularKeywordList = {
  item_list: Array<PopularKeyword>;
};

export type PopularProduct = {
  /** 호스팅사(cafe24, makeshop등) product_no */
  external_product_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 메인 image url */
  image_url: Scalars['String'];
  /** 메인 image url의 원본 */
  original_image_url: Scalars['String'];
  /** 상품의 호스팅사(cafe24, makeshop등) url */
  url?: Maybe<Scalars['String']>;
};

/** deprecated */
export type PostIdListWithCategory = {
  category: Scalars['String'];
  end_cursor?: Maybe<Scalars['String']>;
  post_id_list?: Maybe<Array<GridViewItem>>;
};

export type PostListByUserResponse = {
  id_list: Array<Scalars['ID']>;
};

export enum PostOrder {
  /** 최신순 */
  DATE_CREATED = 'DATE_CREATED',
  /** 좋아요순 */
  LIKE_COUNT = 'LIKE_COUNT',
  /** 인기순 */
  TOP_LIKE = 'TOP_LIKE',
}

export type PostTag = {
  count: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** 알림 내 버튼 정보 */
export type PostboxButton = {
  /** 클릭 시 이동 url */
  link_url?: Maybe<Scalars['String']>;
  /** 버튼 명 */
  name?: Maybe<Scalars['String']>;
};

export type PostboxExtraInfo = {
  buttons?: Maybe<Array<PostboxButton>>;
};

export type PostboxResponse = {
  /** 캠페인 ID */
  campaign_id?: Maybe<Scalars['String']>;
  /** 사용자 알림 유형 */
  category: Scalars['String'];
  /** 알림 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 알림 확인일 */
  date_read?: Maybe<Scalars['CrTimestamp']>;
  /** 버튼 등 알림에 부가적인 요소 정보 */
  extra_info?: Maybe<PostboxExtraInfo>;
  /** 알림 아이콘 url */
  icon_image_url?: Maybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 알림 내용 이미지 url */
  image_url?: Maybe<Scalars['String']>;
  /** 알림 내용 이미지 url 목록 */
  image_url_list?: Maybe<Array<Scalars['String']>>;
  /** 알림 확인 여부 */
  read: Scalars['Boolean'];
  /** 스토어 ID */
  shop_id?: Maybe<Scalars['ID']>;
  /** 알림 구독한 스토어 정보 */
  shop_info?: Maybe<UserAccountNotificationShop>;
  /** 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수 */
  shop_unseen_count?: Maybe<Scalars['Int']>;
  /** 사이트 ID */
  site_id?: Maybe<Scalars['ID']>;
  /** 알림 표시 text */
  text: Scalars['String'];
  /** 알림 표시 title */
  title?: Maybe<Scalars['String']>;
  /** 해당하는 사용자(uuid or user_account_id) */
  uid: Scalars['String'];
  /** Landing url */
  url: Scalars['String'];
};

export enum PostingInfoType {
  /** 일반 안내 */
  DEFAULT_INFO = 'DEFAULT_INFO',
  /** 포인트 지급 안내 */
  POINTED_INFO = 'POINTED_INFO',
}

export type PresignedUrlInfo = {
  cf_url: Scalars['String'];
  presigned_url: Scalars['String'];
};

export type PriceWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: UxPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

export type PriceWithCurrencyInput = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: UxPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

/** processGoodsStatEventsForWeb의 입력 */
export type ProcessGoodsStatEventsForWebInput = {
  event_data: Scalars['CrJson'];
  event_type: GoodsStatEventType;
};

/** processKmcAuthentication의 입력 */
export type ProcessKmcAuthenticationInput = {
  cert_num: Scalars['String'];
  rec_cert: Scalars['String'];
};

/** processKmcAuthentication의 응답값 */
export type ProcessKmcAuthenticationResult = {
  /** 본인인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key: Scalars['String'];
};

/** ProcessUserAccountAuthenticationResponse의 입력 */
export type ProcessUserAccountAuthenticationResponseInput = {
  cert_num: Scalars['String'];
  /** 성인 인증 여부 */
  is_adult_certification?: InputMaybe<Scalars['Boolean']>;
  rec_cert: Scalars['String'];
};

/** ProcessUserAccountAuthenticationResponse의 결과 */
export type ProcessUserAccountAuthenticationResponseResult = {
  /** 인증 ID */
  authenticate_id: Scalars['String'];
  /** 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 사용자 휴대폰 번호 */
  mobile_tel?: Maybe<Scalars['String']>;
};

export type Product = {
  /** 상품 부가 속성 리스트 */
  attribute_list: Array<ProductAttribute>;
  /** 상품 수정자 */
  auditor?: Maybe<Scalars['String']>;
  /** 묶음배송 타입 */
  bundle_type: ProductShippingBundleType;
  /** 카테고리 */
  category?: Maybe<ProductCategory>;
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 상품 노출 상태 */
  display_status: ProductDisplayStatus;
  /** 에디터 모드 */
  editor_mode: EditorMode;
  /** 입점형/연동형 타입 */
  entry_type: EntryType;
  /** 필수정보제공고시 code */
  essential_code?: Maybe<Scalars['String']>;
  /** @deprecated entryType=CRAWLING_API 인 경우 external_code를 대신 사용하고 그 외의 경우에는 null이다 */
  external_product_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 상품의 이미지 리스트 */
  image_list: Array<ProductImage>;
  /** 국제배송 */
  international_delivery?: Maybe<ProductInternationalDelivery>;
  /** 상품의 품목 리스트 */
  item_list: Array<ItemForProduct>;
  /** 다국어 */
  languages: ProductLanguages;
  /** 모델정보 */
  models?: Maybe<Models>;
  /** 상품명 */
  name: Scalars['String'];
  /** 상품 품목 옵션 리스트 */
  option_list: Array<ProductOption>;
  /**
   * 결제 타입
   * @deprecated payment_type = EXTERNAL은 전부 product_status = INVALID로 통합됨.
   */
  payment_type: PaymentType;
  /** 상품 상태 */
  product_status: ProductStatus;
  /** 상품 타입 */
  product_type: ProductType;
  /** 상품 판매 상태 */
  sales_status: ProductSalesStatus;
  /** 검색어 추천 키워드 */
  search_keyword_list?: Maybe<Array<ProductSearchKeyword>>;
  /** croquis-session-data와 매칭되는 site_country의 정보 */
  session_site_country?: Maybe<ProductSiteCountry>;
  /** 발송소요일 */
  shipping_days: Scalars['Int'];
  /** 배송비 타입 */
  shipping_fee_type: ShippingFeeType;
  /** 상점 ID */
  shop_id: Scalars['Int'];
  /** 사이트 컨트리별 데이터 */
  site_country_list: Array<ProductSiteCountry>;
  /** 사이즈 정보 */
  size_list?: Maybe<Array<ProductSize>>;
  /** 솔루션 */
  solution?: Maybe<Scalars['String']>;
  /** 도매 상품 ID */
  wholesale_product_id?: Maybe<Scalars['ID']>;
};

/** 상품 아이템의 추가 옵션 정보(연동형 옵션/추가입력 옵션) */
export type ProductAdditionalOption = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션 구분자(추가입력 옵션은 code 없음) */
  option_code?: Maybe<Scalars['String']>;
  /** 옵션값 */
  value: Scalars['String'];
  /** 옵션값 구분자(추가입력 옵션은 code 없음) */
  value_code?: Maybe<Scalars['String']>;
};

/** 상품에 추가적으로 필요한 옵션 정보 */
export type ProductAdditionalOptionInput = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션 구분자(추가입력 옵션은 code 없음) */
  option_code?: InputMaybe<Scalars['String']>;
  /** 옵션값 */
  value: Scalars['String'];
  /** 옵션값 구분자(추가입력 옵션은 code 없음) */
  value_code?: InputMaybe<Scalars['String']>;
};

export type ProductAddressResponse = {
  /** 반품지 주소 ID */
  return_id: Scalars['ID'];
  /** 출고지 주소 ID */
  shipping_id: Scalars['ID'];
};

export type ProductAttribute = {
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /**
   * value
   * @deprecated 응답값 변경으로 인해 key과 values사용
   */
  value?: Maybe<ProductAttributeValueV2>;
  /** values */
  value_list: Array<Scalars['String']>;
};

export type ProductAttributeDistributionItem = {
  buckets: Array<TermBucket>;
  /** 상품 속성 키 (ex. detail, fit) */
  key: Scalars['String'];
  /** 속성명 (ex. 디테일, 핏) */
  name: Scalars['String'];
};

export type ProductAttributeLanguages = {
  en?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
};

export type ProductAttributeValueLanguages = {
  languages: ProductAttributeLanguages;
};

export type ProductAttributeValueV2 = {
  data?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<ProductAttributeValueLanguages>;
  value_list?: Maybe<Array<ProductAttributeValueLanguages>>;
};

/**
 * [통합 상품 카드 type]
 *  - notion : https://www.notion.so/croquis/M1-96e02053a5a143dab60e9057f4744070
 *  - notes : type ProductCard 를 포함한 하위 타입에 임의로 필드 추가를 하시면 안됩니다. 필드 추가를 원할 경우, 디스커버리BE개발팀의 Robin, Glen 에게 문의 부탁드립니다.
 */
export type ProductCard = {
  /** 기타 정보 */
  additional_info: ProductCardAdditionalInfo;
  /** badge, emblem 필드 일원화 */
  badge: ProductCardBadge;
  /** 상품 번호 */
  catalog_product_id: Scalars['ID'];
  /** 카테고리 정보 일원화 */
  category: ProductCardCategory;
  /** boolean 타입 단순 플래그 정보 일원화 */
  flag: ProductCardFlag;
  /** image 정보 일원화 */
  image: ProductCardImage;
  /** 가격 정보 일원화 */
  price: ProductCardPrice;
  /** 랭킹 정보 일원화 */
  ranking: ProductCardRanking;
  /** review 정보 일원화 */
  review: ProductCardReview;
  /** 배송 정보 일원화 */
  shipping: ProductCardShipping;
  /** shop 정보 일원화 */
  shop: ProductCardShop;
  /** 상태 정보 일원화 */
  status: ProductCardStatus;
  /** 상품명 */
  title: Scalars['String'];
  /** url 정보 일원화 */
  url: ProductCardUrl;
};

export type ProductCardAdditionalInfo = {
  /** 광고 id */
  aid?: Maybe<Scalars['String']>;
  /** 앱 내 browsing 타입 */
  browsing_type: UxBrowsingType;
  /** 상품카드 컴포넌트 스타일. 3열 노출이면서, 헤더가 있는 상품카드 (Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  /** 상품 옵션 컬러칩 리스트 */
  color_option_list?: Maybe<Array<UxGoodsCardColorChip>>;
  /** 상품이 한줄에 몇개 표기할지 */
  column_count?: Maybe<Scalars['Int']>;
  /** 세일탭 조회수 */
  fomo?: Maybe<UxGoodsCardFomo>;
  /** 성과 측정 데이터 */
  performance_measurement?: Maybe<Scalars['String']>;
  /** 게시글 연동 상품 타입 */
  post_product_type?: Maybe<EPickPostProductType>;
  /** 상품 재고 수량. 최대 50개의 상품목록을 요청할 때 사용 가능. 재고 수량은 1분간 캐싱 */
  stock?: Maybe<Scalars['Int']>;
  /** 상품명 줄 수 */
  title_line_number?: Maybe<Scalars['Int']>;
  /** User Behavior Logs */
  ubl?: Maybe<UxUbl>;
};

export type ProductCardBadge = {
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 쿠폰 뱃지 목록 */
  coupon_badge_component_list?: Maybe<Array<UxBadgeComponent>>;
  /** 가로형 상품카드 메타 엠블렘 */
  metadata_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 뱃지 (최저가도전, 1+1, 자체제작, 무료배송, 브랜드 등) */
  plp_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
};

export type ProductCardCategory = {
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<UxCatalogProductCategory>>;
};

export type ProductCardFlag = {
  /** recommend_item_list 호출 여부 */
  has_recommend_item?: Maybe<Scalars['Boolean']>;
  /** 광고 뱃지 여부 */
  is_ad?: Maybe<Scalars['Boolean']>;
  /** 브랜드 상품 여부 */
  is_brand?: Maybe<Scalars['Boolean']>;
  /** 신상품 표기 */
  is_new?: Maybe<Scalars['Boolean']>;
  /** 찜 상품 여부 */
  is_saved_product?: Maybe<Scalars['Boolean']>;
  /** 비슷한 상품 검색 여부 */
  is_similar_search?: Maybe<Scalars['Boolean']>;
  /** zonly 상품 여부 */
  is_zonly?: Maybe<Scalars['Boolean']>;
};

export type ProductCardImage = {
  /** 이미지 경량화(still) jpeg_image_url */
  jpeg_url?: Maybe<Scalars['String']>;
  /** 이미지 비율 */
  ratio?: Maybe<Scalars['Float']>;
  /** 원본 image_url */
  url?: Maybe<Scalars['String']>;
  /** 상품 카드에 비디오 형식으로 이미지 노출 (광고팀에서 사용) */
  video_url?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_url?: Maybe<Scalars['String']>;
};

export type ProductCardPrice = {
  /** 최종 노출 가격 */
  final_price: Scalars['Int'];
  /** 최종 노출 가격 기준 할인율 */
  final_price_discount_rate: Scalars['Int'];
  /** 노출 원가 */
  max_price: Scalars['Int'];
};

export enum ProductCardProductPromotionStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type ProductCardPromotionBadge = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductCardRanking = {
  /** 숫자를 표기하기 위한 필드 */
  ranking?: Maybe<Scalars['Int']>;
  /** 베스트탭 순위 등락 */
  ranking_fluctuation?: Maybe<RankingFluctuation>;
};

export type ProductCardReview = {
  /** 상품의 리뷰 갯수 */
  count?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 갯수 */
  count_displayed?: Maybe<Scalars['String']>;
  /** 예측 리뷰 점수 */
  predicated_score?: Maybe<Scalars['Float']>;
  /** 상품의 리뷰 점수 */
  score?: Maybe<Scalars['Float']>;
};

export enum ProductCardSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export type ProductCardShipping = {
  /** 당일 배송 문구 */
  arrival_text?: Maybe<UxCommonText>;
  /** 무료배송 여부 */
  is_free_shipping?: Maybe<Scalars['Boolean']>;
  /** 직진배송 또는 스토어배송 타입 */
  zigzin_type?: Maybe<ProductCardZigzinType>;
};

export type ProductCardShop = {
  /** 연령대 필터 리스트 */
  age_list?: Maybe<Array<Scalars['String']>>;
  /** 카테고리(의류, 가방 등) */
  category?: Maybe<Scalars['String']>;
  /** 삭제 동작 */
  delete_action?: Maybe<Scalars['String']>;
  /** 쇼핑몰 id */
  id: Scalars['ID'];
  /** 쇼핑몰 주 도메인 */
  main_domain?: Maybe<Scalars['String']>;
  /** 쇼핑몰 이름 */
  name?: Maybe<Scalars['String']>;
  /** 쇼핑몰 상태(NORMAL, RENEWAL, DORMANCY, DELETION_REQUEST, STOPPED, BAD_WORKING, NOT_DEFINED) */
  status?: Maybe<Scalars['String']>;
  /** 스타일 목록 */
  style_list?: Maybe<Array<Scalars['String']>>;
};

export type ProductCardStatus = {
  /** 상품 판매 상태 */
  sales_status?: Maybe<ProductCardSalesStatus>;
};

export type ProductCardUrl = {
  /** 상품 카드 클릭시 이동할 url 주소 */
  landing_url: Scalars['String'];
};

export type ProductCardUxComponent = UxComponent & {
  /** 리패치하는 목적으로 사용 (App only, Web X) */
  position: Scalars['Int'];
  /** 상품 카드 정보 */
  product_card: ProductCard;
  /** 모델 맵핑 용도로 사용 (App only, Web X) */
  type: UxComponentType;
};

export enum ProductCardZigzinType {
  /** 스토어배송 상품 */
  GENERAL = 'GENERAL',
  /** 직진배송 상품 */
  ZONLY = 'ZONLY',
}

export type ProductCategory = {
  /** 카테고리 full name */
  category_full_name: Scalars['String'];
  /** category DB의 category id */
  category_id: Scalars['ID'];
};

export type ProductCustomTab = {
  name: Scalars['String'];
  products_preset_uuid: Scalars['String'];
};

export type ProductCustomTabsSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type ProductCustomTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  button_title: Scalars['String'];
  default_tab: Scalars['Int'];
  store_unique: Scalars['Boolean'];
  tabs: Array<ProductCustomTab>;
  title: Scalars['String'];
  type: BenefitSectionType;
};

/** 상품 상세 정보 */
export type ProductDetailInfo = {
  /** 최소구매수량 */
  minimum_order_quantity?: Maybe<Scalars['Int']>;
  /** 최소구매수량 타입 */
  minimum_order_quantity_type: MinimumOrderQuantityType;
  /** 상품의 특성관련 정보 */
  trait_list: Array<Scalars['String']>;
};

export type ProductDiscountInfo = {
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 할인 정책 타입 (PROMOTION, PROMOTION_DISCOUNT_PRICE) */
  discount_policy_type: Scalars['String'];
  /** 할인률 (할인 정책 기준) */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 할인 타입 */
  discount_type: ProductDiscountType;
  /** 노출 할인률 (product_original_price 기준) */
  display_discount_rate_bp: Scalars['Int'];
  /** 상품 프로모션 정보 */
  promotion_applied_item?: Maybe<ItemPromotionAppliedInfo>;
};

/** 할인 타입 */
export enum ProductDiscountType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export enum ProductDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type ProductDisplayStatusInput = {
  /** 진열상태 */
  display_status: ProductDisplayStatus;
  id: Scalars['ID'];
};

export type ProductDisplayStatusListInput = {
  /** 상품목록 */
  product_list: Array<ProductDisplayStatusInput>;
};

export type ProductEssential = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ProductFunnelInfoInput = {
  /** 퍼널 정보 */
  funnel_info: FunnelInfoInput;
  /** 상품 레코드 ID */
  product_id: Scalars['ID'];
};

export type ProductGridSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type ProductGridSectionProps = {
  deeplink_url?: Maybe<Scalars['String']>;
  grid_type: GridSectionGridType;
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  title: Scalars['String'];
  type: BenefitSectionType;
  user_custom_title?: Maybe<Scalars['String']>;
};

export type ProductIdMapping = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** Goods ID */
  goods_id?: Maybe<Scalars['Int']>;
  /** 기존 Product ID */
  legacy_product_id?: Maybe<Scalars['Int']>;
  /** Zonly item ID */
  zonly_item_id?: Maybe<Scalars['Int']>;
};

export type ProductImage = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 이미지 높이 */
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** 이미지 타입 */
  image_type: ProductImageType;
  /** zigzag CDN으로 제공하는 원본 이미지. 필요하면 url값에 params로 다이나믹 리사이징 속성을 추가. https://www.notion.so/croquis/Dynamic-Image-Resizing-9726f519b5b040d8892ff96dbfd0e1e6 참고 */
  image_url: Scalars['String'];
  /** 이미지 key */
  key?: Maybe<Scalars['String']>;
  /** 이미지 URL(도메인 포함) */
  origin_url: Scalars['String'];
  /** 이미지 너비 */
  width?: Maybe<Scalars['Int']>;
};

export enum ProductImageType {
  MAIN = 'MAIN',
  SUB = 'SUB',
}

export type ProductInternationalDelivery = {
  /** 혼용율 */
  composition?: Maybe<Scalars['String']>;
  /** 원산지 두자리 국가코드 사용 */
  origin: Scalars['String'];
  /** 무게 g */
  weight?: Maybe<Scalars['Int']>;
};

export type ProductItem = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 품목 노출 상태 */
  display_status: ProductDisplayStatus;
  id: Scalars['ID'];
  /** 품목의 부가 속성 리스트 */
  item_attribute_list: Array<ItemAttribute>;
  /** 품목명 */
  name: Scalars['String'];
  /** 아이템이 속한 상품 */
  product: Product;
  /** 품목 판매 상태 */
  sales_status: ProductSalesStatus;
  /** croquis-session-data와 매칭되는 site_country의 정보 */
  session_site_country?: Maybe<ItemSiteCountry>;
};

/** 상품 아이템 정보 */
export type ProductItemInfoInput = {
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 아이템 ID */
  product_item_id: Scalars['ID'];
  /** 아이템별 수량 */
  quantity: Scalars['Int'];
  /** 샵 아이디 */
  shop_id?: InputMaybe<Scalars['Int']>;
};

/** 상품 아이템 옵션 */
export type ProductItemOption = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션값 */
  value: Scalars['String'];
};

/** 상품 아이템 옵션 타입 */
export enum ProductItemOptionType {
  ADDITIONAL = 'ADDITIONAL',
  BASIC = 'BASIC',
}

/** 상품 아이템 상태 */
export enum ProductItemStatus {
  NORMAL = 'NORMAL',
  SOLD_OUT = 'SOLD_OUT',
}

export type ProductLanguage = {
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 상품본문 등록 타입 */
  description_registration_type?: Maybe<LanguageRegistrationType>;
  /** 상품정보제공 고시 */
  essentials?: Maybe<Array<ProductEssential>>;
  /** 필수정보제공고시 등록 타입 */
  essentials_registration_type?: Maybe<LanguageRegistrationType>;
  /** ID */
  id: Scalars['ID'];
  /** 상품명 */
  name: Scalars['String'];
  /** 상품명 등록 타입 */
  name_registration_type: LanguageRegistrationType;
};

export type ProductLanguages = {
  en?: Maybe<ProductLanguage>;
  ja?: Maybe<ProductLanguage>;
  ko?: Maybe<ProductLanguage>;
};

export type ProductList = {
  item_list: Array<Product>;
  total_count: Scalars['Int'];
};

export type ProductListForEPickPostResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<EPickProduct>;
  /** 상품 목록 (v2: 통합 상품카드) */
  item_list_v2: Array<ProductCard>;
};

export type ProductListInput = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type ProductModel = {
  /** 모델 노출 상태 */
  display_status: ModelDisplayStatus;
  /** 모델 id */
  id: Scalars['ID'];
  /** 등록 타입 */
  registration_type: ModelRegistrationType;
  /** 모델 착용 사이즈 */
  sizes: Array<Scalars['String']>;
};

export type ProductModelHeightDistribution = {
  /** 키 필터 간격 */
  interval: Scalars['Int'];
  /** 모델의 최대 키 */
  max_height: Scalars['Int'];
  /** 모델의 최소 키 */
  min_height: Scalars['Int'];
};

export type ProductOption = {
  id: Scalars['ID'];
  /** 다국어 */
  languages: ProductOptionLanguages;
  /** 속성명, croquis-session-data의 language 값을 이용하여 languages의 값을 매핑해준다 */
  name: Scalars['String'];
  /** 순서 */
  order: Scalars['Int'];
  /** 속성 값 */
  value_list: Array<ProductOptionValue>;
};

/** 선택된 옵션 상세 정보 */
export type ProductOptionDetail = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션값 */
  value: Scalars['String'];
};

export type ProductOptionLanguage = {
  name: Scalars['String'];
  /** 다국어 등록 타입 */
  registration_type: LanguageRegistrationType;
};

export type ProductOptionLanguages = {
  en?: Maybe<ProductOptionLanguage>;
  ja?: Maybe<ProductOptionLanguage>;
  ko?: Maybe<ProductOptionLanguage>;
};

export enum ProductOptionType {
  INDEPENDENT = 'INDEPENDENT',
  MIX = 'MIX',
  NO = 'NO',
  PRODUCT_LINK = 'PRODUCT_LINK',
}

export type ProductOptionValue = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소 */
  image_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
};

export type ProductPrice = {
  /** 삭제여부 */
  deleted: Scalars['Boolean'];
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 정상가 */
  original_price: Scalars['Int'];
};

/** 상품 리뷰 */
export type ProductReview = {
  /** 리뷰 부가 정보 */
  additional_description?: Maybe<Scalars['String']>;
  /** 상품 리뷰 첨부파일 목록 */
  attachment_list: Array<ProductReviewAttachment>;
  /** 상품리뷰 특성 평가 배열 */
  attribute_list: Array<ProductReviewAttribute>;
  /** 카탈로그 상품 ID */
  catalog_product_id: Scalars['ID'];
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 국가 정보 code */
  country: Scalars['String'];
  /** 국가 이름 */
  country_name: Scalars['String'];
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 상품리뷰 삭제 정보 */
  delete_info?: Maybe<ProductReviewStatusChange>;
  /** 도움안돼요 수 */
  dislike_count: Scalars['Int'];
  /** 노출제한 여부 */
  display_limited: Scalars['Boolean'];
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 언어 code */
  language: Scalars['String'];
  /** 도움돼요 수 */
  like_count: Scalars['Int'];
  /** 칭찬하기 리스트 */
  like_list: Array<ProductReviewLikes>;
  /** 상품리뷰 상품주문 정보 */
  order_item: ProductReviewOrderItem;
  /** 상품주문 번호 */
  order_item_number: Scalars['String'];
  /** 상품 레코드 ID */
  product_id: Scalars['ID'];
  /** 상품리뷰 상품유형 타입 */
  product_type: ProductReviewProductType;
  /** 평점 */
  rating: Scalars['Int'];
  /** 상품 리뷰 답글 목록 */
  reply_list: Array<ProductReviewReply>;
  /** 요청한 사용자별 정보 */
  requested_user?: Maybe<ProductReviewRequestedUser>;
  /** 리뷰 작성자 사용자계정 정보 */
  reviewer: ProductReviewUserAccount;
  /** 리뷰 이벤트 선정 보상 정보 */
  seller_event_reward_info?: Maybe<ProductReviewSellerEventRegister>;
  /** 리뷰가 작성된 상품의 쇼핑몰 */
  shop: Shop;
  /** 쇼핑몰 레코드 ID */
  shop_id: Scalars['ID'];
  /** 사이트 ID */
  site_id: Scalars['ID'];
  /** 상품리뷰 상태 */
  status: ProductReviewStatus;
  /** 번역된 리뷰 내용 */
  translated_contents?: Maybe<Scalars['String']>;
  /** 번역 가능 여부 */
  translation_enabled?: Maybe<Scalars['Boolean']>;
  /** 상품리뷰 유형 */
  type: ProductReviewType;
  /** 수정가능 여부 */
  updatable: Scalars['Boolean'];
  /** 사용자 계정 신체정보 레코드 ID(api 내부에서만 사용. 실제로는 무조건 null을 반환) */
  user_account_body_id?: Maybe<Scalars['ID']>;
  /** 사용자 계정의 도움안돼요 여부 */
  user_account_disliked: Scalars['Boolean'];
  /** 사용자 레코드 ID(api 내부에서만 사용. 실제로는 무조건 null을 반환) */
  user_account_id?: Maybe<Scalars['ID']>;
  /** 상품리뷰 사용자 계정 정보 */
  user_account_info: ProductReviewUserAccountInfo;
  /** 사용자 계정의 도움돼요 여부 */
  user_account_liked: Scalars['Boolean'];
  /** 유저 댓글 수 */
  user_reply_count: Scalars['Int'];
};

/** 상품 리뷰 */
export type ProductReviewAttachment_ListArgs = {
  status_list?: InputMaybe<Array<ProductReviewAttachmentStatus>>;
};

/** 상품리뷰 신고 사유 카테고리 */
export enum ProductReviewAbuseReportReasonCategory {
  COPYRIGHT_VIOLATION = 'COPYRIGHT_VIOLATION',
  ETC = 'ETC',
  INSULTING_OR_OBSCENE_CONTENTS = 'INSULTING_OR_OBSCENE_CONTENTS',
  NOT_RELATED_TO_SHOP_OR_PRODUCT = 'NOT_RELATED_TO_SHOP_OR_PRODUCT',
  PERSONAL_CONTENTS = 'PERSONAL_CONTENTS',
  PROMOTIONAL_CONTENTS = 'PROMOTIONAL_CONTENTS',
}

/** 상품 리뷰 첨부파일 */
export type ProductReviewAttachment = {
  /** S3 Object Key */
  object_key: Scalars['String'];
  /** 원본 파일 URL */
  original_url: Scalars['String'];
  /** 상품 레코드 ID */
  product_id: Scalars['ID'];
  /** 상위 상품 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
  /** 상품리뷰 첨부파일 상태 */
  status: ProductReviewAttachmentStatus;
  /**
   * 썸네일 파일 URL
   * @deprecated 이미지팜 전환으로 사용하지 않습니다.
   */
  thumbnail_url: Scalars['String'];
  /** 상품 리뷰 유형 */
  type: ProductReviewType;
};

/** 상품 리뷰 첨부파일 목록 정보 */
export type ProductReviewAttachmentList = {
  item_list: Array<ProductReviewAttachment>;
  total_count: Scalars['Int'];
};

/** 상품 리뷰 첨부파일 상태 */
export enum ProductReviewAttachmentStatus {
  DELETED_BY_ADMIN = 'DELETED_BY_ADMIN',
  EXCLUDED_BY_USER = 'EXCLUDED_BY_USER',
  NORMAL = 'NORMAL',
}

/** 상품리뷰 특성 평가 */
export type ProductReviewAttribute = {
  /** 답변 */
  answer: ProductReviewAttributeAnswer;
  /** 질문 */
  question: ProductReviewAttributeQuestion;
};

export type ProductReviewAttributeAnswer = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProductReviewAttributeImage = {
  image_url: Scalars['String'];
  title: Scalars['String'];
};

export type ProductReviewAttributeOption = {
  /** 특성 평가 항목 */
  answer_list: Array<ProductReviewAttributeValue>;
  /** 특성 평가 질문 */
  question: ProductReviewAttributeValue;
};

export type ProductReviewAttributeQuestion = {
  category: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProductReviewAttributeValue = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProductReviewBadge = {
  /**
   * 뱃지  타입
   * - TEXT
   */
  type: Scalars['String'];
};

/** 배너 정보 */
export type ProductReviewBanner = {
  /** 배너 배경 */
  background: ReviewBannerBackground;
  /** 배너 배경 하단 텍스트 */
  below_background_text?: Maybe<ReviewBannerText>;
  /** 배너 하단 노출 아이템 리스트 */
  bottom_item_list?: Maybe<Array<ProductReviewBannerBottomItem>>;
  /** 배너 하단 링크 */
  bottom_link?: Maybe<ProductReviewBannerBottomLink>;
  /** 하단 텍스트 */
  bottom_text?: Maybe<ReviewBannerText>;
  /** 우측 아이콘 */
  icon?: Maybe<ReviewBannerIcon>;
  /** 배너 ID */
  id: Scalars['ID'];
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 중앙 텍스트 */
  middle_text?: Maybe<ReviewBannerText>;
  /** 최상단 텍스트 */
  title_text?: Maybe<ReviewBannerText>;
  /** 상단 텍스트 */
  top_text?: Maybe<ReviewBannerText>;
  /** 배너 타입 */
  type: ReviewBannerType;
};

export type ProductReviewBannerBottomItem = {
  left_text?: Maybe<ReviewCommonText>;
  middle_text?: Maybe<ReviewCommonText>;
};

export type ProductReviewBannerBottomLink = {
  icon?: Maybe<ReviewBannerIcon>;
  landing_url: Scalars['String'];
  text: ReviewCommonText;
};

/** 선정된 베스트 리뷰 목록 정보 */
export type ProductReviewBestReviewList = {
  item_list: Array<ProductReview>;
  total_count: Scalars['Int'];
};

export type ProductReviewCreateCondition = {
  min_attachment_count?: Maybe<Scalars['Int']>;
  min_length?: Maybe<Scalars['Int']>;
};

/** 리뷰 작성시 안내 정보 */
export type ProductReviewCreateInfo = {
  /** 상품리뷰 작성시 이미지 가이드 정보 */
  attribute_image_url_list: Array<ProductReviewAttributeImage>;
  /** 상품리뷰 작성시 입력가능한 특성평가 정보 */
  attribute_option_list: Array<ProductReviewAttributeOption>;
  /** 상품리뷰 작성시 텍스트 가이드 정보 */
  attribute_question_list: Array<Scalars['String']>;
  /** 리뷰 작성 조건 */
  create_condition?: Maybe<ProductReviewCreateCondition>;
  /** 리뷰 작성 진입 UI 정보 */
  create_ui_info?: Maybe<ProductReviewCreateUiInfo>;
  /** 작성 마감기한 */
  date_deadline: Scalars['CrTimestamp'];
  /** 리뷰 작성 관련 공지 */
  description_html?: Maybe<Scalars['String']>;
  /** 리뷰체험단 참여 여부 */
  is_product_review_experience_group: Scalars['Boolean'];
  /** 판매 여부 */
  is_selling: Scalars['Boolean'];
  /** 리뷰작성시 포인트지급 받기 위함 최소 주문 금액 */
  minimum_item_price: ProductReviewMinimumItemPrice;
  /** 리뷰 작성 관련 공지 */
  notice_html?: Maybe<Scalars['String']>;
  /** 리뷰 작성 바텀싯 공지 */
  notification?: Maybe<ProductReviewCreateNotification>;
  /** 리뷰 작성 대상 상품 주문 번호 */
  order_item_number?: Maybe<Scalars['String']>;
  /** 상품 리뷰 작성시 지급 예정인 포인트 정보 */
  point_amounts: ProductReviewPointAmounts;
  /** 작성된 리뷰 */
  product_review?: Maybe<ProductReview>;
  /** 사용자가 작성한 총 리뷰 수. 삭제처리된 리뷰도 포함한다. */
  product_reviews_count: Scalars['Int'];
  /** 평점 상품리뷰 작성 가능 여부 */
  quick_reviewable: Scalars['Boolean'];
  /** 작성 제한(불가) 정보 */
  restriction?: Maybe<ProductReviewCreateLimitInfo>;
  /** 리뷰 작성 플레이스홀더 */
  review_content_placeholder?: Maybe<Scalars['String']>;
  /** 리뷰 이벤트 배너 정보 */
  review_event?: Maybe<ProductReviewEvent>;
  /** 상품리뷰 작성 가능 여부 */
  reviewable: Scalars['Boolean'];
  /** 적립금 지급 마감 기한 */
  rewards_date_deadline: Scalars['CrTimestamp'];
  /** 유저 신체 정보 */
  user_account_body?: Maybe<UserAccountBody>;
  /** 작성 제한(주의) 정보 */
  warning?: Maybe<ProductReviewCreateLimitInfo>;
};

export type ProductReviewCreateInfoArgs = {
  /** 유저 배송 국가 */
  country?: InputMaybe<Scalars['String']>;
  /** 유저 언어 */
  language?: InputMaybe<Scalars['String']>;
  /** 리뷰 작성 대상 상품 주문 번호 */
  order_item_number?: InputMaybe<Scalars['String']>;
  /** 상품 ID */
  product_id?: InputMaybe<Scalars['ID']>;
};

/** 리뷰 작성시 안내 정보 리스트 */
export type ProductReviewCreateInfoList = {
  item_list: Array<ProductReviewCreateInfo>;
};

export type ProductReviewCreateLimitInfo = {
  /** 상품리뷰 작성제한 안내 메세지 */
  message: Scalars['String'];
  /** 상품리뷰 작성제한 제한 제목 */
  title: Scalars['String'];
};

export type ProductReviewCreateNotification = {
  message: Scalars['String'];
  title: Scalars['String'];
};

export type ProductReviewCreateUiInfo = {
  button_title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** 리뷰 페이징 Input */
export type ProductReviewCursorPaginationInput = {
  /** 주어진 키의 레코드를 건너띄고 반환한다 */
  after_key?: InputMaybe<Scalars['ID']>;
  /** 개수 제한 */
  limit_count?: InputMaybe<Scalars['Int']>;
};

/** 노출 제한된 상품 리뷰 목록 정보 */
export type ProductReviewDisplayLimitedList = {
  item_list: Array<ProductReview>;
  total_count: Scalars['Int'];
};

/** 리뷰 작성시 발급 포인트 예상 */
export type ProductReviewEstimatedIssuePoint = {
  /** 사용자 계정 ID의 첫 작성인 경우 */
  first_review_bonus: ProductReviewEstimatedIssuePointByCase;
  /** 일반적인 경우 */
  normal: ProductReviewEstimatedIssuePointByCase;
  /** 국가/상품금액에 맞는 포인트 조건별로 정리 */
  review_bonus_point_group: ReviewBonusPointGroup;
  /** 국가/상품금액에 맞는 포인트 조건별로 정리 */
  review_point_info?: Maybe<ReviewPointInfo>;
};

export type ProductReviewEstimatedIssuePointByCase = {
  /** 텍스트 리뷰 */
  text_only: Scalars['Int'];
  /** 사진 리뷰 */
  with_photo: Scalars['Int'];
};

/** 리뷰 이벤트 정보 */
export type ProductReviewEvent = {
  /** 리뷰 이벤트 뱃지 문구 */
  badge_title?: Maybe<Scalars['String']>;
  /**
   * 리뷰 이벤트 이미지 배너 정보
   * @deprecated event_banner 필드 이용
   */
  banner?: Maybe<ProductReviewEventBanner>;
  /** 리뷰 이벤트 배너 정보 */
  event_banner?: Maybe<ProductReviewBanner>;
  /** 리뷰 이벤트 진행중 뱃지 노출 여부 */
  is_event_running: Scalars['Boolean'];
  /** 진행중 리뷰 이벤트 뱃지 타이틀 */
  running_event_badge_title?: Maybe<Scalars['String']>;
};

export type ProductReviewEventArgs = {
  /** 유저 배송 국가 */
  country: Scalars['String'];
  /** 유저 언어 */
  language: Scalars['String'];
};

/** 글로벌 리뷰 적립 포인트 안내 배너 정보 */
export type ProductReviewEventBanner = {
  /** 글로벌 리뷰 적립 포인트 안내 문구 */
  description: Scalars['String'];
  /** 글로벌 리뷰 적립 포인트 안내 배너 이미지 비율 */
  image_ratio: Scalars['Float'];
  /** 글로벌 리뷰 적립 포인트 안내 배너 이미지 URL */
  image_url: Scalars['String'];
};

/** 리뷰 이벤트 정보 리스트 */
export type ProductReviewEventList = {
  item_list: Array<ProductReviewEvent>;
};

export type ProductReviewEventProductInfo = {
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
};

export type ProductReviewGuideCondition = {
  date_ended: Scalars['CrTimestamp'];
  date_expired: Scalars['CrTimestamp'];
  key: Scalars['String'];
};

export type ProductReviewGuideInfo = {
  community_guide_url: Scalars['String'];
  condition_list: Array<ProductReviewGuideCondition>;
  /** 맞춤 정보 동의 URL */
  profile_body_agreement_url: Scalars['String'];
  review_ranking_guide_url: Scalars['String'];
  /** 리뷰어 랭킹 선정 방식 URL */
  reviewer_ranking_selection_info_url: Scalars['String'];
};

/** 리뷰 작성시 발급 포인트 정보 */
export type ProductReviewIssuePointInfo = {
  /** 리뷰 작성시 발급 포인트 예상 */
  estimated_point: ProductReviewEstimatedIssuePoint;
  /** 최대 지급 예상 마일리지 */
  maximum_mileage: Scalars['Int'];
  /** 최대 지급 예상 포인트 */
  maximum_point: Scalars['Int'];
  /** 리뷰 이벤트 선정시 적립 가능 포인트 정보 */
  review_event_point_list: Array<ReviewEventPoint>;
};

/** 리뷰 칭찬 버튼 */
export type ProductReviewLikeButton = {
  icon_image_url: Scalars['String'];
  icon_url: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** 칭찬 이력 */
export type ProductReviewLikes = {
  count: Scalars['Int'];
  type: Scalars['String'];
};

/** 상품 리뷰 목록 정보 */
export type ProductReviewList = {
  attachment_total_count: Scalars['Int'];
  item_list: Array<ProductReview>;
  total_count: Scalars['Int'];
};

/** 상품리뷰 목록 정렬 */
export enum ProductReviewListOrderType {
  BEST_SCORE_DESC = 'BEST_SCORE_DESC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  LIKE_COUNT_DESC = 'LIKE_COUNT_DESC',
  RATING_ASC = 'RATING_ASC',
  RATING_DESC = 'RATING_DESC',
}

/** 리뷰 작성시 포인트 발급 최소 주문 금액 정보 */
export type ProductReviewMinimumItemPrice = {
  /** 리뷰 작성시 포인트 발급 위한 최소 주문 금액 화폐 단위 */
  currency: Scalars['String'];
  /** 리뷰 작성시 포인트 발급 위한 최소 주문 금액 */
  price: Scalars['Float'];
};

export type ProductReviewMinimunItemPrice = {
  /** 리뷰 작성시 포인트 발급 위한 최소 주문 금액 화폐 단위 */
  currency: Scalars['String'];
  /** 리뷰 작성시 포인트 발급 위한 최소 주문 금액 */
  minimun_item_price: Scalars['Float'];
};

export type ProductReviewOptionDetail = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션값의 배열 */
  value_list: Array<Scalars['String']>;
};

export type ProductReviewOrderItem = {
  /** 브라우저 타입 */
  browsing_type: BrowsingType;
  /** 상품상세 랜딩 url */
  pdp_landing_url: Scalars['String'];
  product_info: OrderedProductInfo;
};

/** 리뷰 페이징 Input */
export type ProductReviewPaginationInput = {
  /** 개수 제한 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 주어진 수의 레코드를 건너띄고 반환한다 */
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type ProductReviewPdpSummary = {
  /** 대표 한줄평 */
  attribute?: Maybe<ProductReviewSummaryAttribute>;
  /** 리뷰 수 */
  count: Scalars['Int'];
  /** 리뷰 리스트 */
  item_list: Array<ProductReview>;
  /** 평점 평균 */
  ratings_average: Scalars['Float'];
};

export type ProductReviewPointAmounts = {
  /** 적립 이벤트 배너 */
  event_banner?: Maybe<ProductReviewBanner>;
  /** 첫 리뷰 작성시 추가 지급 포인트 */
  first_review_bonus: ProductReviewPointAmountsValue;
  /** 최대 지급 예상 마일리지 */
  maximum_mileage: Scalars['Int'];
  /** 최대 지급 예상 포인트 */
  maximum_point: Scalars['Int'];
  /** 리뷰 작성시 지급하는 기본 포인트 */
  normal: ProductReviewPointAmountsValue;
  /** 조건 그룹별로 지급되는 포인트 정리 */
  review_bonus_point_group: ReviewBonusPointGroup;
  /** 국가/상품금액에 맞는 포인트 조건별로 정리 */
  review_point_info?: Maybe<ReviewPointInfo>;
};

export type ProductReviewPointAmountsValue = {
  /** 텍스트 리뷰 */
  text_only: Scalars['Int'];
  /** 사진 리뷰 */
  with_photo: Scalars['Int'];
};

/** 상품리뷰 상품유형 타입 */
export enum ProductReviewProductType {
  NORMAL = 'NORMAL',
  ZONLY = 'ZONLY',
}

/** 리뷰 프로필 */
export type ProductReviewProfile = {
  /** 뱃지 */
  badge?: Maybe<ProductReviewBadge>;
  /** 본인 리뷰 프로필 여부 */
  is_mine: Scalars['Boolean'];
  /** 랭커 유무 */
  is_ranker: Scalars['Boolean'];
  /** 프로필 공개 여부 */
  is_visible: Scalars['Boolean'];
  /** 칭찬 받은 수 */
  like_count: Scalars['Int'];
  /** 마스킹 처리된 이메일 */
  masked_email: Scalars['String'];
  /** 닉네임 */
  nickname?: Maybe<Scalars['String']>;
  /** 리뷰 유저 정보 레코드 id */
  product_review_user_account_info_id?: Maybe<Scalars['ID']>;
  /** 프로필 이미지 S3 Object Key */
  profile_image_object_key?: Maybe<Scalars['String']>;
  /** 프로필 이미지 */
  profile_image_url: Scalars['String'];
  /** 작성 리뷰 수 */
  review_count: Scalars['Int'];
};

export type ProductReviewProfileImage = {
  id: Scalars['ID'];
  image_object_key: Scalars['String'];
  image_url: Scalars['String'];
};

/** 상품 리뷰 답글 */
export type ProductReviewReply = {
  /** 뱃지 */
  badge?: Maybe<ProductReviewBadge>;
  /** 상품리뷰 답글 내용 */
  contents: Scalars['String'];
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 번역된 상품리뷰 답글 내용 */
  translated_contents?: Maybe<Scalars['String']>;
};

/** 상품리뷰 신고 사유 카테고리 */
export enum ProductReviewReplyAbuseReportReasonCategory {
  ETC = 'ETC',
  INSULTING_OR_OBSCENE_CONTENTS = 'INSULTING_OR_OBSCENE_CONTENTS',
  NOT_RELATED_TO_SHOP_OR_PRODUCT = 'NOT_RELATED_TO_SHOP_OR_PRODUCT',
  PERSONAL_CONTENTS = 'PERSONAL_CONTENTS',
  PROMOTIONAL_CONTENTS = 'PROMOTIONAL_CONTENTS',
}

/** 요청한 사용자별 정보 */
export type ProductReviewRequestedUser = {
  is_abuse_reported: Scalars['Boolean'];
  is_mine: Scalars['Boolean'];
  liked_list: Array<Scalars['String']>;
};

export type ProductReviewResultContent = {
  /** 아이콘 */
  icon?: Maybe<Scalars['String']>;
  /** 중앙 텍스트 */
  middle_text?: Maybe<Scalars['String']>;
  /** 상단 텍스트 */
  top_text?: Maybe<Scalars['String']>;
};

export type ProductReviewReviewerRanker = {
  /** 신규 진입 여부 */
  is_new: Scalars['Boolean'];
  /** 리뷰어 프로필 */
  profile: ProductReviewProfile;
  /** 랭크 */
  rank: Scalars['Int'];
  /** 랭크 변동 */
  rank_changes: Scalars['Int'];
  /** 유저 어카운트 레코드 ID */
  user_account_id: Scalars['ID'];
};

export type ProductReviewReviewerRankerList = {
  item_list: Array<ProductReviewReviewerRanker>;
  total_count: Scalars['Int'];
};

/** 상품별 리뷰 이벤트 배너 정보 */
export type ProductReviewSellerEventBannerInfo = {
  /** 리뷰 이벤트 안내 배너 정보 */
  banner?: Maybe<ProductReviewBanner>;
  /** 리뷰 이벤트 타입 */
  event_type?: Maybe<ProductReviewSellerEventType>;
  /** 요청한 product_id */
  product_id: Scalars['ID'];
  /** 리뷰 작성시 이벤트 응모 안내 배너 정보 */
  register_banner?: Maybe<ProductReviewBanner>;
};

/** 셀러 리뷰 이벤트 선정 리뷰 정보 */
export type ProductReviewSellerEventRegister = {
  /** 선정된 이벤트 뱃지 정보 */
  badge_title?: Maybe<Scalars['String']>;
  /** 선정된 이벤트 안내 배너 정보 */
  banner?: Maybe<ProductReviewBanner>;
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 포인트 지급 날짜 */
  date_rewarded?: Maybe<Scalars['CrTimestamp']>;
  /** 수정 날짜 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 리뷰 이벤트 보상 포인트 지급 여부 */
  is_point_issued: Scalars['Boolean'];
  /** 지급 받은 포인트 */
  issued_point?: Maybe<Scalars['Int']>;
  /** 리뷰 이벤트에서 선정된 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
};

/** 리뷰 이벤트 타입 */
export enum ProductReviewSellerEventType {
  FIRST_REVIEW = 'FIRST_REVIEW',
  NORMAL = 'NORMAL',
}

export type ProductReviewShopDetailSummary = {
  ratings_average: Scalars['Float'];
  total_count: Scalars['Int'];
};

/** 쇼핑몰별 리뷰 요약 정보 */
export type ProductReviewShopSummary = {
  /** 모든 상품리뷰의 총 수 */
  all_count: Scalars['Int'];
  /** 모든 상품리뷰의 평균 평점 */
  ratings_average: Scalars['Float'];
};

/**
 * 상품리뷰 상태
 * PRE_INSPECTION: 상품리뷰 생성 이후 검수 전까지의 상태
 * PRE_INSPECTION_INSPECTOR_ASSIGNED: 검수대기중(담당자 배정됨)
 * NORMAL: 검수 완료 상태
 * NORMAL_ATTACHMENT_DELETED: 검수결과 리뷰 자체는 정상, 첨부파일만 삭제
 * DELETED: 검수결과 삭제(본인을 포함한 모든 사람에게 노출되지 않음) 처리됨
 */
export enum ProductReviewStatus {
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
  NORMAL_ATTACHMENT_DELETED = 'NORMAL_ATTACHMENT_DELETED',
  PRE_INSPECTION = 'PRE_INSPECTION',
  PRE_INSPECTION_INSPECTOR_ASSIGNED = 'PRE_INSPECTION_INSPECTOR_ASSIGNED',
}

/** 상품리뷰 상태 변경 */
export type ProductReviewStatusChange = {
  /** 앱에서 노출할 삭제 정보 메세지 */
  message: Scalars['String'];
  /** 사유 */
  reason: Scalars['String'];
  /** 상세 사유 */
  reason_detail?: Maybe<Scalars['String']>;
  /** 상품리뷰 상태 */
  status: ProductReviewStatus;
};

/** 상품 리뷰 요약 정보 */
export type ProductReviewSummary = {
  /** 모든 상품 리뷰의 총 수 */
  all_count: Scalars['Int'];
  /** 외부 리뷰 포함 모든 상품리뷰의 총 수 */
  all_count_external_included?: Maybe<Scalars['Int']>;
  /** 특성평가 배열 */
  attribute_list: Array<ProductReviewSummaryAttribute>;
  /** 평점 정보의 배열 */
  rating_list: Array<ProductReviewSummaryRating>;
  /** 평점 평균 */
  ratings_average: Scalars['Float'];
  /** 외부 리뷰 포함 평점 평균 */
  ratings_average_external_included?: Maybe<Scalars['Float']>;
};

/** 상품 리뷰 요약 정보 특성평가 */
export type ProductReviewSummaryAttribute = {
  /** 답변 */
  answer_list: Array<ProductReviewSummaryAttributeAnswer>;
  /** 최다 답변 */
  most_answer?: Maybe<ProductReviewSummaryAttributeAnswer>;
  /** 질문 */
  question: ProductReviewAttributeQuestion;
};

export type ProductReviewSummaryAttributeAnswer = {
  count: Scalars['Int'];
  label: Scalars['String'];
  percent?: Maybe<Scalars['Int']>;
  ratio: Scalars['Float'];
  value: Scalars['String'];
};

/** 평점 정보 */
export type ProductReviewSummaryRating = {
  count: Scalars['Int'];
  /** 평점 */
  rating: Scalars['Int'];
  ratio: Scalars['Float'];
};

export type ProductReviewTextBadge = ProductReviewBadge & {
  background_color?: Maybe<ReviewCommonColor>;
  border: ReviewBadgeBorder;
  text: ReviewCommonText;
  type: Scalars['String'];
};

/** 리뷰 토픽 */
export type ProductReviewTopic = {
  /** 리뷰 토픽 노출 타이틀 */
  display_title: Scalars['String'];
  /** 리뷰 토픽 키 */
  key: Scalars['String'];
};

/** 상품 리뷰 유형 */
export enum ProductReviewType {
  PHOTO = 'PHOTO',
  QUICK = 'QUICK',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
}

export type ProductReviewUserAccount = {
  /** 리뷰 작성자의 작성당시 신체 정보 */
  body?: Maybe<UserAccountBody>;
  /** 리뷰 작성자의 작성당시 신체 정보의 텍스트 */
  body_text?: Maybe<Scalars['String']>;
  /** 이름 */
  full_name: Scalars['String'];
  /** 마스킹 처리된 이메일 */
  masked_email: Scalars['String'];
  /** 마스킹 처리된 이름 */
  masked_name: Scalars['String'];
  /** 리뷰 프로필 */
  profile: ProductReviewProfile;
};

/** 사용자 계정의 상품리뷰 관련 요약 정보 */
export type ProductReviewUserAccountInfo = {
  /** 총 작성 리뷰 수 */
  all_count: Scalars['Int'];
  /** 사용자가 경고 조치를 확인한 일자 */
  date_checked_penalty_warning?: Maybe<Scalars['CrTimestamp']>;
  /** 페널티 시작일 */
  date_penalty_gte?: Maybe<Scalars['CrTimestamp']>;
  /** 페널티 종료일 */
  date_penalty_lt?: Maybe<Scalars['CrTimestamp']>;
  /** 갱신 일자 */
  date_updated: Scalars['CrTimestamp'];
  /** 첫 리뷰 레코드 ID */
  first_product_review_id: Scalars['Int'];
  /** 지급된 첫 리뷰 보너스 포인트 */
  issued_first_review_point: Scalars['Int'];
  /** 사용자 계정의 상품리뷰 페널티 배열 */
  penalty_list: Array<ProductReviewUserAccountPenalty>;
  /** 사용자 계정의 상품리뷰 최근 페널티 */
  recent_penalty?: Maybe<ProductReviewUserAccountPenalty>;
  /** 제한 횟수 */
  restriction_count: Scalars['Int'];
  /** 사용자 계정의 상품리뷰 관련 상태 */
  status: ProductReviewUserAccountInfoStatus;
  /** 사용자 계정 레코드 ID */
  user_account_id: Scalars['ID'];
  /** 유효한 리뷰 수(총 작성된 리뷰 중 삭제된 리뷰 제외) */
  valid_count: Scalars['Int'];
  /** 주의 횟수 */
  warning_count: Scalars['Int'];
};

/** 사용자 계정의 상품리뷰 관련 요약 정보의 목록 */
export type ProductReviewUserAccountInfoList = {
  item_list: Array<ProductReviewUserAccountInfo>;
  total_count: Scalars['Int'];
};

/** 사용자 계정의 상품리뷰 관련 상태 */
export enum ProductReviewUserAccountInfoStatus {
  NORMAL = 'NORMAL',
  RESTRICTED = 'RESTRICTED',
  WARNED = 'WARNED',
}

/** 사용자 계정 상품리뷰 페널티 */
export type ProductReviewUserAccountPenalty = {
  /** 사용자 계정 상품리뷰 페널티 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 페널티 시작일 */
  date_penalty_gte?: Maybe<Scalars['CrTimestamp']>;
  /** 페널티 종료일 */
  date_penalty_lt?: Maybe<Scalars['CrTimestamp']>;
  /** deprecated 여부 */
  deprecated: Scalars['Boolean'];
  /** 처리자ID */
  doer: Scalars['String'];
  /** 관리용 메시지 */
  memo?: Maybe<Scalars['String']>;
  /** 사용자 계정 상품리뷰 페널티의 상품리뷰 */
  product_review: ProductReview;
  /** 사용자 계정 상품 리뷰 패널티의 사용자 계정 상품리뷰 정보 */
  product_review_user_account_info: ProductReviewUserAccountInfo;
  /** 사유 */
  reason: Scalars['String'];
  /** 상세 사유 */
  reason_detail?: Maybe<Scalars['String']>;
  /** 사용자 계정의 상품리뷰 관련 상태 */
  status: ProductReviewUserAccountInfoStatus;
};

/** 사용자 계정 상품리뷰 페널티 목록 정보 */
export type ProductReviewUserAccountPenaltyList = {
  item_list: Array<ProductReviewUserAccountPenalty>;
  total_count: Scalars['Int'];
};

/** 상품리뷰 유저 댓글 */
export type ProductReviewUserReply = {
  /** 뱃지 */
  badge?: Maybe<ProductReviewBadge>;
  /** 상품리뷰 댓글 내용 */
  contents: Scalars['String'];
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 상품리뷰 댓글 삭제 메시지 */
  delete_message?: Maybe<Scalars['String']>;
  /** 상품리뷰 댓글 삭제 타입 */
  delete_type?: Maybe<Scalars['String']>;
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 상품리뷰 댓글 삭제 여부 */
  is_deleted: Scalars['Boolean'];
  /** 상위 댓글 레코드 ID */
  parent_reply_id?: Maybe<Scalars['ID']>;
  /** 상위 상품리뷰 */
  product_review: ProductReview;
  /** 댓글 달은 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
  /** 유저 프로필 */
  profile: ProductReviewProfile;
  /** 답글 갯수 */
  reply_count: Scalars['Int'];
  /** 답글(1차 depth까지만 지원) */
  reply_list?: Maybe<Array<ProductReviewUserReply>>;
  /** 요청한 사용자별 정보 */
  requested_user?: Maybe<ProductReviewUserReplyRequestedUser>;
  /** 상품 리뷰 유저 댓글 상태 */
  status: ProductReviewUserReplyStatus;
};

export type ProductReviewUserReplyAlarmBottomSheet = {
  /** 호출 만료 날짜 */
  date_expired: Scalars['CrTimestamp'];
  /** 노출 여부 */
  is_exposed: Scalars['Boolean'];
  /** 리뷰 댓글 알람 페이지 URL */
  url: Scalars['String'];
};

export type ProductReviewUserReplyList = {
  after_key?: Maybe<Scalars['ID']>;
  has_next: Scalars['Boolean'];
  item_list: Array<ProductReviewUserReply>;
};

export type ProductReviewUserReplyRequestedUser = {
  is_abuse_reported: Scalars['Boolean'];
  is_mine: Scalars['Boolean'];
};

export enum ProductReviewUserReplyStatus {
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
  NORMAL_ATTACHMENT_DELETED = 'NORMAL_ATTACHMENT_DELETED',
  PRE_INSPECTION = 'PRE_INSPECTION',
  PRE_INSPECTION_INSPECTOR_ASSIGNED = 'PRE_INSPECTION_INSPECTOR_ASSIGNED',
}

/** 유저 상품 리뷰 목록 정보 */
export type ProductReviewUserReviewList = {
  item_list: Array<ProductReview>;
  total_count: Scalars['Int'];
};

export type ProductReviewWebViewBottomSheet = {
  /** 호출 만료 날짜 */
  date_expired: Scalars['CrTimestamp'];
  /** 노출 여부 */
  is_exposed: Scalars['Boolean'];
  /** 웹뷰 url */
  url?: Maybe<Scalars['String']>;
};

/** 캐시에 저장된 상품리뷰 수 */
export type ProductReviewsCachedCount = {
  /** 모든 상품리뷰의 총 수 */
  all: Scalars['Int'];
  /** PHOTO 유형 상품리뷰의 총 수 */
  photo: Scalars['Int'];
  /** TEXT 유형 상품리뷰의 총 수 */
  text: Scalars['Int'];
};

export enum ProductSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비중 */
  PREPARE = 'PREPARE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 판매중 */
  SALE = 'SALE',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export type ProductSearchKeyword = {
  /** ID */
  id: Scalars['ID'];
  /** 검색어 키워드 */
  value: Scalars['String'];
};

/** 권역별 배송비 */
export type ProductShippingAreaFeeResponse = {
  /** 제3권역(제주외) */
  isolated: Scalars['Int'];
  /** 제2권역(제주) */
  jeju: Scalars['Int'];
};

/** 묶음배송 타입 */
export enum ProductShippingBundleType {
  CONSOLIDATED = 'CONSOLIDATED',
  SEPARATED = 'SEPARATED',
}

/** 상품별 배송비 정보 */
export type ProductShippingFee = {
  /** 권역별 배송비 */
  area_fee: ProductShippingAreaFeeResponse;
  /** 기본배송비 */
  base_fee: Scalars['Int'];
  /** 조건부 배송 주문 금액 */
  conditional_amount?: Maybe<Scalars['Int']>;
  /** 교환배송비 */
  exchange_fee: Scalars['Int'];
  /** 배송비 타입 */
  fee_type: ProductShippingFeeType;
  /** 배송비 결제 수단 */
  payment_type: ProductShippingFeePaymentType;
  /** 반품비 */
  return_fee: ProductShippingReturnFeeResponse;
};

export enum ProductShippingFeePaymentType {
  /** 배송후 결제 ( 착불 ) */
  AFTER_DELIVERY = 'AFTER_DELIVERY',
  /** 주문시 결제 */
  ON_ORDER = 'ON_ORDER',
}

export type ProductShippingFeeResponse = {
  area_fee: ProductShippingAreaFeeResponse;
  base_fee: Scalars['Int'];
  conditional_amount?: Maybe<Scalars['Int']>;
  exchange_fee: Scalars['Int'];
  fee_type: ProductShippingFeeType;
  payment_type: ProductShippingFeePaymentType;
  return_fee: ProductShippingReturnFeeResponse;
};

/** 배송비 결제 시점 */
export enum ProductShippingFeeType {
  /** 고정비 배송 */
  CHARGED = 'CHARGED',
  COD = 'COD',
  /** 조건부 무료 배송 */
  CONDITIONAL_FREE = 'CONDITIONAL_FREE',
  /** 무료 배송 */
  FREE = 'FREE',
  PRE_PAYMENT = 'PRE_PAYMENT',
}

/** 반품비용 정보 */
export type ProductShippingReturnFeeResponse = {
  /** 부분 반품비 */
  partial?: Maybe<Scalars['Int']>;
  /** 전체 반품비 */
  total: Scalars['Int'];
};

export type ProductSiteCountry = {
  /** country code */
  country: CountryCode;
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 페널티 정보 */
  penalty: PenaltyResponse;
  /** 배송비 */
  shipping_fee?: Maybe<ProductShippingFeeResponse>;
  /** site ID */
  site_id: Scalars['Int'];
  /** seller의 요청에 의한 제한 */
  suspend_status: SuspendStatus;
  /** Z결제 할인액 */
  zpay_discount_amount: Scalars['Int'];
};

export type ProductSize = {
  /** 상품 사이즈 카테고리 정보 */
  category: ProductSizeCategory;
  /** 등록타입(PIM_SYSTEM, SELLER) */
  registration_type: ProductSizeRegistrationType;
  /** 상품 사이즈 정보 */
  value_list: Array<ProductSizeValue>;
};

export type ProductSizeAttribute = {
  /** 사이즈템플릿 key (ex: length) */
  key: Scalars['String'];
  /** 사이즈 값 */
  value: Scalars['String'];
};

export type ProductSizeCategory = {
  /** 사이즈 카테고리 ID */
  size_category_id: Scalars['ID'];
};

export enum ProductSizeRegistrationType {
  SELLER = 'SELLER',
  SYSTEM = 'SYSTEM',
}

export type ProductSizeValue = {
  /** 상품 사이즈 정보(문자형) */
  attribute_list: Array<ProductSizeAttribute>;
  /** 상품 사이즈 옵션 값 (ex:S,M,44) */
  option_value: Scalars['String'];
  /** 사이즈 순서 */
  order: Scalars['Int'];
};

export enum ProductStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export enum ProductTaxType {
  FREE = 'FREE',
  TAX = 'TAX',
}

export enum ProductTraitType {
  EXCLUSIVE = 'EXCLUSIVE',
}

export enum ProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

export type ProductTypeDistribution = {
  count: Scalars['Int'];
  type: UxGoodsProductType;
};

export type ProductsCategoryTab = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductsCategoryTabsSectionData = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<ProductsCategoryTabsSectionDataItem>;
  total_count: Scalars['Int'];
};

export type ProductsCategoryTabsSectionDataItem = {
  product: BenefitProductCardItem;
  store: BenefitShop;
};

export type ProductsCategoryTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  category_list: Array<ProductsCategoryTab>;
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type ProductsPresetBannerData = {
  aos_version_lte?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  ios_version_lte?: Maybe<Scalars['String']>;
  landing_url: Scalars['String'];
  products_preset_uuid: Scalars['String'];
  type: BannerItemType;
};

export enum ProductsPresetBrowsingType {
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

/** ux 스키마에서 UxGoodsCardItem 그대로 가져와서 사용 */
export type ProductsPresetCardItem = ProductsPresetComponent & {
  aid?: Maybe<Scalars['String']>;
  bookmarked: Scalars['Boolean'];
  bridge?: Maybe<Scalars['Boolean']>;
  browsing_type: ProductsPresetBrowsingType;
  catalog_product_id?: Maybe<Scalars['ID']>;
  catalog_product_type?: Maybe<ProductsPresetCatalogProductType>;
  column_count?: Maybe<Scalars['Int']>;
  discount_info?: Maybe<ProductsPresetCardItemDiscountInfo>;
  discount_rate?: Maybe<Scalars['Int']>;
  final_price: Scalars['Int'];
  final_price_with_currency: ProductsPresetPriceWithCurrency;
  free_shipping?: Maybe<Scalars['Boolean']>;
  goods_id?: Maybe<Scalars['String']>;
  has_coupon: Scalars['Boolean'];
  image_ratio?: Maybe<Scalars['Float']>;
  image_url?: Maybe<Scalars['String']>;
  is_brand: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  max_price: Scalars['Int'];
  max_price_with_currency: ProductsPresetPriceWithCurrency;
  origin_id?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  product_url?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['Int']>;
  review_count?: Maybe<Scalars['Int']>;
  review_score?: Maybe<Scalars['Float']>;
  sales_status?: Maybe<ProductsPresetSearchedProductSalesStatus>;
  sellable_status: CmsSellableStatus;
  shop_id: Scalars['ID'];
  shop_product_no?: Maybe<Scalars['String']>;
  status?: Maybe<ProductsPresetGoodsStatus>;
  title?: Maybe<Scalars['String']>;
  type: ProductsPresetComponentType;
  uuid?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  webp_image_url?: Maybe<Scalars['String']>;
  zpay?: Maybe<Scalars['Boolean']>;
  zpay_price?: Maybe<Scalars['Int']>;
};

export type ProductsPresetCardItemDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum ProductsPresetCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

export type ProductsPresetComponent = {
  position: Scalars['Int'];
  type: ProductsPresetComponentType;
};

export enum ProductsPresetComponentType {
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

export type ProductsPresetDataResult = {
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  date_started?: Maybe<Scalars['CrTimestamp']>;
  end_cursor?: Maybe<Scalars['String']>;
  group_list_type?: Maybe<ProductsPresetIdListType>;
  groups: Array<ProductsPresetGroup>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<ProductsPresetResponseData>;
  server_time?: Maybe<Scalars['CrTimestamp']>;
  total_count: Scalars['Int'];
  type?: Maybe<ProductsPresetType>;
};

/** 상품 상태 */
export enum ProductsPresetGoodsStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

export type ProductsPresetGroup = {
  item_list: Array<ProductsPresetResponseData>;
  total_count: Scalars['Int'];
};

export enum ProductsPresetIdListType {
  GROUP = 'GROUP',
  LIST = 'LIST',
}

export enum ProductsPresetPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type ProductsPresetPriceWithCurrency = {
  /** 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다. */
  currency: ProductsPresetPaymentCurrency;
  /** 소수점 자리수 */
  decimal: Scalars['Int'];
  /** 화면에 표시할 통화(원, $, yen) */
  display_currency: Scalars['String'];
  /** 통화 앞에 오는지 여부 */
  is_currency_prefix: Scalars['Boolean'];
  /** 소수점 올림한 금액 */
  price_without_decimal: Scalars['Int'];
};

export type ProductsPresetResponseData = {
  product: ProductsPresetCardItem;
  shop: ProductsPresetShop;
};

export enum ProductsPresetSearchedProductSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

/** 프로덕트 프리셋 세션 옵션 */
export type ProductsPresetSessionOptions = {
  /**
   * 배송지
   * 'KR'
   */
  country?: InputMaybe<Scalars['String']>;
  /**
   * 환율
   * 'KRW'
   */
  currency?: InputMaybe<Scalars['String']>;
  /**
   * 언어 ko
   * ---
   * 'ko'
   */
  language?: InputMaybe<Scalars['String']>;
  /**
   * site_id
   * ---
   * '1'
   */
  site_id?: InputMaybe<Scalars['ID']>;
};

export type ProductsPresetShop = {
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum ProductsPresetType {
  FILTER = 'Filter',
  ID_LIST = 'IdList',
  STORE_PICK = 'StorePick',
  TIMEDEAL = 'Timedeal',
}

export type ProductsSliderSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type ProductsSliderSectionProps = {
  column: Scalars['Int'];
  deeplink_url?: Maybe<Scalars['String']>;
  product_count: Scalars['Int'];
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 2탭 쿠폰 메타데이터
 */
export type Promotion202104MetadataCoupon = {
  /** 최근 데이터 갱신 일시 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** event_content_uuid */
  event_content_uuid: Scalars['String'];
  /** 전 상품할인 이벤트 메타데이터 */
  event_metadata_uuid: Scalars['String'];
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 메타데이터 상태값 - 이벤트 진행상태 값
 * 이벤트 전체의 진행상태에서도 사용되며, 일자별, 주별로 이벤트 기간내에서 나뉘어 진행되는 경우에도 사용됨
 */
export enum Promotion202104MetadataStatus {
  /** 시작 이전 */
  BEFORE_PROCEEDING = 'BEFORE_PROCEEDING',
  /** 종료됨 */
  ENDED = 'ENDED',
  /** 진행중 */
  ONGOING = 'ONGOING',
}

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 3탭 타임특가 메타데이터
 */
export type Promotion202104MetadataTimeDealDaily = {
  /** 일자 YYYY-MM-DD 00:00:00 */
  date: Scalars['CrTimestamp'];
  /** 노출 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 노출 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /** 일자, YYYYMMDD 형식 */
  date_ymd: Scalars['Int'];
  /** 프로덕트 프리셋 ID */
  products_preset_uuid: Scalars['String'];
  /** 타임딜 진행 상태 */
  status?: Maybe<Promotion202104MetadataStatus>;
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 1탭 Top100 메타데이터
 */
export type Promotion202104MetadataTop100 = {
  /** Top100 브랜드 카테고리 목록 */
  brand_category_list: Array<Promotion202104Top100Category>;
  /** Top100 카테고리 목록 */
  category_list: Array<Promotion202104Top100Category>;
  /** 최근 데이터 갱신 일시 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** Top100 참여 쇼핑몰 목록 */
  shop_id_list: Array<Scalars['ID']>;
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 4탭 유저 공유 랭킹 메타데이터
 */
export type Promotion202104MetadataUserRanking = {
  /** 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 최근 데이터 갱신 일시 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /** 시즌 index 번호 */
  season_idx: Scalars['Int'];
  /** 상태값 */
  status: Promotion202104MetadataStatus;
  /** 탭 제목 */
  tab_title: Scalars['String'];
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 1탭 Top100 랭킹 아이템 정보
 */
export type Promotion202104RankingItem = {
  /** 카탈로그 product ID */
  catalog_product_id: Scalars['ID'];
  /** 이벤트 기간 동안의 누적 찜수 */
  count_total_bookmark: Scalars['Int'];
  /** 아이템 ID */
  goods_id: Scalars['ID'];
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품정보 - ProductPreset의 상품정보 모델 */
  product: ProductsPresetCardItem;
  /** 랭킹 순위 */
  ranking: Scalars['Int'];
  /** Top100 카테고리 ID */
  ranking_category_id: Scalars['Int'];
  /** Top100 카테고리 이름 */
  ranking_category_name: Scalars['String'];
  /** 순위 변동 */
  ranking_changed: Scalars['Int'];
  /** 쇼핑몰 정보 - ProductPreset의 쇼핑몰 정보 모델 */
  shop: ProductsPresetShop;
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 종합 점수 */
  total_score: Scalars['Int'];
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 2탭 셀러 쿠폰 정보
 */
export type Promotion202104SellerCoupon = {
  /** 최대 할인 금액 */
  max_discount_amount: Scalars['Int'];
  /** 쇼핑몰 도메인 */
  shop_domain?: Maybe<Scalars['String']>;
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name: Scalars['String'];
  /** 쿠폰팩 이름 */
  title: Scalars['String'];
  /** 쇼핑몰 이미지 */
  typical_image_url?: Maybe<Scalars['String']>;
};

/** Top100 상품 카테고리 모델 */
export type Promotion202104Top100Category = {
  /** 카테고리 ID */
  key: Scalars['Int'];
  /** 카테고리 이름 */
  value: Scalars['String'];
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 4탭 유저 공유 랭킹 정보
 */
export type Promotion202104UserRanking = {
  /** 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 최근 데이터 갱신 일시 */
  date_latest_updated: Scalars['CrTimestamp'];
  /** 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /**
   * 랭킹 상위 30위 까지만 노출
   * 지난 시즌의 경우 시즌 최종 결과 리스트, 현재 시즌의 경우 최근 데이터 갱신 일시에 따라 갱신되는 랭킹
   */
  ranking_list?: Maybe<Array<Maybe<Promotion202104UserRankingUserInfo>>>;
  /** 시즌 index 번호 */
  season_idx: Scalars['Int'];
  /** 상태값 */
  status: Promotion202104MetadataStatus;
  /**
   * 가장 많이 공유된 상품 Top3
   * 1탭의 Promotion202104_RankingItem 타입 참고
   * 한주가 끝나면 노출
   */
  top3_goods?: Maybe<Array<Maybe<Promotion202104RankingItem>>>;
  /**
   * 로그인한 유저 랭킹 정보
   * 로그인하지 않으면 null
   */
  user_info?: Maybe<Promotion202104UserRankingUserInfo>;
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 4탭 유저 공유 랭킹 정보
 */
export type Promotion202104UserRankingUserInfo = {
  /** 마스킹된 이메일 주소 */
  email: Scalars['String'];
  /** 참여 여부 */
  is_participation: Scalars['Boolean'];
  /** 받은 포인트 */
  point: Scalars['Int'];
  /** 유저 랭킹 */
  ranking: Scalars['Int'];
  /** 공유 점수 */
  score: Scalars['Int'];
  /** 시즌 index 번호 */
  season_idx: Scalars['Int'];
  /** 당첨 주차 정보 */
  winning_season?: Maybe<Scalars['Int']>;
};

export enum PromotionAllotmentType {
  COMPLEX = 'COMPLEX',
  KAKAOSTYLE = 'KAKAOSTYLE',
  SELLER = 'SELLER',
}

/** 주문할 상품의 프로모션 적용 정보 */
export type PromotionAppliedInfoInput = {
  /** 프로모션 할인 적용 가격 */
  promotion_applied_discount_price: Scalars['Int'];
  /** 프로모션 아이디 */
  promotion_id: Scalars['ID'];
  /** 프로모션 아이템 그룹 식별자 */
  promotion_item_group_key?: InputMaybe<Scalars['String']>;
  /** 프로모션 타입(BOGO/FIRST_ORDER 등) */
  promotion_type: PromotionType;
};

export type PromotionCatalogInfo = {
  /** 할인 가격 */
  discount_price: Scalars['Int'];
  /** 카탈로그 ID */
  id: Scalars['ID'];
  /** 메인 Image URL */
  image_url: Scalars['String'];
  /** 상품 이름(한국어) */
  name: Scalars['String'];
  /** 옵션 목록 */
  option_list: Array<PromotionCatalogOption>;
  /** 시중 가격 */
  original_price: Scalars['Int'];
};

export type PromotionCatalogItemAttribute = {
  /** 속성 코드 */
  code?: Maybe<Scalars['String']>;
  /** 속성 이름 */
  name: Scalars['String'];
  /** 속성 값 */
  value: Scalars['String'];
};

export type PromotionCatalogItemInfo = {
  /** 부가 속성 목록 */
  attribute_list: Array<PromotionCatalogItemAttribute>;
  /** 카탈로그 옵션 ID */
  id: Scalars['ID'];
  /** 메인 Image URL */
  image_url: Scalars['String'];
  /** 옵션 이름 */
  name: Scalars['String'];
};

export type PromotionCatalogOption = {
  /** 옵션 이름 */
  name: Scalars['String'];
  /** 옵션 정렬 우선순위 */
  order: Scalars['Int'];
  /** 옵션 값 목록 */
  value_list: Array<PromotionCatalogOptionValue>;
};

export type PromotionCatalogOptionValue = {
  /** 옵션 값 코드 */
  code?: Maybe<Scalars['String']>;
  /** 옵션 값 이름 */
  value: Scalars['String'];
};

export type PromotionDisplayCategory = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type PromotionExperienceGroupProduct = {
  /** 체험단 프로모션의 스케줄 정보 (v1) */
  experience_schedule?: Maybe<ExperienceGroupSchedule>;
  /** 체험단이 진행되는 상품 정보 */
  promotion_product: PromotionProduct;
  /** 체험단 진행 일정 */
  schedules: Array<PromotionExperienceGroupSchedule>;
};

export type PromotionExperienceGroupProductInput = {
  /** 조회할 프로모션 상품 옵션의 ID (promotion_product.id) */
  promotion_product_id: Scalars['ID'];
};

export type PromotionExperienceGroupSchedule = {
  /** 체험단 스케줄 종료시간 */
  date_end: Scalars['CrTimestamp'];
  /** 체험단 스케줄 시작시간 */
  date_start: Scalars['CrTimestamp'];
  /** 확장이 필요한 값 저장(ex. reward_point) */
  expand_value?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 생성된 프로모션의 ID, 생성되지 않은 경우 null */
  promotion_id?: Maybe<Scalars['ID']>;
  /** 응모 가능한 Site ID */
  site_id: Scalars['ID'];
  /** 체험단 스케줄 타입 */
  type: ExperienceScheduleType;
};

export type PromotionExperienceGroupUserAppliedItem = {
  /** 응모 상태 */
  apply_status: PromotionUserApplyStatus;
  /** 취소된 경우, 취소 정보 */
  cancel_info?: Maybe<PromotionWinnerCanceledInfo>;
  /** 진행 스케줄 정보 v1 */
  experience_group_schedule?: Maybe<ExperienceGroupSchedule>;
  /** 진행 스케줄 정보 v2 */
  experience_group_schedule_list?: Maybe<Array<PromotionExperienceGroupSchedule>>;
  /** 상품의 주문 정보 */
  order_info?: Maybe<PromotionOrderInfo>;
  /** 응모 ID */
  promotion_applicant_id: Scalars['ID'];
  /** 프로모션 ID */
  promotion_id: Scalars['ID'];
  /** 응모한 상품 옵션 정보 */
  promotion_product_item: PromotionProductItem;
  /** 응모한 상품의 옵션 ID (promotion_product_item.id) */
  promotion_product_item_id: Scalars['ID'];
  /** 프로모션 버전 */
  promotion_version?: Maybe<Scalars['String']>;
};

export type PromotionExperienceGroupUserAppliedItemList = {
  /** 검색된 Item list */
  item_list: Array<PromotionExperienceGroupUserAppliedItem>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

export type PromotionExperienceGroupUserAppliedItemListInput = {
  /** 조회할 응모 상태 (응모, 당첨) */
  apply_status?: InputMaybe<PromotionUserApplyStatus>;
  /** 유저의 참여 시각 >= date_applied_gte */
  date_applied_gte: Scalars['CrTimestamp'];
  /** 유저의 참여 시각 <= date_applied_loe */
  date_applied_lte?: InputMaybe<Scalars['CrTimestamp']>;
  /** Limit 수량, 기본 50 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** Skip(Offset) 수량, 기본 0 */
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type PromotionFloatingBannerNudge = {
  end_date?: Maybe<Scalars['CrTimestamp']>;
  icon?: Maybe<UxCommonImageUrl>;
  text?: Maybe<UxCommonText>;
  type: Scalars['String'];
};

export type PromotionOrderInfo = {
  /** Site ID */
  country: Scalars['String'];
  /** 구매 확정 일시 */
  date_order_confirm?: Maybe<Scalars['CrTimestamp']>;
  /** 상품을 주문한 경우, 상품 주문 번호 */
  order_item_number: Scalars['String'];
  /** 연관된 프로모션의 ID */
  promotion_id: Scalars['ID'];
  /** 리뷰 작성 여부 */
  review_written: Scalars['Boolean'];
  /** 리뷰 작성 가능 여부 */
  reviewable: Scalars['Boolean'];
  /** Site ID */
  site_id: Scalars['ID'];
};

export enum PromotionPlatform {
  ALL = 'ALL',
  APP = 'APP',
  WEB = 'WEB',
}

export type PromotionProduct = {
  /** 상품단위 응모자수 */
  applicant_count: Scalars['Int'];
  /** 카탈로그 정보 */
  catalog_info?: Maybe<PromotionCatalogInfo>;
  /** 등록일시 */
  date_created: Scalars['CrTimestamp'];
  /** 수정일시 */
  date_updated: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  /** 프로모션 아이템 목록 */
  item_list: Array<PromotionProductItem>;
  product_id: Scalars['ID'];
  promotion_id: Scalars['ID'];
  /** 프로모션 가격 */
  promotion_price?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  shop_name?: Maybe<Scalars['String']>;
  /** 유저의 응모 정보 (User Account ID 를 쿼리에 제공한 경우) */
  user_apply_info?: Maybe<Array<PromotionUserApplyInfo>>;
};

export enum PromotionProductDiscountType {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

export type PromotionProductItem = {
  /**
   * 응모형 프로모션인 경우, 전체 이용자가 응모한 횟수
   * 응모형 프로모션인 경우에만 카운트가 반환됨, 이외의 경우에는 0
   */
  applicant_count: Scalars['Int'];
  /** 카탈로그 아이템 정보 */
  catalog_item_info?: Maybe<PromotionCatalogItemInfo>;
  /** 등록일시 */
  date_created: Scalars['CrTimestamp'];
  /** 수정일시 */
  date_updated: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  item_id: Scalars['ID'];
  promotion_product?: Maybe<PromotionProduct>;
  /** PromotionProduct FK (catalog_product_id X) */
  promotion_product_id: Scalars['ID'];
  /** 수량 */
  quantity?: Maybe<Scalars['Int']>;
};

export enum PromotionProductRecommendType {
  /** 마감임박순 */
  DEADLINE = 'DEADLINE',
  /** 당첨확률 높은순 */
  SCORE = 'SCORE',
}

/** 프로모션 타입 */
export enum PromotionType {
  BOGO = 'BOGO',
  BOGO_DISCOUNT = 'BOGO_DISCOUNT',
  EXPERIENCE_GROUP = 'EXPERIENCE_GROUP',
  FIRST_ORDER = 'FIRST_ORDER',
  RAFFLE = 'RAFFLE',
  SHIPPING_FEE = 'SHIPPING_FEE',
}

export type PromotionUserApplyInfo = {
  /** 참여한 프로모션 상품 ID */
  applied_promotion_product_id?: Maybe<Scalars['ID']>;
  /** 참여한 프로모션 아이템 ID */
  applied_promotion_product_item_id?: Maybe<Scalars['ID']>;
  /** 프로모션 참여 상태 */
  apply_status: PromotionUserApplyStatus;
  /** 취소된 경우, 취소 정보 */
  cancel_info?: Maybe<PromotionWinnerCanceledInfo>;
  /** 참여 시각 */
  date_applied?: Maybe<Scalars['CrTimestamp']>;
  /** 주문 정보 */
  order_info?: Maybe<PromotionOrderInfo>;
  /** 응모 ID */
  promotion_applicant_id: Scalars['ID'];
};

export enum PromotionUserApplyStatus {
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  WINNED = 'WINNED',
}

export type PromotionUserShippingMemoInput = {
  /** 배송 메모 관리 ID */
  id?: InputMaybe<Scalars['ID']>;
  /** 배송 메모 */
  memo: Scalars['String'];
};

export type PromotionWinner = {
  /** 당첨 취소 일시 */
  date_winner_canceled?: Maybe<Scalars['CrTimestamp']>;
  /** 당첨자 정보(마스킹) */
  info: WinnerInfo;
  /** 당첨 상품 옵션 */
  item_id: Scalars['ID'];
};

export type PromotionWinnerCanceledInfo = {
  /** 취소 일시 */
  date_winner_canceled: Scalars['CrTimestamp'];
  /** 보상 포인트 금액 */
  point: Scalars['Int'];
};

export type PurchaseAges = {
  age: Scalars['String'];
  count: Scalars['Int'];
};

export type PushCategoryAgreement = {
  /** 카테고리 동의 여부 */
  agreed: Scalars['Boolean'];
  /** 카테고리 명 */
  category: Scalars['String'];
  /** 수신 동의 변경 시각 */
  date_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 생성 시간 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 사이트 구분 */
  site_id: Scalars['ID'];
  /** user_account id */
  user_account_id: Scalars['ID'];
};

export type Query = {
  activated_category_list: UxCategoryInfoList;
  /** 현재 활성화된 포인트 리워드를 반환한다 */
  activated_user_account_point_reward?: Maybe<UserAccountPointReward>;
  /** 현재 활성화 된 포인트 보상 목록을 반환한다 */
  activated_user_account_point_reward_list: ActivedUserAccountPointRewardList;
  /** 광고 브릿지 상세 페이지 */
  ad_bridge_detail?: Maybe<AdBridgeInfo>;
  /** 광고 상품 목록을 전달 */
  ad_display_active_ad_list: AdDisplayActiveAdList;
  /** 지그재그 피드 배너 광고 */
  ad_display_banner?: Maybe<AdDisplayBanner>;
  /** 비디오 광고 */
  ad_display_home_video?: Maybe<UxAdVideoItem>;
  /** 지그재그 shop 배너 광고 */
  ad_display_shop?: Maybe<AdDisplayShop>;
  /** 광고 기획전 상세 페이지 */
  ad_exhibition_detail?: Maybe<AdExhibitionInfo>;
  /** 멀티보드 PDP 피드 광고 */
  ad_multi_board_pdp_banner?: Maybe<Array<Maybe<AdMultiBoardFeed>>>;
  /** 광고 확장지면 마이 페이지 */
  ad_my_page_recommend_item_list?: Maybe<UxGoodsGroup>;
  ad_noti_status?: Maybe<UserNotiStatus>;
  /** 광고 확장지면 구매완료 페이지 */
  ad_purchase_recommend_item_list?: Maybe<UxGoodsGroup>;
  /** 상품 리뷰 영역 추천 상품 */
  ad_review_recommend_group: Array<UxComponent>;
  /**
   * 어필레이트 서비스 가입 확인 및 안내문구.
   * @deprecated use getAffiliatePdpModalMeta
   */
  affiliate_member_reward_info: AffiliateMemberInfo;
  /**
   * 어필레이트 서비스 정보 조회. (서비스 활성여부, 최대 리워드율 문구)
   * @deprecated use getAffiliatePdpModalMeta
   */
  affiliate_service_info: AffiliateServiceInfo;
  age_recommended_keyword_info: AgeRecommendedKeywordInfo;
  /** 적용가능한 쿠폰 정책 목록을 반환한다. */
  applicable_coupon_policy_list: ApplicableCouponPolicyList;
  /**
   * 본인인증 노출 목록 조회
   * - KMC, 카카오뱅크
   */
  authenticationAgencyList: AuthenticationAgencyListResult;
  /** 해당 반품 상품들에 대해 가능한 반품비용 지불방식을 판단한다 */
  available_return_shipping_fee_charge_method: Array<ShippingFeeChargeMethod>;
  /** 은행 코드 리스트 */
  bank_code_list?: Maybe<BankCodeList>;
  /**
   * 사용자별 배너 목록
   * @deprecated Field no longer supported
   */
  banner_list: BannerList;
  /**
   * 뷰티 이벤트 프리퀀시 메타정보를 반환한다.
   * 이벤트 종류를 찾지 못한 경우 null 을 반환한다.
   */
  beauty_event_frequency_meta?: Maybe<BeautyEventFrequencyMeta>;
  /** 뷰티 기획전 배너 목록 */
  beauty_exhibition_banner_list: Array<DdpBanner>;
  /** md pick 상품 id 리스트 */
  beauty_md_pick_product_id_list: Array<Scalars['ID']>;
  /** 뷰티 준실시간 랭킹 */
  beauty_nrt_ranking?: Maybe<BeautyNrtRanking>;
  /**
   * 사용자계정의 뷰티 주문 아이템 이벤트 프리퀀시 요약을 반환한다.
   * 이벤트 종류를 찾지 못한 경우, 로그인 한 사용자계정이 아닌 경우, null 을 반환한다.
   */
  beauty_order_item_event_frequency_summary?: Maybe<BeautyOrderItemEventFrequencySummary>;
  /** 사용자계정의 뷰티 주문 아이템 월별 프리퀀시 요약을 반환한다. */
  beauty_order_item_frequency_summary: BeautyOrderItemFrequencySummary;
  /** 뷰티 체험단 메뉴 */
  beauty_reviewer_menu?: Maybe<DdpQuickMenu>;
  /** 뷰티 샘플 상품 리스트 */
  beauty_sample_categorized_item_list: BeautySampleCategorizedItemList;
  /** 뷰티 특가 카테고리별 상품 목록 */
  beauty_special_offer_categorized_item_list: BeautySpecialOfferCategorizedItemList;
  /** 뷰티 특가 상품 목록 */
  beauty_special_offer_item_list: Array<DdpProductCardItem>;
  /** 미입금 취소시 만료 포인트와 만료 마일리지 예상액을 전달한다. */
  before_transfer_order_expired_point_mileage: ExpiredAmounts;
  brand_ranking_list: UxBrandRankingList;
  /** 브랜드패션관 주요 브랜드 리스트 */
  brandfashion_main_brand_list?: Maybe<BrandFashionBrandList>;
  /** 브랜드패션 온보딩 속성 목록 조회 */
  brandfashion_onboarding_attribute_list: BrandFashionAttributeGroup;
  /** 브랜드패션 온보딩 유저 현황 조회 */
  brandfashion_onboarding_user: BrandFashionOnboardingUser;
  /** 브랜드 랭킹 조회 */
  brandfashion_ranking_info?: Maybe<BrandFashionRankingInfo>;
  /** 브랜드패션관 바텀싯 테마 목록 */
  brandfashion_theme_list?: Maybe<BrandFashionBrandList>;
  /** 특정 조건들로 상품들을 검색하여 반환한다.(캐시 사용) */
  cached_product_list: CachedProductListResponse;
  /** 쿠폰 정책별 허들금액을 반환한다. (product_info_list 인자가 없으면 장바구니 디폴트 3시간을 기준으로 계산) */
  cart_coupon_policy_info_list: CartCouponPolicyInfoList;
  /** 장바구니 아이템 수 */
  cart_items_count: Scalars['Int'];
  /** 해당 사용자의 쇼핑몰별 장바구니 모음 목록을 반환한다. */
  cart_shop_list: CartShopList;
  /** 해당 사용자의 쇼핑몰별 장바구니 모음 목록을 반환한다. (직진배송 cart_shop 독립적으로 분리) */
  cart_shop_list_v2: CartShopListV2;
  /** 장바구니 툴팁 */
  cart_tooltip?: Maybe<CartTooltip>;
  /** 상품 문의 답변 */
  catalog_answer: CatalogAnswer;
  /** 통관용 PDP 정보 */
  catalog_product_detail_page_type?: Maybe<CatalogProductDetailPage>;
  /** 통관용 PDP 옵션 정보 */
  catalog_product_detail_page_type_option?: Maybe<CatalogProductDetailPage>;
  /** 상품 목록을 반환한다. */
  catalog_product_list: CatalogProductList;
  /** 상품 문의 */
  catalog_question: CatalogQuestion;
  /** 상품 문의 목록 */
  catalog_question_list: CatalogQuestionList;
  /**
   * 소셜계정에 이메일이 없는 경우 직접 입력한 이메일이 중복되는지 확인한다.
   * [error_code]
   * - user_account_email_exists: 이미 존재하는 이메일인 경우
   */
  checkAvailableEmail: Scalars['Boolean'];
  /** 계좌 정보 확인하기 */
  checkBankAccount: Scalars['Boolean'];
  /**
   * 사용자의 기획전 쿠폰 보유 여부 확인
   * - 회원 가입을 하지 않은 비회원의 경우 계산 없이 보유하지 않은 것으로 리턴 한다.
   */
  checkIssueExhibitionCoupons: CheckIssueExhibitionCouponsResult;
  /**
   * 카카오 톡학생증 제출 동선상태를 확인한다
   * REQUESTED -> 요청
   * ABANDONED -> 실패
   * PRESENTED -> 성공
   * [error_code]
   * - present_proof_data_not_found: request_id 조회되는 제출요청이 없는경우
   * - kakao_check_transaction_request_failed: 카카오 톡학생증 제출동선 확인 API 요청이 실패한 경우
   * - invalid_presentation_definition_id: 제출정의 ID 값이 잘못된 경우
   */
  checkKakaoStudentPresentProofSubmission: CheckKakaoStudentPresentProofSubmissionResult;
  /** 물류 취소 가능여부 조회 (응답에 직진배송 상품주문건만 포함됨) */
  checkWmsOrderItemListCancellable: Array<WmsOrderItemCancellable>;
  /**
   * 입력한 리딤 코드값으로 쿠폰 사용 가능 여부를 조회한다.
   *
   * [error_code]
   * - coupon_already_used_code: 이미 사용한 코드
   * - invalid_coupon_code: 잘못된코드 혹은 쿠폰정책 발급전에 발급 받으려는 경우
   * - already_issued_coupon: 이미 발급 받은경우
   * - exhausted_coupon: 쿠폰정책 발급 가능 수량 초과
   * - expired_coupon: 쿠폰의 유효기간이 지난 경우
   * - coupon_policy_not_found: 해당 쿠폰 정책을 찾을 수 없음
   * - stopped_coupon_policy: 발급이 중지된 쿠폰 정책
   */
  check_issue_coupon_by_redeem: UsableRedeemCouponResult;
  /**
   * 카카오뱅크 본인인증 완료여부
   * [error_code]
   * - kakaobank_authentcation_timeout: 본인인증 시간 초과
   * - kakaobank_authentcation_error: 본인인증 에러
   * - kakaobank_authentcation_waiting: 본인인증 미완료상태에서의 요청
   * - inactive_user_account: 휴면 계정일 경우
   * - deleted_user_account: 탈퇴 계정일 경우
   * - user_account_authentication_invalid_input: 올바른 인증 정보가 아닌 경우
   * - user_account_authentication_different_user: 이미 본인인증 한 상태이고, 다른 명의로 본인인증 하는 경우
   * - user_account_authentication_already_exist: 다른 계정에 본인인증이 된 경우
   * - user_account_authentication_under_14_years_old: 14세 이하 본인인증 불가
   * - failed_adult_certification: 성인인증에 실패하였습니다
   */
  check_kakaobank_authentication: CheckKakaobankAuthenticationResult;
  /** 유저가 첫 주문인지 조회한다. */
  check_user_first_order: Scalars['Boolean'];
  clp_top_component_list: ClpTopComponentList;
  /** api cms 배너 호출 */
  cms_banner_list?: Maybe<Array<Maybe<CmsBanner>>>;
  /** 기획전에서 사용되는 상품 랭킹 모듈에 노출되는 상품 id 목록 */
  cms_campaign_catalog_ranking_id_list?: Maybe<CmsCampaignCatalogRankingInfoList>;
  /** 기획전에서 사용되는 스토어 랭킹 모듈에 노출되는 스토어, 상품 id 목록 */
  cms_campaign_store_ranking_id_list?: Maybe<CmsCampaignStoreRankingIdList>;
  /** 콘텐츠 에디터 */
  cms_content_editor?: Maybe<ContentEditor>;
  /** cms 상품 카드 목록 조회 */
  cms_product_card_item_list: CmsProductsPresetProductCardItemList;
  /** cms 프로덕트 프리셋 상품 카드 목록 조회 */
  cms_products_preset_product_card_item_list: CmsProductsPresetProductCardItemList;
  /** 프로모션 랭킹 카드 리스트 */
  cms_promotion_rank_card_list: CmsPromotionRankCardResponse;
  /** 스토어 프리셋에 등록된 스토어 목록 조회 */
  cms_store_preset_shop_list: CmsStorePresetShopList;
  /** 스토어 랭킹 카드 리스트 */
  cms_stores_rank_card_list: CmsStoresRankCardResponse;
  /** 컨텐츠 에디터 그룹 */
  content_editor_group?: Maybe<ContentEditorGroup>;
  /** 콘텐츠 에디터 선착순 쿠폰 (준)실시간 정보 */
  content_editor_limited_order_coupon_info?: Maybe<ContentEditorLimitedOrderCouponInfo>;
  convert_to_deep_link: Scalars['String'];
  /** 쿠폰 모아보기 쿠폰 리스트 */
  couponCollectionCouponList?: Maybe<UxCouponCollectionCouponContainer>;
  /** 쿠폰 모아보기 페이지 정보 V2 */
  couponCollectionPageInfoV2?: Maybe<UxCouponCollectionPageInfo>;
  /** 쿠폰 모아보기 스타일 월드컵 조회 */
  couponCollectionStyleWorldCup?: Maybe<StyleWorldCup>;
  /** 쿠폰 모아보기 스타일 월드컵 경쟁 상품 조회 */
  couponCollectionStyleWorldCupCompetition?: Maybe<StyleWorldCupCompetition>;
  /** 쿠폰 모아보기 스타일 월드컵 최종 선택 상품 정보 조회 */
  couponCollectionStyleWorldCupWinner?: Maybe<StyleWorldCupWinner>;
  /** 쿠폰 모아보기 상단 쿠폰 리스트 */
  couponCollectionTopCouponList?: Maybe<Array<UxCouponCollectionItem>>;
  /** 상품별 쿠폰 상태를 반환한다. */
  coupon_available_status: CouponAvailableStatus;
  /** 상품/스토어별 쿠폰 상태를 반환한다. */
  coupon_available_status_list: CouponAvailableInfoList;
  /** 해당 사용자의 쿠폰함 목록을 반환한다. */
  coupon_box: CouponBox;
  /** 쿠폰 안내사항을 내려준다. */
  coupon_box_info: Scalars['String'];
  /** 쿠폰 적용 불가 상태를 조회합니다. */
  coupon_exclude_status_list: Array<CouponExcludeStatus>;
  /** 쿠폰 정책 목록을 조회한다. */
  coupon_policy_list: CouponPolicyList;
  /** 정책 별 Target 목록을 조회한다. */
  coupon_policy_target_list: CouponPolicyTargetList;
  /** 쿠폰 Target목록을 조회한다. */
  coupon_target_list: UserAccountCouponTargetList;
  /** 클레임 요청 시, 이미지 업로드 시 S3 버킷의 Pre-Signed-Url을 발행하여 반환한다. */
  create_claim_image_upload_link: ClaimImageUploadLinkInfo;
  /**
   * 주문문의 이미지 파일 업로드 URL을 생성한다
   * [error_code]
   * - invalid_attatchment_extension: 첨부 이미지의 확장자가 이미지 타입이 아닌 경우
   */
  create_order_item_inquiry_image_upload_link: OrderItemInquiryImageUploadLinkInfo;
  /**
   * 조건에 맞는 다통화 메타데이터 정보를 반환한다.
   * display_currency_type: 통화 표시 타입
   */
  currency_meta: CurrencyMeta;
  /** 주문 시점의 환율 정보를 조회한다. */
  currency_meta_of_order?: Maybe<CurrencyMeta>;
  /** 현재 진행중인 Set 정보를 조회합니다. 진행중인 Set이 없을 경우, 또는 테스트 유저군이 아닐 경우 null을 반환합니다. */
  current_my_pick_coupon_set_info?: Maybe<CurrentMyPickCouponSetInfoResult>;
  /** 이번달 리워드 금액 */
  current_user_reward?: Maybe<CurrentUserReward>;
  /** 커스텀 스플래시 이미지 */
  custom_splash_image?: Maybe<UxSplashImage>;
  /**
   * 오늘의 혜택 출석 미션 현황
   *   deprecated ( date_attendance_gte, date_attendance_lte )
   *     : 한달기준이 아닌 연속출석 기준이기에 필요없어짐.
   * @deprecated use daily_mission_attendance_v2
   */
  daily_mission_attendance: DailyMissionAttendance;
  /** 오늘의 혜택 출석 미션 현황 */
  daily_mission_attendance_v2: DailyMissionAttendanceV2;
  /**
   * 오늘의 혜택 행동 미션 현황
   * --
   * behavior_type:
   * - CLICK_PRODUCT_BEAUTY_ON_SALE : 뷰티 특가 상품 클릭
   * - CLICK_PRODUCT_BRAND_ON_SALE : 브랜드 특가 상품 클릭
   * - VIEW_SELLER_CONTENT : 셀러 기획전 확인
   * - CLICK_PRODUCT_BLACK_WEEK : 블랙위크 특가 상품 클릭
   * - VISIT_COUPANG : 쿠팡 방문
   * - CLICK_VIDEO_AD : 영상 광고 클릭
   */
  daily_mission_behavior: DailyMissionBehavior;
  /**
   * 오늘의 혜택 포인트 및 정보 내역
   * @deprecated use getDailyMissionAvailableRewardList instead
   */
  daily_mission_board: DailyMissionBoard;
  /** 오늘의 혜택 리워드 타입 별 미션 중 참여 가능한 미션 목록 */
  daily_mission_card_list: DailyMissionCardList;
  /** 오늘의 혜택 셀러 기획전 응원 미션 목록 */
  daily_mission_cheer_seller_content_list: DailyMissionCheerSellerContentList;
  /**
   * 일별 날씨 리스트
   * - 내일부터 8일후 까지의 일별 날씨
   */
  daily_mission_daily_weather_list: DailyMissionDailyWeatherList;
  /**
   * 배송지 기반 기본 지역 반환
   * 기본 배송지 > 최근 배송지 > 서울시 강남구청 주소지
   */
  daily_mission_default_region?: Maybe<DailyMissionRegion>;
  /** 받을수 있는 시간 및 게임권 보유 현황 */
  daily_mission_game_ticket_summary: DailyMissionGameTicketSummary;
  /** 오늘의 혜택 연락처로 초대 리스트 */
  daily_mission_invitation_list: DailyMissionInvitationList;
  /**
   * 오늘의 혜택 좋아요 미션 현황
   * ---
   * type: EPICK, PRODUCT_BEAUTY, STORE
   */
  daily_mission_like: DailyMissionLike;
  /** 유저별 최근 즐겨찾기한 지역 리스트 */
  daily_mission_liked_region_list: DailyMissionLikedRegionList;
  /** 오늘의 혜택 메뉴 */
  daily_mission_menu?: Maybe<DailyMissionMenu>;
  /**
   * 오늘의 혜택 데일리 미션 미션 스탬프 목록
   * (하루마다 리셋)
   */
  daily_mission_mission_stamp_list: DailyMissionMissionStampList;
  /** 오늘의 혜택 퀴즈 미션 목록 */
  daily_mission_quiz_list: DailyMissionQuizList;
  /** 오늘의 혜택 랜덤박스 수 */
  daily_mission_random_box_count: DailyMissionRandomBoxCount;
  /**
   * 오늘의 혜택 랜덤박스 요약
   * 오늘자 기준 데이터를 전달
   * - 랜덤박스 보유 수량
   * - 랜덤박스 초대 횟수
   * - 랜덤박스 초대 횟수별 수령 여부
   */
  daily_mission_random_box_summary: DailyMissionRandomBoxSummary;
  /** 지역 검색 */
  daily_mission_region_list: DailyMissionRegionList;
  /** 오늘의 혜택 룰렛 리워드 */
  daily_mission_roulette_reward?: Maybe<DailyMissionRouletteReward>;
  /**
   * 유저별 최근 즐겨찾기한 지역 리스트
   * @deprecated use daily_mission_liked_region_list instead
   */
  daily_mission_saved_region_list: DailyMissionSavedRegionList;
  /** 오늘의 혜택 세팅 */
  daily_mission_system_config: DailyMissionSystemConfig;
  /**
   * 어제 참여한 오늘 날짜의 투표정보를 반환한다.
   * 조건에 맞는 지역 및 오늘 날짜의 날씨 기반 스타일링 투표 정보가 없으면 null 을 반환 한다.
   */
  daily_mission_today_weather_styling_poll?: Maybe<DailyMissionWeatherStylingPoll>;
  /**
   * 오늘 참여가능한 내일 날짜의 투표정보를 반환한다.
   * 조건에 맞는 지역 및 내일 날짜의 날씨 기반 스타일링 투표 정보가 없으면 null 을 반환 한다.
   */
  daily_mission_tomorrow_weather_styling_poll?: Maybe<DailyMissionWeatherStylingPoll>;
  /**
   * up/down 전광판
   * - 당첨자 수, 게임 운영상태
   * - 당첨자 정보 및 보상
   * - 오늘 시작한 게임에서, 현재까지 참여한 유저수
   */
  daily_mission_updown_board?: Maybe<DailyMissionUpDownBoard>;
  /**
   * up/down 정책
   * - 정책id, 시작 및 종료 시간, 실제 종료시간
   */
  daily_mission_updown_policy?: Maybe<DailyMissionUpDownPolicy>;
  /** 오늘의 혜택 세금 서류 조회 */
  daily_mission_user_document?: Maybe<DailyMissionUserDocument>;
  /**
   * 날씨미션 알림 스케쥴
   * - 미로그인 유저거나, 저장된 스케쥴 정보가 없는 경우 null로 전달
   */
  daily_mission_weather_notification_schedule?: Maybe<DailyMissionWeatherNotificationSchedule>;
  /**
   * 날씨 요약 리스트
   * - 오늘, 내일, 모레의 일별, 시간별 날씨를 조합해서 전달
   */
  daily_mission_weather_summary_list: DailyMissionWeatherSummaryList;
  /**
   * 오늘의 혜택 당첨자 목록
   *   - 랜덤박스 당첨자 목록
   *   - 지난주 / 이번주 럭키볼 당첨자 summary
   */
  daily_mission_winner_list: DailyMissionWinnerList;
  ddp_banner_catalog_carousel_list?: Maybe<DdpBannerCatalogListGroup>;
  /** DDP 자동TC 리스트형 조회 */
  ddp_basic_catalog_carousel_auto?: Maybe<DdpCatalogCarouselBasic>;
  /** DDP 운영TC 리스트형 조회 */
  ddp_basic_catalog_carousel_operation?: Maybe<DdpCatalogCarouselBasic>;
  /** DDP 자동TC 뷰티 피부 타입 기준 조회 */
  ddp_beauty_product_recommend_group?: Maybe<DdpProductRecommendGroup>;
  /** 체험단 운영 TC */
  ddp_beta_tester_group?: Maybe<DdpBetaTesterGroup>;
  /** DDP 북마크 스토어 신상품 모아보기 */
  ddp_bookmark_new_items_entry?: Maybe<DdpBookmarkShopNewItemsEntry>;
  /** DDP 북마크 상품 그룹 */
  ddp_bookmark_product_card_group?: Maybe<DdpBookmarkProductCardGroup>;
  /** DDP 북마크 스토어 캐러셀 */
  ddp_bookmark_shop_carousel?: Maybe<DdpBookmarkShopCarousel>;
  /** DDP 기획전 운영 TC 썸네일 배너 컴포넌트 조회 */
  ddp_brand_catalog_thumbnail_banner_group?: Maybe<DdpBrandCatalogThumbnailBannerGroup>;
  /** DDP 브랜드 진입점 조회 */
  ddp_brand_entry_menu_group?: Maybe<DdpBrandEntryMenuGroup>;
  /** DDP 스타일 카테고리 브랜드 추천 조회 */
  ddp_brand_filter_recommend_group?: Maybe<DdpBrandFilterRecommendGroup>;
  /** DDP 브랜드 패션관 개인화 추천 컴포넌트 */
  ddp_brand_personal_product_recommend_group?: Maybe<DdpProductRecommendGroup>;
  /** DDP 자동TC 브랜드 베스트 상품 조회 */
  ddp_brand_product_recommend_group?: Maybe<DdpBrandProductRecommendGroup>;
  /** DDP 인기 브랜드 컴포넌트 조회 */
  ddp_brand_ranking_group?: Maybe<DdpBrandRankingGroup>;
  /** DDP 브랜드 추천 조회 */
  ddp_brand_recommend_group?: Maybe<DdpBrandRecommendGroup>;
  /** 브랜드패션관 템플릿 정보 조회 */
  ddp_brandfashion_template: Array<DdpTemplate>;
  ddp_calendar_catalog_list?: Maybe<DdpCalendarItemGroup>;
  ddp_calender_catalog_page?: Maybe<DdpCalendarItemGroup>;
  /** 오늘의 특가 브랜드 딜 조회 */
  ddp_catalog_carousel_brand_deal?: Maybe<DdpCatalogCarouselBrandDeal>;
  /** 특가 상품 리스트 */
  ddp_catalog_carousel_brand_sale_list?: Maybe<DdpCatalogCarouselBrandSale>;
  /** DDP 브랜드특가TC 리스트형 조회 */
  ddp_catalog_carousel_brand_time_deal?: Maybe<DdpCatalogCarouselBrandTimeDeal>;
  /** DDP 하루특가TC 리스트형 조회 */
  ddp_catalog_carousel_daily_deal?: Maybe<DdpCatalogCarouselBasic>;
  /** 홈 전용홈 페이지형 TC 조회 - APP */
  ddp_catalog_carousel_image_vertical_group?: Maybe<DdpCatalogCarouselImageVerticalGroup>;
  /** DDP 타임특가TC 리스트형 조회 */
  ddp_catalog_carousel_time_deal?: Maybe<DdpCatalogCarouselDeal>;
  /** 4.0 모자이크 캐러셀 API */
  ddp_catalog_store_collection_carousel?: Maybe<DdpStoreCollectionCarousel>;
  /** 상품 모아보기 컴포넌트 */
  ddp_categorized_product_list?: Maybe<DdpCategorizedProductList>;
  /** ddp 퀵메뉴 바텀싯 */
  ddp_category_bottom_sheet?: Maybe<DdpBottomSheet>;
  ddp_category_group?: Maybe<DdpCategoryGroup>;
  /** 지그재그 4.0 칩버튼형 운영TC API */
  ddp_chip_button_product_list?: Maybe<DdpChipButtonCarousel>;
  /** DDP 스타일관 진입점 컴포넌트 */
  ddp_department_entry_menu?: Maybe<DdpEntryMenu>;
  /** Discovery Menu */
  ddp_discovery_menu: DdpDiscoveryMenuGroup;
  /** 좋아요 상위 epick post */
  ddp_epick_post_top_like_list?: Maybe<DdpEpickCarousel>;
  /** DDP List Filter */
  ddp_filter_list: DdpListFilter;
  /** DDP 범용 리스트 */
  ddp_filtered_item_list: DdpItemListInfo;
  /** DDP 팔로우탭 북마크 페이지 */
  ddp_follow_bookmark_group?: Maybe<DdpFollowBookmarkGroup>;
  /** DDP 카테고리 컴포넌트 조회 */
  ddp_grid_category?: Maybe<DdpGridCategory>;
  /** 브랜드패션관 DDP 카테고리 컴포넌트 조회 */
  ddp_grid_category_brand?: Maybe<DdpGridCategory>;
  /** 홈 특가 그룹 단건 조회 - APP */
  ddp_home_catalog_carousel_time_deal?: Maybe<DdpCatalogCarouselBrandTimeDeal>;
  /** DDP 홈 퀵메뉴 */
  ddp_home_quick_menu?: Maybe<DdpHomeQuickMenu>;
  /** DDP 자동TC 실시간 베스트 상품 조회 */
  ddp_hourly_product_best_group?: Maybe<DdpHourlyProductBestGroup>;
  /** DDP 자동TC 아이콘형 조회 */
  ddp_icon_catalog_carousel?: Maybe<DdpCatalogCarouselIcon>;
  /** DDP icon category */
  ddp_icon_category?: Maybe<DdpIconCategoryList>;
  /** DDP 운영TC 배너형 조회 */
  ddp_image_catalog_carousel?: Maybe<DdpCatalogCarouselImage>;
  /** DDP 브랜드관 운영TC 이미지형 조회 */
  ddp_image_catalog_carousel_brand?: Maybe<DdpCatalogCarouselImageBrand>;
  /** DDP 운영TC 배너+리스트형 조회 */
  ddp_image_catalog_carousel_vertical?: Maybe<DdpCatalogCarouselImageVertical>;
  /** DDP 운영TC 배너+리스트형 그룹 조회 */
  ddp_image_catalog_carousel_vertical_group?: Maybe<DdpCatalogCarouselImageVerticalGroup>;
  ddp_item_tag_product: DdpItemTagRecommendShopList;
  /** 최신 매거진(다른 매거진) */
  ddp_latest_magazine_list: DdpMagazineCardGroup;
  /** DDP Line Banner */
  ddp_line_banner?: Maybe<DdpLineBanner>;
  /** 매거진 캐러셀 */
  ddp_magazine_carousel?: Maybe<DdpMagazineCarousel>;
  /** 매거진 리스트 */
  ddp_magazine_list: DdpMagazineCardGroup;
  ddp_main_home_recommend_store_group?: Maybe<MainHomeRecommendStoreGroup>;
  ddp_mds_pick_product_card_group?: Maybe<DdpProductCardGroup>;
  /** DDP 운영TC 모자이크형 조회 */
  ddp_mosaic_catalog_carousel?: Maybe<DdpCatalogCarouselMosaic>;
  /** DDP 신상품 조회 */
  ddp_new_catalog_carousel?: Maybe<DdpCatalogCarouselNew>;
  /** DDP Onboarding */
  ddp_onboarding?: Maybe<DdpOnboarding>;
  /** ddp 페이지 정보 */
  ddp_page_info?: Maybe<DdpPageInfo>;
  /** DDP 버전 pages */
  ddp_pages?: Maybe<UxDdpPage>;
  /** DDP Card Group */
  ddp_product_card_group?: Maybe<DdpProductCardGroup>;
  /** DDP 자동TC DDP관 기준 조회 */
  ddp_product_recommend_group?: Maybe<DdpProductRecommendGroup>;
  ddp_promotion_floating_banner?: Maybe<DdpPromotionFloatingBanner>;
  /** 홈 4.0 프로모션 TC */
  ddp_promotion_tc?: Maybe<UxPromotionTc>;
  /** 앱에서 사용할 퀵메뉴 query */
  ddp_quick_menu?: Maybe<DdpQuickMenu>;
  /** DDP 베스트상품 조회 */
  ddp_ranking_catalog_carousel?: Maybe<DdpCatalogCarouselRanking>;
  ddp_ranking_recommend_shop_carousel?: Maybe<DdpRankingRecommendedShopCarousel>;
  ddp_recommend_item_tag_shop: DdpRecommendItemTagShop;
  /** DDP 띠 배너 */
  ddp_rolling_band_banner?: Maybe<DdpRollingBandBanner>;
  ddp_rolling_image_banner?: Maybe<DdpRollingImageBanner>;
  /** DDP New Today */
  ddp_rolling_items_entry?: Maybe<DdpRollingItemsEntry>;
  /** DDP Search */
  ddp_search?: Maybe<DdpSearch>;
  /** ddp 실렉티드관 즐찾 신상 */
  ddp_selected_catalog_carousel_new?: Maybe<DdpCatalogCarouselNew>;
  /** ddp 실렉티드관 카테고리 정보 제공 */
  ddp_selected_product_card_group?: Maybe<DdpSelectedProductCardGroup>;
  /** 실렉티드관 자동 TC 베스트 상품 랭킹 조회 */
  ddp_selected_product_recommend_group?: Maybe<DdpProductRankingGroup>;
  /** 실렉티드관 자동 TC 베스트 상품 랭킹 f조회 */
  ddp_selected_product_recommend_list_group?: Maybe<DdpProductRankingListGroup>;
  /** ddp 실렉티드관 렝킹정보 제공 */
  ddp_selected_ranking_info?: Maybe<DdpShopRankingInfo>;
  /** ddp 실렉티드관 롤링 배너 */
  ddp_selected_rolling_image_banner?: Maybe<DdpRollingImageBanner>;
  /** DDP 숏폼 group */
  ddp_short_form_group?: Maybe<DdpShortFormGroup>;
  /** DDP 숏폼 상세 페이지 노출 예정 숏폼 ID 리스트 */
  ddp_short_form_init_id_list: Array<Scalars['ID']>;
  /** DDP 숏폼 item list */
  ddp_short_form_item_list: Array<DdpShortFormItem>;
  ddp_single_banner?: Maybe<DdpSingleBanner>;
  /** 스낵 트랜드 바디 바텀 시트 */
  ddp_snack_trend_body_bottom_sheet?: Maybe<DdpSnackTrendBodyBottomSheet>;
  /** 스낵 트랜드 카드 그룹 */
  ddp_snack_trend_card_group?: Maybe<DdpSnackTrendCardGroup>;
  /** 지그재그 4.0 주목할만한 기획전 API */
  ddp_special_exhibition_list?: Maybe<DdpSpecialExhibitionCarousel>;
  /** DDP 팔로우탭 스토리 컴포넌트 */
  ddp_story_shop_info?: Maybe<DdpStoryShopInfoCarousel>;
  /** 스타일링 카드 그룹 */
  ddp_styling_card_group?: Maybe<DdpStylingCardGroup>;
  /** layout에 표현할 component 의 기본적인 skeleton 정보 */
  ddp_template: Array<DdpTemplate>;
  /** DDP 자동TC 직진배송 상품 추천 조회 */
  ddp_zigzin_product_recommend_group?: Maybe<DdpZigzinProductRecommendGroup>;
  /** 자동TC - 직진배송추천 */
  ddp_zigzin_recommend_product_carousel?: Maybe<DdpZigzinRecommendProduct>;
  /** 배너의 넛징 데이터 */
  determine_nudge_badge_for_banners?: Maybe<Array<BannerNudgingBadge>>;
  /** 탈퇴시 사라지는 혜택 정보 */
  disappearing_benefits: DisappearingBenefit;
  /** 팔로워 목록 */
  epick_follower_list: FollowListResponse;
  /** 팔로잉 목록 */
  epick_following_list: FollowingListResponse;
  /**
   * 에픽 post 단건을 조회한다.
   * [error_code]
   * NOT_FOUND_POST: 게시글이 존재하지 않음 / OPEN 상태가 아님 / 신고된 게시글인 경우
   */
  epick_post: EPickPost;
  /** 에픽 post ID 목록으로 post 목록을 조회한다. */
  epick_post_list: Array<EPickPost>;
  /** 프로필 단건 */
  epick_profile: EPickProfile;
  /**
   * 에픽 추천 게시글 목록을 조회한다.
   * 최대 560개의 목록이 반환된다.
   * - 공통조건: 디부스트, 게시글 삭제, PDP 미노출 제외
   * - 팔로우한 에디터의 최근 4일 이내 포스트 shuffle: 10개
   * - 개인화 추천 shuffle: 200개
   * - 최근 4일 이내 좋아요 많은순: 200개
   * - 최근 4일 이내 게시물 최신순: 300개
   */
  epick_recommend_post_list: EPickRecommendPostList;
  /** 댓글 단건 */
  epick_reply: EPickReply;
  /**
   * 에픽 서비스 검색(닉네임, 태그명)
   * profile_limit: default 3
   * tag_limit: default 3
   */
  epick_searched_list: EPickSearchedListResponse;
  /**
   * (임시) 주어진 post ID 와 연관된 post 목록을 반환한다.
   * 최대 280개의 목록이 반환된다.
   * - 공통조건: 디부스트, 게시글 삭제, PDP 미노출 제외
   * - 같은 상품을 착용한 게시글 좋아요 많은 순: 20개
   * - 비슷한 상품을 착용한 게시글 좋아요 많은순: 50개
   * - 게시글 작성자의 최근 게시글: 10개
   * - 개인화 추천 shuffle: 200개
   */
  epick_temp_related_post_list: EPickTempRelatedPostList;
  /**
   * 배송비 그룹 주문서 환불예상액을 계산한다.
   * [error_code]
   * - order_not_found: 해당 주문이 존재하지 않는 경우
   * - order_item_not_found: 해당 상품주문이 존재하지 않는 경우
   * - order_item_invalid_status: 구매확정 된 아이템이 포함된 경우
   */
  estimated_shipping_group_refund_amounts: EstimatedShippingGroupRefundAmountsInfo;
  /** 202001 장바구니 추가 이벤트 메타데이터를 받는다. */
  event_metadata_add_to_cart_202001: EventMetadataAddToCart202001Result;
  /**
   * 202001 장바구니 추가 이벤트 참여 가능 여부를 반환한다.
   * 참여가 가능할경우 true를 리턴한다.
   * [error_code]
   * - event_terminated: 이벤트가 종료됐을 경우
   * - already_participated: 이벤트에 이미 참여한 경우
   * - exceeded_the_point_limit: 총 응모포인트가 한도에 도달했을 경우
   */
  event_participated_add_to_cart_202001: EventParticipatedAddToCart202001Result;
  /**
   * 해당 사용자의 이벤트 참여 결과를 반환한다.
   *
   * [error_code]
   * - did_not_participate_event: 이벤트에 참여한 이력이 없는 경우
   */
  event_participation_result: EventParticipationResult;
  /**
   * 이벤트 참여 가능 상태를 반환한다.
   * 참여가 가능할 경우 서버시간과 참여가능 상태를 반환한다.
   *
   * [error_code]
   * - event_terminated: 이벤트가 종료됐을 경우
   * - event_is_preparing: 이벤트가 대기중(오픈 전)일 경우
   *
   * - event_ends_on_weekend: 주말에는 이벤트가 오픈되지 않음
   * - exceeded_the_participants_limit: 응모자 수가 한도에 도달했을 경우
   */
  event_status: EventStatusResult;
  /**
   * 해당 사용자의 이벤트 참여 가능 상태를 반환한다.
   * 참여가 가능할 경우 서버시간과 참여가능 상태를 반환한다.
   *
   * [error_code]
   * - not_found_user_account: 해당 id로 유저 계정을 찾을 수 없는 경우
   * - mobile_number_required: 해당 유저 계정에 핸드폰 번호가 없는 경우
   * - already_participated: 이미 참여한 이력이 있는 경우
   */
  event_user_status: EventUserStatusResult;
  /** 교환요청시 필요한 상품 목록 */
  exchange_product_list: ExchangeProductList;
  /** 외부 상품 리뷰 리스트 조회 */
  external_product_review_list: ExternalProductReviewList;
  /**
   * 패바카의 현재 활성화된 카테고리를 반환한다
   * root category 부터 2 depth category 까지 반환한다
   */
  fbk_activated_display_category: FbkDisplayCategory;
  /** page_name(TREND, CATEGORY)의 활성화된 피처 메뉴 그룹 */
  fbk_activated_feature_menu_group?: Maybe<FbkFeatureMenuGroup>;
  /** 패바카의 활성화된 샵 메타 카테고리 목록을 반환한다 */
  fbk_activated_shop_meta_category_list: FbkShopMetaCategoryList;
  /** 페바카 서비스에서 호출하는 사용가능한 배너 리스트 */
  fbk_active_banner_list: FbkBannerList;
  /**
   * 서비스 서빙용 활성화된 base 컨텐트 리스트
   * @deprecated Field no longer supported
   */
  fbk_active_base_content_list: FbkBaseContentList;
  /**
   * 패바카 활성화된 쿠폰 정책 단건을 반환한다.
   * 주어진 조건에 맞는 쿠폰 정책이 없는 경우 null 을 반환한다.
   */
  fbk_active_coupon_policy?: Maybe<FbkCouponPolicy>;
  /** 패바카 활성화된 쿠폰 정책 목록을 반환한다. */
  fbk_active_coupon_policy_list: FbkCouponPolicyList;
  /** 활성화된 패바카 이벤트 모달 목록을 반환한다. */
  fbk_active_event_modal_list: FbkEventModalList;
  /** 패바카 활성화된 띠 배너를 반환한다. */
  fbk_active_line_banner?: Maybe<FbkLineBanner>;
  /** md 추천 생성한 프로덕트 리스트 */
  fbk_active_new_product_list_by_md_pick: FbkActiveNewProductListByMdPick;
  /** 서비스 서빙용 추천 스토어 리스트 */
  fbk_active_recommend_shop_list: FbkRecommendShopList;
  /**
   * UX -> FBK-UX
   * 연령별로 잘나가는 keyword 리스트를 정리해서 반환한다
   * @deprecated 사용하지 않음
   */
  fbk_age_recommended_keyword_info: FbkAgeRecommendedKeywordInfo;
  /** 출석체크 현황 */
  fbk_attendance_event_log_summary: FbkAttendanceEventLogSummary;
  /** 출석체크 30일 이상 달성 유저중 추첨된 유저 리스트 */
  fbk_attendance_event_raffle_user_account_list: FbkAttendanceEventRaffleUserAccountList;
  /** 유저에게 노출되어야하는 뱃지의 목록을 반환 */
  fbk_badge_list: Array<FbkBadge>;
  /** 패바카 배너광고 송출 */
  fbk_banner_ad_display?: Maybe<FbkBannerAdDisplay>;
  /**
   * FBK 노출 카테고리 ID 별 베스트 상품 랭킹 목록을 반환한다.
   * 최대 200개의 상품목록이 반환된다.
   */
  fbk_best_product_ranking_list: FbkProductRankingList;
  /** FBK 샵 메타 카테고리 ID 별 베스트 샵 랭킹 목록을 반환한다. */
  fbk_best_shop_ranking_list: FbkShopRankingList;
  /**
   * 패바카의 부스트 샵 쿠폰 정책을 반환한다.
   * 활성화된 부스트 샵 쿠폰정책이 없는 경우 null 을 반환한다.
   * 활성화된 부스트 샵 쿠폰정책이 여러개 있는 경우, random 하게 하나만 반환한다.
   */
  fbk_boost_shop_coupon_policy?: Maybe<FbkBoostShopCouponPolicy>;
  /**
   * 캐러셀 호출 쿼리
   * carousel_page_name, carousel_page_name_list 공통사용
   * [ TREND_TAB_CAROUSEL(기본값), TREND_TAB_CAROUSEL, ZIGZIN_CAROUSEL, KIDS_CAROUSEL ]
   */
  fbk_carousel_list: Array<FbkCarousel>;
  /** 세일탭 할인하는 신상품 리스트 */
  fbk_discounted_new_product_list: FbkDiscountedNewProductItemList;
  fbk_event_detail_page?: Maybe<FbkEventDetail>;
  /** 홈지면 광고 ID 또는 상품 ID 목록을 반환한다. */
  fbk_home_ad_or_product_id_list: FbkHomeAdOrProductIdList;
  /**
   * 홈지면 광고 ID 를 기반으로 패바카 홈지면 광고 상품목록을 반환한다.
   * 주어진 광고 ID 순으로 정렬되어 반환한다.
   */
  fbk_home_ad_product_list: FbkHomeAdProductList;
  /**
   * 패바카 홈 광고 카테고리 리스트
   * @deprecated 사용하지 않음
   */
  fbk_home_hot_ad_category_chip_list: Array<FbkHomeCategoryChip>;
  /** 패바카 이미지검색 프리셋 상품목록 */
  fbk_image_search_preset_product_list: FbkImageSearchPresetProductList;
  /**
   * 페바카
   * 주어진 조건에 모두 일치하는 이미지 검색 상품목록을 받는다.
   * 조회된 상품이 없는 경우 빈 배열을 반환한다.
   * [error_code]
   * - invalid_image_search_content_type: 이미지검색 콘텐츠 타입이 올바르지 않습니다.
   */
  fbk_image_search_product_list: FbkImageSearchProductList;
  /** 패바카 사용자가 좋아요한 상품 목록 */
  fbk_like_product_list: FbkLikeProductList;
  /** 패바카 사용자가 좋아요한 샵의 최근 상품 목록을 반환한다. */
  fbk_like_shop_latest_product_list: FbkLikeShopLatestProductList;
  fbk_like_shop_list: UxShopRankingItemList;
  /** 페바카 서비스와 관련된 메타데이터를 제공한다. */
  fbk_metadata: FbkMetadata;
  /** 네이티브 탭 구성을 위한 쿼리 */
  fbk_native_app_tab_list: Array<FbkNativeAppTab>;
  /** 신상탭 카테고리별 상품 리스트 */
  fbk_new_product_list_by_category: FbkNewProductListByCategory;
  /**
   * 개인화 추천 상품
   * 최소 3개, 최대 6개
   * 광고 포함 X
   */
  fbk_personalized_product_list: Array<FbkProduct>;
  /** 많이 찾는 카테고리 리스트 */
  fbk_popular_display_category_list: FbkPopularDisplayCategoryList;
  /** 상품의 베스트 리뷰 정보 목록 ( 최소 1개, 최대 5개 ) */
  fbk_product_best_review_list: FbkProductBestReviewList;
  /**
   * 상품 배송 예상 요약 목록을 반환한다.
   * 상품 ID만 주어진 경우, 내부적으로 해당 상품의 샵 ID, 1 depth 관리카테고리 ID 를 찾아서 배송 예상 요약을 조회하고, 결과값을 반환한다.
   * @deprecated use fbk_product_delivery_summary
   */
  fbk_product_delivery_estimate_summary?: Maybe<FbkProductDeliveryEstimateSummary>;
  /**
   * 주어진 상품 ID 에 대한 배송 요약을 반환한다.
   * - 배송 예상 요약 (있는 경우)
   * - 배송 지연 이력 여부
   */
  fbk_product_delivery_summary: FbkProductDeliverySummary;
  /**
   * 상품 ID 를 기반으로 패바카 상품목록을 반환한다.
   * 주어진 상품 ID 순서로 정렬되어 반환한다.
   */
  fbk_product_list_by_id_list: FbkProductListByIdList;
  /**
   * 패바카 상품 최대 혜택 계산을 위한 최대할인쿠폰, 사용자계정의 현재 사용가능 포인트를 반환한다.
   * 반환할 상품 혜택 정보가 없는 경우 null 을 반환한다.
   */
  fbk_product_max_benefit?: Maybe<FbkProductMaxBenefit>;
  /** 활성화중인 패바카 프로모션 뱃지 리스트를 반환한다. */
  fbk_promotion_badge_list: FbkPromotionBadgeList;
  /** 패바카 최근 본 상품 목록 */
  fbk_recent_visit_product_list: FbkRecentVisitProductList;
  /**
   * 추천 스토어 리스트
   * shop_page_name
   * [ ZIGZIN, KIDS ]
   * @deprecated fbk_active_recommend_shop_list 로 대체
   */
  fbk_recommend_shop_list: FbkShopList;
  /**
   * 패바카 연관 상품 목록을 반환한다.
   * 노출 지면 명 인자가 주어지지 않은 경우, 순수 연관상품 목록만 만환한다.
   */
  fbk_related_product_list: Array<FbkProduct>;
  /** 최근 본 상품리스트와 각 상품의 연관 + 비슷한 상품을 보여준다. */
  fbk_related_product_list_by_recent_view: FbkRelatedProductListByRecentView;
  /** 연관 검색어 리스트를 반환한다 */
  fbk_related_search_word_list: Array<Scalars['String']>;
  /** 최근 즐겨찾기한 샵의 연관된 추천 샵 리스트 */
  fbk_related_shop_list_by_recent_saved_shop: FbkShopList;
  fbk_sale_timesale_product_list: FbkTimeSaleCardItemList;
  /**
   * UX -> FBK-UX
   * 키워드 검색 제안
   */
  fbk_search_popular_keyword: FbkSearchKeywordSuggestion;
  /**
   * FBK 검색 상품 목록
   * (인기순정렬 기본)
   */
  fbk_search_product_list: FbkSearchProductList;
  /**
   * 주어진 조건 모두에 일치하는 패바카 샵을 반환한다.
   * 조건이 주어지지 않으면 null을 반환한다.
   * 샵 ID 와 상품 ID 모두 주어지는 경우, 상품 ID 조건은 무시한다.
   * 조건에 맞는 샵이 없으면 null을 반환한다.
   */
  fbk_shop?: Maybe<FbkShop>;
  /** 패바카 스토어 인기 상품 */
  fbk_shop_best_product_list: Array<FbkProduct>;
  /** 최대 20개의 shop id list 를 받아 샵 목록을 반환한다. */
  fbk_shop_list: FbkShopList;
  /** 패바카 샵라이브 캠페인 목록을 조회한다. */
  fbk_shoplive_campaign_list: FbkShopliveCampaignList;
  /** FBK Shoplive 캠페인 메타 정보 */
  fbk_shoplive_campaign_meta_info: ShopliveSharingMetaInfo;
  /**
   * FBK Shoplive JWT 토큰
   * @deprecated use generateFbkShopliveJwtToken
   */
  fbk_shoplive_jwt_token: ShopliveJwtToken;
  /** FBK 샵라이브 예정 방송 조회 */
  fbk_shoplive_upcoming_campaign?: Maybe<ShopliveCampaignInfo>;
  /**
   * 패바카 비슷한 상품 목록
   * [error_code]
   * - invalid_limit_count: limit_count 수는 10 이상 50 이하만 가능합니다.
   */
  fbk_similar_product_list: Array<FbkProduct>;
  fbk_srp_search_product_list?: Maybe<UxPageInfo>;
  fbk_srp_shop_list: SearchedShopList;
  fbk_storepick_list: FbkStorePickItemList;
  fbk_style_tab?: Maybe<FbkStyleTab>;
  /** 패바카 (임시) pdp category 연관 상품 목록을 반환한다. */
  fbk_tmp_pdp_category_related_product_list: FbkTmpPdpCategoryRelatedProductList;
  /** 트랜드탭 캐러셀 리스트를 반환 */
  fbk_trend_carousel_list: Array<FbkCarousel>;
  /** 인기 키워드 리스트 */
  fbk_trend_keyword_list: FbkTrendKeywordList;
  /**
   * UX -> FBK-UX
   * 직진배송 즐겨찾기 한 쇼핑몰 상품 페이지
   * @deprecated 사용하지 않음
   */
  fbk_zigzin_saved_shop_items: FbkZigzinSavedShopItems;
  /**
   * Deprecated API
   * @deprecated Field no longer supported
   */
  featured_shop_list: FeaturedShopList;
  /** 해당 휴대폰 번호를 갖고 있는 사용자 계정 email을 반환한다 (이메일은 모두 노출되지 않는다) */
  findUserAccountEmailByMobileTel?: Maybe<Scalars['String']>;
  /**
   * 본인인증으로 인증된 사용자에게 계정정보를 전달한다.
   * [의도된 error_code]
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  find_account_with_authentication: FindAccountWithAuthenticationResult;
  /**
   * 휴대폰 인증으로 인증된 사용자에게 계정정보를 전달한다.
   * [의도된 error_code]
   * - mobile_authentication_token_expired: 휴대폰 인증 토큰이 만료된 경우
   * - mobile_phone_token_authentication_failed: 휴대폰 번호와 인증번호가 알맞지 않는경우
   * - user_account_not_found: 사용자 계정을 찾을 수 없는 경우
   */
  find_account_with_mobile_authentication: FindAccountWithAuthenticationResult;
  /** 체험단이 진행중인 상품 리스트를 조회합니다. */
  getActiveExperienceGroupProductList: ActiveExperienceGroupProductList;
  /** 체험단이 진행중인 상품 리스트를 조회합니다. (M1) */
  getActivePromotionExperienceGroupProductList: ActivePromotionExperienceGroupProductList;
  /** 활성화(응모 예정, 응모 중)된 래플 조회 */
  getActiveRaffleList: ActiveRaffleList;
  /** 어필레이트 PDP 공유하기시 노출되는 모달에 노출될 메타 정보를 조회한다. */
  getAffiliatePdpModalMeta?: Maybe<GetAffiliatePdpModalMeta>;
  /**
   * 어필레이트 링크 만들기 페이지에서 노출할 상품 id list 를 조회한다.
   * (기본으로 상품 id는 100개씩 조회한다.)
   */
  getAffiliateProductIdList: GetAffiliateProductIdList;
  /** 어필레이트 소개 페이지에서 노출될 샵을 소호/브랜드에 따라 나눠서 보여준다 */
  getAffiliatePromotionShopList: GetAffiliatePromotionShopList;
  /**
   * 릴레이 어택을 예방하기 위해 SHA256 형식의 데이터를 반환합니다
   * [error_list]
   * - invalid_client : 클라이언트 세션 데이터가 올바르지 않을 경우
   */
  getAppleLoginConfig: AppleLoginConfig;
  getAvailablePaymentMethodList: Array<PaymentMethod>;
  /** 3탭 배너 API */
  getBannerList: GetBannerListResult;
  /** 혜택존 배너 상세 */
  getBenefitBanner: BenefitBanner;
  /** 혜택존 배너 목록 */
  getBenefitBannerList: GetBenefitBannerListResult;
  /** 혜택존 섹션 데이터 */
  getBenefitSectionData: BenefitSectionDataResult;
  /** 혜택존 섹션 설정 */
  getBenefitSectionProps: BenefitSectionPropsResult;
  /** 유저의 쿠폰 발급 상태를 조회한다. */
  getCouponIssueStatus: CouponIssueStatusResult;
  /** CRM 추천 아이템 목록 */
  getCrmRecommendItemList: GetCrmRecommendItemListResult;
  /** CRM 추천 아이템 메타데이터 */
  getCrmRecommendMetadata: GetCrmRecommendMetadataResult;
  /** 오늘의 혜택 메인화면에 최근 참여한 미션 혹은 참여 가능한 미션 목록 조회 */
  getDailyMissionAvailableCardAndRewardList: GetDailyMissionAvailableCardAndRewardList;
  /** 오늘의 혜택 받을수 있는 리워드 목록 */
  getDailyMissionAvailableRewardList: Array<DailyMissionAvailableReward>;
  /** 오늘의 혜택 럭키볼 참여 내역 */
  getDailyMissionLuckyBallParticipateHistoryList: DailyMissionLuckyBallParticipateHistoryList;
  /** 오늘의 혜택 럭키볼 참여 가능 여부 */
  getDailyMissionLuckyBallPlayableStatus: GetDailyMissionLuckyBallPlayableStatus;
  /** 오늘의 혜택 럭키볼 주간 통계 */
  getDailyMissionLuckyBallWeeklyStatsList: GetDailyMissionLuckyBallWeeklyStatsList;
  /**
   * 참여 가능한 포인트 보상 데일리 미션 리스트
   * @deprecated use daily_mission_card_list instead
   */
  getDailyMissionMenuInParticipatableAndRewardTypePoint?: Maybe<DailyMissionMenu>;
  /** 오늘의 혜택 퀴즈 미션 조건 장바구니 수 */
  getDailyMissionQuizCartProductCount: Scalars['Int'];
  /**
   * 오늘 받은 리워드 리스트
   * - 받은 리워드
   * - 추가리워드 지급 미션에 필요한 총 리워드와 받은 리워드 정보
   * ===============
   * 로그인 되어있는 경우 - 오늘 받은 리워드와 리워드 달성 미션을 위한 목표 리워드와 적립 리워드를 전달한다.
   * 로그인이 안되어있는 경우 - 오늘 받은 리워드는 0으로 전달, 리워드 달성 미션을 위한 목표 리워드는 전달하고, 적립 리워드는 전달하지 않는다.
   */
  getDailyMissionReceivedRewardSummary: GetDailyMissionReceivedRewardSummary;
  /**
   * 오늘의 혜택 추천 미션
   * - 미션을 완료 한 경우, 완료한 미션을 제외한 추천 미션 단건을 전달한다.
   */
  getDailyMissionRecommended?: Maybe<DailyMissionCard>;
  /** 미션 공유 링크 전달 */
  getDailyMissionShareLink?: Maybe<GetDailyMissionShareLink>;
  /**
   * 오늘의 혜택 사용가능한 샵 상품 찜하기 미션 대상 샵 단건을 반환한다.
   * ---
   * 활성화된 미션이 없는 경우 - null 을 반환한다.
   * 로그인이 안되어있는 경우 - 활성화된 미션 중 랜덤하게 하나의 샵 정보가 출력한다.
   * 로그인이 되어있는 경우 - 활성화된 미션 중 하루동안 특정 샵 하나만 출력한다.
   */
  getDailyMissionShopProductLikedTargetShop?: Maybe<GetDailyMissionShopProductLikedTargetShop>;
  /**
   * up/down 게임 최초 패배 보상 지급 현황
   * - 보상 수령 가능 여부와, 수령 여부를 반환한다.
   */
  getDailyMissionUpDownFirstLoseRewardStatus: GetDailyMissionUpDownFirstLoseRewardStatus;
  /**
   * up/down 게임 참여 기록
   * - 내려주는 up down 정책에서 참여기록이 없는 경우에는 null 로 내려간다.
   */
  getDailyMissionUpDownRecord?: Maybe<GetDailyMissionUpDownRecord>;
  /**
   * up/down 게임 당첨 기록 목록
   * - 최근 7일간의 당첨 내역 반환
   * - 당첨된 내역이 없는 경우 빈 리스트로 반환
   */
  getDailyMissionUpDownRewardHistoryListInWeek: Array<DailyMissionUpDownRewardHistory>;
  /** 제세공과금 심사 결과 */
  getDailyMissionUserDocumentInspectionResult?: Maybe<GetDailyMissionUserDocumentInspectionResult>;
  /**
   * 노출중인 스토어픽 목록(3탭배너)
   * POOL_TYPE 으로 호출시 skip 값에 의한 pagination하지않는다
   */
  getDisplayStorePickListByOrderType: GetDisplayStorePickListByOrderTypeResult;
  /** APP 내 에픽 post 작성 컴포넌트를 위한 메타 정보를 조회한다. */
  getEPickEditingInfo: EPickEditingInfo;
  /**
   * 주어진 post ID 에 해당하는 post 를 작성한 profile 의 최신 post 목록을 조회한다.
   * (post ID 목록을 반환한다.)
   * 쿼리명 변경 필요 - 예) getEPickPostIdListBySameProfile
   * @deprecated 사용 중지, 제거 필요
   */
  getEPickPostListByPostId: GetEPickPostListByPostIdResponse;
  /**
   * 에픽 스타일 카테고리에 매핑된 post 목록을 조회힌다. (for 추천탭)
   * (post ID 목록을 반환한다.)
   * @deprecated use epick_recommend_post_list
   */
  getEPickPostListByStyleCategory: GetEPickPostListByStyleCategoryResponse;
  /**
   * 에픽 스타일 태그 값에 매핑된 post 목록을 조회한다. (for 추천탭)
   * (post ID 목록을 반환한다.)
   */
  getEPickPostListByStyleTag: GetEPickPostListByStyleTagResponse;
  /**
   * 특정 태그에 포함된 에픽 post 목록을 조회한다.
   * (post ID 목록을 반환한다.)
   */
  getEPickPostListByTag: GetEPickPostListByTagResponse;
  /**
   * 에픽 post 단건을 조회한다. (상태값 상관없이 조회)
   * [error_code]
   * NOT_FOUND_POST: 게시글이 존재하지 않음
   */
  getEPickPostPreview: EPickPost;
  /** 사용자계정이 등록한 에픽 post 개수 정보를 조회한다. */
  getEPickPostedMetaByUser: EPickPostedMetaByUser;
  /** 댓글의 댓글 목록 3개씩 (최신순 정렬) */
  getEPickReReplyListByReplyId: GetEPickReReplyListByReplyIdResponse;
  /** 게시글의 댓글 목록 20개씩 (최신순 정렬) */
  getEPickReplyListByPostId: GetEPickReplyListByPostIdResponse;
  /** 에픽 스타일 카테고리 값 */
  getEPickStyleTag: Array<Maybe<EPickStyleTagResponse>>;
  /** 트렌드 키워드 목록 조회 */
  getEPickTrendKeywordTag: Array<Maybe<EPickTrendKeywordTagResponse>>;
  /** 에픽 메인에서 노출되는 배너 리스트 */
  getEpickEventBannerList: EPickCmsBannerList;
  /**
   * 사용자계정이 등록한 에픽 post 목록을 조회한다.
   * (post ID 목록을 반환한다.)
   */
  getEpickPostListByUser: PostListByUserResponse;
  /** 업로드 페이지에서 노출되는 배너 리스트 */
  getEpickUploadEventBannerList: EPickCmsBannerList;
  /** 글로벌배송비를 아이템 정보로 조회 */
  getEstimatedGlobalShippingExpense: EstimatedGlobalShippingCostResponse;
  /**
   * 이벤트 콘텐츠 댓글 조회
   * after, limit 으로 목록 조회한다
   * limit 은 최대 30으로 제한
   */
  getEventContentReplyList: GetEventContentReplyListResult;
  /** 이벤트 콘텐츠 댓글 메타데이터 조회 */
  getEventContentReplyMetadata?: Maybe<EventContentReplyMetadata>;
  /** 이벤트 메타데이터 조회 */
  getEventMetadata: EventMetadataResult;
  /**
   * 이벤트 프로모션 메타데이터 조회
   * 상세 데이터는 CrJson 형태로 제공됩니다.
   * https://www.notion.so/croquis/EventPromotionMetadata-80b95ca613ea4a3e9ad6cd28f5d08272 를 참고하세요.
   */
  getEventPromotionMetadata: EventPromotionMetadata;
  /** 이벤트 프로모션 알림 동의 조회 */
  getEventPromotionNotificationAgreement?: Maybe<GetEventPromotionNotificationAgreementResult>;
  /** 체험단 단일 상품을 조회합니다. */
  getExperienceGroupProduct?: Maybe<ExperienceGroupProduct>;
  /** 체험단 프로모션에 등록된 상품의 shop department 리스트를 조회(승인된 리스트만 리턴함) */
  getExperienceGroupProductDepartmentList: Array<Scalars['String']>;
  /** 유저가 참여한 상품 리스트를 반환합니다. */
  getExperienceGroupUserAppliedItemList: ExperienceGroupUserAppliedItemList;
  /** 마이페이지에 노출하도록 설정된 최신의 공지사항을 반환한다. */
  getExposingZigzagNoticeInMypage?: Maybe<ZendeskNotice>;
  /**
   * 패바카 하이라이트 샵라이브 캠페인을 조회한다.
   * (ONAIR 또는 3 시간 이내 시작 예정인 캠페인)
   */
  getFbkHighlightShopliveCampaign?: Maybe<FbkShopliveCampaign>;
  /** 트랜드 탭 노출 부스팅 호출 쿼리 */
  getFbkHomeAdAndPersonalizedProductList: GetFbkHomeAdAndPersonalizedProductList;
  /** 사용자계정의 최근 패바카 멤버십을 반환한다. */
  getFbkLatestUserAccountMembership?: Maybe<GetFbkLatestUserAccountMembershipResult>;
  /** 신상품이 있는 shop 정보만 리턴 */
  getFbkShopListHasNewProduct: GetFbkShopListHasNewProduct;
  /** 사용자계정의 패바카 멤버십 예측을 반환한다. */
  getFbkUserAccountMembershipEstimation: GetFbkUserAccountMembershipEstimationResult;
  /** 에픽 팔로잉 탭 내 에픽 post 또는 profile 목록을 조회한다. */
  getFollowList: GetFollowListResponse;
  /**
   * 사용자계정이 좋아요(보관)한 에픽 post 목록을 조회한다.
   * (post ID 목록을 반환한다.)
   */
  getLikedEpickPostListByUser: LikedPostListResponse;
  /** 버저닝 된 관리 카테고리 hierarchy 조회 */
  getManagedHierarchyCategory?: Maybe<VersioningCategory>;
  /**
   * 유저 멤버십 및 마일리지 정보 조회
   * @description
   * 현재 로그인 사용자의 멤버십과 마일리지 정보를 조회하기 위한 API
   */
  getMembershipMileage?: Maybe<GetUserMembershipMileageResult>;
  /** 맴버십 마일리지 적립 금액 조회 */
  getMembershipMileageDepositList: MembershipMileageDepositList;
  /** 멤버십 기능 운영 상태를 받는다. 정상 운영 상태라면 true를 반환한다. */
  getMembershipOperationStatus: Scalars['Boolean'];
  /**
   * 내가 참여한 이벤트 메타데이터 조회
   * 로그인한 정보가 없을 경우 null을 리턴
   */
  getMyParticipateEventMetadata?: Maybe<MyParticipateEventMetadataResult>;
  /**
   * 최소주문금액에 근접한 쿠폰정보 반환
   * - 현재 직진배송 + 상품그룹쿠폰만 해당
   * - 사용했다면 제외
   * - 최소주문금액에 미달될때에만 응답값 존재. 그 외 null
   * - 중복 정책이 나온다면 택1(단 이미 사용한 쿠폰은 제외)
   */
  getNearbyMinRequiredAmountCouponInfo?: Maybe<NearbyMinRequiredAmountCouponInfo>;
  getOrderSheetTotalCalculatedMileage: Scalars['Int'];
  /** 주문서 예상 적립 마일리지 조회 (PROMOTION 타입 포함) */
  getOrderSheetTotalCalculatedMileageList: Array<Maybe<CalculatedMileageResult>>;
  /** 게시글 등록시 사용될 유저가 주문한 상품 목록 */
  getOrderedProductListForPost: Array<EPickProduct>;
  /** 게시글 등록시 사용될 유저가 주문한 상품 목록 (신규 상품 카드) */
  getOrderedProductListForPostV2: Array<ProductCard>;
  /** 스토리 PresignedUrl */
  getPresignedStoryImageUrlList: GetPresignedStoryImageUrlListResponse;
  /** 게시글 이미지 등록을 위한 이미지팜 s3 presigned url list */
  getPresignedUserPickPostImageUrlList: GetPresignedUserPickPostImageUrlListResponse;
  /** 주어진 상품ID에 해당하는 상품 목록을 받는다. */
  getProductListByIdList: Array<SearchedProduct>;
  /** 게시글 등록시 사용될 검색 상품 목록 */
  getProductListByKeywordForPost: ProductListForEPickPostResponse;
  /** 게시글 등록시 사용될 유저가 찜폴더별 상품 목록 */
  getProductListByZzimFolderForPost: ProductListForEPickPostResponse;
  /** 상품연동형 배너 단건 */
  getProductsPresetBannerItem: GetProductsPresetBannerItemResult;
  /** 유저 프로필과 인기 게시글 */
  getProfileAndPostListByTopLike: GetProfileAndPostListByTopLikeResponse;
  /** 사용자 프로필 기본 이미지 리스트 */
  getProfileDefaultImageList: GetProfileDefaultImageListResponse;
  /** 프로필이미지 presigned url */
  getProfileImagePresignedUrl: GetProfileImagePresignedUrlResponse;
  /**
   * 직잭랭킹페스타(2021년 4월 프로모션)
   * 메타 데이터 조회
   */
  getPromotion202104Metadata: ResultPromotion202104Metadata;
  /**
   * 직잭랭킹페스타(2021년 4월 프로모션)
   * 1탭 Top100 랭킹 목록 조회
   */
  getPromotion202104Ranking: ResultPromotion202104RankingResult;
  /**
   * 직잭랭킹페스타(2021년 4월 프로모션)
   * 2탭 셀러 쿠폰 목록 조회
   */
  getPromotion202104SellerCouponList: Array<Promotion202104SellerCoupon>;
  /**
   * 직잭랭킹페스타(2021년 4월 프로모션)
   * 4탭 유저 공유 랭킹 목록 조회
   */
  getPromotion202104UserRanking?: Maybe<Promotion202104UserRanking>;
  /** 체험단 프로모션에 등록된 상품의 shop department 리스트를 조회(승인된 리스트만 리턴함) (M1) */
  getPromotionExperienceDepartmentList: Array<Scalars['String']>;
  /** 체험단 단일 상품을 조회합니다. (M1) */
  getPromotionExperienceGroupProduct?: Maybe<PromotionExperienceGroupProduct>;
  /** 유저가 참여한 상품 리스트를 반환합니다. (M1) */
  getPromotionExperienceGroupUserAppliedItemList: PromotionExperienceGroupUserAppliedItemList;
  /** 래플 상세 */
  getRaffle: Raffle;
  /** 래플 목록 조회 */
  getRaffleList: RaffleList;
  /** 래플 공유하기 주소 반환 */
  getRaffleShareUrl: Scalars['String'];
  /** 유저가 래플에 응모할 할 수 있는 조건들을 검증하여 검증된 결과 리스트를 반환합니다 */
  getRaffleUserConditionList: Array<RaffleUserCondition>;
  /** 최근 통합 노출 카테고리 버전 조회 */
  getRecentDisplayCategoryVersion: RecentActivatedCategoryVersion;
  /** 최근 통합 관리 카테고리 버전 조회 */
  getRecentManagedCategoryVersion: RecentActivatedCategoryVersion;
  /** 게시글 등록시 사용될 유저가 최근 본 상품 목록 */
  getRecentViewedProductListForPost: Array<EPickProduct>;
  /** 게시글 등록시 사용될 유저가 최근 본 상품 목록 (신규 상품 카드) */
  getRecentViewedProductListForPostV2: Array<ProductCard>;
  /** 추천태그 목록 */
  getRecommendTagList: GetRecommendTagListResponse;
  /** 추천태그 목록 (트렌드 키워드 포함) */
  getRecommendTagListWithTrendKeyword: GetRecommendTagListResponse;
  /** 체험단 추천상품 조회 (M1) */
  getRecommendedActivePromotionExperienceGroupProductList: RecommendedPromotionProductList;
  /**
   * 추천 유저 목록
   * @deprecated 미사용, 제거예정
   */
  getRecommnedProfileList: RecommendProfileListResponse;
  /** 리뷰 업로드용 PresignedUrl을 발급한다. */
  getReviewUploadPresignedUrl: GetReviewUploadPresignedUrlResponse;
  /** 리뷰 업로드용 PresignedUrl 목록을 발급한다. */
  getReviewUploadPresignedUrlList: GetReviewUploadPresignedUrlListResponse;
  /** 최근 7일간 일별 통계 목록을 반환한다. */
  getRewardUserDailyStatsListOfRecent7Days: RewardUserDailyStatsList;
  /** 주어진 월에 해당하는 리워드 일별 통계목록을 반환한다. */
  getRewardUserDailyStatsListPerMonth: RewardUserDailyStatsList;
  /**
   * 리워드 사용자 월별 통계 및 정산을 반환한다.
   * 조건에 맞는 값이 없는 경우 null 을 반환한다.
   * - 현재월도 조회 가능하다.
   */
  getRewardUserMonthlyStatsAndSettlement?: Maybe<GetRewardUserMonthlyStatsAndSettlement>;
  /**
   * 조건에 맞는 리워드 사용자 월별 통계 및 정산 목록을 반환한다.
   * 조건에 맞는 목록이 없는 경우, 빈 배열을 반환한다.
   * - 현재월은 제외한 목록이 반환된다.
   * - 목록은 기준월 내림차순으로 정렬된다.
   */
  getRewardUserMonthlyStatsAndSettlementList: GetRewardUserMonthlyStatsAndSettlementList;
  /** 쇼핑몰 ID 조건에 일치하는 쇼핑몰 목록을 받는다. */
  getShopListByShopIdList: Array<SearchedShop>;
  /** 스토어홈 모든 카테고리 목록을 조회한다 */
  getShopUxAllStoreHomeCategories: ShopUxStoreHomeCategoryList;
  /** 컬렉션을 상세 조회한다 */
  getShopUxCollectionDetail: ShopUxCollectionDetail;
  /** 스토어픽 단건 조회(Client, App) */
  getStorePickData: GetStorePickDataResult;
  /** 스토어 프리셋 스토어 목록 조회 */
  getStorePresetItemList?: Maybe<GetStorePresetResult>;
  /** 톡라운지 채택된 댓글조회 */
  getTalkLoungeAcceptedReply?: Maybe<TalkLoungeAcceptedReply>;
  /** 톡라운지 배너 목록 */
  getTalkLoungeBannerList: TalkLoungeBannerList;
  /** 톡라운지 작성 정보 */
  getTalkLoungeEditingInfo: TalkLoungeEditingInfo;
  /** 톡라운지 카테고리 조회 */
  getTalkLoungeEnabledCategoryList: TalkLoungeEnabledCategoryList;
  /** 참여 가능한 톡 list (보상 걸린 talk, 투표 가능한 talk, 골라줘 가능한 talk) */
  getTalkLoungeInteractiveTalkPreviewList: TalkLoungeInteractiveTalkPreviewList;
  /** 내가 '좋아요'를 누른 talk list 조회 */
  getTalkLoungeLikeTalkPreviewList: TalkLoungeLikeTalkPreviewList;
  /** 톡라운지 좋아한 톡 */
  getTalkLoungeLikedTalk: TalkLoungeLikedTalk;
  /** 톡라운지 마이페이지 댓글조회 */
  getTalkLoungeMyReplyList: TalkLoungeMyReplyList;
  /** 톡라운지 마이페이지 톡조회 */
  getTalkLoungeMyTalkList: TalkLoungeMyTalkList;
  /**
   * 포인트가 걸린 톡 리스트
   * @deprecated use getTalkLoungeRewardTalkPreviewList
   */
  getTalkLoungePointTalkPreviewList: TalkLoungePointTalkPreviewList;
  /** 톡라운지 투표 결과 */
  getTalkLoungePollAnswerResult?: Maybe<TalkLoungePollAnswerResult>;
  /** 톡라운지 이미지 등록을 위한 이미지팜 s3 presigned url list */
  getTalkLoungePresignedImageUrlList: TalkLoungePresignedImageUrlList;
  /** 톡라운지 미리보기 목록 조회 */
  getTalkLoungePreviewList: TalkLoungePreviewList;
  /** 톡라운지 talk id list로 톡 미리보기 목록 조회 */
  getTalkLoungePreviewListByTalkIdList: TalkLoungePreviewList;
  /**
   * 톡라운지 댓글 목록
   * --
   * parent_id 가 null 이면 댓글 조회, null 이 아니면 대댓글 조회
   */
  getTalkLoungeReplyList: TalkLoungeReplyList;
  /** 리워드 걸린 톡 리스트 */
  getTalkLoungeRewardTalkPreviewList: TalkLoungeRewardTalkPreviewList;
  /** 톡라운지 톡 검색 */
  getTalkLoungeSearchedTalkPreviewList: TalkLoungeSearchedTalkPreviewList;
  /** 내가 '구독'한 키워드 talk list 조회 */
  getTalkLoungeSubscribedKeywordTalkPreviewList: TalkLoungeSubscribedKeywordTalkPreviewList;
  /** talk review와 매칭되는 톡 id 조회 */
  getTalkLoungeTalkIdByTargetMap?: Maybe<Scalars['ID']>;
  /** 톡라운지 상단 고정 목록 조회 */
  getTalkLoungeTopPinnedPreviewList: TalkLoungePreviewList;
  getTotalCalculatedMileage: Scalars['Int'];
  /** 유저 멤버십 정보 조회 */
  getUserMembership?: Maybe<GetUserMembershipResult>;
  /** DDP 컴포넌트 id로 UserNotificationCategory 조회 */
  getUserNotificationCategoryByDdpComponentId?: Maybe<Scalars['String']>;
  /**
   * 사용자 리포트
   * user_account_id 가 없을 경우 session_data 정보를 사용합니다.
   */
  getUserReport?: Maybe<GetUserReportResult>;
  /** 세일탭 배너 목록 */
  getUxBenefitBannerList: GetUxBenefitBannerListResult;
  /** 혜택존 섹션 데이터 */
  getUxBenefitSectionData: UxBenefitSectionDataResult;
  /** 혜택존 섹션 설정 */
  getUxBenefitSectionProps: UxBenefitSectionPropsResult;
  getUxCachedProductCardItemList?: Maybe<Array<UxGoodsCardItem>>;
  /** 상품 id 기반으로 PLP에 표현될 상품 정보를 뿌려준다. catalog_product_id 가 있으면 goods_id 값은 무시한다. */
  getUxGoodsCardItem: UxGoodsCardItem;
  /** 상품 id 기반으로 PLP에 표현될 상품 정보 리스트를 뿌려준다. catalog_product_id_list 가 있으면 goods_id_list 값은 무시한다. */
  getUxGoodsCardItemList?: Maybe<Array<UxGoodsCardItem>>;
  /** search 서비스의 searched_goods_list 기반으로 PLP에 표현될 상품 정보를 뿌려준다. */
  getUxGoodsCardItemListBySearchedGoodsList?: Maybe<Array<UxGoodsCardItem>>;
  /** 버저닝 된 관리 카테고리 정보 key로 조회 */
  getVersionedManagedCategory?: Maybe<VersionedManagedCategory>;
  /** WEB 캐러셀 조회 */
  getWebDdpCarousel?: Maybe<WebDdpBasicCarouselBasic>;
  /** WEB CLP 배너 조회 */
  getWebSrpBanner?: Maybe<WebSrpBanner>;
  /** 프로모션 당첨자 목록 조회 */
  getWinnerList: Array<PromotionWinner>;
  /** 게시글 등록시 사용될 유저가 만든 찜폴더 목록 */
  getZzimFolderListForPost: Array<ZzimFolder>;
  /** 노출 예정 추천 컴포넌트를 반환한다 */
  get_exposure_recommendation_components: Array<DrExposureRecommendationComponent>;
  get_saved_shop_status: Scalars['Boolean'];
  /** 쿠폰 정책별 허들금액을 반환한다. (웹으로 byPass 하기 위한 api) */
  get_ux_coupon_policy_info_list: UxCouponPolicyInfoList;
  /** 페이지별 게이지 바 조회 */
  get_ux_promotion_gauge_bar?: Maybe<UxPromotionGaugeBarInfo>;
  /** 게이지 바 리프레시 */
  get_ux_promotion_gauge_bar_only?: Maybe<UxPromotionGaugeBarInfo>;
  /** 글로벌웹 배너 목록 */
  global_banner_list?: Maybe<UxImageBannerGroup>;
  /**
   * 유사상품 목록
   * @deprecated Field no longer supported
   */
  goods_preset: GoodsPreset;
  /** 주어진 조건에 맞는 상품의 가격 및 zpay 여부 */
  goods_price_and_zpay_list: Array<GoodsPriceAndZpay>;
  /** 사용자 Section 정보 */
  goods_tab_ui_section_list: Array<UiSection>;
  /**
   * FBK 사용자계정이 주문을 최소 한번 이상 가지고 있는지 여부를 반환한다.
   * 로그인하지 않은 사용자계정의 경우 false를 반환한다.
   */
  hasFbkUserAccountOrder: Scalars['Boolean'];
  /** 해당 UserAccountMetaData에 업데이트 유무 확인 */
  hasUpdateInUserAccountMetadata: Scalars['Boolean'];
  /** 읽지 않은 주문문의 답변이 있는지 여부를 반환한다. */
  has_new_order_item_inquiries: Scalars['Boolean'];
  /** 노출 가능 스토리 유무 */
  has_story: HasStoryResponse;
  /** 이미지 배너 그룹 정보 */
  image_banner_group: ImageBannerGroup;
  /** 글로벌 무료배송 기준 금액을 조회한다. */
  international_free_shipping_amount: Scalars['Float'];
  /**
   * 주어진 조건 모두에 일치하는 글로벌 무료배송 띠배너 이미지를 반환한다.
   * [error_code]
   * - hurdle_not_found: 해당하는 글로벌 무료배송비 허들이 없는 경우
   */
  international_free_shipping_fee_hurdle_image?: Maybe<InternationalFreeShippingFeeHurdleImageBanner>;
  /** 뷰티 월별 프리퀀시 이벤트 진행여부를 반환한다. */
  isBeautyFrequencyEventInProgress: Scalars['Boolean'];
  /**
   * 뷰티페스타 알림 수신 동의 참여 여부
   * deprecated: 뷰티페스타 알림 동의 증대를 위한 임시 query 이므로 24.04.15 이후에 삭제.
   * @deprecated 뷰티페스타 알림 동의 증대를 위한 임시 query 이므로 24.04.15 이후에 삭제.
   */
  isDailyMissionBeautyFestaNotiAgreed: Scalars['Boolean'];
  /** 로그인여부 */
  isEpickLogin: Scalars['Boolean'];
  /** FBK 앱 리뷰 작성 권유 가능한 사용자 계정 여부를 반환한다. */
  isFbkAppReviewRecommendableUserAccount: Scalars['Boolean'];
  /**
   * fbk 사용자계정의 쿠폰 발급 여부를 반환한다.
   * 로그인하지 않은 경우 false를 반환한다.
   */
  isFbkUserAccountCouponIssued: Scalars['Boolean'];
  isIssuableCouponByShop: IsIssuableCouponByShopResult;
  /** 사용자 계정의 로그인 여부를 리턴한다. */
  isLoggedIn: Scalars['Boolean'];
  /** 등록된 간편결제 패스워드가 있는지 확인한다 */
  isRegisteredSimplePayPassword: Scalars['Boolean'];
  /** 해당 쿠폰 정책이 발급이 가능한지 확인한다. */
  isUserAccountCouponPolicyIssuable: Scalars['Boolean'];
  /** 직잭렌즈 아이콘 노출 여부를 응답한다 */
  is_exposed_zigzag_lens_icon: Scalars['Boolean'];
  /**
   * 상품별 발행 가능한 포인트금액 반환
   * - PDP처럼 일반적인 정보만 노출한다면 필수 인자만 필요(트래픽이 많은곳에선 옵셔널 인자 보내면 안됨)
   * - 구매확정 페이지처럼 실제 지급될 정보가 필요하다면 옵셔널 인자까지 필요
   */
  issuable_point_list: IssuablePointList;
  /** 쿠폰 정책 목록을 조회한다. */
  issuable_shop_coupon_info_list: IssuableShopCouponInfoList;
  /** 발급중인 스토어 장바구니 쿠폰 정책 목록을 조회한다. */
  issuable_shop_coupon_info_v2_list: IssuableShopCouponInfoV2List;
  /** 주어진 조건에 일치하는 주소지 정보를 받는다. */
  japan_address_info?: Maybe<JapanAddress>;
  /** 주어진 조건에 일치하는 주소지 리스트를 받는다. */
  japan_address_list?: Maybe<Array<JapanAddress>>;
  /** 카카오 페이 전용 마이페이지 배너 목록 */
  kakao_pay_my_page_banner_list?: Maybe<UxImageBannerGroup>;
  /** 카카오 페이 전용 주문서 배너 목록 */
  kakao_pay_order_sheet_banner_list?: Maybe<UxImageBannerGroup>;
  /** kitto 카테고리 콘텐츠 리스트 & kitto 신규 콘텐츠 목록 조회 */
  kitto_category_content_page: KittoContentPage;
  /** kitto 콘텐츠 상세 조회 */
  kitto_content_detail: KittoContentDetail;
  /** kitto 임시 컨텐츠 상세 조회 */
  kitto_content_detail_temp: KittoContentDetail;
  /** kitto 콘텐츠 상세 조회 */
  kitto_content_detail_with_language: KittoContentDetail;
  /** kitto 인기 콘텐츠 Top 10 목록 조회 */
  kitto_content_ranking: Array<KittoContent>;
  /** kitto 에디터 콘텐츠 페이지 */
  kitto_editor_content_page: KittoContentPage;
  /** kitto 에디터 정보 */
  kitto_editor_detail: KittoEditorDetail;
  /** kitto 에디터 랭킹 */
  kitto_editor_ranking: KittoRankingEditorPage;
  /** kitto 1차 카테고리 목록 조회 */
  kitto_first_category_list: Array<KittoFirstCategory>;
  /**
   * kitto 해시태그에 대한 콘텐츠 리스트
   * order_type: CREATED_AT_DESC | SCORE_DESC
   */
  kitto_hash_tag_content_list: KittoContentListResult;
  /** kitto 해시태그 이름 조회 */
  kitto_hash_tag_list: Array<KittoHashTag>;
  /** kitto 띠배너 조회 */
  kitto_line_banner: Scalars['String'];
  /** kitto 인기 해시태그 목록 */
  kitto_popular_hash_tag_list: Array<KittoHashTag>;
  /** kitto Q&A 콘텐츠 목록 조회 */
  kitto_qna_content_list: Array<KittoContent>;
  /** kitto 추천 콘텐츠 목록 조회 */
  kitto_recommend_content_list: Array<KittoContent>;
  /** kitto 추천 에디터 Top 10 랜덤 목록 조회 */
  kitto_recommend_editor_list: Array<KittoRecommendEditor>;
  /** kitto 연관 콘텐츠 조회 */
  kitto_related_content_list: Array<KittoContent>;
  /** kitto 2차 카테고리 목록 조회 */
  kitto_second_category_list: Array<Scalars['String']>;
  /** 쿠폰으로 구매 가능한 상품 추천 컴포넌트를 로드한다 */
  load_by_available_with_coupon?: Maybe<DrAvailableWithCouponRecommendationComponent>;
  /** 카테고리 내 상품 추천 컴포넌트를 로드한다 */
  load_by_category: DrCategoryRecommendationComponent;
  /** 가격 인하 찜 상품의 유사 상품 추천 컴포넌트를 로드한다 */
  load_by_discounted_saved_product?: Maybe<DrDiscountedSavedProductRecommendationComponent>;
  /** 최근 구매 상품의 추천 컴포넌트를 로드한다 */
  load_by_recently_purchased_product?: Maybe<DrRecentlyPurchasedProductRecommendationComponent>;
  /** 최근 검색한 키워드 상품 추천 컴포넌트를 로드한다 */
  load_by_recently_searched_keyword?: Maybe<DrRecentlySearchedKeywordRecommendationComponent>;
  /** 최근 본 상품의 추천 컴포넌트를 로드한다 */
  load_by_recently_viewed_product?: Maybe<DrRecentlyViewedProductRecommendationComponent>;
  /** 찜한 상품의 추천 컴포넌트를 로드한다 */
  load_by_saved_product?: Maybe<DrSavedProductRecommendationComponent>;
  /** 장바구니 내 상품의 추천 컴포넌트를 로드한다 */
  load_by_shopping_cart?: Maybe<DrShoppingCartRecommendationComponent>;
  /** locale code 목록 반환 */
  locale_code_list: LocaleCodeList;
  /** 로그인 버튼 목록 조회 */
  loginButtonList: LoginButtonListResult;
  main_home_recommend_store_group_internal?: Maybe<Array<MainHomeRecommendStore>>;
  /**
   * 운영 태그 쇼핑몰 리스트를 반환한다
   * @deprecated Field no longer supported
   */
  managed_shops_list: ManagedShopsList;
  /**
   * 운영 태그 쇼핑몰 리스트를 반환한다
   * @deprecated Field no longer supported
   */
  managed_tag_shops: CarouselManagedShops;
  /** 마일리지 최대 사용금액을 조회한다 */
  maximum_usable_mileage: Scalars['Int'];
  /** 아이템 목록 */
  membership_benefit_by_level_list: MembershipBenefitByLevelList;
  /** 유저에 맞는 멤버십 정보 및 쿠폰팩 다운여부를 반환한다. */
  membership_coupon_pack: MembershipCouponPackResult;
  /** 메타데이터를 반환한다. */
  metadata: Metadata;
  /** 최소 주문가능 금액을 조회한다. */
  min_order_amount: MinOrderAmountInfo;
  /** 마이 페이지 배너 목록 */
  my_page_banner_list?: Maybe<UxImageBannerGroup>;
  /** 5탭 마이페이지 추가 메뉴 정보 */
  my_page_extra_menu: Array<MyPageExtraMenu>;
  /** 5탭 마이페이지 멤버십 */
  my_page_membership?: Maybe<MyPageMembership>;
  /** 5탭 마이페이지 마일리지 */
  my_page_mileage?: Maybe<MyPageMileage>;
  /** 유저의 마이픽쿠폰 신청 리스트를 반환합니다. */
  my_pick_coupon_user_request_list: MyPickCouponUserRequestList;
  /** 작성한 상품 리뷰 목록을 반환한다. */
  my_product_review_list: MyProductReviewList;
  /** 작성한 리뷰 유저 댓글 리스트 조회 */
  my_product_review_user_reply_list: ProductReviewUserReplyList;
  /** 내 스토리 도큐먼트 리스트 */
  my_story_list: StoryListResponse;
  /** 내 스토리 프로필 */
  my_story_profile?: Maybe<StoryProfile>;
  /** 내 스토리 view count */
  my_story_view_count: Scalars['Int'];
  new_brand_list: UxNewBrandList;
  /** 온보딩 혜택 관련 정보 제공 */
  onboarding_benefit_info: OnboardingBenefitInfo;
  /** 온보딩 연령정보 입력화면 관련 정보 제공(v2) */
  onboarding_birthyear_info: OnboardingBirthYearInfo;
  /** 온보딩 연령 정보 조회 */
  onboarding_info?: Maybe<OnboardingInfo>;
  /** 온보딩 스타일 결과 조회 */
  onboarding_result: OnboardingResult;
  /** 온보딩 샵 즐겨찾기 */
  onboarding_shop_list_ui?: Maybe<OnboardingShopList>;
  /** 온보딩 스타일 목록 조회 */
  onboarding_style_list: OnboardingStylingCardGroup;
  /** 온보딩한 유저 정보 */
  onboarding_user?: Maybe<OnboardingUser>;
  /** 당일/새벽 배송 주문 마감 띠 배너 */
  one_day_delivery_banner?: Maybe<OneDayDeliveryBanner>;
  /**
   * 해당 조건에 맞는 주문을 반환한다
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없습니다.
   */
  order?: Maybe<Order>;
  /**
   * 조건에 해당하는 해당 사용자의 쇼핑몰 상품 주문을 반환한다
   * [error_code]
   * - order_not_found: 해당 주문을 찾을 수 없습니다.
   */
  order_item?: Maybe<OrderItem>;
  /** 해당 사용자의 주문 상품 문의 내역 목록을 가져온다 */
  order_item_inquiry_list: OrderItemInquiryList;
  /** 조건에 해당하는 해당 사용자의 쇼핑몰 상품주문 목록을 반환한다. */
  order_item_list: OrderItemList;
  /** 상품주문별 반품 배송 현황 정보를 확인한다. */
  order_item_return_shipping_tracking_detail: OrderItemReturnShippingTrackingDetail;
  /** 상품주문별 배송 현황 정보를 확인한다. */
  order_item_shipping_tracking_detail: OrderItemShippingTrackingDetail;
  /** 해당 사용자의 주문 목록을 반환한다. */
  order_list: OrderList;
  /** 주문서 목록을 반환한다 */
  order_sheet?: Maybe<OrderSheet>;
  /** 주문서 목록을 반환한다 (V2) */
  order_sheet_v2?: Maybe<OrderSheetV2>;
  /** 직진 배송 정보 다건 호출용 */
  order_zigzin_shipping_base_info_list: OrderZigzinShippingBaseInfoList;
  /** 해당 사용자의 N일 지난 오래된 장바구니 아이템 목록을 반환한다. */
  outdated_cart_item_list: OutdatedCartItemList;
  page_info?: Maybe<UxPageInfo>;
  pages: UxPage;
  /** 결제 레코드를 반환한다. */
  payment: Payment;
  /**
   * 주어진 조건 모두에 일치하는 결제 뱃지 정보를 반환한다.
   * 조건에 맞는 결제 뱃지 정보가 없으면 빈 목록을 반환한다.
   */
  payment_badge_list: PaymentBadgeList;
  /** 주어진 조건에 일치하는 환율 정보를 반환한다. */
  payment_card_meta_data?: Maybe<PaymentCardMetaDataResponse>;
  /**
   * 주어진 조건 모두에 일치하는 결제 이벤트 정보를 반환한다.
   * 조건에 맞는 결제 이벤트 정보가 없으면 빈 목록을 반환한다.
   */
  payment_event_list: PaymentEventList;
  /**
   * 주어진 조건 모두에 일치하는 결제 이벤트 정보를 반환한다.
   * 조건에 맞는 결제 이벤트 정보가 없으면 빈 목록을 반환한다.
   */
  payment_event_list_for_display: PaymentEventForDisplayListGraph;
  /** 주어진 조건에 일치하는 환율 정보를 반환한다. */
  payment_exchange_rate_information: PaymentExchangeRateInformation;
  /**
   * 주어진 조건 모두에 일치하는 결제 금융사 정보를 반환한다.
   * 조건에 맞는 결제 금융사 정보가 없으면 빈 목록을 반환한다.
   */
  payment_financial_company_list: PaymentFinancialCompanyList;
  /**
   * 주어진 조건 모두에 일치하는 결제 수단 정보를 반환한다.
   * 조건에 맞는 결제 수단 정보가 없으면 빈 목록을 반환한다.
   */
  payment_method_information_list: PaymentMethodInformationListResponse;
  /**
   * 주어진 조건 모두에 일치하는 결제 알림 정보를 반환한다.
   * 조건에 맞는 결제 알림 정보가 없으면 빈 목록을 반환한다.
   */
  payment_notification_list: PaymentNotificationList;
  /**
   * 주어진 조건 모두에 일치하는 무이자 할부 정보를 반환한다.
   * 조건에 맞는 무이자 할부 정보가 없으면 빈 목록을 반환한다.
   */
  payment_vendor_interest_free_list: PaymentVendorInterestFreeList;
  /** PDP 광고 배너 리스트 호출 */
  pdp_banner_ad_creative_list: BannerAdCreativeList;
  /** PDP 기본 정보 (source = 네이버 등 제휴사를 통해 유입된 캠페인 코드) */
  pdp_base_info?: Maybe<PdpCatalogProductDetailPage>;
  /** 브릿지 페이지 광고 */
  pdp_bridge_page_info?: Maybe<UxPageInfo>;
  /** PDP 브라우저 타입 */
  pdp_browsing_type?: Maybe<BrowsingTypeInfo>;
  /** 다른 셀러 간의 동일한 상품 */
  pdp_cross_seller_product?: Maybe<Array<PdpCrossSellerProduct>>;
  /** PDP 배송 무료 배너 */
  pdp_free_shipping_banner?: Maybe<Array<PdpFreeShippingBannerInfo>>;
  /** 카탈로그 PDP 하단 무한 스크롤 추천 상품 */
  pdp_infinite_recommend_group?: Maybe<PdpRecommendCardGroup>;
  /** 카탈로그 PDP 하단 무한 스크롤 추천 상품 (상품 id 리스트로 호출) */
  pdp_infinite_recommend_group_by_product_id_list?: Maybe<PdpRecommendCardGroup>;
  /** size info가 필요한 상품인지 체크한다. */
  pdp_is_required_size_info: Scalars['Boolean'];
  /**
   * 입점형 PDP 상품 옵션 정보
   * catalog_product_detail_page와 동일한 타입을 반환하지만 옵션과 관련된 필드만 채워서 응답한다.
   */
  pdp_option_info?: Maybe<PdpCatalogProductDetailPage>;
  /** PDP 옵션 매칭 validation (backoffice 호출 전용) */
  pdp_option_matching_validation: OptionMatchingValidation;
  /** 카탈로그 PDP 상단, 하단 영역 추천 상품 */
  pdp_recommend_group: Array<PdpComponent>;
  /** 추천 상품 ID LIST */
  pdp_recommend_id_list: Array<PdpRecommendAttribute>;
  /** 카탈로그 PDP 추천 상품 */
  pdp_recommend_item_list: Array<PdpComponent>;
  /** 관계 타입에 따른 연관 상품 리스트를 반환한다 */
  pdp_related_item_list?: Maybe<UxRelatedItemInfo>;
  /** PDP 셀러존 컬렉션 목록 */
  pdp_shop_collection?: Maybe<PdpComponent>;
  /** 숏폼 콘텐츠 */
  pdp_short_form_contents?: Maybe<PdpShortFormContentList>;
  /** 상품 사이즈 정보 */
  pdp_size_info?: Maybe<PdpCatalogProductSizeList>;
  /** PDP 각종 URL 반환 */
  pdp_url_list: Array<PdpUrl>;
  /** 유저의 기본 정보 및 혜택 정보 */
  pdp_user_base_info?: Maybe<PdpUserBaseInfo>;
  /** 직진 배송 정보 단건 호출용 */
  pdp_zigzin_shipping_base_info?: Maybe<PdpZigzinShippingBaseInfo>;
  /** 직진 배송 정보 다건 호출용 (최대 요청 건수 50건입니다. 50건 이상 요청시 앞 50건에 대한 요청만 처리됩니다.) */
  pdp_zigzin_shipping_base_info_list: PdpZigzinShippingBaseInfoList;
  /** 개인통관고유부호를 조회한다. */
  personal_clearance_code?: Maybe<PersonalClearanceCode>;
  /** 개인화된 마이픽쿠폰 정보를 조회합니다. (만약 진행중인 마이픽쿠폰이 없다면 null을 반환합니다) */
  personalized_my_pick_coupon_info?: Maybe<PersonalizedMyPickCouponInfo>;
  /**
   * N일 이내 소멸 마일리지 잔액 조회
   * 해당 유저가 마일리지를 한번도 적립받지 않은 경우 null을 반환한다.
   */
  point_expire_expected_mileage?: Maybe<PointExpireExpectedMileage>;
  /** N일 이내 소멸 되는 마일리지 적립 내역을 반환한다. */
  point_expire_expected_mileage_transaction_list: PointExpireExpectedMileageTransactionList;
  /**
   * 만료일시가 지난 마일리지를 만료처리한다.
   * N일 이내 소멸 마일리지 적립 내역 조회
   */
  point_expire_expected_mileage_transaction_list_with_expire_mileage: PointExpireExpectedMileageTransactionList;
  /**
   * 만료일시가 지난 마일리지를 만료처리한다.
   * N일 이내 소멸 마일리지 잔액 조회
   */
  point_expire_expected_mileage_with_expire_mileage?: Maybe<PointExpireExpectedMileage>;
  /**
   * 주어진 조건 모두에 일치하는 마일리지 정책 정보를 반환한다.
   * 조건에 맞는 마일리지 정책 정보가 없으면 null 을 반환한다.
   */
  point_mileage_policy_list: PointMileagePolicyList;
  /**
   * 주어진 조건 모두에 일치하는 마일리지 사용 정책 정보를 반환한다.
   * 조건에 맞는 마일리지 사용 정책 정보가 없으면 null 을 반환한다.
   */
  point_mileage_policy_list_for_use: PointMileagePolicyForUseList;
  /**
   * 마일리지 거래내역 목록을 반환한다.
   * date_end와 date_start가 null인 경우, 전체 기간에 대하여 반환한다.
   */
  point_mileage_transaction_list: PointMileageTransactionList;
  /**
   * 마일리지 거래내역 목록을 반환한다.
   * 만료일시가 지난 마일리지를 만료처리한다.
   */
  point_mileage_transaction_list_with_expire_mileage: PointMileageTransactionList;
  /**
   * 마일리지 지갑 정보를 반환한다.
   * 해당 유저가 마일리지를 한번도 적립받지 않은 경우 null을 반환한다.
   */
  point_mileage_wallet?: Maybe<PointMileageWallet>;
  /**
   * 주어진 조건 모두에 일치하는 마일리지 지갑 정보를 반환한다.
   * 마일리지 지갑 정보가 있으면, 만료일시가 지난 마일리지를 만료처리한다.
   * 조건에 맞는 마일리지 지갑 정보가 없으면 null 을 반환한다.
   */
  point_mileage_wallet_with_expire_mileage?: Maybe<PointMileageWallet>;
  /**
   * 주어진 조건 모두에 일치하는 환불 마일리지 금액을 조회한다.
   * 조건에 맞는 환불 내역이 없으면 error 을 반환한다.
   */
  point_refund_mileage?: Maybe<PointRefundMileage>;
  /** 상품을 반환한다. */
  product: Product;
  /** 상품 목록을 반환한다. */
  product_list: ProductList;
  /** 상품 리뷰를 조회한다. */
  product_review?: Maybe<ProductReview>;
  /** 상품리뷰 첨부파일 목록을 반환한다 */
  product_review_attachment_list: ProductReviewAttachmentList;
  /** 리뷰 이벤트에 선정된 상품 리뷰 목록을 반환한다. */
  product_review_best_review_list: ProductReviewBestReviewList;
  /** 리뷰 작성시 안내 정보 리스트를 반환한다. */
  product_review_create_info_list: ProductReviewCreateInfoList;
  /** 리뷰 작성 완료 결과 정보 조회 */
  product_review_create_review_result_info?: Maybe<CreateProductReviewResultInfo>;
  /** 노출 제한된 상품리뷰 목록을 반환한다. */
  product_review_display_limited_list: ProductReviewDisplayLimitedList;
  /** 리뷰 이벤트 정보를 조회한다. */
  product_review_event?: Maybe<ProductReviewEvent>;
  /** 리뷰 이벤트 정보를 조회한다. */
  product_review_event_list: ProductReviewEventList;
  /** 상품리뷰 운영정책 가이드 정보 조회 */
  product_review_guide_info: ProductReviewGuideInfo;
  /** 리뷰 작성시 발급 포인트 정보를 반환한다 */
  product_review_issue_point_info: ProductReviewIssuePointInfo;
  /** 리뷰 칭찬 버튼 리스트 조회 */
  product_review_like_button_list: Array<ProductReviewLikeButton>;
  /** 상품 리뷰 목록을 반환한다. */
  product_review_list: ProductReviewList;
  /** PDP용 리뷰 요약 정보 */
  product_review_pdp_summary?: Maybe<ProductReviewPdpSummary>;
  /** 리뷰 프로필 조회 */
  product_review_profile: ProductReviewProfile;
  /** 리뷰 프로필 기본 이미지 리스트 조회 */
  product_review_profile_image_list: Array<ProductReviewProfileImage>;
  /** 작성 가능한 리뷰 화면 배너 리스트 조회 */
  product_review_reviewable_page_banner_list: Array<ProductReviewBanner>;
  /** 리뷰어 랭커 리스트 조회 */
  product_review_reviewer_ranker_list: ProductReviewReviewerRankerList;
  /** 상품 아이디별 리뷰 이벤트 배너 정보를 반환한다. */
  product_review_seller_event_banner_info: ProductReviewSellerEventBannerInfo;
  /** 쇼핑몰별 리뷰 요약 정보를 반환한다 */
  product_review_shop_summary: ProductReviewShopSummary;
  /** 상품 리뷰 요약 정보를 조회한다. */
  product_review_summary: ProductReviewSummary;
  /** 상품 별 리뷰 토픽 리스트를 반환한다. */
  product_review_topic_list: Array<ProductReviewTopic>;
  /** 리뷰 유저 댓글 리스트 조회 */
  product_review_user_reply_list: ProductReviewUserReplyList;
  /** 유저 리뷰 리스트 조회 */
  product_review_user_review_list: ProductReviewUserReviewList;
  /** 마이페이지 웹뷰 바텀싯 노출 조건 조회 */
  product_review_web_view_bottom_sheet?: Maybe<ProductReviewWebViewBottomSheet>;
  /** 캐시에 저장된 상품리뷰 수 를 반환한다. */
  product_reviews_cached_count: ProductReviewsCachedCount;
  /**
   * 상품 사전 설정 설정된 값으로 상품 조회
   * @deprecated cms_products_preset_product_card_item_list 으로 대체 사용 권장
   */
  products_preset_data: ProductsPresetDataResult;
  /** 푸시 카테고리 동의 여부 */
  push_category_agreement: PushCategoryAgreement;
  /** 최근 본 상품 보기 캐러셀 */
  recent_browsed_product_carousel?: Maybe<RecentBrowsedProductCarousel>;
  /** 쿠폰 추천 캐로셀을 반환한다 */
  recommend_by_coupon?: Maybe<DrCouponRecommendationCarousel>;
  /** 카테고리 추천 상품카드 목록을 반환한다 */
  recommend_by_display_category?: Maybe<DrCategoryRecommendationItemList>;
  /** 키워드 추천 캐로셀을 반환한다 */
  recommend_by_keyword?: Maybe<DrKeywordRecommendationCarousel>;
  /** 상품 추천 캐로셀을 반환한다 */
  recommend_by_product?: Maybe<DrProductRecommendationCarousel>;
  /** 상품별 추천상품 목록 조회 */
  recommended_each_product_list: OrderRecommendEachProductList;
  /** 추천지면별 추천상품 목록을 조회한다(v2) */
  recommended_product_list_v2: OrderRecommendProductList;
  /** 추천 검색어 목록을 Shop > SearchedKeywordHistory > TagShop > Category > Event > UserQuery 순서로 반환한다. */
  recommended_search_keyword_list: RecommendedSearchKeywordList;
  /** 연관 상품 리스트를 반환한다 */
  related_item_list?: Maybe<UxRelatedItemInfo>;
  /** 연관 상품 목록 (방금 본 상품의 연관 상품) */
  related_product_list?: Maybe<UxGoodsGroup>;
  /** 연관 상품 리뷰 요약 정보를 조회한다. */
  related_product_review_summary: ProductReviewSummary;
  related_search_word_list: Array<Scalars['String']>;
  /** 최근 N일까지 만료 예정인 포인트내역을 조회한다. */
  reserved_expire_point_list: UserAccountPointTransactionList;
  /** 리뷰작성 가능한 구매확정된 사용자의 상품주문 목록을 반환한다. */
  reviewable_order_item_list: ReviewableOrderItemList;
  /** 쇼핑몰 우대 리워드 대상 조회 */
  reward_promotion_shop_list: RewardPromotionShopList;
  /** 리워드 유저 */
  reward_user_account: RewardUserAccount;
  /** 리워드 유저 상태 */
  reward_user_account_status: RewardUserAccountStatus;
  /**
   * 리워드 정산 계좌정보를 받는다.
   * 리워드 대상 계정이 아니거나 등록된 정산 계좌가 없다면 null을 반환한다.
   */
  reward_user_bank_account?: Maybe<RewardUserBankAccount>;
  /** 리워드 유저 정산 서류 */
  reward_user_settlement_document: RewardUserSettlementDocument;
  /** 세일탭 정보 */
  salePageInfo?: Maybe<SalePageInfo>;
  /** 세일탭 알림 설정 */
  sale_notification?: Maybe<SaleNotification>;
  /** 세일탭 균일가 추천 캐로젤 */
  sale_recommend_carousel?: Maybe<SaleRecommendCarousel>;
  /** 찜한 상품 쿠폰 받기 카루셀 */
  savedProductStoreCouponCarousel?: Maybe<CouponCollectionCouponCarousel>;
  saved_product_id_list?: Maybe<SavedProductList>;
  saved_shop_id_list?: Maybe<SavedShopList>;
  saved_styling_list: SavedStylingList;
  /** 내 스토어 상품 검색 */
  searchMyProduct: DdpMyProductCardList;
  search_auto_complete: SearchAutoComplete;
  /** 검색창 노출 메세지를 조회한다 */
  search_bar_placeholder: SearchBarPlaceholder;
  /** 검색 페이지 인기 키워드 정보 */
  search_popular_keyword: SearchKeywordSuggestion;
  search_result: SearchResult;
  /** SRP 반응형 캐러셀 */
  search_result_recommend_item_list: SearchResultRecommendItemList;
  /** 주어진 조건 모두에 일치하는 상품 목록을 받는다. */
  searched_goods_list: SearchedGoodsList;
  /** 상품을 검색한다. */
  searched_product_list: SearchedProductList;
  searched_shop_list?: Maybe<SearchedShopList>;
  selected_brand_list?: Maybe<SelectedBrandGroup>;
  /** 셀러 등급 정보 / 웹, 앱 구분이 필요한 경우 session으로 처리 */
  seller_grade_info?: Maybe<ShopUxSellerGradeInfo>;
  /** 세션 관련 메타데이터를 반환한다. */
  session_metadata: SessionMetadata;
  /** 사용가능한 택배사 목록을 조회한다. */
  shipping_company_list: Array<ShippingCompanyInfo>;
  /**
   * 해당 사용자의 배송비 그룹 정보 목록을 반환한다.
   * [error_code]
   * - order_not_found: 해당하는 주문이 없는 경우
   * - shipping_group_not_found: 해당하는 배송비 그룹 정보가 없는 경우
   */
  shipping_group_list: ShippingGroupList;
  /**
   * 배송 지역 정보를 조회한다.
   * 조건에 맞는 배송 지역 정보가 없으면 null을 반환한다.
   */
  shipping_region?: Maybe<ShippingRegion>;
  /** 쇼핑몰 정보를 반환한다. */
  shop: Shop;
  /** 유사상품 목록 */
  shop_detail: ShopDetail;
  /** 유사상품 목록 */
  shop_detail_category_goods_list: ShopDetailCategoryGoodsList;
  /** @deprecated Field no longer supported */
  shop_filter_info?: Maybe<UxShopFilterInfo>;
  shop_list: ShopList;
  shop_ranking_list?: Maybe<UxShopRankingList>;
  /** 베스트 상품 모아보기 상세 페이지에 보여줄 카테고리와 상품 목록을 조회한다 */
  shop_ux_best_product_collection_list: ShopUxBestProductCollectionList;
  /** 스토어홈 상품 카테고리와 커스텀카테고리 목록을 조회한다 */
  shop_ux_category_component_list: ShopUxCategoryComponentList;
  /** 셀러 카테고리를 목록을 조회한다 */
  shop_ux_category_list: Array<ShopUxCategory>;
  /** 셀러홈 컴포넌트 목록을 조회한다 */
  shop_ux_component_list?: Maybe<ShopUxComponentList>;
  /** 신상 몰아보기 상세 */
  shop_ux_new_product_collection_list: ShopUxNewProductCollectionList;
  /** 즐겨찾기 쇼핑몰 신상몰아보기 요약 */
  shop_ux_new_product_collection_summary: ShopUxNewProductCollectionSummary;
  /** 셀러 샵정보를 조회한다 */
  shop_ux_shop: ShopUxShop;
  /** 스토어홈 카테고리를 목록을 조회한다 */
  shop_ux_store_home_category_list: Array<ShopUxStoreHomeCategory>;
  /** 스타일탭 콘텐츠 상세조회 */
  shop_ux_style_tab_contents?: Maybe<ShopUxStyleTabContent>;
  /** 현재 전시중인 스타일탭의 콘텐츠 목록 */
  shop_ux_style_tab_displayed_contents_list: ShopUxStyleTabContentList;
  /** Shoplive JWT */
  shoplive_login_token: ShopliveLoginToken;
  /** Shoplive 공유하기 메타 정보 */
  shoplive_sharing_meta_info: ShopliveSharingMetaInfo;
  /** 숏 슬라이드 */
  short_slide: ShortSlide;
  short_slide_live_list: Array<ShortSlide>;
  /** 유사상품 목록 */
  similar_goods_list: SimilarGoodsList;
  /** 비슷한 상품 검색 */
  similar_product_list: Array<UxSimilarProduct>;
  /** 비슷한 상품 검색 */
  similar_search_result: SimilarSearchResult;
  /** 계좌 간편 목록을 받는다. */
  simple_bank_account_list: SimpleBankAccountList;
  /** 계좌 간편 결제 ARS 인증 정보를 반환한다. */
  simple_bank_cert: SimpleBankCert;
  /** 계좌 간편 은행 목록을 받는다. */
  simple_bank_list: SimpleBankList;
  /** 유사스토어 */
  sims_shop_list?: Maybe<UxComponent>;
  /** 사이트 목록을 반환한다 */
  site_list: SiteList;
  /** 사이즈 카테고리를 조회한다. */
  size_category_list: Array<SizeCategory>;
  /** 국가별 SNS 로그인 버튼 노출 순서 반환 */
  social_login_button_list: SocialLoginButtonType;
  /** 스토리에 연동 가능한 쿠폰 리스트 */
  story_coupon_list: BookmarkStoryCouponList;
  /** 스토리 도큐먼트 리스트 */
  story_list: StoryListResponse;
  /** 스토리 셀러 정보 */
  story_seller_info: StorySellerInfo;
  /** @deprecated Field no longer supported */
  story_service?: Maybe<UxStoryShopList>;
  story_service_info?: Maybe<UxStoryInfoList>;
  /** 온보딩 A안 */
  style_group_list_categorized_ui?: Maybe<StyleCategorizedGroupList>;
  /** 온보딩 B안 */
  style_group_list_grid_ui?: Maybe<OnboardingProductList>;
  style_group_list_ui?: Maybe<UxStyleGroupList>;
  style_group_shop_list_ui?: Maybe<UxStyleGroupShopList>;
  /** 스타일링 상세 */
  styling_detail?: Maybe<StylingDetail>;
  styling_list_by_product_id: StylingList;
  /** 스타일링 리스트 by tag list */
  styling_list_by_tag_list: StylingList;
  /** 톡라운지 관심 키워드 리스트 */
  talk_keyword_list: Array<TalkKeyword>;
  /** 톡라운지 톡 조회 */
  talk_lounge_talk?: Maybe<TalkLoungeTalk>;
  umd_saved_product_folder_list?: Maybe<UmdSavedProductFolderList>;
  /** 상품 찜목록 리스트 */
  umd_saved_product_list?: Maybe<UmdSavedProductList>;
  umd_saved_product_list_by_shop?: Maybe<UmdSavedProductList>;
  umd_saved_shop_list?: Maybe<UmdSavedShopList>;
  umd_shop_list_on_saved_product?: Maybe<UmdShopListOnSavedProduct>;
  umd_shop_tag_count_list?: Maybe<UmdShopTagCountList>;
  umd_shop_tag_list?: Maybe<UmdShopTagList>;
  umd_shop_tag_update_info?: Maybe<UmdShopTagUpdateInfo>;
  umd_tagged_shop_list?: Maybe<UmdTaggedShopList>;
  /**
   * 사용 가능한 보유한 쿠폰 목록 반환(input 인자 주의)
   *
   *   [error_code]
   *   - invalid_arguments: 잘못된 인자로 요청
   */
  usable_coupon_list: UsableCouponList;
  /**
   * 주어진 조건 모두에 일치하는 사용자를 받는다.
   * 조건에 맞는 사용자가 없으면 null을 반환한다.
   * 조건이 주어지지 않으면 null을 반환한다.
   */
  user?: Maybe<User>;
  user_account?: Maybe<UserAccount>;
  /** 성인인증 여부 조회 */
  user_account_adult_certification: UserAccountAdultCertificationResponse;
  /** 사용 가능한 포인트 */
  user_account_available_point: Scalars['Int'];
  /** 사용자계정 신체정보에 입력가능한 값의 후보군을 보여준다 */
  user_account_body_candidates: UserAccountBodyCandidates;
  /** 유저의 쿠폰 List를 조회한다. */
  user_account_coupon_list: UserAccountCouponList;
  /** 쿠폰 정책을 조회한다. */
  user_account_coupon_policy?: Maybe<UserAccountCouponPolicy>;
  /** 쿠폰 정책 목록을 조회한다. */
  user_account_coupon_policy_list: UserAccountCouponPolicyList;
  user_account_marketing_agreement: UserAccountMarketingAgreement;
  /** 해당 사용자의 포인트 현황을 가져온다 */
  user_account_point_info: UserAccountPointInfo;
  /** 해당 사용자의 포인트 내역을 가져온다 */
  user_account_point_list: UserAccountPointList;
  user_account_profile: UserAccountProfile;
  user_account_profile_list: UserAccountProfileList;
  /**
   * user_account_id와 조회 조건에 해당하는 정보를 받는다.
   * user_account_id와 조회 조건에 해당하는 정보가 없으면 0의 값을 반환한다.
   * 용례)
   *   사용자 환불 포인트 상세 정보
   */
  user_account_refund_point_info: UserAccountRefundPointInfo;
  /** 사용자가 구독하는 스토어의 상세 알림 목록을 가져온다. */
  user_account_shop_notification_list: UserAccountShopNotificationList;
  /**
   * 사용자가 설정한 스토어 구독 및 Push 수신 여부를 조회한다.
   * @description
   * - user_account_id, site_id 중 존재하지 않는 데이터는 context session 데이터에서 조회한다.
   * - shop_id, user_account_id, site_id 가 존재 하지 않은 경우 vaildation 에러를 반환한다.
   * - 구독 설정을 하지 않은 경우 date_created, date_updated, date_subscribed_changed,
   * date_push_agreement_changed 은 모두 null로 반환하며, 구독 및 알림설정 여부는 기본 false 로 응답 한다.
   */
  user_account_shop_subscribed: UserAccountShopSubscribedType;
  /** 주문배송내역(취소/반품/교환)에 표시되는 요청 "그룹" 목록 */
  user_claim_group_list: UserClaimGroupList;
  /** 유저의 클레임 요청 아이템 목록 */
  user_claim_list: UserClaimList;
  /** 노출 제한된 상품리뷰 목록을 반환한다. */
  user_custom_filter_info?: Maybe<UserCustomFilterInfo>;
  /** 유저의 타입별(UserDataType) 데이터를 구한다. */
  user_data: UserData;
  /** 친구초대 현황 */
  user_invite_status: UserInviteStatus;
  /** 사용자 로케일 정보 반환 (기본값을 입력하면 값이 없을 시 기본값으로 초기화 후 반환한다) */
  user_locale?: Maybe<UserLocale>;
  /** 배송 국가에 맞게 알림함 카테고리를 반환한다. */
  user_notification_category_list?: Maybe<UserNotificationCategoryList>;
  /** 해당하는 사용자의 알림 목록을 전달합니다. */
  user_notification_list: UserNotificationList;
  /** 해당하는 사용자의 알림 목록을 전달합니다. */
  user_notification_metadata: UserNotificationMetadata;
  /**
   * 사용자 기기의 사이트별 알림 수신 동의 정보를 가져온다
   * [error_code]
   * - user_account_not_found: user_account_id 에 해당하는 유저 계정을 찾을 수 없습니다.
   */
  user_notification_push_agreement_list: UserNotificationPushAgreementList;
  /** 유저 주문목록 상단 혜택 가능금액 및 목록 조회 */
  user_order_benefit_list: UserOrderBenefitList;
  /** 주문배송내역 탭 상태에 해당하는 상품 목록을 반환한다. */
  user_order_list: UserOrderList;
  /** feature flag 목록을 반환한다. */
  user_pay_feature_flag_list: UserPayFeatureFlagList;
  /** 결제 지원 금융사 정보 목록을 반환한다. */
  user_pay_payment_financial_company_list: UserPayPaymentFinancialCompanyList;
  /** 우편번호별 공지사항을 반환한다. */
  user_post_code_notice?: Maybe<UserPostCodeNotice>;
  /** 사용자의 알림 목록을 전달 */
  user_postbox_list: UserPostboxListResponse;
  /** 이미지 팜 S3 버킷의 업로드용 Pre-Signed-Url을 발행하여 반환한다. */
  user_profile_pre_signed_url: ImageFarmUploadResponse;
  /** 환불 계좌 가져오기 */
  user_refund_account?: Maybe<UserRefundAccount>;
  /** 웹 주문목록에서 해당 조건에 맞는 클레임 상품 주문 목록(취소/반품/교환) */
  user_searched_claim_list: UserSearchedClaimList;
  /** 검색 API 기반의 클레임이 존재하는 상품주문 검색 쿼리 */
  user_searched_claim_order_item_list: UserSearchedOrderItemListGraph;
  /** 검색 API 기반의 상품주문 검색 쿼리 */
  user_searched_order_item_list: UserSearchedOrderItemListGraph;
  /** 웹 주문배송내역 탭 상태에 해당하는 상품 목록을 반환한다. */
  user_searched_order_list: UserSearchedOrderList;
  /** 사용자별 배송 주소록를 반환한다. */
  user_shipping_address_book?: Maybe<UserShippingAddressBook>;
  /** 사용자별 배송 주소록 목록을 반환한다. */
  user_shipping_address_book_list: UserShippingAddressBookList;
  /** 사용자별 배송 주소록 목록을 반환한다. (마스터 DB 에서 조회) */
  user_shipping_address_book_list_on_master: UserShippingAddressBookList;
  /** 사용자별 최신 메모 목록을 반환한다. */
  user_shipping_memo_list: UserShippingMemoList;
  /** 최근 본 상품 검색 */
  user_viewed_products_info: UserViewedProductsInfo;
  /** ux 북마크 전체 리스트 */
  ux_bookmark_shop_list?: Maybe<UxBookmarkShopList>;
  ux_brand_ranking: UxBrandRankingInfo;
  ux_brand_ranking_category: UxCategory;
  /** 외부 상품 리뷰 탭 노출 여부 조회 */
  ux_external_product_review_tab: UxExternalProductReviewTab;
  /** 글로벌 네비게이션 페이지 */
  ux_global_navigation_page: UxGnpPage;
  ux_multiline_ranking_carousel?: Maybe<UxMultilineRankingCarousel>;
  ux_new_shop_list: UxNewShopList;
  /** 최종 인기 검색어 목록(노출어 추가, 제외어 제거)을 반환한다. */
  ux_popular_keyword_list: PopularKeywordList;
  /** 리뷰 필터 정보 데이터를 반환한다. */
  ux_review_filter_info?: Maybe<UxReviewFilterInfo>;
  /** 예측 리뷰 통계 조회 */
  ux_review_prediction_summary?: Maybe<UxReviewPredictSummary>;
  /** 토픽별 유사상품 리뷰 모아보기 */
  ux_review_similar_product_review_list: UxReviewSimilarProductReviewList;
  /** 상품 별 리뷰 토픽 리스트를 반환한다. */
  ux_review_topic_list: Array<UxReviewTopic>;
  /** 찜한 상품의 카테고리 전달 */
  ux_saved_product_category?: Maybe<UxSavedProductCategory>;
  /** 폴더의 카테고리 전달 croquis-session 기반 */
  ux_saved_product_category_list?: Maybe<UxSavedProductCategoryInfoList>;
  /** 찜 / 가격 하락된 상품 리스트 */
  ux_saved_product_dropped_price_list?: Maybe<UxSavedProductDroppedPriceList>;
  /** 찜한 상품의 필터 정보 */
  ux_saved_product_filter_list?: Maybe<UxSavedProductFilterList>;
  /** 찜 / 가격이 하락된 상품이 존재하는지 여부 / ABT 컨트롤 군인 경우 null */
  ux_saved_product_has_new_dropped_price?: Maybe<UxSavedProductHasNewDroppedPrice>;
  ux_saved_product_list_by_shop_v2?: Maybe<UxSavedProductList>;
  ux_saved_product_list_v2?: Maybe<UxSavedProductList>;
  /** 찜 상품 추천 ABT/ A: 기존, B: 테스트군 */
  ux_saved_product_recommend_abt: Scalars['String'];
  /** 찜 상품 추천 리스트 */
  ux_saved_product_recommend_list?: Maybe<UxSavedProductRecommendList>;
  /** 주어진 조건에 해당하는 쇼핑몰 목록을 반환한다 */
  ux_searched_shop_list: UxSearchedShopList;
  ux_shop_ranking_department_list: Array<UxShopRankingDepartment>;
  /** 숏폼 그룹 조회 */
  ux_short_form_group?: Maybe<DdpShortFormGroup>;
  /** 숏폼 상세 페이지 노출 예정 숏폼 ID 리스트 */
  ux_short_form_init_id_list: Array<Scalars['ID']>;
  /** 숏폼 아이템 목록 조회 */
  ux_short_form_item_list: Array<DdpShortFormItem>;
  ux_user_body_input_info: UxUserBodyInputInfo;
  ux_zonly_entry_point_info?: Maybe<UxZonlyEntryPointInfo>;
  valid_ab_cart_button?: Maybe<Scalars['String']>;
  /** 찜의 폴더 카테고리 AB 테스트 결과 */
  valid_ab_saved_product_category?: Maybe<Scalars['String']>;
  valid_ab_saved_styling: Scalars['String'];
  /** 주문서 데이터를 검증한다 */
  validate_create_order_sheet: Scalars['Boolean'];
  /** 주문에 적용된 프로모션 validation */
  validate_order_promotion: Array<ValidateOrderPromotionForClaimResult>;
  /**
   * 본인인증된 데이터와 일치하는지 검증한다. (return true)
   * [error_code]
   * required_user_account_authentication: 본인인증이 필요한 경우
   */
  verifyAuthedUserAccountData: Scalars['Boolean'];
  /** 국내웹 배너 목록 */
  web_banner_list?: Maybe<UxImageBannerGroup>;
  web_browser_page?: Maybe<UxWebBrowserPage>;
  web_browser_page_item_floating_button: Array<UxWebBrowserPageItemFloatingButton>;
  /** web CLP 상단 컴포넌트 리스트 */
  web_clp_top_component_list: ClpTopComponentList;
  /** web 뷰티 배너 리스트 */
  web_ddp_rolling_image_banner?: Maybe<DdpRollingImageBanner>;
  /** 국내웹 SRP 조회용 page_info 추후 확장 예정 */
  web_page_info?: Maybe<UxPageInfo>;
  /** WEB 카탈로그 PDP 상단, 하단 영역 추천 상품 */
  web_pdp_recommend_group: Array<PdpComponent>;
  /** 웹 PDP 추천 상품 */
  web_pdp_recommend_item_list: Array<PdpComponent>;
  /** web search result */
  web_search_result: SearchResult;
  web_umd_saved_product_folder_list?: Maybe<UmdSavedProductFolderList>;
  /** web 글로벌 네비게이션 페이지 */
  web_ux_global_navigation_page: UxGnpPage;
  /** 웹 상품 찜목록 리스트 */
  web_ux_saved_product_list?: Maybe<UmdSavedProductList>;
  /** 사용자별 웹UI 목록 */
  webui_list: WebUiList;
  /** 직진배송 배송비 정보 조회 */
  wms_delivery_price_info: WmsDeliveryPriceInfo;
  /** 상품 재고조회 */
  wms_product_item_quantity_list: Array<WmsProductItemQuantity>;
  /** 유저 물류 notification 설정 조회 */
  wms_user_notification_list: Array<WmsUserNotification>;
  zendesk_notice_list?: Maybe<ZendeskNoticeList>;
  /** ID에 해당하는 FAQ 카테고리를 반환한다. */
  zigzag_faq_category?: Maybe<ZigzagFaqCategory>;
  /**
   * 조건에 해당하는 FAQ 카테고리를 order 순으로 반환한다.
   *       조건이 주어지지 않으면 모든 FAQ 카테고리를 반환한다.
   */
  zigzag_faq_category_list: ZigzagFaqCategoryList;
  /** 주어진 콘텐츠 ID에 해당하는 FAQ 콘텐츠를 반환한다. */
  zigzag_faq_content?: Maybe<ZigzagFaqContent>;
  /** 주어진 카테고리 ID에 해당하는 FAQ 콘텐츠를 order 순으로 반환한다. */
  zigzag_faq_content_list: ZigzagFaqContentList;
  /** 주어진 유사어 ID에 해당하는 FAQ 단어와 유사어를 반환한다. */
  zigzag_faq_synonym?: Maybe<ZigzagFaqSynonym>;
  /** FAQ 유사어를 id 순으로 반환한다. */
  zigzag_faq_synonym_list: ZigzagFaqSynonymList;
  /** 직잭렌즈 이미지 검색하여 유사한 상품을 추천한다 */
  zigzag_lens_search: ZigzagLensSearch;
  /** ID에 해당하는 공지사항을 반환한다. */
  zigzag_notice?: Maybe<ZigzagNotice>;
  /** 공지 목록을 최신순으로 반환한다. */
  zigzag_notice_list: ZigzagNoticeList;
  /** 직잭위크 이벤트 정보 조회 */
  zigzag_week_meta: ZigzagWeekMetaResult;
  /**
   * 직잭위크 유저 데이터 조회
   *  - 유저가 직잭위크에서 셀러별 다운로드 받은 쿠폰과 그 정보를 확인 할 수 있습니다.
   */
  zigzag_week_user_data?: Maybe<ZigzagWeekUserDataResult>;
  /** 직진배송 베스트 아이템 정보 */
  zigzin_best_item_info: ZigzinBestItemInfo;
  /** 직진배송 즐겨찾기한 샵 정보 */
  zigzin_bookmarked_shop_list: Array<ZigzinBookmarkedShop>;
  /** 직진배송 캐러셀 상품 정보 */
  zigzin_carousel_item_info?: Maybe<ZigzinCarouselItemInfo>;
  /** 직진배송 카테고리별 상품 모아보기 페이지 */
  zigzin_categorized_items: ZigzinCategoryItems;
  /** 직진배송 메인 */
  zigzin_main_info: ZigzinMainInfo;
  /** 직진배송 공지사항 정보 */
  zigzin_notice_info?: Maybe<ZigzinNoticeInfo>;
  /** 직진 배송관 퀵메뉴 */
  zigzin_quick_menu?: Maybe<ZigzinQuickMenu>;
  /** 직진배송 즐겨찾기 한 쇼핑몰 상품 페이지 */
  zigzin_saved_shop_items: ZigzinSavedShopItems;
  /** Z-ONLY 상품 도착예정정보 */
  zonly_estimate_shipping_info?: Maybe<ZOnlyEstimateShippingInfo>;
  /** zonly + 당일 배송 상품의 도착예정정보를 반환한다. (장바구니 화면에서 사용하기 위해 PDP에서 별도로 빼서 작업) */
  zonly_estimate_shipping_info_with_account_id?: Maybe<Array<ZOnlyEstimateShippingInfo>>;
  /** zonly + 당일 배송 상품의 도착예정정보를 반환한다. (장바구니 화면에서 사용하기 위해 PDP에서 별도로 빼서 작업) */
  zonly_estimate_shipping_info_with_postcode?: Maybe<Array<ZOnlyEstimateShippingInfo>>;
  /** zonly + 당일 배송 상품의 도착예정정보를 반환한다. (장바구니 화면에서 사용하기 위해 PDP에서 별도로 빼서 작업) */
  zonly_one_day_estimate_shipping_info?: Maybe<Array<ZOnlyEstimateShippingInfo>>;
};

export type QueryActivated_User_Account_Point_RewardArgs = {
  payment_method?: InputMaybe<UserAccountPointRewardPaymentMethod>;
  type?: InputMaybe<UserAccountPointRewardType>;
};

export type QueryActivated_User_Account_Point_Reward_ListArgs = {
  type?: InputMaybe<UserAccountPointRewardType>;
};

export type QueryAd_Bridge_DetailArgs = {
  ad_id: Scalars['ID'];
  creative_id: Scalars['ID'];
};

export type QueryAd_Display_BannerArgs = {
  type?: InputMaybe<AdDisplayBannerType>;
};

export type QueryAd_Display_Home_VideoArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryAd_Display_ShopArgs = {
  type?: InputMaybe<AdDisplayShopType>;
};

export type QueryAd_Exhibition_DetailArgs = {
  exhibition_id: Scalars['ID'];
};

export type QueryAd_Multi_Board_Pdp_BannerArgs = {
  category_id: Scalars['ID'];
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryAd_Purchase_Recommend_Item_ListArgs = {
  product_id_list: Array<Scalars['ID']>;
};

export type QueryAd_Review_Recommend_GroupArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryApplicable_Coupon_Policy_ListArgs = {
  coupon_target_type_list?: InputMaybe<Array<CouponTargetType>>;
  include_zonly?: InputMaybe<Scalars['Boolean']>;
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryAvailable_Return_Shipping_Fee_Charge_MethodArgs = {
  order_item_number_list: Array<Scalars['String']>;
  order_number: Scalars['String'];
  refund_responsibility: OrderRefundResponsibility;
  shipping_group_id: Scalars['ID'];
};

export type QueryBanner_ListArgs = {
  search_query?: InputMaybe<Scalars['String']>;
  type: BannerType;
};

export type QueryBeauty_Event_Frequency_MetaArgs = {
  beauty_event_type: Scalars['String'];
};

export type QueryBeauty_Order_Item_Event_Frequency_SummaryArgs = {
  beauty_event_type: Scalars['String'];
};

export type QueryBeauty_Order_Item_Frequency_SummaryArgs = {
  date_ym: Scalars['Int'];
};

export type QueryBeauty_Sample_Categorized_Item_ListArgs = {
  display_category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryBefore_Transfer_Order_Expired_Point_MileageArgs = {
  order_number: Scalars['String'];
};

export type QueryBrand_Ranking_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryBrandfashion_Onboarding_Attribute_ListArgs = {
  user_account_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryBrandfashion_Onboarding_UserArgs = {
  user_account_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryBrandfashion_Ranking_InfoArgs = {
  after?: InputMaybe<Scalars['Int']>;
  category_id?: InputMaybe<Scalars['String']>;
  limitCount?: InputMaybe<Scalars['Int']>;
};

export type QueryCached_Product_ListArgs = {
  input: CachedProductListInput;
};

export type QueryCart_Coupon_Policy_Info_ListArgs = {
  input: CartCouponPolicyInfoListInput;
};

export type QueryCart_Shop_ListArgs = {
  last_added_day_after?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryCart_Shop_List_V2Args = {
  last_added_day_after?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryCatalog_AnswerArgs = {
  id: Scalars['ID'];
};

export type QueryCatalog_Product_Detail_Page_TypeArgs = {
  catalog_product_id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryCatalog_Product_Detail_Page_Type_OptionArgs = {
  catalog_product_id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type QueryCatalog_Product_ListArgs = {
  input: CatalogProductListInput;
};

export type QueryCatalog_QuestionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCatalog_Question_ListArgs = {
  input: CatalogQuestionListInput;
};

export type QueryCheckAvailableEmailArgs = {
  email: Scalars['String'];
};

export type QueryCheckBankAccountArgs = {
  bank_account_holder: Scalars['String'];
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
};

export type QueryCheckIssueExhibitionCouponsArgs = {
  policy_id_list: Array<Scalars['ID']>;
  uid_list: Array<Scalars['String']>;
};

export type QueryCheckKakaoStudentPresentProofSubmissionArgs = {
  presentation_definition_id: Scalars['String'];
  request_id: Scalars['String'];
};

export type QueryCheckWmsOrderItemListCancellableArgs = {
  order_number: Scalars['String'];
};

export type QueryCheck_Issue_Coupon_By_RedeemArgs = {
  input: CheckIssueRedeemCouponInput;
};

export type QueryCheck_Kakaobank_AuthenticationArgs = {
  is_adult_certification?: InputMaybe<Scalars['Boolean']>;
  trx_key: Scalars['String'];
  user_account_id?: InputMaybe<Scalars['ID']>;
};

export type QueryClp_Top_Component_ListArgs = {
  ddp_component_id?: InputMaybe<Scalars['String']>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  page_id: Scalars['String'];
};

export type QueryCms_Banner_ListArgs = {
  tag_name?: InputMaybe<Scalars['String']>;
};

export type QueryCms_Campaign_Catalog_Ranking_Id_ListArgs = {
  input: CmsCampaignCatalogRankingDataApiInfoInput;
};

export type QueryCms_Campaign_Store_Ranking_Id_ListArgs = {
  input: CmsCampaignStoreRankingDataApiInfoInput;
};

export type QueryCms_Content_EditorArgs = {
  uuid: Scalars['String'];
};

export type QueryCms_Product_Card_Item_ListArgs = {
  input: CmsProductCardItemListInput;
};

export type QueryCms_Products_Preset_Product_Card_Item_ListArgs = {
  input: CmsProductsPresetProductCardItemListSearchInput;
};

export type QueryCms_Promotion_Rank_Card_ListArgs = {
  input: CmsPromotionRankInput;
};

export type QueryCms_Store_Preset_Shop_ListArgs = {
  uuid: Scalars['String'];
};

export type QueryCms_Stores_Rank_Card_ListArgs = {
  input: CmsStoresRankInput;
};

export type QueryContent_Editor_GroupArgs = {
  group_id: Scalars['ID'];
  target_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryContent_Editor_Limited_Order_Coupon_InfoArgs = {
  coupon_policy_id: Scalars['ID'];
};

export type QueryConvert_To_Deep_LinkArgs = {
  type?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type QueryCouponCollectionCouponListArgs = {
  input: UxCouponCollectionPageInput;
};

export type QueryCouponCollectionPageInfoV2Args = {
  input: UxCouponCollectionPageInput;
};

export type QueryCouponCollectionStyleWorldCupArgs = {
  type?: InputMaybe<StyleWorldCupTargetType>;
};

export type QueryCouponCollectionStyleWorldCupCompetitionArgs = {
  product_id: Scalars['ID'];
};

export type QueryCouponCollectionStyleWorldCupWinnerArgs = {
  product_id: Scalars['ID'];
};

export type QueryCoupon_Available_StatusArgs = {
  additional_option?: InputMaybe<Scalars['String']>;
  include_my_pick?: InputMaybe<Scalars['Boolean']>;
  product_id: Scalars['ID'];
  shop_id: Scalars['ID'];
};

export type QueryCoupon_Available_Status_ListArgs = {
  input: CouponAvailableStatusListInput;
};

export type QueryCoupon_BoxArgs = {
  status: Array<UserAccountCouponStatus>;
};

export type QueryCoupon_Exclude_Status_ListArgs = {
  target_list: Array<CouponExcludeTargetInput>;
};

export type QueryCoupon_Policy_ListArgs = {
  coupon_code_type_list?: InputMaybe<Array<CouponCodeType>>;
  coupon_registration_type_list?: InputMaybe<Array<CouponRegistrationType>>;
  coupon_target_type_list?: InputMaybe<Array<CouponTargetType>>;
  include_zonly?: InputMaybe<Scalars['Boolean']>;
  issue_status?: InputMaybe<UserAccountCouponPolicyIssueStatus>;
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
  user_account_id?: InputMaybe<Scalars['ID']>;
};

export type QueryCoupon_Policy_Target_ListArgs = {
  coupon_policy_id_list: Array<Scalars['ID']>;
};

export type QueryCoupon_Target_ListArgs = {
  coupon_policy_id: Scalars['ID'];
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryCreate_Claim_Image_Upload_LinkArgs = {
  expire_seconds?: InputMaybe<Scalars['Int']>;
  file_type: Scalars['String'];
};

export type QueryCreate_Order_Item_Inquiry_Image_Upload_LinkArgs = {
  expire_seconds?: InputMaybe<Scalars['Int']>;
  file_type: Scalars['String'];
};

export type QueryCurrency_MetaArgs = {
  display_currency_type?: InputMaybe<DisplayCurrencyType>;
};

export type QueryCurrency_Meta_Of_OrderArgs = {
  order_number: Scalars['String'];
};

export type QueryCurrent_My_Pick_Coupon_Set_InfoArgs = {
  entry?: InputMaybe<MyPickCouponEntryType>;
};

export type QueryCurrent_User_RewardArgs = {
  share_type?: InputMaybe<ShareType>;
};

export type QueryDaily_Mission_AttendanceArgs = {
  date_attendance_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_attendance_lte?: InputMaybe<Scalars['CrTimestamp']>;
};

export type QueryDaily_Mission_BehaviorArgs = {
  behavior_type: Scalars['String'];
};

export type QueryDaily_Mission_Card_ListArgs = {
  is_particiatable?: InputMaybe<Scalars['Boolean']>;
  reward_type?: InputMaybe<Scalars['String']>;
};

export type QueryDaily_Mission_Daily_Weather_ListArgs = {
  daily_mission_region_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDaily_Mission_Game_Ticket_SummaryArgs = {
  game_type: Scalars['String'];
};

export type QueryDaily_Mission_Invitation_ListArgs = {
  mobile_tel_list: Array<Scalars['String']>;
};

export type QueryDaily_Mission_LikeArgs = {
  type: Scalars['String'];
};

export type QueryDaily_Mission_MenuArgs = {
  order_type?: InputMaybe<Scalars['String']>;
};

export type QueryDaily_Mission_Region_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  search_keyword?: InputMaybe<Scalars['String']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryDaily_Mission_Roulette_RewardArgs = {
  roulette_id: Scalars['ID'];
};

export type QueryDaily_Mission_Today_Weather_Styling_PollArgs = {
  daily_mission_region_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDaily_Mission_Tomorrow_Weather_Styling_PollArgs = {
  daily_mission_region_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDaily_Mission_Weather_Summary_ListArgs = {
  daily_mission_region_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Banner_Catalog_Carousel_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryDdp_Basic_Catalog_Carousel_AutoArgs = {
  id: Scalars['ID'];
  keyword_list: Array<Scalars['String']>;
  selected_id?: InputMaybe<Scalars['String']>;
};

export type QueryDdp_Basic_Catalog_Carousel_OperationArgs = {
  id: Scalars['ID'];
  selected_id?: InputMaybe<Scalars['String']>;
};

export type QueryDdp_Beauty_Product_Recommend_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Beta_Tester_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Bookmark_New_Items_EntryArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Bookmark_Product_Card_GroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  input?: InputMaybe<DdpBookmarkProductCardGroupInput>;
};

export type QueryDdp_Bookmark_Shop_CarouselArgs = {
  filter?: InputMaybe<DdpBookmarkShopFilterInput>;
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Entry_Menu_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Filter_Recommend_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Personal_Product_Recommend_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Product_Recommend_GroupArgs = {
  attribute_id?: InputMaybe<Scalars['String']>;
  brand_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Ranking_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Brand_Recommend_GroupArgs = {
  filter_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Brandfashion_TemplateArgs = {
  id: Scalars['ID'];
  tab_type?: InputMaybe<Scalars['String']>;
};

export type QueryDdp_Calendar_Catalog_ListArgs = {
  group_id: Scalars['ID'];
};

export type QueryDdp_Calender_Catalog_PageArgs = {
  group_id: Scalars['ID'];
};

export type QueryDdp_Catalog_Carousel_Brand_DealArgs = {
  group_id: Scalars['ID'];
  selected_id?: InputMaybe<Scalars['String']>;
};

export type QueryDdp_Catalog_Carousel_Brand_Sale_ListArgs = {
  ddp_id: Scalars['ID'];
  group_id: Scalars['ID'];
  input: DdpBrandSaleInput;
};

export type QueryDdp_Catalog_Carousel_Brand_Time_DealArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Catalog_Carousel_Daily_DealArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Catalog_Carousel_Image_Vertical_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Catalog_Carousel_Time_DealArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Catalog_Store_Collection_CarouselArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Categorized_Product_ListArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Category_Bottom_SheetArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Category_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Chip_Button_Product_ListArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Department_Entry_MenuArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Discovery_MenuArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Epick_Post_Top_Like_ListArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Filter_ListArgs = {
  filter_input: DdpFilterInput;
};

export type QueryDdp_Filtered_Item_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter_input: DdpFilterInput;
};

export type QueryDdp_Follow_Bookmark_GroupArgs = {
  input?: InputMaybe<DdpFollowBookmarkGroupInput>;
};

export type QueryDdp_Grid_CategoryArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Grid_Category_BrandArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Home_Catalog_Carousel_Time_DealArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Home_Quick_MenuArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Hourly_Product_Best_GroupArgs = {
  attribute_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Icon_Catalog_CarouselArgs = {
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryDdp_Icon_CategoryArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Image_Catalog_CarouselArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Image_Catalog_Carousel_BrandArgs = {
  id: Scalars['ID'];
  need_plp_v1?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDdp_Image_Catalog_Carousel_VerticalArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Image_Catalog_Carousel_Vertical_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Item_Tag_ProductArgs = {
  item_tag_id: Scalars['ID'];
};

export type QueryDdp_Latest_Magazine_ListArgs = {
  exclude_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Line_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Magazine_CarouselArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Magazine_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  ddp_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Main_Home_Recommend_Store_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Mds_Pick_Product_Card_GroupArgs = {
  category_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Mosaic_Catalog_CarouselArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_New_Catalog_CarouselArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryDdp_OnboardingArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Page_InfoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_PagesArgs = {
  type: UxDdpPagesType;
};

export type QueryDdp_Product_Card_GroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DdpProductCardGroupFilterInput>;
  id: Scalars['ID'];
};

export type QueryDdp_Product_Recommend_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Promotion_Floating_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Promotion_TcArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Quick_MenuArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Ranking_Catalog_CarouselArgs = {
  age_filter_id?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sorting_id?: InputMaybe<Scalars['String']>;
};

export type QueryDdp_Ranking_Recommend_Shop_CarouselArgs = {
  filter_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Recommend_Item_Tag_ShopArgs = {
  filter_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryDdp_Rolling_Band_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Rolling_Image_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Rolling_Items_EntryArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_SearchArgs = {
  group_id: Scalars['ID'];
};

export type QueryDdp_Selected_Product_Card_GroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter: DdpProductCardGroupFilterInput;
};

export type QueryDdp_Selected_Product_Recommend_GroupArgs = {
  category_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Selected_Product_Recommend_List_GroupArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  sub_category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_Selected_Ranking_InfoArgs = {
  input: DdpProductCardGroupFilterInput;
};

export type QueryDdp_Selected_Rolling_Image_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Short_Form_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Short_Form_Init_Id_ListArgs = {
  id: Scalars['ID'];
  include_short_form_id_list: Array<Scalars['ID']>;
};

export type QueryDdp_Short_Form_Item_ListArgs = {
  id_list: Array<Scalars['ID']>;
};

export type QueryDdp_Single_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Snack_Trend_Body_Bottom_SheetArgs = {
  product_id_list: Array<Scalars['ID']>;
};

export type QueryDdp_Snack_Trend_Card_GroupArgs = {
  ddp_id: Scalars['ID'];
};

export type QueryDdp_Special_Exhibition_ListArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Styling_Card_GroupArgs = {
  ddp_id: Scalars['ID'];
  tag_id?: InputMaybe<Scalars['ID']>;
};

export type QueryDdp_TemplateArgs = {
  id: Scalars['ID'];
};

export type QueryDdp_Zigzin_Product_Recommend_GroupArgs = {
  attribute_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryDdp_Zigzin_Recommend_Product_CarouselArgs = {
  id: Scalars['ID'];
  selected_id?: InputMaybe<Scalars['String']>;
};

export type QueryDetermine_Nudge_Badge_For_BannersArgs = {
  banner_shop_list?: InputMaybe<Array<BannerShopInput>>;
};

export type QueryEpick_Follower_ListArgs = {
  last_follow_id?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  profile_id: Scalars['ID'];
};

export type QueryEpick_Following_ListArgs = {
  last_follow_id?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  profile_id: Scalars['ID'];
};

export type QueryEpick_PostArgs = {
  post_id: Scalars['ID'];
};

export type QueryEpick_Post_ListArgs = {
  post_id_list: Array<Scalars['ID']>;
};

export type QueryEpick_ProfileArgs = {
  profile_id?: InputMaybe<Scalars['ID']>;
};

export type QueryEpick_ReplyArgs = {
  reply_id: Scalars['ID'];
};

export type QueryEpick_Searched_ListArgs = {
  keyword: Scalars['String'];
  profile_limit?: InputMaybe<Scalars['Int']>;
  tag_limit?: InputMaybe<Scalars['Int']>;
};

export type QueryEpick_Temp_Related_Post_ListArgs = {
  is_opened_by_pdp?: InputMaybe<Scalars['Boolean']>;
  post_id: Scalars['ID'];
};

export type QueryEstimated_Shipping_Group_Refund_AmountsArgs = {
  order_item_number_list: Array<Scalars['String']>;
  order_number: Scalars['String'];
  refund_responsibility: OrderRefundResponsibility;
  shipping_fee_additional_charge_method: ShippingFeeAdditionalChargeMethodInput;
  shipping_group_id: Scalars['ID'];
  user_account_id?: InputMaybe<Scalars['ID']>;
};

export type QueryEvent_Participation_ResultArgs = {
  event_type: EventType;
};

export type QueryEvent_StatusArgs = {
  event_type: EventType;
};

export type QueryEvent_User_StatusArgs = {
  event_type: EventType;
};

export type QueryExchange_Product_ListArgs = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
  product_no_list?: InputMaybe<Array<Scalars['ID']>>;
  shop_main_domain?: InputMaybe<Scalars['String']>;
};

export type QueryExternal_Product_Review_ListArgs = {
  pagination?: InputMaybe<ProductReviewPaginationInput>;
  product_id: Scalars['ID'];
};

export type QueryFbk_Activated_Display_CategoryArgs = {
  product_list_type?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Activated_Feature_Menu_GroupArgs = {
  page_name: Scalars['String'];
};

export type QueryFbk_Activated_Shop_Meta_Category_ListArgs = {
  shop_list_type?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Active_Banner_ListArgs = {
  banner_page_name: Scalars['String'];
};

export type QueryFbk_Active_Base_Content_ListArgs = {
  base_content_type?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};

export type QueryFbk_Active_Coupon_PolicyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryFbk_Active_Coupon_Policy_ListArgs = {
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Active_New_Product_List_By_Md_PickArgs = {
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Active_Recommend_Shop_ListArgs = {
  group_name?: InputMaybe<Scalars['String']>;
  page_name: Scalars['String'];
};

export type QueryFbk_Best_Product_Ranking_ListArgs = {
  best_product_ranking_type?: InputMaybe<Scalars['String']>;
  display_category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryFbk_Best_Shop_Ranking_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  best_shop_ranking_type?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  shop_meta_category_id: Scalars['ID'];
};

export type QueryFbk_Carousel_ListArgs = {
  carousel_page_name?: InputMaybe<Scalars['String']>;
  carousel_page_name_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryFbk_Discounted_New_Product_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  display_category_id?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Event_Detail_PageArgs = {
  uuid: Scalars['ID'];
};

export type QueryFbk_Home_Ad_Or_Product_Id_ListArgs = {
  category_key?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Home_Ad_Product_ListArgs = {
  ad_id_list: Array<Scalars['ID']>;
};

export type QueryFbk_Image_Search_Product_ListArgs = {
  content: Scalars['String'];
  content_type: Scalars['String'];
};

export type QueryFbk_Like_Product_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Like_Shop_Latest_Product_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryFbk_Like_Shop_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_New_Product_List_By_CategoryArgs = {
  display_category_id_list?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Product_Best_Review_ListArgs = {
  product_id: Scalars['ID'];
};

export type QueryFbk_Product_Delivery_Estimate_SummaryArgs = {
  managed_category_id?: InputMaybe<Scalars['ID']>;
  product_id: Scalars['ID'];
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryFbk_Product_Delivery_SummaryArgs = {
  product_id: Scalars['ID'];
};

export type QueryFbk_Product_List_By_Id_ListArgs = {
  product_id_list: Array<Scalars['ID']>;
};

export type QueryFbk_Product_Max_BenefitArgs = {
  product_id: Scalars['ID'];
};

export type QueryFbk_Recent_Visit_Product_ListArgs = {
  date_from?: InputMaybe<Scalars['CrTimestamp']>;
  date_to?: InputMaybe<Scalars['CrTimestamp']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Recommend_Shop_ListArgs = {
  shop_page_name: Scalars['String'];
};

export type QueryFbk_Related_Product_ListArgs = {
  inventory_name?: InputMaybe<Scalars['String']>;
  product_id: Scalars['ID'];
};

export type QueryFbk_Related_Search_Word_ListArgs = {
  search_word?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Search_Popular_KeywordArgs = {
  page_id?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Search_Product_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  input: FbkSearchProductInput;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_ShopArgs = {
  id?: InputMaybe<Scalars['ID']>;
  product_id?: InputMaybe<Scalars['ID']>;
};

export type QueryFbk_Shop_Best_Product_ListArgs = {
  current_catalog_product_id: Scalars['String'];
  shop_id: Scalars['String'];
};

export type QueryFbk_Shop_ListArgs = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryFbk_Shoplive_Campaign_ListArgs = {
  date_start_scheduled_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_start_scheduled_lt?: InputMaybe<Scalars['CrTimestamp']>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryFbk_Shoplive_Campaign_Meta_InfoArgs = {
  campaign_key: Scalars['String'];
};

export type QueryFbk_Shoplive_Jwt_TokenArgs = {
  campaign_key: Scalars['String'];
};

export type QueryFbk_Similar_Product_ListArgs = {
  catalog_product_id: Scalars['String'];
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Srp_Search_Product_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  age_filter_id?: InputMaybe<Scalars['Int']>;
  base_shop_id?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']>>;
  goods_filter_option?: InputMaybe<GoodsFilterOptionInput>;
  session_id?: InputMaybe<Scalars['String']>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryFbk_Srp_Shop_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  query_string: Scalars['String'];
};

export type QueryFbk_Storepick_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryFbk_Tmp_Pdp_Category_Related_Product_ListArgs = {
  product_id: Scalars['ID'];
};

export type QueryFbk_Zigzin_Saved_Shop_ItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  shop_id: Scalars['ID'];
  sorting_id?: InputMaybe<Scalars['ID']>;
};

export type QueryFindUserAccountEmailByMobileTelArgs = {
  mobile_tel: Scalars['String'];
};

export type QueryFind_Account_With_AuthenticationArgs = {
  authenticated_key: Scalars['String'];
};

export type QueryFind_Account_With_Mobile_AuthenticationArgs = {
  mobile_number: Scalars['String'];
  token: Scalars['String'];
};

export type QueryGetActiveExperienceGroupProductListArgs = {
  input: ActiveExperienceGroupProductListInput;
};

export type QueryGetActivePromotionExperienceGroupProductListArgs = {
  input: ActivePromotionExperienceGroupProductListInput;
};

export type QueryGetAffiliatePdpModalMetaArgs = {
  shop_id: Scalars['ID'];
};

export type QueryGetAffiliateProductIdListArgs = {
  after?: InputMaybe<Scalars['String']>;
  is_boost_only: Scalars['Boolean'];
  shop_department: Scalars['String'];
};

export type QueryGetBannerListArgs = {
  os?: InputMaybe<Scalars['String']>;
  type: BannerItemType;
  version?: InputMaybe<Scalars['String']>;
};

export type QueryGetBenefitBannerArgs = {
  banner_group_uuid: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryGetBenefitBannerListArgs = {
  benefit_banner_group_uuid: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  order_type?: InputMaybe<BenefitBannerRequestOrderType>;
  os: BenefitBannerOs;
  skip?: InputMaybe<Scalars['Int']>;
  version: Scalars['String'];
};

export type QueryGetBenefitSectionDataArgs = {
  benefit_section_uuid: Scalars['String'];
  request_data?: InputMaybe<Scalars['CrJson']>;
};

export type QueryGetCouponIssueStatusArgs = {
  coupon_pack_id?: InputMaybe<Scalars['ID']>;
  policy_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetCrmRecommendItemListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  next_token?: InputMaybe<Scalars['String']>;
  rdi: Scalars['String'];
};

export type QueryGetCrmRecommendMetadataArgs = {
  rdi: Scalars['String'];
};

export type QueryGetDailyMissionQuizCartProductCountArgs = {
  quiz_id: Scalars['ID'];
};

export type QueryGetDailyMissionRecommendedArgs = {
  recommend_type: Scalars['String'];
};

export type QueryGetDailyMissionShareLinkArgs = {
  mission_type: Scalars['String'];
};

export type QueryGetDisplayStorePickListByOrderTypeArgs = {
  limit: Scalars['Int'];
  order_type: StorePickOrderType;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryGetEPickPostListByPostIdArgs = {
  post_id: Scalars['ID'];
};

export type QueryGetEPickPostListByStyleCategoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  exclude_priority?: InputMaybe<Scalars['Boolean']>;
  style_category?: InputMaybe<Scalars['String']>;
};

export type QueryGetEPickPostListByStyleTagArgs = {
  after?: InputMaybe<Scalars['String']>;
  style_tag_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEPickPostListByTagArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  order_by?: InputMaybe<PostOrder>;
  tag_id: Scalars['ID'];
};

export type QueryGetEPickPostPreviewArgs = {
  post_id: Scalars['ID'];
};

export type QueryGetEPickReReplyListByReplyIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  parent_reply_id: Scalars['ID'];
  post_id: Scalars['ID'];
};

export type QueryGetEPickReplyListByPostIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  post_id: Scalars['ID'];
};

export type QueryGetEPickStyleTagArgs = {
  style_category?: InputMaybe<Scalars['String']>;
};

export type QueryGetEpickPostListByUserArgs = {
  last_post_id?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  profile_id: Scalars['ID'];
};

export type QueryGetEstimatedGlobalShippingExpenseArgs = {
  input: EstimatedGlobalShippingCostInput;
};

export type QueryGetEventContentReplyListArgs = {
  after?: InputMaybe<Scalars['String']>;
  event_content_reply_metadata_uuid: Scalars['String'];
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetEventContentReplyMetadataArgs = {
  uuid: Scalars['String'];
};

export type QueryGetEventMetadataArgs = {
  event_metadata_uuid: Scalars['String'];
};

export type QueryGetEventPromotionMetadataArgs = {
  uuid: Scalars['String'];
};

export type QueryGetEventPromotionNotificationAgreementArgs = {
  event_id_list: Array<Scalars['String']>;
};

export type QueryGetExperienceGroupProductArgs = {
  input: ExperienceGroupProductInput;
};

export type QueryGetExperienceGroupProductDepartmentListArgs = {
  promotion_id?: InputMaybe<Scalars['ID']>;
  schedule_id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetExperienceGroupUserAppliedItemListArgs = {
  input: ExperienceGroupUserAppliedItemListInput;
};

export type QueryGetFbkHomeAdAndPersonalizedProductListArgs = {
  ad_id_list: Array<Scalars['ID']>;
};

export type QueryGetFollowListArgs = {
  after?: InputMaybe<Scalars['String']>;
};

export type QueryGetLikedEpickPostListByUserArgs = {
  last_id?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  profile_id: Scalars['ID'];
};

export type QueryGetManagedHierarchyCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetMembershipMileageArgs = {
  actual_payment_amount_list: Array<Scalars['Int']>;
};

export type QueryGetMembershipMileageDepositListArgs = {
  target_id_list: Array<Scalars['ID']>;
};

export type QueryGetMyParticipateEventMetadataArgs = {
  event_content_uuid: Scalars['String'];
  event_metadata_uuid: Scalars['String'];
};

export type QueryGetNearbyMinRequiredAmountCouponInfoArgs = {
  input: NearbyMinRequiredAmountCouponInfoInput;
};

export type QueryGetOrderSheetTotalCalculatedMileageArgs = {
  coupon_amount: Scalars['Int'];
  mileage_amount: Scalars['Int'];
  point_amount: Scalars['Int'];
  shop_total_amount_list: Array<ShopTotalAmount>;
};

export type QueryGetOrderSheetTotalCalculatedMileageListArgs = {
  coupon_amount: Scalars['Int'];
  mileage_amount: Scalars['Int'];
  point_amount: Scalars['Int'];
  shop_total_amount_list: Array<ShopTotalAmount>;
};

export type QueryGetOrderedProductListForPostArgs = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type QueryGetOrderedProductListForPostV2Args = {
  limit: Scalars['Int'];
  skip: Scalars['Int'];
};

export type QueryGetPresignedStoryImageUrlListArgs = {
  extension_list: Array<Scalars['String']>;
};

export type QueryGetPresignedUserPickPostImageUrlListArgs = {
  extension_list: Array<Scalars['String']>;
};

export type QueryGetProductListByIdListArgs = {
  product_id_list: Array<Scalars['ID']>;
};

export type QueryGetProductListByKeywordForPostArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  search_keyword: Scalars['String'];
};

export type QueryGetProductListByZzimFolderForPostArgs = {
  after?: InputMaybe<Scalars['String']>;
  folder_id?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
};

export type QueryGetProductsPresetBannerItemArgs = {
  id: Scalars['Int'];
};

export type QueryGetProfileAndPostListByTopLikeArgs = {
  profile_id: Scalars['ID'];
};

export type QueryGetProfileImagePresignedUrlArgs = {
  extension: Scalars['String'];
};

export type QueryGetPromotion202104RankingArgs = {
  is_brand?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  ranking_category_id?: InputMaybe<Scalars['Int']>;
  shuffle?: InputMaybe<Scalars['Boolean']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPromotion202104UserRankingArgs = {
  season_idx: Scalars['Int'];
};

export type QueryGetPromotionExperienceGroupProductArgs = {
  input: PromotionExperienceGroupProductInput;
};

export type QueryGetPromotionExperienceGroupUserAppliedItemListArgs = {
  input: PromotionExperienceGroupUserAppliedItemListInput;
};

export type QueryGetRaffleArgs = {
  id: Scalars['ID'];
};

export type QueryGetRaffleListArgs = {
  input: RaffleListInput;
};

export type QueryGetRaffleShareUrlArgs = {
  raffle_id: Scalars['ID'];
  url_type?: InputMaybe<RaffleShareUrlType>;
};

export type QueryGetRaffleUserConditionListArgs = {
  input: RaffleUserConditionListInput;
};

export type QueryGetRecentViewedProductListForPostArgs = {
  date_from?: InputMaybe<Scalars['CrTimestamp']>;
  date_to?: InputMaybe<Scalars['CrTimestamp']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type QueryGetRecentViewedProductListForPostV2Args = {
  date_from?: InputMaybe<Scalars['CrTimestamp']>;
  date_to?: InputMaybe<Scalars['CrTimestamp']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type QueryGetRecommendedActivePromotionExperienceGroupProductListArgs = {
  input?: InputMaybe<RecommendedActivePromotionProductListInput>;
};

export type QueryGetRecommnedProfileListArgs = {
  target_id: Scalars['ID'];
};

export type QueryGetReviewUploadPresignedUrlArgs = {
  input: GetReviewUploadPresignedUrlInput;
};

export type QueryGetReviewUploadPresignedUrlListArgs = {
  extension_list: Array<Scalars['String']>;
};

export type QueryGetRewardUserDailyStatsListPerMonthArgs = {
  date_stats_ym: Scalars['String'];
};

export type QueryGetRewardUserMonthlyStatsAndSettlementArgs = {
  date_ym: Scalars['String'];
};

export type QueryGetRewardUserMonthlyStatsAndSettlementListArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type QueryGetShopListByShopIdListArgs = {
  popular_product_limit_count?: InputMaybe<Scalars['Int']>;
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetShopUxAllStoreHomeCategoriesArgs = {
  country_code?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['ID'];
  site_id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetShopUxCollectionDetailArgs = {
  id: Scalars['ID'];
  shop_id: Scalars['ID'];
};

export type QueryGetStorePickDataArgs = {
  id: Scalars['Int'];
  store_id: Scalars['Int'];
  store_pick_data_id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetStorePresetItemListArgs = {
  uuid: Scalars['String'];
};

export type QueryGetTalkLoungeAcceptedReplyArgs = {
  talk_id: Scalars['ID'];
};

export type QueryGetTalkLoungeEditingInfoArgs = {
  target_type?: InputMaybe<Scalars['String']>;
};

export type QueryGetTalkLoungeInteractiveTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryGetTalkLoungeLikeTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTalkLoungeLikedTalkArgs = {
  talk_id: Scalars['ID'];
};

export type QueryGetTalkLoungeMyReplyListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count: Scalars['Int'];
};

export type QueryGetTalkLoungeMyTalkListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count: Scalars['Int'];
};

export type QueryGetTalkLoungePointTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  is_rewardable?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTalkLoungePollAnswerResultArgs = {
  talk_id: Scalars['ID'];
};

export type QueryGetTalkLoungePresignedImageUrlListArgs = {
  extension_list: Array<Scalars['String']>;
};

export type QueryGetTalkLoungePreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  limit_count: Scalars['Int'];
  order?: InputMaybe<Scalars['String']>;
};

export type QueryGetTalkLoungePreviewListByTalkIdListArgs = {
  talk_id_list: Array<Scalars['ID']>;
};

export type QueryGetTalkLoungeReplyListArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  is_v2_order?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  parent_id_list?: InputMaybe<Array<Scalars['ID']>>;
  talk_id: Scalars['ID'];
};

export type QueryGetTalkLoungeRewardTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  is_rewardable?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTalkLoungeSearchedTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  search_keyword: Scalars['String'];
};

export type QueryGetTalkLoungeSubscribedKeywordTalkPreviewListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTalkLoungeTalkIdByTargetMapArgs = {
  target_id: Scalars['ID'];
  target_type: Scalars['String'];
};

export type QueryGetTalkLoungeTopPinnedPreviewListArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTotalCalculatedMileageArgs = {
  shop_actual_payment_amount_list: Array<ShopActualPaymentAmount>;
};

export type QueryGetUserNotificationCategoryByDdpComponentIdArgs = {
  ddp_component_id: Scalars['ID'];
};

export type QueryGetUserReportArgs = {
  uid?: InputMaybe<Scalars['String']>;
};

export type QueryGetUxBenefitBannerListArgs = {
  after?: InputMaybe<Scalars['String']>;
  benefit_banner_group_uuid: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  order_type?: InputMaybe<UxBenefitBannerRequestOrderType>;
  os: UxBenefitBannerOs;
  skip?: InputMaybe<Scalars['Int']>;
  version: Scalars['String'];
};

export type QueryGetUxBenefitSectionDataArgs = {
  benefit_section_uuid: Scalars['String'];
  request_data?: InputMaybe<Scalars['CrJson']>;
};

export type QueryGetUxBenefitSectionPropsArgs = {
  site_id?: InputMaybe<Scalars['String']>;
};

export type QueryGetUxCachedProductCardItemListArgs = {
  catalog_product_id_list?: InputMaybe<Array<Scalars['String']>>;
  exclude_price_badge?: InputMaybe<Scalars['Boolean']>;
  has_discount_info?: InputMaybe<Scalars['Boolean']>;
  need_plp_v1?: InputMaybe<Scalars['Boolean']>;
  use_caching?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetUxGoodsCardItemArgs = {
  catalog_product_id?: InputMaybe<Scalars['String']>;
  goods_id?: InputMaybe<Scalars['String']>;
  param?: InputMaybe<UxGoodsCardParamInput>;
};

export type QueryGetUxGoodsCardItemListArgs = {
  catalog_product_id_list?: InputMaybe<Array<Scalars['String']>>;
  goods_id_list?: InputMaybe<Array<Scalars['String']>>;
  param?: InputMaybe<UxGoodsCardParamInput>;
};

export type QueryGetUxGoodsCardItemListBySearchedGoodsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  age_list?: InputMaybe<Array<Scalars['Int']>>;
  age_text_list?: InputMaybe<Array<Scalars['String']>>;
  category_list?: InputMaybe<Array<Scalars['String']>>;
  color_list?: InputMaybe<Array<Scalars['String']>>;
  detailed_category_list?: InputMaybe<Array<Scalars['String']>>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  ignore_goods_options?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  max_price?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Int']>;
  only_brand?: InputMaybe<Scalars['Boolean']>;
  only_free_shipping?: InputMaybe<Scalars['Boolean']>;
  only_zpay?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<SearchedGoodsOrderType>;
  param?: InputMaybe<UxGoodsCardParamInput>;
  properties?: InputMaybe<SearchedGoodsPropertiesInput>;
  purchase_age_list?: InputMaybe<Array<Scalars['String']>>;
  push_back_sold_out_goods?: InputMaybe<Scalars['Boolean']>;
  query_type?: InputMaybe<Scalars['String']>;
  selected_color_image_only?: InputMaybe<Scalars['Boolean']>;
  session_bms?: InputMaybe<Array<Scalars['Int']>>;
  session_id?: InputMaybe<Scalars['ID']>;
  shop_id_list?: InputMaybe<Array<Scalars['String']>>;
  title_query?: InputMaybe<Scalars['String']>;
  ts?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
  zpay?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetVersionedManagedCategoryArgs = {
  input: CategoryVersionedManagedCategoryInput;
};

export type QueryGetWinnerListArgs = {
  promotion_id: Scalars['ID'];
};

export type QueryGet_Exposure_Recommendation_ComponentsArgs = {
  recommendation_section_type: Scalars['String'];
};

export type QueryGet_Saved_Shop_StatusArgs = {
  shop_id: Scalars['ID'];
};

export type QueryGet_Ux_Coupon_Policy_Info_ListArgs = {
  input: UxCouponPolicyInfoListInput;
};

export type QueryGet_Ux_Promotion_Gauge_BarArgs = {
  related_item_input?: InputMaybe<UxPromotionRelatedItemGaugeBarInput>;
  search_result_input?: InputMaybe<UxPromotionSearchResultGaugeBarInput>;
  store_home_input?: InputMaybe<UxPromotionStoreHomeGaugeBarInput>;
};

export type QueryGlobal_Banner_ListArgs = {
  type?: InputMaybe<Scalars['String']>;
};

export type QueryGoods_PresetArgs = {
  uuid: Scalars['String'];
};

export type QueryGoods_Price_And_Zpay_ListArgs = {
  shop_product_no_list: Array<Scalars['String']>;
};

export type QueryGoods_Tab_Ui_Section_ListArgs = {
  recently_search_keyword_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryHasUpdateInUserAccountMetadataArgs = {
  category: UserAccountMetadataCategory;
};

export type QueryHas_StoryArgs = {
  shop_id: Scalars['ID'];
};

export type QueryImage_Banner_GroupArgs = {
  id: Scalars['ID'];
};

export type QueryIsBeautyFrequencyEventInProgressArgs = {
  date_ym: Scalars['Int'];
};

export type QueryIsFbkUserAccountCouponIssuedArgs = {
  coupon_policy_id: Scalars['ID'];
};

export type QueryIsIssuableCouponByShopArgs = {
  shop_id: Scalars['ID'];
};

export type QueryIsUserAccountCouponPolicyIssuableArgs = {
  id: Scalars['ID'];
};

export type QueryIs_Exposed_Zigzag_Lens_IconArgs = {
  page_id: Scalars['String'];
};

export type QueryIssuable_Point_ListArgs = {
  product_id_list: Array<Scalars['ID']>;
  reference_date?: InputMaybe<Scalars['CrTimestamp']>;
  shop_id: Scalars['ID'];
};

export type QueryIssuable_Shop_Coupon_Info_ListArgs = {
  shop_id_list: Array<Scalars['ID']>;
};

export type QueryIssuable_Shop_Coupon_Info_V2_ListArgs = {
  shop_id_list: Array<Scalars['ID']>;
};

export type QueryJapan_Address_InfoArgs = {
  postcode: Scalars['String'];
};

export type QueryJapan_Address_ListArgs = {
  postcode: Scalars['String'];
};

export type QueryKitto_Category_Content_PageArgs = {
  first_category: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  page_input: KittoPageInput;
};

export type QueryKitto_Content_DetailArgs = {
  id: Scalars['ID'];
};

export type QueryKitto_Content_Detail_TempArgs = {
  id: Scalars['ID'];
};

export type QueryKitto_Content_Detail_With_LanguageArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
};

export type QueryKitto_Content_RankingArgs = {
  first_category: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Editor_Content_PageArgs = {
  content_page_input: KittoPageInput;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Editor_DetailArgs = {
  id: Scalars['ID'];
};

export type QueryKitto_Editor_RankingArgs = {
  language?: InputMaybe<Scalars['String']>;
  page_input: KittoPageInput;
};

export type QueryKitto_First_Category_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Hash_Tag_Content_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  hash_tag_name?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Hash_Tag_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
  q: Scalars['String'];
};

export type QueryKitto_Line_BannerArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Popular_Hash_Tag_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Qna_Content_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Recommend_Content_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Recommend_Editor_ListArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type QueryKitto_Related_Content_ListArgs = {
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  second_category: Scalars['String'];
};

export type QueryKitto_Second_Category_ListArgs = {
  first_category: Scalars['String'];
};

export type QueryLoad_By_Available_With_CouponArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_CategoryArgs = {
  display_category_id: Scalars['ID'];
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Discounted_Saved_ProductArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Recently_Purchased_ProductArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Recently_Searched_KeywordArgs = {
  keyword: Scalars['String'];
  recently_searched_keywords: Array<Scalars['String']>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Recently_Viewed_ProductArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Saved_ProductArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLoad_By_Shopping_CartArgs = {
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryLocale_Code_ListArgs = {
  type?: InputMaybe<LocaleCodeType>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryMain_Home_Recommend_Store_Group_InternalArgs = {
  id: Scalars['ID'];
};

export type QueryManaged_Shops_ListArgs = {
  primary_shop_main_domain: Scalars['String'];
  primary_tag_id: Scalars['ID'];
};

export type QueryMaximum_Usable_MileageArgs = {
  coupon_info_list: Array<MaximumUsableMileageCouponInfoInput>;
  product_info_list: Array<MaximumUsableMileageProductInfoInput>;
};

export type QueryMembership_Coupon_PackArgs = {
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  shop_id: Scalars['ID'];
};

export type QueryMetadataArgs = {
  adid?: InputMaybe<Scalars['String']>;
  data_version: Scalars['Int'];
  platform: Scalars['String'];
  ui?: InputMaybe<Scalars['String']>;
};

export type QueryMy_Page_Banner_ListArgs = {
  tag_name?: InputMaybe<Scalars['String']>;
};

export type QueryMy_Page_Extra_MenuArgs = {
  offerwall_available_reward?: InputMaybe<Scalars['Int']>;
};

export type QueryMy_Pick_Coupon_User_Request_ListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryMy_Product_Review_ListArgs = {
  after_key?: InputMaybe<Scalars['ID']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryMy_Product_Review_User_Reply_ListArgs = {
  pagination: ProductReviewCursorPaginationInput;
};

export type QueryMy_Story_ListArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  with_expired?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMy_Story_View_CountArgs = {
  story_id: Scalars['ID'];
};

export type QueryOnboarding_InfoArgs = {
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryOnboarding_ResultArgs = {
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryOnboarding_Shop_List_UiArgs = {
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  user_agent?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryOnboarding_Style_ListArgs = {
  input: OnboardingStyleInput;
};

export type QueryOnboarding_UserArgs = {
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryOne_Day_Delivery_BannerArgs = {
  category_id?: InputMaybe<Scalars['String']>;
};

export type QueryOrderArgs = {
  order_id?: InputMaybe<Scalars['ID']>;
  order_number?: InputMaybe<Scalars['String']>;
};

export type QueryOrder_ItemArgs = {
  order_item_number: Scalars['String'];
};

export type QueryOrder_Item_Inquiry_ListArgs = {
  date_created_ymd_from?: InputMaybe<Scalars['Int']>;
  has_reply?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryOrder_Item_ListArgs = {
  exclude_new_exchange_order_item?: InputMaybe<Scalars['Boolean']>;
  exclude_user_withdrawn_request?: InputMaybe<Scalars['Boolean']>;
  include_withdrawn_request?: InputMaybe<Scalars['Boolean']>;
  order_item_number_list?: InputMaybe<Array<Scalars['String']>>;
  order_item_request_number?: InputMaybe<Scalars['String']>;
  order_number?: InputMaybe<Scalars['String']>;
};

export type QueryOrder_Item_Return_Shipping_Tracking_DetailArgs = {
  order_item_number: Scalars['String'];
};

export type QueryOrder_Item_Shipping_Tracking_DetailArgs = {
  order_item_number: Scalars['String'];
};

export type QueryOrder_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  order_number_list?: InputMaybe<Array<Scalars['String']>>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryOrder_SheetArgs = {
  uuid: Scalars['String'];
};

export type QueryOrder_Sheet_V2Args = {
  uuid: Scalars['String'];
};

export type QueryOrder_Zigzin_Shipping_Base_Info_ListArgs = {
  input: OrderZigzinShippingBaseInfoListInput;
};

export type QueryOutdated_Cart_Item_ListArgs = {
  last_added_day_before: Scalars['Int'];
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryPage_InfoArgs = {
  after?: InputMaybe<Scalars['String']>;
  age_filter_id?: InputMaybe<Scalars['Int']>;
  attribute_id?: InputMaybe<Scalars['Int']>;
  base_shop_id?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  checkbox_filter_values?: InputMaybe<Array<Scalars['String']>>;
  ddp_component_id?: InputMaybe<Scalars['String']>;
  department_id?: InputMaybe<Scalars['String']>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']>>;
  goods_filter_option?: InputMaybe<GoodsFilterOptionInput>;
  is_beauty?: InputMaybe<Scalars['Boolean']>;
  page_id?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  promotion_uuid?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  subcategory_id?: InputMaybe<Scalars['Int']>;
  ui_property?: InputMaybe<UiPropertyInput>;
};

export type QueryPaymentArgs = {
  uuid: Scalars['String'];
};

export type QueryPayment_Card_Meta_DataArgs = {
  bank_identification_number: Scalars['String'];
};

export type QueryPayment_Event_List_For_DisplayArgs = {
  payment_amount?: InputMaybe<Scalars['Int']>;
  payment_event_target_list: Array<PaymentEventTargetInput>;
};

export type QueryPayment_Method_Information_ListArgs = {
  product_type_list?: InputMaybe<Array<PaymentProductType>>;
};

export type QueryPdp_Banner_Ad_Creative_ListArgs = {
  banner_type_list?: InputMaybe<Array<Scalars['String']>>;
  is_first_page: Scalars['Boolean'];
  shop_category_id: Scalars['ID'];
  shop_id?: InputMaybe<Scalars['ID']>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryPdp_Base_InfoArgs = {
  catalog_product_id: Scalars['ID'];
  input?: InputMaybe<PdpBaseInfoInput>;
  source?: InputMaybe<Scalars['String']>;
};

export type QueryPdp_Bridge_Page_InfoArgs = {
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  main_domain?: InputMaybe<Scalars['String']>;
  shop_id?: InputMaybe<Scalars['ID']>;
  shop_product_no?: InputMaybe<Scalars['String']>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryPdp_Browsing_TypeArgs = {
  browsing_type?: InputMaybe<Scalars['String']>;
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Cross_Seller_ProductArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Infinite_Recommend_GroupArgs = {
  catalog_product_id: Scalars['ID'];
  end_cursor?: InputMaybe<Scalars['String']>;
};

export type QueryPdp_Infinite_Recommend_Group_By_Product_Id_ListArgs = {
  group_type?: InputMaybe<Scalars['String']>;
  id_list: Array<Scalars['ID']>;
};

export type QueryPdp_Is_Required_Size_InfoArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Option_InfoArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Option_Matching_ValidationArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Recommend_GroupArgs = {
  catalog_product_id: Scalars['ID'];
  group_type_list?: InputMaybe<Array<Scalars['String']>>;
  seller_coordi_plp_v1?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPdp_Recommend_Id_ListArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Recommend_Item_ListArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Related_Item_ListArgs = {
  catalog_product_id: Scalars['ID'];
  relation_type: PdpRelationType;
};

export type QueryPdp_Shop_CollectionArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Short_Form_ContentsArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Size_InfoArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryPdp_Url_ListArgs = {
  input: PdpUrlInput;
};

export type QueryPdp_User_Base_InfoArgs = {
  input?: InputMaybe<PdpUserBaseInfoInput>;
};

export type QueryPdp_Zigzin_Shipping_Base_InfoArgs = {
  input?: InputMaybe<PdpZigzinShippingBaseInfoInput>;
};

export type QueryPdp_Zigzin_Shipping_Base_Info_ListArgs = {
  input?: InputMaybe<PdpZigzinShippingBaseInfoListInput>;
};

export type QueryPersonalized_My_Pick_Coupon_InfoArgs = {
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryPoint_Expire_Expected_MileageArgs = {
  remain_expiry_day: Scalars['Int'];
};

export type QueryPoint_Expire_Expected_Mileage_Transaction_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  remain_expiry_day: Scalars['Int'];
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryPoint_Expire_Expected_Mileage_Transaction_List_With_Expire_MileageArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  remain_expiry_day: Scalars['Int'];
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryPoint_Expire_Expected_Mileage_With_Expire_MileageArgs = {
  remain_expiry_day: Scalars['Int'];
};

export type QueryPoint_Mileage_Policy_ListArgs = {
  policy_target_types?: InputMaybe<Array<PointMileagePolicyTargetType>>;
  policy_types?: InputMaybe<Array<PointMileagePolicyType>>;
};

export type QueryPoint_Mileage_Policy_List_For_UseArgs = {
  mileage_target_list: Array<PointMileageTargetListInput>;
};

export type QueryPoint_Mileage_Transaction_ListArgs = {
  action_type?: InputMaybe<PointMileageActionType>;
  date_end?: InputMaybe<Scalars['CrTimestamp']>;
  date_start?: InputMaybe<Scalars['CrTimestamp']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryPoint_Mileage_Transaction_List_With_Expire_MileageArgs = {
  action_type?: InputMaybe<PointMileageActionType>;
  date_end?: InputMaybe<Scalars['CrTimestamp']>;
  date_start?: InputMaybe<Scalars['CrTimestamp']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  site_mileage_target_id_list?: InputMaybe<Array<Scalars['String']>>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryPoint_Refund_MileageArgs = {
  mileage: Scalars['Int'];
  refund_responsibility?: InputMaybe<PointRefundMileageResponsibilityType>;
  site_mileage_target_id: Scalars['String'];
  type: PointMileageTransactionType;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProduct_ListArgs = {
  input: ProductListInput;
};

export type QueryProduct_ReviewArgs = {
  id: Scalars['ID'];
};

export type QueryProduct_Review_Attachment_ListArgs = {
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['ID']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryProduct_Review_Best_Review_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<ProductReviewListOrderType>;
  product_id: Scalars['ID'];
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryProduct_Review_Create_Info_ListArgs = {
  args_list: Array<ProductReviewCreateInfoArgs>;
};

export type QueryProduct_Review_Create_Review_Result_InfoArgs = {
  product_review_id: Scalars['ID'];
};

export type QueryProduct_Review_Display_Limited_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryProduct_Review_EventArgs = {
  country: Scalars['String'];
  language: Scalars['String'];
};

export type QueryProduct_Review_Event_ListArgs = {
  locale_list: Array<ProductReviewEventArgs>;
};

export type QueryProduct_Review_Issue_Point_InfoArgs = {
  item_price?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['ID']>;
  shop_id: Scalars['ID'];
  site_id?: InputMaybe<Scalars['ID']>;
};

export type QueryProduct_Review_ListArgs = {
  custom_filter_exists?: InputMaybe<Scalars['Boolean']>;
  custom_filter_query_input?: InputMaybe<ReviewCustomFilterQueryInput>;
  date_created_lt?: InputMaybe<Scalars['CrTimestamp']>;
  has_attachment?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  only_mine?: InputMaybe<Scalars['Boolean']>;
  option_detail_list?: InputMaybe<Array<ProductReviewOptionDetail>>;
  order?: InputMaybe<ProductReviewListOrderType>;
  order_item_number_list?: InputMaybe<Array<Scalars['String']>>;
  product_id?: InputMaybe<Scalars['ID']>;
  skip_count?: InputMaybe<Scalars['Int']>;
  topic_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryProduct_Review_Pdp_SummaryArgs = {
  product_id: Scalars['ID'];
};

export type QueryProduct_Review_ProfileArgs = {
  product_review_user_account_info_id?: InputMaybe<Scalars['ID']>;
};

export type QueryProduct_Review_Reviewer_Ranker_ListArgs = {
  pagination?: InputMaybe<ProductReviewPaginationInput>;
};

export type QueryProduct_Review_Seller_Event_Banner_InfoArgs = {
  only_running?: InputMaybe<Scalars['Boolean']>;
  product_id?: InputMaybe<Scalars['ID']>;
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryProduct_Review_Shop_SummaryArgs = {
  shop_id: Scalars['ID'];
};

export type QueryProduct_Review_SummaryArgs = {
  product_id: Scalars['ID'];
};

export type QueryProduct_Review_Topic_ListArgs = {
  product_id: Scalars['ID'];
};

export type QueryProduct_Review_User_Reply_ListArgs = {
  pagination: ProductReviewCursorPaginationInput;
  parent_reply_id?: InputMaybe<Scalars['ID']>;
  product_review_id: Scalars['ID'];
};

export type QueryProduct_Review_User_Review_ListArgs = {
  date_created_gte?: InputMaybe<Scalars['CrTimestamp']>;
  order?: InputMaybe<ProductReviewListOrderType>;
  pagination: ProductReviewPaginationInput;
  product_review_user_account_info_id: Scalars['ID'];
  type_list?: InputMaybe<Array<ProductReviewType>>;
};

export type QueryProduct_Reviews_Cached_CountArgs = {
  product_id: Scalars['ID'];
};

export type QueryProducts_Preset_DataArgs = {
  after?: InputMaybe<Scalars['String']>;
  app_version?: InputMaybe<Scalars['String']>;
  coupon_discount_price?: InputMaybe<Scalars['Boolean']>;
  group_index?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  products_preset_uuid: Scalars['String'];
  sales_status_list?: InputMaybe<Array<ProductsPresetSearchedProductSalesStatus>>;
  session_data_options?: InputMaybe<ProductsPresetSessionOptions>;
  simple?: InputMaybe<Scalars['Boolean']>;
  store_unique?: InputMaybe<Scalars['Boolean']>;
  use_ad_image?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPush_Category_AgreementArgs = {
  category: Scalars['String'];
};

export type QueryRecommend_By_CouponArgs = {
  coupon_id: Scalars['ID'];
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryRecommend_By_Display_CategoryArgs = {
  display_category_id: Scalars['ID'];
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryRecommend_By_KeywordArgs = {
  keyword: Scalars['String'];
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryRecommend_By_ProductArgs = {
  component_type: DrComponentType;
  product_id: Scalars['ID'];
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryRecommended_Each_Product_ListArgs = {
  display_type: Scalars['String'];
  exclude_product_ids?: InputMaybe<Array<Scalars['ID']>>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryRecommended_Product_List_V2Args = {
  age?: InputMaybe<Scalars['Int']>;
  age_group?: InputMaybe<AgeGroupType>;
  display_type: RecommendProductDisplayType;
  exclude_product_ids?: InputMaybe<Array<Scalars['ID']>>;
  page_size?: InputMaybe<Scalars['Int']>;
  product_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryRecommended_Search_Keyword_ListArgs = {
  category_limit_count?: InputMaybe<Scalars['Int']>;
  department?: InputMaybe<Scalars['String']>;
  event_limit_count?: InputMaybe<Scalars['Int']>;
  is_brand?: InputMaybe<Scalars['Boolean']>;
  keyword: Scalars['String'];
  page_id?: InputMaybe<Scalars['String']>;
  pick_limit_count?: InputMaybe<Scalars['Int']>;
  searched_keyword_history_list?: InputMaybe<Array<SearchedKeywordHistoryListInput>>;
  shop_limit_count?: InputMaybe<Scalars['Int']>;
  site_id?: InputMaybe<Scalars['ID']>;
  site_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryRelated_Item_ListArgs = {
  action: UxRelatedItemAction;
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  product_no?: InputMaybe<Scalars['String']>;
  scene: UxRelatedItemScene;
  session_id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryRelated_Product_ListArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryRelated_Product_Review_SummaryArgs = {
  product_id: Scalars['ID'];
};

export type QueryRelated_Search_Word_ListArgs = {
  page_id?: InputMaybe<Scalars['String']>;
  search_word?: InputMaybe<Scalars['String']>;
};

export type QueryReserved_Expire_Point_ListArgs = {
  expire_days: Scalars['Int'];
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QuerySalePageInfoArgs = {
  input?: InputMaybe<SalePageInfoInput>;
};

export type QuerySale_Recommend_CarouselArgs = {
  id: Scalars['ID'];
};

export type QuerySaved_Product_Id_ListArgs = {
  id_list: Array<Scalars['String']>;
};

export type QuerySaved_Shop_Id_ListArgs = {
  id_list: Array<Scalars['String']>;
};

export type QuerySaved_Styling_ListArgs = {
  input: SavedStylingListInput;
};

export type QuerySearchMyProductArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type QuerySearch_Auto_CompleteArgs = {
  category_limit?: InputMaybe<Scalars['Int']>;
  event_limit?: InputMaybe<Scalars['Int']>;
  keyword_limit?: InputMaybe<Scalars['Int']>;
  page_id?: InputMaybe<Scalars['String']>;
  pick_limit?: InputMaybe<Scalars['Int']>;
  query_string: Scalars['String'];
  shop_limit?: InputMaybe<Scalars['Int']>;
};

export type QuerySearch_Bar_PlaceholderArgs = {
  country?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page_id: Scalars['String'];
  site_id?: InputMaybe<Scalars['ID']>;
};

export type QuerySearch_Popular_KeywordArgs = {
  classified_popular_keyword_id?: InputMaybe<Scalars['String']>;
  page_id?: InputMaybe<Scalars['String']>;
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QuerySearch_ResultArgs = {
  input: SearchResultInput;
};

export type QuerySearch_Result_Recommend_Item_ListArgs = {
  input: SearchResultRecommendItemListInput;
};

export type QuerySearched_Goods_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  age_list?: InputMaybe<Array<Scalars['Int']>>;
  age_text_list?: InputMaybe<Array<Scalars['String']>>;
  attribute_list?: InputMaybe<Array<SearchedProductAttributeListInput>>;
  brand_id?: InputMaybe<Scalars['ID']>;
  category_list?: InputMaybe<Array<Scalars['String']>>;
  color_list?: InputMaybe<Array<Scalars['String']>>;
  detailed_category_list?: InputMaybe<Array<Scalars['String']>>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  filter_id_list?: InputMaybe<Array<Scalars['String']>>;
  ignore_goods_options?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  max_price?: InputMaybe<Scalars['Int']>;
  max_price_with_currency?: InputMaybe<PriceWithCurrencyInput>;
  min_price?: InputMaybe<Scalars['Int']>;
  min_price_with_currency?: InputMaybe<PriceWithCurrencyInput>;
  model_size?: InputMaybe<SearchedGoodsModelSizeInput>;
  only_brand?: InputMaybe<Scalars['Boolean']>;
  only_free_shipping?: InputMaybe<Scalars['Boolean']>;
  only_zpay?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<SearchedGoodsOrderType>;
  page_id?: InputMaybe<Scalars['String']>;
  product_group_list?: InputMaybe<Array<Scalars['String']>>;
  properties?: InputMaybe<SearchedGoodsPropertiesInput>;
  purchase_age_list?: InputMaybe<Array<Scalars['String']>>;
  selected_color_image_only?: InputMaybe<Scalars['Boolean']>;
  shop_id_list?: InputMaybe<Array<Scalars['String']>>;
  style_list?: InputMaybe<Array<SearchedProductStyleInput>>;
  title_query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  zpay?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearched_Product_ListArgs = {
  input: SearchProductInput;
};

export type QuerySearched_Shop_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  page_id?: InputMaybe<Scalars['String']>;
  title_query?: InputMaybe<Scalars['String']>;
};

export type QuerySelected_Brand_ListArgs = {
  group_id: Scalars['ID'];
};

export type QuerySeller_Grade_InfoArgs = {
  shop_id: Scalars['ID'];
};

export type QueryShipping_Group_ListArgs = {
  order_number?: InputMaybe<Scalars['String']>;
  shipping_group_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryShipping_RegionArgs = {
  postcode: Scalars['String'];
};

export type QueryShopArgs = {
  id: Scalars['ID'];
};

export type QueryShop_DetailArgs = {
  id: Scalars['Int'];
};

export type QueryShop_Detail_Category_Goods_ListArgs = {
  category_id?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  shop_id: Scalars['Int'];
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryShop_Filter_InfoArgs = {
  department_id?: InputMaybe<Scalars['ID']>;
};

export type QueryShop_ListArgs = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryShop_Ranking_ListArgs = {
  age_list?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<Scalars['String']>;
  department_id?: InputMaybe<Scalars['ID']>;
  is_minor?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
  style_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryShop_Ux_Best_Product_Collection_ListArgs = {
  input?: InputMaybe<ShopUxBestProductCollectionListInput>;
};

export type QueryShop_Ux_Category_Component_ListArgs = {
  country_code?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['ID'];
  site_id?: InputMaybe<Scalars['ID']>;
};

export type QueryShop_Ux_Category_ListArgs = {
  shop_id: Scalars['ID'];
};

export type QueryShop_Ux_Component_ListArgs = {
  after_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
  check_button_item_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  custom_category_id?: InputMaybe<Scalars['ID']>;
  recommend_similar_product_id?: InputMaybe<Scalars['ID']>;
  shop_id: Scalars['ID'];
  sorting_item_id?: InputMaybe<Scalars['ID']>;
};

export type QueryShop_Ux_New_Product_Collection_ListArgs = {
  after_id?: InputMaybe<Scalars['ID']>;
};

export type QueryShop_Ux_ShopArgs = {
  main_domain?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['ID'];
};

export type QueryShop_Ux_Store_Home_Category_ListArgs = {
  country_code?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['ID'];
  site_id?: InputMaybe<Scalars['ID']>;
};

export type QueryShop_Ux_Style_Tab_ContentsArgs = {
  key: Scalars['ID'];
  type: StyleTabContentType;
};

export type QueryShop_Ux_Style_Tab_Displayed_Contents_ListArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  displayed?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  sort_direction?: InputMaybe<StyleTabSortDirection>;
};

export type QueryShoplive_Login_TokenArgs = {
  campaign_key: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type QueryShoplive_Sharing_Meta_InfoArgs = {
  campaign_key: Scalars['String'];
};

export type QueryShort_SlideArgs = {
  id: Scalars['ID'];
};

export type QueryShort_Slide_Live_ListArgs = {
  id: Scalars['ID'];
};

export type QuerySimilar_Goods_ListArgs = {
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  goods_url: Scalars['String'];
  shop_id?: InputMaybe<Scalars['ID']>;
  shop_product_no?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type QuerySimilar_Product_ListArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QuerySimilar_Search_ResultArgs = {
  input: SimilarSearchResultInput;
};

export type QuerySimple_Bank_Account_ListArgs = {
  status_list?: InputMaybe<Array<SimpleBankAccountStatus>>;
};

export type QuerySimple_Bank_CertArgs = {
  id: Scalars['ID'];
};

export type QuerySims_Shop_ListArgs = {
  scene: UxSimsShopScene;
  shop_id_list: Array<Scalars['ID']>;
};

export type QueryStory_Coupon_ListArgs = {
  shop_id: Scalars['ID'];
};

export type QueryStory_ListArgs = {
  only_exposure: Scalars['Boolean'];
  shop_id: Scalars['ID'];
};

export type QueryStory_Service_InfoArgs = {
  shop_id_list?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryStyle_Group_List_Categorized_UiArgs = {
  user_agent?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryStyle_Group_List_Grid_UiArgs = {
  user_agent?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryStyle_Group_List_UiArgs = {
  birth_on_year?: InputMaybe<Scalars['String']>;
};

export type QueryStyle_Group_Shop_List_UiArgs = {
  group_id_list: Array<Scalars['ID']>;
};

export type QueryStyling_DetailArgs = {
  id: Scalars['ID'];
};

export type QueryStyling_List_By_Product_IdArgs = {
  input?: InputMaybe<StylingListByProductIdInput>;
};

export type QueryStyling_List_By_Tag_ListArgs = {
  input?: InputMaybe<StylingListByTagListInput>;
};

export type QueryTalk_Lounge_TalkArgs = {
  talk_id: Scalars['ID'];
};

export type QueryUmd_Saved_Product_ListArgs = {
  category_id?: InputMaybe<Scalars['String']>;
  end_cursor?: InputMaybe<Scalars['String']>;
  folder_id?: InputMaybe<Scalars['String']>;
  product_filter?: InputMaybe<UxSavedProductFilterInput>;
};

export type QueryUmd_Saved_Product_List_By_ShopArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['String'];
};

export type QueryUmd_Saved_Shop_ListArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Array<UmdShopFilterType>>;
  orderType?: InputMaybe<Array<UmdShopOrderType>>;
};

export type QueryUmd_Shop_List_On_Saved_ProductArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
};

export type QueryUmd_Shop_Tag_ListArgs = {
  shop_id: Scalars['String'];
};

export type QueryUmd_Shop_Tag_Update_InfoArgs = {
  shop_id: Scalars['String'];
};

export type QueryUmd_Tagged_Shop_ListArgs = {
  tag: Scalars['String'];
};

export type QueryUsable_Coupon_ListArgs = {
  input: UsableCouponListInput;
};

export type QueryUser_Account_Coupon_ListArgs = {
  id_list?: InputMaybe<Array<Scalars['ID']>>;
  limit_count?: InputMaybe<Scalars['Int']>;
  order_type_list?: InputMaybe<Array<UserAccountCouponListOrderType>>;
  skip_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<UserAccountCouponStatus>;
  status_list?: InputMaybe<Array<UserAccountCouponStatus>>;
  user_account_coupon_policy_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUser_Account_Coupon_PolicyArgs = {
  id: Scalars['ID'];
};

export type QueryUser_Account_Coupon_Policy_ListArgs = {
  coupon_code_type?: InputMaybe<CouponCodeType>;
  user_account_coupon_policy_id_list: Array<Scalars['ID']>;
};

export type QueryUser_Account_Point_InfoArgs = {
  expire_days?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Account_Point_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Account_ProfileArgs = {
  nickname?: InputMaybe<Scalars['String']>;
  user_account_id: Scalars['ID'];
};

export type QueryUser_Account_Profile_ListArgs = {
  nickname_startswith?: InputMaybe<Scalars['String']>;
  user_account_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUser_Account_Refund_Point_InfoArgs = {
  point?: InputMaybe<Scalars['Int']>;
  refund_responsibility?: InputMaybe<UserAccountPointRefundResponsibility>;
  target_uid: Scalars['String'];
  type: UserAccountPointType;
};

export type QueryUser_Account_Shop_Notification_ListArgs = {
  shop_id: Scalars['ID'];
};

export type QueryUser_Account_Shop_SubscribedArgs = {
  shop_id: Scalars['ID'];
};

export type QueryUser_Claim_Group_ListArgs = {
  date_requested_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_requested_lt?: InputMaybe<Scalars['CrTimestamp']>;
  last_id?: InputMaybe<Scalars['ID']>;
  status_list?: InputMaybe<Array<OrderItemRequestStatus>>;
};

export type QueryUser_Claim_ListArgs = {
  date_requested_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_requested_lt?: InputMaybe<Scalars['CrTimestamp']>;
  last_id?: InputMaybe<Scalars['ID']>;
  status_list?: InputMaybe<Array<OrderItemRequestStatus>>;
};

export type QueryUser_Custom_Filter_InfoArgs = {
  custom_filter_query_input?: InputMaybe<ReviewCustomFilterQueryInput>;
};

export type QueryUser_DataArgs = {
  type_list?: InputMaybe<Array<UserDataType>>;
};

export type QueryUser_LocaleArgs = {
  client_ip_address?: InputMaybe<Scalars['String']>;
  default_aos_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  default_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  default_ios_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryUser_Notification_ListArgs = {
  categories?: InputMaybe<Array<UserNotificationCategory>>;
  limit_count?: InputMaybe<Scalars['Int']>;
  shop_id?: InputMaybe<Scalars['ID']>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Order_ListArgs = {
  date_created_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_created_lt?: InputMaybe<Scalars['CrTimestamp']>;
  exclude_new_exchange_order_item?: InputMaybe<Scalars['Boolean']>;
  last_id?: InputMaybe<Scalars['ID']>;
  status_list?: InputMaybe<Array<OrderItemStatus>>;
};

export type QueryUser_Pay_Feature_Flag_ListArgs = {
  feature_name_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryUser_Pay_Payment_Financial_Company_ListArgs = {
  financial_company_code?: InputMaybe<UserPayFinancialCompanyCode>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UserPayPaymentFinancialCompanyListOrderType>;
  payment_method?: InputMaybe<UserPayPaymentMethod>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Post_Code_NoticeArgs = {
  country?: InputMaybe<CountryType>;
  is_all_zigzin_product: Scalars['Boolean'];
  post_code: Scalars['String'];
};

export type QueryUser_Postbox_ListArgs = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  end_cursor?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type QueryUser_Profile_Pre_Signed_UrlArgs = {
  extension: Scalars['String'];
};

export type QueryUser_Searched_Claim_ListArgs = {
  date_requested_from: Scalars['CrTimestamp'];
  date_requested_to: Scalars['CrTimestamp'];
  last_date_requested?: InputMaybe<Scalars['CrTimestamp']>;
  last_id?: InputMaybe<Scalars['ID']>;
  request_status_list?: InputMaybe<Array<OrderItemRequestStatus>>;
  search_cursor?: InputMaybe<Scalars['String']>;
  search_keyword?: InputMaybe<Scalars['String']>;
};

export type QueryUser_Searched_Claim_Order_Item_ListArgs = {
  date_requested_from: Scalars['CrTimestamp'];
  date_requested_to: Scalars['CrTimestamp'];
  last_id?: InputMaybe<Scalars['ID']>;
  search_cursor?: InputMaybe<Scalars['String']>;
  search_keyword: Scalars['String'];
  status_list?: InputMaybe<Array<OrderItemRequestStatus>>;
};

export type QueryUser_Searched_Order_Item_ListArgs = {
  date_created_from: Scalars['CrTimestamp'];
  date_created_to: Scalars['CrTimestamp'];
  last_id?: InputMaybe<Scalars['ID']>;
  search_cursor?: InputMaybe<Scalars['String']>;
  search_keyword: Scalars['String'];
  status_list?: InputMaybe<Array<OrderItemStatus>>;
};

export type QueryUser_Searched_Order_ListArgs = {
  date_created_from: Scalars['CrTimestamp'];
  date_created_to: Scalars['CrTimestamp'];
  last_date_created?: InputMaybe<Scalars['CrTimestamp']>;
  last_id?: InputMaybe<Scalars['ID']>;
  search_cursor?: InputMaybe<Scalars['String']>;
  search_keyword?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<OrderItemStatus>>;
};

export type QueryUser_Shipping_Address_BookArgs = {
  id: Scalars['ID'];
};

export type QueryUser_Shipping_Address_Book_ListArgs = {
  country?: InputMaybe<CountryType>;
  limit_size?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Shipping_Address_Book_List_On_MasterArgs = {
  country?: InputMaybe<CountryType>;
  limit_size?: InputMaybe<Scalars['Int']>;
};

export type QueryUser_Viewed_Products_InfoArgs = {
  date_from?: InputMaybe<Scalars['CrTimestamp']>;
  date_to?: InputMaybe<Scalars['CrTimestamp']>;
  is_plp_v2?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type QueryUx_Bookmark_Shop_ListArgs = {
  end_cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UxBookmarkShopFilter>;
};

export type QueryUx_Brand_RankingArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryUx_Brand_Ranking_CategoryArgs = {
  category_id?: InputMaybe<Scalars['Int']>;
};

export type QueryUx_External_Product_Review_TabArgs = {
  product_id: Scalars['ID'];
};

export type QueryUx_Multiline_Ranking_CarouselArgs = {
  age_filter_id?: InputMaybe<Scalars['Int']>;
  category_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  sorting_id?: InputMaybe<Scalars['Int']>;
};

export type QueryUx_New_Shop_ListArgs = {
  department_id: Scalars['ID'];
};

export type QueryUx_Review_Filter_InfoArgs = {
  input: UxReviewFilterInfoInput;
};

export type QueryUx_Review_Prediction_SummaryArgs = {
  product_id: Scalars['ID'];
};

export type QueryUx_Review_Similar_Product_Review_ListArgs = {
  after_key?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  product_id: Scalars['ID'];
  topic: Scalars['String'];
};

export type QueryUx_Review_Topic_ListArgs = {
  product_id: Scalars['ID'];
};

export type QueryUx_Saved_Product_CategoryArgs = {
  catalog_product_id?: InputMaybe<Scalars['ID']>;
};

export type QueryUx_Saved_Product_Category_ListArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
};

export type QueryUx_Saved_Product_Filter_ListArgs = {
  folder_id?: InputMaybe<Scalars['String']>;
};

export type QueryUx_Saved_Product_List_By_Shop_V2Args = {
  page_range: UxPageRange;
  shop_id: Scalars['String'];
};

export type QueryUx_Saved_Product_List_V2Args = {
  filter: UxSavedProductListFilter;
  page_range: UxPageRange;
};

export type QueryUx_Saved_Product_Recommend_ListArgs = {
  product_id: Scalars['ID'];
};

export type QueryUx_Searched_Shop_ListArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  page_id?: InputMaybe<Scalars['String']>;
  re_search?: InputMaybe<ReSearchInput>;
  title_query?: InputMaybe<Scalars['String']>;
};

export type QueryUx_Short_Form_GroupArgs = {
  short_form_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUx_Short_Form_Init_Id_ListArgs = {
  id: Scalars['ID'];
  include_short_form_id_list: Array<Scalars['ID']>;
};

export type QueryUx_Short_Form_Item_ListArgs = {
  id_list: Array<Scalars['ID']>;
};

export type QueryUx_User_Body_Input_InfoArgs = {
  category_key?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['ID']>;
};

export type QueryValidate_Create_Order_SheetArgs = {
  input: CreateOrderSheetInput;
};

export type QueryValidate_Order_PromotionArgs = {
  order_item_number_list: Array<Scalars['String']>;
  order_number: Scalars['String'];
  order_promotion_validate_type: OrderPromotionValidateType;
  refund_responsibility: OrderRefundResponsibility;
  shipping_group_id?: InputMaybe<Scalars['ID']>;
};

export type QueryWeb_Banner_ListArgs = {
  type?: InputMaybe<Scalars['String']>;
};

export type QueryWeb_Browser_PageArgs = {
  page: Scalars['String'];
};

export type QueryWeb_Browser_Page_Item_Floating_ButtonArgs = {
  page_id: Scalars['String'];
};

export type QueryWeb_Clp_Top_Component_ListArgs = {
  ddp_component_id?: InputMaybe<Scalars['String']>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  page_id: Scalars['String'];
};

export type QueryWeb_Ddp_Rolling_Image_BannerArgs = {
  id: Scalars['ID'];
};

export type QueryWeb_Page_InfoArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']>>;
  page_id?: InputMaybe<Scalars['String']>;
  searched_filter_option?: InputMaybe<WebSearchedFilterOptionInput>;
  session_id?: InputMaybe<Scalars['String']>;
  ui_property?: InputMaybe<UiPropertyInput>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryWeb_Pdp_Recommend_GroupArgs = {
  catalog_product_id: Scalars['ID'];
  group_type_list?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryWeb_Pdp_Recommend_Item_ListArgs = {
  catalog_product_id: Scalars['ID'];
};

export type QueryWeb_Search_ResultArgs = {
  input: SearchResultInput;
};

export type QueryWeb_Ux_Saved_Product_ListArgs = {
  category_id?: InputMaybe<Scalars['String']>;
  end_cursor?: InputMaybe<Scalars['String']>;
  folder_id?: InputMaybe<Scalars['String']>;
  page_limit?: InputMaybe<Scalars['Int']>;
};

export type QueryWebui_ListArgs = {
  search_query?: InputMaybe<Scalars['String']>;
  types: Array<WebUiType>;
};

export type QueryWms_Product_Item_Quantity_ListArgs = {
  input: WmsProductItemQuantityListInput;
};

export type QueryWms_User_Notification_ListArgs = {
  product_id?: InputMaybe<Scalars['ID']>;
  product_item_id_list?: InputMaybe<Array<Scalars['ID']>>;
  type: WmsUserNotificationType;
};

export type QueryZendesk_Notice_ListArgs = {
  limit_count?: InputMaybe<Scalars['Int']>;
};

export type QueryZigzag_Faq_CategoryArgs = {
  id: Scalars['ID'];
};

export type QueryZigzag_Faq_Category_ListArgs = {
  language?: Scalars['String'];
  main_category?: InputMaybe<ZigzagFaqMainCategory>;
};

export type QueryZigzag_Faq_ContentArgs = {
  id: Scalars['ID'];
};

export type QueryZigzag_Faq_Content_ListArgs = {
  category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryZigzag_Faq_SynonymArgs = {
  id: Scalars['ID'];
};

export type QueryZigzag_Lens_SearchArgs = {
  image_content: Scalars['String'];
  image_type: ZigzagLensSearchImageType;
  is_image_cropped?: InputMaybe<Scalars['Boolean']>;
};

export type QueryZigzag_NoticeArgs = {
  id: Scalars['ID'];
};

export type QueryZigzag_Notice_ListArgs = {
  category?: InputMaybe<Scalars['String']>;
  date_ymd_gte?: InputMaybe<Scalars['String']>;
  date_ymd_lte?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  mypage_exposure?: InputMaybe<Scalars['Boolean']>;
  os?: InputMaybe<ZigzagNoticeOsType>;
  skip_count?: InputMaybe<Scalars['Int']>;
};

export type QueryZigzag_Week_MetaArgs = {
  meta_code: Scalars['String'];
};

export type QueryZigzag_Week_User_DataArgs = {
  meta_code: Scalars['String'];
};

export type QueryZigzin_Best_Item_InfoArgs = {
  input: ZigzinBestItemInfoInput;
};

export type QueryZigzin_Bookmarked_Shop_ListArgs = {
  include_item_count?: InputMaybe<Scalars['Boolean']>;
  shop_id_list: Array<Scalars['String']>;
};

export type QueryZigzin_Carousel_Item_InfoArgs = {
  input: ZigzinCarouselItemInfoInput;
};

export type QueryZigzin_Categorized_ItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id: Scalars['ID'];
  sorting_id?: InputMaybe<Scalars['ID']>;
  sub_category_id?: InputMaybe<Scalars['ID']>;
};

export type QueryZigzin_Saved_Shop_ItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['ID']>;
  shop_id: Scalars['ID'];
  sorting_id?: InputMaybe<Scalars['ID']>;
};

export type QueryZonly_Estimate_Shipping_Info_With_Account_IdArgs = {
  product_Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryZonly_Estimate_Shipping_Info_With_PostcodeArgs = {
  postcode?: InputMaybe<Scalars['String']>;
  product_Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryZonly_One_Day_Estimate_Shipping_InfoArgs = {
  product_Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Raffle = {
  /** 래플 부가 설정 정보 */
  additional_info?: Maybe<RaffleAdditionalInfo>;
  /** 래플 응모 인원 */
  applicant_count: Scalars['Int'];
  /** 국가 */
  country: Scalars['String'];
  /** 래플 응모 종료일시 */
  date_apply_end: Scalars['CrTimestamp'];
  /** 래플 응모 시작일시 */
  date_apply_start: Scalars['CrTimestamp'];
  /** 래플 종료 일시 */
  date_completed: Scalars['CrTimestamp'];
  /** 래플 노출 시작일시 */
  date_display_start: Scalars['CrTimestamp'];
  /** 래플 구매 종료일시 */
  date_purchase_end: Scalars['CrTimestamp'];
  /** 래플 구매 시작일시 */
  date_purchase_start: Scalars['CrTimestamp'];
  /** 래플 중지 일시 */
  date_stopped?: Maybe<Scalars['CrTimestamp']>;
  /** 래플 ID */
  id: Scalars['ID'];
  /** 대상 플랫폼 */
  platform: PromotionPlatform;
  /** 래플과 연관된 프로모션 ID */
  promotion_id: Scalars['ID'];
  /** 래플 참여조건 정보 목록 */
  raffle_condition_info_list: Array<RaffleConditionInfo>;
  /**
   * 래플 참여조건
   * @deprecated raffle_condition_info_list 로 대체
   */
  raffle_condition_list: Array<RaffleConditionType>;
  /** 래플 상품 */
  raffle_product?: Maybe<PromotionProduct>;
  /** 래플 상태 */
  raffle_status?: Maybe<RaffleStatus>;
  /** 사이트 ID */
  site_id: Scalars['ID'];
  /** 래플 이름 */
  title: Scalars['String'];
  /** 유저 참여 정보, 로그인 상태에서만 반환됨, 로그인 되지 않은 경우 null */
  user_apply_info?: Maybe<UserApplyInfo>;
};

export type RaffleAdditionalInfo = {
  /** 래플 응모 완료 페이지의 커스터마이즈 정보들 */
  raffle_applied_page_customization?: Maybe<RaffleAppliedPageCustomization>;
  /** 래플 당첨자 발표 페이지의 커스터마이즈 정보들 */
  raffle_winner_page_customization?: Maybe<RaffleWinnerPageCustomization>;
};

export type RaffleAppliedPageCustomization = {
  custom_button: RaffleCustomButton;
};

export enum RaffleApplyStatus {
  ADDITIONAL_APPLY_AVAILABLE = 'ADDITIONAL_APPLY_AVAILABLE',
  APPLIED = 'APPLIED',
  UNAPPLIED = 'UNAPPLIED',
  WINNED = 'WINNED',
}

export enum RaffleConditionActionType {
  ACQUIRE_TICKET = 'ACQUIRE_TICKET',
  APPLY = 'APPLY',
}

export type RaffleConditionAdditionalInfo = {
  /** 래플 응모가능한 디파트먼트 조건(첫구매 래플 시) */
  department_type?: Maybe<RaffleDepartmentType>;
  /** 래플 응모 불가능한 유저가 응모 버튼을 눌렀을 시 노출할 텍스트 */
  fallback_text?: Maybe<Scalars['String']>;
  /** 래플 응모 불가능한 유저가 응모 버튼을 눌렀을 시 랜딩될 페이지 URL */
  fallback_url?: Maybe<Scalars['String']>;
  /** 래플 응모 가능한 유저가 조건을 충족하지 못하였을 시 랜딩될 페이지 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 최대 조건 값 */
  max_value?: Maybe<Scalars['Int']>;
  /** 최소 조건 값 */
  min_value?: Maybe<Scalars['Int']>;
};

export type RaffleConditionInfo = {
  /** 조건을 적용할 액션 타입 */
  action_type: RaffleConditionActionType;
  /** 참여조건 상세 정보 */
  additional_info?: Maybe<RaffleConditionAdditionalInfo>;
  /** 참여조건 타입 */
  condition_type: RaffleConditionType;
};

export enum RaffleConditionType {
  AGE_LIMIT = 'AGE_LIMIT',
  APP_PUSH = 'APP_PUSH',
  FIRST_ORDER = 'FIRST_ORDER',
  KAKAOTALK_PLUS = 'KAKAOTALK_PLUS',
  MARKETING_AGREEMENT = 'MARKETING_AGREEMENT',
  PERSONAL_AUTH = 'PERSONAL_AUTH',
  SHARE_AND_APPLY = 'SHARE_AND_APPLY',
  SNS_KAKAOTALK = 'SNS_KAKAOTALK',
}

export type RaffleCustomButton = {
  /** 버튼 랜딩 링크 */
  button_landing_url: Scalars['String'];
  /** 버튼 노출 문구 */
  button_text: Scalars['String'];
  /** 노출 종료일 */
  date_display_end: Scalars['CrTimestamp'];
  /** 노출 시작일 */
  date_display_start: Scalars['CrTimestamp'];
};

export enum RaffleDepartmentType {
  BEAUTY = 'BEAUTY',
  BRAND_FASHION = 'BRAND_FASHION',
  LIFE = 'LIFE',
  SOHO_FASHION = 'SOHO_FASHION',
}

export type RaffleList = {
  /** 검색된 Item list */
  item_list: Array<RaffleResponse>;
  /** 총 항목 수 */
  total_count: Scalars['Int'];
};

export type RaffleListInput = {
  /** 래플 응모종료일 검색 시작일 */
  date_apply_end_from?: InputMaybe<Scalars['CrTimestamp']>;
  /** 래플 응모시작일 검색 시작일 */
  date_apply_start_from?: InputMaybe<Scalars['CrTimestamp']>;
  /** 래플 응모시작일 검색 종료일 */
  date_apply_start_to?: InputMaybe<Scalars['CrTimestamp']>;
  /** 등록일 검색 시작일 */
  date_create_from?: InputMaybe<Scalars['CrTimestamp']>;
  /** 등록일 검색 종료일 */
  date_create_to?: InputMaybe<Scalars['CrTimestamp']>;
  /** 래플 ID 목록 */
  id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 반환 값 개수 제한 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 응모한 내역만 노출 */
  only_applied?: Scalars['Boolean'];
  /** 노출 가능 필터 */
  only_displayable?: Scalars['Boolean'];
  /** 정렬 조건(리스트 순서대로 정렬됨) */
  order_condition_list?: InputMaybe<Array<RaffleOrderCondition>>;
  /** 래플 상태 */
  raffle_status?: InputMaybe<RaffleStatus>;
  /** 건너뛸 개수 */
  skip_count?: InputMaybe<Scalars['Int']>;
  /** 래플 이름 */
  title?: InputMaybe<Scalars['String']>;
};

export enum RaffleOrderCondition {
  DATE_APPLY_END_DESC = 'DATE_APPLY_END_DESC',
  DATE_APPLY_START_DESC = 'DATE_APPLY_START_DESC',
  ID_DESC = 'ID_DESC',
  UPCOMING_DEADLINE = 'UPCOMING_DEADLINE',
}

export type RaffleResponse = {
  /** 등록일시 */
  date_created: Scalars['CrTimestamp'];
  /** 수정일시 */
  date_updated: Scalars['CrTimestamp'];
  /** 래플 정보 */
  raffle: Raffle;
};

export type RaffleReviewInfo = {
  /** 버튼 랜딩 링크 */
  button_landing_url: Scalars['String'];
  /** 버튼 노출 문구 */
  button_text: Scalars['String'];
};

export enum RaffleShareUrlType {
  DEEPLINK = 'DEEPLINK',
  KAKAOTALK_AIRBRIDGE_PATH = 'KAKAOTALK_AIRBRIDGE_PATH',
  WEB = 'WEB',
}

export enum RaffleStatus {
  APPLYING = 'APPLYING',
  BUYING = 'BUYING',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  SELECTING = 'SELECTING',
  STOPPED = 'STOPPED',
}

export type RaffleTicketInfo = {
  /** 보유한 응모권 카운트 */
  issued_ticket_count: Scalars['Int'];
  /** 사용한 응모권 카운트 */
  used_ticket_count: Scalars['Int'];
};

export type RaffleUserCondition = {
  /** 래플 응모 조건 관련 부가 정보 */
  additional_info?: Maybe<RaffleConditionAdditionalInfo>;
  /** 래플 응모 조건 충족 상태 */
  condition_status: RaffleUserConditionStatus;
  /** 래플 응모 조건 타입 */
  condition_type: RaffleConditionType;
};

export type RaffleUserConditionListInput = {
  raffle_id: Scalars['ID'];
};

export enum RaffleUserConditionStatus {
  MEET = 'MEET',
  NOT_MEET = 'NOT_MEET',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type RaffleWinnerPageCustomization = {
  custom_button: RaffleCustomButton;
};

export type RankingFluctuation = {
  color?: Maybe<UxCommonColor>;
  offset?: Maybe<Scalars['Int']>;
  ranking_fluctuation_status?: Maybe<Scalars['String']>;
};

export type ReSearch = {
  page_id?: Maybe<Scalars['String']>;
};

export type ReSearchInput = {
  page_id?: InputMaybe<Scalars['String']>;
};

/** 게임 티켓 수령 input */
export type ReceiveDailyMissionGameTicketInput = {
  /**
   * 게임 타입
   * [
   *   UPDOWN,
   * ]
   */
  game_type: Scalars['String'];
};

export type ReceiveMyPickCouponInput = {
  /** 수령할 Set ID */
  set_id: Scalars['ID'];
  /** 수령할 Set Item의 ID 리스트 */
  set_item_id_list: Array<Scalars['ID']>;
};

/** 수령자 정보 */
export type Receiver = {
  /** 주소 */
  address1: Scalars['String'];
  /** 상세 주소 */
  address2?: Maybe<Scalars['String']>;
  /** 마스킹 처리된 주소 */
  masked_address: Scalars['String'];
  /** 마스킹 처리된 상세주소 */
  masked_detail_address?: Maybe<Scalars['String']>;
  /** 마스킹 처리된 휴대폰 번호 */
  masked_mobile_tel: Scalars['String'];
  /** 마스킹 처리된 이름 */
  masked_name: Scalars['String'];
  /** 휴대폰 번호 */
  mobile_tel: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 우편번호 */
  postcode: Scalars['String'];
};

/** 수령자 정보 입력 */
export type ReceiverInput = {
  /** 주소 */
  address1: Scalars['String'];
  /** 상세 주소 */
  address2?: InputMaybe<Scalars['String']>;
  /** 도시 */
  city?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  contract_number?: InputMaybe<Scalars['String']>;
  /** 국가번호 */
  country_number?: InputMaybe<Scalars['String']>;
  /** 공동현관 비밀번호 */
  entrance_password?: InputMaybe<Scalars['String']>;
  /** 공동현관 출입 유형(비밀번호입력/자유출입) */
  entrance_type?: InputMaybe<EntranceType>;
  /** 수령인(이름) */
  first_name?: InputMaybe<Scalars['String']>;
  /** 수령인(성) */
  last_name?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 - deprecated("contract_number 로 대체") */
  mobile_tel: Scalars['String'];
  /** 이름 - deprecated("first_name 로 대체") */
  name: Scalars['String'];
  /** 개인통관고유부호 */
  personal_clearance_code?: InputMaybe<Scalars['String']>;
  /** 우편번호 */
  postcode: Scalars['String'];
  /** 공동현관 출입정보 배송지 저장여부 */
  save_entrance?: InputMaybe<Scalars['Boolean']>;
  /** 지역 */
  state?: InputMaybe<Scalars['String']>;
  /** 배송 주소록 아이디 */
  user_shipping_address_book_id?: InputMaybe<Scalars['ID']>;
};

/** 수령자 정보 입력 V2 */
export type ReceiverV2Input = {
  /** 주소 */
  address1?: InputMaybe<Scalars['String']>;
  /** 상세 주소 */
  address2?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  contract_number?: InputMaybe<Scalars['String']>;
  /** 국가번호 */
  country_number?: InputMaybe<Scalars['String']>;
  /** 수령인(이름) */
  first_name?: InputMaybe<Scalars['String']>;
  /** 수령인(성) */
  last_name?: InputMaybe<Scalars['String']>;
  /** 우편번호 */
  postcode?: InputMaybe<Scalars['String']>;
};

/** 활성화된 카테고리 */
export type RecentActivatedCategoryVersion = {
  /** 카테고리 리스트 */
  category_list: DisplayManagedCategory;
  /** id */
  id: Scalars['ID'];
};

/** 최근 본 상품 캐러셀 */
export type RecentBrowsedProductCarousel = {
  /** 상품 리스트 */
  item_list: RecentBrowsedProductCarouselItemList;
  /** 더보기 버튼 */
  more_button?: Maybe<UxCommonButton>;
  /** 상품 이미지만 표시할지 여부 */
  thumbnail_only: Scalars['Boolean'];
  /** 제목 */
  title?: Maybe<UxCommonText>;
};

/** 최근 본 상품 캐러셀 상품 리스트 */
export type RecentBrowsedProductCarouselItemList = {
  /** 더보기 버튼 표시 여부 */
  is_show_more_button: Scalars['Boolean'];
  /** 상품 리스트 */
  item_list: Array<UxGoodsCardItem>;
  /** 더보기 버튼 랜딩 URL */
  landing_url: Scalars['String'];
};

/** recertification 애플 방식의 입력 */
export type RecertificationAppleInput = {
  /**
   * 접근하려는 페이지 (타입, url 등의 값이 들어갈 수 있음)
   * verifyRecertification 호출시 넘겨주는 값과 동일해야한다.
   */
  access_page: Scalars['String'];
  /** 애플 bundle id */
  bundle_id: Scalars['String'];
  /** 애플 인증 후 발급 code */
  code: Scalars['String'];
};

/** recertificationPassword의 입력 */
export type RecertificationPasswordInput = {
  /**
   * 접근하려는 페이지 (타입, url 등의 값이 들어갈 수 있음)
   * verifyRecertification 호출시 넘겨주는 값과 동일해야한다.
   */
  access_page: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
};

/** 사용자 자격검증에 대한 공통응답 */
export type RecertificationResponse = {
  /** 자격검증여부 */
  result: Scalars['Boolean'];
};

/** recertification 소셜 방식의 입력 */
export type RecertificationSocialInput = {
  /**
   * 접근하려는 페이지 (타입, url 등의 값이 들어갈 수 있음)
   * verifyRecertification 호출시 넘겨주는 값과 동일해야한다.
   */
  access_page: Scalars['String'];
  /** 소셜 인증 후 발급 토큰 */
  access_token: Scalars['String'];
};

export type RecommendModel = {
  model: Scalars['String'];
  score: Scalars['String'];
};

export type RecommendProductAdvertisementInfo = {
  log?: Maybe<Scalars['String']>;
};

export type RecommendProductBadge = {
  badge_type: RecommendProductBadgeType;
  dark_image_url?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_url?: Maybe<Scalars['String']>;
};

export type RecommendProductBadge2 = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum RecommendProductBadgeType {
  BOGO = 'BOGO',
  FREE_SHIPPING = 'FREE_SHIPPING',
}

export type RecommendProductDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum RecommendProductDisplayType {
  CART_01 = 'CART_01',
  CART_02 = 'CART_02',
  CART_03 = 'CART_03',
  CART_04 = 'CART_04',
  CART_05 = 'CART_05',
  CART_ORDERSHEET_01 = 'CART_ORDERSHEET_01',
  CART_ORDERSHEET_02 = 'CART_ORDERSHEET_02',
  CART_ORDERSHEET_03 = 'CART_ORDERSHEET_03',
  CART_ORDERSHEET_04 = 'CART_ORDERSHEET_04',
  CART_ORDERSHEET_05 = 'CART_ORDERSHEET_05',
  CART_ORDERSHEET_06 = 'CART_ORDERSHEET_06',
  CART_ORDERSHEET_07 = 'CART_ORDERSHEET_07',
  CART_SOLDOUT_PRODUCT = 'CART_SOLDOUT_PRODUCT',
  ORDER_COMPLETED_01 = 'ORDER_COMPLETED_01',
  ORDER_COMPLETED_02 = 'ORDER_COMPLETED_02',
  ORDER_COMPLETED_03 = 'ORDER_COMPLETED_03',
  ORDER_COMPLETED_04 = 'ORDER_COMPLETED_04',
  ORDER_COMPLETED_05 = 'ORDER_COMPLETED_05',
  ORDER_COMPLETED_06 = 'ORDER_COMPLETED_06',
  ORDER_DETAIL_01 = 'ORDER_DETAIL_01',
}

export type RecommendProfile = {
  profile: EPickProfile;
};

export type RecommendProfileListResponse = {
  item_list: Array<RecommendProfile>;
};

export type RecommendedActivePromotionProductListInput = {
  /**
   * 추천상품 타입
   * SCORE - 당첨확률순(우선순위 높은순),
   * DEADLINE - 마감임박순
   */
  recommend_type: PromotionProductRecommendType;
};

export type RecommendedPromotionProductList = {
  item_list: Array<PromotionProduct>;
};

export type RecommendedSearchKeyword = {
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 이미지/아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 이미지/아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export type RecommendedSearchKeywordBadge = {
  background_color: Scalars['String'];
  background_opacity: Scalars['Float'];
  border?: Maybe<RecommendedSearchKeywordBadgeBorder>;
  /** 폰트 굵기(REGULAR, MEDIUM, SEMI_BOLD, BOLD) */
  font_weight: Scalars['String'];
  /** MEDIUM, SMALL, XSMALL */
  size: Scalars['String'];
  text: Scalars['String'];
  text_color: Scalars['String'];
};

export type RecommendedSearchKeywordBadgeBorder = {
  color: Scalars['String'];
  /** 테두리 둥글기(px) */
  radius: Scalars['Float'];
  /** 테두리 스타일 (ex. solid, dashed) */
  style: Scalars['String'];
  /** 테두리 두께(px) */
  width: Scalars['Float'];
};

/** 추천 검색어 목록 */
export type RecommendedSearchKeywordList = {
  item_list: Array<RecommendedSearchKeyword>;
  total_count: Scalars['Int'];
};

/** 검색어 타입 */
export enum RecommendedSearchKeywordType {
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  CATEGORY_LANDING = 'CATEGORY_LANDING',
  EVENT = 'EVENT',
  PICK = 'PICK',
  SEARCHED_KEYWORD_HISTORY = 'SEARCHED_KEYWORD_HISTORY',
  SHOP = 'SHOP',
  TAG_SHOP = 'TAG_SHOP',
  USER_QUERY = 'USER_QUERY',
}

/** 계좌 정보 */
export type RefundBankAccount = {
  /** 은행 코드 */
  code?: Maybe<Scalars['String']>;
  /** 예금주 이름 */
  holder?: Maybe<Scalars['String']>;
  /** 계좌 번호 */
  number?: Maybe<Scalars['String']>;
};

/** 환불 계좌 정보 입력 */
export type RefundBankAccountInput = {
  /** 은행 코드 */
  code: Scalars['String'];
  /** 예금주 이름 */
  holder: Scalars['String'];
  /** 계좌 번호 */
  number: Scalars['String'];
};

export type RefundDeductionCosts = {
  /** 무료배송 해지 비용 */
  free_shipping_breaking_fee: Scalars['Int'];
  /** 최초 배송비 차감액 */
  initial_shipping_fee: Scalars['Int'];
  /** 반품 배송비(취소 요청일 때는 0을 반환) */
  return_shipping_fee: Scalars['Int'];
  /** 쿠폰 깨짐비 차감액 */
  shipping_fee_coupon_breaking_fee: Scalars['Int'];
  /** 총 차감액 */
  total_fee: Scalars['Int'];
};

export type RefundDeductionShippingFeeDetail = {
  initial_shipping_fee_cash_amount: Scalars['Int'];
  initial_shipping_fee_point_amount: Scalars['Int'];
  return_shipping_fee_cash_amount: Scalars['Int'];
  return_shipping_fee_point_amount: Scalars['Int'];
};

export type RefundEncloseCosts = {
  /** 무료배송 해지 비용 */
  free_shipping_breaking_fee: Scalars['Int'];
  /** 최초 배송비 차감액 */
  initial_shipping_fee: Scalars['Int'];
  /** 반품 배송비(취소 요청일 때는 0을 반환) */
  return_shipping_fee: Scalars['Int'];
  /** 총 차감액 */
  total_fee: Scalars['Int'];
};

/** 환불 방법 */
export type RefundPayment = {
  /** 환불 계좌 정보 */
  bank_account?: Maybe<RefundBankAccount>;
  /** 환불 수단 */
  method?: Maybe<RefundPaymentMethod>;
};

/** 환불 방법 입력 */
export type RefundPaymentInput = {
  /** 환불 계좌 정보 */
  bank_account?: InputMaybe<RefundBankAccountInput>;
  /** 환불 수단 */
  method: RefundPaymentMethod;
};

/** 환불 수단 */
export enum RefundPaymentMethod {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export type RefundRequestedAmounts = {
  /** 환불 요청 금액(상품액 zpay, 프로모션 적용가) */
  items_amount: Scalars['Int'];
  /** 환불 요청 금액(배송비) */
  shipping_fee: Scalars['Int'];
  /** 환불 요청 금액(쿠폰 깨짐비 환불) */
  shipping_fee_coupon: Scalars['Int'];
  /** 쿠폰 깨짐비 환급금 */
  shipping_fee_coupon_breaking_fee: Scalars['Int'];
  /** 환불 요청 금액(상품액 + 배송비) */
  total_amount: Scalars['Int'];
  /** 환불 요청 금액(상품원가) */
  total_product_price: Scalars['Int'];
};

export type RefundTotalAmounts = {
  /** 환불 금액(포인트, 마일리지를 제외) */
  cash_amount: Scalars['Int'];
  /** 환불 마일리지 */
  mileage_amount: Scalars['Int'];
  /** 환불 포인트 */
  point_amount: Scalars['Int'];
  /** 환불 금액 + 환불 포인트 + 환불 마일리지 */
  total_amount: Scalars['Int'];
};

/** registerRewardUserAccountChannel 의 input */
export type RegisterRewardUserAccountChannelInput = {
  /** 채널 목록 */
  channel_list: Array<RewardUserAccountChannelInput>;
  /** 초대자 레퍼럴 코드 */
  inviter_referral_code?: InputMaybe<Scalars['String']>;
};

/** RegisterSimpleBank 입력 */
export type RegisterSimpleBankInput = {
  /** 계좌 간편 결제 인증 ID */
  simple_bank_cert_id: Scalars['ID'];
};

/** 계좌 등록 결과 */
export type RegisterSimpleBankResult = {
  /** 간편결제계좌 UUID */
  simple_bank_uuid: Scalars['String'];
};

/** RegisterSimplePayPassword 입력 */
export type RegisterSimplePayPasswordInput = {
  /** 패스워드 */
  password: Scalars['String'];
};

export type RegisterZibetLinkPriceOrderInput = {
  order_number: Scalars['String'];
};

export type RenewQuickProductReviewInput = {
  /** 상품리뷰 첨부파일 목록 */
  attachment_list?: InputMaybe<Array<CreateProductReviewAttachmentInput>>;
  /**
   * 리뷰 첨부파일 URL 목록
   * deprecated: attachment_list 사용
   */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 상품리뷰 특성 평가의 배열 */
  attribute_list: Array<CreateProductReviewInputAttribute>;
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 리뷰 레코드 ID */
  id: Scalars['ID'];
  /** 리뷰 평점 */
  rating: Scalars['Int'];
};

export type RenewQuickProductReviewResult = {
  /** 전환된 상품 리뷰의 레코드 ID */
  id: Scalars['ID'];
  /** 첫 리뷰 작성 여부 */
  is_first_review: Scalars['Boolean'];
  /** 발급된 마일리지 */
  mileage_issued: Scalars['Int'];
  /** 발급된 포인트 */
  point_issued: Scalars['Int'];
  /** 상품 리뷰 */
  product_review: ProductReview;
  /** 상품 리뷰 첨부파일 유형 */
  type: ProductReviewType;
};

export type ReportEPickPostResponse = {
  reported_profile_id: Scalars['ID'];
};

export type ReportEPickReplyResponse = {
  reported_profile_id: Scalars['ID'];
};

export type ReportEventContentReplyInput = {
  /** 이벤트 댓글 신고 내용 */
  comment: Scalars['String'];
  /** 이벤트 댓글 메타데이터 uuid */
  event_content_reply_metadata_uuid: Scalars['String'];
  /** 이벤트 댓글 ID */
  reply_id: Scalars['ID'];
  /** 이벤트 댓글 신고 타입 */
  report_type: EventContentReplyReportCommentType;
};

export type ReportExternalProductReviewAbuseInput = {
  /** 외부 상품리뷰 ID */
  external_product_review_id: Scalars['ID'];
  /** 상품리뷰 신고 사유 카테고리 */
  reported_reason_category: ProductReviewAbuseReportReasonCategory;
  /** 상품리뷰 신고 상세 사유 */
  reported_reason_detail?: InputMaybe<Scalars['String']>;
};

/** reportProductReviewAbuse의 입력 */
export type ReportProductReviewAbuseInput = {
  /** 상품리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
  /** 상품리뷰 신고 사유 카테고리 */
  reported_reason_category: ProductReviewAbuseReportReasonCategory;
  /** 상품리뷰 신고 상세 사유 */
  reported_reason_detail?: InputMaybe<Scalars['String']>;
};

/** ReportProductReviewUserReply의 입력 */
export type ReportProductReviewUserReplyInput = {
  /** 작성자 차단 여부 */
  include_user_account: Scalars['Boolean'];
  /** 상품리뷰 댓글 ID */
  product_review_user_reply_id: Scalars['ID'];
  /** 상품리뷰 댓글 신고 사유 카테고리 */
  reported_reason_category?: InputMaybe<ProductReviewReplyAbuseReportReasonCategory>;
  /** 상품리뷰 댓글 신고 상세 사유 */
  reported_reason_detail?: InputMaybe<Scalars['String']>;
};

export type ReportTalkLoungeReplyInput = {
  reason: Scalars['String'];
  reply_id: Scalars['ID'];
};

export type ReportTalkLoungeTalkInput = {
  reason: Scalars['String'];
  talk_id: Scalars['ID'];
};

export type RequestAdProductExposureInput = {
  /** 상품 ID 목록 */
  product_id_list: Array<Scalars['ID']>;
};

export type RequestAdProductNonExposureInput = {
  /** 상품 ID 목록 */
  product_id_list: Array<Scalars['ID']>;
};

/** 이벤트용 휴대폰 인증토큰 요청 입력 */
export type RequestAuthenticationTokenForEventInput = {
  /** 이벤트 타입 */
  event_type: EventType;
  /** 동일한 번호가 설정되어 있는지 체크를 무시할지 여부 */
  ignore_exist?: InputMaybe<Scalars['Boolean']>;
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 서브 이벤트 타입 */
  sub_event_type?: InputMaybe<Scalars['String']>;
};

/** RequestAuthenticationTokenForEventResult 결과 */
export type RequestAuthenticationTokenForEventResult = {
  /** 이미 존재하는 이메일 */
  already_email?: Maybe<Scalars['String']>;
  /** 요청 결과 */
  success: Scalars['Boolean'];
};

/** requestEmailAuthentication의 입력 */
export type RequestEmailAuthenticationInput = {
  /** email */
  email: Scalars['String'];
  /**
   * 이메일 인증 목적
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type: Scalars['String'];
};

/** requestEmailAuthentication의 결과 */
export type RequestEmailAuthenticationResult = {
  /** 토큰 유효 시간 (단위: 초) */
  expire_sec: Scalars['Int'];
  /** 인증 요청 결과 */
  result: Scalars['Boolean'];
};

/** requestExchangeOrderItemList의 입력 */
export type RequestExchangeOrderItemListInput = {
  /** 첨부파일 URL 목록 */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 교환 전 상품 수거 방법 */
  collecting_type: CollectingType;
  /** 추가금액 지불 방식 정보 */
  extra_additional_fee_charge_method: ExtraAdditionalFeeChargeMethod;
  /** 운송장 번호(이미 유저가 교환 상품을 셀러에게 보낸 경우) */
  invoice_number?: InputMaybe<Scalars['String']>;
  /** 교환하고자 하는 상품 주문 정보 목록 */
  new_exchange_product_item_list: Array<ExchangeProductItemInput>;
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 수령자 정보 */
  receiver: ReceiverInput;
  /** 환불 계좌 */
  refund_payment?: InputMaybe<RefundPaymentInput>;
  /** 요청 사유 */
  requested_reason?: InputMaybe<Scalars['String']>;
  /** 요청 사유 카테고리 */
  requested_reason_category: RequestedReasonCategory;
  /** 택배사(이미 유저가 교환 상품을 셀러에게 보낸 경우) */
  shipping_company?: InputMaybe<Scalars['String']>;
  /** 배송비 추가 지불방식 정보 */
  shipping_fee_additional_charge_method: ShippingFeeAdditionalChargeMethodInput;
  /** 배송비 그룹의 ID */
  shipping_group_id?: InputMaybe<Scalars['ID']>;
  /** 배송 메모 */
  shipping_memo?: InputMaybe<Scalars['String']>;
  /** 쇼핑몰 도메인 */
  shop_main_domain?: InputMaybe<Scalars['String']>;
};

/** 본인인증으로 비밀번호 재설정 요청을 위한 입력값 */
export type RequestForResetPasswordWithAuthenticationInput = {
  /** 본인인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key: Scalars['String'];
};

/**
 * 본인인증으로 비밀번호 재설정 요청을 위한 검증값
 * - mutation: requestForResetPasswordWithAuthentication
 * - mutation: requestForResetPasswordWithMobileAuthentication
 */
export type RequestForResetPasswordWithAuthenticationResult = {
  /** 이메일 정보 */
  email: Scalars['String'];
  /** 비밀번호 재설정시 유효한 토큰 */
  token: Scalars['String'];
};

/** requestForResetPasswordWithEmailAuthentication의 입력 */
export type RequestForResetPasswordWithEmailAuthenticationInput = {
  /** 인증키 */
  authenticated_key: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
};

/** requestForResetPasswordWithMobileAuthentication 입력 */
export type RequestForResetPasswordWithMobileAuthenticationInput = {
  /** 입력받은 이메일 주소 */
  email: Scalars['String'];
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 휴대폰 번호 인증 토큰 */
  token: Scalars['String'];
};

/** requestKakaoStudentPresentProof 입력값 */
export type RequestKakaoStudentPresentProofInput = {
  /** 제출받을 정보를 정의한 uuid 형태의 id (카카오 제공) */
  presentation_definition_id: Scalars['String'];
};

/** requestKakaoStudentPresentProof 응답값 */
export type RequestKakaoStudentPresentProofResult = {
  /** 제출 앱스킴 url */
  present_app_url: Scalars['String'];
  /** 제출 웹 url */
  present_web_url: Scalars['String'];
  /** 제출 요청 ID */
  request_id: Scalars['String'];
};

/** 카카오뱅크 본인인증 진행시 필요값 */
export type RequestKakaobankAuthenticationInput = {
  /**
   * 카카오뱅크 본인인증 요청 방법
   * - APP_PUSH: 앱푸시 (default)
   * - APP_TO_APP: 앱투앱
   */
  app_invoke_method?: InputMaybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 휴대폰번호('-' 제외) */
  phone: Scalars['String'];
  /**
   * 본인인증 목적
   * - ACCOUNT: 계정인증
   * - FIND_ACCOUNT: 계정찾기
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type?: InputMaybe<Scalars['String']>;
};

/** 카카오뱅크 본인인증 요청 후 필요 값 */
export type RequestKakaobankAuthenticationResult = {
  /**
   * 카카오뱅크인증서 앱스킴
   * (app_invoke_method 가 "APP_TO_APP" 인 경우 제공)
   */
  app_scheme?: Maybe<Scalars['String']>;
  /**
   * 카카오뱅크 원링크 URL
   * (app_invoke_method 가 "APP_PUSH" 인 경우 제공)
   */
  onelink_url?: Maybe<Scalars['String']>;
  /** 트랜잭션 키 (본인인증 결과 체크시 사용) */
  trx_key: Scalars['String'];
};

/** requestMobileAuthentication의 입력 */
export type RequestMobileAuthenticationInput = {
  /** 앱 해시 문자열 (for AOS) */
  app_hash_string?: InputMaybe<Scalars['String']>;
  /** 인증시간 만료 초 (기본값 120초) */
  expire_time_sec?: InputMaybe<Scalars['Int']>;
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /**
   * 점유인증 목적
   * - ACCOUNT: 계정인증
   * - FIND_ACCOUNT: 계정찾기
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type?: InputMaybe<Scalars['String']>;
  /** 사이트 아이디 */
  site_id?: InputMaybe<Scalars['ID']>;
  /** 유저 IP */
  user_ip?: InputMaybe<Scalars['String']>;
};

/** requestMobileAuthentication의 응답값 */
export type RequestMobileAuthenticationResult = {
  /** 토큰 유효 시간 (단위: 초) */
  expire_sec: Scalars['Int'];
  /** 요청결과 */
  result: Scalars['Boolean'];
};

/** requestReturnOrder의 입력 */
export type RequestReturnOrderInput = {
  /** 첨부파일 URL 목록 */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 반품 수거 방법 */
  collecting_type: CollectingType;
  /** 송장번호 */
  invoice_number?: InputMaybe<Scalars['String']>;
  /** 요청 목록 */
  item_list: Array<OrderItemRequestListItemInput>;
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 환불 방법 */
  refund_payment?: InputMaybe<RefundPaymentInput>;
  /** 유저가 직접 반품 택배를 보낼 시 택배사 */
  shipping_company?: InputMaybe<Scalars['String']>;
  /** 배송비 추가 지불방식 정보 */
  shipping_fee_additional_charge_method: ShippingFeeAdditionalChargeMethodInput;
  /** 배송비 그룹의 ID */
  shipping_group_id?: InputMaybe<Scalars['ID']>;
};

/** requestSimpleBankCert 입력 */
export type RequestSimpleBankCertInput = {
  /** 계좌 인증 정보 ID */
  simple_bank_cert_id: Scalars['ID'];
};

/** 계좌 인증 재요청 결과 */
export type RequestSimpleBankCertResult = {
  /** 새 계좌 인증 정보 ID */
  simple_bank_cert_id: Scalars['ID'];
};

/** requestUserAccountAuthentication의 입력 */
export type RequestUserAccountAuthenticationInput = {
  /**
   * 본인인증 목적
   * - ACCOUNT: 계정인증
   * - FIND_ACCOUNT: 계정찾기
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type?: InputMaybe<Scalars['String']>;
};

/** RequestUserAccountAuthentication의 결과 */
export type RequestUserAccountAuthenticationResult = {
  /** 인증 정보 */
  tr_cert: Scalars['String'];
};

/** requestUserAccountPasswordReset의 입력 */
export type RequestUserAccountPasswordResetInput = {
  /** 이메일 */
  email: Scalars['String'];
};

/** requestUserPay의 입력 */
export type RequestUserPayInput = {
  /** 앱 스키마 */
  app_schema?: InputMaybe<Scalars['String']>;
  /** 카드 cvc */
  card_cvc?: InputMaybe<Scalars['String']>;
  /** 카드 번호 만료 기간 */
  card_expiry_date_yymm?: InputMaybe<Scalars['String']>;
  /** 카드 번호 */
  card_number?: InputMaybe<Scalars['String']>;
  /** 현금영수증 발행 정보 */
  cash_receipt_identity?: InputMaybe<Scalars['String']>;
  /** 현금영수증 발행 정보 타입 */
  cash_receipt_identity_type?: InputMaybe<PaymentCashReceiptIdentityType>;
  /** 현금영수증 발행 용도 */
  cash_receipt_purpose?: InputMaybe<PaymentCashReceiptPurpose>;
  /** 체크아웃 uuid */
  checkout_uuid: Scalars['String'];
  /** 주문 실패 페이지 */
  fail_redirect_url?: InputMaybe<Scalars['String']>;
  /** 금융사 정보 */
  financial_company?: InputMaybe<UserPayFinancialCompanyInput>;
  /** 할부 개월 */
  installment_month?: InputMaybe<Scalars['Int']>;
  /** 주문자 이메일 */
  orderer_email?: InputMaybe<Scalars['String']>;
  /** 주문자 전화번호 */
  orderer_tel?: InputMaybe<Scalars['String']>;
  /** 결제 이벤트 정보 */
  payment_event?: InputMaybe<PaymentEventInput>;
  /** 결제 수단 */
  payment_method: UserPayPaymentMethod;
  /** 상품 유형 목록 */
  payment_product_type_list?: InputMaybe<Array<InputMaybe<PaymentProductType>>>;
  /** 주문 완료 페이지 */
  redirect_url?: InputMaybe<Scalars['String']>;
  /** 배송지 정보 */
  shipping_address?: InputMaybe<UserPayShippingAddressRequestUserPayInput>;
  /** 간편결제계좌 UUID */
  simple_bank_uuid?: InputMaybe<Scalars['String']>;
  /** 간편결제계좌 비밀번호 */
  simple_pay_password?: InputMaybe<Scalars['String']>;
  /** 카드사 쿠폰 사용여부 */
  usable_coupon?: InputMaybe<Scalars['Boolean']>;
  /** 에스크로 결제 여부 */
  usable_escrow?: InputMaybe<Scalars['Boolean']>;
  /** 카드사 무이자할부 사용여부 */
  usable_interest_free?: InputMaybe<Scalars['Boolean']>;
  /** 카드사 포인트 사용여부 */
  usable_point?: InputMaybe<Scalars['Boolean']>;
  /** 유저 DI */
  user_account_di?: InputMaybe<Scalars['String']>;
};

/** requestUserPay의 반환값 */
export type RequestUserPayResult = {
  /** 주문번호 */
  order_number: Scalars['String'];
  /** 결제 uuid */
  payment_uuid: Scalars['String'];
  /** 결제 페이지 주소 */
  redirect_url: Scalars['String'];
};

export enum RequestedReasonCategory {
  CANCEL_AFTER_CONFIRMED_DEFECTIVE_PRODUCT = 'CANCEL_AFTER_CONFIRMED_DEFECTIVE_PRODUCT',
  CANCEL_AFTER_CONFIRMED_DIFFRENT_FROM_ORDERED = 'CANCEL_AFTER_CONFIRMED_DIFFRENT_FROM_ORDERED',
  CANCEL_AFTER_CONFIRMED_DIFFRENT_FROM_PRODUCT_INFO = 'CANCEL_AFTER_CONFIRMED_DIFFRENT_FROM_PRODUCT_INFO',
  CANCEL_AFTER_CONFIRMED_MISORDER = 'CANCEL_AFTER_CONFIRMED_MISORDER',
  CANCEL_AFTER_CONFIRMED_NO_LONGER_WANTED = 'CANCEL_AFTER_CONFIRMED_NO_LONGER_WANTED',
  CANCEL_AFTER_CONFIRMED_SERVICE_DISSATISFIED = 'CANCEL_AFTER_CONFIRMED_SERVICE_DISSATISFIED',
  CANCEL_AFTER_CONFIRMED_SHIPPING_DELAY = 'CANCEL_AFTER_CONFIRMED_SHIPPING_DELAY',
  CANCEL_CROQUIS_RESPONSIBILITY = 'CANCEL_CROQUIS_RESPONSIBILITY',
  CANCEL_ETC_BY_SELLER = 'CANCEL_ETC_BY_SELLER',
  CANCEL_ETC_BY_USER = 'CANCEL_ETC_BY_USER',
  CANCEL_INVALID_PRODUCT_INFO = 'CANCEL_INVALID_PRODUCT_INFO',
  CANCEL_INVALID_PRODUCT_RECEIVING = 'CANCEL_INVALID_PRODUCT_RECEIVING',
  CANCEL_MISORDER = 'CANCEL_MISORDER',
  CANCEL_MISSELECT_PRODUCT = 'CANCEL_MISSELECT_PRODUCT',
  CANCEL_MISSING_PRODUCT = 'CANCEL_MISSING_PRODUCT',
  CANCEL_NO_LONGER_WANTED = 'CANCEL_NO_LONGER_WANTED',
  CANCEL_PRICE_DISSATISFIED = 'CANCEL_PRICE_DISSATISFIED',
  CANCEL_SERVICE_DISSATISFIED = 'CANCEL_SERVICE_DISSATISFIED',
  CANCEL_SHIPPING_DELAY = 'CANCEL_SHIPPING_DELAY',
  CANCEL_SHIPPING_DELAY_DISSATISFIED = 'CANCEL_SHIPPING_DELAY_DISSATISFIED',
  CANCEL_SHIPPING_LONG_TERM_DELAY = 'CANCEL_SHIPPING_LONG_TERM_DELAY',
  CANCEL_SHIPPING_WILL_DELAY = 'CANCEL_SHIPPING_WILL_DELAY',
  CANCEL_SOLD_OUT = 'CANCEL_SOLD_OUT',
  CANCEL_UNABLE_EXPORT_DECLARATION = 'CANCEL_UNABLE_EXPORT_DECLARATION',
  CANCEL_WILL_REORDER = 'CANCEL_WILL_REORDER',
  EXCHANGE_DEFECTIVE_PRODUCT = 'EXCHANGE_DEFECTIVE_PRODUCT',
  EXCHANGE_DIFFRENT_FROM_ORDERED = 'EXCHANGE_DIFFRENT_FROM_ORDERED',
  EXCHANGE_MISORDER = 'EXCHANGE_MISORDER',
  EXCHANGE_NO_LONGER_WANTED = 'EXCHANGE_NO_LONGER_WANTED',
  RETURN_DEFECTIVE_PRODUCT = 'RETURN_DEFECTIVE_PRODUCT',
  RETURN_DIFFRENT_FROM_ORDERED = 'RETURN_DIFFRENT_FROM_ORDERED',
  RETURN_DIFFRENT_FROM_PRODUCT_INFO = 'RETURN_DIFFRENT_FROM_PRODUCT_INFO',
  RETURN_MISORDER = 'RETURN_MISORDER',
  RETURN_MISSING_PRODUCT = 'RETURN_MISSING_PRODUCT',
  RETURN_NO_LONGER_WANTED = 'RETURN_NO_LONGER_WANTED',
  RETURN_SERVICE_DISSATISFIED = 'RETURN_SERVICE_DISSATISFIED',
  RETURN_SHIPPING_DELAY = 'RETURN_SHIPPING_DELAY',
}

/** resetPasswordWithEmail 의 입력 */
export type ResetPasswordWithEmailInput = {
  /** 인증된 값으로 받은 계정 이메일 */
  email: Scalars['String'];
  /** 비밀번호 */
  password: Scalars['String'];
  /** 비밀번호 초기화 토큰 */
  token: Scalars['String'];
};

/** ResetSimplePayPassword 입력 */
export type ResetSimplePayPasswordInput = {
  /** 인증 ID */
  authenticate_id: Scalars['String'];
  /** 새 패스워드 */
  new_password: Scalars['String'];
};

/** resetUserAccountPassword의 입력 */
export type ResetUserAccountPasswordInput = {
  /** 비밀번호 */
  password: Scalars['String'];
  /** 비밀번호 초기화 토큰 */
  token: Scalars['String'];
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 메타 데이터 조회 결과
 */
export type ResultPromotion202104Metadata = {
  /** 2탭 쿠폰 */
  coupon: Promotion202104MetadataCoupon;
  /** 이벤트 종료일시 */
  date_ended: Scalars['CrTimestamp'];
  /** 이벤트 시작일시 */
  date_started: Scalars['CrTimestamp'];
  /** 서버타임 */
  server_time: Scalars['CrTimestamp'];
  status: Promotion202104MetadataStatus;
  /** 타임딜 일자별 정보 */
  time_deal_daily: Array<Promotion202104MetadataTimeDealDaily>;
  /** Top 100정보 */
  top100: Promotion202104MetadataTop100;
  /** 유저 공유 랭킹 */
  user_ranking: Array<Promotion202104MetadataUserRanking>;
};

/**
 * 직잭랭킹페스타(2021년 4월 프로모션)
 * 1탭 Top100 랭킹 목록 조회 결과
 */
export type ResultPromotion202104RankingResult = {
  /** 최근 데이터 갱신 일시 */
  date_latest_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 아이템 목록 */
  item_list: Array<Maybe<Promotion202104RankingItem>>;
  /** 총 아이템 수 */
  total_count: Scalars['Int'];
};

export type ReviewBadgeBorder = {
  border_color: ReviewCommonColor;
  width: Scalars['Float'];
};

/** 배너 배경 정보 */
export type ReviewBannerBackground = {
  /** 세로 / 가로 비율 */
  aspect_ratio?: Maybe<Scalars['Float']>;
  /** 배경 색상 */
  color?: Maybe<ReviewBannerColor>;
  /** 이미지 URL */
  image_url?: Maybe<ReviewBannerImageUrl>;
  /** 배경 radius */
  radius?: Maybe<Scalars['Float']>;
};

/** 배너 색상 정보 */
export type ReviewBannerColor = {
  /** 다크 모드 색상 */
  dark?: Maybe<Scalars['String']>;
  /** 일반 모드 색상 */
  normal: Scalars['String'];
};

/** 배너 html 정보 */
export type ReviewBannerHtml = {
  /** 다크 모드 url */
  dark?: Maybe<Scalars['String']>;
  /** 일반 모드 url */
  normal: Scalars['String'];
};

/** 배너 아이콘 정보 */
export type ReviewBannerIcon = {
  /** 아이콘 높이 */
  height: Scalars['Int'];
  /** 이미지 URL */
  image_url: ReviewBannerImageUrl;
  /** 아이콘 너비 */
  width: Scalars['Int'];
};

/** 배너 이미지 정보 */
export type ReviewBannerImageUrl = {
  /** 다크 모드 url */
  dark?: Maybe<Scalars['String']>;
  /** 일반 모드 url */
  normal: Scalars['String'];
};

/** 배너 텍스트 정보 */
export type ReviewBannerText = {
  /** 배경 색상 */
  background_color?: Maybe<ReviewBannerColor>;
  /** 배경 radius */
  background_radius?: Maybe<Scalars['Float']>;
  /** 텍스트 색상 */
  color?: Maybe<ReviewBannerColor>;
  /** html */
  html?: Maybe<ReviewBannerHtml>;
  /** 문구 */
  text: Scalars['String'];
};

/** 리뷰 이벤트 배너 타입 */
export enum ReviewBannerType {
  CARD = 'CARD',
  FULL_WIDTH = 'FULL_WIDTH',
  LARGE_TOP_CARD = 'LARGE_TOP_CARD',
  LIST_GROUPED = 'LIST_GROUPED',
}

/** 보너스 포인트 지급 조건 */
export type ReviewBonusPointCondition = {
  /** 위 조건을 만족할 시 추가적으로 적립되는 마일리지 */
  bonus_mileage: Scalars['Int'];
  /** 위 조건을 만족할 시 추가적으로 적립되는 금액 */
  bonus_point: Scalars['Int'];
  /** 최대 리뷰 길이(최대 길이제한이 없는 ~자 이상일 경우 max_length = Int.MAX_VALUE) */
  max_length: Scalars['Int'];
  /** 최소 리뷰 길이(최소 길이제한이 없는 ~자 이하일 경우 min_length = 0) */
  min_length: Scalars['Int'];
  /** 사진 첨부시 추가 보너스 마일리지 */
  photo_bonus_mileage: Scalars['Int'];
  /** 사진 첨부시 추가 보너스 포인트 */
  photo_bonus_point: Scalars['Int'];
};

/** 리뷰 작성시 발급 포인트 정보 */
export type ReviewBonusPointGroup = {
  /** 보너스 포인트 지급 조건 */
  bonus_point_conditions: Array<ReviewBonusPointCondition>;
  /** 그룹 내 최대 지급 가능 마일리지 */
  max_bonus_mileage: Scalars['Int'];
  /** 그룹 내 최대 지급 가능 포인트 */
  max_bonus_point: Scalars['Int'];
};

/** 체크옵션 리스트 컴포넌트 */
export type ReviewCheckOptionList = ReviewComponent & {
  /** 화면에 표시할 타이틀 */
  display_title: Scalars['String'];
  /** 옵션 key값. ex) bottom_size, upper_size, shoe_size, skin_tone, etc. */
  key: Scalars['String'];
  /** 옵션 리스트 */
  option_list: Array<ReviewOption>;
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 선택한 옵션 리스트. 내 맞춤정보 필터 기능을 사용하기 위해서 서버에서 내려줘야 한다. */
  selected_option_id_list: Array<Scalars['ID']>;
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type ReviewCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type ReviewCommonText = {
  color?: Maybe<ReviewCommonColor>;
  text: Scalars['String'];
};

/** 리뷰 컴포넌트 */
export type ReviewComponent = {
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type ReviewCustomFilterQueryInput = {
  /** 내 맞춤정보 필터 체크여부 */
  my_custom_filter_checked: Scalars['Boolean'];
  /** 쿼리할 리뷰 옵션 리스트 */
  option_list: Array<ReviewOptionInput>;
  /** 상품 아이디 */
  product_id: Scalars['ID'];
};

/** 리뷰 이벤트 선정시 적립 가능 포인트 정보 */
export type ReviewEventPoint = {
  /** 리뷰 이벤트 지급 마일리지 */
  max_mileage: Scalars['Int'];
  /** 리뷰 이벤트 지급 포인트 */
  max_point: Scalars['Int'];
  /** 리뷰 이벤트 타입 */
  type: ProductReviewSellerEventType;
};

/** 리뷰 옵션 컴포넌트 */
export type ReviewOption = ReviewComponent & {
  /** 옵션 id 값 */
  id: Scalars['String'];
  /** 화면에 표시할 라벨값 */
  label: Scalars['String'];
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type ReviewOptionInput = {
  /** 옵션 key값. ex) bottom_size, upper_size, shoe_size, skin_tone, etc. */
  key: Scalars['String'];
  /** 쿼리할 옵션값 리스트 - check, radio option의 경우 id_list를 value_list 값으로 설정 - range option의 경우 [gte, lte] 순서로 List 형태로 설정. length가 2이고 첫번째 인자가 두번째 인자보다 작거나 같을 때만 valid한 value_list */
  value_list: Array<Scalars['String']>;
};

/** 리뷰 바텀싯 포인트 */
export type ReviewPointDescription = {
  /** 포인트 지급조건 */
  condition: Scalars['String'];
  /** 지급되는 포인트 정보 */
  description: Scalars['String'];
};

/** 리뷰 바텀싯 포인트 정보 */
export type ReviewPointInfo = {
  /** 기본 포인트 지급 관련 설명(ex> 텍스트 리뷰 20자 이상) */
  base_point_description: ReviewPointDescription;
  /** 추가지급 포인트 지급 관련 설명(ex> 50자 이상, 사진첨부시) */
  bonus_point_description: Array<ReviewPointDescription>;
  /** 예외 상품 포인트 지급 관련 설명(ex> 3,000 ~ 10,000원 지급 포인트) */
  exceptional_point_description: Array<ReviewPointDescription>;
  /** 타이틀 */
  title: Scalars['String'];
};

/** 체크옵션 리스트 컴포넌트 */
export type ReviewRadioOptionList = ReviewComponent & {
  /** 화면에 표시할 타이틀 */
  display_title: Scalars['String'];
  /** 옵션 key값. ex) bottom_size, upper_size, shoe_size, skin_tone, etc. */
  key: Scalars['String'];
  /** 옵션 리스트 */
  option_list: Array<ReviewOption>;
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 선택한 옵션. 내 맞춤정보 필터 기능을 사용하기 위해서 서버에서 내려줘야 한다. */
  selected_option_id: Scalars['ID'];
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

/** 체크옵션 리스트 컴포넌트 */
export type ReviewRangeOption = ReviewComponent & {
  /** 화면에 표시할 타이틀 */
  display_title: Scalars['String'];
  /** 옵션 Range를 조정할 때 클라이언트에서 사용할 diff 값 */
  gap: Scalars['Int'];
  /** 옵션 key값. ex) bottom_size, upper_size, shoe_size, skin_tone, etc. */
  key: Scalars['String'];
  /** 허용되는 범위 최댓값 */
  max: Scalars['Int'];
  /** 단위. cm, mm, m, etc. */
  measure_unit: Scalars['String'];
  /** 허용되는 범위 최솟값 */
  min: Scalars['Int'];
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 선택한 범위값. 내 맞춤정보 필터 기능을 사용하기 위해서 서버에서 내려줘야 한다. - [gte, lte] 순서로 값이 내려간다. 이 순서를 지켜서 ReviewOptionInput에 value_list로 설정해야 한다. */
  selected_range: Array<Scalars['Int']>;
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type ReviewSummary = {
  review_count?: Maybe<Scalars['String']>;
  review_score?: Maybe<Scalars['Float']>;
};

/** 리뷰작성 가능한 구매확정된 사용자의 상품주문 목록 및 요약 데이터 */
export type ReviewableOrderItemList = {
  item_list: Array<OrderItem>;
  /** 리뷰 이벤트 */
  review_event?: Maybe<ProductReviewEvent>;
  total_count: Scalars['Int'];
};

export type RewardInviteeInput = {
  code: Scalars['String'];
  /**
   * promotion_type : 프로모션 타입
   * ---
   * FRIEND_INVITE // 친구 초대
   */
  promotion_type: Scalars['String'];
};

export type RewardInviteeResult = {
  is_success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type RewardInviterInput = {
  /**
   * promotion_type : 프로모션 타입
   * ---
   * FRIEND_INVITE // 친구 초대
   */
  promotion_type: Scalars['String'];
};

export type RewardInviterResult = {
  is_success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type RewardPromotionShop = {
  image_url?: Maybe<Scalars['String']>;
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
};

export type RewardPromotionShopList = {
  item_list: Array<RewardPromotionShop>;
};

export type RewardUserAccount = {
  bank_account_holder?: Maybe<Scalars['String']>;
  bank_account_number?: Maybe<Scalars['String']>;
  bank_code?: Maybe<Scalars['String']>;
  /** document_inspection_status 가 NONE 일 때만 값이 존재 */
  can_create_document?: Maybe<Scalars['Boolean']>;
  /**
   * 채널 검수 상태
   * ---
   * deprecated 처리되었으므로 NONE 값 반환 예정
   * @deprecated 미사용으로 인한 제거 예정
   */
  channel_inspection_status: Scalars['String'];
  /**
   * 어필레이트 정책 국가 코드 (ISO 3166-1 alpha-2)
   * KR, US, JP
   */
  country: Scalars['String'];
  document_inspection_status: AffiliateInspectionStatus;
  editable_user_account_type: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_valid_bank_account: Scalars['Boolean'];
  min_settlement_amount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  referral_code: Scalars['String'];
  tax_type: RewardUserTaxType;
  /** can_create_document 가 false 일 때만 값이 존재 */
  total_reward_amount_before_qualified?: Maybe<Scalars['Int']>;
  user_account_status: RewardUserAccountStatus;
  /** @deprecated 미사용으로 인한 제거 예정 */
  user_account_type: Scalars['String'];
};

/** 어필레이트 사용자계정 채널 input */
export type RewardUserAccountChannelInput = {
  /**
   * 채널 유형
   * ---
   * YOUTUBE
   * INSTAGRAM
   * FACEBOOK
   * BLOG
   */
  channel_type: Scalars['String'];
  /**
   * 채널 URL
   * ---
   * 채널 유형 별 url 허용 패턴
   * YOUTUBE: https://www.youtube.com/@handle
   * INSTAGRAM: https://www.instagram.com/username
   * FACEBOOK: https://www.facebook.com/username
   * BLOG: http://, https://
   */
  channel_url: Scalars['String'];
  /**
   * 채널 검수를 위한 base64 encoded 이미지
   * ---
   * data:image/<이미지확장자>;base64,<data코드>
   */
  image_content: Scalars['String'];
  /** 대표채널 여부 */
  is_representative: Scalars['Boolean'];
};

export enum RewardUserAccountStatus {
  /** 활동 */
  ACTIVATED = 'ACTIVATED',
  /** 채널인증 미완료(활동) */
  CHANNEL_UNCERTIFIED = 'CHANNEL_UNCERTIFIED',
  /** 채널인증 미완료(비활동) */
  CHANNEL_UNCERTIFIED_DEACTIVATED = 'CHANNEL_UNCERTIFIED_DEACTIVATED',
  /** 비활동 */
  DEACTIVATED = 'DEACTIVATED',
  /** 탈퇴 */
  DELETED = 'DELETED',
  /** deprecated 어필레이트 Phase1.5 부터 미사용 */
  INVITED = 'INVITED',
  /** 없음 */
  NONE = 'NONE',
  /** 어뷰징(정지) */
  STOPPED = 'STOPPED',
}

export type RewardUserBankAccount = {
  bank_account_holder?: Maybe<Scalars['String']>;
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
  status: Scalars['String'];
};

/** 리워드 일별 통계 */
export type RewardUserDailyStats = {
  /** 주문 취소 금액 */
  cancel_amount: Scalars['Int'];
  /** 주문 취소 수 */
  cancel_count: Scalars['Int'];
  /** 통계 일자 (yyyy-MM-dd) */
  date_stats_ymd: Scalars['String'];
  /** 유효 주문 금액 */
  order_amount: Scalars['Int'];
  /** 유효 주문 수 */
  order_count: Scalars['Int'];
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /** 조회수 */
  view_count: Scalars['Int'];
};

/** 리워드 일별 통계 목록 및 요약 */
export type RewardUserDailyStatsList = {
  /** 리워드 일별 통계 목록 */
  item_list: Array<RewardUserDailyStats>;
  /** 조회된 기간 내 총 리워드 금액 */
  total_amount: Scalars['Int'];
};

/** 리워드 사용자 월별 통계 */
export type RewardUserMonthlyStats = {
  /** 주문 취소 금액 */
  cancel_amount: Scalars['Int'];
  /** 주문 취소 수 */
  cancel_count: Scalars['Int'];
  /** 기준 월 (yyyy-MM) */
  date_stats_ym: Scalars['String'];
  /**
   * 최종 리워드 금액
   * final_reward_amount = reward_amount + inviter_referral_amount + invitee_referral_amount + first_order_incentive_amount
   */
  final_reward_amount: Scalars['Int'];
  /** 첫 주문 수 */
  first_order_count: Scalars['Int'];
  /** 첫 구매 인센티브 금액 */
  first_order_incentive_amount: Scalars['Int'];
  /** 피초대자 레퍼럴 금액 */
  invitee_referral_amount: Scalars['Int'];
  /** 초대자 레퍼럴 금액 */
  inviter_referral_amount: Scalars['Int'];
  /** 유효 주문 금액 */
  order_amount: Scalars['Int'];
  /** 유효 주문 수 */
  order_count: Scalars['Int'];
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /** 조회수 */
  view_count: Scalars['Int'];
};

/** 리워드 사용자 정산 */
export type RewardUserSettlement = {
  /** 소득귀속시기(yyyy-MM) */
  date_income_ym?: Maybe<Scalars['String']>;
  /** 리워드 적립 연월 (yyyy-MM) */
  date_reward_ym: Scalars['String'];
  /** 정산금 지급 실패 메시지 */
  fail_reason_message?: Maybe<Scalars['String']>;
  /** 메모 (지급준비상태 등 text) */
  memo?: Maybe<Scalars['String']>;
  /** 리워드 금액 */
  reward_amount: Scalars['Int'];
  /**
   * 정산금 지급 상태
   * ---
   * SCHEDULED: 지급예정
   * SUCCESS: 지급완료
   * FAILED: 지급오류
   * DEFERRED: 지급보류
   * UNPAID: 미지급
   * CANCELLED: 적립금소멸
   */
  settlement_status: Scalars['String'];
};

export type RewardUserSettlementDocument = {
  bank_account_holder?: Maybe<Scalars['String']>;
  bank_account_number?: Maybe<Scalars['String']>;
  bank_code?: Maybe<Scalars['String']>;
  bankbook_file?: Maybe<Scalars['String']>;
  bankbook_file_inspection_reason?: Maybe<Scalars['String']>;
  business_registration_file?: Maybe<Scalars['String']>;
  business_registration_file_inspection_reason?: Maybe<Scalars['String']>;
  business_registration_number?: Maybe<Scalars['String']>;
  business_registration_number_inspection_reason?: Maybe<Scalars['String']>;
  /**
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  identification_file?: Maybe<Scalars['String']>;
  identification_file_inspection_reason?: Maybe<Scalars['String']>;
  inspection_status: AffiliateInspectionStatus;
  name?: Maybe<Scalars['String']>;
  resident_registration_number?: Maybe<Scalars['String']>;
  resident_registration_number_inspection_reason?: Maybe<Scalars['String']>;
  tax_type: RewardUserTaxType;
};

export enum RewardUserTaxType {
  /** 법인사업자(세금계산서 발행) */
  CORPORATE_BUSINESS = 'CORPORATE_BUSINESS',
  /** 개인 */
  PERSONAL = 'PERSONAL',
  /** 개인사업자(세금계산서 미발행) */
  PERSONAL_BUSINESS_WITHOUT_TAX_INVOICE = 'PERSONAL_BUSINESS_WITHOUT_TAX_INVOICE',
  /** 개인사업자(세금계산서 발행) */
  PERSONAL_BUSINESS_WITH_TAX_INVOICE = 'PERSONAL_BUSINESS_WITH_TAX_INVOICE',
}

export type SaleChipFilter = SaleComponent & {
  id: Scalars['ID'];
  item_list: Array<SaleFilterItem>;
  type: SaleComponentType;
};

export type SaleComponent = {
  id: Scalars['ID'];
  type: SaleComponentType;
};

export enum SaleComponentType {
  SALE_CHIP_FILTER = 'SALE_CHIP_FILTER',
  SALE_HORIZONTAL_PRODUCT_CARD = 'SALE_HORIZONTAL_PRODUCT_CARD',
  SALE_LIST_HEADER = 'SALE_LIST_HEADER',
  SALE_NOTICE = 'SALE_NOTICE',
  SALE_QUICK_MENU = 'SALE_QUICK_MENU',
  SALE_RAFFLE_CAROUSEL = 'SALE_RAFFLE_CAROUSEL',
  SALE_SEGMENT = 'SALE_SEGMENT',
  SALE_TAB = 'SALE_TAB',
  SALE_TEMPLATE = 'SALE_TEMPLATE',
  SALE_TIME_DEAL = 'SALE_TIME_DEAL',
}

export enum SaleDynamicType {
  SALE_RECOMMEND_CAROUSEL = 'SALE_RECOMMEND_CAROUSEL',
}

export type SaleFilterItem = {
  disabled: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<UxCommonImage>;
  name?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  type: SalePageInfoFilterType;
  ubl?: Maybe<UxUbl>;
};

export type SaleHorizontalProductCard = SaleComponent & {
  id: Scalars['ID'];
  /** 상품 카드 데이터 */
  item: SaleProductCardItem;
  type: SaleComponentType;
};

export type SaleListHeader = SaleComponent & {
  filter_item_list: Array<SaleFilterItem>;
  id: Scalars['ID'];
  sorting_item_list: Array<SaleSortingItem>;
  type: SaleComponentType;
};

export type SaleNotice = SaleComponent & {
  background_color: UxCommonColor;
  id: Scalars['ID'];
  image: UxCommonImage;
  type: SaleComponentType;
};

export type SaleNotification = {
  ad_noti_status: UserNotiStatus;
  button_title: Scalars['String'];
  title: UxCommonText;
};

export type SalePageComponent = {
  chip_filter?: Maybe<SaleChipFilter>;
  list_header?: Maybe<SaleListHeader>;
  notice?: Maybe<SaleNotice>;
  quick_menu?: Maybe<SaleQuickMenu>;
  /** 래플 카루셀 */
  raffle_carousel?: Maybe<SaleRaffleCarousel>;
  segment?: Maybe<SaleSegment>;
  sticky_type?: Maybe<SaleStickyType>;
  tab: SaleTab;
  time_deal?: Maybe<SaleTimeDeal>;
};

export type SalePageInfo = {
  component_list: Array<Maybe<SaleComponent>>;
  end_cursor?: Maybe<Scalars['String']>;
  has_next: Scalars['Boolean'];
  page_component: SalePageComponent;
};

export type SalePageInfoFilterInput = {
  id: Scalars['ID'];
  type: SalePageInfoFilterType;
};

export enum SalePageInfoFilterType {
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  CATEGORY_SEGMENT = 'CATEGORY_SEGMENT',
  DELIVERY = 'DELIVERY',
  DISCOUNT = 'DISCOUNT',
  NONE = 'NONE',
  PRODUCT_PRESET = 'PRODUCT_PRESET',
}

export type SalePageInfoInput = {
  after?: InputMaybe<Scalars['String']>;
  filter_list?: InputMaybe<Array<SalePageInfoFilterInput>>;
  sorting?: InputMaybe<SalePageInfoSortingInput>;
  tab_id?: InputMaybe<Scalars['ID']>;
};

export type SalePageInfoSortingInput = {
  id: Scalars['ID'];
  type: SalePageInfoSortingType;
};

export enum SalePageInfoSortingType {
  LATEST = 'LATEST',
  POPULARITY = 'POPULARITY',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RANK = 'RANK',
  RECOMMENDATION = 'RECOMMENDATION',
  REVIEW_COUNT = 'REVIEW_COUNT',
}

/** 세일탭 상품 카드 데이터 */
export type SaleProductCardItem = {
  product: UxGoodsCardItem;
  /** 타임딜 */
  time_deal?: Maybe<SaleTimeDeal>;
};

export type SaleProductCarousel = {
  column_count: Scalars['Float'];
  item_list: Array<SaleProductCardItem>;
};

export enum SaleProgressType {
  ON_GOING = 'ON_GOING',
  SCHEDULED = 'SCHEDULED',
}

export type SaleQuickMenu = SaleComponent & {
  id: Scalars['ID'];
  item_list: Array<SaleFilterItem>;
  type: SaleComponentType;
};

export type SaleRaffleCarousel = {
  header: DdpHeader;
  id: Scalars['ID'];
  product_item_list?: Maybe<Array<SaleRaffleCarouselProductCard>>;
  type: SaleComponentType;
};

export type SaleRaffleCarouselProductCard = {
  product: UxGoodsCardItem;
  progress_type: SaleProgressType;
  time_deal?: Maybe<SaleTimeDeal>;
};

export type SaleRecommendCarousel = {
  item: SaleProductCarousel;
  sub_title?: Maybe<UxCommonText>;
  title: UxCommonText;
};

export type SaleSegment = SaleComponent & {
  id: Scalars['ID'];
  item_list: Array<SaleFilterItem>;
  type: SaleComponentType;
};

export type SaleSnackBarActionInfo = {
  deeplink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SaleSnackBarInfo = {
  action_info: SaleSnackBarActionInfo;
  message: Scalars['String'];
};

export type SaleSortingItem = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  type: SalePageInfoSortingType;
  ubl?: Maybe<UxUbl>;
};

export enum SaleStickyType {
  CHIP_FILTER = 'CHIP_FILTER',
  QUICK_MENU = 'QUICK_MENU',
  SEGMENT = 'SEGMENT',
}

export type SaleTab = SaleComponent & {
  id: Scalars['ID'];
  item_list: Array<SaleTabItem>;
  type: SaleComponentType;
};

export type SaleTabItem = {
  id: Scalars['ID'];
  image: UxCommonImage;
  lottie_image_url?: Maybe<UxCommonImageUrl>;
  selected: Scalars['Boolean'];
  selected_image: UxCommonImage;
  selected_lottie_image_url?: Maybe<UxCommonImageUrl>;
  text: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SaleTabNotificationUpdateResult = {
  success: Scalars['Boolean'];
};

export type SaleTemplate = SaleComponent & {
  dynamic_type?: Maybe<SaleDynamicType>;
  id: Scalars['ID'];
  type: SaleComponentType;
};

export type SaleTimeDeal = SaleComponent & {
  date_deadline: Scalars['CrTimestamp'];
  end_title: Scalars['String'];
  id: Scalars['ID'];
  snackbar_info?: Maybe<SaleSnackBarInfo>;
  start_title?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: SaleComponentType;
};

/** 당일 배송비 정보 */
export type SameDayShippingFee = {
  /** 결제된 현금 금액 */
  cash_amount: Scalars['Int'];
  /** 당일 배송비 ID */
  id: Scalars['ID'];
  /** 클레임 번호 */
  order_item_request_number?: Maybe<Scalars['String']>;
  /** 결제된 포인트 금액 */
  point_amount: Scalars['Int'];
  /** 배송 그룹 ID */
  shipping_group_id: Scalars['ID'];
  /** 결제된 총 금액 */
  total_amount: Scalars['Int'];
};

export type SaveProductFunnelInfoListInput = {
  /** 상품 퍼널 정보 리스트 */
  product_list: Array<ProductFunnelInfoInput>;
};

export type SavedProductList = {
  catalog_product_id_list?: Maybe<Array<Scalars['ID']>>;
  user_account_id?: Maybe<Scalars['String']>;
  user_metadata_id: Scalars['String'];
  uuid: Scalars['String'];
};

export type SavedShopList = {
  shop_id_list?: Maybe<Array<Scalars['ID']>>;
  user_account_id?: Maybe<Scalars['String']>;
  user_metadata_id: Scalars['String'];
  uuid: Scalars['String'];
};

export type SavedStylingList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<Styling>;
  total_count: Scalars['Int'];
};

export type SavedStylingListInput = {
  end_cursor?: InputMaybe<Scalars['String']>;
};

export type SavedUxProductInput = {
  catalog_product_id?: InputMaybe<Scalars['String']>;
  folder_id?: InputMaybe<Scalars['ID']>;
  folder_name?: InputMaybe<Scalars['String']>;
  shop_id: Scalars['ID'];
  shop_product_no?: InputMaybe<Scalars['String']>;
};

export type SearchAutoComplete = {
  query_string: Scalars['String'];
  search_auto_complete_list?: Maybe<Array<SearchAutoCompleteItem>>;
};

export type SearchAutoCompleteItem = {
  category_list?: Maybe<Array<Scalars['String']>>;
  event_url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pick_uuid?: Maybe<Scalars['String']>;
  shop_main_domain?: Maybe<Scalars['String']>;
  type: SearchAutoCompleteType;
  type_text?: Maybe<Scalars['String']>;
};

export enum SearchAutoCompleteType {
  CATEGORY = 'CATEGORY',
  EVENT = 'EVENT',
  GOODS = 'GOODS',
  PICK = 'PICK',
  SHOPS = 'SHOPS',
  SHOP_DETAIL = 'SHOP_DETAIL',
}

export type SearchBarPlaceholder = {
  /** 딥 링크 URL */
  deep_link_url?: Maybe<Scalars['String']>;
  /** 라이트 모드 검색창 노출 정보 */
  placeholder: Placeholder;
  /** 다크 모드 검색창 노출 정보 */
  placeholder_dark: Placeholder;
};

export type SearchBoxFilterComponent = SearchFilterComponent & {
  input?: Maybe<SearchFilterValue>;
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  placeholder: SearchBoxFilterPlaceholder;
  placeholder_dark: SearchBoxFilterPlaceholder;
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
  value?: Maybe<Scalars['String']>;
};

export type SearchBoxFilterPlaceholder = {
  is_html: Scalars['Boolean'];
  text: Scalars['String'];
};

export type SearchBreadcrumbFilterComponent = SearchFilterComponent & {
  delimeter: Scalars['String'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value_list: Array<SearchResultListFilterBreadcrumb>;
};

export type SearchChipButtonFilterComponent = SearchFilterComponent & {
  key: Scalars['String'];
  max_rows?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value_list: Array<SearchChipButtonFilterValue>;
};

export type SearchChipButtonFilterValue = {
  count: Scalars['Int'];
  disabled: Scalars['Boolean'];
  icon?: Maybe<SearchFilterIcon>;
  image_url?: Maybe<Scalars['String']>;
  input?: Maybe<SearchFilterValue>;
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
  value: Scalars['String'];
  value_highlighted?: Maybe<Scalars['String']>;
};

export type SearchClassificationKeyword = {
  /** 배경 색상 */
  background_color?: Maybe<UxCommonColor>;
  /** 딥링크 */
  landing_url?: Maybe<Scalars['String']>;
  /** 노출 텍스트 */
  text: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type SearchClassificationKeywordList = {
  icon_image_url?: Maybe<UxCommonImageUrl>;
  item_list: Array<SearchClassificationKeyword>;
  title: Scalars['String'];
};

export type SearchClassifiedImage = {
  background_color?: Maybe<UxCommonColor>;
  border?: Maybe<UxBorder>;
  dark_border?: Maybe<UxBorder>;
  url: UxCommonImageUrl;
};

export type SearchClassifiedImageKeyword = SearchClassifiedKeyword & {
  /** 이미지 */
  image: SearchClassifiedImage;
  /** 딥링크 */
  landing_url?: Maybe<Scalars['String']>;
  /** 노출 텍스트 */
  text: UxCommonText;
  /** 키워드 타입(TEXT/IMAGE) */
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchClassifiedKeyword = {
  /** 딥링크 */
  landing_url?: Maybe<Scalars['String']>;
  /** 노출 텍스트 */
  text: UxCommonText;
  /** 키워드 타입(TEXT/IMAGE) */
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchClassifiedKeywordList = {
  icon_image_url?: Maybe<UxCommonImageUrl>;
  item_list: Array<SearchClassifiedKeyword>;
  title: Scalars['String'];
  /** 키워드 목록 타입(TEXT/IMAGE) */
  type: Scalars['String'];
};

export type SearchClassifiedPopularKeywordChipButton = {
  /**
   * 칩버튼 id
   * 클릭시 classified_popular_keyword_id 입력값으로 활용
   */
  id: Scalars['String'];
  selected: Scalars['Boolean'];
  text: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type SearchClassifiedTextKeyword = SearchClassifiedKeyword & {
  /** 배경 색상 */
  background_color?: Maybe<UxCommonColor>;
  /** 딥링크 */
  landing_url?: Maybe<Scalars['String']>;
  /** 노출 텍스트 */
  text: UxCommonText;
  /** 키워드 타입(TEXT/IMAGE) */
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchClickedProductListInput = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type SearchColumnShiftingButton = {
  column_count: Scalars['Int'];
  icon_url: UxCommonImageUrl;
  ubl?: Maybe<UxUbl>;
};

export type SearchFilter = {
  collapse: Scalars['Boolean'];
  component_list: Array<SearchFilterComponent>;
  key: Scalars['String'];
  name: Scalars['String'];
  show_tooltip?: Maybe<Scalars['Boolean']>;
  tooltip_text?: Maybe<Scalars['String']>;
};

export type SearchFilterComponent = {
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type SearchFilterIcon = {
  /** 스트로크 색상 코드 */
  border_color?: Maybe<UxCommonColor>;
  /** 색상 코드 */
  color?: Maybe<UxCommonColor>;
  /** 다크모드 아이콘 이미지 */
  dark_image_url?: Maybe<Scalars['String']>;
  /** 아이콘 이미지 (image_url 없으면 color 사용) */
  image_url?: Maybe<Scalars['String']>;
};

export type SearchFilterValue = {
  attribute?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  max_value?: Maybe<DecimalUnitNumber>;
  min_value?: Maybe<DecimalUnitNumber>;
  order?: Maybe<Scalars['Int']>;
  tag: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type SearchFilterValueInput = {
  attribute?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  last_selected?: InputMaybe<Scalars['Boolean']>;
  max_value?: InputMaybe<DecimalUnitNumberInput>;
  min_value?: InputMaybe<DecimalUnitNumberInput>;
  order?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type SearchFormReview = {
  category: Scalars['String'];
  items?: Maybe<Array<SearchFormReviewItem>>;
  sub_category?: Maybe<Scalars['String']>;
};

export type SearchFormReviewItem = {
  log?: Maybe<Scalars['String']>;
  product: SearchFormReviewItemProduct;
  review: SearchFormReviewItemReview;
  user: SearchFormReviewItemUser;
};

export type SearchFormReviewItemProduct = {
  browsing_type: UxBrowsingType;
  catalog_product_id?: Maybe<Scalars['ID']>;
  goods_url: Scalars['String'];
  image: Scalars['String'];
  is_brand: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  name: Scalars['String'];
  product_url: Scalars['String'];
  shop_id: Scalars['ID'];
  shop_main_domain: Scalars['String'];
  shop_product_no?: Maybe<Scalars['String']>;
};

export type SearchFormReviewItemReview = {
  content: Scalars['String'];
  rating: Scalars['Int'];
};

export type SearchFormReviewItemUser = {
  email: Scalars['String'];
};

export type SearchKeywordSuggestion = {
  /**
   * 분류된 검색어 목록
   * @deprecated classified_keyword_list 필드로 전환됨
   */
  classification_keyword_list?: Maybe<Array<SearchClassificationKeywordList>>;
  /** 분류된 검색어 목록 */
  classified_keyword_list: Array<SearchClassifiedKeywordList>;
  /** 분류된 인기검색어 */
  classified_popular_keyword_list?: Maybe<SearchPopularKeywordList>;
  /**
   * 구(舊) 인기검색어
   * @deprecated popular_keyword_list 필드 사용 권장
   */
  keywords: Array<Scalars['String']>;
  /**
   * 구(舊) 인기검색어
   * @deprecated popular_keyword_list 필드 사용 권장
   */
  keywords_title: Scalars['String'];
  /** 인기검색어 */
  popular_keyword_list?: Maybe<SearchPopularKeywordList>;
  /** 최근 검색 키워드 타이틀 */
  recent_searches_title: Scalars['String'];
  /** 추천검색어 */
  suggested_keyword_list?: Maybe<SearchSuggestedKeywordList>;
};

export type SearchListFilterComponent = SearchFilterComponent & {
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value_list: Array<SearchResultListFilterOptionValue>;
};

export type SearchMessageFilterComponent = SearchFilterComponent & {
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: Scalars['String'];
};

export type SearchPopularKeyword = {
  diff_previous_ranking: Scalars['Int'];
  keyword: Scalars['String'];
  ranking: Scalars['Int'];
};

export type SearchPopularKeywordList = {
  classified_chip_button_list?: Maybe<Array<SearchClassifiedPopularKeywordChipButton>>;
  is_show_ranking_ui: Scalars['Boolean'];
  item_list: Array<SearchPopularKeyword>;
  title: Scalars['String'];
  updated_date_text: Scalars['String'];
};

export type SearchProductInput = {
  /** 응답한 커서(end_cursor) 이후의 상품 */
  after?: InputMaybe<Scalars['String']>;
  /**
   * 입력한 display_category_id_list에 해당하는 상품을 반환한다.
   * 대분류, 중분류, 소분류는 ;로 구분한다
   * 예) display_category_id_list: ["1388;1390;1397"]
   */
  display_category_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 목록 개수 제한 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 입력한 managed_category_id_list에 해당하는 상품을 반환한다. */
  managed_category_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 상품 정렬 순서 */
  order?: InputMaybe<SearchedProductOrderType>;
  /** 입력한 질의를 만족하는 상품들을 반환한다. */
  q?: InputMaybe<Scalars['String']>;
};

export type SearchRangeInputBoxFilterComponent = SearchFilterComponent & {
  input?: Maybe<SearchFilterValue>;
  key: Scalars['String'];
  max: DecimalUnitNumber;
  max_placeholder: Scalars['String'];
  min: DecimalUnitNumber;
  min_placeholder: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  selected_max?: Maybe<DecimalUnitNumber>;
  selected_min?: Maybe<DecimalUnitNumber>;
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchRangeSliderFilterComponent = SearchFilterComponent & {
  disabled: Scalars['Boolean'];
  input?: Maybe<SearchFilterValue>;
  interval: DecimalUnitNumber;
  key: Scalars['String'];
  max_without_decimal: Scalars['Int'];
  min_without_decimal: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  range_text_replacement?: Maybe<Scalars['String']>;
  selected_max_without_decimal?: Maybe<Scalars['Int']>;
  selected_min_without_decimal?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchRecentUserActionInput = {
  clicked_product_list?: InputMaybe<Array<SearchClickedProductListInput>>;
};

export type SearchResult = {
  ab_test_list: Array<SearchUxAbTest>;
  end_cursor?: Maybe<Scalars['String']>;
  filter_list: Array<SearchFilter>;
  has_next: Scalars['Boolean'];
  input_filter_list: Array<SearchFilterValue>;
  /** 제안어 교정 검색 결과 여부 */
  is_guided_keyword_search?: Maybe<Scalars['Boolean']>;
  re_search?: Maybe<ReSearch>;
  /** 실제 검색에 사용된 쿼리(페이징 query로도 사용 됨) */
  searched_keyword?: Maybe<Scalars['String']>;
  selected_filter_list: Array<SelectedSearchFilter>;
  total_count: Scalars['Int'];
  ubl?: Maybe<UxUbl>;
  ui_item_list: Array<UxComponent>;
};

export type SearchResultInput = {
  after?: InputMaybe<Scalars['String']>;
  brand_id?: InputMaybe<Scalars['ID']>;
  department_id?: InputMaybe<Scalars['String']>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  /** 검색결과가 없을 경우 교정 키워드로 검색 (default: false) */
  enable_guided_keyword_search?: InputMaybe<Scalars['Boolean']>;
  filter_id_list?: InputMaybe<Array<Scalars['String']>>;
  filter_list?: InputMaybe<Array<SearchFilterValueInput>>;
  include_filter_key_list?: InputMaybe<Array<Scalars['String']>>;
  initial?: InputMaybe<Scalars['Boolean']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  page_id: Scalars['String'];
  q?: InputMaybe<Scalars['String']>;
  re_search?: InputMaybe<ReSearchInput>;
  recent_user_action?: InputMaybe<SearchRecentUserActionInput>;
  session_id?: InputMaybe<Scalars['String']>;
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
  ui_property?: InputMaybe<UiPropertyInput>;
  user_feature_list?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchResultListFilterBreadcrumb = {
  disabled: Scalars['Boolean'];
  id: Scalars['String'];
  input?: Maybe<SearchFilterValue>;
  name: Scalars['String'];
  order: Scalars['Int'];
  path: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
};

export type SearchResultListFilterOptionValue = {
  count: Scalars['Int'];
  disabled: Scalars['Boolean'];
  id: Scalars['String'];
  input?: Maybe<SearchFilterValue>;
  name: Scalars['String'];
  path: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
};

export type SearchResultQuickFilter = {
  disabled: Scalars['Boolean'];
  icon?: Maybe<SearchFilterIcon>;
  name: UxCommonText;
  quick_input?: Maybe<SearchFilterValue>;
  selected: Scalars['Boolean'];
  selected_count?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
  tooltip_text?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type SearchResultRecommendItemList = {
  ui_item_list: Array<UxComponent>;
};

export type SearchResultRecommendItemListInput = {
  brand_id?: InputMaybe<Scalars['ID']>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  origin_object_idx?: InputMaybe<Scalars['Int']>;
  page_id: Scalars['String'];
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
  shop_id_list?: InputMaybe<Array<Scalars['ID']>>;
  ui_property?: InputMaybe<UiPropertyInput>;
};

export type SearchSorting = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  title: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SearchSuggestedKeywordList = {
  keyword_list: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type SearchUxAbTest = {
  group: Scalars['String'];
  key: Scalars['String'];
};

export type SearchedGoods = {
  /** @deprecated internal */
  _goods_id: Scalars['ID'];
  aid?: Maybe<Scalars['String']>;
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 브라우저 타입 */
  browsing_type?: Maybe<BrowsingType>;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 최종 할인가 */
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  /** 무료 배송비 여부 */
  free_shipping: Scalars['Boolean'];
  /** 상품 쿠폰 존재 여부 */
  has_coupon: Scalars['Boolean'];
  image_height: Scalars['Int'];
  image_url: Scalars['String'];
  image_width: Scalars['Int'];
  /** 블랙프라이데이 참여 상품 여부 */
  is_black_friday?: Maybe<Scalars['Boolean']>;
  /** brand 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품 찜 여부 */
  is_saved_product: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** Z페이 즉시 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  /** 최대 가격 */
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  price: Scalars['Int'];
  price_with_currency: PriceWithCurrency;
  product_no: Scalars['String'];
  /** 상품상세 url */
  product_url: Scalars['String'];
  /** 주요 구매자의 연령대 정보 : 10대, 20대, 30대 */
  purchase_age_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 상품 리뷰 정보 */
  review: SearchedGoodsProductReview;
  /** 상품 판매 상태 */
  sales_status: SearchedProductSalesStatus;
  shop_id: Scalars['ID'];
  shop_main_domain: Scalars['String'];
  /** 쇼핑몰의 상품 ID */
  shop_product_no?: Maybe<Scalars['ID']>;
  similar_search: Scalars['Boolean'];
  title: Scalars['String'];
  trait_list: Array<Scalars['String']>;
  url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 구매 가능 여부 */
  zpay: Scalars['Boolean'];
  /** Z결제 전용가 */
  zpay_price?: Maybe<Scalars['Int']>;
  zpay_price_with_currency?: Maybe<PriceWithCurrency>;
};

export type SearchedGoodsList = {
  age_distribution: Array<PurchaseAges>;
  attribute_distribution_list?: Maybe<Array<ProductAttributeDistributionItem>>;
  available_age_list: Array<Scalars['String']>;
  available_category_list: Array<GoodsCategory>;
  available_color_list: Array<GoodsColor>;
  available_display_category_list: Array<GoodsDisplayCategory>;
  category_distribution: Array<GoodsCategoryCount>;
  color_distribution: Array<GoodsColorCount>;
  display_category_distribution: Array<GoodsDisplayCategoryCount>;
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<SearchedGoods>;
  /** 모델 키 집계 결과 */
  model_height_distribution?: Maybe<ProductModelHeightDistribution>;
  price_distribution: GoodsPriceDistribution;
  product_type_distribution?: Maybe<Array<ProductTypeDistribution>>;
  properties_distribution: GoodsPropertiesDistribution;
  /** 상품 스타일 분포 */
  style_distribution?: Maybe<ProductAttributeDistributionItem>;
  total_count: Scalars['Int'];
  total_zpay_count: Scalars['Int'];
};

export type SearchedGoodsModelHeightRange = {
  gte?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type SearchedGoodsModelSizeInput = {
  height_range?: InputMaybe<SearchedGoodsModelHeightRange>;
};

export enum SearchedGoodsOrderType {
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  REVIEW_COUNT_DESC = 'REVIEW_COUNT_DESC',
  REVIEW_SCORE_DESC = 'REVIEW_SCORE_DESC',
  SCORE_DESC = 'SCORE_DESC',
}

export type SearchedGoodsPriceRange = {
  gte?: InputMaybe<Scalars['Int']>;
  gte_with_currency?: InputMaybe<PriceWithCurrencyInput>;
  lte?: InputMaybe<Scalars['Int']>;
  lte_with_currency?: InputMaybe<PriceWithCurrencyInput>;
};

export type SearchedGoodsProductReview = {
  /** 리뷰 개수 */
  count: Scalars['Int'];
  /** 리뷰 평점 */
  score: Scalars['Int'];
};

export type SearchedGoodsPropertiesInput = {
  /** 디테일, 예) 밀리터리, 싱글버튼 */
  detail_list?: InputMaybe<Array<Scalars['String']>>;
  /** 핏, 예) 루즈핏, 포멀핏 */
  fit_list?: InputMaybe<Array<Scalars['String']>>;
  /** 사이즈/기장, 예) 롱가디건, 스몰사이즈 */
  length_list?: InputMaybe<Array<Scalars['String']>>;
  /** 소재, 예) 캐시미어, 실크 */
  material_list?: InputMaybe<Array<Scalars['String']>>;
  /** 패턴, 예) 무지, 체크 */
  pattern_list?: InputMaybe<Array<Scalars['String']>>;
  /** 소매기장, 예) 긴팔, 반팔 */
  sleeve_length_list?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchedKeywordHistoryListInput = {
  /** 키워드를 검색한 시간 */
  date_searched_at: Scalars['CrTimestamp'];
  /** 사용자가 검색한 키워드 */
  keyword: Scalars['String'];
};

export type SearchedKeywordHistoryRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 로그용 키워드 검색 날짜 */
  search_history_date?: Maybe<Scalars['String']>;
  /** 노출용 키워드 검색 날짜(MM.DD) */
  sub_text?: Maybe<Scalars['String']>;
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export type SearchedProduct = {
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 노출 상태 */
  display_status: SearchedProductDisplayStatus;
  /** 무료 배송 여부 */
  free_shipping: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 이미지 상세 정보 */
  image: SearchedProductImage;
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품명 */
  name: Scalars['String'];
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 운영검수/관리에 의해서 상품 제외처리 */
  penalty_status: SearchedProductPenaltyStatus;
  /** 상품 판매 상태 */
  sales_status: SearchedProductSalesStatus;
  /** 판매 가능 여부 */
  sellable: Scalars['Boolean'];
  /** seller의 요청에 의한 상품 제외처리 */
  suspend_status: SearchedProductSuspendStatus;
};

export type SearchedProductAttributeListInput = {
  /** 상품 속성키 (ex. detail, fit) */
  key: Scalars['String'];
  /** 상품 속성키 이름 (ex. 디테일, 핏) */
  name: Scalars['String'];
  /** 속성값 (ex. 루즈핏, 슬림핏) */
  value: Scalars['String'];
};

export enum SearchedProductDisplayStatus {
  /** 비노출 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

/** 이미지 상세 정보 */
export type SearchedProductImage = {
  original_url: Scalars['String'];
  url: Scalars['String'];
};

export type SearchedProductList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<SearchedProduct>;
  total_count: Scalars['Int'];
};

export enum SearchedProductOrderType {
  /** 신상품 정렬 */
  LATEST = 'LATEST',
  /** 저가순 정렬 */
  PRICE_ASC = 'PRICE_ASC',
  /** 고가순 정렬 */
  PRICE_DESC = 'PRICE_DESC',
}

/** 운영검수/관리에 의해서 상품 제외처리 */
export enum SearchedProductPenaltyStatus {
  HIDDEN = 'HIDDEN',
  NONE = 'NONE',
  /** 검색불가, 구매불가 */
  PENALTY = 'PENALTY',
  UNSEARCHABLE = 'UNSEARCHABLE',
}

export enum SearchedProductSalesStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENDED = 'SUSPENDED',
}

export type SearchedProductStyleInput = {
  /** 상품 속성키 이름 (ex. 스타일, Style) */
  name: Scalars['String'];
  /** 속성값 (ex. 미니멀, 시크) */
  value: Scalars['String'];
};

/** seller의 요청에 의한 상품 제외처리 */
export enum SearchedProductSuspendStatus {
  /** 정상 */
  NONE = 'NONE',
  /** 검색불가 / 구매불가 */
  SUSPEND = 'SUSPEND',
}

export type SearchedShop = {
  age_list: Array<Scalars['String']>;
  alias_list?: Maybe<Array<Scalars['String']>>;
  bookmark_count: Scalars['Int'];
  bookmarked: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  delete_action: Scalars['Int'];
  department_type?: Maybe<Scalars['String']>;
  is_brand: Scalars['Boolean'];
  is_free_shipping: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay: Scalars['Boolean'];
  keyword_list: Array<Maybe<Scalars['String']>>;
  keywords: Scalars['String'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  popular_product_list: Array<PopularProduct>;
  shop_id: Scalars['ID'];
  state: Scalars['Int'];
  style_list: Array<ShopStyle>;
  typical_image_url: Scalars['String'];
  url: Scalars['String'];
};

export type SearchedShopList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<SearchedShop>;
  total_count: Scalars['Int'];
};

export type SelectedBrand = {
  id: Scalars['ID'];
  image_list: Array<DdpImage>;
  is_bookmark: Scalars['Boolean'];
  landing_url: Scalars['String'];
  shop_id: Scalars['ID'];
  subtitle: DdpText;
  title: DdpText;
  ubl?: Maybe<UxUbl>;
};

export type SelectedBrandGroup = {
  header: DdpHeaderComponent;
  item_list: Array<SelectedBrand>;
};

export type SelectedSearchFilter = {
  icon?: Maybe<SearchFilterIcon>;
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  tag: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type SendMobileAuthInput = {
  app_hash_string?: InputMaybe<Scalars['String']>;
  mobile_tel: Scalars['String'];
};

export type SendMobileAuthResult = {
  expire_timestamp: Scalars['CrTimestamp'];
};

/** sendMobileAuthenticationToken의 입력 */
export type SendMobileAuthenticationTokenInput = {
  /** 앱 해시 문자열 (for AOS) */
  app_hash_string?: InputMaybe<Scalars['String']>;
  /** 인증시간 만료 초 */
  expire_time_sec?: InputMaybe<Scalars['Int']>;
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /**
   * 점유인증 목적
   * - ACCOUNT: 계정인증
   * - FIND_ACCOUNT: 계정찾기
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type?: InputMaybe<Scalars['String']>;
  /** 유저 IP */
  user_ip?: InputMaybe<Scalars['String']>;
};

/** 세션 메타데이터 */
export type SessionMetadata = {
  /** 마이스토어 url */
  my_store_url?: Maybe<Scalars['String']>;
  /** 사이트 ID */
  site_id: Scalars['ID'];
};

/** setUserAccountBody의 입력 */
export type SetUserAccountBodyInput = {
  bottom_size?: InputMaybe<Scalars['Int']>;
  foot_shape?: InputMaybe<Array<UserAccountBodyFootShape>>;
  height?: InputMaybe<Scalars['Int']>;
  shoe_size?: InputMaybe<Scalars['Int']>;
  skin_concern?: InputMaybe<Array<UserAccountBodySkinConcern>>;
  skin_tone?: InputMaybe<UserAccountBodySkinTone>;
  skin_tone_base?: InputMaybe<UserAccountBodySkinToneBase>;
  skin_type?: InputMaybe<UserAccountBodySkinType>;
  upper_size?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** setUserAccountEmailReceptionAgreed의 입력 */
export type SetUserAccountEmailReceptionAgreedInput = {
  /** email 수신 여부 */
  agreed: Scalars['Boolean'];
};

/** setUserAccountNightlyAdNotiAgreedInput 의 입력 */
export type SetUserAccountNightlyAdNotiAgreedInput = {
  /** 야간 혜택 알림 수신 동의 여부 */
  agreed: Scalars['Boolean'];
};

/** SetUserAccountOfferWallAgreedInput 의 입력 */
export type SetUserAccountOfferWallAgreedInput = {
  /** 오퍼월 동의 여부 */
  agreed: Scalars['Boolean'];
};

/**
 * 스토어 알림 Push 수신 동의 여부 설정 입력
 * @description
 * user_account_id, site_id 값을 전달 하지 않는 경우에는 context의 session 값을 이용한다.
 * 둘다 없을 경우 validation 에러가 발생한다.
 * push_agreed 값이 없을 경우 호출 시 마다 기존에 저장된 값의 반대로 설정한다.
 */
export type SetUserAccountShopNotificationPushAgreedInput = {
  /** 푸시 알림 동의 여부 */
  push_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/**
 * 스토어 알림 구독 여부 설정 입력
 * @description
 * user_account_id, site_id 값을 전달 하지 않는 경우에는 context의 session 값을 이용한다.
 * 둘다 없을 경우 validation 에러가 발생한다.
 * is_subscribed 값이 없을 경우 호출 시 마다 기존에 저장된 값의 반대로 설정한다.
 */
export type SetUserAccountShopSubscribedInput = {
  /** 구독 여부 */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
};

/** setUserAccountSmsReceptionAgreed의 입력 */
export type SetUserAccountSmsReceptionAgreedInput = {
  /** sms 수신 동의 여부 */
  agreed: Scalars['Boolean'];
};

/** setUserAccountThirdProvideAgreed 의 입력 */
export type SetUserAccountThirdProvideAgreedInput = {
  /** 뉴티아라 제3자 제공 동의 여부 */
  agreed: Scalars['Boolean'];
};

/**
 * Users 속성 추가/업데이트 입력
 * user_uuid가 비어 있는 경우 header > session 순으로 조회하여 채운다.
 */
export type SetUserAttributeInput = {
  /** AirBridge Campaign */
  airbridge_campaign?: InputMaybe<Scalars['String']>;
  /** AirBridge Channel */
  airbridge_channel?: InputMaybe<Scalars['String']>;
  /** AirBridge ID */
  airbridge_id?: InputMaybe<Scalars['String']>;
  /** 유저(Users) UUID */
  user_uuid?: InputMaybe<Scalars['String']>;
};

/** setUserNotificationPushAgreed의 입력 */
export type SetUserNotificationPushAgreedInput = {
  /** 알림 푸시 수신 동의 여부 */
  agreed: Scalars['Boolean'];
  /** 알림 카테고리 */
  category: UserNotificationCategory;
};

export type ShareShopInfo = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
  name: Scalars['String'];
};

export enum ShareType {
  AFFILIATE = 'AFFILIATE',
}

/** 출고지, 반품지 정보 */
export type ShippingAddress = {
  /** 반품지 주소 */
  return_address: ShippingAndReturnAddress;
  /** 출고지 주소 */
  shipping_address?: Maybe<ShippingAndReturnAddress>;
};

export type ShippingAndReturnAddress = {
  address: Scalars['String'];
  address_detail?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  shipping_company?: Maybe<Scalars['String']>;
};

/** 택배사 정보 */
export type ShippingCompanyInfo = {
  /** 택배사 enum code */
  code: Scalars['String'];
  /** 택배사 영어 이름 */
  en_name: Scalars['String'];
  /** 택배사 일본어 이름 */
  ja_name: Scalars['String'];
  /** 택배사 이름 */
  name: Scalars['String'];
};

export enum ShippingDeliveryType {
  /** 일반배송 */
  NORMAL = 'NORMAL',
  /** 직진당일 */
  ONE_DAY = 'ONE_DAY',
  /** 직진새벽 */
  TODAY = 'TODAY',
  /** 직진택배 */
  ZONLY = 'ZONLY',
}

export type ShippingFeeAdditionalChargeMethod = {
  /** 교환배송비 추가지불방식 */
  exchange?: Maybe<ShippingFeeChargeMethod>;
  /** 초기배송비 추가지불방식 */
  initial?: Maybe<ShippingFeeChargeMethod>;
  /** 반품배송비 추가지불방식 */
  return?: Maybe<ShippingFeeChargeMethod>;
};

/** 배송비 추가 지불방식 정보 */
export type ShippingFeeAdditionalChargeMethodInput = {
  /** 교환배송비 추가지불방식 */
  exchange?: InputMaybe<ShippingFeeChargeMethod>;
  /** 초기배송비 추가지불방식 */
  initial?: InputMaybe<ShippingFeeChargeMethod>;
  /** 반품배송비 추가지불방식 */
  return?: InputMaybe<ShippingFeeChargeMethod>;
};

/**
 * 추가배송비(반품배송비, 무료배송해지, 초기배송비) 지불 방식
 * - NOT_REQUIRED: 배송비 추가 지불 불필요
 * - USER_WILL_DEPOSIT_PRIVATELY: 추가 배송비를 사용자가 계좌로 입금
 * - USER_WILL_ENCLOSE_WITH_PACKAGE: 추가 배송비를 사용자가 배송 박스에 동봉
 * - DEDUCT_FROM_REFUND_PAYMENT: 배송비를 환불금액에서 차감
 */
export enum ShippingFeeChargeMethod {
  DEDUCT_FROM_REFUND_PAYMENT = 'DEDUCT_FROM_REFUND_PAYMENT',
  NOT_REQUIRED = 'NOT_REQUIRED',
  USER_WILL_DEPOSIT_PRIVATELY = 'USER_WILL_DEPOSIT_PRIVATELY',
  USER_WILL_ENCLOSE_WITH_PACKAGE = 'USER_WILL_ENCLOSE_WITH_PACKAGE',
}

export type ShippingFeeRefundTotalAmounts = {
  /** 환불 금액(포인트를 제외) */
  cash_amount: Scalars['Int'];
  /** 환불 포인트 */
  point_amount: Scalars['Int'];
  /** 환불 금액 + 환불 포인트 */
  total_amount: Scalars['Int'];
};

export enum ShippingFeeType {
  /** 무료 배송 */
  FREE_SHIPPING = 'FREE_SHIPPING',
  /** SHOP의 배송정책을 따름 */
  SHOP_POLICY = 'SHOP_POLICY',
}

/** 배송비 그룹 정보 */
export type ShippingGroup = {
  /** 도서산간 배송비 */
  area_fee: ShippingGroupAreaFee;
  /** 배송비 그룹 ID */
  id: Scalars['ID'];
  /** 주문서 */
  order: Order;
  /** 품목 리스트 */
  order_item_list: Array<OrderItem>;
  /** 남은 상품 금액 */
  remaining_item_amount: Scalars['Int'];
  /** 남은 배송비 */
  remaining_shipping_fee: Scalars['Int'];
  /** 반품지 ID */
  return_address_id?: Maybe<Scalars['ID']>;
  /** 당일 배송비 목록 */
  same_day_shipping_fee_list: Array<SameDayShippingFee>;
  /** 배송지 정보 */
  shipping_address?: Maybe<ShippingAddress>;
  /** 출고지 ID */
  shipping_address_id?: Maybe<Scalars['ID']>;
  /** 배송비 그룹 배송비 결제 정보 */
  shipping_group_payment_shipping_fee?: Maybe<ShippingGroupPaymentShippingFee>;
  /** 배송비 그룹 주문 반품/취소시 차감/동봉/입금 금액 목록 */
  shipping_group_refund_list: Array<ShippingGroupRefund>;
  /** 배송 정보 */
  shipping_info: ShippingInfo;
  /** 배송 타입 */
  shipping_type: ShippingType;
  /** 총 상품 금액 */
  total_item_amount: Scalars['Int'];
  /** 총 배송비 */
  total_shipping_fee: Scalars['Int'];
  /** 직진/당일배송 정보(order_item_list > order_item_number 필드 필요) */
  wms_delivery_info?: Maybe<OrderWmsDeliveryInfo>;
};

/** 배송비 그룹 정보 */
export type ShippingGroupOrder_Item_ListArgs = {
  exclude_new_exchange_order_item?: InputMaybe<Scalars['Boolean']>;
};

/** 도서산간 배송비 */
export type ShippingGroupAreaFee = {
  /** 제주외 도서산간 */
  isolated: Scalars['Int'];
  /** 제주 */
  jeju: Scalars['Int'];
};

/** 배송비 그룹 정보 목록 및 요약 데이터 */
export type ShippingGroupList = {
  /** 배송비 그룹 정보 목록 */
  item_list: Array<ShippingGroup>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** ShippingGroup 배송비 결제 및 환불 정보 */
export type ShippingGroupPaymentShippingFee = {
  /** 현금결제 한 배송비 */
  paid_cash_total_shipping_fee: Scalars['Int'];
  /** 쿠폰결제 한 배송비 */
  paid_coupon_total_shipping_fee: Scalars['Int'];
  /** 포인트결제 한 배송비 */
  paid_point_total_shipping_fee: Scalars['Int'];
};

/** 배송비 결제 수단 */
export enum ShippingGroupProductShippingFeePaymentType {
  AFTER_DELIVERY = 'AFTER_DELIVERY',
  ON_ORDER = 'ON_ORDER',
}

/** 배송비 타입 */
export enum ShippingGroupProductShippingFeeType {
  CHARGED = 'CHARGED',
  CONDITIONAL_FREE = 'CONDITIONAL_FREE',
  FREE = 'FREE',
}

/** 배송비 그룹의 환불 정보 */
export type ShippingGroupRefund = {
  /** 환불일 */
  date_refunded: Scalars['CrTimestamp'];
  /** 총 차감 내역 */
  deduction_cost: RefundDeductionCosts;
  /** 차감 배송비 상세 */
  deduction_shipping_fee_detail?: Maybe<RefundDeductionShippingFeeDetail>;
  /** 동봉/입금 예정 금액 */
  enclose_amount: RefundEncloseCosts;
  /** 소멸된 금액 */
  expired_amounts: ExpiredAmounts;
  /** 해지되는 할인 목록 */
  order_discount_revoked_list: Array<OrderDiscountRevoked>;
  /** 총 프로모션 할인가 (상품금액-프로모션적용가 이므로 할인가에 바로 노출되는 가격) */
  promotion_applied_discount_price?: Maybe<Scalars['Int']>;
  /** 환불된 금액. (= 환불 요청금액 - 차감금액) */
  refunded_amounts: RefundTotalAmounts;
  /** 환불 요청 금액 */
  requested_amounts: RefundRequestedAmounts;
  /** 해지되는 할인액 */
  revoked_discount_amount: Scalars['Int'];
  /** 해지되는 카드사 즉시할인 금액 */
  revoked_discount_payment_amount?: Maybe<Scalars['Int']>;
};

/** 배송 정보 */
export type ShippingInfo = {
  /** 교환 배송비(왕복) */
  exchange_shipping_fee?: Maybe<Scalars['Int']>;
  /** 추가 배송비 */
  extra_shipping_fee: Scalars['Int'];
  /** 조건부 무료 배송 금액 */
  free_shipping_amount?: Maybe<Scalars['Int']>;
  /** 반품 배송비 */
  return_shipping_fee: Scalars['Int'];
  /** 배송비 */
  shipping_fee: Scalars['Int'];
};

export type ShippingInfoPerSiteCountry = {
  country_code: Scalars['String'];
  is_default: Scalars['Boolean'];
  shipping_info: ShopShippingInfo;
  shop_id: Scalars['ID'];
  shop_site_country_id: Scalars['ID'];
  site_id: Scalars['ID'];
};

export enum ShippingMemoType {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

/** 상품 주문 클레임 상세 정보 */
export type ShippingOrderActiveRequest = {
  /** 송장 번호 */
  invoice_number?: Maybe<Scalars['String']>;
  /** 택배사명 */
  shipping_company?: Maybe<Scalars['String']>;
  /** shipping_tracking_id */
  shipping_tracking_id?: Maybe<Scalars['String']>;
  /** 상태 */
  status?: Maybe<ShippingOrderItemRequestStatus>;
  /** 탸입 */
  type?: Maybe<ShippingOrderItemRequestType>;
};

/** 상품 주문 클레임 상태 */
export enum ShippingOrderItemRequestStatus {
  CANCELLED = 'CANCELLED',
  CANCELLED_AFTER_CONFIRMED = 'CANCELLED_AFTER_CONFIRMED',
  CANCELLING = 'CANCELLING',
  CANCELLING_AFTER_CONFIRMED = 'CANCELLING_AFTER_CONFIRMED',
  CANCEL_AFTER_CONFIRMED_REQUESTED = 'CANCEL_AFTER_CONFIRMED_REQUESTED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  EXCHANGED = 'EXCHANGED',
  EXCHANGE_COLLECTED = 'EXCHANGE_COLLECTED',
  EXCHANGE_COLLECTING = 'EXCHANGE_COLLECTING',
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED',
  RETURNED = 'RETURNED',
  RETURN_COLLECTED = 'RETURN_COLLECTED',
  RETURN_COLLECTING = 'RETURN_COLLECTING',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
}

/** 상품 주문 클레임 타입 */
export enum ShippingOrderItemRequestType {
  CANCEL = 'CANCEL',
  CANCEL_AFTER_CONFIRM = 'CANCEL_AFTER_CONFIRM',
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN',
}

/** 상품 주문 상태 */
export enum ShippingOrderItemStatus {
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  BEFORE_TRANSFER = 'BEFORE_TRANSFER',
  CANCELLED = 'CANCELLED',
  CANCELLED_AFTER_CONFIRMED = 'CANCELLED_AFTER_CONFIRMED',
  CANCELLED_BEFORE_TRANSFER = 'CANCELLED_BEFORE_TRANSFER',
  CANCELLING = 'CANCELLING',
  CANCEL_DEFERRED = 'CANCEL_DEFERRED',
  CONFIRMED = 'CONFIRMED',
  DOMESTIC_IN_TRANSIT = 'DOMESTIC_IN_TRANSIT',
  EXCHANGED = 'EXCHANGED',
  EXCHANGE_COLLECTED = 'EXCHANGE_COLLECTED',
  EXCHANGE_COLLECTING = 'EXCHANGE_COLLECTING',
  INTERNATIONAL_AWAITING_SHIPMENT = 'INTERNATIONAL_AWAITING_SHIPMENT',
  INTERNATIONAL_IN_TRANSIT = 'INTERNATIONAL_IN_TRANSIT',
  IN_TRANSIT = 'IN_TRANSIT',
  NEW_ORDER = 'NEW_ORDER',
  RETURNED = 'RETURNED',
  RETURN_COLLECTED = 'RETURN_COLLECTED',
  RETURN_COLLECTING = 'RETURN_COLLECTING',
  RETURN_DEFERRED = 'RETURN_DEFERRED',
  SHIPMENT_PROCESS_REQUESTED = 'SHIPMENT_PROCESS_REQUESTED',
  SHIPPED = 'SHIPPED',
  SHIPPING_DEFERRED = 'SHIPPING_DEFERRED',
  UNKNOWN_VALUE = 'UNKNOWN_VALUE',
}

/** 배송 지역 정보 */
export type ShippingRegion = {
  /** 주소 */
  address?: Maybe<Scalars['String']>;
  /** 우편번호 */
  postcode: Scalars['String'];
  /** 권역번호 */
  region_no: Scalars['Int'];
};

/** 배송 추적 상세 정보 */
export type ShippingTrackingDetailHistory = {
  /** 상호명 */
  brand_name: Scalars['String'];
  /** 실행 시간 */
  date_processed: Scalars['CrTimestamp'];
  /** 택배 기사 이름 */
  delivery_driver_name?: Maybe<Scalars['String']>;
  /** 택배 기사 휴대폰 번호 */
  delivery_driver_phone_number?: Maybe<Scalars['String']>;
  /** 추적 정보 순서 */
  sequence_number: Scalars['Int'];
  /** 배송 상태 */
  status: Scalars['String'];
};

/** 배송 유형 */
export enum ShippingType {
  DIGITAL_DELIVERY = 'DIGITAL_DELIVERY',
  NORMAL = 'NORMAL',
  ONE_DAY = 'ONE_DAY',
  TODAY = 'TODAY',
  ZIGZAGMATE = 'ZIGZAGMATE',
  ZIGZIN = 'ZIGZIN',
}

/** 셀러 등급 항목별 리스트 */
export type ShoUxSellerGradeCategoryScore = {
  /** 카테고리 설명 */
  category_description: ShopUxCommonText;
  /** 스코어 카테고리 이름 */
  category_name: ShopUxCommonText;
  /** 스코어 등급 */
  score_grade?: Maybe<ShopUxIconTextBadgeComponent>;
  /** 스코어 점수(퍼센트) */
  score_percent: Scalars['Int'];
  /** 스코어 점수 퍼세트 텍스트 ex) '92%' */
  score_percent_text: ShopUxCommonText;
};

/** 쇼핑몰 상세 정보 */
export type Shop = {
  /** 판매채널별 적용된 배송비 목록 */
  applied_shipping_info_list: AppliedShippingInfoList;
  /** 고객 문의 전화번호 */
  customer_center_tel?: Maybe<Scalars['String']>;
  /** 삭제 액션 */
  delete_action: Scalars['Float'];
  /**
   * 조건부 배송비 무료 총 금액
   * @deprecated Field no longer supported
   */
  free_shipping_amount?: Maybe<Scalars['Int']>;
  /** 쇼핑몰 Record ID */
  id: Scalars['ID'];
  /**
   * 쇼핑몰 즐겨찾기 여부
   * @deprecated Not supported field
   */
  is_saved_shop: Scalars['Boolean'];
  /** zonly 여부 */
  is_zonly: Scalars['Boolean'];
  language_list: Array<ShopLanguage>;
  /** 쇼핑몰 도메인 정보 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 이름 */
  name: Scalars['String'];
  /**
   * 반품 주소
   * @deprecated Field no longer supported
   */
  return_address?: Maybe<ShopAddress>;
  /**
   * 기본 배송비
   * @deprecated Field no longer supported
   */
  shipping_fee?: Maybe<Scalars['Int']>;
  /** 사이트/국가 목록 */
  site_country_list: Array<ShopSiteCountry>;
  /** 쇼핑몰 상태 */
  state: Scalars['Int'];
  /** 샵 특징 정보 */
  trait: ShopTrait;
  /** 대표 이미지 */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 쇼핑몰 URL */
  url: Scalars['String'];
  /** zpay 상태 */
  zpay_status?: Maybe<ShopZpayStatus>;
};

export type ShopActualPaymentAmount = {
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 스토어 별 총 실결제가 */
  total_actual_payment_amount: Scalars['Int'];
};

/** 반품 주소 */
export type ShopAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

/** 쇼핑몰 카테고리 정보 */
export type ShopDetail = {
  /** 연령대 필터 리스트 */
  age_list: Array<Scalars['String']>;
  /** 쇼핑몰 즐켜찾기 개수 */
  bookmark_count: Scalars['Int'];
  /** 쇼핑몰 카테고리 리스트 */
  category_list?: Maybe<Array<ZigzagCategory>>;
  /** 쇼핑몰 카테고리 Record ID */
  id: Scalars['ID'];
  /** 무료배송 여부 */
  is_free_shipping: Scalars['Boolean'];
  /** Z결제 쇼핑몰 여부 */
  is_zpay: Scalars['Boolean'];
  /** 쇼핑몰 도메인 정보 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 이름 */
  name: Scalars['String'];
  /** 쇼핑몰별 리뷰 요약 정보 */
  product_review_shop_summary?: Maybe<ProductReviewShopDetailSummary>;
  /** 쇼핑몰 스타일 리스트 */
  style_list?: Maybe<Array<UxShopRankingStyle>>;
  /** 쇼핑몰 로고 이미지 */
  typical_image_url: Scalars['String'];
  /** 쇼핑몰 URL */
  url: Scalars['String'];
  /** Z결제 상태 */
  zpay_status: ShopZpayStatus;
};

/** 쇼핑몰 카테고리 상품 */
export type ShopDetailCategoryGoods = {
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 상품 이미지 주소 */
  image: Scalars['String'];
  /** 무료 배송 여부 */
  is_free_shipping: Scalars['Boolean'];
  /** 신상품 여부 */
  is_new: Scalars['Boolean'];
  /** zpay 상품 여부 */
  is_zpay: Scalars['Boolean'];
  /** 상품 가격 */
  price: Scalars['Int'];
  product_no: Scalars['String'];
  /** 쇼핑몰 메인 도메인 */
  shop_main_domain: Scalars['String'];
  /** 상품명 */
  title: Scalars['String'];
  /** 상품 url */
  url: Scalars['String'];
  /** 상품 webp 이미지 주소 */
  webp_image: Scalars['String'];
};

/** 쇼핑몰 카테고리 상품 리스트 */
export type ShopDetailCategoryGoodsList = {
  /** 업데이트 시간 정보 */
  description?: Maybe<Scalars['String']>;
  /** 쇼핑몰 카테고리 상품 */
  item_list: Array<ShopDetailCategoryGoods>;
  total_count: Scalars['Int'];
};

export type ShopLanguage = {
  language_code: Scalars['String'];
  name: Scalars['String'];
};

export type ShopList = {
  item_list: Array<Shop>;
};

export type ShopModel = {
  /** 하의 사이즈 */
  bottom?: Maybe<Scalars['String']>;
  /** 가슴 사이즈 */
  bust?: Maybe<Scalars['String']>;
  /** 발 사이즈 */
  foot?: Maybe<Scalars['String']>;
  /** 키 */
  height: Scalars['Int'];
  /** 힙 사이즈 */
  hip?: Maybe<Scalars['String']>;
  /** 식별자 */
  id: Scalars['ID'];
  /** 모델 이름 */
  name: Scalars['String'];
  /** 모델 대표 이미지 */
  representative_image?: Maybe<Scalars['String']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 상의 사이즈 */
  top?: Maybe<Scalars['String']>;
  /** 허리 사이즈 */
  waist?: Maybe<Scalars['String']>;
  /** 몸무게 */
  weight: Scalars['String'];
};

export type ShopModelList = {
  item_list: Array<ShopModel>;
  total_count: Scalars['Int'];
};

export type ShopRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** 뱃지 목록 */
  badge_list?: Maybe<Array<RecommendedSearchKeywordBadge>>;
  /** 다크모드 뱃지 목록 */
  dark_badge_list?: Maybe<Array<RecommendedSearchKeywordBadge>>;
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 쇼핑몰 메인 도메인 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 쇼핑몰 대표 이미지 url */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 쇼핑몰 대표 이미지 url */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export enum ShopShippingCompany {
  CJ = 'CJ',
  ETC = 'ETC',
  HANJIN = 'HANJIN',
  LOGEN = 'LOGEN',
  LOGIS = 'LOGIS',
  LOTTE = 'LOTTE',
  POSTAL = 'POSTAL',
}

/** 쇼핑몰 배송 정보 */
export type ShopShippingInfo = {
  /** 교환 배송비 */
  exchange_shipping_fee?: Maybe<Scalars['Int']>;
  /** 추가 배송비2 */
  extra_shipping_fee2: Scalars['Int'];
  /** 추가 배송비3 */
  extra_shipping_fee3: Scalars['Int'];
  /** 조건부 배송비 무료 총 금액 */
  free_shipping_amount: Scalars['Int'];
  /** 부분 반품 배송비 */
  partial_return_shipping_fee: Scalars['Int'];
  /** 반품 수거 시 사용하는 배송 업체 */
  return_shipping_company: ShopShippingCompany;
  /** 반품 배송비 */
  return_shipping_fee: Scalars['Int'];
  /** 쇼핑몰 배송 업체1 */
  shipping_company1: ShopShippingCompany;
  /** 쇼핑몰 배송 업체2 */
  shipping_company2?: Maybe<ShopShippingCompany>;
  /** 배송비 */
  shipping_fee: Scalars['Int'];
};

export type ShopSiteCountry = {
  /** 국가 코드 */
  country_code: Scalars['String'];
  /** 국가 이미지 URL */
  country_image_url: Scalars['String'];
  /** 사이트 ID */
  site_id: Scalars['ID'];
};

export type ShopStyle = {
  /** 쇼핑몰 스타일 색상 */
  color: Scalars['String'];
  /** 쇼핑몰 스타일 명 */
  value: Scalars['String'];
};

export type ShopThumbnailCategoryList = {
  category_list: Array<ShopUxStoreHomeCategory>;
};

export type ShopTotalAmount = {
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 스토어 별 총 상품 금액 */
  total_amount: Scalars['Int'];
};

/** 쇼핑몰 특징 정보 */
export type ShopTrait = {
  age10?: Maybe<Scalars['Boolean']>;
  age20early?: Maybe<Scalars['Boolean']>;
  age20late?: Maybe<Scalars['Boolean']>;
  age20mid?: Maybe<Scalars['Boolean']>;
  age30early?: Maybe<Scalars['Boolean']>;
  age30late?: Maybe<Scalars['Boolean']>;
  age30mid?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['Int']>;
  category_list?: Maybe<Array<Scalars['String']>>;
  minor_category?: Maybe<Scalars['Int']>;
  minor_category_list?: Maybe<Array<Scalars['String']>>;
  minor_style?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

/** 쇼핑몰 유형 */
export enum ShopType {
  META = 'META',
  STORE = 'STORE',
  ZPAY = 'ZPAY',
}

export type ShopUxAlarmInfo = {
  /** 스토어 알림 푸시 수신 동의 여부 */
  is_push_agreed: Scalars['Boolean'];
  /** 스토어 알림 구독 동의 여부 */
  is_subscribed: Scalars['Boolean'];
};

export type ShopUxBackgroundEntryBanner = ShopUxComponent & {
  background_color?: Maybe<ShopUxCommonColor>;
  common_image?: Maybe<ShopUxCommonImage>;
  common_title: ShopUxCommonText;
  landing_url?: Maybe<Scalars['String']>;
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxBadge = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_size?: Maybe<ShopUxImageSize>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_size?: Maybe<ShopUxImageSize>;
  small_image_url?: Maybe<Scalars['String']>;
};

export type ShopUxBadgeBorder = {
  border_color: ShopUxCommonColor;
  width: Scalars['Float'];
};

export type ShopUxBadgeComponent = {
  /**
   * 뱃지 컴포넌트 타입
   * - ICON_TEXT
   */
  type: Scalars['String'];
};

export type ShopUxBanner = {
  /** 생성일 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 배너 종료일 */
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  /** 수정일 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 노출여부 */
  display_status?: Maybe<ShopUxBannerDisplayStatus>;
  /** jpeg image url */
  fallback_image_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** webp image url */
  image_url?: Maybe<Scalars['String']>;
  /** 배너 레이블(단독세일, 지금핫한 특가전 등) */
  label_list?: Maybe<Array<ShopUxBannerLabelStyle>>;
  /**
   * 딥링크 또는 URL
   * @deprecated use link_button.link
   */
  link?: Maybe<Scalars['String']>;
  /** 배너 링크 이동 버튼 컴포넌트 */
  link_button?: Maybe<ShopUxButton>;
  /** 링크 종류 */
  link_type?: Maybe<ShopUxBannerLinkType>;
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 타이틀 첫번째 줄 (백오피스용) */
  main_title_first?: Maybe<Scalars['String']>;
  /** 메인 타이틀 두번째 줄 (백오피스용) */
  main_title_second?: Maybe<Scalars['String']>;
  /** 관리를 위한 배너이름 */
  name?: Maybe<Scalars['String']>;
  /** 순서 */
  order?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  /** 서브 타이틀 (백오피스용 & 앱) */
  sub_title?: Maybe<Scalars['String']>;
  /** 배너 타이틀 */
  title?: Maybe<Scalars['String']>;
  ubl?: Maybe<ShopUxUbl>;
};

export enum ShopUxBannerDisplayStatus {
  /** 비노출 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type ShopUxBannerGroup = {
  /** 오토스크롤 간격(초) */
  auto_rolling_interval?: Maybe<Scalars['Int']>;
  banner_list?: Maybe<Array<ShopUxBanner>>;
  /** 오토스크롤 여부 */
  is_auto_rolling: Scalars['Boolean'];
  ratio: ShopUxCommonImageRatio;
};

export type ShopUxBannerLabelStyle = {
  /** 레이블 배경색상 */
  background_color?: Maybe<Scalars['String']>;
  /** 레이블명 */
  name: Scalars['String'];
  /** 레이블 색상 */
  name_color?: Maybe<Scalars['String']>;
};

export enum ShopUxBannerLinkType {
  CATEGORY = 'CATEGORY',
  COLLECTION = 'COLLECTION',
  IMAGE = 'IMAGE',
  PRODUCT = 'PRODUCT',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
}

export type ShopUxBestProductCollectionList = {
  /** 전체 카테고리 목록 */
  category_list: Array<ShopUxCategory>;
  /**
   * chip_tab_item_list : 상품 조회 기준 필터 리스트 (실시간, 일간, 주간, 월간)
   * check_button_item_list : 상품 필터 리스트 (직진 배송, 무료 배송)
   */
  filter_and_chip_tab?: Maybe<ShopUxTextAndChipTab>;
  /**
   * sorting_item_list : 상품 조회 기준 필터 리스트 (실시간, 일간, 주간, 월간)
   * check_button_item_list : 상품 필터 리스트 (직진 배송, 무료 배송)
   */
  filter_and_sorting?: Maybe<ShopUxTextAndSorting>;
  /**
   * 신상품 포함 여부
   * @deprecated 신상품 위계 변경(신상품 카테고리 노출)으로 as is spec 에서만 사용
   */
  include_new?: Maybe<Scalars['Boolean']>;
  /** 베스트 상품 목록 */
  item_list: Array<ShopUxProductCardItem>;
  /** 랭킹 업데이트 일시 (epoch time, UTC) */
  ranking_updated: Scalars['String'];
  store_home_theme?: Maybe<Scalars['String']>;
  /** 웹뷰 노출 타이틀 */
  title: Scalars['String'];
};

export type ShopUxBestProductCollectionListInput = {
  /** 선택한 카테고리 값 default: 0(root) */
  category_id?: InputMaybe<Scalars['String']>;
  /** 선택한 상품 필터 ID 리스트 (check_button_item_list - 직진 배송, 무료 배송) */
  check_button_item_id_list?: InputMaybe<Array<Scalars['ID']>>;
  /** 선택한 시간별 칩탭 (chip_tab_item_list - 실시간, 일간, 주간, 월간) */
  chip_tab_item_id?: InputMaybe<Scalars['ID']>;
  /** 신상품만 조회하기 */
  only_new: Scalars['Boolean'];
  /** Shop 식별자 */
  shop_id: Scalars['ID'];
  /** 선택한 조회 기준 필터 ID (sorting_item_list - 실시간, 일간, 주간, 월간) */
  sorting_item_id?: InputMaybe<Scalars['ID']>;
};

export enum ShopUxBrowsingType {
  /** PDP 부분 네이티브화 */
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  /** 입점형 상품 PDP 랜딩 필요 상품 */
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  /** 일반 상품 -> 인앱 브라우저 랜딩 */
  NORMAL = 'NORMAL',
  /** Z결제 PDP 랜딩 필요 상품 */
  Z_BROWSER = 'Z_BROWSER',
}

/** 버튼 컴포넌트 */
export type ShopUxButton = ShopUxComponent & {
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  /** 딥링크 URL */
  link_url: Scalars['String'];
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  /** 버튼 스타일 */
  style?: Maybe<ShopUxButtonStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

/** UxButton 스타일 */
export enum ShopUxButtonStyle {
  BUTTON_DEFAULT = 'BUTTON_DEFAULT',
  BUTTON_DEFAULT_NUM = 'BUTTON_DEFAULT_NUM',
  BUTTON_SMALL = 'BUTTON_SMALL',
  BUTTON_SMALL_NUM = 'BUTTON_SMALL_NUM',
  DEFAULT_L = 'DEFAULT_L',
  DEFAULT_M = 'DEFAULT_M',
  DEFAULT_S = 'DEFAULT_S',
  EXTENDED_TEXT_S_ARROW = 'EXTENDED_TEXT_S_ARROW',
  PRIMARY_L = 'PRIMARY_L',
  PRIMARY_M = 'PRIMARY_M',
  PRIMARY_S = 'PRIMARY_S',
  SECONDARY_L = 'SECONDARY_L',
  SECONDARY_M = 'SECONDARY_M',
  SECONDARY_S = 'SECONDARY_S',
  SQUARE_DEFAULT_L = 'SQUARE_DEFAULT_L',
  SQUARE_SECONDARY_L = 'SQUARE_SECONDARY_L',
  TOGGLE_M = 'TOGGLE_M',
  TOGGLE_S = 'TOGGLE_S',
}

export type ShopUxCatalogProductCategory = {
  /** category DB의 category id */
  category_id: Scalars['Int'];
  /** 카테고리 1차 또는 2차 또는 3차 */
  depth: Scalars['Int'];
  id: Scalars['ID'];
  /** 카테고리 키 */
  key: Scalars['String'];
  /** 카테고리 이름 */
  value: Scalars['String'];
};

/** 카테고리 목록 */
export type ShopUxCategory = {
  /** 자식 카테고리 목록 */
  children: Array<ShopUxCategory>;
  /** id */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
  /** 카테고리 선택유무 */
  selected?: Maybe<Scalars['Boolean']>;
  /** 동일 depth에서의 순서 */
  sibling_order: Scalars['Int'];
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxCategoryComponentList = {
  text_category_list: Array<ShopUxCategory>;
  thumbnail_category_list?: Maybe<Array<ShopThumbnailCategoryList>>;
};

export type ShopUxChipAndSorting = ShopUxComponent & {
  column_shifting_button_list?: Maybe<Array<ShopUxColumnShiftingButton>>;
  filter_chip_item_list: Array<ShopUxFilterChip>;
  main_title?: Maybe<ShopUxText>;
  sorting_item_list: Array<ShopUxFilterItem>;
  sub_title?: Maybe<ShopUxText>;
  total_count: Scalars['Int'];
  /** CHIP_AND_SORT */
  type: ShopUxComponentType;
};

export type ShopUxChipTab = {
  id: Scalars['ID'];
  /** default = "" */
  log: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxCollectionDetail = {
  /** 컬렉션 설명 */
  description?: Maybe<Scalars['String']>;
  /** 컬렉션 ID */
  id: Scalars['ID'];
  /** 컬렉션 이미지 URL 목록 */
  image_url_list: Array<Scalars['String']>;
  /** 상품 목록 */
  product_list: Array<ShopUxProductCardItem>;
  /** theme 정보 */
  store_home_theme?: Maybe<Scalars['String']>;
  /** 컬렉션 타이틀 */
  title: Scalars['String'];
};

export type ShopUxColumnShiftingButton = {
  column_count: Scalars['Int'];
  icon_url: ShopUxCommonImageUrl;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxCommonButton = {
  background_color?: Maybe<ShopUxCommonColor>;
  image?: Maybe<ShopUxCommonImage>;
  landing_url: Scalars['String'];
  text?: Maybe<ShopUxCommonText>;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type ShopUxCommonHtmlText = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type ShopUxCommonImage = {
  url: ShopUxImageUrl;
  webp_url?: Maybe<ShopUxImageUrl>;
};

export type ShopUxCommonImageRatio = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type ShopUxCommonImageUrl = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

export type ShopUxCommonText = {
  color?: Maybe<ShopUxCommonTextColor>;
  html?: Maybe<ShopUxCommonHtmlText>;
  text: Scalars['String'];
};

export type ShopUxCommonTextColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type ShopUxComponent = {
  type: ShopUxComponentType;
};

export type ShopUxComponentList = {
  /** 조회된 마지막 상품의 ID */
  after_id?: Maybe<Scalars['ID']>;
  /** 현재 카테고리 목록 */
  category_list?: Maybe<Array<ShopUxCategory>>;
  /** 페이지네이션 가능 여부 */
  has_next_page?: Maybe<Scalars['Boolean']>;
  /** 컴포넌트 리스트 */
  item_list: Array<Maybe<ShopUxComponent>>;
  /** 상위 카테고리 목록 */
  parent_category_list?: Maybe<Array<ShopUxCategory>>;
  /** 스토어홈 테마 */
  store_home_theme?: Maybe<Scalars['String']>;
  /** 상단 이미지 */
  top_image_url?: Maybe<Scalars['String']>;
};

/** Component 정의에 따라 변경예정 */
export enum ShopUxComponentType {
  BUTTON = 'BUTTON',
  CAROUSEL = 'CAROUSEL',
  CHIP_AND_SORT = 'CHIP_AND_SORT',
  ENTRY_BANNER = 'ENTRY_BANNER',
  FULL_LOOKBOOK = 'FULL_LOOKBOOK',
  IMAGE = 'IMAGE',
  IMAGE_BANNER_AND_LIST = 'IMAGE_BANNER_AND_LIST',
  IMAGE_CARD = 'IMAGE_CARD',
  LINE_WITH_MARGIN = 'LINE_WITH_MARGIN',
  LOOKBOOK = 'LOOKBOOK',
  PRODUCT = 'PRODUCT',
  PRODUCT_GROUP = 'PRODUCT_GROUP',
  SHORT_FORM_CONTENT_LIST = 'SHORT_FORM_CONTENT_LIST',
  TEXT = 'TEXT',
  TEXT_AND_CHIP_TAB = 'TEXT_AND_CHIP_TAB',
  TEXT_AND_SORT = 'TEXT_AND_SORT',
}

export type ShopUxFilterChip = {
  icon?: Maybe<ShopUxCommonImage>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  type: ShopUxFilterChipType;
  ubl?: Maybe<ShopUxUbl>;
};

export enum ShopUxFilterChipType {
  FILTER = 'FILTER',
  SORT = 'SORT',
}

export type ShopUxFilterItem = {
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxFloatingTextButton = {
  background_color: ShopUxCommonColor;
  link_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  text: ShopUxCommonText;
  ubl?: Maybe<ShopUxUbl>;
};

/** 상품 카드 풀룩북 컴포넌트 */
export type ShopUxFullLookBook = ShopUxComponent & {
  /** 이미지 url */
  common_image?: Maybe<ShopUxCommonImage>;
  /** 타이틀 */
  common_title: ShopUxCommonText;
  header: ShopUxHeaderComponent;
  landing_url?: Maybe<Scalars['String']>;
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

/** 스토어홈 상단 해시태그 정보 */
export type ShopUxHashTag = {
  link_url?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ShopUxHeaderComponent = {
  button?: Maybe<ShopUxCommonButton>;
  image?: Maybe<ShopUxCommonImage>;
  subtitle?: Maybe<ShopUxCommonText>;
  title?: Maybe<ShopUxCommonText>;
};

export type ShopUxIconTextBadgeComponent = ShopUxBadgeComponent & {
  background_color: ShopUxCommonColor;
  border: ShopUxBadgeBorder;
  icon_url?: Maybe<ShopUxCommonImageUrl>;
  text: ShopUxCommonText;
  /** ICON_TEXT */
  type: Scalars['String'];
};

/** IMAGE 타입의 컴포넌트 */
export type ShopUxImage = ShopUxComponent & {
  aspect_Ratio?: Maybe<Scalars['Float']>;
  image_url: Scalars['String'];
  /** 이미지 클릭시 랜딩될 딥링크 */
  link_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxImageBadgeComponent = ShopUxBadgeComponent & {
  image_size?: Maybe<ShopUxImageSize>;
  image_url: ShopUxCommonImageUrl;
  small_image_size?: Maybe<ShopUxImageSize>;
  small_image_url?: Maybe<ShopUxCommonImageUrl>;
  type: Scalars['String'];
};

/** IMAGE_BANNER_AND_LIST 타입의 컴포넌트 */
export type ShopUxImageBannerAndList = ShopUxComponent & {
  /** 더보기 버튼 */
  action_button?: Maybe<ShopUxButton>;
  /** 이미지 url */
  image_url: Scalars['String'];
  /** 상품 목록(최대 3개) */
  product_list: Array<ShopUxProductCardItem>;
  /** 타이틀 */
  title: Scalars['String'];
  type: ShopUxComponentType;
};

/** 상품 컬렉션 이미지 카드 컴포넌트 */
export type ShopUxImageCard = ShopUxComponent & {
  /** 이미지 url */
  common_image?: Maybe<ShopUxCommonImage>;
  /** 타이틀 */
  header: ShopUxHeaderComponent;
  /** 상품 목록(최대 3개) */
  product_list: Array<ShopUxProductCardItem>;
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxImageSize = {
  /** 이미지 높이 크기 */
  height: Scalars['Int'];
  /** 이미지 너비 크기 */
  width: Scalars['Int'];
};

export type ShopUxImageUrl = {
  dark: Scalars['String'];
  normal: Scalars['String'];
};

export type ShopUxLineWithMargin = ShopUxComponent & {
  background_color?: Maybe<ShopUxCommonColor>;
  color?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  margin?: Maybe<ShopUxMargin>;
  position: Scalars['Int'];
  type: ShopUxComponentType;
};

/** LOOKBOOK 타입의 컴포넌트 */
export type ShopUxLookBook = ShopUxComponent & {
  /** 더보기 버튼 */
  action_button?: Maybe<ShopUxButton>;
  /** 이미지 url 목록 */
  image_url_list: Array<Scalars['String']>;
  /** 타이틀 */
  title: Scalars['String'];
  type: ShopUxComponentType;
};

export type ShopUxMargin = {
  bottom: Scalars['Int'];
  left: Scalars['Int'];
  right: Scalars['Int'];
  top: Scalars['Int'];
};

/** 스토어홈 상단 탭 노출 정보 */
export type ShopUxMenuTab = {
  /** 포함 컨텐츠 갯수 */
  item_count?: Maybe<Scalars['Int']>;
  /** 탭 이름 */
  name: Scalars['String'];
  /** 새 아이템 존재 여부 */
  new_item_exists?: Maybe<Scalars['Boolean']>;
  /** 메뉴탭 타입 PRODUCT, STYLE */
  type: Scalars['String'];
};

export type ShopUxNewProductCollection = {
  component_list: Array<ShopUxProductCardItem>;
  store: ShopUxShop;
};

export type ShopUxNewProductCollectionList = {
  after_id?: Maybe<Scalars['ID']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<ShopUxNewProductCollection>;
};

export type ShopUxNewProductCollectionSummary = {
  store_list: Array<ShopUxShop>;
  total_count: Scalars['Int'];
};

/** 결제 방식 */
export enum ShopUxPaymentType {
  EXTERNAL = 'EXTERNAL',
  ZPAY = 'ZPAY',
}

/** Product 통합 모델 */
export type ShopUxProduct = {
  catalog_product_id?: Maybe<Scalars['String']>;
  discount_rate?: Maybe<Scalars['Int']>;
  image_url: Scalars['String'];
  name: Scalars['String'];
  payment_type?: Maybe<ShopUxPaymentType>;
  price: Scalars['Int'];
  price_with_currency: PriceWithCurrency;
  sales_status?: Maybe<ShopUxSalesStatus>;
  shipping_fee_type?: Maybe<ShopUxShippingFeeType>;
  shop_id: Scalars['Int'];
  shop_product_no?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type ShopUxProductCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ShopUxProductCardFomo = {
  icon_image_url: Scalars['String'];
  text: Scalars['String'];
};

/** PRODUCT 타입의 컴포넌트 */
export type ShopUxProductCardItem = ShopUxComponent & {
  /** 뱃지 목록 */
  badge_list?: Maybe<Array<ShopUxBadge>>;
  /** PLP 상품카드 스토어명 우측 영역 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<ShopUxBadge>>;
  browsing_type: ShopUxBrowsingType;
  /** 상품카드 컴포넌트 스타일 (Contents or Normal, 여백 & 비율 변경) */
  card_item_style?: Maybe<Scalars['String']>;
  /** 한행에 노출되는 상품카드 개수 */
  column_count?: Maybe<Scalars['Int']>;
  /** 쿠폰 뱃지 목록 */
  coupon_badge_component_list?: Maybe<Array<ShopUxBadgeComponent>>;
  discount_info?: Maybe<ShopUxProductCardDiscountInfo>;
  /** Z결제 할인과 쿠폰 할인이 적용된 최종 금액 */
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  /** FOMO */
  fomo?: Maybe<ShopUxProductCardFomo>;
  /** 아이템 쿠폰 할인 적용 여부 */
  has_coupon: Scalars['Boolean'];
  /** 추천 상품 여부 */
  has_recommend_item?: Maybe<Scalars['Boolean']>;
  /** BRAND 여부 */
  is_brand: Scalars['Boolean'];
  /** Z결제 미지원 여부 */
  is_display_not_zpay: Scalars['Boolean'];
  /** 상품카드 브랜드명 영역에 dot 버튼 노출 여부 */
  is_enabled_not_my_tasty: Scalars['Boolean'];
  is_new: Scalars['Boolean'];
  /** 웹, 웹뷰 신규 상품카드 노출 여부 (웹 전환 완료 후 제거 필요) */
  is_plp_v2: Scalars['Boolean'];
  /** 찜 여부 */
  is_saved_product?: Maybe<Scalars['Boolean']>;
  /** ZONLY 여부 */
  is_zonly: Scalars['Boolean'];
  /** Z결제 단독 할인 적용 여부 */
  is_zpay_discount: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  managed_category_list?: Maybe<Array<ShopUxCatalogProductCategory>>;
  /** 할인율을 극대화하기 위해 설정된 최대금액 */
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  /** PLP 상품카드 이미지 브랜드 이름 영역 뱃지 리스트 */
  metadata_emblem_badge_list?: Maybe<Array<ShopUxBadge>>;
  /** 당일 배송 */
  one_day_delivery?: Maybe<ShopUxCommonText>;
  /** 성과 측정 데이터 */
  performance_measurement?: Maybe<Scalars['String']>;
  product: ShopUxProduct;
  /** 상품 이름 최대 라인 수 */
  product_name_max_line: Scalars['Int'];
  /** 순위 */
  ranking?: Maybe<Scalars['Int']>;
  /** 순위 변동 */
  ranking_delta?: Maybe<Scalars['Int']>;
  /** 리뷰 개수 */
  review_count?: Maybe<Scalars['String']>;
  /** 리뷰 평점 */
  review_score?: Maybe<Scalars['Float']>;
  /** 스토어 명 (있을시에만 노출) */
  shop_name?: Maybe<Scalars['String']>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<ShopUxBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<ShopUxBadge>>;
  type: ShopUxComponentType;
  ubl?: Maybe<ShopUxUbl>;
};

/** CAROUSEL 타입의 컴포넌트 */
export type ShopUxProductCarousel = ShopUxComponent & {
  component_list: Array<ShopUxProductCardItem>;
  /** 화면에 노출되는 아이템 개수 */
  item_column_count: Scalars['Float'];
  /** 더보기 버튼 */
  more_button?: Maybe<ShopUxButton>;
  type: ShopUxComponentType;
};

/** PRODUCT_GROUP 타입의 컴포넌트 */
export type ShopUxProductGroup = ShopUxComponent & {
  /** action 더보기 버튼 */
  action_button?: Maybe<ShopUxButton>;
  /**
   * ProductGroup 의 product_carousel 을 구성하고있는 상품 타입.
   * - BEST_PRODUCTS: 판매베스트 상품
   * - ZIGZIN_PRODUCTS: 직진배송 상품
   * - COLLECTION: 스토어홈 컬랙션 상품
   */
  contents_type: Scalars['String'];
  image?: Maybe<ShopUxImage>;
  main_title?: Maybe<ShopUxText>;
  /** 더보기 버튼 */
  more_button?: Maybe<ShopUxButton>;
  product_carousel: ShopUxProductCarousel;
  sub_title?: Maybe<ShopUxText>;
  type: ShopUxComponentType;
};

/** 샵 대표 쿠폰 */
export type ShopUxRepresentativeCoupon = {
  /** 쿠폰 전체 목록 URL */
  link_url: Scalars['String'];
  /** 이름 */
  title: Scalars['String'];
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

export type ShopUxRepresentativeCouponBadge = {
  background_color: ShopUxCommonColor;
  icon_url?: Maybe<ShopUxCommonImageUrl>;
  text: ShopUxCommonText;
};

export type ShopUxRepresentativeCouponColorInfo = {
  /** 배경 컬러 */
  background_color: ShopUxCommonColor;
  /** 전체 쿠폰 문구 좌측 구분선 */
  divider_color: ShopUxCommonColor;
};

export type ShopUxRepresentativeCouponV2 = {
  /** 뱃지 정보 */
  badge_info?: Maybe<ShopUxRepresentativeCouponBadge>;
  /** 버튼 박스 컬러 정보 */
  color_info: ShopUxRepresentativeCouponColorInfo;
  /** 쿠폰 등록 종료일 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 만료 일시 */
  date_issue_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 노출 기준일 */
  day_limit?: Maybe<Scalars['Int']>;
  /** {금액}원 이상 */
  description?: Maybe<ShopUxCommonText>;
  /** 전체 쿠폰 */
  landing_title: ShopUxCommonText;
  /** 쿠폰 전체 목록 URL */
  link_url: Scalars['String'];
  /** 최대 {정액}원/{정률}% 쿠폰 */
  main_title: ShopUxCommonText;
  /** {잔여일}일 남음 */
  remaining_date: ShopUxCommonColor;
  ubl?: Maybe<ShopUxUbl>;
};

/** 판매상태 */
export enum ShopUxSalesStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENDED = 'SUSPENDED',
}

export type ShopUxSavedShop = {
  store: ShopUxShop;
  total_new_product_count: Scalars['Int'];
};

export type ShopUxSavedShopList = {
  after_id?: Maybe<Scalars['ID']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<ShopUxSavedShop>;
};

/** 셀러 등급 정보 */
export type ShopUxSellerGradeInfo = {
  /** 카테고리별 스코어 리스트 */
  score_list_per_category: Array<ShoUxSellerGradeCategoryScore>;
  /** 셀러 등급 Footer */
  seller_grade_info_footer?: Maybe<ShopUxSellerGradeInfoFooter>;
  /** 셀러 등급 헤더 */
  seller_grade_info_header?: Maybe<ShopUxSellerGradeInfoHeader>;
  /** 정보 타이틀 / 아마 웹 전용일듯 */
  title: ShopUxCommonText;
};

/** 셀러 등급 Footer */
export type ShopUxSellerGradeInfoFooter = {
  /** Description (ex) 매주 월요일, 일주일 동안 어쩌고 저쩌고) */
  description?: Maybe<ShopUxCommonText>;
};

/** 셀러 등급 헤더 */
export type ShopUxSellerGradeInfoHeader = {
  /** 뱃지 이미지 */
  badge_image_url?: Maybe<ShopUxCommonImage>;
  /** Description (ex) 매주 월요일, 일주일 동안 어쩌고 저쩌고) */
  description?: Maybe<ShopUxCommonText>;
  /** 등급 */
  grade?: Maybe<ShopUxCommonText>;
  /** 등급 설명 */
  grade_description?: Maybe<ShopUxCommonText>;
};

/** 셀러 등급 정보 요약 */
export type ShopUxSellerGradeSummary = {
  /** 셀러 등급 뱃지 이미지 URL */
  badge_image_url?: Maybe<ShopUxCommonImage>;
  /** 등급 */
  grade?: Maybe<ShopUxCommonText>;
  /** info 버튼 */
  info_button?: Maybe<ShopUxCommonButton>;
  /** 점수 항목 리스트 */
  score_category_list?: Maybe<Array<ShopUxCommonText>>;
  /** 점수 항목 등급 */
  score_category_list_grade?: Maybe<ShopUxIconTextBadgeComponent>;
  /** ubl */
  ubl?: Maybe<ShopUxUbl>;
};

/** 배송비 종류 */
export enum ShopUxShippingFeeType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  SHOP_POLICY = 'SHOP_POLICY',
}

/** 셀러홈 샵정보 */
export type ShopUxShop = {
  /** 연령대 목록 */
  age_list: Array<Scalars['String']>;
  /** 유저 알림 정보 */
  alarm_info?: Maybe<ShopUxAlarmInfo>;
  /** 배너 */
  banner_group?: Maybe<ShopUxBannerGroup>;
  /** 즐겨찾기 수 */
  bookmark_count: Scalars['Int'];
  /** 브랜드홈 brand_id */
  brand_home_brand_id?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  /** 한줄문구 */
  comment?: Maybe<Scalars['String']>;
  /** 스토어홈 상단 영역 스토어설명 */
  description?: Maybe<Scalars['String']>;
  /** 스토어홈 플로팅 버튼 */
  floating_button?: Maybe<ShopUxFloatingTextButton>;
  /** 스토어 바로가기 여부 */
  has_store_shortcut: Scalars['Boolean'];
  /** 샵의 스토리 정보 */
  has_story: HasStory;
  /** 스토어홈 노출 해시태그 */
  hash_tag_list: Array<ShopUxHashTag>;
  /** 샵 아이디 */
  id: Scalars['ID'];
  info_landing_url: Scalars['String'];
  /** 즐겨찾기 수 노출 여부 */
  is_bookmark_count_displayed?: Maybe<Scalars['Boolean']>;
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 즐찾 여부 */
  is_saved_shop: Scalars['Boolean'];
  /** 쇼핑몰 바로가기 버튼 노출 여부 */
  is_shop_shortcut_button_displayed: Scalars['Boolean'];
  /** 상단 태그버튼 노출 여부 */
  is_tag_edit_button_displayed?: Maybe<Scalars['Boolean']>;
  /** 셀렉티드 스토어홈 한글 이름 */
  logo_image?: Maybe<ShopUxCommonImage>;
  /** 샵 메인도메인 */
  main_domain: Scalars['String'];
  /** 스타일 탭 노출 정보 */
  menu_tab_list: Array<ShopUxMenuTab>;
  /** 스토어 모델 목록 */
  model_list: ShopModelList;
  /** 샵 이름 */
  name: Scalars['String'];
  /** 샵 대표 쿠폰 */
  representative_coupon?: Maybe<ShopUxRepresentativeCoupon>;
  /** 샵 대표 쿠폰 V2 */
  representative_coupon_v2?: Maybe<ShopUxRepresentativeCouponV2>;
  /** 셀러 등급 요약 정보 */
  seller_grade_summary?: Maybe<ShopUxSellerGradeSummary>;
  /** 셀렉티드 스토어홈 이름 */
  shop_title: ShopUxCommonText;
  /** 해시태그 배너 노출 여부 */
  show_hash_tag_banner: Scalars['Boolean'];
  /** 상단 검색 버튼 및 검색관련 컴포넌트 노출 여부 */
  show_search_button: Scalars['Boolean'];
  /**
   * 스토어 SNS 계정
   * @deprecated use sns_list
   */
  sns?: Maybe<ShopUxSns>;
  /** 스토어 SNS 계정 목록 */
  sns_list: ShopUxSnsList;
  /** 셀렉티드 스토어홈 변경사항 (NORMAL/ SELECTED) */
  store_home_theme?: Maybe<Scalars['String']>;
  /** 스타일 목록 */
  style_list: Array<ShopUxShopStyle>;
  subtitle: ShopUxCommonText;
  /**
   * 샵 요약정보
   * @deprecated 더이상 사용하지 않음
   */
  summary_list: Array<ShopUxShopSummary>;
  /** 셀렉티드 스토어홈 한글 이름 */
  title: ShopUxCommonText;
  /** 샵 이미지 */
  typical_image_url: Scalars['String'];
  /** 샵 도메인 */
  url: Scalars['String'];
  /** 사용자 태그 목록 */
  user_tag_list: Array<Scalars['String']>;
  /** Z결제 운영 상태 */
  zpay_status: ShopUxShopZpayStatus;
};

/** 샵 스타일 */
export type ShopUxShopStyle = {
  /** 컬러 */
  color?: Maybe<Scalars['String']>;
  /** 값 */
  value?: Maybe<Scalars['String']>;
};

/** 샵 요약정보 */
export type ShopUxShopSummary = {
  /** 이름 */
  name: Scalars['String'];
  /** 타입 */
  type: ShopUxShopSummaryType;
  /** 값 */
  value: Scalars['String'];
};

/** 샵 요약 정보 종류 */
export enum ShopUxShopSummaryType {
  /** 리뷰 개수 */
  REVIEW_COUNT = 'REVIEW_COUNT',
  /** 리뷰 평점 (상품 평점) */
  REVIEW_RATING = 'REVIEW_RATING',
  /** 배송 정보 */
  SHIPPING_INFO = 'SHIPPING_INFO',
}

/** zpay 상태 */
export enum ShopUxShopZpayStatus {
  NORMAL = 'NORMAL',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type ShopUxShortFormContent = ShopUxStyleTabContent & {
  /** 컨버팅된 동영상 URL */
  convert_url?: Maybe<Scalars['String']>;
  /** 전시 상태 */
  displayed: Scalars['Boolean'];
  /** 숏폼 ID */
  key: Scalars['ID'];
  /** 링크 url */
  link_url: Scalars['String'];
  /** 숏폼 이름 */
  name: Scalars['String'];
  /** 동영상 원본 URL */
  origin_url: Scalars['String'];
  /** 연관 상품카드 목록 */
  product_list: Array<Maybe<ShopUxProductCardItem>>;
  /** 숏폼 썸네일 이미지 URL */
  thumbnail_image_url: Scalars['String'];
  /** 콘텐츠타입 = 숏폼 */
  type: StyleTabContentType;
};

export type ShopUxShortFormContentList = ShopUxComponent & {
  content_list: Array<ShopUxStyleTabContent>;
  end_cursor?: Maybe<Scalars['String']>;
  has_next: Scalars['Boolean'];
  header: ShopUxHeaderComponent;
  image_ratio?: Maybe<Scalars['Float']>;
  more_button?: Maybe<ShopUxButton>;
  type: ShopUxComponentType;
};

/** 스토어 SNS 계정 */
export type ShopUxSns = {
  image_url: Scalars['String'];
  link_url: Scalars['String'];
  name: Scalars['String'];
};

/** 스토어 SNS 계정 목록 */
export type ShopUxSnsList = {
  item_list: Array<ShopUxSns>;
};

export type ShopUxStoreHomeCategory = {
  /** 썸네일 이미지 */
  image_url: ShopUxImageUrl;
  /** 신규 상품 알림 표시여부 */
  is_new: Scalars['Boolean'];
  /** 앱 링크 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 추가 로그 정보 */
  log?: Maybe<Scalars['String']>;
  /** 노출 순서 */
  order: Scalars['Int'];
  /** 문구 */
  text: Scalars['String'];
  ubl?: Maybe<ShopUxUbl>;
};

export type ShopUxStoreHomeCategoryList = {
  /** 스토어홈 전시중인 전체 커스텀 카테고리 */
  custom_category_list: Array<ShopUxStoreHomeCategory>;
  /** 스토어홈 전시중인 전체 상품 노출 카테고리 */
  display_category_list: Array<ShopUxStoreHomeCategory>;
};

export type ShopUxStyleTabContent = {
  /** 전시에 매핑된 콘텐츠 ID */
  key: Scalars['ID'];
  /** 전시 콘텐츠 타입 */
  type: StyleTabContentType;
};

export type ShopUxStyleTabContentList = {
  content_list: Array<ShopUxStyleTabContent>;
  end_cursor?: Maybe<Scalars['ID']>;
  has_next: Scalars['Boolean'];
  item_aspect_ratio: Scalars['Float'];
  item_column_count: Scalars['Int'];
  shop_id: Scalars['ID'];
  store_home_theme?: Maybe<Scalars['String']>;
};

/** TEXT 타입의 컴포넌트 */
export type ShopUxText = ShopUxComponent & {
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  /** 텍스트 스타일 */
  style?: Maybe<ShopUxTextStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: ShopUxComponentType;
};

export type ShopUxTextAndChipTab = {
  check_button_item_list: Array<ShopUxFilterItem>;
  chip_tab_item_list: Array<ShopUxChipTab>;
  main_title?: Maybe<ShopUxText>;
  sub_title?: Maybe<ShopUxText>;
  type: ShopUxComponentType;
};

/** TEXT_AND_SORT 타입의 컴포넌트 */
export type ShopUxTextAndSorting = ShopUxComponent & {
  check_button_item_list: Array<ShopUxFilterItem>;
  main_title?: Maybe<ShopUxText>;
  sorting_item_list: Array<ShopUxFilterItem>;
  sub_title?: Maybe<ShopUxText>;
  type: ShopUxComponentType;
};

/** UxText 스타일 */
export enum ShopUxTextStyle {
  BODY_1 = 'BODY_1',
  BODY_2 = 'BODY_2',
  BODY_3 = 'BODY_3',
  CAPTION = 'CAPTION',
  CAPTION_NUM = 'CAPTION_NUM',
  SUB_TITLE_1 = 'SUB_TITLE_1',
  SUB_TITLE_2 = 'SUB_TITLE_2',
  SUB_TITLE_3 = 'SUB_TITLE_3',
  TITLE_1 = 'TITLE_1',
  TITLE_2 = 'TITLE_2',
}

export type ShopUxUbl = {
  object?: Maybe<ShopUxUblObject>;
  server_log?: Maybe<Scalars['CrJson']>;
};

export type ShopUxUblObject = {
  id?: Maybe<Scalars['String']>;
  idx?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Z결제 상태 */
export enum ShopZpayStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type ShopliveCampaignGoods = {
  action?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  campaign_id: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Float']>;
  discounted_price?: Maybe<Scalars['Float']>;
  goods_id: Scalars['Int'];
  medias?: Maybe<Array<ShopliveGoodsMedia>>;
  name: Scalars['String'];
  original_price: Scalars['Float'];
  payload?: Maybe<Scalars['String']>;
  showing_now?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  sold_out?: Maybe<Scalars['Boolean']>;
};

export type ShopliveCampaignInfo = {
  adore_count: Scalars['Int'];
  background_url?: Maybe<Scalars['String']>;
  campaign_goods_count: Scalars['Int'];
  campaign_goods_list?: Maybe<Array<ShopliveCampaignGoods>>;
  campaign_key: Scalars['String'];
  campaign_status: Scalars['String'];
  campaign_url: Scalars['String'];
  ended_at?: Maybe<Scalars['CrTimestamp']>;
  live_url?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  poster2_url?: Maybe<Scalars['String']>;
  poster_url?: Maybe<Scalars['String']>;
  preview_live_url?: Maybe<Scalars['String']>;
  replay_live_url?: Maybe<Scalars['String']>;
  scheduled_at: Scalars['CrTimestamp'];
  scheduled_end_at: Scalars['CrTimestamp'];
  started_at?: Maybe<Scalars['CrTimestamp']>;
  title: Scalars['String'];
  unsupport_info_url?: Maybe<Scalars['String']>;
  user_count: Scalars['Int'];
};

/** Shoplive 쿠폰 상태 */
export enum ShopliveCouponStatus {
  /** 이미 쿠폰을 발급받은 경우 */
  ALREADY_ISSUED = 'ALREADY_ISSUED',
  /** 발급 가능 수량이 초과된 쿠폰인 경우 */
  EXHAUSTED = 'EXHAUSTED',
  /** 만료된 쿠폰인 경우 */
  EXPIRED = 'EXPIRED',
  /** 쿠폰 발급에 실패한 경우 */
  FAILED = 'FAILED',
  /** 존재하지 않는 쿠폰인 경우 */
  NOT_FOUND = 'NOT_FOUND',
  /** 로그인하지 않은 사용자인 경우 */
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  /** 쿠폰 발급에 성공한 경우 */
  SUCCEEDED = 'SUCCEEDED',
}

export type ShopliveGoodsMedia = {
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  media_type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

/** Shoplive JWT 토큰 */
export type ShopliveJwtToken = {
  /** 서명된 JWT (JWS) */
  token: Scalars['String'];
};

/** Shoplive 로그인 토큰 */
export type ShopliveLoginToken = {
  /** 서명된 JWT (JWS) */
  token: Scalars['String'];
  /** 사용자 이름 */
  username: Scalars['String'];
};

/** Shoplive 공유하기 실행 파라미터 */
export type ShopliveSharingExecutionParams = {
  /** 안드로이드용 파라미터 */
  android: Scalars['String'];
  /** iOS용 파라미터 */
  ios: Scalars['String'];
};

/** Shoplive 공유하기 메타 정보 */
export type ShopliveSharingMetaInfo = {
  /** 버튼 타이틀 */
  button_title: Scalars['String'];
  /** 미리보기 설명 */
  description: Scalars['String'];
  /** 실행 파라미터 */
  execution_params: ShopliveSharingExecutionParams;
  /** 썸네일 이미지 URL */
  thumbnail_image_url: Scalars['String'];
  /** 캠페인 타이틀 */
  title: Scalars['String'];
};

/** Shoplive 쿠폰 */
export type ShopliveUserAccountCoupon = {
  /** 쿠폰 ID */
  id: Scalars['ID'];
  /** 쿠폰 상태 */
  status: ShopliveCouponStatus;
};

export type ShortSlide = {
  id: Scalars['ID'];
  main_title_1: UxCommonText;
  main_title_2?: Maybe<UxCommonText>;
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
  slide: Array<Slide>;
  sub_title?: Maybe<UxCommonText>;
};

export type ShortUrl = {
  event_info?: Maybe<ShortUrlEventInfo>;
  kakao_share_link: Scalars['String'];
  long_url: Scalars['String'];
  shop_info?: Maybe<ShareShopInfo>;
  short_url: Scalars['String'];
};

export type ShortUrlEventInfo = {
  items?: Maybe<Array<ShortUrlEventItem>>;
  need_login?: Maybe<Scalars['Boolean']>;
};

export type ShortUrlEventItem = {
  caption?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: ShortUrlEventType;
  url?: Maybe<Scalars['String']>;
};

export enum ShortUrlEventType {
  KAKAO = 'KAKAO',
  SHARE = 'SHARE',
}

/** signupApple 결과 */
export type SignUpAppleResult = {
  /** 가입 완료시 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 계정 정보 */
  user_account: UserAccount;
};

/** signupFacebook 결과 */
export type SignUpFacebookResult = {
  /** 가입 완료시 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 계정 정보 */
  user_account: UserAccount;
};

/** signupGoogle 결과 */
export type SignUpGoogleResult = {
  /** 가입 완료시 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 계정 정보 */
  user_account: UserAccount;
};

/** signupKakao 결과 */
export type SignUpKakaoResult = {
  /** 가입 완료시 메시지 */
  message?: Maybe<Scalars['String']>;
  /** 계정 정보 */
  user_account: UserAccount;
};

/** signupApple 의 입력 */
export type SignupAppleInput = {
  /**
   * 앱푸시 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 로그인 인증 코드 */
  code: Scalars['String'];
  /** 사용자가 이메일 가리기 옵션을 했을경우 사용자 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 점유 뺏어오기 여부 */
  is_occupied_mobile_tel?: InputMaybe<Scalars['Boolean']>;
  /** 인증된 사용자 핸드폰 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
  /**
   * 야간 혜택 알림 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /**
   * 제 3자 제공 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
};

/** signupFacebook 의 입력 */
export type SignupFacebookInput = {
  /** 로그인 인증 토큰 */
  access_token: Scalars['String'];
  /**
   * 앱푸시 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 페이스북 계정 정보에 이메일이 없는경우 사용자 이메일 정보 */
  email?: InputMaybe<Scalars['String']>;
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 점유 뺏어오기 여부 */
  is_occupied_mobile_tel?: InputMaybe<Scalars['Boolean']>;
  /** 인증된 사용자 핸드폰 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
  /**
   * 야간 혜택 알림 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /**
   * 제 3자 제공 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
};

/** signupGoogle 의 입력 */
export type SignupGoogleInput = {
  /**
   * 앱푸시 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 점유 뺏어오기 여부 */
  is_occupied_mobile_tel?: InputMaybe<Scalars['Boolean']>;
  /** 인증된 사용자 핸드폰 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
  /**
   * 야간 혜택 알림 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /**
   * 제 3자 제공 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 로그인 인증 토큰 */
  token: Scalars['String'];
};

/** signup의 입력 */
export type SignupInput = {
  /**
   * 앱푸시 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 이메일 */
  email: Scalars['String'];
  /** email 수신 동의 여부 */
  email_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** email sms  통합 수신 동의 여부 */
  email_sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
  /**
   * 야간 혜택 알림 수신 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  nightly_ad_noti_agreed?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 */
  password: Scalars['String'];
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: InputMaybe<Scalars['Boolean']>;
  /**
   * 제 3자 제공 동의 여부
   * ※ (이전 버전 호환을 위해 필수값 지정 X)
   */
  third_provide_agreed?: InputMaybe<Scalars['Boolean']>;
};

/** signupKakao 의 입력 */
export type SignupKakaoInput = {
  /** 사용자 카카오 계정 로그인 후 SDK 로부터 받은 접근 토큰 */
  access_token: Scalars['String'];
  /** 가입 경로 */
  init_path?: InputMaybe<Scalars['String']>;
  /** 휴대폰 점유 뺏어오기 여부 */
  is_occupied_mobile_tel?: InputMaybe<Scalars['Boolean']>;
  /** 점유인증된 휴대폰번호 */
  mobile_tel?: InputMaybe<Scalars['String']>;
};

/** signup 결과 */
export type SignupResult = {
  /** 가입 완료시 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 가입한 계정 정보 */
  user_account: UserAccount;
};

/** 유사 상품 */
export type SimilarGoods = {
  /** 뱃지 리스트 */
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 브라우저 타입 */
  browsing_type?: Maybe<BrowsingType>;
  /** 클릭 트래킹 url */
  c_url?: Maybe<Scalars['String']>;
  /** 카탈로그 상품 ID */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 카탈로그 상품 타입 */
  catalog_product_type?: Maybe<CatalogProductType>;
  /** 상품 옵션 컬러칩 리스트 */
  color_option_list?: Maybe<Array<UxGoodsCardColorChip>>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  /** 상품 할인율 */
  discount_rate: Scalars['Int'];
  /** 최종 할인가 */
  final_price: Scalars['Int'];
  /** 최종 할인가 (통화 지원) */
  final_price_with_currency?: Maybe<PriceWithCurrency>;
  /** 무료 배송 여부 */
  free_shipping: Scalars['Boolean'];
  /** 상품 쿠폰 존재 여부 */
  has_coupon: Scalars['Boolean'];
  /** goods id */
  id: Scalars['ID'];
  /** 상품 이미지 높이 */
  image_height: Scalars['Int'];
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** 상품 이미지 너비 */
  image_width: Scalars['Int'];
  /** brand 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** 상품 찜 여부 */
  is_saved_product: Scalars['Boolean'];
  /** zolny 상품유무 */
  is_zonly: Scalars['Boolean'];
  /** Z페이 즉시 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 최대 가격 */
  max_price: Scalars['Int'];
  /** 최대 가격 (통화 지원) */
  max_price_with_currency?: Maybe<PriceWithCurrency>;
  /** 상품 가격 */
  price: Scalars['Int'];
  /** 상품 가격 (통화 지원) */
  price_with_currency?: Maybe<PriceWithCurrency>;
  /** 자사몰 상품 번호 */
  product_no: Scalars['String'];
  /** 상품상세 url */
  product_url: Scalars['String'];
  /** 소비자가 */
  retail_price?: Maybe<Scalars['Int']>;
  /** 상품 상태 */
  sales_status: UxCatalogSalesStatus;
  /** 쇼핑몰 아이디 */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 주도메인 */
  shop_main_domain: Scalars['String'];
  /** 스토어 이름 */
  shop_name: Scalars['String'];
  /** 쇼핑몰의 상품 ID */
  shop_product_no?: Maybe<Scalars['ID']>;
  /** 유사 상품 검색 가능 여부 */
  similar_search: Scalars['Boolean'];
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 상품명 */
  title: Scalars['String'];
  /** 상품 url */
  url: Scalars['String'];
  /** zpay 상품 여부 */
  zpay: Scalars['Boolean'];
  /** Z페이 할인액 */
  zpay_discount_price?: Maybe<Scalars['Int']>;
  /** Z페이 할인 적용 된 금액 */
  zpay_price?: Maybe<Scalars['Int']>;
};

/** 유사 상품 목록 및 요약 데이터 */
export type SimilarGoodsList = {
  /** 유사 상품 목록 */
  item_list: Array<SimilarGoods>;
};

export type SimilarSearchResult = {
  ui_item_list: Array<UxComponent>;
};

export type SimilarSearchResultInput = {
  extra_log?: InputMaybe<Scalars['String']>;
  product_id: Scalars['ID'];
};

/** 조회된 계좌 간편 은행코드 목록 데이터 */
export type SimpleBank = {
  /** 은행 코드 */
  code: Scalars['String'];
  /** 등록가능 여부 */
  is_registered: Scalars['Boolean'];
  /** 점검 시간 목록 */
  maintainance_time_list: Array<SimpleBankMaintainanceTime>;
  /** 은행명 */
  name: Scalars['String'];
};

export type SimpleBankAccount = {
  /** 예금주 명 */
  bank_account_holder: Scalars['String'];
  /** 계좌 번호 */
  bank_account_number: Scalars['String'];
  /** 은행 코드 */
  bank_code: Scalars['String'];
  /** 은행 코드 정보 */
  simple_bank: SimpleBank;
  /** 상태 */
  status: SimpleBankAccountStatus;
  /** uuid */
  uuid: Scalars['String'];
};

/** 조회된 계좌 간편 내역 목록 데이터 */
export type SimpleBankAccountList = {
  /** 조회된 계좌 간편 내역 목록 */
  item_list: Array<SimpleBankAccount>;
  /** 조회된 계좌 간편 내역 수 */
  total_count: Scalars['Int'];
};

/** 계좌 간편 상태 */
export enum SimpleBankAccountStatus {
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
}

/** 계좌 인증 정보 */
export type SimpleBankCert = {
  /** 핸드폰 번호 */
  mobile_number: Scalars['String'];
};

/** 조회된 계좌 간편 내역 목록 데이터 */
export type SimpleBankList = {
  /** 조회된 계좌 간편 은행 목록 */
  item_list: Array<SimpleBank>;
};

/** 계좌 간편 은행 점검 내역 */
export type SimpleBankMaintainanceTime = {
  /** 은행 점검 종료 시간 */
  date_end: Scalars['CrTimestamp'];
  /** 은행 점검 시작 시간 */
  date_start: Scalars['CrTimestamp'];
  /** 정기 점검 여부 */
  is_regular?: Maybe<Scalars['Boolean']>;
};

/** 사이트 */
export type Site = {
  id: Scalars['ID'];
  /** 사이트명 */
  name: Scalars['String'];
};

export type SiteList = {
  item_list: Array<Site>;
};

/** 사이즈 카테고리 */
export type SizeCategory = {
  /** 자식 카테고리 목록 */
  children?: Maybe<Array<SizeCategory>>;
  /** id */
  id: Scalars['ID'];
  /** 카테고리 이미지 */
  image?: Maybe<SizeCategoryLanguages>;
  /** 카테고리 key */
  key: Scalars['String'];
  /** 카테고리 이름 (ex: 하의) */
  name: Scalars['String'];
  /** 카테고리 옵션(ex: 사이즈) */
  option?: Maybe<SizeCategoryLanguages>;
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
  /** 카테고리 템플릿 정보 */
  template_value?: Maybe<Array<SizeCategoryTemplateValue>>;
};

export type SizeCategoryLanguages = {
  en?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
};

export type SizeCategoryTemplateValue = {
  /** 템플릿 key */
  key: Scalars['String'];
  /** 언어별 템플릿 값 */
  languages: SizeCategoryLanguages;
  /** 필수여부 */
  required: Scalars['Boolean'];
};

export type Slide = {
  id: Scalars['ID'];
  image: UxCommonImageUrl;
  item_list: Array<DdpProductCardItem>;
};

export type SnsLink = {
  /** instagram url */
  instagram_url?: Maybe<Scalars['String']>;
  /** twitter url */
  twitter_url?: Maybe<Scalars['String']>;
  /** youtube url */
  youtube_url?: Maybe<Scalars['String']>;
};

export type SnsLinkInput = {
  /** instagram url */
  instagram_url?: InputMaybe<Scalars['String']>;
  /** twitter url */
  twitter_url?: InputMaybe<Scalars['String']>;
  /** youtube url */
  youtube_url?: InputMaybe<Scalars['String']>;
};

export type SocialConnection = {
  /** 애플 계정 연동여부 */
  apple_connected?: Maybe<Scalars['Boolean']>;
  /** 이메일 정보 */
  email?: Maybe<Scalars['String']>;
  /** 페이스북 계정 연동여부 */
  facebook_connected?: Maybe<Scalars['Boolean']>;
  /** 구글 계정 연동여부 */
  google_connected?: Maybe<Scalars['Boolean']>;
  /** 비밀번호 유무 */
  has_password: Scalars['Boolean'];
  /** 최초 가입 경로 */
  init_type?: Maybe<UserAccountInitType>;
  /** 휴면계정 여부 */
  is_inactive?: Maybe<Scalars['Boolean']>;
  /** 카카오 계정 연동여부 */
  kakao_connected?: Maybe<Scalars['Boolean']>;
};

/** 국가별 SNS 로그인 버튼 노출 순서 */
export type SocialLoginButtonType = {
  common_item_list?: Maybe<Array<UserAccountInitType>>;
  highlight_item_list?: Maybe<Array<UserAccountInitType>>;
};

/** 스폰서 섹션 */
export type SponsoredTitle = UiSection & {
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

export type SquarePickBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type SquarePickBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export enum StoreCollectionDisplayType {
  CAROUSEL = 'CAROUSEL',
  IMAGE_BANNER = 'IMAGE_BANNER',
  LOOKBOOK = 'LOOKBOOK',
}

export type StoreCouponPriceTabCardItem = {
  coupon_discount_amount: Scalars['Int'];
  image_url: Scalars['String'];
  shop_id: Scalars['String'];
  shop_name: Scalars['String'];
};

export type StoreCouponPriceTabData = {
  min_required_amount: Scalars['Int'];
  name: Scalars['String'];
};

export type StoreCouponPriceTabsSectionData = {
  item_list: Array<StoreCouponPriceTabCardItem>;
};

export type StoreCouponPriceTabsSectionProps = {
  default_select_tab_index: Scalars['Int'];
  price_tab_list?: Maybe<Array<StoreCouponPriceTabData>>;
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type StoreCouponSlide = {
  price: Scalars['Int'];
  product: BenefitProductCardItem;
  store: BenefitShop;
};

export type StoreCouponSlideSectionData = {
  item_list: Array<StoreCouponSlide>;
};

export type StoreCouponSlideSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type StoreCouponTabsGroup = {
  item_list: Array<BenefitProductCardItem>;
  store: BenefitShop;
  total_discount_amount: Scalars['Int'];
};

export type StoreCouponTabsSectionData = {
  group_list: Array<StoreCouponTabsGroup>;
};

export type StoreCouponTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type StorePickBannerData = {
  /** 배너 노출 종료일자 */
  date_ended: Scalars['CrTimestamp'];
  /** 배너 노출 시작일자 */
  date_started: Scalars['CrTimestamp'];
  /** 할인율 정보 */
  discount: StorePickBannerDiscount;
  /** 활성화 여부 */
  enabled?: Maybe<Scalars['Boolean']>;
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 태그리스트 */
  tags: StorePickBannerTags;
  /** 배너제목 */
  title: Scalars['String'];
};

export type StorePickBannerDiscount = {
  /** 활성화 여부 */
  enabled: Scalars['Boolean'];
};

export type StorePickBannerTags = {
  /** 쿠폰태그 */
  coupon: Scalars['Boolean'];
  /** 무료배송태그 */
  free_shipping: Scalars['Boolean'];
  /** 상품할인율값 */
  product_discount?: Maybe<Scalars['Int']>;
};

export type StorePickDetailCoupon = {
  /** 버튼 배경 컬러 */
  background_color: Scalars['String'];
  /** 설명 */
  description: Scalars['String'];
  /** 활성화 여부 */
  enabled: Scalars['Boolean'];
};

export type StorePickDetailData = {
  /** 아트워크 이미지 세로 사이즈 */
  artwork_image_height?: Maybe<Scalars['Int']>;
  /** 아트워크 이미지 주소 */
  artwork_image_url: Scalars['String'];
  /** 아트워크 이미지 가로 사이즈 */
  artwork_image_width?: Maybe<Scalars['Int']>;
  /** 쿠폰 정보 */
  coupon: StorePickDetailCoupon;
  /** 설명 */
  description: Scalars['String'];
};

export type StorePickDisplayBanner = {
  /** 할인율 정보 */
  discount: StorePickBannerDiscount;
  /** 이미지 주소 */
  image_url: Scalars['String'];
  /** 태그 재정의(필드별 한글명으로 재정의) */
  refined_tags: Array<Scalars['String']>;
  /** 태그리스트 */
  tags: StorePickBannerTags;
  /** 배너제목 */
  title: Scalars['String'];
};

export type StorePickDisplayData = {
  banner: StorePickDisplayBanner;
  catalog_product_id_list: Array<Scalars['String']>;
  /**
   * 생성된 content_short_url
   * @description
   * ex) https://s.zigzag.kr/sp_c4kmcabi1k
   * 승인된경우에만 전달되며 승인이 안된경우 null
   */
  content_short_url?: Maybe<Scalars['String']>;
  /**
   * content page url
   * @description
   * id=${store_pick_id},${store_id}
   * 승인된경우에만 전달되며 승인이 안된경우 null
   */
  content_url?: Maybe<Scalars['String']>;
  products_preset_uuid: Scalars['String'];
  store: StorePickStoreSimpleData;
  store_pick_id: Scalars['Int'];
};

/** 클라이언트에서 요청하는 정렬기준 */
export enum StorePickOrderType {
  /** 할인율순 */
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  /** 최신순 */
  LATEST = 'LATEST',
  /** 일반풀,테스트풀의 각 정렬기준 */
  POOL_TYPE = 'POOL_TYPE',
  /** 추천순(score 높은순) */
  RECOMMEND = 'RECOMMEND',
}

export type StorePickProductsPresetData = {
  /** catalog_product_id_list list */
  catalog_product_id_list: Array<Scalars['String']>;
  /** products_preset uuid */
  products_preset_uuid?: Maybe<Scalars['String']>;
};

export type StorePickStoreData = {
  bookmark_count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type StorePickStoreSimpleData = {
  /** store_id (shop_id) */
  id: Scalars['ID'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
};

export type StorePresetStoreItemResponse = {
  /** 스토어 ID */
  id: Scalars['Int'];
  /** image_url */
  image_url: Scalars['String'];
  /** 스토어 이름 */
  name: Scalars['String'];
};

export type Story = {
  coupon_info?: Maybe<StoryCouponInfo>;
  created_at: Scalars['CrTimestamp'];
  document: Scalars['String'];
  duration: Scalars['Int'];
  expired_at: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  is_read: Scalars['Boolean'];
  mapping_product_list?: Maybe<Array<DdpProductCardItem>>;
  status: StoryStatus;
  thumbnail: Scalars['String'];
  type: StoryType;
};

export type StoryCouponInfo = {
  coupon: BookmarkStoryCoupon;
  issue_status: BookmarkStoryCouponIssueStatus;
};

export type StoryIcon = {
  title: Scalars['String'];
  type: StoryIconType;
};

export enum StoryIconType {
  MY_STORY_DRAWER_ICON = 'MY_STORY_DRAWER_ICON',
  STORY_UPLOAD_ICON = 'STORY_UPLOAD_ICON',
}

export type StoryListResponse = {
  end_cursor?: Maybe<Scalars['String']>;
  shop_id: Scalars['String'];
  shop_name: Scalars['String'];
  shop_thumbnail_url?: Maybe<Scalars['String']>;
  story_list?: Maybe<Array<Story>>;
};

export type StoryProfile = {
  content: UxCommonText;
};

export enum StoryReportType {
  COPYRIGHT_INFRINGEMENT = 'COPYRIGHT_INFRINGEMENT',
  FAKE_INFORMATION = 'FAKE_INFORMATION',
  INAPPROPRIATE_CONTENT = 'INAPPROPRIATE_CONTENT',
  NOT_SATISFIED = 'NOT_SATISFIED',
  OTHER = 'OTHER',
  RESTRICTED_PRODUCT = 'RESTRICTED_PRODUCT',
}

export type StorySellerInfo = {
  is_story_seller: Scalars['Boolean'];
  shop_id?: Maybe<Scalars['ID']>;
};

export type StoryShopInfo = {
  has_coupon: HasStoryCoupon;
  is_mine: Scalars['Boolean'];
  is_new: Scalars['Boolean'];
  name: Scalars['String'];
  shop_id: Scalars['ID'];
  thumbnail_url: Scalars['String'];
  updated_at: Scalars['CrTimestamp'];
};

export enum StoryStatus {
  ACTIVATED = 'ACTIVATED',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
}

export enum StoryType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type StrapBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type StrapBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

export type StyleCategorizedGroupList = {
  item_list: Array<OnboardingProductList>;
};

export type StyleGoodsImage = {
  image_url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export enum StyleTabContentType {
  /** 숏폼 */
  SHORT_FORM = 'SHORT_FORM',
}

export enum StyleTabSortDirection {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
}

/** 스타일 월드컵 정보 */
export type StyleWorldCup = {
  /** 스타일 월드컵 경쟁 상품 정보 */
  competition?: Maybe<StyleWorldCupCompetition>;
  /** 스타일 월드컵 설명 ex. 내 맘에 쏙 드는 스타일 고르고 쿠폰 받기 */
  description?: Maybe<Scalars['String']>;
  /** 스타일 월드컵 기준 상품 정보 */
  target?: Maybe<StyleWorldCupTarget>;
  /** 스타일 월드컵 제목 ex. 골라바 스타일 월드컵 */
  title: Scalars['String'];
};

/** 스타일 월드컵 경쟁 상품 정보 */
export type StyleWorldCupCompetition = {
  /** 스타일 월드컵 경쟁 상품 아이템 리스트 */
  item_list?: Maybe<Array<StyleWorldCupItem>>;
};

/** 스타일 월드컵 상품에 대한 쿠폰 발급 가능 상태 */
export enum StyleWorldCupCouponAvailableStatus {
  /** 발급할 수 있는 쿠폰 있음 */
  COUPON_AVAILABLE = 'COUPON_AVAILABLE',
  /** 상품에 할당된 모든 쿠폰 발급 받음 */
  COUPON_ISSUED = 'COUPON_ISSUED',
  /** 상품에 할당된 쿠폰 없음 */
  COUPON_UNAVAILABLE = 'COUPON_UNAVAILABLE',
}

/** 스타일 월드컵 아이템의 'fear of missing out' 내용 */
export type StyleWorldCupFomo = {
  /** fomo 발생 count */
  fomo_count: Scalars['Int'];
  /** fomo 발생 couon 안내 문구 ex. 명이 구매중 */
  fomo_description: Scalars['String'];
};

/** 스타일 월드컵 아이템 */
export type StyleWorldCupItem = {
  /** 스타일 월드컵 아이템 fomo(Fear of Mission out) 정보 */
  fomo?: Maybe<StyleWorldCupFomo>;
  /** 스타월 월드컵 아이템 상품 정보 */
  product?: Maybe<UxGoodsCardItem>;
};

/** 스타일 월드컵 기준 상품 정보 */
export type StyleWorldCupTarget = {
  /** 스타일 월드컵 기준 상품 아이템 리스트 */
  item_list?: Maybe<Array<StyleWorldCupItem>>;
  /** 스타일 월드컵 기준 상품의 타입 */
  type: StyleWorldCupTargetType;
};

/** 스타일 월드컵 기준 상품 타입 */
export enum StyleWorldCupTargetType {
  /** 장바구니에 담긴 상품이 있는 경우 */
  CART = 'CART',
  /** 상품이 없는 경우 */
  NONE = 'NONE',
  /** 장바구니가 없을 시 랭킹으로 */
  RANKING = 'RANKING',
}

/** 스타일 월드컵 최종 선택 상품 정보 */
export type StyleWorldCupWinner = {
  /** 쿠폰 발급 가능 여부 정보(발급할 수 있는 쿠폰 있음, 상품에 할당된 모든 쿠폰 발급 받음, 상품에 할당된 쿠폰 없음) */
  coupon_available_status: StyleWorldCupCouponAvailableStatus;
  /** 쿠폰 사용 시 최대 할인액 */
  coupon_discount_amount?: Maybe<Scalars['Int']>;
  /** 노출 카테고리명 */
  display_category_name: Scalars['String'];
  /** 상품 정보 */
  item: UxGoodsCardItem;
};

/** 스타일링 객체 */
export type Styling = {
  /** 생성 시간 (노출 시간이 아니라 진짜 생성 시간/ 예약이라는 기능이 있어 실제 노출 시간과 다를 수 있음) */
  date_created: Scalars['CrTimestamp'];
  /** 노출 시작 날짜 / 노출 시작 날짜가 null인 경우 앱에 노출되고 있지 않음 */
  date_released?: Maybe<Scalars['CrTimestamp']>;
  /** 수정일시 */
  date_updated: Scalars['CrTimestamp'];
  /** ID */
  id: Scalars['ID'];
  /** 트랜드 여부 */
  is_trend: Scalars['Boolean'];
  /** 상품 태킹 */
  product_tag_list: Array<StylingProductTag>;
  /** Shop ID / store_type=SINGLE 일때 값이 존재 */
  shop_id?: Maybe<Scalars['ID']>;
  /** 스타일링 타입 */
  store_type: StylingStoreType;
  /** 스타일링 이미지 */
  styling_image: StylingImage;
  /** 스타일 리스트 */
  tag_list: Array<StylingTag>;
};

export type StylingDetail = {
  component_list: Array<DdpData>;
  id: Scalars['ID'];
  move_store_button?: Maybe<UxCommonButton>;
  styling: DdpStylingCardItem;
  ubl?: Maybe<UxUbl>;
};

/** 스타일링 이미지 */
export type StylingImage = {
  /** 이미지 높이 비율 */
  height: Scalars['Float'];
  /** 스타일링 이미지 URL */
  image_url: Scalars['String'];
  /** 이미지의 썸네일 URL / 썸네일이 없는 경우도 있음. 없는 경우는 image_url 참조 */
  thumbnail_url?: Maybe<Scalars['String']>;
  /** 이미지 가로 비율 */
  width: Scalars['Float'];
};

/** 스타일링 리스트 조회 응답 객체 */
export type StylingList = {
  /** 커서 / item_list의 스타일링 ID (다음 페이지 존재하는 경우 값이 있음) */
  end_cursor?: Maybe<Scalars['String']>;
  /** 스타일링 리스트 */
  item_list: Array<Styling>;
};

export type StylingListByProductIdInput = {
  after_cursor?: InputMaybe<Scalars['String']>;
  after_released_date?: InputMaybe<Scalars['CrTimestamp']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  product_id: Scalars['ID'];
  store_type?: InputMaybe<StylingStoreType>;
};

/** 스타일링 태그ID로 조회 Input / 정렬은 기본값으로 노출 날짜 내림차순 */
export type StylingListByTagListInput = {
  /** 다음 커서부터 검색 */
  after_cursor?: InputMaybe<Scalars['String']>;
  /** 조회 개수 */
  limit_count?: InputMaybe<Scalars['Int']>;
  /** 스토어 타입 */
  store_type?: InputMaybe<StylingStoreType>;
  /** 태그 ID List */
  tag_id_list: Array<Scalars['ID']>;
  /** 태그 ID 검색 타입 */
  tag_search_type?: InputMaybe<StylingTagSearchType>;
};

/** 스타일링 상품 태킹 */
export type StylingProductTag = {
  /** 태킹 좌표 x */
  coordinate_x: Scalars['Float'];
  /** 태킹 좌표 y */
  coordinate_y: Scalars['Float'];
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 태킹 순서 */
  sequence: Scalars['Int'];
  /** Shop ID */
  shop_id: Scalars['ID'];
};

/** 스타일링 타입 */
export enum StylingStoreType {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export type StylingStyleTagName = {
  /** 국가 코드 */
  country: Scalars['String'];
  /** 스타일 태그 */
  name: Scalars['String'];
};

/** 스타일링 태그 */
export type StylingTag = {
  /** 스타일 태그 여부 */
  is_style_tag: Scalars['Boolean'];
  /** 스타일 태그 이름 */
  names: Array<StylingStyleTagName>;
  /** 스타일 태그 ID */
  tag_id: Scalars['ID'];
};

export enum StylingTagSearchType {
  AND = 'AND',
  OR = 'OR',
}

export type SubBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type SubBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

/** SubEventStatus 결과 */
export type SubEventStatus = {
  /** 서브 이벤트 종료 일시 */
  date_end?: Maybe<Scalars['CrTimestamp']>;
  /** 서브 이벤트 시작 일시 */
  date_start?: Maybe<Scalars['CrTimestamp']>;
  /** 카카오 링크 Callback ID */
  kakao_link_callback_id?: Maybe<Scalars['String']>;
  /** 서브 이벤트 참여 가능 상태 */
  status: Scalars['Boolean'];
  /** 서브 이벤트 참여 가능 상태 코드 */
  status_code: Scalars['String'];
  /** 서브 이벤트 타입 */
  sub_event_type: Scalars['String'];
};

/** subscribeFcmRegistrationTokenToTopic의 입력 */
export type SubscribeFcmRegistrationTokenToTopicInput = {
  /** FCM 토큰 */
  fcm_registration_token?: InputMaybe<Scalars['String']>;
  /**
   * ALL: 전체
   * SILENT_PUSH: 사일런트 푸쉬 전용
   * SILENT_PUSH_V2: 사일런트 푸쉬 v2 전용
   */
  topic: Scalars['String'];
};

export type SubscribeTalkLoungeKeywordInput = {
  keyword: Scalars['String'];
};

export enum SuspendStatus {
  /** 판매 중단 */
  BLOCK = 'BLOCK',
  /** 정상 상품 */
  NONE = 'NONE',
  /** 노출 중단 */
  SUSPEND = 'SUSPEND',
}

export type TagShopRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** 뱃지 목록 */
  badge_list?: Maybe<Array<RecommendedSearchKeywordBadge>>;
  /** 다크모드 뱃지 목록 */
  dark_badge_list?: Maybe<Array<RecommendedSearchKeywordBadge>>;
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>키작녀</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 쇼핑몰 ID */
  shop_id: Scalars['ID'];
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 해시태그
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 url */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

export type TalkKeyword = {
  id: Scalars['ID'];
  keyword: Scalars['String'];
};

export type TalkLoungeAcceptedReply = {
  date_created: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  image_list: Array<TalkLoungeImage>;
  /** 운영자 여부 */
  is_admin: Scalars['Boolean'];
  /** 글쓴이 댓글 여부 */
  is_author: Scalars['Boolean'];
  /** 내 댓글 여부 */
  is_mine: Scalars['Boolean'];
  nickname: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  product_list: Array<TalkLoungeProduct>;
  /** 댓글 상태 */
  reply_status: TalkLoungeReplyStatus;
  text: Scalars['String'];
  user_account_id: Scalars['ID'];
};

export type TalkLoungeBanner = {
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  title: Scalars['String'];
};

export type TalkLoungeBannerList = {
  item_list: Array<TalkLoungeBanner>;
};

export type TalkLoungeCategory = {
  /** 글쓰기 placeholder */
  body_placeholder: TalkLoungeCommonText;
  /** 카테고리 요약 문구 */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** (UBL) page id */
  page_id: Scalars['String'];
};

export type TalkLoungeCommonButton = {
  image?: Maybe<TalkLoungeCommonImage>;
  landing_url: Scalars['String'];
  text?: Maybe<TalkLoungeCommonText>;
};

export type TalkLoungeCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

export type TalkLoungeCommonImage = {
  url: TalkLoungeCommonImageUrl;
  webp_url?: Maybe<TalkLoungeCommonImageUrl>;
};

export type TalkLoungeCommonImageUrl = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

export type TalkLoungeCommonText = {
  color?: Maybe<TalkLoungeCommonColor>;
  text: Scalars['String'];
};

/** 상품 카드 PLP 뱃지 */
export type TalkLoungeDisplayBannerBadge = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
};

export type TalkLoungeEditingInfo = {
  /**
   * 톡라운지 글쓰기 body placeholder
   * (카테고리 선택하지 않을 때, 기본으로 노출되는 body_placeholder)
   */
  body_placeholder: TalkLoungeCommonText;
  /** 톡 작성 취소 바텀싯 알림 */
  cancel_talk_creation_notice: TalkLoungeInfoComponent;
  /** 커뮤니티 가이드라인 */
  community_guide_line: TalkLoungeInfoLink;
  /** 카테고리 목록 */
  enabled_category_list: TalkLoungeEnabledCategoryList;
  /**
   * 사용 가능 모듈
   * POLL: 투표
   * IMAGE: 첨부 이미지
   * PICK_ONE: 골라줘
   * REWARDED_QUESTION: 리워드 걸고 질문
   * PRODUCT: 상품 불러오기
   */
  enabled_module_type_list: Array<Maybe<Scalars['String']>>;
  /**
   * 톡라운지 글쓰기 body placeholder
   * enabled_category_list 내 각 카테고리 별 body placeholder 를 무시하고 강제로 force_body_placeholder 를 사용한다.
   */
  force_body_placeholder?: Maybe<TalkLoungeCommonText>;
  /** 리워드 걸고 질문해 보세요 안내 */
  rewarded_question_notice: TalkLoungeInfoLink;
  /** 리워드 걸기 정책 */
  rewarded_question_policy: TalkLoungeInfoComponent;
  /**
   * 리워드 걸기 요구사항
   * @deprecated use rewarded_question_reward_list
   */
  rewarded_question_requirement: TalkLoungeRewardedQuestionRequirement;
  /** 리워드 걸기 보상 선택 안내 */
  rewarded_question_reward_choice: TalkLoungeInfoComponent;
  /** 리워드 걸기 가능한 보상 타입 (POINT, MILEAGE) */
  rewarded_question_reward_list: Array<TalkLoungeRewardedQuestionReward>;
  /** 리워드 걸기에서 금액 부족시 안내 */
  rewarded_question_shortage: TalkLoungeInfoComponent;
};

export type TalkLoungeEnabledCategoryList = {
  item_list: Array<TalkLoungeCategory>;
};

export type TalkLoungeImage = {
  image_url: Scalars['String'];
};

export type TalkLoungeInfoComponent = {
  button?: Maybe<TalkLoungeCommonButton>;
  description: TalkLoungeCommonText;
  sub_title?: Maybe<TalkLoungeCommonText>;
  title: TalkLoungeCommonText;
};

export type TalkLoungeInfoLink = {
  landing_url: Scalars['String'];
  text: TalkLoungeCommonText;
};

/** 참여 가능한 톡 list */
export type TalkLoungeInteractiveTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 내가 좋아요한 톡라운지 톡 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungeLikeTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor: Scalars['String'];
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
};

/** 댓글 좋아요 */
export type TalkLoungeLikedReply = {
  enabled: Scalars['Boolean'];
  like_count: Scalars['Int'];
};

export type TalkLoungeLikedTalk = {
  /** 톡라운지 톡 좋아요 여부 (미로그인시 false) */
  enabled: Scalars['Boolean'];
  like_count: Scalars['Int'];
};

export type TalkLoungeMyReply = {
  /** 차단 사유 */
  blocked_reason?: Maybe<Scalars['String']>;
  date_created: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  image_list: Array<TalkLoungeImage>;
  /** 부모 댓글 ID */
  parent_id?: Maybe<Scalars['ID']>;
  product_list: Array<TalkLoungeProduct>;
  /** 댓글 상태 */
  reply_status: TalkLoungeReplyStatus;
  reply_text: Scalars['String'];
  talk_id: Scalars['ID'];
  talk_title: Scalars['String'];
};

export type TalkLoungeMyReplyList = {
  end_cursor: Scalars['String'];
  has_next: Scalars['Boolean'];
  item_list: Array<TalkLoungeMyReply>;
};

export type TalkLoungeMyTalk = {
  /** 차단 사유 */
  blocked_reason?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  date_created: Scalars['CrTimestamp'];
  image_url_list: Array<Scalars['String']>;
  /** 골라줘 사용 여부 */
  is_pick_one: Scalars['Boolean'];
  /** 좋아요 */
  like: TalkLoungeLikedTalk;
  /** 투표 응답수 */
  poll_answer_count?: Maybe<Scalars['Int']>;
  /** 댓글수 */
  reply_count: Scalars['Int'];
  rewarded_question?: Maybe<TalkLoungeRewardedQuestion>;
  talk_id: Scalars['ID'];
  /** 톡 상태 */
  talk_status: TalkLoungeTalkStatus;
  /** target map 정보 */
  target_map?: Maybe<TalkLoungeTargetMap>;
  title: Scalars['String'];
};

export type TalkLoungeMyTalkList = {
  end_cursor: Scalars['String'];
  has_next: Scalars['Boolean'];
  item_list: Array<TalkLoungeMyTalk>;
};

/** 톡라운지 톡 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungePointTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor: Scalars['String'];
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export type TalkLoungePollAnswerResult = {
  allow_multiple_answer: Scalars['Boolean'];
  /** 응답수 */
  answer_count: Scalars['Int'];
  /** 응답여부 */
  answered: Scalars['Boolean'];
  option_list: Array<TalkLoungePollOptionAnswerResult>;
  poll_id: Scalars['ID'];
};

export type TalkLoungePollOptionAnswerResult = {
  /** 응답수 */
  answer_count: Scalars['Int'];
  /** 응답률 */
  answer_rate: Scalars['Float'];
  /** 응답여부 */
  answered: Scalars['Boolean'];
  poll_option_id: Scalars['ID'];
  product?: Maybe<TalkLoungeProduct>;
  text: Scalars['String'];
};

export type TalkLoungePresignedImageUrlList = {
  item_list: Array<PresignedUrlInfo>;
};

export type TalkLoungePreview = {
  content: Scalars['String'];
  date_created: Scalars['CrTimestamp'];
  image_url_list: Array<Scalars['String']>;
  /** 골라줘 사용 여부 */
  is_pick_one: Scalars['Boolean'];
  /** 좋아요 */
  like: TalkLoungeLikedTalk;
  /** 투표 응답수 */
  poll_answer_count?: Maybe<Scalars['Int']>;
  /** 댓글수 */
  reply_count: Scalars['Int'];
  rewarded_question?: Maybe<TalkLoungeRewardedQuestion>;
  talk_id: Scalars['ID'];
  /** target map 정보 */
  target_map?: Maybe<TalkLoungeTargetMap>;
  title: Scalars['String'];
};

/** 톡라운지 톡 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungePreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor: Scalars['String'];
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 전체 갯수 */
  total_count: Scalars['Int'];
};

/** 톡라운지 상품 (UxGoodsCardItem bypass) */
export type TalkLoungeProduct = {
  /** PLP 뱃지 */
  badge_list?: Maybe<Array<TalkLoungeDisplayBannerBadge>>;
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<TalkLoungeDisplayBannerBadge>>;
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 */
  browsing_type: TalkLoungeProductBrowsingType;
  /** catalog_product_id */
  catalog_product_id: Scalars['ID'];
  /** 할인가 표기 정보 */
  discount_info?: Maybe<TalkLoungeProductDiscountInfo>;
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 쿠폰 할인 여부 */
  has_coupon: Scalars['Boolean'];
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /**
   * log
   * @deprecated ux 서비스 CrJson 포맷팅 이슈 해결 이후 server_log 로 변경 필요
   */
  log?: Maybe<Scalars['String']>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** Catalog 상품의 상태 (판매중, 품절) */
  sales_status?: Maybe<TalkLoungeProductSalesStatus>;
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰 이름 */
  shop_name?: Maybe<Scalars['String']>;
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price?: Maybe<Scalars['Int']>;
};

export enum TalkLoungeProductBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type TalkLoungeProductDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum TalkLoungeProductSalesStatus {
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type TalkLoungeReply = {
  /** 댓글 채택 여부 */
  accepted: Scalars['Boolean'];
  /** 대댓글 수 */
  child_count: Scalars['Int'];
  date_created: Scalars['CrTimestamp'];
  id: Scalars['ID'];
  image_list: Array<TalkLoungeImage>;
  /** 운영자 여부 */
  is_admin: Scalars['Boolean'];
  /** 글쓴이 댓글 여부 */
  is_author: Scalars['Boolean'];
  /** 내 댓글 여부 */
  is_mine: Scalars['Boolean'];
  /** 댓글 좋아요 */
  like: TalkLoungeLikedReply;
  nickname: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  product_list: Array<TalkLoungeProduct>;
  /** 댓글 상태 */
  reply_status: TalkLoungeReplyStatus;
  text: Scalars['String'];
  user_account_id: Scalars['ID'];
};

export type TalkLoungeReplyList = {
  end_cursor: Scalars['String'];
  has_next: Scalars['Boolean'];
  item_list: Array<TalkLoungeReply>;
};

export enum TalkLoungeReplyStatus {
  /** 차단 */
  BLOCKED = 'BLOCKED',
  /** 삭제 */
  DELETED = 'DELETED',
  /** 노출 */
  DISPLAYED = 'DISPLAYED',
  /** 차단해제 */
  UNBLOCKED = 'UNBLOCKED',
}

/** 톡라운지 리워드 걸린 톡 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungeRewardTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor: Scalars['String'];
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 리워드 걸고 질문하기 */
export type TalkLoungeRewardedQuestion = {
  /**
   * 포인트 금액(마일리지일 경우, 0)
   * @deprecated reward_amount 와 reward_type 조합을 사용해주세요.
   */
  point_amount: Scalars['Int'];
  /** 보상 금액 */
  reward_amount: Scalars['Int'];
  /** 보상 타입 (POINT, MILEAGE) */
  reward_type: Scalars['String'];
  /** 질문하기 상태 */
  status: TalkLoungeRewardedQuestionStatus;
};

export type TalkLoungeRewardedQuestionRequirement = {
  /** 최대 포인트 */
  max_point_amount: Scalars['Int'];
  /** 최소 포인트 */
  min_point_amount: Scalars['Int'];
  /** 선택 포인트 목록 */
  option_point_amount_list: Array<Scalars['Int']>;
};

/** 리워드 걸기 가능한 보상 타입 */
export type TalkLoungeRewardedQuestionReward = {
  /** 최대 사용 가능한 금액 */
  max_amount: Scalars['Int'];
  /** 최소 사용 가능한 금액 */
  min_amount: Scalars['Int'];
  /** 선택 리워드 목록 */
  option_reward_amount_list: Array<Scalars['Int']>;
  /** 리워드 타입 (POINT, MILEAGE) */
  reward_type: Scalars['String'];
};

export enum TalkLoungeRewardedQuestionStatus {
  /** 기간종료 */
  END = 'END',
  /** 진행중 */
  IN_PROGRESS = 'IN_PROGRESS',
  /** 채택완료 */
  REWARDED = 'REWARDED',
}

/** 톡라운지 검색 결과 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungeSearchedTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor?: Maybe<Scalars['String']>;
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export type TalkLoungeShareLink = {
  common_share_link: Scalars['String'];
  kakao_share_link: Scalars['String'];
};

/** 내가 구독한 키워드를 가진 톡라운지 톡 미리보기 목록과 페이지네이션 정보 */
export type TalkLoungeSubscribedKeywordTalkPreviewList = {
  /** 다음 페이지의 end_cursor */
  end_cursor: Scalars['String'];
  /** 다음 페이지가 있는지 여부 */
  has_next: Scalars['Boolean'];
  /** 톡라운지 톡 미리보기 목록 */
  item_list: Array<TalkLoungePreview>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export type TalkLoungeTalk = {
  /** 톡라운지 카테고리 */
  category: TalkLoungeCategory;
  /** 톡 내용 */
  content: Scalars['String'];
  /** 톡 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 톡의 id */
  id: Scalars['ID'];
  /** 이미지 list */
  image_list: Array<TalkLoungeImage>;
  /** 운영자 여부 */
  is_admin: Scalars['Boolean'];
  /** 내 게시물 여부 */
  is_mine: Scalars['Boolean'];
  /** 톡 좋아요 */
  like: TalkLoungeLikedTalk;
  /** 유저 닉네임 */
  nickname: Scalars['String'];
  /** 톡 연동 상품 리스트 */
  product_list: Array<TalkLoungeProduct>;
  /** 댓글 갯수 */
  reply_count: Scalars['Int'];
  /** 리워드 걸고 질문 */
  rewarded_question?: Maybe<TalkLoungeRewardedQuestion>;
  /** 톡 상태 */
  talk_status: TalkLoungeTalkStatus;
  /** target map 정보 */
  target_map?: Maybe<TalkLoungeTargetMap>;
  /** 톡 제목 */
  title: Scalars['String'];
  /** 유저의 id */
  user_account_id: Scalars['ID'];
};

export enum TalkLoungeTalkStatus {
  /** 차단 */
  BLOCKED = 'BLOCKED',
  /** 삭제 */
  DELETED = 'DELETED',
  /** 노출 */
  DISPLAYED = 'DISPLAYED',
  /** 차단해제 */
  UNBLOCKED = 'UNBLOCKED',
}

/** 톡라운지 타겟 맵 */
export type TalkLoungeTargetMap = {
  /**
   * target_type에 따라 target_id 정보가 달라짐
   * RAFFLE : raffle 당첨 id
   */
  target_id: Scalars['ID'];
  /**
   * target_type
   * [
   * RAFFLE : 래플 당첨
   * ]
   */
  target_type: Scalars['String'];
};

export type TermBucket = {
  doc_count: Scalars['Int'];
  key: Scalars['String'];
};

export type TextOption = {
  is_required: Scalars['Boolean'];
  max_length: Scalars['Int'];
  name: Scalars['String'];
};

/** 시간 범위 */
export type TimeRange = {
  /** 시작 시간 */
  from_hm?: Maybe<Scalars['String']>;
  /** 끝 시간 */
  to_hm?: Maybe<Scalars['String']>;
};

export type TimedealSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type TimedealSectionProps = {
  event_time_ended: Scalars['CrTimestamp'];
  event_time_started: Scalars['CrTimestamp'];
  products_preset_uuid: Scalars['String'];
  server_time?: Maybe<Scalars['CrTimestamp']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  type: BenefitSectionType;
};

/** toggleProductReviewDislike의 입력 */
export type ToggleProductReviewDislikeInput = {
  /** 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
};

/** toggleProductReviewDislike의 결과 */
export type ToggleProductReviewDislikeResult = {
  /** 도움안돼요 결과 */
  disliked_result: Scalars['Boolean'];
};

/** toggleProductReviewLike의 입력 */
export type ToggleProductReviewLikeInput = {
  /** 칭찬하기 타입 */
  like_type?: InputMaybe<Scalars['String']>;
  /** 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
};

/** toggleProductReviewLike의 결과 */
export type ToggleProductReviewLikeResult = {
  /** 도움돼요 결과 */
  liked_result: Scalars['Boolean'];
};

export type TopImageProductGridSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type TopImageProductGridSectionProps = {
  button_title: Scalars['String'];
  image_url: Scalars['String'];
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  type: BenefitSectionType;
};

export type UiSection = {
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

/** ui 를 구성하는 콘텐츠의 종류 */
export enum UiSectionType {
  CAROUSEL = 'CAROUSEL',
  GOODS_CARD_LIST = 'GOODS_CARD_LIST',
  IMAGE_BANNER_GROUP = 'IMAGE_BANNER_GROUP',
  PICK_BANNER = 'PICK_BANNER',
  SPONSORED_TITLE = 'SPONSORED_TITLE',
  WEBVIEW_BANNER = 'WEBVIEW_BANNER',
}

export type UiAppUpdateBanner = {
  /** 팝업 타입 */
  ID?: Maybe<Scalars['String']>;
  /** 내용 */
  contents?: Maybe<Scalars['String']>;
  /** 종료일 */
  end_date?: Maybe<Scalars['CrTimestamp']>;
  /** 운영메세지 */
  note?: Maybe<Scalars['String']>;
  /** OS 여부 */
  osType?: Maybe<Scalars['String']>;
  /** 시작일 */
  start_date?: Maybe<Scalars['CrTimestamp']>;
  /** 제목 */
  title?: Maybe<Scalars['String']>;
  /** 팝업 타입 */
  type?: Maybe<Scalars['String']>;
};

export type UiPropertyInput = {
  column_count?: InputMaybe<Scalars['Int']>;
};

/** UI 유형 */
export enum UiType {
  CAROUSEL = 'CAROUSEL',
  LIST = 'LIST',
  PAGER = 'PAGER',
}

export enum UmdBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export enum UmdDeleteAction {
  FORCE_REMOVE = 'FORCE_REMOVE',
  NONE = 'NONE',
  POPUP = 'POPUP',
}

export type UmdGoodsCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum UmdPaymentType {
  EXTERNAL = 'EXTERNAL',
  ZPAY = 'ZPAY',
}

export type UmdProduct = {
  catalog_product_id?: Maybe<Scalars['ID']>;
  image_url: Scalars['String'];
  name: Scalars['String'];
  payment_type: UmdPaymentType;
  sales_status: UmdSalesStatus;
  shipping_fee_type: UmdShippingFeeType;
  shop_id: Scalars['ID'];
  shop_product_no?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type UmdProductIdentifiers = {
  /** 카탈로그 상품 id */
  catalog_product_id?: Maybe<Scalars['String']>;
  /** 쇼핑몰 ID */
  shop_id: Scalars['String'];
  /** 쇼핑몰 상품 no */
  shop_product_no?: Maybe<Scalars['String']>;
};

export type UmdProductIdentifiersInput = {
  /** 카탈로그 상품 id */
  catalog_product_id?: InputMaybe<Scalars['String']>;
  /** 쇼핑몰 ID */
  shop_id: Scalars['String'];
  /** 쇼핑몰 상품 no */
  shop_product_no?: InputMaybe<Scalars['String']>;
};

export type UmdProductInfoInput = {
  product_ids_list: Array<UmdProductIdentifiersInput>;
};

export type UmdProductReview = {
  display_review_count?: Maybe<Scalars['String']>;
  review_count?: Maybe<Scalars['Int']>;
  review_score?: Maybe<Scalars['Float']>;
};

export enum UmdSalesStatus {
  CLOSED = 'CLOSED',
  ON_SALE = 'ON_SALE',
  PREPARING = 'PREPARING',
  SOLD_OUT = 'SOLD_OUT',
  SUSPENDED = 'SUSPENDED',
}

export type UmdSavedProduct = {
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  browsing_type: UmdBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  discount_info?: Maybe<UmdGoodsCardDiscountInfo>;
  discount_rate?: Maybe<Scalars['Int']>;
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  has_coupon: Scalars['Boolean'];
  is_brand: Scalars['Boolean'];
  is_display_not_zpay: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<UxCatalogProductCategory>>;
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  product: UmdProduct;
  review?: Maybe<UmdProductReview>;
  select_option_url?: Maybe<Scalars['String']>;
  sellable_status: UmdSellableStatus;
  shop: UmdShop;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  umd_product_id: Scalars['ID'];
};

export type UmdSavedProductFolder = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnail_url?: Maybe<Scalars['String']>;
};

export type UmdSavedProductFolderList = {
  item_list: Array<UmdSavedProductFolder>;
};

export type UmdSavedProductList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UmdSavedProduct>;
  total_count: Scalars['Int'];
};

export enum UmdSavedProductPosition {
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
}

export type UmdSavedShop = {
  coupon?: Maybe<UmdShopCouponInfo>;
  new_item_count: Scalars['Int'];
  shop: UmdShop;
  tag_list?: Maybe<Array<Scalars['String']>>;
};

export type UmdSavedShopList = {
  coupon_shop_count: Scalars['Int'];
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UmdSavedShop>;
  total_count: Scalars['Int'];
};

export enum UmdSellableStatus {
  CLOSED = 'CLOSED',
  NOT_SUPPORT_COUNTRY = 'NOT_SUPPORT_COUNTRY',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export enum UmdShippingFeeType {
  FREE_SHIPPING = 'FREE_SHIPPING',
  SHOP_POLICY = 'SHOP_POLICY',
}

export type UmdShop = {
  age_list?: Maybe<Array<Scalars['String']>>;
  bookmark_count?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  customer_center_tel?: Maybe<Scalars['String']>;
  delete_action: UmdDeleteAction;
  id: Scalars['ID'];
  is_free_shipping: Scalars['Boolean'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  shop_main_domain: Scalars['String'];
  status: UmdShopStatus;
  style_list?: Maybe<Array<UmdShopStyle>>;
  url: Scalars['String'];
  zpay_status: UmdShopZpayStatus;
};

export type UmdShopCouponInfo = {
  description: Scalars['String'];
  shop_id: Scalars['String'];
  user_account_coupon_policy_id: Scalars['String'];
};

export enum UmdShopFilterType {
  COUPON = 'COUPON',
}

export type UmdShopListOnSavedProduct = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list: Array<UmdShopOnSavedProduct>;
  total_count: Scalars['Int'];
};

export type UmdShopOnSavedProduct = {
  is_saved_shop: Scalars['Boolean'];
  product_count: Scalars['Int'];
  shop: UmdShop;
  shop_tag_list: Array<Scalars['String']>;
};

export enum UmdShopOrderType {
  DATE = 'DATE',
  EDIT = 'EDIT',
  NAME = 'NAME',
  VISIT = 'VISIT',
}

export enum UmdShopStatus {
  BAD_WORKING = 'BAD_WORKING',
  DELETION_REQUEST = 'DELETION_REQUEST',
  DORMANCY = 'DORMANCY',
  NORMAL = 'NORMAL',
  NOT_DEFINED = 'NOT_DEFINED',
  RENEWAL = 'RENEWAL',
  STOPPED = 'STOPPED',
}

export type UmdShopStyle = {
  color: Scalars['String'];
  value: Scalars['String'];
};

export type UmdShopTagCount = {
  count: Scalars['Int'];
  tag: Scalars['String'];
};

export type UmdShopTagCountList = {
  item_list: Array<UmdShopTagCount>;
};

export type UmdShopTagList = {
  item_list: Array<Scalars['String']>;
};

export type UmdShopTagUpdateInfo = {
  suggestion_tag_list: Array<UmdSuggestionShopTagList>;
  tag_list: Array<Scalars['String']>;
};

export enum UmdShopZpayStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type UmdSuggestionShopTagList = {
  item_list: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type UmdTaggedShop = {
  is_saved_shop: Scalars['Boolean'];
  shop: UmdShop;
  tag_list: Array<Scalars['String']>;
};

export type UmdTaggedShopList = {
  item_list: Array<UmdTaggedShop>;
};

export type UmdUxGoodsCard = {
  is_saved: Scalars['Boolean'];
  product: UmdProduct;
  review_count?: Maybe<Scalars['Int']>;
  shop: UmdShop;
};

export type UnsubscribeTalkLoungeKeywordInput = {
  keyword_id: Scalars['ID'];
};

export type UpdateAppsFlyerConversionDataInput = {
  apps_flyer_id?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  media_source?: InputMaybe<Scalars['String']>;
};

export type UpdateDailyMissionUserDocumentInput = {
  /**
   * base64 인코딩 된 신분증 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  identification_file: Scalars['String'];
  /** 주민등록번호 */
  resident_registration_number: Scalars['String'];
  /** 성함 */
  user_name: Scalars['String'];
};

export type UpdateEPickPostInput = {
  description?: InputMaybe<Scalars['String']>;
  post_id: Scalars['ID'];
  product_list?: InputMaybe<Array<EPickPostProductInput>>;
  style_tag?: InputMaybe<Scalars['String']>;
  style_tag_id_list?: InputMaybe<Array<Scalars['ID']>>;
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

/** 에픽 포스트 수정 V2 */
export type UpdateEPickPostInputV2 = {
  /** 본문 */
  description?: InputMaybe<Scalars['String']>;
  /** 게시글 id */
  post_id: Scalars['ID'];
  /** 스타일 태그 id 리스트 */
  style_tag_id_list: Array<Scalars['ID']>;
  /** 태그 리스트 */
  tag_list?: InputMaybe<Array<Scalars['String']>>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateEPickProfileInput = {
  description?: InputMaybe<Scalars['String']>;
  instagram_url?: InputMaybe<Scalars['String']>;
  naver_blog_url?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  youtube_url?: InputMaybe<Scalars['String']>;
};

export type UpdateEPickProfileResponse = {
  message?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type UpdateEventContentReplyInput = {
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 이벤트 댓글 메타데이터 uuid */
  event_content_reply_metadata_uuid: Scalars['String'];
  /** 이벤트 댓글 ID */
  reply_id: Scalars['ID'];
};

/** 이벤트 메타데이터 광고 수신동의 Input */
export type UpdateEventMetadataNotificationInput = {
  /** 동의 여부 */
  agreement: Scalars['Boolean'];
  /**
   * 이벤트를 특정하는 ID
   * 새로운 ID 를 추가할 경우, 백엔드팀에 문의
   */
  event_id_list: Array<Scalars['String']>;
};

export type UpdateFcmRegistrationTokenInput = {
  app_version?: InputMaybe<Scalars['String']>;
  device_language?: InputMaybe<Scalars['String']>;
  device_model?: InputMaybe<Scalars['String']>;
  fcm_registration_token: Scalars['String'];
  os_version?: InputMaybe<Scalars['String']>;
};

export type UpdateFollowInput = {
  enabled: Scalars['Boolean'];
  to_profile_id: Scalars['ID'];
};

/** updateOrderReceiver의 입력 V2 */
export type UpdateOrderReceiverV2Input = {
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 수령자 정보 */
  receiver: ReceiverV2Input;
  /** 배송 메모 */
  shipping_memo?: InputMaybe<Scalars['String']>;
};

/** updateProductReview의 입력 */
export type UpdateProductReviewInput = {
  /** 상품리뷰 첨부파일 목록 */
  attachment_list?: InputMaybe<Array<CreateProductReviewAttachmentInput>>;
  /**
   * 리뷰 첨부파일 URL 목록
   * deprecated: attachment_list 사용
   */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 상품리뷰 특성 평가의 배열 */
  attribute_list: Array<CreateProductReviewInputAttribute>;
  /** 리뷰 내용 */
  contents: Scalars['String'];
  /** 리뷰 레코드 ID */
  id: Scalars['ID'];
  /** 리뷰 평점 */
  rating: Scalars['Int'];
};

export type UpdateProductReviewProfileInput = {
  /**
   * 기본 프로필 이미지 ID(url보다 우선)
   * @deprecated: use image_object_key
   */
  image_id?: InputMaybe<Scalars['ID']>;
  /** 이미지 S3 ObjectKey */
  image_object_key?: InputMaybe<Scalars['String']>;
  /**
   * 프로필 이미지 URL
   * @deprecated: use image_object_key
   */
  image_url?: InputMaybe<Scalars['String']>;
  /** 프로필 공개 여부 */
  is_visible?: InputMaybe<Scalars['Boolean']>;
  /** 프로필 닉네임 */
  nickname?: InputMaybe<Scalars['String']>;
};

/** UpdateProductReviewReply 의 입력 */
export type UpdateProductReviewUserReplyInput = {
  /** 상품리뷰 댓글 내용 */
  contents: Scalars['String'];
  /** 상품리뷰 댓글 ID */
  id: Scalars['ID'];
};

export type UpdateProfileImageUrlInput = {
  profile_image_url: Scalars['String'];
};

export type UpdateQuickProductReviewInput = {
  /** 리뷰 레코드 ID */
  id: Scalars['ID'];
  /** 리뷰 평점 */
  rating: Scalars['Int'];
};

export type UpdateQuickProductReviewResult = {
  /** 생성된 상품 리뷰의 레코드 ID */
  id: Scalars['ID'];
};

/** updateReceiverExchangeRequestInfo의 입력 */
export type UpdateReceiverExchangeRequestInfoInput = {
  /** 상품주문 요청 번호 */
  order_item_request_number: Scalars['String'];
  /** 수령자 정보 */
  receiver: ReceiverInput;
  /** 배송 메모 */
  shipping_memo?: InputMaybe<Scalars['String']>;
};

export type UpdateRewardUserBankAccountInput = {
  bank_account_holder: Scalars['String'];
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
  updated_by?: InputMaybe<Scalars['String']>;
};

export type UpdateRewardUserSettlementDocumentInput = {
  bank_account_holder: Scalars['String'];
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
  /**
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  bankbook_file: Scalars['String'];
  /**
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  business_registration_file: Scalars['String'];
  /** 사업자 번호 */
  business_registration_number: Scalars['String'];
  /**
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  identification_file: Scalars['String'];
  resident_registration_number: Scalars['String'];
  tax_type: RewardUserTaxType;
};

export type UpdateStoryInput = {
  document: Scalars['String'];
  id: Scalars['ID'];
  thumbnail_url?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<StoryType>;
};

/** updateUserAccount의 입력 */
export type UpdateUserAccountInput = {
  /** 이름 */
  full_name?: InputMaybe<Scalars['String']>;
  /** 휴대전화 */
  mobile_tel?: InputMaybe<Scalars['String']>;
};

/** updateUserAccountPassword의 입력 */
export type UpdateUserAccountPasswordInput = {
  /** 신규 비밀번호 */
  new_password: Scalars['String'];
  /** 이전 비밀번호 */
  old_password: Scalars['String'];
};

export type UpdateUserAccountProfileImageInput = {
  /** 유저 프로필 레코드 ID */
  id: Scalars['ID'];
  /** 사용자 프로필 이미지 url */
  profile_image_url?: InputMaybe<Scalars['String']>;
  /** 사용자 계정 ID */
  user_account_id: Scalars['ID'];
};

export type UpdateUserAccountProfileInput = {
  /** 유저 프로필 레코드 ID */
  id: Scalars['ID'];
  /** 사용자 프로필 닉네임 */
  nickname: Scalars['String'];
  /** 사용자 계정 ID */
  user_account_id: Scalars['ID'];
};

/** updateUserAccount의 결과 */
export type UpdateUserAccountResult = {
  /** 성공 여부 */
  success: Scalars['Boolean'];
};

/** updateUserAdNotiStatus의 입력 */
export type UpdateUserAdNotiStatusInput = {
  /** 알림 상태 */
  status: UserNotiStatus;
  /** UUID */
  uuid?: InputMaybe<Scalars['String']>;
};

/** updateUserAdNotiStatus의 출력 */
export type UpdateUserAdNotiStatusResult = {
  /** 앱 푸시 상태 변경 완료 토스트 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 성공여부 */
  success: Scalars['Boolean'];
};

/** updateUserAppNotiStatus의 입력 */
export type UpdateUserAppNotiStatusInput = {
  /** 알림 상태 */
  status: UserNotiStatus;
  /** UUID */
  uuid?: InputMaybe<Scalars['String']>;
};

/** updateUserAppNotiStatus의 출력 */
export type UpdateUserAppNotiStatusResult = {
  /** 성공여부 */
  success: Scalars['Boolean'];
};

/** updateUserLocale의 입력 */
export type UpdateUserLocaleInput = {
  country_id?: InputMaybe<Scalars['ID']>;
  currency_id?: InputMaybe<Scalars['ID']>;
  default_aos_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  default_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  default_ios_codes?: InputMaybe<UserLocaleDefaultCodeInput>;
  language_id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type UpdateUserSaleNotiStatusInput = {
  status: UserNotiStatus;
};

/** 배송 주소록 입력 - changed_default_address_book는 값 변경이 있을때에만 변경된 값으로 전달 */
export type UpdateUserShippingAddressBookInput = {
  /** 주소지 */
  address: Scalars['String'];
  /** 배송지 명 */
  address_name: Scalars['String'];
  /** 변경된 기본 배송지 여부 - 체크박스 변경이 있을 때만 전달필요!! */
  changed_default_address_book?: InputMaybe<Scalars['Boolean']>;
  /** 도시 */
  city?: InputMaybe<Scalars['String']>;
  /** 연락처 */
  contract_number: Scalars['String'];
  /** 국가 코드 */
  country?: InputMaybe<CountryType>;
  /** 상세주소지 */
  detail_address?: InputMaybe<Scalars['String']>;
  /** 수령인(이름) */
  first_name: Scalars['String'];
  /** 주소록 아이디 */
  id: Scalars['ID'];
  /** 수령인(성) */
  last_name: Scalars['String'];
  /** 우편번호 */
  post_code: Scalars['String'];
  /** 지역 */
  state?: InputMaybe<Scalars['String']>;
};

export type UploadInput = {
  /** 업로드할 파일의 확장자명 ex) jpg, mp4 */
  extension: Scalars['String'];
};

/** S3 Upload를 위한 Form Data */
export type UploadLink = {
  /** S3 ACL */
  acl: Scalars['String'];
  /** 업로드 이미지 조회를 위한 cloudfront url */
  cloudfront_url: Scalars['String'];
  /** S3 signed policy and form data */
  fields: Scalars['CrJson'];
  /** S3 formdata upload key prefix. 항상 /로 끝남. 클라이언트에서는 key_prefix와 파일명을 합쳐 FormData.key에 추가 */
  key_prefix: Scalars['String'];
  /** S3 formdata upload url */
  url: Scalars['String'];
};

/** 업로드 링크 범주 */
export enum UploadLinkCategory {
  ORDER_ITEM_INQUIRY = 'ORDER_ITEM_INQUIRY',
  ORDER_ITEM_REQUEST = 'ORDER_ITEM_REQUEST',
  PRODUCT_REVIEW = 'PRODUCT_REVIEW',
}

export type UploadResponse = {
  /** Pre-Signed URL에 업로드 시 사용할 파일명 */
  file_name: Scalars['String'];
  /** S3 Key */
  key: Scalars['String'];
  /** 발행된 Pre-Signed Url */
  pre_signed_url: Scalars['String'];
};

/** 제세공과금 서류 제출 생성/수정 input */
export type UpsertDailyMissionUserDocumentInput = {
  /** 연락처 - 외국인 유저인 경우 필수 */
  contact_tel?: InputMaybe<Scalars['String']>;
  /** 국정 - 외국인 유저인 경우 필수 */
  country?: InputMaybe<Scalars['String']>;
  /**
   * 내국인 : 주민등록증
   * 외국인 : 외국인 등록증
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  identification_file: Scalars['String'];
  /**
   * 외국인 여부
   * true: 외국인, false: 내국인
   */
  is_foreigner: Scalars['Boolean'];
  /**
   * 성인 여부
   * true: 미성년자, false: 성인
   */
  is_under_age: Scalars['Boolean'];
  /**
   * 법정 대리인 동의서 - 미성년자인 경우 필수
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  legal_representative_agreement_file?: InputMaybe<Scalars['String']>;
  /**
   * 법정 대리인 증명 서류 - 미성년자인 경우 필수
   * ------
   * base64 인코딩 된 파일
   * data:image/<이미지확장자>;base64,<data코드>
   */
  proof_of_legal_representative_agreement_file?: InputMaybe<Scalars['String']>;
  /**
   * 내국인 : 주민등록번호
   * 외국인 : 외국인 등록 번호
   */
  resident_registration_number: Scalars['String'];
  /** 이름 */
  user_name: Scalars['String'];
};

/** 날씨 알림 스케쥴 추가 및 수정 input */
export type UpsertDailyMissionWeatherNotificationScheduleInput = {
  /**
   * 알림 시간
   * - 0~23
   */
  hour: Scalars['Int'];
  /**
   * 알림 분
   * - 0~59, 10분단위 설정
   */
  minute: Scalars['Int'];
};

export type UpsertPersonalClearanceCodeInput = {
  /** 개인통관고유부호 */
  personal_clearance_code: Scalars['String'];
};

/** 사용 가능한 쿠폰 목록 정보 */
export type UsableCoupon = {
  /** 쿠폰 제외 대상 여부 */
  coupon_exclude_status?: Maybe<UsableCouponExcludeStatus>;
  /** 상품ID */
  product_id: Scalars['ID'];
  /** 상품 옵션 ID */
  product_item_id: Scalars['ID'];
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  usable_coupon_list: Array<CouponAndDiscountInfo>;
};

/** 쿠폰정보 & 추가정보 */
export type UsableCouponCheckProduct = {
  /** 실제 할인될 금액(정률이라면 계산된 금액) */
  calculated_discount_amount: Scalars['Int'];
  /**
   * 쿠폰 code
   * @deprecated 해당 필드를 사용하여 처리하는 부분이 없음
   */
  code?: Maybe<Scalars['String']>;
  /** 쿠폰 발행일 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 삭제일 */
  date_deactived?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 만료일 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 지급일 */
  date_issued?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 수정일 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 사용일 */
  date_used?: Maybe<Scalars['CrTimestamp']>;
  /** 기존 쿠폰 id */
  deactived_user_account_coupon_id: Scalars['Float'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 사이트 ID (지그재그와 다른 사이트 구분을 위해 사용) */
  site_id: Scalars['Int'];
  /** 쿠폰 상태 */
  status?: Maybe<UserAccountCouponStatus>;
  /** 사용한 사이트 */
  used_site_id?: Maybe<Scalars['Int']>;
  /** 쿠폰 정책 */
  user_account_coupon_policy: UserAccountCouponPolicy;
  /** 쿠폰 정책 레코드 ID */
  user_account_coupon_policy_id: Scalars['ID'];
  /** 사용자 계정 레코드 ID */
  user_account_id: Scalars['ID'];
};

/** 쿠폰 제외 상태 */
export enum UsableCouponExcludeStatus {
  EXCLUDED = 'EXCLUDED',
  NONE = 'NONE',
}

/** 사용 가능한 쿠폰 목록 정보 */
export type UsableCouponList = {
  /** 최대 할인 적용 가능한 쿠폰조합 정보 */
  max_discount_info: MaxDiscountInfo;
  /** 상품별 적용 가능한 쿠폰정보 목록 */
  product_list: Array<UsableCoupon>;
  /** shop 별로 적용 가능한 배송비 쿠폰정보 목록 */
  shipping_coupon_list: Array<UsableShippingCoupon>;
  /** 최대 할인 적용 가능한 쿠폰조합 정보 */
  shipping_max_discount_info: MaxDiscountInfo;
  /** 사용 불가능한 쿠폰 목록 */
  unusable_coupon_list: Array<CouponAndDiscountInfo>;
  /** 사용 불가능한 배송비 쿠폰 목록 */
  unusable_shipping_coupon_list: Array<CouponAndDiscountInfo>;
  /** 사용 가능한 쿠폰 목록 */
  usable_coupon_list: Array<CouponAndDiscountInfo>;
  /** 사용 가능한 배송비 쿠폰 목록 */
  usable_shipping_coupon_list: Array<CouponAndDiscountInfo>;
};

export type UsableCouponListInput = {
  /** 이미 적용된 1차 쿠폰 정보 목록 */
  already_applied_coupon_info_list?: InputMaybe<Array<AlreadyAppliedCouponInfo>>;
  /** 입력된 target 만 계산 */
  only_usable_coupon_target?: InputMaybe<Array<Scalars['String']>>;
  /** 적용 대상 Platform (값이 없으면 필터하지 않음) */
  platform?: InputMaybe<PolicyPlatform>;
  /** 쿠폰 적용 차수(기본값 0) */
  priority?: InputMaybe<Scalars['Int']>;
  /** 상품정보 목록 */
  product_list: Array<CouponProductInfo>;
  /** 배송비 정보 목록 */
  shipping_list?: InputMaybe<Array<CouponShippingInfo>>;
};

export type UsableMileage = {
  /** 스토어 기준 상품 구매에 마일리지 사용 가능한 비율 중 기본값 (3 퍼센트 -> 300으로 표기) */
  default_use_mileage_rate_bp?: Maybe<Scalars['Int']>;
  /** 스토어 기준 상품 구매에 마일리지 사용 가능한 비율 중 셀러가 추가 설정한 값 (7 퍼센트 -> 700으로 표기) */
  seller_specific_use_mileage_rate_bp?: Maybe<Scalars['Int']>;
  /** 스토어 기준 상품 구매에 마일리지 사용 가능한 비율 총합(기본값 + 셀러가 추가 설정한 값) (10 퍼센트 -> 1000으로 표기) */
  total_use_mileage_rate_bp?: Maybe<Scalars['Int']>;
  /** 마일리지 사용 허용 여부. 이 값이 false 이면 마일리지 사용률 정보는 기입하지 않음(null) */
  use_mileage: Scalars['Boolean'];
};

/** 사용 가능한 리딤 쿠폰 정보 */
export type UsableRedeemCouponResult = {
  /** 쿠폰 정책 */
  policy: UserAccountCouponPolicy;
  /** 상품별 적용 가능한 쿠폰정보 목록 */
  product_list: Array<UsableCoupon>;
  /** 사용 불가능한 쿠폰 목록 */
  unusable_coupon_list: Array<UsableCouponCheckProduct>;
  /** 사용 가능한 쿠폰 목록 */
  usable_coupon_list: Array<UsableCouponCheckProduct>;
  /** 쿠폰 정책 */
  user_account_coupon?: Maybe<UserAccountCoupon>;
};

/** 사용 가능한 배송비 쿠폰 목록 정보 */
export type UsableShippingCoupon = {
  /** 배송비 쿠폰이 적용된 상품 옵션 ID LIST */
  product_item_id_list: Array<Scalars['ID']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 사용 불가능한 쿠폰 목록 정보 리스트(최소 주문 금액 미달) */
  unusable_coupon_list: Array<CouponAndDiscountInfo>;
  /** 사용 가능한 쿠폰 목록 정보 리스트 */
  usable_coupon_list: Array<CouponAndDiscountInfo>;
};

/** useShopEventCouponCode의 입력 */
export type UseShopEventCouponCodeInput = {
  /** 사용할 코드 */
  code?: InputMaybe<Scalars['String']>;
  /** 쇼핑몰 이벤트 UUID */
  shop_event_uuid: Scalars['String'];
};

/** useShopEventCouponCode의 출력 */
export type UseShopEventCouponCodeResult = {
  /** 성공여부 */
  success: Scalars['Boolean'];
};

/** 쿠폰 상세정보 */
export type UsedUserAccountCouponInfo = {
  /** 분담 타입 */
  allotment_type?: Maybe<UserAccountCouponAllotmentType>;
  /** 프로모션 코드 */
  code?: Maybe<Scalars['String']>;
  /** 쿠폰 정책 ID */
  coupon_policy_id?: Maybe<Scalars['ID']>;
  /** 쿠폰 사용 만료날짜 */
  date_expire: Scalars['Int'];
  /** 쿠폰 발행 날짜 */
  date_issued: Scalars['Int'];
  /** 쿠폰 설명 */
  description: Scalars['String'];
  /** 쿠폰 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰 할인률 */
  discount_rate_bp: Scalars['Int'];
  /** 할인 유형 */
  discount_type?: Maybe<CouponPolicyDiscountType>;
  /** 쿠폰이 최대로 할인해줄수있는 금액 */
  max_discount_amount: Scalars['Int'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰적용 회차정보 (1차 쿠폰 : 0 , 2차 쿠폰 : 1) */
  priority?: Maybe<Scalars['Int']>;
  /** 쿠폰 발행 주체 타입 */
  publisher_type?: Maybe<UserAccountCouponPublisherType>;
  /** 쇼핑몰 분담 비율 */
  seller_rate_bp?: Maybe<Scalars['Int']>;
  /** 쿠폰의 target_type */
  target_type: CouponTargetType;
  /** 쿠폰 이름 */
  title: Scalars['String'];
  /** 지그재그 분담 비율 */
  zigzag_rate_bp?: Maybe<Scalars['Int']>;
};

/**
 * 지그재그 사용자.
 * 앱을 재설치한 경우 다른 사용자가 만들어진다.
 */
export type User = {
  /** 광고성 푸시 알림 동의 여부 */
  ad_noti_status?: Maybe<AdNotiStatus>;
  /** 기기 푸시 알림 동의 여부 */
  app_noti_status?: Maybe<AppNotiStatus>;
  /** 사용자를 구분하기 위한 UUID(version 4). 사용자 기기에서 생성한다 */
  uuid: Scalars['String'];
};

/** 사용자 계정 */
export type UserAccount = {
  /** 동의한 약관 타입 목록 */
  agreed_term_type_list: Array<UserAccountTermType>;
  /** 애플 계정에 연결되었는지 여부 */
  apple_connected: Scalars['Boolean'];
  /** 본인 인증 여부 */
  authenticated?: Maybe<Scalars['Boolean']>;
  /** 본인인증 된 사용자 이름 */
  authenticated_name?: Maybe<Scalars['String']>;
  /** 사용자 계정 신체 정보 */
  body?: Maybe<UserAccountBody>;
  /** 삭제예정 정보 */
  delete_reservation?: Maybe<DeleteUserAccountReservation>;
  /** 사용자 이메일 */
  email: Scalars['String'];
  /** email 수신 동의 여부 */
  email_reception_agreed?: Maybe<Scalars['Boolean']>;
  /** 페이스북 계정에 연결되었는지 여부 */
  facebook_connected: Scalars['Boolean'];
  /** 사용자 이름 */
  full_name?: Maybe<Scalars['String']>;
  /** 구글 계정에 연결되었는지 여부 */
  google_connected: Scalars['Boolean'];
  /** 비밀번호 유무 */
  has_password: Scalars['Boolean'];
  /** 만14세 미만 법정대리인 미인증으로 인한 계정 삭제 예정 여부 */
  is_delete_reserved?: Maybe<Scalars['Boolean']>;
  /** 카카오 계정 */
  kakao_account?: Maybe<UserKakaoAccount>;
  /** 카카오 계정에 연결되었는지 여부 */
  kakao_connected: Scalars['Boolean'];
  /**
   * 사용자 계정 약관 동의 정보
   * (지정된 site_id - param or session_data 또는 설정된 동의 정보가 없는 경우 반환되지 않음)
   */
  marketing_agreement?: Maybe<UserAccountMarketingAgreement>;
  /** 사용자 휴대폰 번호 */
  mobile_tel?: Maybe<Scalars['String']>;
  /** 야간 혜택 알림 수신 동의 여부 */
  nightly_ad_noti_agreed?: Maybe<Scalars['Boolean']>;
  /** 사용자 계정 리뷰 관련 정보 */
  product_review_info: UserAccountProductReviewInfo;
  /** 사용자 프로필 */
  profile?: Maybe<UserAccountProfile>;
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: Maybe<Scalars['Boolean']>;
  /** 사용자 계정의 약관 동의 정보 목록 */
  term_agreement_list: Array<UserAccountTermAgreement>;
  /** 사용자 계정을 구분하기 위한 UUID(version 4). 서버에서 생성한다 */
  uuid: Scalars['String'];
};

/** 사용자 계정 */
export type UserAccountTerm_Agreement_ListArgs = {
  term_type_list?: InputMaybe<Array<UserAccountTermType>>;
};

/** 성인인증 여부 결과 */
export type UserAccountAdultCertificationResponse = {
  /**
   * 성인인증 여부
   * - 인증을 받지 않았거나 만료된 경우 false
   */
  is_adult: Scalars['Boolean'];
  /**
   * 성인인증 기간만료 여부
   * - 인증을 받았으니 기간이 만료되어서 재인증이 필요한 경우
   */
  is_expired: Scalars['Boolean'];
};

/** 사용자 계정 인증 정보 */
export type UserAccountAuthentication = {
  /** 생년월일 */
  birthday_ymd: Scalars['Int'];
  /** 인증 업데이트 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 외국인 여부 */
  is_foreigner?: Maybe<Scalars['Boolean']>;
  /** 휴대폰 번호 */
  mobile_tel: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 사용자 계정 */
  user_account?: Maybe<UserAccount>;
  /** 인증한 사용자 계정 id */
  user_account_id: Scalars['ID'];
};

/** 사용자 계정 신체 정보 */
export type UserAccountBody = {
  bottom_size: UserAccountBodyValue;
  foot_shape?: Maybe<Array<UserAccountBodyStringValue>>;
  height: UserAccountBodyValue;
  shoe_size: UserAccountBodyValue;
  skin_concern?: Maybe<Array<UserAccountBodyStringValue>>;
  skin_tone?: Maybe<UserAccountBodyStringValue>;
  skin_tone_base?: Maybe<UserAccountBodyStringValue>;
  skin_type?: Maybe<UserAccountBodyStringValue>;
  upper_size: UserAccountBodyValue;
  weight: UserAccountBodyValue;
};

export type UserAccountBodyCandidate = {
  list: Array<UserAccountBodyValue>;
  maximum: UserAccountBodyValue;
  minimum: UserAccountBodyValue;
};

export type UserAccountBodyCandidates = {
  bottom_size: UserAccountBodyCandidate;
  height: UserAccountBodyCandidate;
  shoe_size: UserAccountBodyCandidate;
  upper_size: UserAccountBodyCandidate;
  weight: UserAccountBodyCandidate;
};

/** 사용자 계정 신체정보의 발 형태 */
export enum UserAccountBodyFootShape {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NARROW = 'NARROW',
  NONE = 'NONE',
  WIDE = 'WIDE',
}

/** 사용자 계정 신체정보의 피부 고민 */
export enum UserAccountBodySkinConcern {
  ACNE = 'ACNE',
  ACNE_SCARS = 'ACNE_SCARS',
  BLACKHEAD = 'BLACKHEAD',
  BLEMISHES = 'BLEMISHES',
  DANDRUFF = 'DANDRUFF',
  DARK_CIRCLES = 'DARK_CIRCLES',
  ECZEMA = 'ECZEMA',
  ELASTICITY = 'ELASTICITY',
  EXCESS_SEBUM = 'EXCESS_SEBUM',
  EXFOLIATION = 'EXFOLIATION',
  HAIR_LOSS = 'HAIR_LOSS',
  MELASMA = 'MELASMA',
  NONE = 'NONE',
  OIL_AND_WATER_BALANCE = 'OIL_AND_WATER_BALANCE',
  REDNESS = 'REDNESS',
  SENSITIVITY = 'SENSITIVITY',
  WHITENING = 'WHITENING',
  WRINKLES = 'WRINKLES',
}

/** 사용자 계정 신체정보의 피부 톤 */
export enum UserAccountBodySkinTone {
  AUTUMN_COOL = 'AUTUMN_COOL',
  AUTUMN_WARM = 'AUTUMN_WARM',
  COOL = 'COOL',
  NEUTRAL = 'NEUTRAL',
  SPRING_WARM = 'SPRING_WARM',
  SUMMER_COOL = 'SUMMER_COOL',
  WARM = 'WARM',
}

/** 사용자 계정 신체정보의 피부 톤(베이스 컬러) */
export enum UserAccountBodySkinToneBase {
  FAIR = 'FAIR',
  LIGHT_BEIGE = 'LIGHT_BEIGE',
  MEDIUM_BEIGE = 'MEDIUM_BEIGE',
  TAN = 'TAN',
}

/** 사용자 계정 신체정보의 피부 타입 */
export enum UserAccountBodySkinType {
  ACNE_PRONE = 'ACNE_PRONE',
  COMBINATION = 'COMBINATION',
  DRY = 'DRY',
  EXTREMELY_DRY = 'EXTREMELY_DRY',
  NORMAL = 'NORMAL',
  OILY = 'OILY',
  OILY_BUT_DEHYDRATED = 'OILY_BUT_DEHYDRATED',
  SENSITIVITY = 'SENSITIVITY',
}

export type UserAccountBodyStringValue = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UserAccountBodyValue = {
  label: Scalars['String'];
  value: Scalars['Int'];
};

/** 쓸 수 있는 사용자 쿠폰 */
export type UserAccountCoupon = {
  /** 쿠폰 code */
  code?: Maybe<Scalars['String']>;
  /** 쿠폰 발행일 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 삭제일 */
  date_deactived?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 지급일 */
  date_expire: Scalars['CrTimestamp'];
  /** 쿠폰 지급일 */
  date_issued: Scalars['CrTimestamp'];
  /** 쿠폰 수정일 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 사용일 */
  date_used?: Maybe<Scalars['CrTimestamp']>;
  /** 기존 쿠폰 id */
  deactived_user_account_coupon_id: Scalars['Float'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 사용한 사이트 */
  used_site_id?: Maybe<Scalars['Int']>;
  /** user_account_coupon 의 policy 정보 */
  user_account_coupon_policy: UserAccountCouponPolicy;
  /** 사용자 계정 레코드 ID */
  user_account_id?: Maybe<Scalars['ID']>;
};

export enum UserAccountCouponAllotmentType {
  COMPLEX = 'COMPLEX',
  SELLER = 'SELLER',
  ZIGZAG = 'ZIGZAG',
}

/** 사용자가 사용할 수 있는 쿠폰 목록 */
export type UserAccountCouponList = {
  /** 쿠폰 타입별 개수 */
  count_of_types: CountOfTypes;
  /** user_account_coupon 목록 */
  item_list: Array<UserAccountCoupon>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 쿠폰 목록 정렬 */
export enum UserAccountCouponListOrderType {
  DATE_EXPIRE_ASC = 'DATE_EXPIRE_ASC',
  DATE_EXPIRE_DESC = 'DATE_EXPIRE_DESC',
  DATE_ISSUED_DESC = 'DATE_ISSUED_DESC',
  DATE_USED_DESC = 'DATE_USED_DESC',
  ID_DESC = 'ID_DESC',
  LATEST_USED_OR_EXPIRED = 'LATEST_USED_OR_EXPIRED',
}

/** 쿠폰 정책 */
export type UserAccountCouponPolicy = {
  /** 뱃지 리스트 */
  badge_list: Array<Scalars['String']>;
  /** 쿠폰 뱃지 타입 */
  badge_type: CouponBadgeType;
  /** 정책 칩 목록 */
  chip_list: Array<PolicyChipType>;
  /** 쿠폰 code */
  code?: Maybe<Scalars['String']>;
  /** 쿠폰 CODE TYPE */
  coupon_code_type: CouponCodeType;
  /** 쿠폰 TARGET TYPE */
  coupon_target_type: CouponTargetType;
  /** 쿠폰 정책 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 만료 일시 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 쿠폰 등록 종료일 */
  date_issue_end: Scalars['CrTimestamp'];
  /** 응답 포맷 적용된 쿠폰 등록 종료일 */
  date_issue_end_with_format: Scalars['String'];
  /** 쿠폰 등록 시작일 */
  date_issue_start: Scalars['CrTimestamp'];
  /** 쿠폰 정책 수정일 */
  date_updated: Scalars['CrTimestamp'];
  /** 만료 기간 (일단위), 총 만료 기간은 days_expire + minute_expire 를 합산하여 계산 필요 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 적용 가능 상품 배송 타입 (직진 배송 / 일반 배송) */
  delivery_type?: Maybe<CouponDeliveryType>;
  /** 쿠폰 설명 */
  description?: Maybe<Scalars['String']>;
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 통화 적용된 할인금액 */
  discount_amount_with_currency?: Maybe<CouponPriceWithCurrency>;
  /** 쿠폰 할인률 */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 쿠폰 다운로드 가능 상태 */
  download_status: UserAccountCouponStatus;
  /** user_account_coupon_policy id */
  id: Scalars['ID'];
  /** 중복 쿠폰과 같이 쓸 수 있는 지 여부 */
  is_use_with_duplicate_coupon: Scalars['Boolean'];
  /** Z-Only 대표샵 쿠폰 여부 */
  is_zonly_store_coupon: Scalars['Boolean'];
  /** Item 정보(상품쿠폰일 경우만 해당) */
  item_info?: Maybe<CouponItemInfo>;
  /** 쿠폰 상세 랜딩 URL(현재는 상품 카테고리 쿠폰에만 적용) */
  landing_url?: Maybe<Scalars['String']>;
  /** 쿠폰이 최대로 할인해줄수있는 금액 */
  max_discount_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 만료 기간 (분단위), 총 만료 기간은 days_expire + minute_expire 를 합산하여 계산 필요 */
  minutes_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 주문 가능 수량(선착순) */
  order_limit_count: Scalars['Int'];
  /** 적용된 페널티 그룹 ID */
  penalty_group_id?: Maybe<Scalars['ID']>;
  /** 쿠폰 정책 사용 가능 플랫폼 타입 */
  platform: PolicyPlatform;
  /** 쿠폰적용 회차정보 (1차 쿠폰 : 0 , 2차 쿠폰 : 1) */
  priority: Scalars['Int'];
  /** 쿠폰 정책에 속한 상품들의 카테고리 정보 (상품그룹 쿠폰에서만 활용) */
  product_category?: Maybe<Array<CouponPolicyProductCategory>>;
  /** 쿠폰 발행 주체 */
  publisher: Scalars['String'];
  /** 쿠폰 발급 유저 타입 */
  registration_type: CouponRegistrationType;
  /** 유저당 사용 가능한 횟수(무제한 : -1, 기본값 1) */
  repeatable_use_count: Scalars['Int'];
  /** shop 정보(스토어장바구니, 상품쿠폰일 경우만 해당) */
  shop_info?: Maybe<CouponShopInfo>;
  /** 정책 태그 목록 */
  tag_list: Array<Scalars['String']>;
  target_list?: Maybe<Array<UserAccountCouponTarget>>;
  /** coupon target 목록 */
  targets: Array<UserAccountCouponTarget>;
  /** 쿠폰 title */
  title: Scalars['String'];
  /** 실시간 쿠폰 사용 가능 개수 (선착순 쿠폰인 경우에만 반환됨, 이외의 경우 0) */
  usable_coupon_count: Scalars['Int'];
};

/** 쿠폰 정책 */
export type UserAccountCouponPolicyTarget_ListArgs = {
  filter_duplicate_product?: InputMaybe<Scalars['Boolean']>;
};

/** 쿠폰 정책 발급 상태 */
export enum UserAccountCouponPolicyIssueStatus {
  ISSUE_COMPLETED = 'ISSUE_COMPLETED',
  ISSUE_STOPPED = 'ISSUE_STOPPED',
  ISSUE_WAITING = 'ISSUE_WAITING',
  ISSUING = 'ISSUING',
}

/** 쿠폰 policy의 리스트 */
export type UserAccountCouponPolicyList = {
  item_list: Array<UserAccountCouponPolicy>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export enum UserAccountCouponPublisherType {
  SHOP = 'SHOP',
  ZIGZAG = 'ZIGZAG',
}

/** 쿠폰 상태 */
export enum UserAccountCouponStatus {
  EXPIRED = 'EXPIRED',
  ISSUABLE = 'ISSUABLE',
  ISSUEABLE = 'ISSUEABLE',
  ISSUED = 'ISSUED',
  UNISSUABLE = 'UNISSUABLE',
  USED = 'USED',
}

/** 쿠폰 정책 TARGET */
export type UserAccountCouponTarget = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 상품쿠폰일 경우 상품정보 */
  product_info?: Maybe<CouponCatalogInfo>;
  /** 셀러 분담 분담율 */
  seller_rate_bp?: Maybe<Scalars['Int']>;
  /** shop 레코드 ID */
  shop_id?: Maybe<Scalars['ID']>;
  /** shop 정보 */
  shop_info?: Maybe<CouponShopInfo>;
  /** 쿠폰 target model의 TYPE */
  target_type: Scalars['String'];
  /** 쿠폰 target model의 ID */
  target_uid: Scalars['String'];
  /** 지그재그 부담 분담율 */
  zigzag_rate_bp?: Maybe<Scalars['Int']>;
};

/** 쿠폰 목록 정보 */
export type UserAccountCouponTargetList = {
  /** 대상 목록 */
  item_list: Array<UserAccountCouponTarget>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 사용자 계정 최초 가입경로 */
export enum UserAccountInitType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GENERAL = 'GENERAL',
  GOOGLE = 'GOOGLE',
  KAKAO = 'KAKAO',
}

/** 문의 첨부파일 */
export type UserAccountInquiryAttachment = {
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 첨부파일 원본 URL */
  original_url: Scalars['String'];
  /** 첨부파일 썸네일 URL */
  thumbnail_url: Scalars['String'];
  /** 문의 첨부파일 유형 */
  type: UserAccountInquiryAttachmentType;
};

/** 문의 첨부파일 유형 */
export enum UserAccountInquiryAttachmentType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
}

export type UserAccountMarketingAgreement = {
  /** email 수신 동의 변경 시각 */
  date_email_reception_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 야간 혜택 알림 수신 동의 변경 시각 */
  date_nightly_ad_noti_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 오퍼월 동의 여부 변경 시각 */
  date_offer_wall_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** sms 수신 동의 변경 시각 */
  date_sms_reception_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 제 3자 제공 동의 변경 시각 */
  date_third_provide_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** email 수신 동의 여부 */
  email_reception_agreed?: Maybe<Scalars['Boolean']>;
  /** 야간 혜택 알림 수신 동의 여부 */
  nightly_ad_noti_agreed?: Maybe<Scalars['Boolean']>;
  /** 오퍼월 동의 여부 */
  offer_wall_agreed?: Maybe<Scalars['Boolean']>;
  /** sms 수신 동의 여부 */
  sms_reception_agreed?: Maybe<Scalars['Boolean']>;
  /** 제 3자 제공 동의 여부 */
  third_provide_agreed?: Maybe<Scalars['Boolean']>;
};

export enum UserAccountMetadataCategory {
  COUPON = 'COUPON',
  NOTICE = 'NOTICE',
  ORDER = 'ORDER',
  POINT = 'POINT',
  REVIEW = 'REVIEW',
}

export type UserAccountNotificationShop = {
  /** 스토어 대표 이미지 */
  shop_image?: Maybe<Scalars['String']>;
  /** 스토어명 */
  shop_name?: Maybe<Scalars['String']>;
};

/** 사용자 알림 */
export type UserAccountNotificationShopList = {
  /** 캠페인 ID */
  campaign_id?: InputMaybe<Scalars['String']>;
  /** 사용자 알림 유형 */
  category: Scalars['Int'];
  /** 알림 생성일 */
  date_created: Scalars['String'];
  /** 알림 확인일 */
  date_read?: InputMaybe<Scalars['String']>;
  /** 알림 아이콘 url */
  icon_image_url?: InputMaybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 알림 내용 이미지 url */
  image_url?: InputMaybe<Scalars['String']>;
  /** 알림 내용 이미지 url 목록 */
  image_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 알림 확인 여부 */
  read: Scalars['Boolean'];
  /** 스토어 ID */
  shop_id?: InputMaybe<Scalars['ID']>;
  /** 스토어 알림의 경우 스토어 대표 이미지 */
  shop_image?: InputMaybe<Scalars['String']>;
  /** 스토어 알림의 경우 스토어명 */
  shop_name?: InputMaybe<Scalars['String']>;
  /** 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수 */
  shop_unseen_count?: InputMaybe<Scalars['Int']>;
  /** 사이트 ID */
  site_id?: InputMaybe<Scalars['ID']>;
  /** 알림 표시 text */
  text: Scalars['String'];
  /** 알림 표시 title */
  title?: InputMaybe<Scalars['String']>;
  /** 해당하는 사용자(uuid or user_account_id) */
  uid: Scalars['String'];
  /** Landing url */
  url: Scalars['String'];
};

/** 사용자의 포인트 상세 */
export type UserAccountPoint = {
  /** 만료 날짜 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 등록일 */
  date_issued: Scalars['CrTimestamp'];
  /** 세부 내역 */
  description?: Maybe<Scalars['String']>;
  /** 포인트 이용 내역 포인트 레코드 ID */
  id: Scalars['ID'];
  /** 포인트 원장 레코드 ID */
  origin_id: Scalars['String'];
  /** 포인트 */
  point: Scalars['Int'];
  /** 다통화 포인트 */
  point_with_currency: PointWithCurrency;
  /** 포인트 사용처에서 입력한 UUID */
  target_uid: Scalars['String'];
  /** 타입 */
  type: UserAccountPointType;
  /** 포인트 상세 목록 */
  user_account_point_transaction_list: Array<UserAccountPointTransaction>;
};

/** 조회된 사용자 포인트 데이터 */
export type UserAccountPointInfo = {
  /** 총 사용 가능 포인트 */
  available_point_of_use: Scalars['Int'];
  /** 총 사용 가능 다통화 포인트 */
  available_point_of_use_with_currency: PointWithCurrency;
  /** 지정 기간내 만료 예정 포인트 */
  expire_point: Scalars['Int'];
  /** 지정 기간내 만료 예정 다통화 포인트 */
  expire_point_with_currency: PointWithCurrency;
  /** 총 누적 발급 포인트 */
  total_point_added_types: Scalars['Int'];
  /** 총 누적 만료 포인트 */
  total_point_expired_types: Scalars['Int'];
  /** 총 누적 사용 포인트 */
  total_point_used_types: Scalars['Int'];
};

/** 조회된 사용자 포인트 데이터 */
export type UserAccountPointInfoExpire_PointArgs = {
  days: Scalars['Int'];
};

/** 조회된 사용자 포인트 데이터 */
export type UserAccountPointInfoExpire_Point_With_CurrencyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

/** 조회된 포인트 목록 데이터 */
export type UserAccountPointList = {
  /** 조회된 포인트 목록 */
  item_list: Array<UserAccountPoint>;
  /** 조회된 포인트 목록수 */
  total_count: Scalars['Int'];
};

/** 귀책 사유 책임 */
export enum UserAccountPointRefundResponsibility {
  SELLER = 'SELLER',
  USER = 'USER',
}

/** 리워드 포인트 */
export type UserAccountPointReward = {
  /** 적립양 */
  amount: Scalars['Int'];
  /** 결제 수단 */
  payment_method?: Maybe<UserAccountPointRewardPaymentMethod>;
  /** 리워드 포인트 타입 */
  type: UserAccountPointRewardType;
  /** 포인트 적립 단위 */
  unit: UserAccountPointRewardUnit;
};

/** 포인트 리워드 결제 수단 */
export enum UserAccountPointRewardPaymentMethod {
  BANK = 'BANK',
  CARD = 'CARD',
  PHONE = 'PHONE',
  POINT = 'POINT',
  SIMPLE_BANK = 'SIMPLE_BANK',
  SIMPLE_CARD = 'SIMPLE_CARD',
  VBANK = 'VBANK',
}

/** 사용자 리워드 포인트 타입 */
export enum UserAccountPointRewardType {
  CONFIRM_ORDER = 'CONFIRM_ORDER',
  MEMBERSHIP_GOLD = 'MEMBERSHIP_GOLD',
  MEMBERSHIP_PINK = 'MEMBERSHIP_PINK',
  MEMBERSHIP_SILVER = 'MEMBERSHIP_SILVER',
  MEMBERSHIP_VIP = 'MEMBERSHIP_VIP',
  MEMBERSHIP_VVIP = 'MEMBERSHIP_VVIP',
  REVIEW_PHOTO = 'REVIEW_PHOTO',
  REVIEW_TEXT = 'REVIEW_TEXT',
}

/** 포인트 적립 단위 (BP: 10000분위, POINT: 해당 포인트만큼) */
export enum UserAccountPointRewardUnit {
  BP = 'BP',
  POINT = 'POINT',
}

/** 포인트 */
export type UserAccountPointTransaction = {
  /** 만료 날짜 (적립타입) */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 발생된 날짜 */
  date_issued: Scalars['CrTimestamp'];
  /** 설명 */
  description?: Maybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 포인트 거래 원장 ID */
  origin_id?: Maybe<Scalars['ID']>;
  /** 포인트 */
  point: Scalars['Int'];
  /** 포인트 사용처가 입력한 UUID */
  target_uid: Scalars['String'];
  /** 타입 */
  type: UserAccountPointType;
  /** 사용자 계정 레코드 ID */
  user_account_id: Scalars['ID'];
};

/** 조회된 포인트 목록 데이터 */
export type UserAccountPointTransactionList = {
  /** 조회된 포인트 목록 */
  item_list: Array<UserAccountPointTransaction>;
};

/** 포인트 적립 타입 (적립/사용/만료) */
export enum UserAccountPointType {
  ADDED = 'ADDED',
  ADDED_ADVERTISEMENT_REWARD = 'ADDED_ADVERTISEMENT_REWARD',
  ADDED_COMPENSATION = 'ADDED_COMPENSATION',
  ADDED_CONFIRM_ORDER = 'ADDED_CONFIRM_ORDER',
  ADDED_CONTENT_REWARD = 'ADDED_CONTENT_REWARD',
  ADDED_COUPANG_MISSION_COMPLETE_REWARD = 'ADDED_COUPANG_MISSION_COMPLETE_REWARD',
  ADDED_CREATE_PRODUCT_REVIEW = 'ADDED_CREATE_PRODUCT_REVIEW',
  ADDED_CS_REFUND_COUPON = 'ADDED_CS_REFUND_COUPON',
  ADDED_CS_REFUND_DELIVERY = 'ADDED_CS_REFUND_DELIVERY',
  ADDED_CS_REFUND_NORMAL = 'ADDED_CS_REFUND_NORMAL',
  ADDED_DELIVERY_DELAY_ONE_DAY_COMPENSATION = 'ADDED_DELIVERY_DELAY_ONE_DAY_COMPENSATION',
  ADDED_DELIVERY_DELAY_TODAY_COMPENSATION = 'ADDED_DELIVERY_DELAY_TODAY_COMPENSATION',
  ADDED_DELIVERY_DELAY_ZIGZIN_COMPENSATION = 'ADDED_DELIVERY_DELAY_ZIGZIN_COMPENSATION',
  ADDED_MISSION_AD_CONTENT_COMPLETE_REWARD = 'ADDED_MISSION_AD_CONTENT_COMPLETE_REWARD',
  ADDED_MISSION_AD_QUIZ_COMPLETE_REWARD = 'ADDED_MISSION_AD_QUIZ_COMPLETE_REWARD',
  ADDED_MISSION_COMPLETE_REWARD = 'ADDED_MISSION_COMPLETE_REWARD',
  ADDED_ORDER_FAILED = 'ADDED_ORDER_FAILED',
  ADDED_PARTIAL_REFUND_ORDER = 'ADDED_PARTIAL_REFUND_ORDER',
  ADDED_REFUND_ORDER = 'ADDED_REFUND_ORDER',
  ADDED_REWARD = 'ADDED_REWARD',
  ADDED_SELLER = 'ADDED_SELLER',
  ADDED_SELLER_BEST_REVIEW = 'ADDED_SELLER_BEST_REVIEW',
  ADDED_SELLER_FIRST_PURCHASE = 'ADDED_SELLER_FIRST_PURCHASE',
  ADDED_SELLER_FIRST_REVIEW = 'ADDED_SELLER_FIRST_REVIEW',
  ADDED_SELLER_NORMAL_PURCHASE = 'ADDED_SELLER_NORMAL_PURCHASE',
  ADDED_SELLER_POINT = 'ADDED_SELLER_POINT',
  ADDED_TALK_LOUNGE_ANSWER_ADOPTION_REWARD = 'ADDED_TALK_LOUNGE_ANSWER_ADOPTION_REWARD',
  ADDED_TARGET_EVENT = 'ADDED_TARGET_EVENT',
  ADDED_WELFARE = 'ADDED_WELFARE',
  ADDED_ZIGZIN_COMPENSATION = 'ADDED_ZIGZIN_COMPENSATION',
  EXPIRED = 'EXPIRED',
  EXPIRED_ABUSING = 'EXPIRED_ABUSING',
  USED = 'USED',
  USED_CREATE_TALK_LOUNGE_ASK = 'USED_CREATE_TALK_LOUNGE_ASK',
  USED_ORDER = 'USED_ORDER',
  USED_PARTICIPATE_LUCKY_BALL = 'USED_PARTICIPATE_LUCKY_BALL',
  WITHDRAW = 'WITHDRAW',
  WITHDRAW_CONFIRM_ORDER = 'WITHDRAW_CONFIRM_ORDER',
  WITHDRAW_MANUAL = 'WITHDRAW_MANUAL',
  WITHDRAW_SETOFF = 'WITHDRAW_SETOFF',
}

export type UserAccountProductReviewInfo = {
  /** 작성 제한(불가) 정보 */
  restriction?: Maybe<ProductReviewCreateLimitInfo>;
  /** 작성 제한(주의) 정보 */
  warning?: Maybe<ProductReviewCreateLimitInfo>;
};

export type UserAccountProfile = {
  /** 프로필 최초 생성 시각 */
  date_created: Scalars['CrTimestamp'];
  /** 프로필 이미지 수정 시각 */
  date_image_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 프로필 수정 시각 */
  date_updated: Scalars['CrTimestamp'];
  /** 사용자 프로필 이미지 없을 때 기본 이미지 url */
  default_profile_image_url: Scalars['String'];
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 사용자 프로필 닉네임 */
  nickname: Scalars['String'];
  /** 사용자 프로필 이미지 url */
  profile_image_url?: Maybe<Scalars['String']>;
};

export type UserAccountProfileList = {
  /** 유저 프로필 리스트 */
  item_list: Array<UserAccountProfile>;
  /** 유저 프로필 레코드 개수 */
  total_count: Scalars['Int'];
};

/** 조회된 사용자 환불 포인트 데이터 */
export type UserAccountRefundPointInfo = {
  /** 소멸 포인트 */
  expire_refund_point: Scalars['Int'];
  /** 지정 기간내 만료 예정 다통화 포인트 */
  refund_point_with_currency: PointWithCurrency;
  /** 총 환불 포인트 */
  total_refund_point: Scalars['Int'];
};

export type UserAccountShopNotification = {
  /** 알림 인덱스 */
  shop_item_index?: Maybe<Scalars['Int']>;
  /** 해당 스토어 모아보기 알림 리스트 */
  shop_notification_list?: Maybe<Array<Maybe<UserNotification>>>;
  /** 전체 알림 개수 */
  total_count?: Maybe<Scalars['Int']>;
};

export type UserAccountShopNotificationItem = {
  /** 해당 스토어 모아보기 정보 */
  shop_notification_info?: Maybe<UserAccountShopNotification>;
};

export type UserAccountShopNotificationList = {
  /** 알림 목록 */
  item_list: Array<UserNotification>;
  /** 스토어 정보 */
  shop_info?: Maybe<UserAccountNotificationShop>;
  /** 전체 알림 개수 */
  total_count: Scalars['Int'];
};

export type UserAccountShopSubscribe = {
  /** 처음 스토어 구독한 일시 */
  date_created: Scalars['CrTimestamp'];
  /** 스토어 알림 Push 수신 여부 변경 일시 */
  date_push_agreement_changed: Scalars['CrTimestamp'];
  /** 스토어 구독 상태 변경 일시 */
  date_subscribed_changed: Scalars['CrTimestamp'];
  /** 가장 최근 스토어 구독 or Push 알림 설정 변경 일시 */
  date_updated: Scalars['CrTimestamp'];
  /** 스토어 구독 아이디 */
  id: Scalars['ID'];
  /** 스토어 구독 알림 여부 */
  is_subscribed: Scalars['Boolean'];
  /** 스토어 알림 Push 수신 동의 여부 */
  push_agreed: Scalars['Boolean'];
  /** 스토어 아이디 */
  shop_id: Scalars['ID'];
  /** 사이트 아이디 */
  site_id: Scalars['ID'];
  /** 유저 계정 아이디 */
  user_account_id: Scalars['ID'];
};

/** 스토어 알림 구독 목록 */
export type UserAccountShopSubscribedList = {
  item_list: Array<UserAccountShopSubscribe>;
  total_count: Scalars['Int'];
};

export type UserAccountShopSubscribedType = {
  /** 처음 스토어 구독한 일시 */
  date_created?: Maybe<Scalars['CrTimestamp']>;
  /** 스토어 알림 Push 수신 여부 변경 일시 */
  date_push_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 스토어 구독 상태 변경 일시 */
  date_subscribed_changed?: Maybe<Scalars['CrTimestamp']>;
  /** 가장 최근 스토어 구독 or Push 알림 설정 변경 일시 */
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  /** 스토어 구독 아이디 */
  id?: Maybe<Scalars['ID']>;
  /** 스토어 구독 알림 여부 */
  is_subscribed: Scalars['Boolean'];
  /** 스토어 알림 Push 수신 동의 여부 */
  push_agreed: Scalars['Boolean'];
  /** 스토어 아이디 */
  shop_id: Scalars['ID'];
  /** 사이트 아이디 */
  site_id: Scalars['ID'];
  /** 유저 계정 아이디 */
  user_account_id: Scalars['ID'];
};

/** 사용자 계정 약관 동의 정보 */
export type UserAccountTermAgreement = {
  /** 동의일자 */
  date_agreed: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 약관 유형 */
  term_type: UserAccountTermType;
};

/** 약관 유형 */
export enum UserAccountTermType {
  TEMP = 'TEMP',
  ZPAY_APPROVE_USER_INFO_BEFORE_USER_REFUND_BANK_ACCOUNT = 'ZPAY_APPROVE_USER_INFO_BEFORE_USER_REFUND_BANK_ACCOUNT',
  ZPAY_COLLECT_USER_REFUND_BANK_ACCOUNT = 'ZPAY_COLLECT_USER_REFUND_BANK_ACCOUNT',
  ZPAY_SERVICE = 'ZPAY_SERVICE',
}

/** 사용자 연령대 */
export type UserAgeGroup = {
  /** 사용자 연령 */
  age?: Maybe<Scalars['Int']>;
  /** 사용자 연령대 */
  age_group?: Maybe<AgeGroup>;
  /** 사용자 연령대 bin2 */
  age_group_bin2?: Maybe<AgeGroupBin2>;
  /** 사용자 연령대 bin3 */
  age_group_bin3?: Maybe<AgeGroupBin3>;
};

export type UserApplyInfo = {
  /** 참여 상태 */
  apply_status: RaffleApplyStatus;
  /** 참여 item */
  item?: Maybe<PromotionProductItem>;
  /** 래플 당첨 후기 정보 */
  raffle_review_info?: Maybe<RaffleReviewInfo>;
  /** 래플 추가 응모권 정보 */
  raffle_ticket_info?: Maybe<RaffleTicketInfo>;
};

/** 클레임 요청 그룹 */
export type UserClaimGroup = {
  /** 요청 날짜 */
  date_requested: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 주문서 */
  order: Order;
  /** 주문 키 */
  order_id: Scalars['ID'];
  /** 요청에 해당하는 상품주문 목록 */
  order_item_list: Array<OrderItem>;
  /**
   * 상품주문요청 번호
   * - 동일 클레임 요청 그룹 안의 상품주문요청은 같은 번호를 가짐
   */
  order_item_request_number: Scalars['String'];
};

/** 해당 조건에 맞는 클레임 요청 그룹 목록(취소/반품/교환) */
export type UserClaimGroupList = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 유저의 클레임 요청 그룹 목록 */
  item_list: Array<UserClaimGroup>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

/** 유저 클레임 요청 목록 및 요약 데이터 */
export type UserClaimList = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 유저 클레임 요청 목록 */
  item_list: Array<OrderItemRequest>;
  /** 클레임 개수 */
  total_count: Scalars['Float'];
};

/** 사용자 계정 상품리뷰 페널티 목록 정보 */
export type UserCustomFilterInfo = {
  /** 맞춤필터 화면 구성 컴포넌트 리스트 */
  component_list: Array<ReviewComponent>;
  /** 맞춤 정보 선택 가능 여부 */
  custom_filter_exists: Scalars['Boolean'];
  /** 유저 개인 맞춤정보 체크 여부 */
  my_custom_filter_checked: Scalars['Boolean'];
  /** 유저 개인 맞춤정보 존재 여부 */
  my_custom_filter_exists: Scalars['Boolean'];
  /** 내정보 맞춤정보 노출 문구 정보 */
  my_custom_filter_title?: Maybe<CustomFilterTitleInfo>;
};

/** 사용자별 데이터 */
export type UserData = {
  /** 새로운 쿠폰이 존재하는지 여부 */
  exist_new_coupon: Scalars['Boolean'];
};

/** 유저별 데이터 타입 */
export enum UserDataType {
  EXIST_NEW_COUPON = 'EXIST_NEW_COUPON',
}

/** 유저 추가 금액 결제 계좌 정보 */
export type UserExtraPayment = {
  /** 계좌 소유자 */
  account_holder?: Maybe<Scalars['String']>;
  /** 계좌 번호 */
  account_number?: Maybe<Scalars['String']>;
  /** 은행 코드 */
  bank_code?: Maybe<Scalars['String']>;
};

export type UserInviteStatus = {
  /** 친구초대로 초대인이 적립한 누적 마일리지 */
  awarded_mileage_amount: Scalars['Int'];
  /** 친구초대로 초대인이 적립한 누적 포인트 */
  awarded_point_amount: Scalars['Int'];
  /** 가입한 친구의 수 */
  count_of_invitee: Scalars['Int'];
  /** 친구초대로 초대인이 리워드를 지급 받은 횟수 */
  count_of_promotion_fulfill: Scalars['Int'];
  /**
   * 친구초대로 초대인이 적립한 누적 포인트
   * @deprecated use awarded_point_amount or awarded_mileage_amount
   */
  reward_amount: Scalars['Int'];
};

export type UserKakaoAccount = {
  /** 카카오톡 채널 추가 여부 */
  plus_friends_status?: Maybe<PlusFriendsType>;
  /** 뉴티아라 appUserId */
  provider_id: Scalars['String'];
};

/** 사용자 로케일(국가/언어/통화) 정보 */
export type UserLocale = {
  country?: Maybe<LocaleCode>;
  country_id: Scalars['ID'];
  currency?: Maybe<LocaleCode>;
  currency_id: Scalars['ID'];
  /** ip기준 국가코드 */
  ip_country_code?: Maybe<Scalars['String']>;
  /** 배송국가 설정팝업 노출 여부 */
  is_country_mismatch?: Maybe<Scalars['Boolean']>;
  language?: Maybe<LocaleCode>;
  language_id: Scalars['ID'];
};

/** user_locale이 존재하지 않을 경우 default code 입력값 */
export type UserLocaleDefaultCodeInput = {
  country_code: Scalars['String'];
  currency_code: Scalars['String'];
  language_code: Scalars['String'];
};

export enum UserNotiStatus {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

/** 사용자 알림 */
export type UserNotification = {
  /** 캠페인 ID */
  campaign_id?: Maybe<Scalars['String']>;
  /** 사용자 알림 유형 */
  category: UserNotificationCategory;
  /** 알림 생성일 */
  date_created: Scalars['CrTimestamp'];
  /** 알림 확인일 */
  date_read?: Maybe<Scalars['CrTimestamp']>;
  /** 버튼 등 알림에 부가적인 요소 정보 */
  extra_info?: Maybe<UserNotificationExtraInfo>;
  /** 알림 아이콘 url */
  icon_image_url?: Maybe<Scalars['String']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 알림 내용 이미지 url */
  image_url?: Maybe<Scalars['String']>;
  /** 알림 내용 이미지 url 목록 */
  image_url_list?: Maybe<Array<Scalars['String']>>;
  /** 알림 확인 여부 */
  read: Scalars['Boolean'];
  /** 스토어 ID */
  shop_id?: Maybe<Scalars['ID']>;
  /** 알림 구독한 스토어 정보 */
  shop_info?: Maybe<UserAccountNotificationShop>;
  /** 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수 */
  shop_unseen_count?: Maybe<Scalars['Int']>;
  /** 사이트 ID */
  site_id?: Maybe<Scalars['ID']>;
  /** 알림 표시 text */
  text: Scalars['String'];
  /** 알림 표시 title */
  title?: Maybe<Scalars['String']>;
  /** 해당하는 사용자(uuid or user_account_id) */
  uid: Scalars['String'];
  /** Landing url */
  url: Scalars['String'];
};

/** 알림 내 버튼 정보 */
export type UserNotificationButton = {
  /** 클릭 시 이동 url */
  link_url: Scalars['String'];
  /** 버튼 명 */
  name: Scalars['String'];
};

/** 유저 알림 카테고리 */
export enum UserNotificationCategory {
  BEAUTY = 'BEAUTY',
  DAILY_MISSION = 'DAILY_MISSION',
  EPICK = 'EPICK',
  EXPIRED_USER_BENEFIT = 'EXPIRED_USER_BENEFIT',
  INFORMATION = 'INFORMATION',
  MEMBERSHIP = 'MEMBERSHIP',
  NORMAL = 'NORMAL',
  ORDER = 'ORDER',
  PROMOTION_202406 = 'PROMOTION_202406',
  RESTOCK = 'RESTOCK',
  REVIEW = 'REVIEW',
  SALE_TAB = 'SALE_TAB',
  SAVED_PRODUCTS = 'SAVED_PRODUCTS',
  SHOP = 'SHOP',
  TALK_LOUNGE = 'TALK_LOUNGE',
  WEATHER = 'WEATHER',
  ZPAY = 'ZPAY',
}

export type UserNotificationCategoryList = {
  /** 배송 국가에 해당하는 알림 카테고리 리스트 */
  category_list?: Maybe<Array<UserNotificationCategory>>;
  /** 배송 국가 id */
  country_id?: Maybe<Scalars['ID']>;
};

export type UserNotificationExtraInfo = {
  buttons?: Maybe<Array<UserNotificationButton>>;
};

/** 사용자 알림 목록 */
export type UserNotificationList = {
  /** 알림 목록 */
  item_list: Array<UserNotification>;
  /** 전체 알림 개수 */
  total_count: Scalars['Int'];
  /** 전체 읽지 않은 알림 개수 */
  total_unread_count: Scalars['Int'];
};

/** 사용자에 대한 알림 정보 */
export type UserNotificationMetadata = {
  /** 미확인 알림 수 */
  total_unseen_count: Scalars['Int'];
};

export type UserNotificationPushAgreement = {
  /** 알림 수신 동의 여부 */
  agreed: Scalars['Boolean'];
  /** 알림 카테고리 */
  category_id: UserNotificationCategory;
  /** 수신 동의 변경 시각 */
  date_agreement_changed?: Maybe<Scalars['CrTimestamp']>;
};

export type UserNotificationPushAgreementList = {
  /** 사이트별 알림 수신 동의 리스트 */
  item_list: Array<UserNotificationPushAgreement>;
  /** 알림 수신 동의 레코드 개수 */
  total_count: Scalars['Int'];
};

/** 유저 주문의 혜택 가능 목록 */
export type UserOrderBenefitList = {
  /** 총 혜택 가능 금액 */
  benefit_total_sum: UserOrderBenefitTotalSum;
  /** 유저의 주문 목록 */
  item_list: Array<UserSearchedOrder>;
};

/** 유저 주문의 총 혜택 가능 금액 */
export type UserOrderBenefitTotalSum = {
  /** 총 마일리지 금액 */
  total_estimated_mileage_amount: Scalars['Int'];
  /** 총 포인트 금액 */
  total_estimated_point_amount: Scalars['Int'];
};

/** 배송 전/후 주문 목록 및 요약 데이터 */
export type UserOrderList = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 주문 목록 */
  item_list: Array<Order>;
  /** 주문 개수 */
  total_count: Scalars['Float'];
};

/** feature flag 정보 */
export type UserPayFeatureFlag = {
  /** 할당 feature flag group */
  assigned_feature_flag_group?: Maybe<UserPayFeatureFlagGroup>;
  /** 기능 이름 */
  feature_name: Scalars['String'];
};

/** feature flag 정보 */
export type UserPayFeatureFlagGroup = {
  /** 그룹 이름 */
  group_name: Scalars['String'];
};

/** feature flag 목록 */
export type UserPayFeatureFlagList = {
  /** 조회된 feature flag 목록 */
  item_list: Array<UserPayFeatureFlag>;
  total_count: Scalars['Int'];
};

/** 금융사 목업 */
export type UserPayFinancialCompany = {
  /** 코드 */
  code: Scalars['String'];
  /** 타입 */
  type: Scalars['String'];
};

/** 금융사 */
export enum UserPayFinancialCompanyCode {
  B_BUSAN = 'B_BUSAN',
  B_CITY = 'B_CITY',
  B_DAEGU = 'B_DAEGU',
  B_GWANGJU = 'B_GWANGJU',
  B_GYEONGNAM = 'B_GYEONGNAM',
  B_HANA = 'B_HANA',
  B_HSBC = 'B_HSBC',
  B_IBK = 'B_IBK',
  B_JEJU = 'B_JEJU',
  B_JEONBUK = 'B_JEONBUK',
  B_KAKAOBANK = 'B_KAKAOBANK',
  B_KB = 'B_KB',
  B_KBANK = 'B_KBANK',
  B_KDB = 'B_KDB',
  B_KEB = 'B_KEB',
  B_NH = 'B_NH',
  B_POST = 'B_POST',
  B_SC = 'B_SC',
  B_SHINHAN = 'B_SHINHAN',
  B_SHINHYUP = 'B_SHINHYUP',
  B_SUHYUP = 'B_SUHYUP',
  B_WOORI = 'B_WOORI',
  C_BC = 'C_BC',
  C_CITY = 'C_CITY',
  C_GWANGJU = 'C_GWANGJU',
  C_HANA = 'C_HANA',
  C_HYUNDAI = 'C_HYUNDAI',
  C_JEJU = 'C_JEJU',
  C_JEONBUK = 'C_JEONBUK',
  C_KAKAOBANK = 'C_KAKAOBANK',
  C_KB = 'C_KB',
  C_KBANK = 'C_KBANK',
  C_KDB = 'C_KDB',
  C_LOTTE = 'C_LOTTE',
  C_NH = 'C_NH',
  C_POST = 'C_POST',
  C_SAMSUNG = 'C_SAMSUNG',
  C_SHINHAN = 'C_SHINHAN',
  C_SHINHYUP = 'C_SHINHYUP',
  C_SUHYUP = 'C_SUHYUP',
  C_WOORI = 'C_WOORI',
  UNKNOWN = 'UNKNOWN',
}

/** 금융사 정보 */
export type UserPayFinancialCompanyInput = {
  /** 금융사 코드 */
  code: Scalars['String'];
  /** 금융사 타입 */
  type: Scalars['String'];
};

/** 결제 이벤트 정보 */
export type UserPayPaymentEvent = {
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 종료 날짜 */
  date_ended: Scalars['CrTimestamp'];
  /** 시작 날짜 */
  date_started: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 결제 이벤트 타입 */
  event_type: UserPayPaymentEventType;
  /** 금융사 코드 */
  financial_company_code: UserPayFinancialCompanyCode;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 노출 여부 */
  is_active: Scalars['Boolean'];
  /** 최소 결제 금액 */
  min_amount: Scalars['Int'];
  /** 결제 수단 */
  payment_method: UserPayPaymentMethod;
  /** 결제 PG */
  pg_vendor: PaymentGatewayVendor;
};

/** 결제 이벤트 타입 */
export enum UserPayPaymentEventType {
  PG_COUPON = 'PG_COUPON',
  PG_POINT = 'PG_POINT',
}

/** 결제 지원 금융사 정보 */
export type UserPayPaymentFinancialCompany = {
  /** 활성 이벤트 목록 */
  active_event_list?: Maybe<Array<UserPayPaymentEvent>>;
  /** 생성 날짜 */
  date_created: Scalars['CrTimestamp'];
  /** 수정 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 금융사 */
  financial_company?: Maybe<UserPayFinancialCompany>;
  /** 금융사 코드 */
  financial_company_code: UserPayFinancialCompanyCode;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 무이자할부 목록 */
  interest_free_list?: Maybe<Array<InterestFree>>;
  /** 노출 여부 */
  is_active: Scalars['Boolean'];
  /** 점검여부 */
  is_maintainance?: Maybe<Scalars['Boolean']>;
  /** 최대 할부개월 */
  max_installment_month?: Maybe<Scalars['Int']>;
  /** 결제 수단 */
  payment_method: UserPayPaymentMethod;
  /** 우선 순위 */
  priority: Scalars['Int'];
};

/** 결제 지원 금융사 정보 목록 */
export type UserPayPaymentFinancialCompanyList = {
  /** 조회된 결제 지원 금융사 정보의 목록 */
  item_list: Array<UserPayPaymentFinancialCompany>;
  total_count: Scalars['Int'];
};

/** 결제 지원 금융사 정보 목록 정렬 */
export enum UserPayPaymentFinancialCompanyListOrderType {
  ID_DESC = 'ID_DESC',
  PRIORITY_ASC = 'PRIORITY_ASC',
}

/** 결제 수단 */
export enum UserPayPaymentMethod {
  BANK = 'BANK',
  CARD = 'CARD',
  CONVENIENCE_STORE = 'CONVENIENCE_STORE',
  CONVENIENCE_STORE_JP = 'CONVENIENCE_STORE_JP',
  GLOBAL_CARD = 'GLOBAL_CARD',
  KAKAOPAY = 'KAKAOPAY',
  PHONE = 'PHONE',
  POINT = 'POINT',
  SIMPLE_BANK = 'SIMPLE_BANK',
  SIMPLE_CARD = 'SIMPLE_CARD',
  TOSSPAY = 'TOSSPAY',
  VBANK = 'VBANK',
}

/** 환불 계좌 정보 */
export type UserPayRefundBankAccount = {
  /** 은행 코드 */
  bank_code?: Maybe<Scalars['String']>;
  /** 예금주 */
  holder?: Maybe<Scalars['String']>;
  /** 계좌번호 */
  number?: Maybe<Scalars['String']>;
};

/** 배송지 정보 */
export type UserPayShippingAddressRequestUserPayInput = {
  /** 도시 */
  city?: InputMaybe<Scalars['String']>;
  /** 국가 */
  country?: InputMaybe<Scalars['String']>;
  /** 성 */
  first_name?: InputMaybe<Scalars['String']>;
  /** 이름 */
  last_name?: InputMaybe<Scalars['String']>;
  /** 핸드폰 번호 */
  phone_number?: InputMaybe<Scalars['String']>;
  /** 우편번호 */
  postal_code?: InputMaybe<Scalars['String']>;
  /** state */
  state?: InputMaybe<Scalars['String']>;
  /** 도로주소 */
  street?: InputMaybe<Scalars['String']>;
};

export type UserPostCodeNotice = {
  /** 내용 */
  contents: Scalars['String'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 제목 */
  title: Scalars['String'];
};

export type UserPostboxListResponse = {
  /** 다음 페이지 커서 (값이 없을 경우 end page) */
  end_cursor?: Maybe<Scalars['ID']>;
  /** 알림 목록 */
  item_list: Array<PostboxResponse>;
  /** 한 페이지에 보여질 아이템 수 */
  limit?: Maybe<Scalars['Int']>;
  /** 전체 알림 개수 */
  total_count?: Maybe<Scalars['Int']>;
  /** 전체 읽지 않은 알림 개수 */
  total_unread_count?: Maybe<Scalars['Int']>;
};

export type UserQueryRecommendedSearchKeyword = RecommendedSearchKeyword & {
  /** 추천 검색어 */
  keyword: Scalars['String'];
  /**
   * 하이라이트 된 검색어 텍스트
   * ex) <em>원피스</em>
   */
  keyword_highlighted: Scalars['String'];
  /** 사용자 쿼리 타입 */
  query_type: UserQueryRecommendedSearchKeywordType;
  /** 검색어 타입 */
  type: RecommendedSearchKeywordType;
  /**
   * 타입 문자열
   * ex) 쇼핑몰, 기획전, 이벤트
   */
  type_text?: Maybe<Scalars['String']>;
  /** 아이콘 URL */
  typical_image_url?: Maybe<Scalars['String']>;
  /** 다크모드 아이콘 URL */
  typical_image_url_dark?: Maybe<Scalars['String']>;
};

/** 사용자 키워드 타입 */
export enum UserQueryRecommendedSearchKeywordType {
  CATEGORY = 'CATEGORY',
  INPUT = 'INPUT',
  TREND = 'TREND',
}

export type UserRefundAccount = {
  /** 예금주 명 */
  account_holder: Scalars['String'];
  /** 계좌 번호 */
  account_number: Scalars['String'];
  /** 은행 코드 */
  bank_code: Scalars['String'];
};

/** 앱 랜딩유도 페이지 */
export type UserReportAppLandingComponent = {
  /** 맞춤 추천상품 보러가기 이미지 */
  user_recommend_products_image: Scalars['String'];
  /** 맞춤 추천상품 보러가기 링크 */
  user_recommend_products_image_link: Scalars['String'];
};

/** 전체 혜택 */
export type UserReportBenefitComponent = {
  /** 혜택 정보 */
  benefit_info: Scalars['String'];
  /** 혜택 정보 이미지 */
  benefit_info_image: Scalars['String'];
  /** 이벤트 메타데이터 UUID */
  event_metadata_uuid: Scalars['String'];
};

export type UserReportComponent =
  | UserReportAppLandingComponent
  | UserReportBenefitComponent
  | UserReportFavoriteCategoryComponent
  | UserReportFavoriteColorComponent
  | UserReportFavoriteKeywordComponent
  | UserReportFavoriteStoreComponent
  | UserReportHeaderComponent
  | UserReportShareComponent
  | UserReportShoppingTypeComponent
  | UserReportUserTogetherNDayComponent
  | UserReportZigzagUsingPatternComponent;

/** 쇼핑 유형 */
export enum UserReportComponentType {
  /** 앱 랜딩유도 */
  APP_LANDING = 'AppLanding',
  /** 전체혜택 */
  BENEFIT = 'Benefit',
  /** 최애 아이템(카테고리) */
  FAVORITE_CATEGORY = 'FavoriteCategory',
  /** 최애 아이템(색상) */
  FAVORITE_COLOR = 'FavoriteColor',
  /** 관심 키워드 */
  FAVORITE_KEYWORD = 'FavoriteKeyword',
  /** 최애 스토어 */
  FAVORITE_STORE = 'FavoriteStore',
  /** 헤더 */
  HEADER = 'Header',
  /** 공유하기 */
  SHARE = 'Share',
  /** 쇼핑타입 */
  SHOPPING_TYPE = 'ShoppingType',
  /** 우리 함께한지 n일째 */
  USER_TOGETHER_N_DAY = 'UserTogetherNDay',
  /** 지그재그 사용 패턴 */
  ZIGZAG_USING_PATTERN = 'ZigzagUsingPattern',
}

/** 최애 아이템(카테고리) */
export type UserReportFavoriteCategoryComponent = {
  /** 관심 카테고리 */
  category_name: Scalars['String'];
  /** PDP 방문한 관심 카테고리 상품 수 */
  product_count: Scalars['Int'];
  /** 관심 카테고리 아이콘 이미지 */
  product_icon_image: Scalars['String'];
  /** 관심 카테고리 일러스트 */
  product_image: Scalars['String'];
};

/** 최애 아이템(색상) */
export type UserReportFavoriteColorComponent = {
  /** 선호 색상 */
  favorite_color: Scalars['String'];
  /** 선호 색상 일러스트 */
  favorite_color_image: Scalars['String'];
  /** 선호 색상 아이템 구매 수 */
  favorite_color_viewed_product_count: Scalars['Int'];
  /** 사용자 이름 */
  user_name: Scalars['String'];
};

/** 관심 키워드 */
export type UserReportFavoriteKeywordComponent = {
  /** 동일연령대 1순위 관심 키워드 */
  age_top1_keyword: Scalars['String'];
  /** 동일연령대 2순위 관심 키워드 */
  age_top2_keyword: Scalars['String'];
  /** 동일연령대 3순위 관심 키워드 */
  age_top3_keyword: Scalars['String'];
  /** 연령정보 유무 */
  has_age_info: Scalars['Boolean'];
  /** 사용자 1순위 관심 키워드 */
  top1_keyword: Scalars['String'];
  /** 사용자 2순위 관심 키워드 */
  top2_keyword: Scalars['String'];
  /** 사용자 3순위 관심 키워드 */
  top3_keyword: Scalars['String'];
  /** 사용자 이름 */
  user_name: Scalars['String'];
};

/** 최애 스토어 */
export type UserReportFavoriteStoreComponent = {
  /** 최애 스토어 */
  favorite_store: Scalars['String'];
  /** 최애 스토어 아이콘 이미지 */
  favorite_store_icon_image: Scalars['String'];
  /** 최애 스토어 대표 이미지 */
  favorite_store_image: Scalars['String'];
  /** 상품 구매 수 */
  ordered_product_count: Scalars['Int'];
  /** PDP 방문 상품 수 */
  product_count: Scalars['Int'];
  /** 상품 찜 수 */
  saved_product_count: Scalars['Int'];
  /** PDP 방문스토어 수 */
  store_count: Scalars['Int'];
};

/** 유저레포트 헤더 */
export type UserReportHeaderComponent = {
  /** 사용자 이름 */
  user_name: Scalars['String'];
};

/** 공유하기 */
export type UserReportShareComponent = {
  /** 공유하기 링크 */
  kakaotalk_share_link: Scalars['String'];
  /** 공유하기 로고포함 이미지 */
  share_logo_image: Scalars['String'];
  /** 공유하기 로고없는 이미지 */
  share_plain_image: Scalars['String'];
  /** 공유하기 uid */
  uid: Scalars['String'];
};

/** 쇼핑유형 */
export type UserReportShoppingTypeComponent = {
  /** 사용자 이름 */
  user_name: Scalars['String'];
  /** 분류된 쇼핑 유형 */
  user_shopping_type: Scalars['String'];
  /** 쇼핑 유형 이미지 배경 */
  user_shopping_type_bg_image: Scalars['String'];
  /** 분류된 쇼핑 유형 설명 */
  user_shopping_type_comment: Scalars['String'];
  /** 유형 설명 bold 처리 문구 목록 */
  user_shopping_type_comment_bold_string: Array<Scalars['String']>;
  /** 쇼핑 유형 이미지 */
  user_shopping_type_image: Scalars['String'];
};

/** 우리 함께한지 n일째 */
export type UserReportUserTogetherNDayComponent = {
  /** 회원가입 연도 */
  user_created_year: Scalars['Int'];
  /** 회원가입연도 해당 이벤트 */
  user_created_year_event: Scalars['String'];
  /** 사용 기간 */
  user_days_in_use: Scalars['Int'];
  /** 사용자 이름 */
  user_name: Scalars['String'];
};

/** 지그재그 사용패턴 */
export type UserReportZigzagUsingPatternComponent = {
  /** 가장 많이 사용하는 요일 */
  favorite_day_of_week: Scalars['String'];
  /** 가장 많이 사용하는 요일별 이미지 */
  favorite_day_of_week_image: Scalars['String'];
  /** 가장 많이 사용하는 시간 */
  favorite_hour: Scalars['Int'];
  /** 가장 많이 사용하는 시간 매핑 문구 */
  favorite_hour_comment: Scalars['String'];
};

/** UserRequestCancelAndRefundOrderItemList의 입력 */
export type UserRequestCancelAndRefundOrderItemListInput = {
  /** 취소 후 장바구니 다시 담기 여부 */
  add_to_cart: Scalars['Boolean'];
  /** 첨부파일 URL 목록 */
  attachment_url_list?: InputMaybe<Array<Scalars['String']>>;
  /** 요청 목록 */
  order_item_number_list: Array<Scalars['String']>;
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 환불 계좌 */
  refund_payment?: InputMaybe<RefundPaymentInput>;
  /** 요청 사유 */
  requested_reason?: InputMaybe<Scalars['String']>;
  /** 요청 사유 카테고리 */
  requested_reason_category: RequestedReasonCategory;
};

/** 유저 클레임 */
export type UserSearchedClaim = {
  /** 요청 날짜 */
  date_requested: Scalars['CrTimestamp'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 주문 */
  order: UserSearchedOrder;
  /** 주문 키 */
  order_id: Scalars['ID'];
  /** 상품주문 목록 */
  order_item_list: Array<OrderItem>;
  /** 상품주문요청 번호 (함께 생성된 상품주문요청은 같은 번호를 가짐) */
  order_item_request_number: Scalars['String'];
};

/** 유저 클레임 목록 */
export type UserSearchedClaimList = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 주문 목록 */
  item_list: Array<UserSearchedClaim>;
  /** 검색어 기반 조회 시 다음 페이지 검색을 위한 키 */
  search_cursor?: Maybe<Scalars['String']>;
};

/** 유저 주문 */
export type UserSearchedOrder = {
  /** 국가 정보 code */
  country: CountryType;
  /** 통화 정보 code */
  currency: CurrencyType;
  /** 주문서 생성일자 */
  date_created: Scalars['CrTimestamp'];
  /** 결제 완료일 */
  date_paid?: Maybe<Scalars['CrTimestamp']>;
  /** 결제시 환율 정보 */
  exchange_rate: Scalars['String'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 언어 정보 code */
  language: LanguageType;
  /** 상품주문 목록 */
  order_item_list: Array<OrderItem>;
  /** 주문 번호 */
  order_number: Scalars['String'];
  /** 사이트 ID */
  site_id: Scalars['ID'];
  /** 사용자 계정 레코드 ID */
  user_account_id: Scalars['ID'];
};

/** 검색 API 기반으로 찾은 상품주문 목록 */
export type UserSearchedOrderItemListGraph = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 아이템 목록 */
  item_list: Array<OrderItem>;
  /** 검색어 기반 조회 시 다음 페이지 검색을 위한 키 */
  search_cursor?: Maybe<Scalars['String']>;
};

/** 유저 주문 목록 */
export type UserSearchedOrderList = {
  /** 다음 페이지 존재 여부 */
  has_next: Scalars['Boolean'];
  /** 주문 목록 */
  item_list: Array<UserSearchedOrder>;
  /** 검색어 기반 조회 시 다음 페이지 검색을 위한 키 */
  search_cursor?: Maybe<Scalars['String']>;
};

/** 사용자 배송 주소록 */
export type UserShippingAddressBook = {
  /** 주소지 */
  address: Scalars['String'];
  /** 배송지 명 */
  address_name: Scalars['String'];
  /** 도시 */
  city?: Maybe<Scalars['String']>;
  /** 연락처 */
  contract_number: Scalars['String'];
  /** 국가 정보 code */
  country: CountryType;
  /** 국가번호 */
  country_number: Scalars['String'];
  /** 해당 배송지로 배송한 일자 */
  date_used?: Maybe<Scalars['CrTimestamp']>;
  /** 상세주소지 */
  detail_address?: Maybe<Scalars['String']>;
  /** 공동현관 비밀번호 */
  entrance_password?: Maybe<Scalars['String']>;
  /** 공동현관 출입 유형(비밀번호입력/자유출입) */
  entrance_type?: Maybe<EntranceType>;
  /** 수령인(이름) */
  first_name: Scalars['String'];
  /** 기본 키 */
  id: Scalars['ID'];
  /** 디폴트 셋팅 여부 */
  is_default?: Maybe<Scalars['Boolean']>;
  /** 수령인(성) */
  last_name: Scalars['String'];
  /** 우편번호 */
  post_code: Scalars['String'];
  /** 지역 */
  state?: Maybe<Scalars['String']>;
  /** 사용자 아이디 */
  user_account_id: Scalars['ID'];
};

/** 사용자 배송 주소록 목록 */
export type UserShippingAddressBookList = {
  /** 기본 배송지 */
  default_item?: Maybe<UserShippingAddressBook>;
  /** 최근 배송 목록 */
  item_list?: Maybe<Array<UserShippingAddressBook>>;
};

/** 사용자 배송 메모 */
export type UserShippingMemo = {
  /** 기본 키 */
  id: Scalars['ID'];
  /** 메모 */
  memo: Scalars['String'];
  /** 메모타입(선택지,직접작성) */
  type: ShippingMemoType;
};

/** 사용자가 작성한 배송메모 입력 */
export type UserShippingMemoInput = {
  /** 배송 메모 관리 ID(기존 최근메모 선택시 전달 */
  id?: InputMaybe<Scalars['ID']>;
  /** 배송 메모 */
  memo: Scalars['String'];
};

/** 사용자 배송 메모 목록 */
export type UserShippingMemoList = {
  /** 최근 배송메모 목록 */
  item_list?: Maybe<Array<UserShippingMemo>>;
};

export type UserViewedProductsInfo = {
  /** 상품정보 */
  item_list: Array<UxGoodsCardItem>;
  /** 상품정보 (통합 상품 카드 타입) */
  item_list_v2: Array<ProductCardUxComponent>;
  /** 최근 본 상품이 없을 때 보여줄 메세지 */
  message?: Maybe<Scalars['String']>;
  /** 부제목 */
  subtitle?: Maybe<Scalars['String']>;
  /** 제목 */
  title: Scalars['String'];
};

export type UxAdAgreementSectionData = {
  server_time: Scalars['CrTimestamp'];
};

/** 마케팅 수신 동의 여부 props */
export type UxAdAgreementSectionProps = {
  deeplink_url?: Maybe<Scalars['String']>;
  image_url: Scalars['String'];
  type: UxBenefitSectionType;
};

/** 4.0 HOME 배너 카드형 리턴 타입 */
export type UxAdBannerCard = UxComponent & {
  background_color?: Maybe<UxCommonColor>;
  card_more_button: UxCommonButton;
  column_count?: Maybe<Scalars['Int']>;
  image_ratio?: Maybe<Scalars['Float']>;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  position: Scalars['Int'];
  sub_title?: Maybe<UxCommonText>;
  thumbnail_image_url: UxCommonImageUrl;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxAdDisplayBanner =
  | UxAdDisplayClpBanner
  | UxAdDisplayLargeBanner
  | UxAdDisplayMidBanner
  | UxAdDisplaySmallBanner
  | UxAdDisplayXLargeBanner
  | UxAdDisplayXMidBanner;

export type UxAdDisplayBannerGroup = UxComponent & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxAdDisplayBanner>;
  position: Scalars['Int'];
  type: UxComponentType;
  update_interval?: Maybe<Scalars['Int']>;
};

/** 배너 광고 타입 CLP용 */
export type UxAdDisplayClpBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** "혜택 뱃지 */
  badge?: Maybe<AdBannerBadge>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

/** 배너 광고 타입 LARGE형 */
export type UxAdDisplayLargeBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** "혜택 뱃지 */
  badge?: Maybe<AdBannerBadge>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** "샵 정보 */
  shop: SearchedShop;
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  /** 텍스트 색상 */
  text_color?: Maybe<UxCommonTextColor>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

/** 배너 광고 타입 MID형 */
export type UxAdDisplayMidBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** "혜택 뱃지 */
  badge?: Maybe<AdBannerBadge>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** "샵 정보 */
  shop: SearchedShop;
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  /** 텍스트 색상 */
  text_color?: Maybe<UxCommonTextColor>;
  type: UxComponentType;
};

/** 배너 광고 타입 SMALL형 */
export type UxAdDisplaySmallBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** "혜택 뱃지 */
  badge?: Maybe<AdBannerBadge>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** "샵 정보 */
  shop: SearchedShop;
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

/** 배너 광고 타입 XLARGE형 */
export type UxAdDisplayXLargeBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** "샵 정보 */
  shop: SearchedShop;
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

/** 배너 광고 타입 XMID형 */
export type UxAdDisplayXMidBanner = UxComponent & {
  /** 광고 배너 타입 */
  ad_banner_type: AdBannerType;
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** "혜택 뱃지 */
  badge?: Maybe<AdBannerBadge>;
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 광고 뱃지 유무 */
  is_ad: Scalars['Boolean'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 메인 광고 문구 */
  main_copy: Scalars['String'];
  position: Scalars['Int'];
  /** "샵 정보 */
  shop: SearchedShop;
  /** 서브 광고 문구 */
  sub_copy?: Maybe<Scalars['String']>;
  /** 텍스트 색상 */
  text_color?: Maybe<UxCommonTextColor>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxAdVideoImage = {
  black_bg_image_url?: Maybe<Scalars['String']>;
  white_bg_image_url?: Maybe<Scalars['String']>;
};

export type UxAdVideoItem = {
  comment: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  image?: Maybe<UxAdVideoImage>;
  landing_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  ratio: Scalars['Float'];
  type: AdVideoType;
  ubl?: Maybe<UxUbl>;
  video_url: Scalars['String'];
  width: Scalars['Int'];
};

export type UxBadge = {
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type UxBadgeBorder = {
  border_color: UxCommonColor;
  width: Scalars['Float'];
};

export type UxBadgeComponent = {
  /**
   * 뱃지 컴포넌트 타입
   * - ICON_TEXT
   * - IMAGE
   */
  type: Scalars['String'];
};

export type UxBadgeNormal = {
  background_color: Scalars['String'];
  background_opacity: Scalars['Float'];
  border?: Maybe<UxBorder>;
  /** 폰트 굵기(REGULAR, MEDIUM, SEMI_BOLD, BOLD) */
  font_weight: Scalars['String'];
  size: UxBadgeNormalSize;
  text: Scalars['String'];
  text_color: Scalars['String'];
};

export enum UxBadgeNormalSize {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
}

export type UxBandBanner = UxComponent & {
  common_title: UxCommonText;
  id: Scalars['ID'];
  landing_url: Scalars['String'];
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxBannerItem = UxComponent & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  component_list?: Maybe<Array<UxComponent>>;
  image_url?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  label_color?: Maybe<Scalars['Float']>;
  landing_link?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  shop_main_domain?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: UxComponentType;
};

/** 세일탭 배너 */
export type UxBenefitBanner =
  | UxBenefitMainBanner
  | UxBenefitPinLightBanner
  | UxBenefitSquarePickBanner
  | UxBenefitStrapBanner
  | UxBenefitSubBanner;

/** 세일탭 배너 뱃지 정보 */
export type UxBenefitBannerBadge = {
  color: Scalars['String'];
  text: Scalars['String'];
};

/** 세일탭 배너 노출 앱 버전 */
export type UxBenefitBannerDisplayAppVersion = {
  aos?: Maybe<Scalars['String']>;
  ios?: Maybe<Scalars['String']>;
};

export type UxBenefitBannerMyPickCouponItem = {
  /** 쿠폰 셋 ID */
  coupon_set_id: Scalars['ID'];
  /** 할인 정보 */
  discount_info: Scalars['String'];
  /** 스토어 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  /** 스토어명 */
  name: Scalars['String'];
  /** 스토어 banner_image_url */
  shop_banner_image_url?: Maybe<Scalars['String']>;
  /** 스토어 ID */
  shop_id: Scalars['ID'];
  /** 스토어 스타일 */
  style?: Maybe<Scalars['String']>;
};

export enum UxBenefitBannerOs {
  AOS = 'AOS',
  IOS = 'IOS',
}

export enum UxBenefitBannerRequestOrderType {
  /** 할인율순 */
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  /** 최신순 */
  LATEST = 'LATEST',
  /** 추천순(백오피스 설정값 기준) */
  RECOMMEND = 'RECOMMEND',
}

/** 세일탭 배너 Shop 정보 */
export type UxBenefitBannerShop = {
  id: Scalars['Int'];
  name: Scalars['String'];
  typical_image_url: Scalars['String'];
};

/** 세일탭 배너 태그 정보 */
export type UxBenefitBannerTag = {
  color: Scalars['String'];
  text: Scalars['String'];
};

/** 세일탭 배너 타입 */
export enum UxBenefitBannerType {
  MAIN_BANNER = 'MAIN_BANNER',
  PIN_LIGHT_BANNER = 'PIN_LIGHT_BANNER',
  SQUAREPICK_BANNER = 'SQUAREPICK_BANNER',
  STRAP_BANNER = 'STRAP_BANNER',
  SUB_BANNER = 'SUB_BANNER',
}

/** 혜택존 쿠폰 정보 */
export type UxBenefitCoupon = {
  /** 만료 기간 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 기본 키 */
  id: Scalars['ID'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
};

/** 세일탭 메인배너타입 */
export type UxBenefitMainBanner = {
  content_name?: Maybe<Scalars['String']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  display_app_version_lt: UxBenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: UxBenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

export type UxBenefitMyPickCouponListIssuedInfo = {
  /** 쿠폰 만료 시간 */
  coupon_policy_end_date: Scalars['CrTimestamp'];
};

/** 세일탭 핀라이트배너타입 */
export type UxBenefitPinLightBanner = {
  badge_list?: Maybe<Array<UxBenefitBannerBadge>>;
  benefit_section_content?: Maybe<Scalars['String']>;
  content_name?: Maybe<Scalars['String']>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent?: Maybe<Scalars['Int']>;
  display_app_version_lt: UxBenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: UxBenefitBannerShop;
  store_pick_id?: Maybe<Scalars['Int']>;
  tag_list?: Maybe<Array<UxBenefitBannerTag>>;
  title: Scalars['String'];
  type: UxBenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

export enum UxBenefitProductBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

export type UxBenefitProductCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** ux 스키마에서 UxGoodsCardItem 그대로 가져와서 사용 */
export type UxBenefitProductCardItem = UxBenefitProductComponent & {
  /** 광고 id */
  aid?: Maybe<Scalars['String']>;
  /**
   * 찜하기 여부
   * 아직 사용x
   */
  bookmarked: Scalars['Boolean'];
  /**
   * 광고 1.0에서 bridge page 노출 여부
   * 사실상 사용 x
   */
  bridge?: Maybe<Scalars['Boolean']>;
  /** 앱 내부에서 어떤 browser 로 PDP를 띄울지 여부 */
  browsing_type: UxBenefitProductBrowsingType;
  /** catalog_product_id */
  catalog_product_id?: Maybe<Scalars['ID']>;
  /** 상품의 type */
  catalog_product_type?: Maybe<UxBenefitProductCatalogProductType>;
  /** 상품이 한줄에 몇개 표기할지 */
  column_count?: Maybe<Scalars['Int']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<UxBenefitProductCardDiscountInfo>;
  /** 상품의 할인율 */
  discount_rate?: Maybe<Scalars['Int']>;
  /** 모든 할인이 적용된 최종 가격 */
  final_price: Scalars['Int'];
  /** 무료배송 여부 */
  free_shipping?: Maybe<Scalars['Boolean']>;
  /** goods_id */
  goods_id?: Maybe<Scalars['String']>;
  /** 쿠폰 사용 여부 */
  has_coupon: Scalars['Boolean'];
  /** 이미지 비율 */
  image_ratio?: Maybe<Scalars['Float']>;
  /** 원본 image_url */
  image_url?: Maybe<Scalars['String']>;
  /** 브랜드 상품 여부 */
  is_brand: Scalars['Boolean'];
  /** zonly 상품 여부 */
  is_zonly: Scalars['Boolean'];
  /** zpay할인가 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** log */
  log?: Maybe<Scalars['String']>;
  /** 연동된 가격중 가장 높은 가격 */
  max_price: Scalars['Int'];
  /**
   * goods_id, 쇼핑몰의 product_no 등이 사용되는데
   * 정확하게 사용하는곳 x
   */
  origin_id?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  /**
   * 상품 가격
   * final_price 생기면서 사용x
   */
  price?: Maybe<Scalars['Int']>;
  /** 상품 클릭시 이동할 url 주소 */
  product_url?: Maybe<Scalars['String']>;
  /** 상품 카드에 숫자를 표기하기 위한 필드 */
  ranking?: Maybe<Scalars['Int']>;
  /** 상품의 리뷰 갯수 */
  review_count?: Maybe<Scalars['Int']>;
  /** Catalog 상품의 상태 (판매중, 품절) */
  sales_status?: Maybe<UxBenefitProductSearchedProductSalesStatus>;
  /** 쇼핑몰 id */
  shop_id: Scalars['ID'];
  /** 쇼핑몰에서 부여한 고유 product_no */
  shop_product_no?: Maybe<Scalars['String']>;
  /** Goods 상품의 상태 (판매중, 품절) */
  status?: Maybe<UxBenefitProductGoodsStatus>;
  /** 상품 이름 */
  title?: Maybe<Scalars['String']>;
  /** 컴포넌트 type */
  type: UxBenefitProductComponentType;
  ubl?: Maybe<UxUbl>;
  /**
   * 쇼핑몰 메인 도메인과 product_no
   * ex. crealfit$333
   * 사실상 사용 x
   */
  uuid?: Maybe<Scalars['String']>;
  /** 상품 카드에 비디오 형식으로 이미지 노출 (광고팀에서 사용) */
  video_url?: Maybe<Scalars['String']>;
  /** 썸네일화 webp_image_url */
  webp_image_url?: Maybe<Scalars['String']>;
  /** zpay 상품 여부 */
  zpay?: Maybe<Scalars['Boolean']>;
  /** zpay 할인가가 적용된 가격 */
  zpay_price?: Maybe<Scalars['Int']>;
};

/** 혜택존 catalog_product type */
export enum UxBenefitProductCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** Z-ONE 상품 */
  ZONLY = 'ZONLY',
}

export type UxBenefitProductComponent = {
  position: Scalars['Int'];
  type: UxBenefitProductComponentType;
};

export enum UxBenefitProductComponentType {
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

/** 상품 상태 */
export enum UxBenefitProductGoodsStatus {
  /** 삭제: 상품 페이지가 존재하지 않는 상품 */
  DELETED = 'DELETED',
  /** EC상품 품절: EC에서만 노출되는 품절 상품 */
  EC_SOLD_OUT = 'EC_SOLD_OUT',
  /** 숨김: 셀러가 노출하지 않기를 원하는 상품 */
  HIDDEN = 'HIDDEN',
  /** 정상 */
  NORMAL = 'NORMAL',
  /** 품절: 상품 페이지는 존재하지만 품절 처리된 상품 */
  SOLD_OUT = 'SOLD_OUT',
}

/** 혜택존 catalog_product sales_status */
export enum UxBenefitProductSearchedProductSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

/** 혜택존 섹션 data */
export type UxBenefitSectionData =
  | UxAdAgreementSectionData
  | UxBigProductSlideSectionData
  | UxMainBannerGroupSectionData
  | UxMyCouponSectionData
  | UxMyPickCouponListSectionData
  | UxPinLightBannerSlideSectionData
  | UxProductCustomTabsSectionData
  | UxProductGridSectionData
  | UxProductsCategoryTabsSectionData
  | UxProductsSliderSectionData
  | UxSquarePickBannerGroupSectionData
  | UxStoreCouponPriceTabsSectionData
  | UxStoreCouponSlideSectionData
  | UxStoreCouponTabsSectionData
  | UxStrapBannerGroupSectionData
  | UxSubBannerGroupSectionData
  | UxTimedealSectionData
  | UxTopImageProductGridSectionData;

export type UxBenefitSectionDataResult = {
  data: UxBenefitSectionData;
};

/** 혜택존 섹션 props */
export type UxBenefitSectionProps =
  | UxAdAgreementSectionProps
  | UxBigProductSlideSectionProps
  | UxMainBannerGroupSectionProps
  | UxMyCouponSectionProps
  | UxMyPickCouponListSectionProps
  | UxPinLightBannerSlideSectionProps
  | UxProductCustomTabsSectionProps
  | UxProductGridSectionProps
  | UxProductsCategoryTabsSectionProps
  | UxProductsSliderSectionProps
  | UxSquarePickBannerGroupSectionProps
  | UxStoreCouponPriceTabsSectionProps
  | UxStoreCouponSlideSectionProps
  | UxStoreCouponTabsSectionProps
  | UxStrapBannerGroupSectionProps
  | UxSubBannerGroupSectionProps
  | UxTimedealSectionProps
  | UxTopImageProductGridSectionProps;

export type UxBenefitSectionPropsResult = {
  data: Array<UxBenefitSectionTypeProps>;
};

/** 혜택존 섹션 타입 */
export enum UxBenefitSectionType {
  AD_AGREEMENT_SECTION = 'AdAgreementSection',
  BIG_PRODUCT_SLIDE_SECTION = 'BigProductSlideSection',
  MAIN_BANNER_GROUP_SECTION = 'MainBannerGroupSection',
  MY_COUPON_SECTION = 'MyCouponSection',
  MY_PICK_COUPON_LIST_SECTION = 'MyPickCouponListSection',
  PIN_LIGHT_BANNER_SLIDE_SECTION = 'PinLightBannerSlideSection',
  PRODUCT_CUSTOM_TABS_SECTION = 'ProductCustomTabsSection',
  PRODUCT_GRID_SECTION = 'ProductGridSection',
  PRODUCTS_CATEGORY_TABS_SECTION = 'ProductsCategoryTabsSection',
  PRODUCTS_SLIDER_SECTION = 'ProductsSliderSection',
  SQUARE_PICK_BANNER_GROUP_SECTION = 'SquarePickBannerGroupSection',
  STORE_COUPON_PRICE_TABS_SECTION = 'StoreCouponPriceTabsSection',
  STORE_COUPON_SLIDE_SECTION = 'StoreCouponSlideSection',
  STORE_COUPON_TABS_SECTION = 'StoreCouponTabsSection',
  STRAP_BANNER_GROUP_SECTION = 'StrapBannerGroupSection',
  SUB_BANNER_GROUP_SECTION = 'SubBannerGroupSection',
  TIMEDEAL_SECTION = 'TimedealSection',
  TOP_IMAGE_PRODUCT_GRID_SECTION = 'TopImageProductGridSection',
}

export type UxBenefitSectionTypeProps = {
  /** benefit_section UUID */
  benefit_section_uuid: Scalars['String'];
  /** benefit_section ID */
  id: Scalars['Int'];
  /** benefit_section type에 따른 props */
  props: UxBenefitSectionProps;
  /** 해당 섹션에 대한 정렬 순번 */
  section_sort_number: Scalars['Int'];
  /** benefit_section type */
  type: UxBenefitSectionType;
};

/** 스토어검색API 스키마에서 미사용 필드만 제거 */
export type UxBenefitShop = {
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** 세일탭 스퀘어픽배너타입 */
export type UxBenefitSquarePickBanner = {
  badge_list?: Maybe<Array<UxBenefitBannerBadge>>;
  content_name?: Maybe<Scalars['String']>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent: Scalars['Int'];
  display_app_version_lt: UxBenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: UxBenefitBannerShop;
  store_pick_id?: Maybe<Scalars['Int']>;
  tag_list?: Maybe<Array<UxBenefitBannerTag>>;
  title: Scalars['String'];
  type: UxBenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

/** 세일탭 스트랩배너타입 */
export type UxBenefitStrapBanner = {
  content_name?: Maybe<Scalars['String']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  display_app_version_lt: UxBenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  title: Scalars['String'];
  type: UxBenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

/** 세일탭 서브배너타입 */
export type UxBenefitSubBanner = {
  badge_list?: Maybe<Array<UxBenefitBannerBadge>>;
  content_name?: Maybe<Scalars['String']>;
  coupon_tag?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['CrTimestamp'];
  date_ended: Scalars['CrTimestamp'];
  date_started: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  discount_percent: Scalars['Int'];
  display_app_version_lt: UxBenefitBannerDisplayAppVersion;
  enabled: Scalars['Boolean'];
  free_shipping_tag?: Maybe<Scalars['Boolean']>;
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  order: Scalars['Int'];
  shop_data: UxBenefitBannerShop;
  tag_list?: Maybe<Array<UxBenefitBannerTag>>;
  title: Scalars['String'];
  type: UxBenefitBannerType;
  user_updated: Scalars['String'];
  uuid: Scalars['String'];
};

/** 4.0 HOME 오늘의 혜택 리턴 타입 */
export type UxBenefitsCard = UxComponent & {
  card_more_button: UxCommonButton;
  column_count?: Maybe<Scalars['Int']>;
  image_ratio?: Maybe<Scalars['Float']>;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  position: Scalars['Int'];
  sub_title?: Maybe<UxCommonText>;
  thumbnail_image_url: UxCommonImageUrl;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxBigProductSlideSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxBigProductSlideSectionProps = {
  badge_type: Scalars['String'];
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  date_started?: Maybe<Scalars['CrTimestamp']>;
  products_preset_uuid: Scalars['String'];
  server_time?: Maybe<Scalars['CrTimestamp']>;
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxBookmarkShopFilter = {
  sorting_id?: InputMaybe<Scalars['ID']>;
};

export type UxBookmarkShopList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  is_reorderable: Scalars['Boolean'];
  item_list: Array<DdpBookmarkShopItem>;
  sorting_list: Array<DdpFilterItem>;
  total_count: Scalars['Int'];
};

export type UxBorder = {
  color: Scalars['String'];
  /** 테두리 둥글기(px) */
  radius: Scalars['Float'];
  /** 테두리 스타일 (ex. solid, dashed) */
  style: Scalars['String'];
  /** 테두리 두께(px) */
  width: Scalars['Float'];
};

export type UxBrand = {
  id: Scalars['ID'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  shop_main_domain: Scalars['String'];
  url: Scalars['String'];
};

export type UxBrandChipProductListGroup = UxComponent & {
  brand_list: Array<UxCommonBrandChip>;
  header_title?: Maybe<UxCommonText>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxBrandRankingInfo = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxShopRankingCardItem>;
};

export type UxBrandRankingList = {
  has_more: Scalars['Boolean'];
  item_list: Array<UxShopRanking>;
  updated_at?: Maybe<Scalars['CrTimestamp']>;
};

export type UxBrandRecommendGroup = UxComponent & {
  brand_list?: Maybe<Array<UxBrand>>;
  langing_url?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  type: UxComponentType;
};

/** 브랜드관 TC 디자인 타입 */
export enum UxBrandTargetContentDesignType {
  /** 텍스트가 가운데 정렬되어있는 타입 */
  COVER_CENTER = 'COVER_CENTER',
  /** 텍스트가 왼쪽 정렬되어있는 타입 */
  COVER_LEFT = 'COVER_LEFT',
  /** 메거진 컨텐츠 형태의 타입 */
  MAGAZINE = 'MAGAZINE',
}

/** 브랜드관 TC 메인 배너 */
export type UxBrandTargetContentMainBanner = {
  /** 랜딩 URL */
  landing_url?: Maybe<Scalars['String']>;
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  /** 컨텐츠 픽셀 비율(가로픽셀에 대한 세로픽셀) */
  ratio?: Maybe<Scalars['Float']>;
  /** 타입이 영상일 경우 스틸이미지 포함 */
  still_image_url?: Maybe<Scalars['String']>;
  /** TC 배너 ID */
  tc_banner_id?: Maybe<Scalars['ID']>;
  type: UxBrandTargetContentMainBannerType;
  ubl?: Maybe<UxUbl>;
  /** 컨텐츠 URL */
  url?: Maybe<Scalars['String']>;
};

/** 브랜드관 TC 메인 배너 타입 */
export enum UxBrandTargetContentMainBannerType {
  GOODS_ONLY = 'GOODS_ONLY',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

/** 브랜드관 테마뷰타입(타겟컨텐츠) */
export type UxBrandThemeContent = UxComponent & {
  action_button?: Maybe<UxButton>;
  design_type: UxBrandTargetContentDesignType;
  /** 브랜드관 TC 기획전 네이밍 */
  exhibition_name?: Maybe<UxText>;
  goods_carousel?: Maybe<UxGoodsCarousel>;
  /** 사용 목적 타입 */
  group_type: Scalars['String'];
  main_banner: UxBrandTargetContentMainBanner;
  main_title?: Maybe<UxText>;
  position: Scalars['Int'];
  sub_title?: Maybe<UxText>;
  tc_id: Scalars['ID'];
  type: UxComponentType;
};

export enum UxBrowsingType {
  DETAIL_BROWSER = 'DETAIL_BROWSER',
  INTERNAL_BROWSER = 'INTERNAL_BROWSER',
  NORMAL = 'NORMAL',
  Z_BROWSER = 'Z_BROWSER',
}

/** 버튼 컴포넌트 */
export type UxButton = UxComponent & {
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  /** 딥링크 URL */
  link_url: Scalars['String'];
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  /** 버튼 스타일 */
  style?: Maybe<UxButtonStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

/** UxButton 스타일 */
export enum UxButtonStyle {
  BUTTON_DEFAULT = 'BUTTON_DEFAULT',
  BUTTON_DEFAULT_NUM = 'BUTTON_DEFAULT_NUM',
  BUTTON_SMALL = 'BUTTON_SMALL',
  BUTTON_SMALL_NUM = 'BUTTON_SMALL_NUM',
  DEFAULT_L = 'DEFAULT_L',
  DEFAULT_M = 'DEFAULT_M',
  DEFAULT_S = 'DEFAULT_S',
  EXTENDED_TEXT_S_ARROW = 'EXTENDED_TEXT_S_ARROW',
  PRIMARY_L = 'PRIMARY_L',
  PRIMARY_M = 'PRIMARY_M',
  PRIMARY_S = 'PRIMARY_S',
  SECONDARY_L = 'SECONDARY_L',
  SECONDARY_M = 'SECONDARY_M',
  SECONDARY_S = 'SECONDARY_S',
  SQUARE_DEFAULT_L = 'SQUARE_DEFAULT_L',
  SQUARE_SECONDARY_L = 'SQUARE_SECONDARY_L',
  TOGGLE_M = 'TOGGLE_M',
  TOGGLE_S = 'TOGGLE_S',
}

export type UxCatalogCroppedEssentials = {
  height?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  info_index: Scalars['Int'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UxCatalogCustomInputOption = {
  id?: Maybe<Scalars['ID']>;
  /** 값 입력 제한 */
  max_length?: Maybe<Scalars['Int']>;
  /** 상품 옵션명 */
  name: Scalars['String'];
  /** 필수 여부 */
  required: Scalars['Boolean'];
};

export enum UxCatalogDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type UxCatalogEssentials = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UxCatalogItem = {
  /** 품목 노출 상태 */
  display_status: UxCatalogItemDisplayStatus;
  id: Scalars['ID'];
  /** 품목의 부가 속성 리스트 */
  item_attribute_list: Array<UxCatalogItemAttribute>;
  /** 품목 코드 */
  item_code?: Maybe<Scalars['String']>;
  /** 품목의 재고 */
  item_inventory?: Maybe<UxCatalogItemInventory>;
  /** 품목명 */
  name: Scalars['String'];
  /** 품목 값 */
  price: Scalars['Int'];
  /** 품목 값 차액 */
  price_delta: Scalars['Int'];
  /** 품목 값 차액 */
  price_delta_with_currency: PriceWithCurrency;
  /** 품목 값 */
  price_with_currency: PriceWithCurrency;
  /** 품목 판매 상태 */
  sales_status: UxCatalogSalesStatus;
  /** 재입고 알림 여부 */
  wms_notification_info?: Maybe<UxWmsNotificationInfo>;
};

export type UxCatalogItemAttribute = {
  /** 대응되는 상품 옵션 ID */
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 속성 값 */
  value: Scalars['String'];
};

export enum UxCatalogItemDisplayStatus {
  /** 숨김 */
  HIDDEN = 'HIDDEN',
  /** 노출 */
  VISIBLE = 'VISIBLE',
}

export type UxCatalogItemInventory = {
  /** 판매된 재고 누적량 */
  assigned_quantity: Scalars['Int'];
  deleted: Scalars['Boolean'];
  /** 총 재고 */
  total_quantity: Scalars['Int'];
};

export enum UxCatalogPaymentType {
  /** 외부 결제 */
  EXTERNAL = 'EXTERNAL',
  /** Z-PAY */
  ZPAY = 'ZPAY',
}

export type UxCatalogProduct = {
  /** 뱃지 리스트 */
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 브랜드 id */
  brand_id?: Maybe<Scalars['ID']>;
  /** 브랜드 네임 우측 뱃지 리스트 */
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 브라우저 타입 */
  browsing_type: UxBrowsingType;
  /** 카테고리 리스트 */
  category_list?: Maybe<Array<UxCatalogProductCategory>>;
  /** 파트너센터에서 등록한 상품 color 정보 */
  color_image_list: Array<UxGoodsColorImage>;
  /** 쿠폰 상태 */
  coupon_available_status: UxCouponAvailableStatus;
  /** 상품의 생성 일자 */
  created_at?: Maybe<Scalars['CrTimestamp']>;
  /** 상품정보제공 고시 crop 이미지 */
  cropped_essentials?: Maybe<Array<UxCatalogCroppedEssentials>>;
  /** 사용자 입력 옵션 리스트 */
  custom_input_option_list: Array<UxCatalogCustomInputOption>;
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 글로벌 대응 한글 설명 */
  detail_info?: Maybe<Scalars['String']>;
  /** 할인가 표기 정보 */
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  /** 상품 노출 상태 */
  display_status: UxCatalogDisplayStatus;
  /** 입점 타입 */
  entry_type: UxProductEntryType;
  /** 상품정보제공 고시 */
  essentials: Array<UxCatalogEssentials>;
  /** 예측 배송기간 정보(from order service) */
  estimated_shipping_date?: Maybe<UxEstimatedShippingDate>;
  external_product_id?: Maybe<Scalars['String']>;
  /** 원본 상품 url (등록형 상품인 경우 null) */
  external_product_url?: Maybe<Scalars['String']>;
  /** 쿠폰 할인 여부 */
  has_coupon: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 브랜드 여부 */
  is_brand: Scalars['Boolean'];
  /** 유저가 선택한 언어와 상품의 언어가 같은지 */
  is_language_match?: Maybe<Scalars['Boolean']>;
  /** 찜 상품 여부 */
  is_saved_product: Scalars['Boolean'];
  /** z결제 할인 여부 */
  is_zpay_discount: Scalars['Boolean'];
  /** 상품의 품목 리스트 */
  item_list: Array<UxCatalogItem>;
  /** 최대구매수량 */
  maximum_order_quantity?: Maybe<Scalars['Int']>;
  /** 메타 옵션 링크 정보 */
  meta_catalog_product_info?: Maybe<MetaCatalogProductInfo>;
  /** 최소구매수량 */
  minimum_order_quantity: Scalars['Int'];
  /** 상품명 */
  name: Scalars['String'];
  /** 병행 수입 배너 이미지 URL */
  parallel_imported_banner_image_url?: Maybe<Scalars['String']>;
  /** 결제 타입 */
  payment_type: UxCatalogPaymentType;
  /** PDP 이미지 배너 */
  pdp_image_rolling_banner?: Maybe<UxPdpRollingImageBanner>;
  /** 프리오더 정보 */
  preorder?: Maybe<UxProductPreorder>;
  /** 상품 준비 기간 */
  preparation_period?: Maybe<Scalars['Int']>;
  /** 상품 부가 속성 리스트 */
  product_attribute_list: Array<UxCatalogProductAttribute>;
  /** 상품의 매핑 정보 */
  product_id_mapping?: Maybe<UxCatalogProductIdMapping>;
  /** 상품의 이미지 리스트 */
  product_image_list: Array<UxCatalogProductImage>;
  /** 상품 품목 부가 속성 리스트 */
  product_item_attribute_list: Array<UxCatalogProductItemAttribute>;
  /** 상품 품목 부가 속성 리스트 */
  product_option_list: Array<UxCatalogProductOption>;
  /** 상품의 가격 정보 */
  product_price: UxCatalogProductPrice;
  /** 상품 타입 */
  product_type: UxCatalogProductType;
  /** 상품 url */
  product_url: Scalars['String'];
  /** 프로모션 메세지. 배송기간 정보가 없을 때 해당 메세지를 보여준다 */
  promotion_message: Scalars['String'];
  /** 신뢰도 정보 */
  reliability?: Maybe<UxProductReliability>;
  /** 상품 판매 상태 */
  sales_status: UxCatalogSalesStatus;
  /** 배송비 타입 */
  shipping_fee_type: UxCatalogShippingFeeType;
  /** 상점 ID */
  shop_id: Scalars['Int'];
  /** 상점의 도메인 */
  shop_main_domain: Scalars['String'];
  /** 원본 상품 url with tracking param (등록형 상품인 경우 null) */
  source_product_url?: Maybe<Scalars['String']>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 상품의 수정 일자 */
  updated_at?: Maybe<Scalars['CrTimestamp']>;
  /**
   * Z-ONLY 상품 도착예정정보
   * @deprecated 필드명 수정
   */
  z_only_estimate_shipping_info?: Maybe<ZOnlyEstimateShippingInfo>;
  /** Z-ONLY 상품 도착예정정보 */
  zonly_estimate_shipping_info?: Maybe<ZOnlyEstimateShippingInfo>;
};

export type UxCatalogProductAttribute = {
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 속성 값 */
  value_list: Array<Scalars['String']>;
};

export type UxCatalogProductCategory = {
  /** category DB의 category id */
  category_id: Scalars['Int'];
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 카테고리 1차 또는 2차 또는 3차 */
  depth: Scalars['Int'];
  id: Scalars['ID'];
  /** 카테고리 키 */
  key: Scalars['String'];
  /** 카테고리 이름 */
  value: Scalars['String'];
};

export type UxCatalogProductIdMapping = {
  /** Goods ID */
  goods_id?: Maybe<Scalars['Int']>;
  /** 기존 Product ID */
  legacy_product_id?: Maybe<Scalars['Int']>;
};

export type UxCatalogProductImage = {
  /** 삭제 여부 */
  deleted: Scalars['Boolean'];
  /** 이미지 타입 */
  image_type: UxCatalogProductImageType;
  /** 원본 파일 URL */
  origin_url: Scalars['String'];
  /** pdp static image url. gif 이미지 로딩속도 개선을 위함 */
  pdp_static_image_url?: Maybe<Scalars['String']>;
  /** pdp 썸네일 */
  pdp_thumbnail_url?: Maybe<Scalars['String']>;
  /** plp 썸네일 */
  plp_thumbnail_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum UxCatalogProductImageType {
  CONTENT = 'CONTENT',
  MAIN = 'MAIN',
  SUB = 'SUB',
}

export type UxCatalogProductItemAttribute = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 순서 */
  order: Scalars['Int'];
  /** 필수여부 */
  required: Scalars['Boolean'];
  /** 속성 값 */
  value_list: Array<UxCatalogProductItemAttributeValue>;
};

export type UxCatalogProductItemAttributeValue = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소(키값) */
  image_key?: Maybe<Scalars['String']>;
  /** 이미지 전체 주소 */
  image_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
  /** 이미지 전체 주소(webp 형식) */
  webp_image_url?: Maybe<Scalars['String']>;
};

export type UxCatalogProductOption = {
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 속성명 */
  name: Scalars['String'];
  /** 순서 */
  order: Scalars['Int'];
  /** 필수여부 */
  required: Scalars['Boolean'];
  /** 속성 값 */
  value_list: Array<UxCatalogProductOptionValue>;
};

export type UxCatalogProductOptionValue = {
  /** 추가금액 */
  additional_amount?: Maybe<Scalars['Int']>;
  /** 코드 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 이미지 주소 */
  image_key?: Maybe<Scalars['String']>;
  /** 정적 이미지 URL(jpeg type) */
  jpeg_url?: Maybe<Scalars['String']>;
  /** 상품 속성 값 순서 */
  order?: Maybe<Scalars['Int']>;
  /** 정적 이미지 URL(webp type) */
  static_url?: Maybe<Scalars['String']>;
  /** 썸네일 URL */
  thumbnail_url?: Maybe<Scalars['String']>;
  /** 속성 값 */
  value: Scalars['String'];
};

export type UxCatalogProductPrice = {
  /** 쿠폰 할인양 */
  coupon_discount_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인양 with 통화 */
  coupon_discount_amount_with_currency?: Maybe<PriceWithCurrency>;
  /** 할인양 */
  discount_amount: Scalars['Int'];
  /** 할인양 with 통화 */
  discount_amount_with_currency: PriceWithCurrency;
  /** 할인가 */
  discount_price: Scalars['Int'];
  /** 할인가 with 통화 */
  discount_price_with_currency: PriceWithCurrency;
  /** 할인률 */
  discount_rate: Scalars['Int'];
  /** 최종 가격 */
  final_price: Scalars['Int'];
  /** 최종 가격 with 통화 */
  final_price_with_currency: PriceWithCurrency;
  /** 쿠폰 할인가 유무 */
  has_coupon: Scalars['Boolean'];
  /** 최대 가격 */
  max_price: Scalars['Int'];
  /** 최대 가격 with 통화 */
  max_price_with_currency: PriceWithCurrency;
  /** 정상가 */
  original_price: Scalars['Int'];
  /** 정상가 with 통화 */
  original_price_with_currency: PriceWithCurrency;
  /** z결제 할인양 */
  zpay_discount_amount?: Maybe<Scalars['Int']>;
  /** z결제 할인양 with 통화 */
  zpay_discount_amount_with_currency?: Maybe<PriceWithCurrency>;
};

export enum UxCatalogProductType {
  /** 일반 상품 */
  GENERAL = 'GENERAL',
  /** ZONLY 상품 */
  ZONLY = 'ZONLY',
}

export enum UxCatalogSalesStatus {
  /** 판매 종료 */
  CLOSED = 'CLOSED',
  /** 세일 중 */
  ON_SALE = 'ON_SALE',
  /** 준비 중 */
  PREPARING = 'PREPARING',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
  /** 유예 */
  SUSPENDED = 'SUSPENDED',
}

export enum UxCatalogShippingFeeType {
  /** 무료 배송 */
  FREE_SHIPPING = 'FREE_SHIPPING',
  /** SHOP의 배송정책을 따름 */
  SHOP_POLICY = 'SHOP_POLICY',
}

export type UxCategory = UxComponent & {
  filter_item_list: Array<UxFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  sub_category_list?: Maybe<Array<UxFilterItem>>;
  type: UxComponentType;
  ux_category_style?: Maybe<UxCategoryStyle>;
};

export type UxCategoryInfo = {
  ID: Scalars['ID'];
  icon_url: Scalars['String'];
  name: Scalars['String'];
  sub_category_list: Array<UxSubCategoryInfo>;
};

export type UxCategoryInfoList = {
  item_list: Array<UxCategoryInfo>;
};

export enum UxCategoryStyle {
  CATEGORY_ROUNDED = 'CATEGORY_ROUNDED',
  CATEGORY_TEXT = 'CATEGORY_TEXT',
}

export type UxCheckButtonAndSorting = UxComponent & {
  check_button_item_list: Array<UxFilterItem>;
  column_shifting_button_list?: Maybe<Array<SearchColumnShiftingButton>>;
  position: Scalars['Int'];
  sorting_item_list: Array<UxFilterItem>;
  type: UxComponentType;
};

export type UxChipButton = {
  /** 배경 색상 */
  background_color?: Maybe<UxCommonColor>;
  /** 딥링크 */
  landing_url?: Maybe<Scalars['String']>;
  /** 노출 텍스트 */
  text: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type UxChipButtonAndProductList = {
  icon?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  item_list: Array<UxGoodsCardItem>;
  name: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type UxChipButtonAndProductListGridGroup = UxComponent & {
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** 리스트 */
  chip_and_product_list: Array<UxChipButtonAndProductList>;
  /** 칩버튼 디자인 타입 */
  chip_type?: Maybe<ChipButtonType>;
  /** 자동 페이지네이션 여부 */
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  /** 타이틀 */
  main_title: UxText;
  /** 노출되는 최대 줄 수 */
  max_rows?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  type: UxComponentType;
  /** 자동 페이지네이션 시간 (초) */
  update_interval?: Maybe<Scalars['Int']>;
};

/** 칩버튼 그리드 묶음 컴포넌트 */
export type UxChipButtonGridGroup = UxComponent & {
  /** 배경 색상 */
  background_color?: Maybe<UxCommonColor>;
  /** 칩버튼 컴포넌트 리스트 */
  chip_list: Array<UxChipButton>;
  /** 타이틀 */
  main_title: UxText;
  /** 노출되는 최대 줄 수 */
  max_rows?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxClpCategory = {
  alias?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_all?: Maybe<Scalars['Boolean']>;
  landing_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  sub_category_list?: Maybe<Array<UxClpSubCategory>>;
};

export type UxClpCategoryList = UxComponent & {
  category_list?: Maybe<Array<UxClpCategory>>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxClpSegmentTab = UxComponent & {
  filter_item_list: Array<UxFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxClpSubCategory = {
  id: Scalars['ID'];
  landing_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type UxCommonBrandChip = {
  icon?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  item_list: Array<UxGoodsCardItem>;
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  ubl?: Maybe<UxUbl>;
  url?: Maybe<Scalars['String']>;
};

/** Button */
export type UxCommonButton = {
  background_color?: Maybe<UxCommonColor>;
  image?: Maybe<UxCommonImage>;
  landing_url: Scalars['String'];
  text?: Maybe<UxCommonText>;
  ubl?: Maybe<UxUbl>;
};

export type UxCommonColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type UxCommonHtmlText = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

/** 이미지 */
export type UxCommonImage = {
  url: UxCommonImageUrl;
  webp_url?: Maybe<UxCommonImageUrl>;
};

/** 이미지 비율 */
export type UxCommonImageRatio = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

/** 이미지 URL */
export type UxCommonImageUrl = {
  dark?: Maybe<Scalars['String']>;
  normal: Scalars['String'];
};

export type UxCommonLottie = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

/** 텍스트 */
export type UxCommonText = {
  color?: Maybe<UxCommonTextColor>;
  html?: Maybe<UxCommonHtmlText>;
  text: Scalars['String'];
};

/** 텍스트 Color */
export type UxCommonTextColor = {
  dark?: Maybe<Scalars['String']>;
  normal?: Maybe<Scalars['String']>;
};

export type UxCommonVideo = {
  mute_sound?: Maybe<Scalars['Boolean']>;
  video_low_quality_thumbnail?: Maybe<Scalars['String']>;
  video_thumbnail?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
};

export type UxComponent = {
  position: Scalars['Int'];
  type: UxComponentType;
};

export enum UxComponentType {
  UX_AD_BANNER_CARD = 'UX_AD_BANNER_CARD',
  UX_AD_DISPLAY_BANNER_GROUP = 'UX_AD_DISPLAY_BANNER_GROUP',
  UX_AD_DISPLAY_CLP_BANNER = 'UX_AD_DISPLAY_CLP_BANNER',
  UX_AD_DISPLAY_LARGE_BANNER = 'UX_AD_DISPLAY_LARGE_BANNER',
  UX_AD_DISPLAY_MID_BANNER = 'UX_AD_DISPLAY_MID_BANNER',
  UX_AD_DISPLAY_SMALL_BANNER = 'UX_AD_DISPLAY_SMALL_BANNER',
  UX_AD_DISPLAY_X_LARGE_BANNER = 'UX_AD_DISPLAY_X_LARGE_BANNER',
  UX_AD_DISPLAY_X_MID_BANNER = 'UX_AD_DISPLAY_X_MID_BANNER',
  UX_BAND_BANNER = 'UX_BAND_BANNER',
  UX_BANNER_ITEM = 'UX_BANNER_ITEM',
  UX_BANNER_LIST = 'UX_BANNER_LIST',
  UX_BENEFITS_CARD = 'UX_BENEFITS_CARD',
  UX_BRAND_CHIP_PRODUCT_LIST_GROUP = 'UX_BRAND_CHIP_PRODUCT_LIST_GROUP',
  UX_BRAND_RECOMMEND_GROUP = 'UX_BRAND_RECOMMEND_GROUP',
  UX_BRAND_THEME_CONTENT = 'UX_BRAND_THEME_CONTENT',
  UX_BUTTON = 'UX_BUTTON',
  UX_CATEGORY = 'UX_CATEGORY',
  UX_CHECKBUTTON_AND_SELECTION_AND_SORTING = 'UX_CHECKBUTTON_AND_SELECTION_AND_SORTING',
  UX_CHECK_BUTTON_AND_SORTING = 'UX_CHECK_BUTTON_AND_SORTING',
  UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP = 'UX_CHIP_BUTTON_AND_PRODUCT_LIST_GRID_GROUP',
  UX_CHIP_BUTTON_GRID_GROUP = 'UX_CHIP_BUTTON_GRID_GROUP',
  UX_CLP_CATEGORY = 'UX_CLP_CATEGORY',
  UX_CLP_SEGMENT_TAB = 'UX_CLP_SEGMENT_TAB',
  UX_DDP_TEMPLATE = 'UX_DDP_TEMPLATE',
  UX_ENTRY_BANNER = 'UX_ENTRY_BANNER',
  UX_ERROR = 'UX_ERROR',
  UX_FULL_WIDTH_GOODS_CARD_ITEM = 'UX_FULL_WIDTH_GOODS_CARD_ITEM',
  UX_FULL_WIDTH_IMAGE_BANNER_GROUP = 'UX_FULL_WIDTH_IMAGE_BANNER_GROUP',
  UX_GOODS_CARD_ITEM = 'UX_GOODS_CARD_ITEM',
  UX_GOODS_CARD_LIST = 'UX_GOODS_CARD_LIST',
  UX_GOODS_CAROUSEL = 'UX_GOODS_CAROUSEL',
  UX_GOODS_FILTER_LIST = 'UX_GOODS_FILTER_LIST',
  UX_GOODS_GRID_GROUP = 'UX_GOODS_GRID_GROUP',
  UX_GOODS_GROUP = 'UX_GOODS_GROUP',
  UX_HYBRID_PROMOTION = 'UX_HYBRID_PROMOTION',
  UX_IMAGE = 'UX_IMAGE',
  UX_IMAGE_AND_TEXT_TITLE = 'UX_IMAGE_AND_TEXT_TITLE',
  UX_IMAGE_BANNER_GROUP = 'UX_IMAGE_BANNER_GROUP',
  UX_IMAGE_GOODS_CARD_ITEM = 'UX_IMAGE_GOODS_CARD_ITEM',
  UX_LINE = 'UX_LINE',
  UX_LINE_WITH_MARGIN = 'UX_LINE_WITH_MARGIN',
  UX_MULTILINE_RANKING_CAROUSEL = 'UX_MULTILINE_RANKING_CAROUSEL',
  UX_NO_RESULTS = 'UX_NO_RESULTS',
  UX_PRODUCT_LIST_GRID_GROUP = 'UX_PRODUCT_LIST_GRID_GROUP',
  UX_PRODUCT_REVIEW_SUMMARY_GROUP = 'UX_PRODUCT_REVIEW_SUMMARY_GROUP',
  UX_PROMOTION_FLOATING_BANNER = 'UX_PROMOTION_FLOATING_BANNER',
  UX_PROMOTION_TC = 'UX_PROMOTION_TC',
  UX_QUICK_MENU = 'UX_QUICK_MENU',
  UX_SEARCHED_SHOP_CAROUSEL = 'UX_SEARCHED_SHOP_CAROUSEL',
  UX_SEARCH_KEYWORD_GUIDE = 'UX_SEARCH_KEYWORD_GUIDE',
  UX_SEARCH_RESULT_HEADER = 'UX_SEARCH_RESULT_HEADER',
  UX_SEARCH_RESULT_QUICK_FILTER_LIST = 'UX_SEARCH_RESULT_QUICK_FILTER_LIST',
  UX_SEGMENT_TAB = 'UX_SEGMENT_TAB',
  UX_SELECTION_AND_SORTING = 'UX_SELECTION_AND_SORTING',
  UX_SHOP_EXPANDABLE_LIST = 'UX_SHOP_EXPANDABLE_LIST',
  UX_SHOP_GROUP = 'UX_SHOP_GROUP',
  UX_SHOP_RANKING_CARD_ITEM = 'UX_SHOP_RANKING_CARD_ITEM',
  UX_SIMPLE_PROMOTION_BANNER = 'UX_SIMPLE_PROMOTION_BANNER',
  UX_SINGLE_BANNER = 'UX_SINGLE_BANNER',
  UX_SMALL_TEXT_TITLE = 'UX_SMALL_TEXT_TITLE',
  UX_SORTING = 'UX_SORTING',
  UX_TEXT = 'UX_TEXT',
  UX_TEXT_AND_MORE_BUTTON = 'UX_TEXT_AND_MORE_BUTTON',
  UX_TEXT_AND_SORTING = 'UX_TEXT_AND_SORTING',
  UX_TEXT_TITLE = 'UX_TEXT_TITLE',
  UX_THEME_CATEGORY = 'UX_THEME_CATEGORY',
  UX_THEME_COMPONENT_CAROUSEL_GROUP = 'UX_THEME_COMPONENT_CAROUSEL_GROUP',
  UX_TIME_DEAL_CARD = 'UX_TIME_DEAL_CARD',
  UX_WEB_PAGE = 'UX_WEB_PAGE',
  UX_ZONLY_ENTRY_BANNER = 'UX_ZONLY_ENTRY_BANNER',
  UX_ZONLY_ENTRY_CARD = 'UX_ZONLY_ENTRY_CARD',
  UX_ZONLY_ENTRY_GROUP = 'UX_ZONLY_ENTRY_GROUP',
  UX_ZONLY_ENTRY_INFO = 'UX_ZONLY_ENTRY_INFO',
}

export enum UxCouponAvailableStatus {
  /** 유저가 받을 수 있는 쿠폰이 있음 */
  COUPON_AVAILABLE = 'COUPON_AVAILABLE',
  /** 발급받을 수 있는 쿠폰을 모두 받음 */
  COUPON_ISSUED = 'COUPON_ISSUED',
  /** 쿠폰 적용 불가 상품 */
  COUPON_NOT_APPLICABLE = 'COUPON_NOT_APPLICABLE',
  /** 받을 수 있는 쿠폰이 없음 */
  COUPON_UNAVAILABLE = 'COUPON_UNAVAILABLE',
}

/** 쿠폰 정책 리스트 조회 결과 */
export type UxCouponCollectionCouponContainer = {
  end_cursor?: Maybe<Scalars['String']>;
  /** 쿠폰 정책 조회 에러 */
  errors?: Maybe<Array<Maybe<CouponCollectionError>>>;
  has_next_page?: Maybe<Scalars['Boolean']>;
  item_list?: Maybe<Array<Maybe<UxCouponCollectionItem>>>;
  total_count?: Maybe<Scalars['Int']>;
};

/** 쿠폰 발급 유저 타입 - user-account-coupon 의 CouponRegistarationType 중 사용되는 타입 */
export enum UxCouponCollectionCouponRegistrationType {
  BOOKMARK = 'BOOKMARK',
  GENERAL = 'GENERAL',
  NEW = 'NEW',
}

/** 쿠폰 Target TYPE - user-account-coupon 의 CouponTargetType 중 사용되는 타입 */
export enum UxCouponCollectionCouponTargetType {
  /** 상품 카테고리 쿠폰 */
  CATEGORY = 'CATEGORY',
  /** 스토어 카테고리 쿠폰 */
  DEPARTMENT = 'DEPARTMENT',
  /** 장바구니 쿠폰 */
  ORDER = 'ORDER',
  /** 단일 상품 대상 쿠폰 */
  ORDER_ITEM = 'ORDER_ITEM',
  /** 여러 상품 대상 쿠폰 */
  ORDER_ITEM_GROUP = 'ORDER_ITEM_GROUP',
  /** 단일 스토어 대상 쿠폰 */
  SHOP = 'SHOP',
  /** 여러 스토어 대상 쿠폰 */
  SHOP_GROUP = 'SHOP_GROUP',
  /** 단일 스토어 쿠폰 (직진 배송 상품 포함) */
  SHOP_WITH_ZONLY = 'SHOP_WITH_ZONLY',
}

export enum UxCouponCollectionFcfsCouponState {
  AVAILABLE = 'AVAILABLE',
  AVAILABLE_USED_MORE_THAN_HALF = 'AVAILABLE_USED_MORE_THAN_HALF',
  CLOSE = 'CLOSE',
}

/** 쿠폰 모아보기 용 쿠폰 정책 UI 아이템 기본 인터페이스 */
export type UxCouponCollectionItem = {
  /** 쿠폰 TARGET TYPE */
  coupon_target_type?: Maybe<UxCouponCollectionCouponTargetType>;
  /** 쿠폰 설명 */
  description: Scalars['String'];
  /** 쿠폰 할인 금액 */
  discount_amount?: Maybe<Scalars['Int']>;
  /** 할인액 or 할인율 문구 */
  discount_label?: Maybe<Scalars['String']>;
  /** 쿠폰 할인률 */
  discount_rate_bp?: Maybe<Scalars['Int']>;
  /** 할인 표시 타입 (할인율 or 할인액) */
  discount_type?: Maybe<UxCouponDiscountType>;
  /** [사용 선착순 쿠폰] 쿠폰 남은 수량 */
  fcfs_coupon_remained_count?: Maybe<Scalars['Int']>;
  /** [사용 선착순 쿠폰] 사용 선착순 쿠폰 발급 상태 TYPE over | available | close | More than half */
  fcfs_coupon_state?: Maybe<UxCouponCollectionFcfsCouponState>;
  /** [사용 선착순 쿠폰] 사용 선착순 쿠폰 사용 상황 문구 (ex. "현재 ~ 개 사용됨. y 개 남음") */
  fcfs_coupon_state_text?: Maybe<Scalars['String']>;
  /** [사용 선착순 쿠폰] 총 쿠폰 제공 가능 수량 */
  fcfs_coupon_total_count?: Maybe<Scalars['Int']>;
  /** [사용 선착순 쿠폰] 쿠폰 사용 수량 */
  fcfs_coupon_used_count?: Maybe<Scalars['Int']>;
  /** 쿠폰 정책 id */
  id: Scalars['ID'];
  /** 사용 선착순 쿠폰 여부 */
  is_fcfs: Scalars['Boolean'];
  /** 쿠폰 발급여부 */
  issued: Scalars['Boolean'];
  /** 랜딩 url (현재 [상품 카테고리 쿠폰 정책]만 사용) */
  landing_url?: Maybe<Scalars['String']>;
  /** description 외 별도 안내 문구 */
  notice?: Maybe<Array<Scalars['String']>>;
  /** [상품 쿠폰 정책] 쿠폰 적용 대상 대표 상품 카드 - 추후 개선 필요(상품 그룹과 동일한 형태로) */
  product?: Maybe<UxGoodsCardItem>;
  /** [상품 쿠폰 정책] 쿠폰 적용 대상 상품 카드 갯수 - 추후 개선 필요(상품 그룹과 동일한 형태로) */
  product_count?: Maybe<Scalars['Int']>;
  /** [상품 그룹 쿠폰 정책] 상품 그룹 쿠폰 적용 대상 스토어 상품 카드 리스트 */
  product_list?: Maybe<Array<UxGoodsCardItem>>;
  /** 쿠폰 발급 유저 타입 (일반, 즐겨찾기, 첫구매 등) */
  registration_type?: Maybe<UxCouponCollectionCouponRegistrationType>;
  /** 쿠폰 스코어 (쿠폰 스코어 기반으로 조회했을 경우 제공) */
  score?: Maybe<Scalars['Int']>;
  /** 셀러 분담율 */
  seller_rate_bp?: Maybe<Scalars['Int']>;
  /** [스토어 쿠폰 정책] 쿠폰 적용 대상 스토어 */
  shop?: Maybe<UxCouponCollectionShopInfo>;
  /** [스토어 쿠폰 정책] 스토어 쿠폰 정책의 shop id */
  shop_id?: Maybe<Scalars['ID']>;
  /** [스토어 그룹 쿠폰 정책] 스토어 리스트 */
  shop_list?: Maybe<Array<UxCouponCollectionShopInfo>>;
  /** [스토어 쿠폰 정책] 더보기 버튼 */
  show_more_button?: Maybe<Scalars['Boolean']>;
  /** 쿠폰 정책의 대상 정보(현재 [스토어 카테고리 쿠폰 정책]만 사용) */
  target?: Maybe<CouponCollectionCouponTarget>;
  /** 쿠폰 타이틀 */
  title: Scalars['String'];
  /** 상단 쿠폰 리스트 내 쿠폰 정책 UI 아이템의 쿠폰 발급용 id. 다른 보안 정보들과 함께 암호화된 쿠폰 모아보기 쿠폰 정책 id */
  top_coupon_policy_id?: Maybe<Scalars['ID']>;
};

/** 쿠폰 모아보기 페이지 정보 */
export type UxCouponCollectionPageInfo = {
  banner: UxBannerItem;
  /** 쿠폰 리스트 */
  coupon_list?: Maybe<UxCouponCollectionCouponContainer>;
  /** 찜한 상품 쿠폰 받기 카루셀 */
  saved_product_store_coupon_carousel?: Maybe<CouponCollectionCouponCarousel>;
  sorter_list: Array<UxSortingItem>;
  /** 상단 노출 쿠폰 리스트 */
  top_coupon_list?: Maybe<Array<Maybe<UxCouponCollectionItem>>>;
};

/** 쿠폰 모아보기 페이지 정보 */
export type UxCouponCollectionPageInfoCoupon_ListArgs = {
  input?: InputMaybe<UxCouponCollectionPageInput>;
};

/** 쿠폰 모아보기 페이지 정보 조회 input */
export type UxCouponCollectionPageInput = {
  /** 하단 메인 쿠폰 리스트 조회 시 즐겨찾기 스토어 대상으로 조회할 지 여부 */
  is_favorite?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['String']>;
  sorter?: InputMaybe<Scalars['String']>;
};

/** 쿠폰 모아보기용 스토어 정보 */
export type UxCouponCollectionShopInfo = {
  id: Scalars['ID'];
  /** 이미지 필드는 필요에 따라 사용 */
  image_url?: Maybe<Scalars['String']>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
};

export type UxCouponCollectionSortItem = {
  selected: Scalars['Boolean'];
  type?: Maybe<UxCouponCollectionSorter>;
};

export enum UxCouponCollectionSorter {
  /** 할인율 높은 순 */
  HIGH_DISCOUNT_RATE = 'HIGH_DISCOUNT_RATE',
  /** 최소 주문 금액 낮은 순 */
  LOW_MIN_REQUIRED_AMOUNT = 'LOW_MIN_REQUIRED_AMOUNT',
  /** 추천순 (자체적으로 계산한 값을 기준으로 한 정렬 옵션) */
  RECOMMENDATION = 'RECOMMENDATION',
}

export enum UxCouponDiscountType {
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_RATE = 'DISCOUNT_RATE',
}

/** 쿠폰 정책별 허들금액 정보 */
export type UxCouponPolicyInfo = {
  /** 달성액 */
  achieved_amount: Scalars['Int'];
  /** 쿠폰 할인 금액 */
  coupon_discount_amount: Scalars['Int'];
  /** 쿠폰 할인율 */
  coupon_discount_rate: Scalars['Int'];
  /** 쿠폰 정책 ID */
  coupon_policy_id: Scalars['ID'];
  /** 쿠폰 사용 까지의 잔여액 */
  insufficient_amount: Scalars['Int'];
  /** 쿠폰 정책 정보 */
  policy_info: UxUserAccountCouponPolicy;
  /** 쿠폰 사용 여부 상태값, USED: 사용 완료, EXPIRED: 기간 만료, ISSUABLE: 발급 가능, AVAILABLE: 사용 가능, UNAVAILABLE: 금액 미달 */
  status: Scalars['String'];
};

/** 쿠폰 정책별 허들금액 정보 목록 */
export type UxCouponPolicyInfoList = {
  item_list: Array<UxCouponPolicyInfo>;
};

/** ux_coupon_policy_info_list의 input */
export type UxCouponPolicyInfoListInput = {
  /** 쿠폰 정책 ID 목록 */
  coupon_policy_id_list: Array<Scalars['ID']>;
  /** 게이지바 노출 될 페이지 (web에서는 CART와 EXHIBITION만 사용) */
  page: GaugeBarPage;
  /** 상품 아이템 정보 목록 */
  product_info_list?: InputMaybe<Array<UxProductItemInfoInput>>;
  /** 상품 아이템 정보 목록으로 직접 넘긴 인자 활용 여부 */
  use_product_info_list?: InputMaybe<Scalars['Boolean']>;
};

export type UxDailyShippingProbability = {
  day: Scalars['Int'];
  probability: Scalars['Float'];
};

export type UxDdpNavigation = {
  feature_list: Array<UxDdpNavigationFeatureType>;
  title?: Maybe<Scalars['String']>;
};

export enum UxDdpNavigationFeatureType {
  CART = 'CART',
  HAMBURGER = 'HAMBURGER',
  SEARCH = 'SEARCH',
  SEARCH_BAR = 'SEARCH_BAR',
}

export type UxDdpPage = {
  highlight_color?: Maybe<UxCommonColor>;
  navigation?: Maybe<UxDdpNavigation>;
  page_list: Array<UxDdpPageItem>;
};

export type UxDdpPageItem = {
  id: Scalars['ID'];
  is_new?: Maybe<Scalars['Boolean']>;
  log?: Maybe<UxDdpPageItemLog>;
  name: Scalars['String'];
  page_type: UxDdpPageType;
  pull_to_refresh?: Maybe<Scalars['Boolean']>;
};

export type UxDdpPageItemLog = {
  navigation: Scalars['String'];
  navigation_sub?: Maybe<Scalars['CrJson']>;
};

export enum UxDdpPageType {
  DISCOVERY_MAGAZINE_LIST = 'DISCOVERY_MAGAZINE_LIST',
  DISCOVERY_MAIN_M1 = 'DISCOVERY_MAIN_M1',
  DISCOVERY_MAIN_M2 = 'DISCOVERY_MAIN_M2',
  GLOBAL_BEST_ALL = 'GLOBAL_BEST_ALL',
  GLOBAL_BEST_ITEM_BEAUTY = 'GLOBAL_BEST_ITEM_BEAUTY',
  GLOBAL_BEST_ITEM_FASHION = 'GLOBAL_BEST_ITEM_FASHION',
  GLOBAL_HOME_DISCOVER = 'GLOBAL_HOME_DISCOVER',
  GLOBAL_HOME_FOR_YOU = 'GLOBAL_HOME_FOR_YOU',
  GLOBAL_ITEMS_BY_STYLE_BUSINESS_CASUAL = 'GLOBAL_ITEMS_BY_STYLE_BUSINESS_CASUAL',
  GLOBAL_ITEMS_BY_STYLE_CASUAL = 'GLOBAL_ITEMS_BY_STYLE_CASUAL',
  GLOBAL_ITEMS_BY_STYLE_CHIC_COOL = 'GLOBAL_ITEMS_BY_STYLE_CHIC_COOL',
  GLOBAL_ITEMS_BY_STYLE_ELEGANT_POLISHED = 'GLOBAL_ITEMS_BY_STYLE_ELEGANT_POLISHED',
  GLOBAL_ITEMS_BY_STYLE_IT_GIRL = 'GLOBAL_ITEMS_BY_STYLE_IT_GIRL',
  GLOBAL_ITEMS_BY_STYLE_MINIMAL_LOOK = 'GLOBAL_ITEMS_BY_STYLE_MINIMAL_LOOK',
  GLOBAL_ITEMS_BY_STYLE_PREPPY = 'GLOBAL_ITEMS_BY_STYLE_PREPPY',
  GLOBAL_ITEMS_BY_STYLE_RETRO = 'GLOBAL_ITEMS_BY_STYLE_RETRO',
  GLOBAL_ITEMS_BY_STYLE_ROMANTIC = 'GLOBAL_ITEMS_BY_STYLE_ROMANTIC',
  GLOBAL_ITEMS_BY_STYLE_SPORT_ATHLEISURE = 'GLOBAL_ITEMS_BY_STYLE_SPORT_ATHLEISURE',
  GLOBAL_ITEMS_BY_STYLE_STREETWEAR = 'GLOBAL_ITEMS_BY_STYLE_STREETWEAR',
  GLOBAL_NEW_TODAY = 'GLOBAL_NEW_TODAY',
  GLOBAL_SALE_ALL = 'GLOBAL_SALE_ALL',
  GLOBAL_SALE_BEAUTY = 'GLOBAL_SALE_BEAUTY',
  GLOBAL_SALE_FASHION = 'GLOBAL_SALE_FASHION',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
}

export enum UxDdpPagesType {
  DISCOVERY_MAGAZINE_LIST = 'DISCOVERY_MAGAZINE_LIST',
  DISCOVERY_MAIN_M1 = 'DISCOVERY_MAIN_M1',
  DISCOVERY_MAIN_M2 = 'DISCOVERY_MAIN_M2',
  GLOBAL_BEST_ALL = 'GLOBAL_BEST_ALL',
  GLOBAL_BEST_ITEM = 'GLOBAL_BEST_ITEM',
  GLOBAL_HOME = 'GLOBAL_HOME',
  GLOBAL_ITEMS_BY_STYLE_BUSINESS_CASUAL = 'GLOBAL_ITEMS_BY_STYLE_BUSINESS_CASUAL',
  GLOBAL_ITEMS_BY_STYLE_CASUAL = 'GLOBAL_ITEMS_BY_STYLE_CASUAL',
  GLOBAL_ITEMS_BY_STYLE_CHIC_COOL = 'GLOBAL_ITEMS_BY_STYLE_CHIC_COOL',
  GLOBAL_ITEMS_BY_STYLE_ELEGANT_POLISHED = 'GLOBAL_ITEMS_BY_STYLE_ELEGANT_POLISHED',
  GLOBAL_ITEMS_BY_STYLE_IT_GIRL = 'GLOBAL_ITEMS_BY_STYLE_IT_GIRL',
  GLOBAL_ITEMS_BY_STYLE_MINIMAL_LOOK = 'GLOBAL_ITEMS_BY_STYLE_MINIMAL_LOOK',
  GLOBAL_ITEMS_BY_STYLE_PREPPY = 'GLOBAL_ITEMS_BY_STYLE_PREPPY',
  GLOBAL_ITEMS_BY_STYLE_RETRO = 'GLOBAL_ITEMS_BY_STYLE_RETRO',
  GLOBAL_ITEMS_BY_STYLE_ROMANTIC = 'GLOBAL_ITEMS_BY_STYLE_ROMANTIC',
  GLOBAL_ITEMS_BY_STYLE_SPORT_ATHLEISURE = 'GLOBAL_ITEMS_BY_STYLE_SPORT_ATHLEISURE',
  GLOBAL_ITEMS_BY_STYLE_STREETWEAR = 'GLOBAL_ITEMS_BY_STYLE_STREETWEAR',
  GLOBAL_NEW_TODAY = 'GLOBAL_NEW_TODAY',
  GLOBAL_SALE = 'GLOBAL_SALE',
  GLOBAL_SALE_ALL = 'GLOBAL_SALE_ALL',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
}

export type UxDdpTemplate = UxComponent & {
  id: Scalars['ID'];
  layout?: Maybe<DdpLayout>;
  position: Scalars['Int'];
  template_type: DdpComponentType;
  type: UxComponentType;
};

export type UxDisplayBadge = {
  dark_image_url?: Maybe<Scalars['String']>;
  image_size?: Maybe<UxImageSize>;
  image_url: Scalars['String'];
  small_dark_image_url?: Maybe<Scalars['String']>;
  small_image_size?: Maybe<UxImageSize>;
  small_image_url?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UxEntryBanner = UxComponent & {
  deeplink: Scalars['String'];
  image_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  lottie_json?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxErrorComponent = UxComponent & {
  error_subtitle?: Maybe<UxCommonText>;
  error_title?: Maybe<UxCommonText>;
  id: Scalars['String'];
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxEstimatedShippingDate = {
  estimate_list?: Maybe<Array<UxDailyShippingProbability>>;
  repr_estimate?: Maybe<UxDailyShippingProbability>;
};

export type UxExternalProductReviewTab = {
  /** 노출 여부 */
  is_exposed: Scalars['Boolean'];
  /** 우선 노출 여부 */
  is_prior: Scalars['Boolean'];
};

export type UxFilterItem = {
  children?: Maybe<Array<UxFilterItem>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  dot_info?: Maybe<UxOneOffNotificationInfo>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  show_tooltip?: Maybe<Scalars['Boolean']>;
  str_id: Scalars['ID'];
  style?: Maybe<UxFilterItemStyle>;
  tooltip_text?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type UxFilterItemStyle = {
  default_color?: Maybe<UxCommonTextColor>;
  selected_color?: Maybe<UxCommonTextColor>;
};

export type UxFullWidthImageBannerGroup = UxComponent & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxImageBanner>;
  position: Scalars['Int'];
  type: UxComponentType;
  update_interval?: Maybe<Scalars['Int']>;
};

export type UxGaugeColor = {
  background_color: UxCommonColor;
  end_color: UxCommonColor;
  start_color: UxCommonColor;
};

export type UxGnp = {
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

/** 배너로된 진입점 */
export type UxGnpBanner = {
  id: Scalars['ID'];
  image: UxCommonImage;
  landing_url: Scalars['String'];
  subtitle?: Maybe<UxCommonText>;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

/** 배너로된 진입점 */
export type UxGnpBannerList = UxGnp & {
  banner_list: Array<UxGnpBanner>;
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

/** 2차 카테고리 아이템 */
export type UxGnpCategoryItem = {
  category_key: Scalars['String'];
  icon?: Maybe<UxCommonImage>;
  landing_url: Scalars['String'];
  name: UxCommonText;
};

/** 카테고리 진입점 */
export type UxGnpCategoryList = UxGnp & {
  display_id?: Maybe<Scalars['String']>;
  icon_category_list: Array<UxGnpIconCategoryItem>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

export enum UxGnpCmsBannerType {
  /** 온사이트 배너 */
  CATEGORY = 'CATEGORY',
  /** DDP 배너 */
  DDP = 'DDP',
  /** 이벤트 배너 */
  EVENT = 'EVENT',
  /** 선택 안함 */
  NONE = 'NONE',
  /** 퀵 카테고리 */
  QUICK_MENU = 'QUICK_MENU',
  /** 스타일관 배너 */
  STYLE = 'STYLE',
}

/** 스타일관 배너 세부유형 */
export enum UxGnpCmsStyleDepartmentType {
  /** 뷰티 */
  BEAUTY = 'BEAUTY',
  /** 의류 */
  CLOTHES = 'CLOTHES',
  /** 라이프 */
  LIFE = 'LIFE',
  /** 선택 안함 */
  NONE = 'NONE',
}

/** 1차 카테고리 아이템 */
export type UxGnpIconCategoryItem = {
  category_key: Scalars['String'];
  category_list: Array<UxGnpCategoryItem>;
  icon: UxCommonImage;
  name: UxCommonText;
};

/** 메뉴 진입점 */
export type UxGnpMenu = UxGnp & {
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  menu_list: Array<UxGnpMenuItem>;
  sidebar_name?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

/** 메뉴 진입점 아이템 */
export type UxGnpMenuItem = {
  image: UxCommonImage;
  landing_url: Scalars['String'];
  name: UxCommonText;
};

/** 페이지 타이틀 */
export type UxGnpPage = {
  category_list?: Maybe<Array<GnpSidebarCategoryInfo>>;
  item_list: Array<UxGnp>;
  page_title: Scalars['String'];
  quick_category_list?: Maybe<Array<GnpQuickCategoryInfo>>;
};

export type UxGnpQuickCategory = UxGnp & {
  category_list: Array<UxGnpQuickCategoryItem>;
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

export type UxGnpQuickCategoryItem = {
  id?: Maybe<Scalars['ID']>;
  image_url?: Maybe<Scalars['String']>;
  landing_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum UxGnpStyle {
  STYLE_ENTRY_DEFAULT = 'STYLE_ENTRY_DEFAULT',
  STYLE_ENTRY_SMALLER = 'STYLE_ENTRY_SMALLER',
}

/** 스타일 진입점 배너 */
export type UxGnpStyleEntryCategoryList = UxGnp & {
  banner_list: Array<UxGnpBanner>;
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  main_title: UxCommonText;
  sidebar_name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  type: UxGnpType;
};

/** 단순 텍스트 진입점 */
export type UxGnpTextLink = {
  category_key?: Maybe<Scalars['String']>;
  icon?: Maybe<UxCommonImage>;
  id?: Maybe<Scalars['ID']>;
  landing_url: Scalars['String'];
  title: UxCommonText;
};

/** 단순 텍스트로된 진입점 리스트 */
export type UxGnpTextLinkList = UxGnp & {
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  text_link_list: Array<UxGnpTextLink>;
  type: UxGnpType;
};

/** Global Navigation 타입 */
export enum UxGnpType {
  BANNER_LIST = 'BANNER_LIST',
  CATEGORY_LIST = 'CATEGORY_LIST',
  GRID_MENU = 'GRID_MENU',
  QUICK_CATEGORY = 'QUICK_CATEGORY',
  ROLLING_BANNER_LIST = 'ROLLING_BANNER_LIST',
  STYLE_ENTRY_CATEGORY_LIST = 'STYLE_ENTRY_CATEGORY_LIST',
  TEXT_LINK_LIST = 'TEXT_LINK_LIST',
  VERTICAL_CATEGORY_LIST = 'VERTICAL_CATEGORY_LIST',
  ZIGZIN_BANNER = 'ZIGZIN_BANNER',
}

/** 카테고리 진입점 */
export type UxGnpVerticalCategoryList = UxGnp & {
  category_list: Array<UxGnpVerticalSubCategory>;
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sidebar_name?: Maybe<Scalars['String']>;
  title: UxGnpVerticalCategoryTitle;
  type: UxGnpType;
};

/** 카테고리 타이틀 */
export type UxGnpVerticalCategoryTitle = {
  category_key: Scalars['String'];
  icon?: Maybe<UxCommonImage>;
  id?: Maybe<Scalars['ID']>;
  landing_url: Scalars['String'];
  title: UxCommonText;
};

/** 하위 카테고리 아이템 */
export type UxGnpVerticalSubCategory = {
  Id?: Maybe<Scalars['ID']>;
  category_key: Scalars['String'];
  icon?: Maybe<UxCommonImage>;
  landing_url: Scalars['String'];
  title: UxCommonText;
};

/** 직진 배너로된 진입점 */
export type UxGnpZigzinBanner = UxGnp & {
  display_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image: UxCommonImage;
  landing_url: Scalars['String'];
  sidebar_name?: Maybe<Scalars['String']>;
  sub_title: UxCommonText;
  title: UxCommonText;
  type: UxGnpType;
};

export type UxGoodsAgeFilterOption = {
  age: Scalars['String'];
  count: Scalars['Int'];
  selected: Scalars['Boolean'];
};

export type UxGoodsAgeFilterOptionList = UxGoodsFilterOption & {
  age_option_list: Array<UxGoodsAgeFilterOption>;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsAttributeFilterOption = {
  attribute_item_option_list: Array<UxGoodsAttributeItemFilterOption>;
  /** 속성 키 (ex. length, fit, material 등) */
  key: Scalars['String'];
  name: Scalars['String'];
};

export type UxGoodsAttributeFilterOptionList = UxGoodsFilterOption & {
  attribute_option_list: Array<UxGoodsAttributeFilterOption>;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsAttributeItemFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type UxGoodsBrandFilterOption = UxGoodsFilterOption & {
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsCardColorChip = {
  border_color_dark?: Maybe<Scalars['String']>;
  border_color_light?: Maybe<Scalars['String']>;
  color_code: Scalars['String'];
};

export type UxGoodsCardDiscountInfo = {
  color?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum UxGoodsCardEmblem {
  NEW = 'NEW',
  ZIGZIN = 'ZIGZIN',
  ZPICK = 'ZPICK',
}

export type UxGoodsCardFomo = {
  icon_image_url: Scalars['String'];
  text: Scalars['String'];
};

export type UxGoodsCardItem = UxComponent & {
  aid?: Maybe<Scalars['String']>;
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  /** 북마크 여부 */
  bookmarked: Scalars['Boolean'];
  brand_name_badge_list?: Maybe<Array<UxDisplayBadge>>;
  bridge?: Maybe<Scalars['Boolean']>;
  browsing_type: UxBrowsingType;
  /** 상품카드 컴포넌트 스타일(Contents or Normal) */
  card_item_style?: Maybe<Scalars['String']>;
  catalog_product_id?: Maybe<Scalars['ID']>;
  color_option_list?: Maybe<Array<UxGoodsCardColorChip>>;
  column_count?: Maybe<Scalars['Int']>;
  /** 쿠폰 뱃지 목록 */
  coupon_badge_component_list?: Maybe<Array<UxBadgeComponent>>;
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  discount_rate?: Maybe<Scalars['Int']>;
  display_review_count?: Maybe<Scalars['String']>;
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  fomo?: Maybe<UxGoodsCardFomo>;
  free_shipping?: Maybe<Scalars['Boolean']>;
  goods_id?: Maybe<Scalars['String']>;
  has_coupon: Scalars['Boolean'];
  has_recommend_item?: Maybe<Scalars['Boolean']>;
  image_ratio?: Maybe<Scalars['Float']>;
  image_url?: Maybe<Scalars['String']>;
  is_ad: Scalars['Boolean'];
  is_brand: Scalars['Boolean'];
  /** 상품카드 닷 표시 여부(default: false) */
  is_enabled_not_my_tasty?: Maybe<Scalars['Boolean']>;
  is_exclusive: Scalars['Boolean'];
  is_new?: Maybe<Scalars['Boolean']>;
  /** 2024년 4월자 신규 상품 카드 여부(신규 상품 카드 대응 완료 후 제거되어야함) */
  is_plp_v2?: Maybe<Scalars['Boolean']>;
  is_saved_product: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  jpeg_image_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  /** 관리 카테고리 리스트 */
  managed_category_list?: Maybe<Array<UxCatalogProductCategory>>;
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  /** 가로형 상품카드 메타데이터 영역용 엠블렘 */
  metadata_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  metadata_emblem_list?: Maybe<Array<UxGoodsCardEmblem>>;
  one_day_delivery?: Maybe<UxCommonText>;
  origin_id?: Maybe<Scalars['String']>;
  /** admin에 의한 판매 중단 상태 (PENALTY, HIDDEN, NONE, OUT_OF_SYNC) */
  penalty?: Maybe<Scalars['String']>;
  performance_measurement?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  predicated_review_score?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Int']>;
  price_with_currency?: Maybe<PriceWithCurrency>;
  /** 상품 상태(VALID, INVALID) */
  product_status?: Maybe<UxProductStatus>;
  product_url?: Maybe<Scalars['String']>;
  ranking?: Maybe<Scalars['Int']>;
  ranking_fluctuation?: Maybe<RankingFluctuation>;
  review_count?: Maybe<Scalars['Int']>;
  review_score?: Maybe<Scalars['Float']>;
  sales_status?: Maybe<CatalogSalesStatus>;
  sellable_status: UxSellableStatus;
  shop?: Maybe<UxGoodsCardShopInfo>;
  shop_id: Scalars['ID'];
  shop_name?: Maybe<Scalars['String']>;
  shop_product_no?: Maybe<Scalars['String']>;
  similar_search?: Maybe<Scalars['Boolean']>;
  /** 상품 재고 수량. 쵀대 50개의 상품목록을 요청할 때 사용 가능. 재고 수량은 1분간 캐싱 */
  stock?: Maybe<Scalars['Int']>;
  /** seller의 요청에 의한 제한 (BLOCK: 판매중단, SUSPEND: 노출 중단, NONE: 정상 상품) */
  suspend_status?: Maybe<UxProductSuspendStatus>;
  /** PLP 상품카드 이미지 좌 하단 뱃지 리스트 */
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  thumbnail_emblem_list?: Maybe<Array<UxGoodsCardEmblem>>;
  thumbnail_nudge?: Maybe<UxGoodsCardNudge>;
  /** PLP 상품카드 이미지 좌 상단 뱃지 리스트 */
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  title?: Maybe<Scalars['String']>;
  title_line_number?: Maybe<Scalars['Int']>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
  uuid?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  webp_image_url?: Maybe<Scalars['String']>;
  zpay?: Maybe<Scalars['Boolean']>;
  zpay_price?: Maybe<Scalars['Int']>;
  zpay_price_with_currency?: Maybe<PriceWithCurrency>;
};

export type UxGoodsCardList = UxComponent & {
  columns_count?: Maybe<Scalars['Int']>;
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxComponent>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxGoodsCardNudge = {
  background_color: UxCommonColor;
  text: Scalars['String'];
  text_color: UxCommonColor;
};

export type UxGoodsCardParamInput = {
  /** 한줄에 표현할 상품 카드 갯수 (default 2) */
  colum_count?: InputMaybe<Scalars['Int']>;
  /** 상품 카드 이미지 비율 */
  image_ratio?: InputMaybe<Scalars['Float']>;
};

export type UxGoodsCardShopInfo = {
  delete_action?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['Int'];
  zpay_status?: Maybe<Scalars['Int']>;
};

export type UxGoodsCarousel = UxComponent & {
  /** 상품 카드 컴포넌트 리스트 */
  component_list: Array<UxGoodsCardItem>;
  /** 컬럼 수 */
  item_column_count?: Maybe<Scalars['Float']>;
  /** 캐로셀 라인 수 */
  line_count?: Maybe<Scalars['Int']>;
  /** 더보기 버튼 */
  more_button?: Maybe<UxButton>;
  position: Scalars['Int'];
  /** GoodsCarousel 스타일 */
  style?: Maybe<UxGoodsCarouselStyle>;
  type: UxComponentType;
};

/** UxGoodsCarousel 스타일 */
export enum UxGoodsCarouselStyle {
  DEFAULT = 'DEFAULT',
  WITHOUT_SHOP_NAME = 'WITHOUT_SHOP_NAME',
}

export type UxGoodsCategoryFilterOption = {
  count: Scalars['Int'];
  id: Scalars['ID'];
  multiple: Scalars['Boolean'];
  name: Scalars['String'];
  properties: Scalars['Boolean'];
  selected: Scalars['Boolean'];
  sub_category_list: Array<UxGoodsCategoryFilterOption>;
};

export type UxGoodsCategoryFilterOptionList = UxGoodsFilterOption & {
  category_option_list: Array<UxGoodsCategoryFilterOption>;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsColorFilterOption = {
  count: Scalars['Int'];
  image_url: Scalars['String'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  text_color: Scalars['Int'];
};

export type UxGoodsColorFilterOptionList = UxGoodsFilterOption & {
  color_option_list: Array<UxGoodsColorFilterOption>;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsColorImage = {
  color_list: Array<Scalars['String']>;
  id: Scalars['ID'];
  image_height?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  image_width?: Maybe<Scalars['Int']>;
  is_main: Scalars['Boolean'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type UxGoodsDeliveryFilterOption = {
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type UxGoodsDeliveryFilterOptionList = UxGoodsFilterOption & {
  delivery_option_list: Array<UxGoodsDeliveryFilterOption>;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsFilterList = UxComponent & {
  filter_list: Array<GoodsFilter>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxGoodsFilterOption = {
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsFilterOptionList = {
  item_list: Array<UxGoodsFilterOption>;
};

export enum UxGoodsFilterOptionType {
  UX_GOODS_AGE_FILTER_OPTION_LIST = 'UX_GOODS_AGE_FILTER_OPTION_LIST',
  UX_GOODS_ATTRIBUTE_FILTER_OPTION_LIST = 'UX_GOODS_ATTRIBUTE_FILTER_OPTION_LIST',
  UX_GOODS_BRAND_FILTER_OPTION = 'UX_GOODS_BRAND_FILTER_OPTION',
  UX_GOODS_CATEGORY_FILTER_OPTION_LIST = 'UX_GOODS_CATEGORY_FILTER_OPTION_LIST',
  UX_GOODS_COLOR_FILTER_OPTION_LIST = 'UX_GOODS_COLOR_FILTER_OPTION_LIST',
  UX_GOODS_DELIVERY_FILTER_OPTION_LIST = 'UX_GOODS_DELIVERY_FILTER_OPTION_LIST',
  UX_GOODS_MODEL_HEIGHT_FILTER_OPTION = 'UX_GOODS_MODEL_HEIGHT_FILTER_OPTION',
  UX_GOODS_PRICE_DISTRIBUTION_FILTER_OPTION = 'UX_GOODS_PRICE_DISTRIBUTION_FILTER_OPTION',
  UX_GOODS_PRICE_RANGE_FILTER_OPTION_LIST = 'UX_GOODS_PRICE_RANGE_FILTER_OPTION_LIST',
  UX_GOODS_PRODUCT_TYPE_FILTER_OPTION_LIST = 'UX_GOODS_PRODUCT_TYPE_FILTER_OPTION_LIST',
  UX_GOODS_PROPERTY_FILTER_OPTION_LIST = 'UX_GOODS_PROPERTY_FILTER_OPTION_LIST',
  UX_GOODS_STYLE_FILTER_OPTION_LIST = 'UX_GOODS_STYLE_FILTER_OPTION_LIST',
}

/** 상품 그리드 묶음 컴포넌트 */
export type UxGoodsGridGroup = UxComponent & {
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** 상품 카드 컴포넌트 리스트 */
  component_list: Array<UxGoodsCardItem>;
  /** 사용 목적 타입 */
  group_type: Scalars['String'];
  /** 타이틀 */
  main_title: UxText;
  position: Scalars['Int'];
  type: UxComponentType;
};

/** 상품 묶음 컴포넌트 */
export type UxGoodsGroup = UxComponent & {
  /** 액션 버튼 */
  action_button?: Maybe<UxButton>;
  /** 상품 캐로셀 */
  goods_carousel: UxGoodsCarousel;
  /** 사용 목적 타입 */
  group_type: Scalars['String'];
  /** TC ID 또는 추천 타입 ID */
  id: Scalars['ID'];
  /** 메인 이미지 */
  image?: Maybe<UxImage>;
  /** 광고 캐러셀 여부 */
  is_ad: Scalars['Boolean'];
  /** 메인 타이틀 */
  main_title: UxText;
  /** 더보기 버튼 */
  more_button?: Maybe<UxButton>;
  position: Scalars['Int'];
  /** 서브 타이틀 */
  sub_title?: Maybe<UxText>;
  type: UxComponentType;
};

export type UxGoodsModelHeightFilterOption = UxGoodsFilterOption & {
  /** 키 필터 간격 */
  interval: Scalars['Int'];
  /** 모델의 최대 키 */
  max_height: Scalars['Float'];
  /** 선택된 모델 키 */
  max_height_selected?: Maybe<Scalars['Float']>;
  /** 모델의 최소 키 */
  min_height: Scalars['Float'];
  /** 선택된 모델 키 */
  min_height_selected?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** UX_GOODS_MODEL_HEIGHT_FILTER_OPTION */
  type: UxGoodsFilterOptionType;
};

export type UxGoodsPriceDistributionFilterOption = UxGoodsFilterOption & {
  count_list: Array<Scalars['Int']>;
  interval: Scalars['Int'];
  interval_with_currency: PriceWithCurrency;
  max: Scalars['Int'];
  max_selected?: Maybe<Scalars['Int']>;
  max_with_currency: PriceWithCurrency;
  min: Scalars['Int'];
  min_selected?: Maybe<Scalars['Int']>;
  min_with_currency: PriceWithCurrency;
  name: Scalars['String'];
  type: UxGoodsFilterOptionType;
};

export type UxGoodsPriceRangeSelectFilterOption = {
  count: Scalars['Int'];
  max: Scalars['Int'];
  min: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type UxGoodsPriceRangeSelectFilterOptionList = UxGoodsFilterOption & {
  name: Scalars['String'];
  price_range_list: Array<UxGoodsPriceRangeSelectFilterOption>;
  total_count: Scalars['Int'];
  type: UxGoodsFilterOptionType;
};

export enum UxGoodsProductType {
  BRAND = 'BRAND',
  SOHO = 'SOHO',
}

export type UxGoodsProductTypeFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  type: UxGoodsProductType;
};

export type UxGoodsProductTypeFilterOptionList = UxGoodsFilterOption & {
  name: Scalars['String'];
  product_type_option_list: Array<UxGoodsProductTypeFilterOption>;
  type: UxGoodsFilterOptionType;
};

export type UxGoodsPropertyFilterOption = {
  name: Scalars['String'];
  property_item_option_list: Array<UxGoodsPropertyItemFilterOption>;
  type: UxGoodsPropertyType;
};

export type UxGoodsPropertyFilterOptionList = UxGoodsFilterOption & {
  name: Scalars['String'];
  property_option_list: Array<UxGoodsPropertyFilterOption>;
  type: UxGoodsFilterOptionType;
};

export type UxGoodsPropertyItemFilterOption = {
  count: Scalars['Int'];
  property: Scalars['String'];
  selected: Scalars['Boolean'];
};

export enum UxGoodsPropertyType {
  DETAIL = 'DETAIL',
  FIT = 'FIT',
  LENGTH = 'LENGTH',
  MATERIAL = 'MATERIAL',
  PATTERN = 'PATTERN',
  SLEEVE_LENGTH = 'SLEEVE_LENGTH',
}

export type UxGoodsStyleFilterOption = {
  count: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type UxGoodsStyleFilterOptionList = UxGoodsFilterOption & {
  name: Scalars['String'];
  style_option_list: Array<UxGoodsStyleFilterOption>;
  type: UxGoodsFilterOptionType;
};

export enum UxGridSectionGridType {
  THREE_TIMES_THREE = 'ThreeTimesThree',
  THREE_TIMES_TWO = 'ThreeTimesTwo',
  TWO_TIMES_THREE = 'TwoTimesThree',
  TWO_TIMES_TWO = 'TwoTimesTwo',
}

export type UxHybridPromotion = UxComponent & {
  native_header?: Maybe<UxHybridPromotionHeader>;
  position: Scalars['Int'];
  share_context?: Maybe<UxHybridShareContext>;
  type: UxComponentType;
  webview_content?: Maybe<UxHybridWebPage>;
};

export type UxHybridPromotionHeader = {
  image?: Maybe<UxCommonImage>;
  low_quality_image?: Maybe<UxCommonImage>;
  ratio?: Maybe<Scalars['Float']>;
  video?: Maybe<UxCommonVideo>;
};

export type UxHybridShareContext = {
  actions?: Maybe<Array<UxHybridShareContextAction>>;
  content?: Maybe<UxHybridShareContextHeader>;
  log?: Maybe<UxHybridShareContextLog>;
  title?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextAction = {
  data?: Maybe<UxHybridShareContextExtraData>;
  icon_url?: Maybe<Scalars['String']>;
  landing_link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextExtraData = {
  button_title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  extra_params?: Maybe<UxHybridShareContextExtraDataParam>;
  image_url?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextExtraDataParam = {
  url?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextHeader = {
  image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextLog = {
  data?: Maybe<UxHybridShareContextLogData>;
  navigation_sub?: Maybe<UxHybridShareContextLogNavigationSub>;
};

export type UxHybridShareContextLogData = {
  uuid?: Maybe<Scalars['String']>;
};

export type UxHybridShareContextLogNavigationSub = {
  page_id?: Maybe<Scalars['String']>;
  page_url?: Maybe<Scalars['String']>;
};

export type UxHybridWebPage = {
  web_view_native_url?: Maybe<Scalars['String']>;
  web_view_url?: Maybe<Scalars['String']>;
};

export type UxIconTextBadgeComponent = UxBadgeComponent & {
  background_color: UxCommonColor;
  border: UxBadgeBorder;
  icon_url?: Maybe<UxCommonImageUrl>;
  text: UxCommonText;
  /** ICON_TEXT */
  type: Scalars['String'];
};

/** 이미지 컴포넌트 */
export type UxImage = UxComponent & {
  /** 이미지 비율 */
  aspect_ratio?: Maybe<Scalars['Float']>;
  /** 이미지 URL */
  image_url: Scalars['String'];
  /** 이미지 WEBP URL */
  image_webp_url?: Maybe<Scalars['String']>;
  /** 딥링크 URL */
  link_url?: Maybe<Scalars['String']>;
  /** 추가 로깅 정보 */
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxImageAndTextTitle = UxComponent & {
  image_landing_url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  info?: Maybe<UxTitleInfo>;
  position: Scalars['Int'];
  sub_text?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: UxComponentType;
};

export type UxImageBadgeComponent = UxBadgeComponent & {
  image_size?: Maybe<UxImageSize>;
  image_url: UxCommonImageUrl;
  small_image_size?: Maybe<UxImageSize>;
  small_image_url?: Maybe<UxCommonImageUrl>;
  /** IMAGE */
  type: Scalars['String'];
};

export type UxImageBanner = {
  background_image_url?: Maybe<Scalars['String']>;
  dark_image_url?: Maybe<Scalars['String']>;
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  date_started?: Maybe<Scalars['CrTimestamp']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  landing_url?: Maybe<Scalars['String']>;
  log?: Maybe<Scalars['String']>;
  main_title_first?: Maybe<Scalars['String']>;
  main_title_second?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  shop_id?: Maybe<Scalars['ID']>;
  /** 비디오 영상인 경우 사운드 on/off 기능을 컨트롤한다 */
  sound_enabled?: Maybe<Scalars['Boolean']>;
  sub_title?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
  ui_type?: Maybe<UxImageBannerUiType>;
  /** 배너 롤링 업데이트 주기를 개별로 설정한다 */
  update_interval?: Maybe<Scalars['Int']>;
  use_dim?: Maybe<Scalars['Boolean']>;
  video_url?: Maybe<Scalars['String']>;
};

export type UxImageBannerGroup = UxComponent & {
  aspect_ratio?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxImageBanner>;
  position: Scalars['Int'];
  type: UxComponentType;
  update_interval?: Maybe<Scalars['Int']>;
};

export enum UxImageBannerUiType {
  CLP = 'CLP',
  DEFAULT = 'DEFAULT',
}

export type UxImageSize = {
  /** 이미지 높이 크기 */
  height: Scalars['Int'];
  /** 이미지 너비 크기 */
  width: Scalars['Int'];
};

/** 정수의 [min, max] 구간 */
export type UxIntRange = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type UxLine = UxComponent & {
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxLineWithMargin = UxComponent & {
  background_color?: Maybe<UxCommonColor>;
  color?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  margin?: Maybe<UxMargin>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxMainBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxMainBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxMargin = {
  bottom: Scalars['Int'];
  left: Scalars['Int'];
  right: Scalars['Int'];
  top: Scalars['Int'];
};

export enum UxMethodType {
  AD_DISPLAY = 'AD_DISPLAY',
  AD_PRODUCT = 'AD_PRODUCT',
  BEST_PRODUCT = 'BEST_PRODUCT',
  BUY_TOGETHER = 'BUY_TOGETHER',
  FULL_WIDTH_IMAGE_BANNER = 'FULL_WIDTH_IMAGE_BANNER',
  NEW_PRODUCT = 'NEW_PRODUCT',
  NONE = 'NONE',
  PERSONALIZE = 'PERSONALIZE',
  SALE_PRODUCT = 'SALE_PRODUCT',
  USER_NAME_HTML_TITLE = 'USER_NAME_HTML_TITLE',
  ZIGZIN_ENTRY = 'ZIGZIN_ENTRY',
}

export type UxMultilineRankingCarousel = UxComponent & {
  action_button?: Maybe<UxButton>;
  category?: Maybe<UxCategory>;
  component_list: Array<UxGoodsCardItem>;
  id: Scalars['ID'];
  log: Scalars['String'];
  main_title?: Maybe<UxText>;
  position: Scalars['Int'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxMyCounponItem = {
  color: UxMyCouponItemColor;
  coupon: UxBenefitCoupon;
  store: UxBenefitShop;
};

/** 혜택존 MyCoupon section text color 정보 */
export type UxMyCouponItemColor = {
  bg: Scalars['String'];
  dash: Scalars['String'];
  text: Scalars['String'];
};

export type UxMyCouponSectionData = {
  coupons: Array<UxMyCounponItem>;
};

export type UxMyCouponSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxMyPickCouponListSectionData = {
  /** 전체 보기 URL */
  deeplink_url: Scalars['String'];
  /** 마이픽 쿠폰 발급 정보 */
  issued_coupon_info?: Maybe<UxBenefitMyPickCouponListIssuedInfo>;
  /** shop 정보 목록 4개 */
  shop_list: Array<UxBenefitBannerMyPickCouponItem>;
  /** 혜택 섹션 타입 */
  type: UxBenefitSectionType;
};

export type UxMyPickCouponListSectionProps = {
  /** 앱 노출 타이틀 */
  title: Scalars['String'];
  /** 섹션 타입 */
  type: UxBenefitSectionType;
};

export type UxNewBrandList = {
  item_list: Array<UxShopRanking>;
};

export type UxNewProductInfo = {
  id?: Maybe<Scalars['ID']>;
  is_new?: Maybe<Scalars['Boolean']>;
  renewed_ad?: Maybe<Scalars['CrTimestamp']>;
};

export type UxNewShopList = {
  item_list: Array<UxShopRanking>;
};

export type UxNoResults = UxComponent & {
  /** 종횡비 */
  aspect_ratio?: Maybe<Scalars['Float']>;
  /** 다크모드 이미지 URL */
  dark_image_url?: Maybe<Scalars['String']>;
  /** 스토어 추가 버튼 노출 여부 */
  display_shop_add_button?: Maybe<Scalars['Boolean']>;
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 메인 타이틀 */
  no_results_main_title?: Maybe<UxText>;
  /** 서브 타이틀 */
  no_results_sub_title?: Maybe<UxText>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxOneOffNotificationInfo = {
  id: Scalars['ID'];
  text?: Maybe<UxCommonText>;
};

export type UxOptionCountDiff = {
  /** 속성 옵션 수 차이 */
  count_diff: Scalars['Int'];
  /** 속성명 */
  name: Scalars['String'];
};

export type UxPage = {
  highlight_color?: Maybe<Scalars['String']>;
  page_list: Array<UxPageItem>;
  user_feature?: Maybe<Scalars['Boolean']>;
  user_group?: Maybe<Scalars['String']>;
};

export type UxPageInfo = {
  end_cursor?: Maybe<Scalars['String']>;
  goods_filter_option_list?: Maybe<UxGoodsFilterOptionList>;
  has_next?: Maybe<Scalars['Boolean']>;
  page_name?: Maybe<Scalars['String']>;
  shop_end_cursor?: Maybe<Scalars['String']>;
  shop_has_next?: Maybe<Scalars['Boolean']>;
  top_item_list: Array<UxComponent>;
  type: Scalars['String'];
  ui_item_list: Array<UxComponent>;
  user_group?: Maybe<Scalars['String']>;
};

export type UxPageItem = {
  deep_link?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  dot_info?: Maybe<UxOneOffNotificationInfo>;
  header_color?: Maybe<UxCommonColor>;
  is_default_page?: Maybe<Scalars['Boolean']>;
  is_new?: Maybe<Scalars['Boolean']>;
  nudge_info?: Maybe<UxPageItemNudgeInfo>;
  page_id: Scalars['String'];
  page_name?: Maybe<Scalars['String']>;
  promotion_uuid?: Maybe<Scalars['String']>;
  pull_to_refresh: Scalars['Boolean'];
  sequence?: Maybe<Scalars['Int']>;
  sub_page_list?: Maybe<Array<UxPageItem>>;
  title_info?: Maybe<UxPageItemTitleInfo>;
  type: Scalars['String'];
  ubl?: Maybe<UxUbl>;
  user_feature?: Maybe<Scalars['String']>;
  web_url?: Maybe<Scalars['String']>;
};

export type UxPageItemNudgeInfo = {
  id: Scalars['ID'];
  title_text_color?: Maybe<UxStateColor>;
  type: Scalars['String'];
};

export type UxPageItemTitleInfo = {
  image_url?: Maybe<Scalars['String']>;
  landing_url?: Maybe<Scalars['String']>;
  lottie?: Maybe<UxCommonLottie>;
  search_link_url?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type UxPageRange = {
  end_cursor?: InputMaybe<Scalars['String']>;
  page_limit?: InputMaybe<Scalars['Int']>;
};

export enum UxPaymentCurrency {
  CAD = 'CAD',
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export type UxPdpBanner = {
  aspect_ratio: Scalars['Float'];
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  ubl?: Maybe<UxUbl>;
};

export type UxPdpNotice = {
  /** 내용 */
  content?: Maybe<Scalars['String']>;
  /** 공지 아이디 */
  id: Scalars['ID'];
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /**
   * 공지 유형
   * - VACATION
   * - DELIVERY
   * - NORMAL
   * - IMAGE
   */
  notice_type: Scalars['String'];
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
};

export type UxPdpRollingImageBanner = {
  id: Scalars['ID'];
  item_list: Array<UxPdpBanner>;
};

export type UxPinLightBannerSlideSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxPinLightBannerSlideSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxProductCustomTab = {
  name: Scalars['String'];
  products_preset_uuid: Scalars['String'];
};

export type UxProductCustomTabsSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxProductCustomTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  button_title: Scalars['String'];
  default_tab: Scalars['Int'];
  store_unique: Scalars['Boolean'];
  tabs: Array<UxProductCustomTab>;
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export enum UxProductEntryType {
  CRAWLING_API = 'CRAWLING_API',
  DIRECT = 'DIRECT',
  SCRAPPING = 'SCRAPPING',
}

export type UxProductFittingModel = {
  badge?: Maybe<UxBadge>;
  id: Scalars['ID'];
  image_url_list: Array<Scalars['String']>;
  name: Scalars['String'];
  profile_image_url: Scalars['String'];
  size_text_list: Array<Scalars['String']>;
};

export type UxProductFittingModelList = {
  item_list: Array<UxProductFittingModel>;
};

export type UxProductGridSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxProductGridSectionProps = {
  deeplink_url?: Maybe<Scalars['String']>;
  grid_type: UxGridSectionGridType;
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
  user_custom_title?: Maybe<Scalars['String']>;
};

/** 상품 아이템 정보 */
export type UxProductItemInfoInput = {
  /** 상품 ID */
  product_id: Scalars['ID'];
  /** 상품 아이템 ID */
  product_item_id: Scalars['ID'];
  /** 아이템별 수량 */
  quantity: Scalars['Int'];
  /** 샵 아이디 */
  shop_id?: InputMaybe<Scalars['Int']>;
};

export type UxProductListGridGroup = UxComponent & {
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** 상품 카드 컴포넌트 리스트 */
  component_list: Array<UxGoodsCardItem>;
  /** 자동 페이지네이션 여부 */
  is_auto_rolling?: Maybe<Scalars['Boolean']>;
  /** 인디케이터 노출여부 */
  is_show_indicator?: Maybe<Scalars['Boolean']>;
  /** 타이틀 */
  main_title: UxText;
  /** 노출되는 최대 줄 수 */
  max_rows?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  type: UxComponentType;
  /** 자동 페이지네이션 시간 (초) */
  update_interval?: Maybe<Scalars['Int']>;
};

/** 선택된 옵션 상세 정보 */
export type UxProductOptionDetail = {
  /** 옵션명 */
  name: Scalars['String'];
  /** 옵션값 */
  value: Scalars['String'];
};

export type UxProductOptionValueSize = {
  answer?: Maybe<UxProductOptionValueSizeAnswer>;
  height_range?: Maybe<UxIntRange>;
  name: Scalars['String'];
  purchase_percent?: Maybe<Scalars['Int']>;
  ranking: Scalars['Int'];
  size_with_category?: Maybe<Scalars['String']>;
  weight_range?: Maybe<UxIntRange>;
};

export type UxProductOptionValueSizeAnswer = {
  percent: Scalars['Int'];
  value: Scalars['String'];
};

export type UxProductPreorder = {
  /** 프리오더 주문 종료 */
  end_at: Scalars['CrTimestamp'];
  /** 프리오더 주문 배송 예정 */
  shipping_at: Scalars['CrTimestamp'];
  /** 프리오더 주문 시작 */
  start_at: Scalars['CrTimestamp'];
};

export type UxProductReliability = {
  text: UxText;
  value: Scalars['Int'];
};

export type UxProductReviewSummary = {
  /** 상품 카드 */
  item: UxGoodsCardItem;
  /** 리뷰 요약 컴포넌트 */
  review_summary: UxReviewTagSummaryList;
};

/** 상품 리뷰 요약 캐러셀 */
export type UxProductReviewSummaryGroup = UxComponent & {
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** 페이지에 노출되는 최대 상품 수 */
  column_count?: Maybe<Scalars['Int']>;
  /** 상품 카드 컴포넌트 리스트 */
  component_list: Array<UxProductReviewSummary>;
  /** info 설명 */
  description?: Maybe<Scalars['String']>;
  /** 타이틀 */
  main_title: UxText;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxProductSize = {
  option_value_list: Array<UxProductOptionValueSize>;
  recommendation_type: UxProductSizeType;
  user_account?: Maybe<UxUserAccount>;
};

export enum UxProductSizeType {
  /** 추천 사이즈를 제공할 수 없는 경우 */
  NONE = 'NONE',
  /** 옵션값별 최빈 구매 체형을 기반으로 제공되는 추천 사이즈 */
  OPTION = 'OPTION',
  /** 입력된 체형에 대해 구매 비중 기반으로 제공되는 추천 사이즈 */
  PURCHASE = 'PURCHASE',
  /** backfilled 데이터를 기반으로 제공되는 추천 사이즈 */
  RECOMMENDATION = 'RECOMMENDATION',
}

/** 상품 상태(catalog CatalogProductStatus 참조) */
export enum UxProductStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

/** seller의 요청에 의한 제한(catalog SuspendStatus 참조) */
export enum UxProductSuspendStatus {
  /** 판매 중단 */
  BLOCK = 'BLOCK',
  /** 정상 상품 */
  NONE = 'NONE',
  /** 노출 중단 */
  SUSPEND = 'SUSPEND',
}

/** 상품 카테고리 탭 정보 */
export type UxProductsCategoryTab = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UxProductsCategoryTabsSectionData = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<UxProductsCategoryTabsSectionDataItem>;
  total_count: Scalars['Int'];
};

export type UxProductsCategoryTabsSectionDataItem = {
  product: UxBenefitProductCardItem;
  store: UxBenefitShop;
};

export type UxProductsCategoryTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  category_list: Array<UxProductsCategoryTab>;
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxProductsSliderSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxProductsSliderSectionProps = {
  column: Scalars['Int'];
  deeplink_url?: Maybe<Scalars['String']>;
  product_count: Scalars['Int'];
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxPromotionFloatingBanner = UxComponent & {
  background_color?: Maybe<UxCommonColor>;
  button: UxCommonButton;
  icon?: Maybe<UxCommonImageUrl>;
  id: Scalars['ID'];
  is_lottie_icon: Scalars['Boolean'];
  key_color: UxCommonColor;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  nudge: PromotionFloatingBannerNudge;
  position: Scalars['Int'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxPromotionGaugeBarInfo = {
  action_button?: Maybe<UxCommonButton>;
  bonus_step_list: Array<UxPromotionGaugeStep>;
  promotion_id: Scalars['ID'];
  step_list: Array<UxPromotionGaugeStep>;
};

export type UxPromotionGaugeStep = {
  bonus_step_button?: Maybe<UxCommonButton>;
  coupon_button: UxCommonButton;
  coupon_discount_rate_title: UxCommonText;
  description?: Maybe<UxCommonText>;
  gauge_color: UxGaugeColor;
  progress: Scalars['Float'];
  status: GaugeStatus;
  target_price_text: UxCommonText;
  title: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export type UxPromotionRelatedItemGaugeBarInput = {
  action?: InputMaybe<Scalars['String']>;
  coupon_available_status?: InputMaybe<PdpCouponAvailableStatus>;
  product_id?: InputMaybe<Scalars['ID']>;
  scene?: InputMaybe<Scalars['String']>;
  shop_id?: InputMaybe<Scalars['ID']>;
};

export type UxPromotionSearchResultGaugeBarInput = {
  display_category_id_list?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  filter_id_list?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  page_id: Scalars['ID'];
  q?: InputMaybe<Scalars['String']>;
};

export type UxPromotionStoreHomeGaugeBarInput = {
  store_id: Scalars['ID'];
};

export type UxPromotionTc = UxComponent & {
  id: Scalars['ID'];
  item_aspect_ratio: Scalars['Float'];
  item_list: Array<UxPromotionTcItem>;
  main_title: UxCommonText;
  more_button?: Maybe<UxButton>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export enum UxPromotionTcDesignType {
  EVENT = 'EVENT',
  STORE = 'STORE',
}

export type UxPromotionTcEventItem = UxPromotionTcItem & {
  button?: Maybe<UxButton>;
  id: Scalars['ID'];
  image_url: UxCommonImage;
  landing_url: Scalars['String'];
  main_title_1: UxCommonText;
  main_title_2: UxCommonText;
  sub_title?: Maybe<UxCommonText>;
  type: UxPromotionTcDesignType;
  ubl?: Maybe<UxUbl>;
};

export type UxPromotionTcItem = {
  id: Scalars['ID'];
  image_url: UxCommonImage;
  landing_url: Scalars['String'];
  type: UxPromotionTcDesignType;
  ubl?: Maybe<UxUbl>;
};

export type UxPromotionTcStoreItem = UxPromotionTcItem & {
  id: Scalars['ID'];
  image_url: UxCommonImage;
  item: UxPromotionTcStoreItemInfo;
  landing_url: Scalars['String'];
  sub_title: UxCommonText;
  type: UxPromotionTcDesignType;
  ubl?: Maybe<UxUbl>;
};

export type UxPromotionTcStoreItemImageInfo = UxPromotionTcStoreItemInfo & {
  image_ratio: Scalars['Float'];
  logo_image_url: UxCommonImage;
  type: UxPromotionTcStoreItemInfoType;
};

export type UxPromotionTcStoreItemInfo = {
  type: UxPromotionTcStoreItemInfoType;
};

export enum UxPromotionTcStoreItemInfoType {
  IMAGE = 'IMAGE',
  TITLE_LINE_1 = 'TITLE_LINE_1',
  TITLE_LINE_2 = 'TITLE_LINE_2',
}

export type UxPromotionTcStoreItemTitleLine1Info = UxPromotionTcStoreItemInfo & {
  store_title: UxCommonText;
  type: UxPromotionTcStoreItemInfoType;
};

export type UxPromotionTcStoreItemTitleLine2Info = UxPromotionTcStoreItemInfo & {
  store_title_1: UxCommonText;
  store_title_2: UxCommonText;
  type: UxPromotionTcStoreItemInfoType;
};

export type UxQuickMenu = UxComponent & {
  is_animated: Scalars['Boolean'];
  menu_items: Array<UxQuickMenuItem>;
  position: Scalars['Int'];
  sub_items?: Maybe<Array<UxQuickMenuItem>>;
  type: UxComponentType;
};

export type UxQuickMenuItem = {
  ad_video?: Maybe<UxAdVideoItem>;
  badge_image?: Maybe<UxCommonImage>;
  category_key?: Maybe<Scalars['String']>;
  dot_info?: Maybe<UxOneOffNotificationInfo>;
  id: Scalars['ID'];
  image_url: UxCommonImage;
  landing_url?: Maybe<Scalars['String']>;
  name: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

export enum UxRelatedItemAction {
  CART = 'CART',
  LIKE = 'LIKE',
}

export type UxRelatedItemInfo = {
  /** 광고 뱃지 여부 */
  is_ad: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  ui_item_list: Array<UxComponent>;
};

export enum UxRelatedItemScene {
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

export type UxReview = {
  /** 리뷰 부가 정보 */
  additional_description?: Maybe<Scalars['String']>;
  attachment_list: Array<UxReviewAttachment>;
  attribute_list: Array<UxReviewAttribute>;
  contents: Scalars['String'];
  date_created: Scalars['CrTimestamp'];
  date_updated: Scalars['CrTimestamp'];
  fulfillment_badge?: Maybe<UxDisplayBadge>;
  id: Scalars['ID'];
  order_item_number: Scalars['String'];
  product_id: Scalars['ID'];
  product_info: UxReviewProductInfo;
  rating: Scalars['Int'];
  /** 요청한 사용자별 정보 */
  requested_user?: Maybe<UxReviewRequestedUser>;
  reviewer: UxReviewReviewer;
  shop_id: Scalars['ID'];
  site_id: Scalars['ID'];
  type: Scalars['String'];
};

export type UxReviewAttachment = {
  /** 원본 파일 URL */
  original_url: Scalars['String'];
  /** 상위 상품 리뷰 레코드 ID */
  product_review_id: Scalars['ID'];
  /** 썸네일 파일 URL */
  thumbnail_url: Scalars['String'];
};

export type UxReviewAttribute = {
  /** 답변 */
  answer: UxReviewAttributeAnswer;
  /** 질문 */
  question: UxReviewAttributeQuestion;
};

export type UxReviewAttributeAnswer = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UxReviewAttributeQuestion = {
  category: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UxReviewChipOptionList = UxReviewComponent & {
  /** 화면에 표시할 타이틀 */
  display_title: Scalars['String'];
  /** 옵션 key값 */
  key: Scalars['String'];
  /** 옵션 리스트 */
  option_list: Array<UxReviewOption>;
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 선택한 옵션 리스트 */
  selected_option_value_list: Array<Scalars['String']>;
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type UxReviewComponent = {
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

export type UxReviewCustomFilterInfo = {
  component_list: Array<UxReviewComponent>;
  load_custom_filter_title: UxReviewFilterTitleInfo;
  my_custom_filter_title: UxReviewFilterTitleInfo;
  title: UxReviewFilterTitleInfo;
};

export type UxReviewCustomFilterOptionInput = {
  /** 옵션 key값. ex) bottom_size, upper_size, shoe_size, skin_tone, etc. */
  key: Scalars['String'];
  /** 쿼리할 옵션값 리스트 - check, radio option의 경우 id_list를 value_list 값으로 설정 - range option의 경우 [gte, lte] 순서로 List 형태로 설정. length가 2이고 첫번째 인자가 두번째 인자보다 작거나 같을 때만 valid한 value_list */
  value_list: Array<Scalars['String']>;
};

/** 사용자 계정 상품리뷰 페널티 목록 정보 */
export type UxReviewFilterInfo = {
  custom_filter?: Maybe<UxReviewCustomFilterInfo>;
  /** 맞춤 정보 선택 가능 여부 */
  custom_filter_exists: Scalars['Boolean'];
  /** 유저 개인 맞춤정보 체크 여부 */
  my_custom_filter_checked: Scalars['Boolean'];
  /** 유저 개인 맞춤정보 존재 여부 */
  my_custom_filter_exists: Scalars['Boolean'];
  product_option_filter?: Maybe<UxReviewProductOptionFilterInfo>;
};

export type UxReviewFilterInfoInput = {
  /** 쿼리할 맞춤 필터 옵션 리스트 */
  custom_filter_option_list: Array<UxReviewCustomFilterOptionInput>;
  /** 내 맞춤정보 필터 체크여부 */
  my_custom_filter_checked: Scalars['Boolean'];
  /** 상품 아이디 */
  product_id: Scalars['ID'];
  /** 쿼리한 상품 옵션 리스트 */
  product_option_list: Array<UxReviewProductOptionInput>;
};

export type UxReviewFilterTitleInfo = {
  filter_id: Scalars['ID'];
  title: Scalars['String'];
};

export type UxReviewOption = UxReviewComponent & {
  /** 색상 코드 */
  code?: Maybe<Scalars['String']>;
  /** 화면에 표시할 라벨값 */
  label: Scalars['String'];
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 컴포넌트 타입 */
  type: Scalars['String'];
  /** 옵션 value 값 */
  value: Scalars['String'];
};

export type UxReviewPredictReview = {
  content: Scalars['String'];
  highlighting: Scalars['Boolean'];
  review_count: Scalars['Int'];
  topic: UxReviewTopic;
};

export type UxReviewPredictSummary = {
  predict_review_list: Array<UxReviewPredictReview>;
  preview_max_count: Scalars['Int'];
  rating?: Maybe<Scalars['Float']>;
  satisfaction_rate?: Maybe<Scalars['Int']>;
  title_list: Array<UxCommonText>;
};

export type UxReviewProductInfo = {
  /** 브라우저 타입 */
  browsing_type: PdpBrowsingType;
  /** 상품 이미지 주소 */
  image_url: Scalars['String'];
  /** 상품 이름 */
  name: Scalars['String'];
  /** 선택된 옵션 상세 정보 목록 */
  option_detail_list?: Maybe<Array<UxProductOptionDetail>>;
  /** 상품상세 랜딩 url */
  pdp_landing_url: Scalars['String'];
};

export type UxReviewProductOptionFilterInfo = {
  component_list: Array<UxReviewComponent>;
  title: UxReviewFilterTitleInfo;
};

export type UxReviewProductOptionInput = {
  /** 옵션 key값 */
  key: Scalars['String'];
  /** 쿼리할 옵션값 리스트 */
  value_list: Array<Scalars['String']>;
};

export type UxReviewRangeOption = UxReviewComponent & {
  /** 화면에 표시할 타이틀 */
  display_title: Scalars['String'];
  /** 옵션 Range를 조정할 때 클라이언트에서 사용할 diff 값 */
  gap: Scalars['Int'];
  /** 옵션 key값 */
  key: Scalars['String'];
  /** 허용되는 범위 최댓값 */
  max: Scalars['Int'];
  /** 단위. cm, mm, m, etc. */
  measure_unit: Scalars['String'];
  /** 허용되는 범위 최솟값 */
  min: Scalars['Int'];
  /** 컴포넌트를 화면에 표시할 순서값 */
  position: Scalars['Int'];
  /** 선택한 범위값 [gte, lte] 순서로 값이 내려간다. 이 순서를 지켜서 ReviewOptionInput에 value_list로 설정해야 한다. */
  selected_range: Array<Scalars['Int']>;
  /** 컴포넌트 타입 */
  type: Scalars['String'];
};

/** 요청한 사용자별 정보 */
export type UxReviewRequestedUser = {
  is_abuse_reported: Scalars['Boolean'];
  is_mine: Scalars['Boolean'];
};

export type UxReviewReviewer = {
  /** 리뷰 작성자의 작성당시 신체 정보의 텍스트 */
  body_text?: Maybe<Scalars['String']>;
  reviewer_id?: Maybe<Scalars['ID']>;
};

export type UxReviewSimilarProductReviewList = {
  after_key?: Maybe<Scalars['String']>;
  item_list: Array<UxReview>;
  title_list: Array<UxCommonText>;
  topic_list: Array<UxReviewTopic>;
};

export type UxReviewTagSummary = {
  /** 리뷰 요약 */
  summary: UxText;
  /** 태그 */
  tag?: Maybe<UxBadgeNormal>;
  /** 태그 다크모드 */
  tag_dark?: Maybe<UxBadgeNormal>;
};

export type UxReviewTagSummaryList = {
  /** 배경색 */
  background_color?: Maybe<UxCommonColor>;
  /** 상품의 리뷰 갯수 (#,### 형태) */
  display_review_count: Scalars['String'];
  /** 리뷰 점수 */
  review_score: Scalars['Float'];
  /** 리뷰 태그 요약 리스트 */
  tag_summary_list?: Maybe<Array<UxReviewTagSummary>>;
  /** UBL */
  ubl?: Maybe<UxUbl>;
};

export type UxReviewTopic = {
  display_title: Scalars['String'];
  key: Scalars['String'];
};

export type UxSavedProduct = {
  /** 상품 추천 버튼이 있는지 여부 / null이면 버튼자체를 노출하지 않음 */
  has_recommend?: Maybe<Scalars['Boolean']>;
  product_card_item: UxGoodsCardItem;
  shop_status: Scalars['String'];
};

export type UxSavedProductCategory = {
  category_id: Scalars['String'];
  key: Scalars['String'];
};

export type UxSavedProductCategoryInfo = {
  category_id: Scalars['String'];
  key: Scalars['String'];
  title: UxCommonText;
};

export type UxSavedProductCategoryInfoList = {
  category_list?: Maybe<Array<UxSavedProductCategoryInfo>>;
  is_exceeded: Scalars['Boolean'];
  limit_count: Scalars['Int'];
};

export type UxSavedProductDroppedPriceItem = {
  /** 업데이트 날짜 */
  date_updated: Scalars['CrTimestamp'];
  /** 하락 금액 */
  dropped_price: Scalars['Int'];
  /** 찜 했을때 가격 */
  price_when_saved: Scalars['Int'];
  /** 상품 카드 */
  product_card: UxGoodsCardItem;
  /** 장바구니 */
  select_option_url?: Maybe<Scalars['String']>;
};

export type UxSavedProductDroppedPriceList = {
  /** 마지막으로 상품 가격 하락을 확인한 날짜 */
  date_last_seen?: Maybe<Scalars['CrTimestamp']>;
  /** 상품 가격 하락 아이템 리스트 */
  item_list: Array<UxSavedProductDroppedPriceItem>;
  /** 총 개수 */
  total_count: Scalars['Int'];
};

export type UxSavedProductFilterInput = {
  input_item_list?: InputMaybe<Array<UxSavedProductFilterItemInput>>;
};

export type UxSavedProductFilterItem = {
  title: UxCommonText;
  type: Scalars['String'];
};

export type UxSavedProductFilterItemInput = {
  type: Scalars['String'];
};

export type UxSavedProductFilterList = {
  filter_item_list?: Maybe<Array<UxSavedProductFilterItem>>;
  folder_id?: Maybe<Scalars['String']>;
  is_exceeded: Scalars['Boolean'];
  limit_count: Scalars['Int'];
  saved_product_total_count: Scalars['Int'];
};

export type UxSavedProductHasNewDroppedPrice = {
  has_new_dropped_price: Scalars['Boolean'];
};

export type UxSavedProductList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  item_list: Array<UxSavedProduct>;
  total_count: Scalars['Int'];
};

export type UxSavedProductListFilter = {
  category_id?: InputMaybe<Scalars['String']>;
  folder_id?: InputMaybe<Scalars['String']>;
};

export type UxSavedProductRecommendList = {
  is_ad: Scalars['Boolean'];
  product_id: Scalars['ID'];
  product_recommend_list: Array<UxGoodsCardItem>;
  title: UxCommonText;
};

export type UxSavedStyling = {
  is_exceeded: Scalars['Boolean'];
  limit_count: Scalars['Int'];
  styling?: Maybe<Styling>;
};

/** 검색어 제안 */
export type UxSearchKeywordGuide = UxComponent & {
  /** 제안어 */
  guided_keyword?: Maybe<Scalars['String']>;
  /** 검색어 제안 문구 */
  main_title: UxCommonHtmlText;
  position: Scalars['Int'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxSearchResultHeader = UxComponent & {
  column_shifting_button_list?: Maybe<Array<SearchColumnShiftingButton>>;
  main_title: UxText;
  position: Scalars['Int'];
  sorting_list?: Maybe<Array<SearchSorting>>;
  total_count: Scalars['Int'];
  type: UxComponentType;
};

export type UxSearchResultQuickFilterList = UxComponent & {
  position: Scalars['Int'];
  quick_filter_list: Array<SearchResultQuickFilter>;
  type: UxComponentType;
};

export type UxSearchedShop = {
  alias_list?: Maybe<Array<Scalars['String']>>;
  badge_list?: Maybe<Array<UxBadgeNormal>>;
  bookmark_count: Scalars['Int'];
  /** 카테고리(의류, 가방 등) */
  category?: Maybe<Scalars['String']>;
  category_list: Array<Category>;
  dark_badge_list?: Maybe<Array<UxBadgeNormal>>;
  deeplink_url?: Maybe<Scalars['String']>;
  /** @deprecated badge_list 필드로 대체됨 */
  department_badge?: Maybe<UxBadgeNormal>;
  /** @deprecated dark_badge_list 필드로 대체됨 */
  department_dark_badge?: Maybe<UxBadgeNormal>;
  id: Scalars['ID'];
  is_bookmark_hidden?: Maybe<Scalars['Boolean']>;
  /** 쇼핑몰 운영 중단 여부 */
  is_disabled: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  /** Z결제 지원 여부 */
  is_zpay: Scalars['Boolean'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
  /** 쇼핑몰 상태(NORMAL, RENEWAL, DORMANCY, DELETION_REQUEST, STOPPED, BAD_WORKING) */
  status: Scalars['String'];
  style_list: Array<Scalars['String']>;
  typical_image_url: Scalars['String'];
  weblink_url?: Maybe<Scalars['String']>;
};

export type UxSearchedShopCarousel = UxComponent & {
  position: Scalars['Int'];
  /** 쇼핑몰 목록 */
  searched_shop_list: Array<UxSearchedShop>;
  type: UxComponentType;
};

export type UxSearchedShopList = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  item_list: Array<UxSearchedShop>;
  re_search?: Maybe<ReSearch>;
  total_count: Scalars['Int'];
};

export type UxSegmentTab = UxComponent & {
  filter_item_list: Array<UxFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  segment_tab_style?: Maybe<Scalars['String']>;
  type: UxComponentType;
};

export type UxSelectionAndCheckBoxAndSorting = UxComponent & {
  check_button_item_list?: Maybe<Array<UxFilterItem>>;
  fixed?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  selection_bottom_sheet_style?: Maybe<Scalars['String']>;
  selection_item_list: Array<UxFilterItem>;
  sorting_item_list: Array<UxFilterItem>;
  sorting_tooltip_info?: Maybe<UxOneOffNotificationInfo>;
  type: UxComponentType;
  update_text?: Maybe<UxCommonText>;
  update_tooltip_info?: Maybe<UxOneOffNotificationInfo>;
  ux_selection_sorting_style?: Maybe<UxSelectionAndSortingStyle>;
};

export type UxSelectionAndSorting = UxComponent & {
  fixed?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  selection_bottom_sheet_style?: Maybe<Scalars['String']>;
  selection_item_list: Array<UxFilterItem>;
  sorting_item_list: Array<UxFilterItem>;
  sorting_tooltip_info?: Maybe<UxOneOffNotificationInfo>;
  type: UxComponentType;
  update_text?: Maybe<UxCommonText>;
  update_tooltip_info?: Maybe<UxOneOffNotificationInfo>;
  ux_selection_sorting_style?: Maybe<UxSelectionAndSortingStyle>;
};

export enum UxSelectionAndSortingStyle {
  BUTTON = 'BUTTON',
  SELECTION = 'SELECTION',
}

export enum UxSellableStatus {
  CLOSED = 'CLOSED',
  NOT_SUPPORT_COUNTRY = 'NOT_SUPPORT_COUNTRY',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
}

export type UxSellerInfo = {
  business_license_number: Scalars['String'];
  company_address: Scalars['String'];
  company_email: Scalars['String'];
  company_name: Scalars['String'];
  domain: Scalars['String'];
  kakao_cs_url?: Maybe<Scalars['String']>;
  landline_number: Scalars['String'];
  mobile_number: Scalars['String'];
  naver_cs_url?: Maybe<Scalars['String']>;
  online_sales_license_number: Scalars['String'];
  online_sales_license_report_agency: Scalars['String'];
  privacy_policy_url: Scalars['String'];
  report_status: Scalars['String'];
  representative_name: Scalars['String'];
  selling_category: Scalars['String'];
  selling_method: Scalars['String'];
  terms_of_use_policy_url: Scalars['String'];
};

export type UxShop = {
  bookmark_count?: Maybe<Scalars['Int']>;
  business_license?: Maybe<UxShopBusinessLicense>;
  customer_center?: Maybe<UxShopCustomerCenter>;
  customer_center_tel?: Maybe<Scalars['String']>;
  date_created: Scalars['Float'];
  delete_action: Scalars['Int'];
  exchange_shipping_fee?: Maybe<Scalars['Int']>;
  extra_shipping_fee2?: Maybe<Scalars['Int']>;
  extra_shipping_fee3?: Maybe<Scalars['Int']>;
  free_shipping_amount?: Maybe<Scalars['Int']>;
  goods_count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** 쇼핑몰 즐겨찾기 여부 */
  is_saved_shop: Scalars['Boolean'];
  keywords: Scalars['String'];
  main_contact?: Maybe<UxShopContact>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
  need_wrap_goods_image: Scalars['Boolean'];
  online_sales_license?: Maybe<UxShopOnlineSalesLicense>;
  parent_id?: Maybe<Scalars['ID']>;
  partial_return_shipping_fee?: Maybe<Scalars['Int']>;
  ranking: Scalars['Int'];
  ranking_live: Scalars['Int'];
  return_address?: Maybe<UxShopAddress>;
  return_shipping_company?: Maybe<UxShopShippingCompany>;
  return_shipping_fee?: Maybe<Scalars['Int']>;
  shipping_address?: Maybe<UxShopAddress>;
  shipping_company1?: Maybe<UxShopShippingCompany>;
  shipping_company2?: Maybe<UxShopShippingCompany>;
  shipping_fee?: Maybe<Scalars['Int']>;
  shop_business_info?: Maybe<UxShopBusinessInfo>;
  shop_category_list: Array<UxShopCategory>;
  state: Scalars['Int'];
  trait: UxShopTrait;
  type: UxShopType;
  typical_image_url?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  zigzag_policy: Array<ZigzagPolicy>;
  zpay_status?: Maybe<UxShopZpayStatus>;
};

export type UxShopAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type UxShopBusinessInfo = {
  id: Scalars['Int'];
  seller_info: UxSellerInfo;
  shop_id: Scalars['ID'];
};

export type UxShopBusinessLicense = {
  /** 업태 */
  business_category?: Maybe<Scalars['String']>;
  /** 종목 */
  business_subcategory?: Maybe<Scalars['String']>;
  /** 사업장소재지 */
  company_address?: Maybe<Scalars['String']>;
  /** 업체(법인)명 */
  company_name?: Maybe<Scalars['String']>;
  /** 사업자등록증 번호 */
  registration_number?: Maybe<Scalars['String']>;
  /** 대표자명 */
  representative_name?: Maybe<Scalars['String']>;
};

export type UxShopCard = {
  is_ad: Scalars['Boolean'];
  log?: Maybe<Scalars['String']>;
  logo?: Maybe<UxImage>;
  shop: UxShopCardItem;
  thumbnail?: Maybe<UxImage>;
};

export type UxShopCardItem = {
  id: Scalars['ID'];
  is_bookmark: Scalars['Boolean'];
  main_domain?: Maybe<Scalars['String']>;
  main_name: Scalars['String'];
  sub_name?: Maybe<Scalars['String']>;
};

export type UxShopCarousel = {
  component_list: Array<UxShopCard>;
};

/** 샵 카테고리 정보(셀러홈과 동일) */
export type UxShopCategory = {
  /** id */
  id: Scalars['ID'];
  /** 이름 */
  name: Scalars['String'];
  /** 동일 depth에서의 순서 */
  sibling_order: Scalars['Int'];
  /** 카테고리 클릭시 노출될 딥링크 url(store_home) */
  store_home_deep_link?: Maybe<Scalars['String']>;
};

export type UxShopContact = {
  email?: Maybe<Scalars['String']>;
  landline_number?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum UxShopContactType {
  CEO = 'CEO',
  CS = 'CS',
  ETC = 'ETC',
  FINANCE = 'FINANCE',
  MAIN = 'MAIN',
  MANAGER = 'MANAGER',
}

/** 고객센터 정보 */
export type UxShopCustomerCenter = {
  /** 고객센터 운영시간 */
  business_time?: Maybe<TimeRange>;
  /** 고객센터 휴일 */
  closed_week_day_list?: Maybe<Array<UxWeekDayType>>;
  /** 공휴일 휴무 여부 */
  is_closed_on_holiday?: Maybe<Scalars['Boolean']>;
  /** 고객센터 점심시간 */
  lunch_time?: Maybe<TimeRange>;
  /** 담당자명 */
  manager_name?: Maybe<Scalars['String']>;
  /** 담당자 연락처 */
  manager_tel?: Maybe<Scalars['String']>;
};

export type UxShopExpandableList = UxComponent & {
  /** 한 화면에 보여지는 상품 갯수 */
  item_column_count: Scalars['Float'];
  /** 아이콘 */
  logo?: Maybe<UxImage>;
  position: Scalars['Int'];
  /** 쇼핑몰 캐로셀 */
  shop_carousel: UxShopCarousel;
  /** 확장형 리스트의 on/off 버튼 노출 유무 */
  show_open_button: Scalars['Boolean'];
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** shop_carousel 갯수 */
  total_count: Scalars['Int'];
  type: UxComponentType;
};

export type UxShopFilterInfo = {
  default_category: Scalars['String'];
  option_list: Array<UxShopFilterOption>;
};

export type UxShopFilterOption = {
  age_list: Array<Scalars['String']>;
  category: Scalars['String'];
  default_age_text?: Maybe<Scalars['String']>;
  default_style_text?: Maybe<Scalars['String']>;
  style_list: Array<Scalars['String']>;
};

export type UxShopGroup = UxComponent & {
  /** 한 화면에 보여지는 상품 갯수 */
  item_column_count: Scalars['Float'];
  position: Scalars['Int'];
  /** 쇼핑몰 캐로셀 */
  shop_carousel: UxShopCarousel;
  /** 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** shop_carousel 갯수 */
  total_count: Scalars['Int'];
  type: UxComponentType;
};

export type UxShopOnlineSalesLicense = {
  /** 라이센스 번호 */
  license_number?: Maybe<Scalars['String']>;
};

export type UxShopRanking = {
  age_list: Array<Scalars['String']>;
  /** 패바카에서만 제공합니다. 호출하는 경우에만 불러옵니다. */
  best_product_list: Array<UxGoodsCardItem>;
  bookmark_count?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  coupon_description?: Maybe<Scalars['String']>;
  date_approved?: Maybe<Scalars['CrTimestamp']>;
  goods_list: Array<UxShopRankingGoods>;
  /** 샵 스토리 유무 */
  has_story: HasStoryResponse;
  /** 광고 여부 */
  is_ad?: Maybe<Scalars['Boolean']>;
  is_free_shipping: Scalars['Boolean'];
  is_saved_shop: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay: Scalars['Boolean'];
  /** 로그 */
  log?: Maybe<Scalars['String']>;
  /** 스타일 태그가 아닌 커스텀 문구가 필요할 때 사용 한다 */
  main_copy?: Maybe<Scalars['String']>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
  new_product?: Maybe<UxNewProductInfo>;
  ranking: Scalars['Int'];
  shop_id: Scalars['ID'];
  style_list: Array<UxShopRankingStyle>;
  typical_image_url: Scalars['String'];
  variance?: Maybe<UxShopRankingVariance>;
};

export type UxShopRankingCardItem = UxComponent & {
  action_button?: Maybe<UxButton>;
  component_list: Array<UxGoodsCardItem>;
  is_saved_shop: Scalars['Boolean'];
  position: Scalars['Int'];
  ranking?: Maybe<Scalars['Int']>;
  shop_id: Scalars['ID'];
  shop_name: Scalars['String'];
  shop_typical_image_url: Scalars['String'];
  type: UxComponentType;
  variance?: Maybe<UxShopRankingVariance>;
};

/** 랭킹관 */
export type UxShopRankingDepartment = {
  id: Scalars['ID'];
  is_ad_visible: Scalars['Boolean'];
  is_bookmark_hidden: Scalars['Boolean'];
  is_new: Scalars['Boolean'];
  is_new_shop_visible: Scalars['Boolean'];
  is_product_visible: Scalars['Boolean'];
  name: Scalars['String'];
  page_id: Scalars['String'];
};

export type UxShopRankingFilterChip = {
  selected_item_list: Array<Scalars['String']>;
  type: UxShopRankingFilterChipType;
  value: Scalars['String'];
};

export enum UxShopRankingFilterChipType {
  AGE = 'AGE',
  CATEGORY = 'CATEGORY',
  STYLE = 'STYLE',
}

export type UxShopRankingGoods = {
  image_url: Scalars['String'];
  log: Scalars['String'];
  url: Scalars['String'];
};

export type UxShopRankingItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<UxShopRanking>>;
};

export type UxShopRankingList = {
  filter_list: Array<UxShopRankingFilterChip>;
  has_filter: Scalars['Boolean'];
  has_minor: Scalars['Boolean'];
  has_more: Scalars['Boolean'];
  item_list: Array<UxShopRanking>;
  ranking_updated: Scalars['String'];
};

export type UxShopRankingStyle = {
  color: Scalars['String'];
  value: Scalars['String'];
};

export type UxShopRankingVariance = {
  color: Scalars['String'];
  type?: Maybe<UxShopRankingVarianceType>;
  value?: Maybe<Scalars['Int']>;
};

export enum UxShopRankingVarianceType {
  DOWN = 'DOWN',
  NEW = 'NEW',
  UP = 'UP',
  ZERO = 'ZERO',
}

export enum UxShopShippingCompany {
  CJ = 'CJ',
  ETC = 'ETC',
  HANJIN = 'HANJIN',
  LOGEN = 'LOGEN',
  LOGIS = 'LOGIS',
  LOTTE = 'LOTTE',
  POSTAL = 'POSTAL',
}

/** 카테고리, 스타일, 연령정보 등 */
export type UxShopTrait = {
  age10?: Maybe<Scalars['Boolean']>;
  age20early?: Maybe<Scalars['Boolean']>;
  age20late?: Maybe<Scalars['Boolean']>;
  age20mid?: Maybe<Scalars['Boolean']>;
  age30early?: Maybe<Scalars['Boolean']>;
  age30late?: Maybe<Scalars['Boolean']>;
  age30mid?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['Int']>;
  category_list?: Maybe<Array<Scalars['String']>>;
  minor_category?: Maybe<Scalars['Int']>;
  minor_category_list?: Maybe<Array<Scalars['String']>>;
  minor_style?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export enum UxShopType {
  META = 'META',
  STORE = 'STORE',
  ZPAY = 'ZPAY',
}

/** zpay 상태 */
export enum UxShopZpayStatus {
  NORMAL = 'NORMAL',
  PREPARING = 'PREPARING',
  STOPPED = 'STOPPED',
  SUSPENDED = 'SUSPENDED',
}

export type UxSimilarProduct = {
  badge_list?: Maybe<Array<UxDisplayBadge>>;
  browsing_type: UxBrowsingType;
  c_url?: Maybe<Scalars['String']>;
  catalog_product_id?: Maybe<Scalars['ID']>;
  catalog_product_type?: Maybe<UxCatalogProductType>;
  color_option_list?: Maybe<Array<UxGoodsCardColorChip>>;
  discount_info?: Maybe<UxGoodsCardDiscountInfo>;
  discount_rate: Scalars['Int'];
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  free_shipping: Scalars['Boolean'];
  has_coupon: Scalars['Boolean'];
  id: Scalars['ID'];
  image_height: Scalars['Int'];
  image_url: Scalars['String'];
  image_width: Scalars['Int'];
  is_brand: Scalars['Boolean'];
  is_saved_product: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  price: Scalars['Int'];
  price_with_currency: PriceWithCurrency;
  product_no: Scalars['String'];
  product_url: Scalars['String'];
  retail_price?: Maybe<Scalars['Int']>;
  retail_price_with_currency?: Maybe<PriceWithCurrency>;
  sales_status: UxCatalogSalesStatus;
  shop_id: Scalars['ID'];
  shop_main_domain: Scalars['String'];
  shop_name: Scalars['String'];
  shop_product_no?: Maybe<Scalars['ID']>;
  similar_search: Scalars['Boolean'];
  thumbnail_emblem_badge_list?: Maybe<Array<UxDisplayBadge>>;
  thumbnail_nudge_badge_list?: Maybe<Array<UxDisplayBadge>>;
  title: Scalars['String'];
  url: Scalars['String'];
  zpay: Scalars['Boolean'];
  zpay_discount_price?: Maybe<Scalars['Int']>;
  zpay_discount_price_with_currency?: Maybe<PriceWithCurrency>;
  zpay_price?: Maybe<Scalars['Int']>;
  zpay_price_with_currency?: Maybe<PriceWithCurrency>;
};

/** SRP 배너 고도화 실험용으로 만든 간단한 형태의 배너 */
export type UxSimplePromotionBanner = UxComponent & {
  banner_id: Scalars['ID'];
  icon?: Maybe<UxCommonImageUrl>;
  key_color: UxCommonColor;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  position: Scalars['Int'];
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export enum UxSimsShopScene {
  /** 바텀 시트 */
  BOTTOM_SHEET = 'BOTTOM_SHEET',
  /** 쇼핑몰 탭 랭킹 (2탭) */
  SHOP_TAB_RANKING = 'SHOP_TAB_RANKING',
  /** 쇼핑몰 탭 즐겨찾기 (2탭) */
  SHOP_TAB_SAVED_SHOP = 'SHOP_TAB_SAVED_SHOP',
}

export type UxSingleBanner = UxComponent & {
  badge_types?: Maybe<Array<Scalars['String']>>;
  banner_id: Scalars['ID'];
  display_more_button?: Maybe<Scalars['Boolean']>;
  landing_url: Scalars['String'];
  log?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  single_banner_image: UxCommonImage;
  subtitle?: Maybe<UxCommonText>;
  title_first: UxCommonText;
  title_second?: Maybe<UxCommonText>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
  use_dim?: Maybe<Scalars['Boolean']>;
  use_more_button?: Maybe<Scalars['Boolean']>;
};

export type UxSorting = UxComponent & {
  filter_item_list: Array<UxFilterItem>;
  fixed?: Maybe<Scalars['Boolean']>;
  info?: Maybe<UxTitleInfo>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxSortingItem = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  str_id: Scalars['ID'];
};

export type UxSplashImage = {
  android_img_type?: Maybe<Scalars['String']>;
  android_img_url: Scalars['String'];
  display_schedule: DateRange;
  id: Scalars['String'];
  ios_img_type?: Maybe<Scalars['String']>;
  ios_img_url: Scalars['String'];
};

export type UxSquarePickBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxSquarePickBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxStateColor = {
  /** 활성화 색상 */
  active_color: UxCommonColor;
  /** 비활성화 색상 */
  inactive_color: UxCommonColor;
};

export type UxStoreCouponPriceTabCardItem = {
  coupon_discount_amount: Scalars['Int'];
  image_url: Scalars['String'];
  shop_id: Scalars['String'];
  shop_name: Scalars['String'];
};

export type UxStoreCouponPriceTabData = {
  min_required_amount: Scalars['Int'];
  name: Scalars['String'];
};

export type UxStoreCouponPriceTabsSectionData = {
  item_list: Array<UxStoreCouponPriceTabCardItem>;
};

export type UxStoreCouponPriceTabsSectionProps = {
  default_select_tab_index: Scalars['Int'];
  price_tab_list?: Maybe<Array<UxStoreCouponPriceTabData>>;
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxStoreCouponSlide = {
  price: Scalars['Int'];
  product: UxBenefitProductCardItem;
  store: UxBenefitShop;
};

export type UxStoreCouponSlideSectionData = {
  item_list: Array<UxStoreCouponSlide>;
};

export type UxStoreCouponSlideSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxStoreCouponTabsGroup = {
  item_list: Array<UxBenefitProductCardItem>;
  store: UxBenefitShop;
  total_discount_amount: Scalars['Int'];
};

export type UxStoreCouponTabsSectionData = {
  group_list: Array<UxStoreCouponTabsGroup>;
};

export type UxStoreCouponTabsSectionProps = {
  benefit_section_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxStoryDetailInfo = {
  duration: Scalars['Float'];
  image_url?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  reaction?: Maybe<Array<Maybe<UxStoryReaction>>>;
  timestamp: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type UxStoryGoodsInfo = {
  browsing_type: UxBrowsingType;
  catalog_product_id?: Maybe<Scalars['ID']>;
  discount_rate?: Maybe<Scalars['Int']>;
  final_price: Scalars['Int'];
  final_price_with_currency: PriceWithCurrency;
  free_shipping?: Maybe<Scalars['Boolean']>;
  goods_url?: Maybe<Scalars['String']>;
  has_coupon: Scalars['Boolean'];
  image_url?: Maybe<Scalars['String']>;
  is_brand: Scalars['Boolean'];
  is_zonly: Scalars['Boolean'];
  is_zpay_discount: Scalars['Boolean'];
  max_price: Scalars['Int'];
  max_price_with_currency: PriceWithCurrency;
  price?: Maybe<Scalars['Int']>;
  price_with_currency?: Maybe<PriceWithCurrency>;
  shop_id: Scalars['ID'];
  shop_product_no?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  webp_image_url?: Maybe<Scalars['String']>;
  zpay?: Maybe<Scalars['Boolean']>;
};

export type UxStoryInfo = {
  item_focus_position: Scalars['Int'];
  item_list?: Maybe<Array<UxStoryItem>>;
  shop_id: Scalars['String'];
};

export type UxStoryInfoList = {
  story_list?: Maybe<Array<Maybe<UxStoryInfo>>>;
};

export type UxStoryItem = {
  goods_info?: Maybe<UxStoryGoodsInfo>;
  log?: Maybe<Scalars['String']>;
  story_id: Scalars['String'];
  story_info?: Maybe<UxStoryDetailInfo>;
};

export type UxStoryReaction = {
  count: Scalars['Int'];
  is_select: Scalars['Boolean'];
  text: Scalars['String'];
  type: UxStoryReactionType;
};

export type UxStoryReactionInput = {
  is_select: Scalars['Boolean'];
  reaction_type: UxStoryReactionType;
  story_id: Scalars['String'];
};

export enum UxStoryReactionType {
  UX_HEART_EYES = 'UX_HEART_EYES',
}

export type UxStoryShop = {
  id: Scalars['String'];
  image_url: Scalars['String'];
  is_new: Scalars['Boolean'];
  main_domain: Scalars['String'];
  name: Scalars['String'];
};

export type UxStoryShopList = {
  shop_list?: Maybe<Array<Maybe<UxStoryShop>>>;
  survey_url?: Maybe<Scalars['String']>;
};

export type UxStoryViewInput = {
  story_id: Scalars['String'];
  timestamp: Scalars['String'];
};

export type UxStrapBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxStrapBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxStyleGoodsImage = {
  image_url: Scalars['String'];
  webp_image_url?: Maybe<Scalars['String']>;
};

export type UxStyleGroup = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
};

export type UxStyleGroupList = {
  birth_on_year?: Maybe<Scalars['String']>;
  is_deprecated: Scalars['Boolean'];
  item_list: Array<UxStyleGroup>;
};

export type UxStyleGroupShop = {
  bookmark_count?: Maybe<Scalars['String']>;
  goods_image_list: Array<UxStyleGoodsImage>;
  goods_image_url_list: Array<Scalars['String']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  main_domain: Scalars['String'];
  name: Scalars['String'];
};

export type UxStyleGroupShopList = {
  item_list: Array<UxStyleGroupShop>;
};

export type UxSubBannerGroupSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxSubBannerGroupSectionProps = {
  banner_group_uuid: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxSubCategoryInfo = {
  ID: Scalars['ID'];
  name: Scalars['String'];
};

/** 텍스트 컴포넌트 */
export type UxText = UxComponent & {
  /** 텍스트 정렬 */
  align?: Maybe<UxTextAlign>;
  /** HTML 텍스트 여부 */
  is_html_text?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  /** 텍스트 스타일 */
  style?: Maybe<UxTextStyle>;
  /** 텍스트 */
  text: Scalars['String'];
  type: UxComponentType;
};

/** UxText 정렬 위치 */
export enum UxTextAlign {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type UxTextAndMoreButton = UxComponent & {
  main_title: UxText;
  more_button?: Maybe<UxButton>;
  position: Scalars['Int'];
  total_count: Scalars['Int'];
  type: UxComponentType;
};

export type UxTextAndSorting = UxComponent & {
  main_title?: Maybe<UxText>;
  position: Scalars['Int'];
  sorting_item_list: Array<UxSortingItem>;
  sub_title?: Maybe<UxText>;
  type: UxComponentType;
};

/** UxText 스타일 */
export enum UxTextStyle {
  BODY_1 = 'BODY_1',
  BODY_2 = 'BODY_2',
  BODY_3 = 'BODY_3',
  CAPTION = 'CAPTION',
  CAPTION_NUM = 'CAPTION_NUM',
  HEADER_16 = 'HEADER_16',
  HEADER_18 = 'HEADER_18',
  SUB_TITLE_1 = 'SUB_TITLE_1',
  SUB_TITLE_1_2 = 'SUB_TITLE_1_2',
  SUB_TITLE_2 = 'SUB_TITLE_2',
  SUB_TITLE_3 = 'SUB_TITLE_3',
  TITLE_1 = 'TITLE_1',
  TITLE_2 = 'TITLE_2',
}

export type UxTextTitle = UxComponent & {
  info?: Maybe<UxTitleInfo>;
  is_html: Scalars['Boolean'];
  is_sponsored?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  sub_text?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: UxComponentType;
};

export type UxThemeChip = {
  chip_type: CategoryThemeType;
  filter_item: UxThemeChipFilterItem;
  item_list: Array<UxGoodsCardItem>;
};

export type UxThemeChipFilterItem = {
  icon?: Maybe<UxCommonImage>;
  id: Scalars['ID'];
  selected: Scalars['Boolean'];
  title: Scalars['String'];
  ubl?: Maybe<UxUbl>;
};

export type UxThemeComponentCarousel = UxComponent & {
  component_list: Array<UxGoodsCardItem>;
  header_title?: Maybe<UxCommonText>;
  position: Scalars['Int'];
  type: UxComponentType;
};

export type UxThemeRanking = UxComponent & {
  main_title: UxText;
  position: Scalars['Int'];
  theme_chips?: Maybe<Array<UxThemeChip>>;
  type: UxComponentType;
};

/** 4.0 HOME 뷰티어택 카드형 리턴 타입 */
export type UxTimeDealCard = UxComponent & {
  card_more_button: UxCommonButton;
  column_count?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['CrTimestamp']>;
  image_ratio?: Maybe<Scalars['Float']>;
  image_url_list: Array<UxCommonImageUrl>;
  landing_url: Scalars['String'];
  main_title: UxCommonText;
  position: Scalars['Int'];
  sub_title?: Maybe<UxCommonText>;
  type: UxComponentType;
  ubl?: Maybe<UxUbl>;
};

export type UxTimedealSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxTimedealSectionProps = {
  event_time_ended: Scalars['CrTimestamp'];
  event_time_started: Scalars['CrTimestamp'];
  products_preset_uuid: Scalars['String'];
  server_time?: Maybe<Scalars['CrTimestamp']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  type: UxBenefitSectionType;
};

export type UxTitleInfo = {
  desc?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UxTopImageProductGridSectionData = {
  server_time: Scalars['CrTimestamp'];
};

export type UxTopImageProductGridSectionProps = {
  button_title: Scalars['String'];
  image_url: Scalars['String'];
  products_preset_uuid: Scalars['String'];
  store_unique: Scalars['Boolean'];
  type: UxBenefitSectionType;
};

export type UxUbl = {
  object?: Maybe<UxUblObject>;
  server_log?: Maybe<Scalars['CrJson']>;
};

export type UxUblObject = {
  id?: Maybe<Scalars['String']>;
  idx?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UxUserAccount = {
  body?: Maybe<UxUserBody>;
  name?: Maybe<Scalars['String']>;
};

/** 쿠폰 정책 */
export type UxUserAccountCouponPolicy = {
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount?: Maybe<Scalars['Int']>;
  /** 쿠폰적용 회차정보 (1차 쿠폰 : 0 , 2차 쿠폰 : 1) */
  priority: Scalars['Int'];
};

export type UxUserBody = {
  /** 신장 */
  height: Scalars['Int'];
  /** 사이즈에 대한 문구, e.g. '하의 27' */
  size_text?: Maybe<Scalars['String']>;
  /** 체중 */
  weight: Scalars['Int'];
};

export type UxUserBodyInputComponent = {
  position: Scalars['Int'];
  type: Scalars['String'];
};

export type UxUserBodyInputComponentGroup = {
  key_list: Array<Scalars['String']>;
  label: Scalars['String'];
};

export type UxUserBodyInputGroup = {
  key: Scalars['String'];
  label: Scalars['String'];
};

export type UxUserBodyInputInfo = {
  component_group_list?: Maybe<Array<UxUserBodyInputComponentGroup>>;
  component_list: Array<UxUserBodyInputComponent>;
};

export type UxUserBodyOption = UxUserBodyInputComponent & {
  key: Scalars['String'];
  label: Scalars['String'];
  position: Scalars['Int'];
  type: Scalars['String'];
};

export type UxUserBodyOptionListInputInfo = UxUserBodyInputComponent & {
  group: UxUserBodyInputGroup;
  is_multiple: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  /** 옵션 리스트 */
  option_list: Array<UxUserBodyOption>;
  position: Scalars['Int'];
  selected_option_key_list?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type UxUserBodySelectionInputInfo = UxUserBodyInputComponent & {
  anchor: Scalars['Int'];
  /** 옵션 Range를 조정할 때 클라이언트에서 사용할 diff 값 */
  gap: Scalars['Int'];
  group: UxUserBodyInputGroup;
  key: Scalars['String'];
  label: Scalars['String'];
  /** 허용되는 범위 최댓값 */
  max: Scalars['Int'];
  /** 단위. cm, mm, m, etc. */
  measure_unit: Scalars['String'];
  /** 허용되는 범위 최솟값 */
  min: Scalars['Int'];
  placeholder: Scalars['String'];
  position: Scalars['Int'];
  selected_value?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type UxWebBrowserPage = {
  /** 페이지에 속한 page_item list */
  page_item_list: Array<UxWebBrowserPageItem>;
  /** 페이지 상단에 노출될 이미지 */
  page_logo_image?: Maybe<UxCommonImage>;
  /** (로그) 검색시 랜딩에 이용 */
  srp_page_id: Scalars['String'];
};

/** webBrowserPage 버튼 */
export type UxWebBrowserPageButton = {
  /** 이미지 */
  image?: Maybe<UxCommonImage>;
  /** 랜딩 url */
  landing_url?: Maybe<Scalars['String']>;
  /** 버튼 타입 */
  type: UxWebBrowserPageButtonType;
  /** ubl */
  ubl?: Maybe<UxUbl>;
};

export enum UxWebBrowserPageButtonType {
  CART = 'CART',
  CUSTOM = 'CUSTOM',
}

export type UxWebBrowserPageItem = {
  /** ddp page id */
  ddp_page_id?: Maybe<Scalars['ID']>;
  /** 플로팅 버튼 list */
  floating_button_list: Array<UxWebBrowserPageItemFloatingButton>;
  id: Scalars['ID'];
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** page item 이름 */
  name: Scalars['String'];
  /** 활성/비활성 page item name 색상 */
  name_color: UxStateColor;
  /**
   * 우 상단 버튼 list
   * 반환 값 : null 이면 기본 버튼 셋(장바구니, 검색)을 노출
   * 반환 값 : not null 이면 서버에서 내려주는 값만 노출
   */
  page_button_list?: Maybe<Array<UxWebBrowserPageButton>>;
  /** (로그) page id */
  page_id: Scalars['String'];
  /** sub page item list */
  page_item_list: Array<UxWebBrowserPageItem>;
};

export type UxWebBrowserPageItemFloatingButton = {
  background_color: UxCommonColor;
  icon_image?: Maybe<UxCommonImage>;
  landing_url?: Maybe<Scalars['String']>;
  text: UxCommonText;
  ubl?: Maybe<UxUbl>;
};

/** 웹 페이지 컴포넌트 */
export type UxWebPage = UxComponent & {
  position: Scalars['Int'];
  type: UxComponentType;
  /** 웹 사이트 URL */
  url: Scalars['String'];
};

/** 휴일 요일 */
export enum UxWeekDayType {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED',
}

export type UxWmsNotificationInfo = {
  /** 재입고 알림 여부 */
  active: Scalars['Boolean'];
};

export type UxZonlyEntryPointBanner = {
  aspect_ratio: Scalars['Float'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
};

export type UxZonlyEntryPointCard = {
  card_color: Scalars['String'];
  card_text?: Maybe<Scalars['String']>;
  card_title: Scalars['String'];
  header_text?: Maybe<Scalars['String']>;
  header_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  open_reserve_time?: Maybe<Scalars['CrTimestamp']>;
};

export type UxZonlyEntryPointGroup = {
  item_list: Array<Maybe<UxZonlyEntryPointCard>>;
  landing_url: Scalars['String'];
};

export type UxZonlyEntryPointInfo = {
  banner?: Maybe<UxZonlyEntryPointBanner>;
  group?: Maybe<UxZonlyEntryPointGroup>;
};

export type UxdGoodsCardItemList = {
  end_cursor?: Maybe<Scalars['String']>;
  item_list?: Maybe<Array<UxGoodsCardItem>>;
  total_count: Scalars['Int'];
};

/** 상품주문 관련 요청 */
export type ValidateOrderPromotionForClaimResult = {
  /** 에러 코드 */
  error_code: Scalars['String'];
  /** 에러 메시지 */
  error_message: Scalars['String'];
  /** 프로모션별로 validate 후에 사용자에게 노출될 상품주문목록 */
  item_list: Array<OrderItem>;
  /** 주문에 적용된 프로모션 정보 */
  promotion?: Maybe<OrderPromotion>;
};

export type VbankPayInfo = {
  /** 입금 금액 */
  amount: Scalars['Int'];
  /** 은행 이름 */
  bank_name: Scalars['String'];
  /** 입금 기한 */
  due_date_ymd: Scalars['Int'];
  /** 예금주 이름 */
  holder: Scalars['String'];
  /** 계좌 번호 */
  number: Scalars['String'];
};

export enum VendorEventType {
  CARD_CLAIM = 'CARD_CLAIM',
  PG_COUPON = 'PG_COUPON',
  PG_POINT = 'PG_POINT',
}

export enum VendorType {
  EXIMBAY = 'EXIMBAY',
  GALAXIA = 'GALAXIA',
  INICIS = 'INICIS',
  KAKAOPAY = 'KAKAOPAY',
  NICEPAY = 'NICEPAY',
  SETTLEBANK = 'SETTLEBANK',
  TOSSPAY = 'TOSSPAY',
  ZIGZAG = 'ZIGZAG',
}

/** verifyEmailAuthentication의 입력 */
export type VerifyEmailAuthenticationInput = {
  /** email */
  email: Scalars['String'];
  /**
   * 이메일 인증 목적
   * - FIND_PASSWORD: 비밀번호 찾기
   */
  purpose_type: Scalars['String'];
  /** 인증코드 */
  token: Scalars['String'];
};

/** verifyEmailAuthentication의 결과 */
export type VerifyEmailAuthenticationResult = {
  /** 인증완료 처리 후 내부적으로 사용되는 인증키 */
  authenticated_key: Scalars['String'];
};

/** verifyMobileAuthenticationToken의 입력 */
export type VerifyMobileAuthenticationTokenInput = {
  /** 휴대폰 번호 */
  mobile_number: Scalars['String'];
  /** 인증 토큰 */
  token: Scalars['String'];
};

/** verifyMobileAuthenticationToken 결과 */
export type VerifyMobileAuthenticationTokenResult = {
  /** 이메일 목록 */
  email_list: Array<Scalars['String']>;
  /** 소셜로그인 계정 연동여부 */
  social_connection_list: Array<SocialConnection>;
};

/** verifyPasswordInLoginMobile의 입력 */
export type VerifyPasswordInLoginMobileInput = {
  /** 비밀번호 */
  password: Scalars['String'];
};

/** verifyPasswordInLoginMobile의 결과 */
export type VerifyPasswordInLoginMobileResult = {
  /** 인증키 */
  authenticated_key: Scalars['String'];
};

export type VerifyRecertificationInput = {
  /**
   * 접근하려는 페이지 (타입, url 등의 값이 들어갈 수 있음)
   * 인증시 넘겨주는 값과 동일해야한다.
   */
  access_page: Scalars['String'];
};

/** VerifySimplePayPassword 입력 */
export type VerifySimplePayPasswordInput = {
  /** 패스워드 */
  password: Scalars['String'];
};

/** 비밀번호 확인 결과 */
export type VerifySimplePayPasswordOutput = {
  error_message?: Maybe<Scalars['String']>;
  error_title?: Maybe<Scalars['String']>;
  exceeded?: Maybe<Scalars['Boolean']>;
  /** 인증 결과 */
  verified: Scalars['Boolean'];
};

/** 카테고리 asset */
export type VersionedCategoryAsset = {
  /** key */
  key: Scalars['String'];
  /** values */
  values: Scalars['CrJson'];
};

/** 카테고리 목록 */
export type VersionedManagedCategory = {
  /** asset 목록 */
  asset_list?: Maybe<Array<VersionedCategoryAsset>>;
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 카테고리 positon 정보 */
  position: CategoryPositionType;
};

/** 버저닝 된 카테고리 */
export type VersioningCategory = {
  /** asset 목록 */
  asset_list: Array<CategoryAsset>;
  /** 카테고리 상태 */
  category_status: Scalars['String'];
  /** 자식 카테고리 목록 */
  child?: Maybe<VersioningCategory>;
  /** id */
  id: Scalars['ID'];
  /** key */
  key: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** 부모 id */
  parent_id?: Maybe<Scalars['ID']>;
  /** 카테고리 positon 정보 */
  position: CategoryPositionType;
  /** 동일 depth에서의 순서 */
  sibling_order: Scalars['Int'];
};

export type ViewedProductBadge = {
  background_color?: Maybe<UxCommonColor>;
  text: UxCommonText;
};

export type WebDdpBasicCarousel = {
  item_list: Array<UxGoodsCardItem>;
};

export type WebDdpBasicCarouselBasic = {
  button?: Maybe<DdpButton>;
  header?: Maybe<DdpHeaderComponent>;
  item: WebDdpBasicCarousel;
};

export type WebSearchedFilterOptionInput = {
  brand_id?: InputMaybe<Scalars['String']>;
  display_category_id_list?: InputMaybe<Array<Scalars['String']>>;
  entry_type_list?: InputMaybe<Array<UxProductEntryType>>;
  only_free_shipping?: InputMaybe<Scalars['Boolean']>;
  only_zonly?: InputMaybe<Scalars['Boolean']>;
  shop_id_list?: InputMaybe<Array<Scalars['String']>>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type WebSrpBanner = {
  /** 이미지 URL */
  image_url?: Maybe<Scalars['String']>;
  /** 랜딩 URL */
  landing_url: Scalars['String'];
  /** left_image_url */
  left_image_url?: Maybe<Scalars['String']>;
  /** right_image_url */
  right_image_url?: Maybe<Scalars['String']>;
};

export type WebUi = {
  /** 웹UI의 비율 */
  aspect_ratio: Scalars['Float'];
  /** 컨텐츠 html url */
  content_url: Scalars['String'];
  /** 웹UI의 위치 */
  index?: Maybe<Scalars['Int']>;
  /** 웹UI의 표시 위치 */
  position: WebUiPosition;
  /** 웹UI의 종류 */
  type: WebUiType;
};

export type WebUiList = {
  /** 웹UI 목록 */
  item_list: Array<WebUi>;
};

export enum WebUiPosition {
  FIXED_TOP = 'FIXED_TOP',
  IN_CONTENTS = 'IN_CONTENTS',
}

export enum WebUiType {
  AD_DISPLAY = 'AD_DISPLAY',
  ITEMS = 'ITEMS',
  MY_PAGE = 'MY_PAGE',
  SEARCH = 'SEARCH',
  SHOPS_FAVORITE = 'SHOPS_FAVORITE',
  SHOPS_RANKING = 'SHOPS_RANKING',
}

/** WebviewBanner 섹션 */
export type WebviewBanner = UiSection & {
  /** WebviewBanner 섹션 데이터 */
  item: WebviewBannerItem;
  /** UI 섹션 타이틀 */
  title?: Maybe<Scalars['String']>;
  /** UI 섹션 타입 */
  type: UiSectionType;
};

/** WebView 로 구성된 Banner 정보 */
export type WebviewBannerItem = {
  /** Webview URL */
  content_url: Scalars['String'];
  /** 배치 정보 */
  location: WebUiPosition;
  /** UI 포지션 */
  position?: Maybe<Scalars['Int']>;
  /** WebviewBanner height ratio */
  ratio: Scalars['Float'];
};

export type WinnerInfo = {
  /** 전화번호 뒷자리(마스킹) */
  phone_number: Scalars['String'];
  /** 유저 ID(이메일, 마스킹) */
  user_id: Scalars['String'];
};

/** withdrawOrderItemRequest의 입력 */
export type WithdrawOrderItemRequestInput = {
  /** 주문 아이템 번호 */
  order_item_number: Scalars['String'];
};

/** withdrawOrderItemRequestList의 입력 */
export type WithdrawOrderItemRequestListInput = {
  /** 상품주문 번호 목록 */
  order_item_number_list: Array<Scalars['String']>;
};

/** 철회될 수 있는 요청 종류 */
export type WithdrawnRequestType = {
  /** 취소 */
  cancel?: Maybe<Scalars['String']>;
  /** 교환 */
  exchange?: Maybe<Scalars['String']>;
  /** 반품 */
  return?: Maybe<Scalars['String']>;
};

export type WmsDeliveryPriceInfo = {
  /** 당일배송 배송비 */
  oneday_delivery_price: Scalars['Int'];
  /** 당일배송 조건부 무료배송 기준 금액 */
  oneday_free_delivery_threshold: Scalars['Int'];
};

export enum WmsDeliveryType {
  ONE_DAY = 'ONE_DAY',
  TODAY = 'TODAY',
  ZONLY = 'ZONLY',
}

export type WmsOrderItemCancellable = {
  cancellable: Scalars['Boolean'];
  order_item_number: Scalars['String'];
};

export type WmsProductItem = {
  category_id: Scalars['ID'];
  date_recent_sold_out?: Maybe<Scalars['CrTimestamp']>;
  date_recent_warehoused_in?: Maybe<Scalars['CrTimestamp']>;
  factory_product_item_name?: Maybe<Scalars['String']>;
  forecast_quantity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  product_id: Scalars['ID'];
  product_item_id: Scalars['ID'];
  product_item_name?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  requested_safety_quantity?: Maybe<Scalars['Boolean']>;
  shop_id: Scalars['ID'];
  shop_name?: Maybe<Scalars['String']>;
  status?: Maybe<WmsProductItemStatus>;
  stock_status?: Maybe<WmsProductItemSafetyStockStatus>;
  total_available_quantity?: Maybe<Scalars['Int']>;
  total_hold_available_quantity?: Maybe<Scalars['Int']>;
  total_hold_refund_quantity?: Maybe<Scalars['Int']>;
  total_outgoing_waiting_quantity?: Maybe<Scalars['Int']>;
  total_safety_quantity?: Maybe<Scalars['Int']>;
  total_unavailable_quantity?: Maybe<Scalars['Int']>;
  use_safety_quantity?: Maybe<Scalars['Boolean']>;
  wms_product_item_option_list: Array<WmsProductItemOption>;
};

export type WmsProductItemOption = {
  date_created?: Maybe<Scalars['CrTimestamp']>;
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  product_item_id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  wms_product_item_id: Scalars['ID'];
};

export type WmsProductItemQuantity = {
  id: Scalars['ID'];
  product_id: Scalars['ID'];
  product_item_id: Scalars['ID'];
  shop_id: Scalars['ID'];
  total_available_quantity?: Maybe<Scalars['Int']>;
  total_hold_available_quantity?: Maybe<Scalars['Int']>;
  total_hold_refund_quantity?: Maybe<Scalars['Int']>;
  total_long_term_quantity?: Maybe<Scalars['Int']>;
  total_on_carry_out_quantity?: Maybe<Scalars['Int']>;
  total_outgoing_waiting_quantity?: Maybe<Scalars['Int']>;
  total_safety_quantity?: Maybe<Scalars['Int']>;
  total_unavailable_quantity?: Maybe<Scalars['Int']>;
};

export type WmsProductItemQuantityListInput = {
  product_id_list?: InputMaybe<Array<Scalars['ID']>>;
  product_item_id_list?: InputMaybe<Array<Scalars['ID']>>;
};

export enum WmsProductItemSafetyStockStatus {
  /** 충분 */
  ENOUGH = 'ENOUGH',
  /** 품절 임박 */
  NEARLY_SOLD_OUT = 'NEARLY_SOLD_OUT',
  /** 품절 */
  SOLD_OUT = 'SOLD_OUT',
}

export enum WmsProductItemStatus {
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
}

export type WmsUserNotification = {
  activated_at?: Maybe<Scalars['CrTimestamp']>;
  active: Scalars['Boolean'];
  date_created?: Maybe<Scalars['CrTimestamp']>;
  date_updated?: Maybe<Scalars['CrTimestamp']>;
  notification_type: WmsUserNotificationType;
  product_id: Scalars['ID'];
  product_item_id: Scalars['ID'];
  shop_id: Scalars['ID'];
  user_id: Scalars['ID'];
  wms_product_item?: Maybe<WmsProductItem>;
};

export type WmsUserNotificationInput = {
  active: Scalars['Boolean'];
  notification_type: WmsUserNotificationType;
  product_item_id: Scalars['ID'];
};

export enum WmsUserNotificationType {
  RE_WAREHOUSED_IN = 'RE_WAREHOUSED_IN',
}

export type ZOnlyEstimateShippingInfo = {
  arrival_date_except_title_text?: Maybe<Scalars['String']>;
  arrival_date_text: Scalars['String'];
  arrival_date_title_text?: Maybe<Scalars['String']>;
  arrival_notice_text?: Maybe<Scalars['String']>;
  arrival_one_day_text?: Maybe<Scalars['String']>;
  arrival_one_day_title_text?: Maybe<Scalars['String']>;
  arrival_text_with_user_postcode?: Maybe<Scalars['String']>;
  delivery_type?: Maybe<Scalars['String']>;
  estimate_content: Scalars['String'];
  estimate_timestamp: Scalars['CrTimestamp'];
  is_default_address?: Maybe<Scalars['Boolean']>;
  product_id?: Maybe<Scalars['ID']>;
  shipping_notify_info: ZOnlyShippingNotifyInfo;
};

export type ZOnlyOrderAndEstimateInfo = {
  delivery_estimate_time_str: Scalars['String'];
  order_time_str: Scalars['String'];
};

export type ZOnlyShippingNotifyInfo = {
  note_list?: Maybe<Array<Scalars['String']>>;
  note_title?: Maybe<Scalars['String']>;
  one_day_estimate_info?: Maybe<ZonlyOneDayEstimateInfoTable>;
  order_and_estimate_info: Array<ZOnlyOrderAndEstimateInfo>;
  order_and_estimate_info_title?: Maybe<Scalars['String']>;
  sub_notify?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** 젠데스크 챗 토큰 */
export type ZendeskChatToken = {
  token: Scalars['String'];
};

/** 공지사항 */
export type ZendeskNotice = {
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 제목 */
  title: Scalars['String'];
  /** 해당 공지사항의 url */
  url: Scalars['String'];
};

/** 공지사항 목록 및 요약 데이터 */
export type ZendeskNoticeList = {
  /** 공지사항 목록 */
  item_list: Array<ZendeskNotice>;
  /** 전체 갯수 */
  total_count: Scalars['Int'];
};

export type ZigzagCategory = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** 사용자 FAQ 카테고리 */
export type ZigzagFaqCategory = {
  /** 사용자 FAQ 카테고리 ID */
  id: Scalars['ID'];
  /** 사용자 FAQ 메인 카테고리 */
  main_category: ZigzagFaqMainCategory;
  /** 사용자 FAQ 카테고리 순서 */
  order: Scalars['Int'];
  /** 사용자 FAQ 카테고리 제목 */
  title: Scalars['String'];
};

/** 사용자 FAQ 카테고리 목록 */
export type ZigzagFaqCategoryList = {
  /** 사용자 FAQ 카테고리 목록 */
  item_list: Array<ZigzagFaqCategory>;
};

/** 사용자 FAQ 콘텐츠 */
export type ZigzagFaqContent = {
  /** 사용자 FAQ 콘텐츠 답 */
  answer: Scalars['String'];
  /** 콘텐츠가 속한 카테고리 ID */
  category_id: Scalars['ID'];
  /** 사용자 FAQ 콘텐츠 ID */
  id: Scalars['ID'];
  /** 사용자 FAQ 콘텐츠 순서 */
  order: Scalars['Int'];
  /** 사용자 FAQ 콘텐츠 질문 */
  question: Scalars['String'];
};

/** 사용자 FAQ 콘텐츠 목록 */
export type ZigzagFaqContentList = {
  /** 사용자 FAQ 콘텐츠 목록 */
  item_list: Array<ZigzagFaqContent>;
};

/** 사용자 FAQ 카테고리 */
export enum ZigzagFaqMainCategory {
  APP = 'APP',
  ZPAY = 'ZPAY',
}

/** 사용자 FAQ 유사어 */
export type ZigzagFaqSynonym = {
  /** 사용자 FAQ 유사어 ID */
  id: Scalars['ID'];
  /** 사용자 FAQ 콘텐츠 단어 */
  original: Scalars['String'];
  /** 사용자 FAQ 유사어 */
  synonym: Scalars['String'];
};

/** 사용자 FAQ 유사어 목록 */
export type ZigzagFaqSynonymList = {
  /** 사용자 FAQ 유사어 목록 */
  item_list: Array<ZigzagFaqSynonym>;
};

export type ZigzagLensSearch = {
  /** 검출된 아이템 목록 (검출된 아이템 없을 경우 emptyList) */
  detected_item_list: Array<ZigzagLensSearchDetectedItem>;
  /** 결과 코드 */
  result_code?: Maybe<ZigzagLensSearchResultCode>;
  /** 로그용 데이터 (APP에 노출하는 아이템만 포함) */
  server_log?: Maybe<Scalars['CrJson']>;
  /** 크로퍼 노출 여부 */
  show_cropper?: Maybe<Scalars['Boolean']>;
  /** 가이드 보기 버튼 노출 여부 */
  show_guide_button?: Maybe<Scalars['Boolean']>;
  /** 다시 검색하기 버튼 노출 여부 */
  show_retry_button?: Maybe<Scalars['Boolean']>;
  /** 노출 서브 문구 */
  sub_text?: Maybe<Scalars['String']>;
  /** 노출 문구 */
  text?: Maybe<Scalars['String']>;
};

export type ZigzagLensSearchDetectedItem = {
  /** 검출한 아이템 좌표 */
  crop_box: CropBox;
  /** 검출한 아이템 중앙 좌표 */
  crop_center?: Maybe<CropCenter>;
  /** 이미지가 유사한 상품 목록 */
  item_list: Array<UxGoodsCardItem>;
  /** 결과 코드 */
  result_code: ZigzagLensSearchResultCode;
  /**
   * 이미지가 유사한 상품 목록
   * @deprecated item_list 사용 권장
   */
  similar_product_list: Array<UxSimilarProduct>;
};

/** 직잭렌즈 이미지 데이터 타입 */
export enum ZigzagLensSearchImageType {
  BASE64 = 'BASE64',
  URL = 'URL',
}

export enum ZigzagLensSearchResultCode {
  /** 실패 */
  ERROR = 'ERROR',
  /** 비슷한 상품을 찾지 못함 (노출할 상품이 없음) */
  NO_RESULT = 'NO_RESULT',
  /** 성공 */
  SUCCESS = 'SUCCESS',
}

/** 공지사항 */
export type ZigzagNotice = {
  /** 카테고리 */
  category: Scalars['String'];
  /** 내용 */
  contents: Scalars['String'];
  /** 공지 내용 (HTML) */
  contents_html: Scalars['String'];
  /** 공지 날짜 ex) 20160101 */
  date_ymd: Scalars['Int'];
  /** 레코드 ID */
  id: Scalars['ID'];
  /** 공지사항 목록 상단 고정 여부 */
  is_pinned: Scalars['Boolean'];
  /** 공지사항에 필요한 link url */
  link?: Maybe<Scalars['String']>;
  /** 마이페이지 노출 시작일 */
  mypage_exposure_date_ymd_gte?: Maybe<Scalars['Int']>;
  /** 마이페이지 노출 종료일 */
  mypage_exposure_date_ymd_lte?: Maybe<Scalars['Int']>;
  /** os */
  os?: Maybe<ZigzagNoticeOsType>;
  /** 내용 요약 */
  shorten_contents: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
  /** 해당 공지사항의 url */
  url?: Maybe<Scalars['String']>;
};

/** 공지사항 목록 및 요약 데이터 */
export type ZigzagNoticeList = {
  /** 공지사항 목록 */
  item_list: Array<ZigzagNotice>;
  /** 조건을 만족하는 고정 공지사항 수 (페이지네이션 조건 제외) */
  pinned_count: Scalars['Int'];
  /** 조건을 만족하는 공지사항 수 (페이지네이션 조건 제외) */
  total_count: Scalars['Int'];
};

export enum ZigzagNoticeOsType {
  ANDROID = 'ANDROID',
  COMMON = 'COMMON',
  IOS = 'IOS',
  NONE = 'NONE',
}

export type ZigzagPolicy = {
  content_list: Array<ZigzagPolicyContent>;
  main_title: Scalars['String'];
};

export type ZigzagPolicyContent = {
  description: Scalars['String'];
  title: Scalars['String'];
};

/** 직잭위크 쿠폰 정보 */
export type ZigzagWeekCouponInfo = {
  /** 만료 일시 */
  date_expire?: Maybe<Scalars['CrTimestamp']>;
  /** 만료 기간 */
  days_expire?: Maybe<Scalars['Int']>;
  /** 쿠폰 할인 금액 */
  discount_amount: Scalars['Int'];
  /** 쿠폰을 사용할 수 있는 최소 금액 */
  min_required_amount: Scalars['Int'];
  /** 쿠폰 정책 ID */
  user_account_coupon_policy_id: Scalars['ID'];
};

/** 직잭위크 쿠폰팩 정보 */
export type ZigzagWeekCouponPackInfo = {
  /** 쿠폰팩 쿠폰 정보 */
  coupon_pack_items: Array<ZigzagWeekCouponInfo>;
  /** 쿠폰팩 unique ID */
  uid: Scalars['String'];
};

/** 직잭위크 쿠폰 타입 */
export enum ZigzagWeekCouponType {
  /** 단일 쿠폰 제공 */
  COUPON = 'COUPON',
  /** 쿠폰팩 제공 */
  COUPON_PACK = 'COUPON_PACK',
  /** 쿠폰 없음 */
  NONE = 'NONE',
}

/** 직잭위크 메타 정보 결과 */
export type ZigzagWeekMetaResult = {
  /** 직잭위크 종료일시 */
  date_ended?: Maybe<Scalars['CrTimestamp']>;
  /** 직잭위크 시작일시 */
  date_started?: Maybe<Scalars['CrTimestamp']>;
  /** 직잭위크 메타 코드 */
  meta_code: Scalars['String'];
  /**
   * 랜덤 키
   * 세션에 따라 1시간에 한번씩 갱신되는 랜덤 키
   * 비로그인 사용자의 경우도 동일
   */
  random_key?: Maybe<Scalars['String']>;
  /** 참여 셀러 목록 */
  seller_list: Array<ZigzagWeekSeller>;
  /** 서버 시간 */
  server_time: Scalars['CrTimestamp'];
  /**
   * 직잭위크 운영상태
   * true: 운영중, false: 운영 종료 OR 시작전
   */
  status: Scalars['Boolean'];
  /**
   * 직잭위크 운영상태 코드
   * 특별한 사항이 없을 경우 빈문자열 리턴
   * running_develop_mode - 개발 모드로 실제 쿠폰은 수행하지 않는다.
   * preparing_exhibitions - 직잭위크 시작전인 경우
   * closed_exhibitions - 직잭위크가 종료된 경우
   * terminated_exhibitions - 직잭위크가 삭제된 경우 or meta_code가 없는 경우
   */
  status_code: Scalars['String'];
  /** 직잭위크 카테고리 목록 */
  zigzag_week_category_list?: Maybe<Array<Scalars['String']>>;
};

/** 직잭위크 셀러 목록 */
export type ZigzagWeekSeller = ZigzagWeekUserReward & {
  /** 단일 쿠폰 정보 */
  coupon_info?: Maybe<ZigzagWeekCouponInfo>;
  /** 쿠폰팩 정보 */
  coupon_pack_info?: Maybe<ZigzagWeekCouponPackInfo>;
  /** 셀러 쿠폰 타입 */
  coupon_type: ZigzagWeekCouponType;
  /** 셀러 노출 상품 목록 UUID 리스트(goods_preset 이용) */
  goods_preset_uuid_list: Array<Scalars['String']>;
  /**
   * deprecated
   * 2020년 12월 직잭위크부터는 무조건 true를 리터 합니다.
   * 유저의 쿠폰 다운로드 여부, 비로그인 회원의 경우 무조건 false
   */
  is_download_coupon: Scalars['Boolean'];
  /**
   * 셀러 쇼핑몰 연령 정보
   * 10, 20e, 20m, 20l, 30e, 30m, 30l
   */
  seller_shop_age_list: Array<Scalars['String']>;
  /** 셀러 shop ID */
  seller_shop_id: Scalars['ID'];
  /** 셀러 쇼핑몰 이름 */
  seller_shop_name: Scalars['String'];
  /** 셀러 쇼핑몰 태그 정보 */
  seller_shop_tag_list: Array<Scalars['String']>;
  /** 셀러 영문 ID */
  seller_str_id: Scalars['String'];
  /** 셀러 쇼핑몰 썸네일 이미지 */
  seller_typical_image_url?: Maybe<Scalars['String']>;
  /** 직잭위크 셀러 백그라운드 이미지 주소 */
  zigzag_week_background_image_url?: Maybe<Scalars['String']>;
  /** 직잭위크 카테고리 */
  zigzag_week_category?: Maybe<Scalars['String']>;
};

/** 직잭위크 유저 데이터 결과 */
export type ZigzagWeekUserDataResult = {
  /** 모든 직잭위크 쿠폰을 다운로드 받은 경우 true */
  all_download_coupon: Scalars['Boolean'];
  /** 유저가 다운로드 받은 직잭위크 쿠폰 목록 */
  download_coupon_list: Array<Maybe<ZigzagWeekUserRewardWithSeller>>;
  /** 직잭윜 메타 코드 */
  meta_code: Scalars['String'];
  /** 응답한 서버 시간 */
  server_time: Scalars['CrTimestamp'];
  /** 사용자 계정 ID */
  user_account_id: Scalars['ID'];
};

export type ZigzagWeekUserReward = {
  /** 단일 쿠폰 정보 */
  coupon_info?: Maybe<ZigzagWeekCouponInfo>;
  /** 쿠폰팩 정보 */
  coupon_pack_info?: Maybe<ZigzagWeekCouponPackInfo>;
  /** 셀러 쿠폰 타입 */
  coupon_type: ZigzagWeekCouponType;
};

export type ZigzagWeekUserRewardWithSeller = ZigzagWeekUserReward & {
  /** 단일 쿠폰 정보 */
  coupon_info?: Maybe<ZigzagWeekCouponInfo>;
  /** 쿠폰팩 정보 */
  coupon_pack_info?: Maybe<ZigzagWeekCouponPackInfo>;
  /** 셀러 쿠폰 타입 */
  coupon_type: ZigzagWeekCouponType;
  /** 유저의 쿠폰 다운로드 여부, 쿠폰팩인 경우 쿠폰팩의 모든 쿠폰을 다운로드 받은 경우 true */
  is_download_coupon: Scalars['Boolean'];
  /** 셀러 shop ID */
  seller_shop_id: Scalars['ID'];
  /** 셀러 영문 ID */
  seller_str_id: Scalars['String'];
};

export type ZigzinBestItemInfo = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  ui_item_list: Array<UxComponent>;
};

export type ZigzinBestItemInfoInput = {
  after?: InputMaybe<Scalars['String']>;
  age_filter_id: Scalars['Int'];
  category_id: Scalars['Int'];
  sorting_id: Scalars['Int'];
};

export type ZigzinBookmarkedShop = {
  image_url: Scalars['String'];
  name: Scalars['String'];
  shop_id: Scalars['ID'];
  zigzin_product_count: Scalars['Int'];
};

export type ZigzinCarouselItemInfo = {
  /** 상품정보 */
  item_list: Array<UxGoodsCardItem>;
  /** 직진 캐러셀조회 상세 옵션 */
  option_list?: Maybe<Array<ZigzinCarouselItemOption>>;
  /** 직진 캐러셀조회 제목 */
  title: UxCommonText;
  /** 직진 캐러셀 정보 문구 */
  wrapped_info?: Maybe<UxCommonText>;
};

export type ZigzinCarouselItemInfoInput = {
  /** 직진 캐러셀 ID */
  carousel_id: Scalars['String'];
  /** 직진 캐러셀 상세 옵션 */
  selected_option?: InputMaybe<Scalars['String']>;
};

export type ZigzinCarouselItemOption = {
  option_id: Scalars['String'];
  selected: Scalars['Boolean'];
  title: Scalars['String'];
};

export type ZigzinCategoryItems = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  ui_item_list: Array<UxComponent>;
};

export type ZigzinEstimateShippingInfo = {
  arrival_date_except_title_text?: Maybe<Scalars['String']>;
  arrival_date_text: Scalars['String'];
  arrival_date_title_text?: Maybe<Scalars['String']>;
  arrival_notice_text?: Maybe<Scalars['String']>;
  arrival_one_day_text?: Maybe<Scalars['String']>;
  arrival_one_day_title_text?: Maybe<Scalars['String']>;
  arrival_text_with_user_postcode?: Maybe<Scalars['String']>;
  estimate_content: Scalars['String'];
  estimate_timestamp: Scalars['CrTimestamp'];
  is_default_address?: Maybe<Scalars['Boolean']>;
  product_id?: Maybe<Scalars['ID']>;
  shipping_notify_info: ZigzinShippingNotifyInfo;
};

export type ZigzinEstimateShippingItemInfo = {
  /** 도착 예정 일자 timestamp */
  arrival_date_timestamp: Scalars['CrTimestamp'];
  /** 배송 지연 문구 */
  delivery_delay_text?: Maybe<Scalars['String']>;
  /** 도착 예정 일자 문구 */
  estimated_date_text: Scalars['String'];
  /** 가이드 뱃지 문구 (ex. 더 빠른배송) */
  guide_badge_text?: Maybe<Scalars['String']>;
  /** 제주/도서산간 지역 지연 일 수 */
  islands_mountains_delay_days?: Maybe<Scalars['Int']>;
  /** 제주/도서산간 지역 지연 텍스트 */
  islands_mountains_delay_text?: Maybe<Scalars['String']>;
  /** 도착 예정 일자 문구에 해당되는 주문 마감 시간 문구 */
  order_cutoff_time_text?: Maybe<Scalars['String']>;
  /** estimated_date_text 와 결합하여 노출할 timestamp */
  order_cutoff_timestamp?: Maybe<Scalars['CrTimestamp']>;
  /** 배송비 문구 */
  shipping_fee_text?: Maybe<Scalars['String']>;
  /** 직진 타입 뱃지 문구 (ex. 당일, 새벽, 내일) */
  type_badge_text: Scalars['String'];
  /** WMS 에서 사용하는 직진 배송 타입 (ex. ONE_DAY, TODAY, ZONLY) */
  wms_delivery_type: Scalars['String'];
};

export type ZigzinMainCategory = {
  id: Scalars['ID'];
  image_url: Scalars['String'];
  landing_url: Scalars['String'];
  name: Scalars['String'];
};

export type ZigzinMainCategoryList = {
  item_list: Array<ZigzinMainCategory>;
  total_count: Scalars['Int'];
};

export type ZigzinMainInfo = {
  category_list: ZigzinMainCategoryList;
  entry_banner: UxEntryBanner;
  image_banner: Array<UxComponent>;
  review_list: Array<ZigzinReview>;
  sub_banner?: Maybe<Array<UxImageBanner>>;
};

export type ZigzinNoticeInfo = {
  close_text?: Maybe<Scalars['String']>;
  exposure_frequency?: Maybe<Scalars['Int']>;
  go_text?: Maybe<Scalars['String']>;
  icon_url?: Maybe<Scalars['String']>;
  item_type: CmsExtraNoticeType;
  item_value?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notice_id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type ZigzinOneDayEstimateInfo = {
  delivery_estimate_time_str: Scalars['String'];
  order_time_str: Scalars['String'];
};

export type ZigzinOneDayEstimateInfoTable = {
  info_list: Array<ZigzinOneDayEstimateInfo>;
  note_list?: Maybe<Array<Scalars['String']>>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ZigzinOrderAndEstimateInfo = {
  delivery_estimate_time_str: Scalars['String'];
  order_time_str: Scalars['String'];
};

export type ZigzinQuickMenu = {
  id: Scalars['ID'];
  items?: Maybe<Array<ZigzinQuickMenuItem>>;
};

export type ZigzinQuickMenuItem = {
  id: Scalars['ID'];
  image_url: UxCommonImageUrl;
  is_dot: Scalars['Boolean'];
  landing_url: Scalars['String'];
  title: Scalars['String'];
};

export type ZigzinReview = {
  contents: Scalars['String'];
  product_id: Scalars['Int'];
  product_image_url: Scalars['String'];
  product_title: Scalars['String'];
  review_id: Scalars['ID'];
  sentences: Scalars['String'];
  shop_id: Scalars['Int'];
  shop_name: Scalars['String'];
  shop_typical_image_url: Scalars['String'];
  user_account_id: Scalars['Int'];
};

export type ZigzinSavedShopItems = {
  end_cursor?: Maybe<Scalars['String']>;
  has_next?: Maybe<Scalars['Boolean']>;
  store_home_theme?: Maybe<Scalars['String']>;
  ui_item_list: Array<UxComponent>;
};

export type ZigzinShippingNotifyInfo = {
  note_list?: Maybe<Array<Scalars['String']>>;
  note_title?: Maybe<Scalars['String']>;
  one_day_estimate_info?: Maybe<ZigzinOneDayEstimateInfoTable>;
  order_and_estimate_info: Array<ZigzinOrderAndEstimateInfo>;
  order_and_estimate_info_sub_title?: Maybe<Scalars['String']>;
  order_and_estimate_info_title?: Maybe<Scalars['String']>;
  sub_notify?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ZonlyOneDayEstimateInfo = {
  delivery_estimate_time_str: Scalars['String'];
  order_time_str: Scalars['String'];
};

export type ZonlyOneDayEstimateInfoTable = {
  info_list: Array<ZonlyOneDayEstimateInfo>;
  note_list?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

/** zonly 내 원래 쇼핑몰 정보 */
export type ZonlyOriginShop = {
  /** 쇼핑몰 ID */
  id: Scalars['ID'];
  /** 메인 도메인 */
  main_domain: Scalars['String'];
  /** 쇼핑몰 URL */
  url: Scalars['String'];
};

export enum ZpayStatus {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NOT_ZPAY_SHOP = 'NOT_ZPAY_SHOP',
  SELLABLE = 'SELLABLE',
  SUSPENDED = 'SUSPENDED',
}

/** 유저의 찜폴더 */
export type ZzimFolder = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type FirstCategoryInput = {
  first_category: Scalars['String'];
};

export type GetEventPromotionNotificationAgreementItem = {
  event_id: Scalars['String'];
  is_agree: Scalars['Boolean'];
};

export type GetEventPromotionNotificationAgreementResult = {
  item_list: Array<GetEventPromotionNotificationAgreementItem>;
};

export type ProcessUserAccountAuthenticationResponseVariables = Exact<{
  input: ProcessUserAccountAuthenticationResponseInput;
}>;

export type ProcessUserAccountAuthenticationResponse = {
  processUserAccountAuthenticationResponse: { full_name?: string | null; mobile_tel?: string | null };
};

export type UserShippingAddressBookFragment = {
  id: string;
  country: CountryType;
  address_name: string;
  first_name: string;
  last_name: string;
  country_number: string;
  contract_number: string;
  post_code: string;
  address: string;
  detail_address?: string | null;
  state?: string | null;
  city?: string | null;
  is_default?: boolean | null;
  entrance_password?: string | null;
  entrance_type?: EntranceType | null;
};

export type GetUserShippingAddressBookListVariables = Exact<{
  limit_size?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<CountryType>;
}>;

export type GetUserShippingAddressBookList = {
  user_shipping_address_book_list: {
    default_item?: {
      id: string;
      country: CountryType;
      address_name: string;
      first_name: string;
      last_name: string;
      country_number: string;
      contract_number: string;
      post_code: string;
      address: string;
      detail_address?: string | null;
      state?: string | null;
      city?: string | null;
      is_default?: boolean | null;
      entrance_password?: string | null;
      entrance_type?: EntranceType | null;
    } | null;
    item_list?: Array<{
      id: string;
      country: CountryType;
      address_name: string;
      first_name: string;
      last_name: string;
      country_number: string;
      contract_number: string;
      post_code: string;
      address: string;
      detail_address?: string | null;
      state?: string | null;
      city?: string | null;
      is_default?: boolean | null;
      entrance_password?: string | null;
      entrance_type?: EntranceType | null;
    }> | null;
  };
};

export type GetUserShippingAddressBookVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserShippingAddressBook = {
  user_shipping_address_book?: {
    id: string;
    country: CountryType;
    address_name: string;
    first_name: string;
    last_name: string;
    country_number: string;
    contract_number: string;
    post_code: string;
    address: string;
    detail_address?: string | null;
    state?: string | null;
    city?: string | null;
    is_default?: boolean | null;
    entrance_password?: string | null;
    entrance_type?: EntranceType | null;
  } | null;
};

export type CreateUserShippingAddressBookVariables = Exact<{
  input: CreateUserShippingAddressBookInput;
}>;

export type CreateUserShippingAddressBook = {
  createUserShippingAddressBook: {
    id: string;
    country: CountryType;
    address_name: string;
    first_name: string;
    last_name: string;
    country_number: string;
    contract_number: string;
    post_code: string;
    address: string;
    detail_address?: string | null;
    state?: string | null;
    city?: string | null;
    is_default?: boolean | null;
    entrance_password?: string | null;
    entrance_type?: EntranceType | null;
  };
};

export type UpdateUserShippingAddressBookVariables = Exact<{
  input: UpdateUserShippingAddressBookInput;
}>;

export type UpdateUserShippingAddressBook = {
  updateUserShippingAddressBook: {
    id: string;
    country: CountryType;
    address_name: string;
    first_name: string;
    last_name: string;
    country_number: string;
    contract_number: string;
    post_code: string;
    address: string;
    detail_address?: string | null;
    state?: string | null;
    city?: string | null;
    is_default?: boolean | null;
    entrance_password?: string | null;
    entrance_type?: EntranceType | null;
  };
};

export type DeleteUserShippingAddressBookVariables = Exact<{
  input: DeleteUserShippingAddressBookInput;
}>;

export type DeleteUserShippingAddressBook = { deleteUserShippingAddressBook: boolean };

export type GetAppleLoginConfigVariables = Exact<{ [key: string]: never }>;

export type GetAppleLoginConfig = { getAppleLoginConfig: { bundle_service_id: string; nonce: string } };

export type LoginForWebVariables = Exact<{
  input: LoginInput;
}>;

export type LoginForWeb = {
  login: {
    success: boolean;
    full_name?: string | null;
    uuid?: string | null;
    error_code?: string | null;
    error_message?: string | null;
  };
};

export type SendMobileAuthenticationTokenVariables = Exact<{
  input: SendMobileAuthenticationTokenInput;
}>;

export type SendMobileAuthenticationToken = { sendMobileAuthenticationToken: boolean };

export type VerifyMobileAuthenticationTokenVariables = Exact<{
  input: VerifyMobileAuthenticationTokenInput;
}>;

export type VerifyMobileAuthenticationToken = { verifyMobileAuthenticationToken: { email_list: Array<string> } };

export type LoginKakaoVariables = Exact<{
  input: LoginKakaoInput;
}>;

export type LoginKakao = {
  loginKakao: { uuid: string; email: string; full_name?: string | null; is_created?: boolean | null };
};

export type SignUpEmailVariables = Exact<{
  input: SignupInput;
}>;

export type SignUpEmail = {
  signup: {
    message?: string | null;
    user_account: { email: string; full_name?: string | null; mobile_tel?: string | null };
  };
};

export type RequestUserAccountPasswordResetVariables = Exact<{
  input: RequestUserAccountPasswordResetInput;
}>;

export type RequestUserAccountPasswordReset = { requestUserAccountPasswordReset: boolean };

export type GetUserAccountVariables = Exact<{ [key: string]: never }>;

export type GetUserAccount = { user_account?: { email: string; full_name?: string | null } | null };

export type GetUserProfileVariables = Exact<{ [key: string]: never }>;

export type GetUserProfile = {
  user_account?: {
    email: string;
    full_name?: string | null;
    mobile_tel?: string | null;
    authenticated?: boolean | null;
  } | null;
};

export type GetSimpleProfileFragment = { uuid: string; email: string; full_name?: string | null };

export type ConnectKakaoWithAuthVariables = Exact<{
  input: ConnectKakaoWithAuthInput;
}>;

export type ConnectKakaoWithAuth = { connectKakaoWithAuth: { uuid: string; email: string; full_name?: string | null } };

export type FindUserAccountEmailByMobileTelVariables = Exact<{
  mobile_tel: Scalars['String'];
}>;

export type FindUserAccountEmailByMobileTel = { findUserAccountEmailByMobileTel?: string | null };

export type UpdateUserAccountVariables = Exact<{
  input: UpdateUserAccountInput;
}>;

export type UpdateUserAccount = { updateUserAccount: boolean };

export type GetUserNameVariables = Exact<{ [key: string]: never }>;

export type GetUserName = { user_account?: { full_name?: string | null; authenticated_name?: string | null } | null };

export type GetAttendanceCheckInfoVariables = Exact<{ [key: string]: never }>;

export type GetAttendanceCheckInfo = {
  attendance_event_log_summary: {
    total_attendance_day: number;
    date_last_attended: number;
    total_reward_point: number;
    attendance_ticket_count: number;
    raffle_apply_ticket_count: number;
    today_rewarded_point: number;
  };
  attendance_event_raffle_user_account_list: { date_raffled: number; item_list: Array<{ masked_email: string }> };
};

export type UseAttendanceTicketVariables = Exact<{ [key: string]: never }>;

export type UseAttendanceTicket = { useFbkAttendanceTicket: boolean };

export type FindOrCreateAttendanceEventInviterCodeVariables = Exact<{ [key: string]: never }>;

export type FindOrCreateAttendanceEventInviterCode = {
  findOrCreateFbkAttendanceEventInviterCode: { inviter_code: string };
};

export type ParticipateAttendanceEventVariables = Exact<{ [key: string]: never }>;

export type ParticipateAttendanceEvent = { participateFbkAttendanceEvent: boolean };

export type ParticipateAttendanceEventInviteLinkVariables = Exact<{
  input: ParticipateFbkAttendanceEventInviteLinkInput;
}>;

export type ParticipateAttendanceEventInviteLink = { participateFbkAttendanceEventInviteLink: boolean };

export type GetUsableCouponListVariables = Exact<{
  input: UsableCouponListInput;
}>;

export type GetUsableCouponList = {
  usable_coupon_list: {
    usable_coupon_list: Array<{
      id: string;
      user_account_coupon_policy: { registration_type: CouponRegistrationType; shop_info?: { id: string } | null };
    }>;
    unusable_coupon_list: Array<{ id: string }>;
  };
};

export type GetCouponAvailableStatusListVariables = Exact<{
  input: CouponAvailableStatusListInput;
}>;

export type GetCouponAvailableStatusList = {
  coupon_available_status_list: {
    item_list: Array<{ shop_id: string; origin_shop_id?: string | null; status: CouponAvailableStatus }>;
  };
};

export type GetCartZonlyEstimatedShippingInfoVariables = Exact<{ [key: string]: never }>;

export type GetCartZonlyEstimatedShippingInfo = {
  zonly_estimate_shipping_info?: { estimate_content: string; estimate_timestamp: number } | null;
};

export type GetCartShopListVariables = Exact<{ [key: string]: never }>;

export type GetCartShopList = {
  cart_shop_list: {
    item_list: Array<{
      is_zonly: boolean;
      shop: {
        id: string;
        name: string;
        main_domain: string;
        url: string;
        typical_image_url?: string | null;
        zpay_status?: ShopZpayStatus | null;
        type: ShopType;
      };
      shipping_group_list: Array<{
        shipping_info: { shipping_type: ShippingType };
        cart_item_list: Array<{
          quantity: number;
          product_item_id: string;
          is_valid_product: boolean;
          date_last_added: number;
          cart_item_hash?: string | null;
          additional_option_detail_list?: Array<{
            name: string;
            option_code?: string | null;
            value: string;
            value_code?: string | null;
          }> | null;
          product_item: {
            price: number;
            price_delta: number;
            date_deleted?: number | null;
            status: ProductItemStatus;
            option_list: Array<{ value: string }>;
            product: {
              id: string;
              shop_id: string;
              product_no: string;
              catalog_product_id?: string | null;
              browsing_type: BrowsingType;
              pdp_landing_url?: string | null;
              name: string;
              url?: string | null;
              zpay_status: ZpayStatus;
              minimum_quantity: number;
              minimum_quantity_type: MinimumOrderQuantityType;
              maximum_quantity: number;
              option_type: ProductOptionType;
              is_zpay_discount: boolean;
              price: number;
              due_date_in_transit: number;
              is_fast_delivery: boolean;
              category?: { category_full_name: string } | null;
              image: { url?: string | null };
              option_list: Array<{
                name: string;
                code: string;
                is_required: boolean;
                value_set: Array<{ code: string; name: string; additional_price: number }>;
              }>;
              text_options: Array<{ name: string; is_required: boolean; max_length: number }>;
              preorder?: { date_start: string; date_end: string; reserved_date_in_transit: string } | null;
              shipping_fee?: {
                fee_type: ProductShippingFeeType;
                base_fee: number;
                conditional_amount?: number | null;
              } | null;
            };
          };
          item_final_price?: {
            product_original_price: number;
            item_discount_price: number;
            item_promotion_discount_price?: number | null;
            promotion_price?: number | null;
            final_price: number;
            final_discount_amount: number;
            final_discount_rate_bp: number;
          } | null;
          origin_shop?: { id: string; name: string } | null;
        }>;
      }>;
    }>;
  };
};

export type AddCartItemListVariables = Exact<{
  input: AddCartItemListInput;
}>;

export type AddCartItemList = { addCartItemList: boolean };

export type DeleteCartItemListVariables = Exact<{
  input: DeleteCartItemListInput;
}>;

export type DeleteCartItemList = { deleteCartItemList: boolean };

export type GetSavedProductIdListVariables = Exact<{
  id_list: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetSavedProductIdList = {
  saved_product_id_list?: { catalog_product_id_list?: Array<string> | null } | null;
};

export type GetSimilarProductListVariables = Exact<{
  catalog_product_id: Scalars['String'];
  limit_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetSimilarProductList = {
  fbk_similar_product_list: Array<{
    catalog_product_id: string;
    image_url?: string | null;
    webp_image_url?: string | null;
    shop_name?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    log?: string | null;
    aid?: string | null;
    product_url?: string | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    shop_product_no?: string | null;
    shop_id: string;
    is_zonly: boolean;
    is_brand: boolean;
    is_saved_product: boolean;
    review_count?: number | null;
    review_score?: number | null;
    is_new?: boolean | null;
    is_ad: boolean;
    badge_list: Array<{ type: string }>;
    thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
  }>;
};

export type GetShopBestProductListVariables = Exact<{
  shop_id: Scalars['String'];
  current_catalog_product_id: Scalars['String'];
}>;

export type GetShopBestProductList = {
  fbk_shop_best_product_list: Array<{
    catalog_product_id: string;
    image_url?: string | null;
    webp_image_url?: string | null;
    shop_name?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    log?: string | null;
    aid?: string | null;
    product_url?: string | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    shop_product_no?: string | null;
    shop_id: string;
    is_zonly: boolean;
    is_brand: boolean;
    is_saved_product: boolean;
    review_count?: number | null;
    review_score?: number | null;
    is_new?: boolean | null;
    is_ad: boolean;
    badge_list: Array<{ type: string }>;
    thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
  }>;
};

export type GetPaymentBenefitVariables = Exact<{ [key: string]: never }>;

export type GetPaymentBenefit = {
  event: {
    item_list: Array<{
      id: string;
      payment_method: PaymentMethod;
      financial_company_code?: FinancialCompanyCode | null;
      event_type: VendorEventType;
      min_amount: number;
      discount_amount: number;
      payment_method_type?: string | null;
    }>;
  };
  notification: {
    item_list: Array<{
      id: string;
      payment_method: PaymentMethod;
      notification_type: PaymentNotificationType;
      financial_company_code?: FinancialCompanyCode | null;
      min_amount?: number | null;
      discount_amount?: number | null;
      payment_notification_content_list: Array<{ content: string; language: string }>;
    }>;
  };
  vendor_interest_free: {
    item_list: Array<{
      id: string;
      financial_company_code: FinancialCompanyCode;
      installment_month: number;
      min_amount: number;
      vendor_type: VendorType;
    }>;
  };
};

export type GetRelatedProductListVariables = Exact<{
  product_id: Scalars['ID'];
  inventory_name?: InputMaybe<Scalars['String']>;
}>;

export type GetRelatedProductList = {
  related_product_list: Array<{
    catalog_product_id: string;
    image_url?: string | null;
    webp_image_url?: string | null;
    shop_name?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    log?: string | null;
    aid?: string | null;
    product_url?: string | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    shop_product_no?: string | null;
    shop_id: string;
    is_zonly: boolean;
    is_brand: boolean;
    is_saved_product: boolean;
    review_count?: number | null;
    review_score?: number | null;
    is_new?: boolean | null;
    is_ad: boolean;
    badge_list: Array<{ type: string }>;
    thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
  }>;
};

export type PdpShopInfo = {
  id: string;
  main_domain: string;
  name: string;
  url: string;
  keywords: string;
  typical_image_url?: string | null;
  bookmark_count?: number | null;
  is_saved_shop: boolean;
  customer_center_tel?: string | null;
  shipping_company1?: PdpShopShippingCompany | null;
  shipping_company2?: PdpShopShippingCompany | null;
  type: PdpShopType;
  zpay_status?: PdpShopZpayStatus | null;
  return_shipping_company?: PdpShopShippingCompany | null;
  parent_id?: string | null;
  trait: {
    category?: number | null;
    category_list?: Array<string> | null;
    style?: string | null;
    minor_style?: string | null;
    minor_category?: number | null;
    minor_category_list?: Array<string> | null;
    age10?: boolean | null;
    age20early?: boolean | null;
    age20mid?: boolean | null;
    age20late?: boolean | null;
    age30early?: boolean | null;
    age30mid?: boolean | null;
    age30late?: boolean | null;
  };
  customer_center?: {
    closed_week_day_list?: Array<PdpWeekDayType> | null;
    is_closed_on_holiday?: boolean | null;
    manager_name?: string | null;
    manager_tel?: string | null;
    business_time?: { from_hm?: string | null; to_hm?: string | null } | null;
    lunch_time?: { from_hm?: string | null; to_hm?: string | null } | null;
  } | null;
  main_contact?: {
    name?: string | null;
    email?: string | null;
    landline_number?: string | null;
    mobile_number?: string | null;
  } | null;
  business_license?: {
    registration_number?: string | null;
    company_name?: string | null;
    representative_name?: string | null;
    business_category?: string | null;
    business_subcategory?: string | null;
    company_address?: string | null;
  } | null;
  shop_business_info?: {
    seller_info: {
      terms_of_use_policy_url: string;
      privacy_policy_url: string;
      kakao_cs_url?: string | null;
      naver_cs_url?: string | null;
    };
  } | null;
  online_sales_license?: { license_number?: string | null } | null;
  shipping_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
  return_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
  shop_category_list: Array<{ id: string; name: string; store_home_deep_link?: string | null }>;
};

export type GetFbkProductInfoVariables = Exact<{
  catalog_product_id: Scalars['ID'];
}>;

export type GetFbkProductInfo = {
  shop_attribute_info?: { name: string; attribute_list: Array<{ key: string; value: string }> } | null;
  product_delivery_summary: {
    has_delivery_delayed_log: boolean;
    product_shipping_type?: string | null;
    delivery_estimate_summary?: {
      product_id: string;
      response_type: string;
      repr_estimate?: { day: number; probability: number } | null;
      estimate_list: Array<{ day: number; probability: number }>;
    } | null;
  };
  fbk_catalog_product_base_info?: {
    policy_list: Array<{ main_title: string; content_list: Array<{ title: string; description: string }> }>;
    shop: {
      id: string;
      main_domain: string;
      name: string;
      url: string;
      keywords: string;
      typical_image_url?: string | null;
      bookmark_count?: number | null;
      is_saved_shop: boolean;
      customer_center_tel?: string | null;
      shipping_company1?: PdpShopShippingCompany | null;
      shipping_company2?: PdpShopShippingCompany | null;
      type: PdpShopType;
      zpay_status?: PdpShopZpayStatus | null;
      return_shipping_company?: PdpShopShippingCompany | null;
      parent_id?: string | null;
      trait: {
        category?: number | null;
        category_list?: Array<string> | null;
        style?: string | null;
        minor_style?: string | null;
        minor_category?: number | null;
        minor_category_list?: Array<string> | null;
        age10?: boolean | null;
        age20early?: boolean | null;
        age20mid?: boolean | null;
        age20late?: boolean | null;
        age30early?: boolean | null;
        age30mid?: boolean | null;
        age30late?: boolean | null;
      };
      customer_center?: {
        closed_week_day_list?: Array<PdpWeekDayType> | null;
        is_closed_on_holiday?: boolean | null;
        manager_name?: string | null;
        manager_tel?: string | null;
        business_time?: { from_hm?: string | null; to_hm?: string | null } | null;
        lunch_time?: { from_hm?: string | null; to_hm?: string | null } | null;
      } | null;
      main_contact?: {
        name?: string | null;
        email?: string | null;
        landline_number?: string | null;
        mobile_number?: string | null;
      } | null;
      business_license?: {
        registration_number?: string | null;
        company_name?: string | null;
        representative_name?: string | null;
        business_category?: string | null;
        business_subcategory?: string | null;
        company_address?: string | null;
      } | null;
      shop_business_info?: {
        seller_info: {
          terms_of_use_policy_url: string;
          privacy_policy_url: string;
          kakao_cs_url?: string | null;
          naver_cs_url?: string | null;
        };
      } | null;
      online_sales_license?: { license_number?: string | null } | null;
      shipping_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
      return_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
      shop_category_list: Array<{ id: string; name: string; store_home_deep_link?: string | null }>;
    };
    brand_home?: {
      id: string;
      main_domain: string;
      name: string;
      url: string;
      keywords: string;
      typical_image_url?: string | null;
      bookmark_count?: number | null;
      is_saved_shop: boolean;
      customer_center_tel?: string | null;
      shipping_company1?: PdpShopShippingCompany | null;
      shipping_company2?: PdpShopShippingCompany | null;
      type: PdpShopType;
      zpay_status?: PdpShopZpayStatus | null;
      return_shipping_company?: PdpShopShippingCompany | null;
      parent_id?: string | null;
      trait: {
        category?: number | null;
        category_list?: Array<string> | null;
        style?: string | null;
        minor_style?: string | null;
        minor_category?: number | null;
        minor_category_list?: Array<string> | null;
        age10?: boolean | null;
        age20early?: boolean | null;
        age20mid?: boolean | null;
        age20late?: boolean | null;
        age30early?: boolean | null;
        age30mid?: boolean | null;
        age30late?: boolean | null;
      };
      customer_center?: {
        closed_week_day_list?: Array<PdpWeekDayType> | null;
        is_closed_on_holiday?: boolean | null;
        manager_name?: string | null;
        manager_tel?: string | null;
        business_time?: { from_hm?: string | null; to_hm?: string | null } | null;
        lunch_time?: { from_hm?: string | null; to_hm?: string | null } | null;
      } | null;
      main_contact?: {
        name?: string | null;
        email?: string | null;
        landline_number?: string | null;
        mobile_number?: string | null;
      } | null;
      business_license?: {
        registration_number?: string | null;
        company_name?: string | null;
        representative_name?: string | null;
        business_category?: string | null;
        business_subcategory?: string | null;
        company_address?: string | null;
      } | null;
      shop_business_info?: {
        seller_info: {
          terms_of_use_policy_url: string;
          privacy_policy_url: string;
          kakao_cs_url?: string | null;
          naver_cs_url?: string | null;
        };
      } | null;
      online_sales_license?: { license_number?: string | null } | null;
      shipping_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
      return_address?: { postcode?: string | null; address1?: string | null; address2?: string | null } | null;
      shop_category_list: Array<{ id: string; name: string; store_home_deep_link?: string | null }>;
    } | null;
    seller_notice?: {
      id: string;
      title?: string | null;
      content?: string | null;
      notice_type: string;
      image_url?: string | null;
    } | null;
    product: {
      id: string;
      name: string;
      is_saved_product: boolean;
      category_key?: string | null;
      product_url: string;
      description?: string | null;
      product_type: PdpCatalogProductType;
      payment_type: PdpCatalogPaymentType;
      shop_id: number;
      shop_main_domain: string;
      preparation_period?: number | null;
      sales_status: PdpCatalogSalesStatus;
      display_status: PdpCatalogDisplayStatus;
      promotion_message: string;
      coupon_available_status: PdpCouponAvailableStatus;
      minimum_order_quantity: number;
      minimum_order_quantity_type?: string | null;
      maximum_order_quantity?: number | null;
      category_list?: Array<{ category_id: number; value: string }> | null;
      essentials: Array<{ name: string; value: string }>;
      shipping_fee?: {
        fee_type: PdpShippingFeeType;
        base_fee: number;
        minimum_free_shipping_fee?: number | null;
        exchange_fee: number;
        area_fee: { jeju_fee: number; isolated_fee: number };
        return_fee: { total_fee: number; partial_fee?: number | null };
      } | null;
      product_image_list: Array<{
        url?: string | null;
        origin_url: string;
        pdp_thumbnail_url?: string | null;
        pdp_static_image_url?: string | null;
        image_type: PdpCatalogProductImageType;
      }>;
      estimated_shipping_date?: {
        estimate_list?: Array<{ day: number; probability: number }> | null;
        repr_estimate?: { day: number; probability: number } | null;
      } | null;
      product_price: {
        discount_price: number;
        discount_amount: number;
        discount_rate: number;
        original_price: number;
        final_price: number;
        zpay_discount_amount?: number | null;
        max_price: number;
        coupon_discount_amount?: number | null;
      };
      discount_info?: { image_url?: string | null; title?: string | null; color?: string | null } | null;
      shipping_send_date?: {
        exist_shop_holidays: boolean;
        send_date: number;
        send_date_text: string;
        fast_shipping?: { is_fast_type: boolean } | null;
      } | null;
    };
  } | null;
  product_review_pdp_summary?: {
    count: number;
    ratings_average: number;
    attribute?: {
      question: { label: string; value: string; category: string };
      most_answer?: { label: string; value: string; count: number; ratio: number; percent?: number | null } | null;
      answer_list: Array<{ label: string; value: string; count: number; ratio: number; percent?: number | null }>;
    } | null;
    item_list: Array<{
      id: string;
      contents: string;
      attachment_list: Array<{
        product_review_id: string;
        product_id: string;
        original_url: string;
        thumbnail_url: string;
        status: ProductReviewAttachmentStatus;
      }>;
    }>;
  } | null;
};

export type GetProductMaxBenefitVariables = Exact<{
  product_id: Scalars['ID'];
}>;

export type GetProductMaxBenefit = {
  product_max_benefit?: {
    max_coupon_discount?: {
      coupon_policy_id: string;
      title: string;
      discount_amount: number;
      discount_type: string;
      issue_status: string;
      registration_type: string;
    } | null;
    user_account_point?: { available_point: number } | null;
  } | null;
};

export type GetProductEstimateShippingInfoVariables = Exact<{
  catalog_product_id: Scalars['ID'];
}>;

export type GetProductEstimateShippingInfo = {
  catalog_product_estimate_shipping_info?: {
    product: {
      zonly_estimate_shipping_info?: {
        estimate_content: string;
        estimate_timestamp: number;
        arrival_date_text: string;
        arrival_one_day_text?: string | null;
        arrival_date_title_text?: string | null;
        arrival_date_except_title_text?: string | null;
        arrival_one_day_title_text?: string | null;
        arrival_text_with_user_postcode?: string | null;
        arrival_notice_text?: string | null;
        shipping_notify_info: {
          title: string;
          sub_notify?: string | null;
          note_title?: string | null;
          note_list?: Array<string> | null;
          order_and_estimate_info_title?: string | null;
          order_and_estimate_info: Array<{ order_time_str: string; delivery_estimate_time_str: string }>;
          one_day_estimate_info?: {
            title?: string | null;
            note_list?: Array<string> | null;
            info_list: Array<{ order_time_str: string; delivery_estimate_time_str: string }>;
          } | null;
        };
      } | null;
    };
  } | null;
};

export type GetProductOptionInfoVariables = Exact<{
  catalog_product_id: Scalars['ID'];
}>;

export type GetProductOptionInfo = {
  product_option_info?: {
    product: {
      id: string;
      shop_main_domain: string;
      product_price: {
        discount_price: number;
        discount_amount: number;
        discount_rate: number;
        original_price: number;
        final_price: number;
        zpay_discount_amount?: number | null;
        max_price: number;
        coupon_discount_amount?: number | null;
      };
      product_image_list: Array<{
        url?: string | null;
        origin_url: string;
        pdp_thumbnail_url?: string | null;
        pdp_static_image_url?: string | null;
        image_type: PdpCatalogProductImageType;
      }>;
      product_option_list: Array<{
        id: string;
        order: number;
        name: string;
        code?: string | null;
        required: boolean;
        option_type?: string | null;
        value_list: Array<{
          id: string;
          code?: string | null;
          value: string;
          static_url?: string | null;
          jpeg_url?: string | null;
        }>;
      }>;
      item_list: Array<{
        id: string;
        name: string;
        price: number;
        price_delta: number;
        item_code?: string | null;
        sales_status: PdpCatalogSalesStatus;
        display_status: PdpCatalogItemDisplayStatus;
        option_type?: string | null;
        is_zigzin?: boolean | null;
        delivery_type?: string | null;
        expected_delivery_date?: string | null;
        item_attribute_list: Array<{ name: string; value: string }>;
        wms_notification_info?: { active: boolean } | null;
      }>;
      custom_input_option_list: Array<{ name: string; max_length?: number | null; is_required: boolean }>;
      color_image_list: Array<{
        is_main: boolean;
        image_url?: string | null;
        image_width?: number | null;
        image_height?: number | null;
        webp_image_url?: string | null;
        color_list: Array<string>;
      }>;
    };
  } | null;
};

export type GetProductMetaInfoVariables = Exact<{
  catalog_product_id: Scalars['ID'];
}>;

export type GetProductMetaInfo = {
  product_meta_info?: {
    product: {
      meta_catalog_product_info?: {
        id: string;
        name: string;
        is_link_visible: boolean;
        is_able_to_buy: boolean;
        pdp_url: string;
        option_list: Array<{
          order: number;
          name: string;
          code?: string | null;
          value_list: Array<{ id: string; code?: string | null; value: string; order?: number | null }>;
        }>;
        option_count_diff_list: Array<{ name: string; count_diff: number }>;
      } | null;
    };
  } | null;
};

export type GetCategoryAdProductListVariables = Exact<{
  product_id: Scalars['ID'];
}>;

export type GetCategoryAdProductList = {
  category_ad_product_list: {
    ad_category_name?: string | null;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type PointReward = { amount: number; unit: UserAccountPointRewardUnit };

export type EstimatedIssuePoint = { text_only: number; with_photo: number };

export type GetActivatedBenefitVariables = Exact<{
  shop_id: Scalars['ID'];
  item_price?: InputMaybe<Scalars['Int']>;
}>;

export type GetActivatedBenefit = {
  review: {
    maximum_point: number;
    maximum_mileage: number;
    estimated_point: {
      normal: { text_only: number; with_photo: number };
      first_review_bonus: { text_only: number; with_photo: number };
    };
    review_event_point_list: Array<{ type: ProductReviewSellerEventType; max_point: number; max_mileage: number }>;
  };
  activated_point_reward?: { amount: number; unit: UserAccountPointRewardUnit } | null;
};

export type GetProductImageListVariables = Exact<{
  input: CatalogProductListInput;
}>;

export type GetProductImageList = {
  catalog_product_list: {
    item_list: Array<{
      id: string;
      product_type: CatalogProductType;
      product_image_list: Array<{ image_url: string; image_type: CatalogProductImageType }>;
    }>;
  };
};

export type RegisterWmsUserNotificationVariables = Exact<{
  input: WmsUserNotificationInput;
}>;

export type RegisterWmsUserNotification = { registerWmsUserNotification?: { active: boolean } | null };

export type ProductReviewInfo = {
  id: string;
  site_id: string;
  country: string;
  country_name: string;
  language: string;
  shop_id: string;
  product_id: string;
  catalog_product_id: string;
  order_item_number: string;
  type: ProductReviewType;
  product_type: ProductReviewProductType;
  status: ProductReviewStatus;
  contents: string;
  translated_contents?: string | null;
  translation_enabled?: boolean | null;
  date_created: number;
  date_updated?: number | null;
  rating: number;
  user_account_liked: boolean;
  user_account_disliked: boolean;
  like_count: number;
  dislike_count: number;
  display_limited: boolean;
  updatable: boolean;
  attachment_list: Array<{
    product_review_id: string;
    product_id: string;
    type: ProductReviewType;
    original_url: string;
    thumbnail_url: string;
    status: ProductReviewAttachmentStatus;
  }>;
  attribute_list: Array<{
    question: { label: string; value: string; category: string };
    answer: { label: string; value: string };
  }>;
  delete_info?: { reason: string; reason_detail?: string | null; status: ProductReviewStatus; message: string } | null;
  reviewer: {
    masked_name: string;
    masked_email: string;
    body_text?: string | null;
    body?: {
      height: { value: number; label: string };
      weight: { value: number; label: string };
      upper_size: { value: number; label: string };
      bottom_size: { value: number; label: string };
      shoe_size: { value: number; label: string };
    } | null;
  };
  order_item: {
    pdp_landing_url: string;
    browsing_type: BrowsingType;
    product_info: {
      image_url: string;
      url: string;
      name: string;
      options: string;
      option_detail_list?: Array<{ name: string; value: string }> | null;
    };
  };
};

export type GetProductReviewVariables = Exact<{
  id: Scalars['ID'];
  attachment_status_list?: InputMaybe<Array<ProductReviewAttachmentStatus> | ProductReviewAttachmentStatus>;
}>;

export type GetProductReview = {
  product_review?: {
    id: string;
    site_id: string;
    country: string;
    country_name: string;
    language: string;
    shop_id: string;
    product_id: string;
    catalog_product_id: string;
    order_item_number: string;
    type: ProductReviewType;
    product_type: ProductReviewProductType;
    status: ProductReviewStatus;
    contents: string;
    translated_contents?: string | null;
    translation_enabled?: boolean | null;
    date_created: number;
    date_updated?: number | null;
    rating: number;
    user_account_liked: boolean;
    user_account_disliked: boolean;
    like_count: number;
    dislike_count: number;
    display_limited: boolean;
    updatable: boolean;
    user_account_info: { status: ProductReviewUserAccountInfoStatus };
    attachment_list: Array<{
      product_review_id: string;
      product_id: string;
      type: ProductReviewType;
      original_url: string;
      thumbnail_url: string;
      status: ProductReviewAttachmentStatus;
    }>;
    attribute_list: Array<{
      question: { label: string; value: string; category: string };
      answer: { label: string; value: string };
    }>;
    delete_info?: {
      reason: string;
      reason_detail?: string | null;
      status: ProductReviewStatus;
      message: string;
    } | null;
    reviewer: {
      masked_name: string;
      masked_email: string;
      body_text?: string | null;
      body?: {
        height: { value: number; label: string };
        weight: { value: number; label: string };
        upper_size: { value: number; label: string };
        bottom_size: { value: number; label: string };
        shoe_size: { value: number; label: string };
      } | null;
    };
    order_item: {
      pdp_landing_url: string;
      browsing_type: BrowsingType;
      product_info: {
        image_url: string;
        url: string;
        name: string;
        options: string;
        option_detail_list?: Array<{ name: string; value: string }> | null;
      };
    };
  } | null;
};

export type GetProductReviewListVariables = Exact<{
  product_id?: InputMaybe<Scalars['ID']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
  only_mine?: InputMaybe<Scalars['Boolean']>;
  has_attachment?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<ProductReviewListOrderType>;
  option_detail_list?: InputMaybe<Array<ProductReviewOptionDetail> | ProductReviewOptionDetail>;
  custom_filter_query_input?: InputMaybe<ReviewCustomFilterQueryInput>;
  custom_filter_exists?: InputMaybe<Scalars['Boolean']>;
  attachment_status_list?: InputMaybe<Array<ProductReviewAttachmentStatus> | ProductReviewAttachmentStatus>;
}>;

export type GetProductReviewList = {
  product_review_list: {
    total_count: number;
    attachment_total_count: number;
    item_list: Array<{
      id: string;
      site_id: string;
      country: string;
      country_name: string;
      language: string;
      shop_id: string;
      product_id: string;
      catalog_product_id: string;
      order_item_number: string;
      type: ProductReviewType;
      product_type: ProductReviewProductType;
      status: ProductReviewStatus;
      contents: string;
      translated_contents?: string | null;
      translation_enabled?: boolean | null;
      date_created: number;
      date_updated?: number | null;
      rating: number;
      user_account_liked: boolean;
      user_account_disliked: boolean;
      like_count: number;
      dislike_count: number;
      display_limited: boolean;
      updatable: boolean;
      attachment_list: Array<{
        product_review_id: string;
        product_id: string;
        type: ProductReviewType;
        original_url: string;
        thumbnail_url: string;
        status: ProductReviewAttachmentStatus;
      }>;
      attribute_list: Array<{
        question: { label: string; value: string; category: string };
        answer: { label: string; value: string };
      }>;
      delete_info?: {
        reason: string;
        reason_detail?: string | null;
        status: ProductReviewStatus;
        message: string;
      } | null;
      reviewer: {
        masked_name: string;
        masked_email: string;
        body_text?: string | null;
        body?: {
          height: { value: number; label: string };
          weight: { value: number; label: string };
          upper_size: { value: number; label: string };
          bottom_size: { value: number; label: string };
          shoe_size: { value: number; label: string };
        } | null;
      };
      order_item: {
        pdp_landing_url: string;
        browsing_type: BrowsingType;
        product_info: {
          image_url: string;
          url: string;
          name: string;
          options: string;
          option_detail_list?: Array<{ name: string; value: string }> | null;
        };
      };
    }>;
  };
};

export type GetUserCustomFilterInfoVariables = Exact<{
  custom_filter_query_input?: InputMaybe<ReviewCustomFilterQueryInput>;
}>;

export type GetUserCustomFilterInfo = {
  user_custom_filter_info?: {
    my_custom_filter_exists: boolean;
    my_custom_filter_checked: boolean;
    component_list: Array<
      | {
          type: string;
          position: number;
          key: string;
          display_title: string;
          selected_option_id_list: Array<string>;
          option_list: Array<{ type: string; position: number; id: string; label: string }>;
        }
      | { type: string; position: number; id: string; label: string }
      | {
          type: string;
          position: number;
          key: string;
          display_title: string;
          selected_option_id: string;
          option_list: Array<{ type: string; position: number; id: string; label: string }>;
        }
      | {
          type: string;
          position: number;
          key: string;
          display_title: string;
          min: number;
          max: number;
          gap: number;
          measure_unit: string;
          selected_range: Array<number>;
        }
    >;
  } | null;
};

export type GetProductReviewSummaryVariables = Exact<{
  product_id: Scalars['ID'];
}>;

export type GetProductReviewSummary = {
  product_review_summary: {
    ratings_average: number;
    rating_list: Array<{ rating: number; count: number; ratio: number }>;
    attribute_list: Array<{
      question: { label: string; value: string; category: string };
      most_answer?: { label: string; value: string; count: number; ratio: number; percent?: number | null } | null;
      answer_list: Array<{ label: string; value: string; count: number; ratio: number; percent?: number | null }>;
    }>;
  };
};

export type GetReviewAttachmentListVariables = Exact<{
  product_id?: InputMaybe<Scalars['ID']>;
  catalog_product_id?: InputMaybe<Scalars['ID']>;
  skip_count?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetReviewAttachmentList = {
  product_review_attachment_list: {
    total_count: number;
    item_list: Array<{
      product_review_id: string;
      product_id: string;
      type: ProductReviewType;
      original_url: string;
      thumbnail_url: string;
      status: ProductReviewAttachmentStatus;
    }>;
  };
};

export type ReportProductReviewAbuseVariables = Exact<{
  input: ReportProductReviewAbuseInput;
}>;

export type ReportProductReviewAbuse = { reportProductReviewAbuse: boolean };

export type ProductReviewLikeVariables = Exact<{
  input: ToggleProductReviewLikeInput;
}>;

export type ProductReviewLike = { toggleProductReviewLike: { liked_result: boolean } };

export type ProductReviewDislikeVariables = Exact<{
  input: ToggleProductReviewDislikeInput;
}>;

export type ProductReviewDislike = { toggleProductReviewDislike: { disliked_result: boolean } };

export type GetZOnlyOneDayShippingInfoVariables = Exact<{
  product_Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type GetZOnlyOneDayShippingInfo = {
  zonly_one_day_estimate_shipping_info?: Array<{
    arrival_one_day_text?: string | null;
    shipping_notify_info: {
      title: string;
      sub_notify?: string | null;
      note_title?: string | null;
      note_list?: Array<string> | null;
      order_and_estimate_info_title?: string | null;
      order_and_estimate_info: Array<{ order_time_str: string; delivery_estimate_time_str: string }>;
      one_day_estimate_info?: {
        title?: string | null;
        note_list?: Array<string> | null;
        info_list: Array<{ order_time_str: string; delivery_estimate_time_str: string }>;
      } | null;
    };
  }> | null;
};

export type GetCategoryProductListVariables = Exact<{
  category_id?: InputMaybe<Scalars['Int']>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  goods_filter_option?: InputMaybe<GoodsFilterOptionInput>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetCategoryProductList = {
  fbk_srp_search_product_list?: {
    has_next?: boolean | null;
    end_cursor?: string | null;
    top_item_list: Array<
      | {
          image_url?: string | null;
          webp_image_url?: string | null;
          shop_name?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          zpay?: boolean | null;
          log?: string | null;
          aid?: string | null;
          product_url?: string | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          shop_product_no?: string | null;
          shop_id: string;
          is_zonly: boolean;
          is_brand: boolean;
          is_saved_product: boolean;
          review_count?: number | null;
          review_score?: number | null;
          is_new?: boolean | null;
          badge_list?: Array<{ type?: string | null }> | null;
          thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
        }
      | {}
    >;
    ui_item_list: Array<
      | {
          is_ad: boolean;
          image_url?: string | null;
          webp_image_url?: string | null;
          shop_name?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          zpay?: boolean | null;
          log?: string | null;
          aid?: string | null;
          product_url?: string | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          shop_product_no?: string | null;
          shop_id: string;
          is_zonly: boolean;
          is_brand: boolean;
          is_saved_product: boolean;
          review_count?: number | null;
          review_score?: number | null;
          is_new?: boolean | null;
          badge_list?: Array<{ type?: string | null }> | null;
          thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
        }
      | {}
    >;
    goods_filter_option_list?: { item_list: Array<{ type: UxGoodsFilterOptionType; total_count: number } | {}> } | null;
  } | null;
};

export type GetActiveFeatureMenuVariables = Exact<{
  page_name: Scalars['String'];
}>;

export type GetActiveFeatureMenu = {
  fbk_activated_feature_menu_group?: {
    feature_menu_list: Array<{
      name: string;
      icon_url: string;
      landing_url: string;
      loop_count: number;
      icon_type: string;
    }>;
  } | null;
};

export type GetPopularDisplayCategoryListVariables = Exact<{ [key: string]: never }>;

export type GetPopularDisplayCategoryList = {
  fbk_popular_display_category_list: {
    date_updated: number;
    item_list: Array<{ ranking: number; category_1_depth_id: string; category_2_depth_id: string; name: string }>;
  };
};

export type GetShopListByIdListVariables = Exact<{
  id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetShopListByIdList = {
  shop_list: {
    item_list: Array<{
      shop_id: string;
      name: string;
      typical_image_url?: string | null;
      is_saved_shop: boolean;
      attribute_list: Array<{ key: string; value: string }>;
    }>;
  };
};

export type GetSavedShopIdListVariables = Exact<{
  id_list: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetSavedShopIdList = {
  saved_shop_id_list?: {
    uuid: string;
    user_account_id?: string | null;
    user_metadata_id: string;
    shop_id_list?: Array<string> | null;
  } | null;
};

export type CouponDiscountInfo = {
  id: string;
  status?: UserAccountCouponStatus | null;
  date_issued?: number | null;
  date_expire?: number | null;
  date_used?: number | null;
  calculated_discount_amount: number;
  user_account_coupon_policy: {
    id: string;
    coupon_target_type: CouponTargetType;
    delivery_type?: CouponDeliveryType | null;
    registration_type: CouponRegistrationType;
    title: string;
    min_required_amount?: number | null;
    max_discount_amount?: number | null;
    discount_amount?: number | null;
    discount_rate_bp?: number | null;
    description?: string | null;
    badge_type: CouponBadgeType;
    platform: PolicyPlatform;
    shop_info?: { id: string; name: string; url: string; typical_image_url?: string | null } | null;
  };
};

export type GetAppliableCouponListVariables = Exact<{
  input: UsableCouponListInput;
}>;

export type GetAppliableCouponList = {
  usable_coupon_list: {
    product_list: Array<{
      shop_id: string;
      product_id: string;
      product_item_id: string;
      usable_coupon_list: Array<{ id: string; calculated_discount_amount: number }>;
    }>;
    usable_coupon_list: Array<{
      id: string;
      status?: UserAccountCouponStatus | null;
      date_issued?: number | null;
      date_expire?: number | null;
      date_used?: number | null;
      calculated_discount_amount: number;
      user_account_coupon_policy: {
        id: string;
        coupon_target_type: CouponTargetType;
        delivery_type?: CouponDeliveryType | null;
        registration_type: CouponRegistrationType;
        title: string;
        min_required_amount?: number | null;
        max_discount_amount?: number | null;
        discount_amount?: number | null;
        discount_rate_bp?: number | null;
        description?: string | null;
        badge_type: CouponBadgeType;
        platform: PolicyPlatform;
        shop_info?: { id: string; name: string; url: string; typical_image_url?: string | null } | null;
      };
    }>;
    unusable_coupon_list: Array<{
      id: string;
      status?: UserAccountCouponStatus | null;
      date_issued?: number | null;
      date_expire?: number | null;
      date_used?: number | null;
      calculated_discount_amount: number;
      user_account_coupon_policy: {
        id: string;
        coupon_target_type: CouponTargetType;
        delivery_type?: CouponDeliveryType | null;
        registration_type: CouponRegistrationType;
        title: string;
        min_required_amount?: number | null;
        max_discount_amount?: number | null;
        discount_amount?: number | null;
        discount_rate_bp?: number | null;
        description?: string | null;
        badge_type: CouponBadgeType;
        platform: PolicyPlatform;
        shop_info?: { id: string; name: string; url: string; typical_image_url?: string | null } | null;
      };
    }>;
  };
};

export type GetCouponPolicyTargetListVariables = Exact<{
  coupon_policy_id_list: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetCouponPolicyTargetList = {
  coupon_policy_target_list: {
    item_list: Array<{
      user_account_coupon_policy_id: string;
      target_list: Array<{ target_type: string; target_uid: string }>;
    }>;
  };
};

export type GetCouponExcludeStatusListVariables = Exact<{
  target_list: Array<CouponExcludeTargetInput> | CouponExcludeTargetInput;
}>;

export type GetCouponExcludeStatusList = {
  coupon_exclude_status_list: Array<{ shop_id: string; product_id: string; disabled: boolean }>;
};

export type GetShopCouponPolicyListVariables = Exact<{
  shop_id: Scalars['Int'];
  product_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  shop_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  coupon_registration_type_list?: InputMaybe<Array<CouponRegistrationType> | CouponRegistrationType>;
  coupon_code_type_list?: InputMaybe<Array<CouponCodeType> | CouponCodeType>;
  include_zonly?: InputMaybe<Scalars['Boolean']>;
  coupon_target_type_list?: InputMaybe<Array<CouponTargetType> | CouponTargetType>;
  issue_status?: InputMaybe<UserAccountCouponPolicyIssueStatus>;
}>;

export type GetShopCouponPolicyList = {
  shop_detail: { id: string; name: string; main_domain: string };
  coupon_policy_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      coupon_target_type: CouponTargetType;
      title: string;
      description?: string | null;
      date_issue_end_with_format: string;
      registration_type: CouponRegistrationType;
      download_status: UserAccountCouponStatus;
      date_created: number;
      date_issue_end: number;
      min_required_amount?: number | null;
      max_discount_amount?: number | null;
      discount_amount?: number | null;
      discount_rate_bp?: number | null;
      is_zonly_store_coupon: boolean;
      platform: PolicyPlatform;
      target_list?: Array<{ target_uid: string }> | null;
    }>;
  };
};

export type GetUserCouponListVariables = Exact<{
  status_list?: InputMaybe<Array<UserAccountCouponStatus> | UserAccountCouponStatus>;
  order_type_list?: InputMaybe<Array<UserAccountCouponListOrderType> | UserAccountCouponListOrderType>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetUserCouponList = {
  user_account_coupon_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      date_issued: number;
      date_expire: number;
      date_used?: number | null;
      user_account_coupon_policy: {
        delivery_type?: CouponDeliveryType | null;
        title: string;
        description?: string | null;
        coupon_target_type: CouponTargetType;
        badge_type: CouponBadgeType;
        min_required_amount?: number | null;
        max_discount_amount?: number | null;
        discount_amount?: number | null;
        discount_rate_bp?: number | null;
        tag_list: Array<string>;
        chip_list: Array<PolicyChipType>;
        repeatable_use_count: number;
        platform: PolicyPlatform;
        order_limit_count: number;
        usable_coupon_count: number;
        policy_id: string;
        shop_info?: {
          id: string;
          name: string;
          main_domain: string;
          url: string;
          typical_image_url?: string | null;
          type: CouponShopType;
          is_brand: boolean;
          is_zonly: boolean;
          style?: string | null;
        } | null;
        item_info?: { product_id: string; count: number } | null;
      };
    }>;
  };
};

export type IssueUserAccountShopCouponListVariables = Exact<{
  input: IssueUserAccountShopCouponListInput;
}>;

export type IssueUserAccountShopCouponList = {
  issueUserAccountShopCouponList: {
    shop_id_list: Array<string>;
    coupon_list: Array<{ user_account_coupon_policy: { id: string } }>;
  };
};

export type IssueCouponByCodeVariables = Exact<{
  input: IssueCouponByCodeInput;
}>;

export type IssueCouponByCode = { issueCouponByCode: boolean };

export type ConfirmCouponBoxVariables = Exact<{ [key: string]: never }>;

export type ConfirmCouponBox = { confirmCouponBox: boolean };

export type GetFbkEventDetailPageVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetFbkEventDetailPage = {
  fbk_event_detail_page?: {
    id: string;
    uuid: string;
    title?: string | null;
    main_html?: string | null;
    banner_image_url?: string | null;
    date_event_end?: number | null;
    date_event_start?: number | null;
    active_event_list?: {
      total_count: number;
      item_list?: Array<{
        id: string;
        uuid: string;
        title?: string | null;
        main_html?: string | null;
        banner_image_url?: string | null;
        date_event_start?: number | null;
        date_event_end?: number | null;
        order?: number | null;
        created_by?: string | null;
      } | null> | null;
    } | null;
    section_list?: Array<{
      id: string;
      title?: string | null;
      item_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      }>;
    }> | null;
  } | null;
};

export type ParticipateEventMetadataVariables = Exact<{
  input: ParticipateEventMetadataInput;
}>;

export type ParticipateEventMetadata = {
  participateEventMetadata: {
    type: string;
    title?: string | null;
    message?: string | null;
    image_url?: string | null;
    link_title?: string | null;
    link_url?: string | null;
    alert_type: ParticipateEventMetadataResultAlertType;
  };
};

export type ParticipateFbkEventVariables = Exact<{
  input: ParticipateFbkEventInput;
}>;

export type ParticipateFbkEvent = { participateFbkEvent: boolean };

export type ParticipateFbkEventWithEventMetadataVariables = Exact<{
  input: ParticipateFbkEventWithEventMetadataInput;
}>;

export type ParticipateFbkEventWithEventMetadata = { participateFbkEventWithEventMetadata: boolean };

export type CmsContentEditorShareInfoPart = {
  button_title?: string | null;
  description?: string | null;
  image_src?: string | null;
  title?: string | null;
  content_short_url?: string | null;
  disabled?: boolean | null;
};

export type CmsContentEditor = {
  support_agent_list: Array<ContentEditorAgent>;
  uuid: string;
  title?: string | null;
  language?: CmsLanguage | null;
  country?: CmsCountry | null;
  currency?: CmsCurrency | null;
  content_editor_json?: string | null;
  date_started?: number | null;
  date_ended?: number | null;
  share_info?: {
    button_title?: string | null;
    description?: string | null;
    image_src?: string | null;
    title?: string | null;
    content_short_url?: string | null;
    disabled?: boolean | null;
  } | null;
};

export type GetCmsContentEditorVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetCmsContentEditor = {
  cms_content_editor?: {
    support_agent_list: Array<ContentEditorAgent>;
    uuid: string;
    title?: string | null;
    language?: CmsLanguage | null;
    country?: CmsCountry | null;
    currency?: CmsCurrency | null;
    content_editor_json?: string | null;
    date_started?: number | null;
    date_ended?: number | null;
    share_info?: {
      button_title?: string | null;
      description?: string | null;
      image_src?: string | null;
      title?: string | null;
      content_short_url?: string | null;
      disabled?: boolean | null;
    } | null;
  } | null;
};

export type CmsContentEditorGroup = {
  id: string;
  title?: string | null;
  operation_title: string;
  content_list: Array<{
    support_agent_list: Array<ContentEditorAgent>;
    uuid: string;
    title?: string | null;
    language?: CmsLanguage | null;
    country?: CmsCountry | null;
    currency?: CmsCurrency | null;
    content_editor_json?: string | null;
    date_started?: number | null;
    date_ended?: number | null;
    share_info?: {
      button_title?: string | null;
      description?: string | null;
      image_src?: string | null;
      title?: string | null;
      content_short_url?: string | null;
      disabled?: boolean | null;
    } | null;
  }>;
};

export type GetCmsContentEditorGroupVariables = Exact<{
  group_id: Scalars['ID'];
  target_uuid: Scalars['String'];
}>;

export type GetCmsContentEditorGroup = {
  content_editor_group?: {
    id: string;
    title?: string | null;
    operation_title: string;
    content_list: Array<{
      support_agent_list: Array<ContentEditorAgent>;
      uuid: string;
      title?: string | null;
      language?: CmsLanguage | null;
      country?: CmsCountry | null;
      currency?: CmsCurrency | null;
      content_editor_json?: string | null;
      date_started?: number | null;
      date_ended?: number | null;
      share_info?: {
        button_title?: string | null;
        description?: string | null;
        image_src?: string | null;
        title?: string | null;
        content_short_url?: string | null;
        disabled?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type CmsStorePresetShopPart = { id: number; name: string; image_url: string };

export type CmsStorePresetShopListPart = {
  uuid: string;
  shop_list: Array<{ id: number; name: string; image_url: string }>;
};

export type GetCmsStorePresetShopListVariables = Exact<{
  store_preset_uuid: Scalars['String'];
}>;

export type GetCmsStorePresetShopList = {
  cms_store_preset_shop_list: { uuid: string; shop_list: Array<{ id: number; name: string; image_url: string }> };
};

export type CmsProductsPresetShopPart = { id: string; main_domain: string; name: string };

export type CmsProductsPresetProductPart = {
  product_url?: string | null;
  image_url?: string | null;
  webp_image_url?: string | null;
  title?: string | null;
  discount_rate?: number | null;
  price?: number | null;
  max_price: number;
  free_shipping?: boolean | null;
  zpay?: boolean | null;
  shop_id: string;
  is_zpay_discount: boolean;
  final_price: number;
  has_coupon: boolean;
  browsing_type: CmsBrowsingType;
  shop_product_no?: string | null;
  catalog_product_id?: string | null;
  is_zonly: boolean;
  is_brand: boolean;
  status?: CmsUxGoodsStatus | null;
  ranking?: number | null;
  review_count?: number | null;
  review_score?: number | null;
  thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
};

export type CmsProductsPresetProductCardItemPart = {
  shop: { id: string; main_domain: string; name: string };
  product: {
    product_url?: string | null;
    image_url?: string | null;
    webp_image_url?: string | null;
    title?: string | null;
    discount_rate?: number | null;
    price?: number | null;
    max_price: number;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    shop_id: string;
    is_zpay_discount: boolean;
    final_price: number;
    has_coupon: boolean;
    browsing_type: CmsBrowsingType;
    shop_product_no?: string | null;
    catalog_product_id?: string | null;
    is_zonly: boolean;
    is_brand: boolean;
    status?: CmsUxGoodsStatus | null;
    ranking?: number | null;
    review_count?: number | null;
    review_score?: number | null;
    thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
  };
};

export type CmsProductsPresetProductCardItemListPart = {
  total_count: number;
  has_next_page: boolean;
  end_cursor?: string | null;
  item_list: Array<{
    shop: { id: string; main_domain: string; name: string };
    product: {
      product_url?: string | null;
      image_url?: string | null;
      webp_image_url?: string | null;
      title?: string | null;
      discount_rate?: number | null;
      price?: number | null;
      max_price: number;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      shop_id: string;
      is_zpay_discount: boolean;
      final_price: number;
      has_coupon: boolean;
      browsing_type: CmsBrowsingType;
      shop_product_no?: string | null;
      catalog_product_id?: string | null;
      is_zonly: boolean;
      is_brand: boolean;
      status?: CmsUxGoodsStatus | null;
      ranking?: number | null;
      review_count?: number | null;
      review_score?: number | null;
      thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
    };
  }>;
};

export type CmsProductsPresetGroupPart = { total_count: number };

export type CmsProductsPresetProductCardItemListPrefetchPart = {
  item_list: Array<{
    shop: { id: string; main_domain: string; name: string };
    product: {
      product_url?: string | null;
      image_url?: string | null;
      webp_image_url?: string | null;
      title?: string | null;
      discount_rate?: number | null;
      price?: number | null;
      max_price: number;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      shop_id: string;
      is_zpay_discount: boolean;
      final_price: number;
      has_coupon: boolean;
      browsing_type: CmsBrowsingType;
      shop_product_no?: string | null;
      catalog_product_id?: string | null;
      is_zonly: boolean;
      is_brand: boolean;
      status?: CmsUxGoodsStatus | null;
      ranking?: number | null;
      review_count?: number | null;
      review_score?: number | null;
      thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
    };
  }>;
  groups: Array<{ total_count: number }>;
};

export type GetCmsProductsPresetProductCardItemListVariables = Exact<{
  products_preset_uuid: Scalars['String'];
  group_index?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetCmsProductsPresetProductCardItemList = {
  cms_products_preset_product_card_item_list: {
    total_count: number;
    has_next_page: boolean;
    end_cursor?: string | null;
    item_list: Array<{
      shop: { id: string; main_domain: string; name: string };
      product: {
        product_url?: string | null;
        image_url?: string | null;
        webp_image_url?: string | null;
        title?: string | null;
        discount_rate?: number | null;
        price?: number | null;
        max_price: number;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        shop_id: string;
        is_zpay_discount: boolean;
        final_price: number;
        has_coupon: boolean;
        browsing_type: CmsBrowsingType;
        shop_product_no?: string | null;
        catalog_product_id?: string | null;
        is_zonly: boolean;
        is_brand: boolean;
        status?: CmsUxGoodsStatus | null;
        ranking?: number | null;
        review_count?: number | null;
        review_score?: number | null;
        thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
      };
    }>;
  };
};

export type GetPrefetchedCmsProductsPresetProductCardItemListVariables = Exact<{
  products_preset_uuid: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetPrefetchedCmsProductsPresetProductCardItemList = {
  cms_products_preset_product_card_item_list: {
    item_list: Array<{
      shop: { id: string; main_domain: string; name: string };
      product: {
        product_url?: string | null;
        image_url?: string | null;
        webp_image_url?: string | null;
        title?: string | null;
        discount_rate?: number | null;
        price?: number | null;
        max_price: number;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        shop_id: string;
        is_zpay_discount: boolean;
        final_price: number;
        has_coupon: boolean;
        browsing_type: CmsBrowsingType;
        shop_product_no?: string | null;
        catalog_product_id?: string | null;
        is_zonly: boolean;
        is_brand: boolean;
        status?: CmsUxGoodsStatus | null;
        ranking?: number | null;
        review_count?: number | null;
        review_score?: number | null;
        thumbnail_emblem_badge_list?: Array<{ type?: string | null }> | null;
      };
    }>;
    groups: Array<{ total_count: number }>;
  };
};

export type GetCmsMyParticipateEventMetadataVariables = Exact<{
  event_content_uuid: Scalars['String'];
  event_metadata_uuid: Scalars['String'];
}>;

export type GetCmsMyParticipateEventMetadata = {
  getMyParticipateEventMetadata?: {
    is_participate: boolean;
    reward: Array<{
      type: EventMetadataRewardType;
      value:
        | {
            __typename: 'EventMetadataRewardAsyncCoupon';
            policy_id: string;
            min_required_amount: number;
            discount_amount: number;
            days_expire?: number | null;
            date_expire?: number | null;
            title?: string | null;
          }
        | { __typename: 'EventMetadataRewardAsyncCouponPack'; id: string; uid: string; title?: string | null }
        | {
            __typename: 'EventMetadataRewardCoupon';
            policy_id: string;
            min_required_amount: number;
            discount_amount: number;
            days_expire?: number | null;
            date_expire?: number | null;
            title?: string | null;
          }
        | {
            __typename: 'EventMetadataRewardCouponPack';
            uid: string;
            title?: string | null;
            item_list: Array<{
              policy_id: string;
              min_required_amount: number;
              discount_amount: number;
              days_expire?: number | null;
              date_expire?: number | null;
              title?: string | null;
            }>;
          }
        | {
            __typename: 'EventMetadataRewardManual';
            type: string;
            title?: string | null;
            message?: string | null;
            image_url?: string | null;
            link_title?: string | null;
            link_url?: string | null;
            alert_type: ParticipateEventMetadataResultAlertType;
          }
        | {
            __typename: 'EventMetadataRewardPoint';
            amount: number;
            days_expire?: number | null;
            description?: string | null;
          }
        | { __typename: 'EventMetadataRewardRandom'; value?: any | null }
        | { __typename: 'EventMetadataRewardUserSelected'; value?: any | null }
        | {};
      button_list?: Array<{
        text: string;
        color: string;
        background_color: string;
        action: {
          type: EventMetadataResultButtonActionType;
          option?:
            | { __typename: 'EventMetadataResultButtonActionKakaoShare'; data?: any | null }
            | { __typename: 'EventMetadataResultButtonActionOptionDeepLink'; zigzag_link: string }
            | null;
        };
      }> | null;
    }>;
  } | null;
};

export type CmsParticipateEventMetadataVariables = Exact<{
  input: ParticipateEventMetadataInput;
}>;

export type CmsParticipateEventMetadata = {
  participateEventMetadata: {
    type: string;
    title?: string | null;
    message?: string | null;
    image_url?: string | null;
    link_title?: string | null;
    link_url?: string | null;
    alert_type: ParticipateEventMetadataResultAlertType;
    reward: Array<{
      type: EventMetadataRewardType;
      value:
        | {
            __typename: 'EventMetadataRewardAsyncCoupon';
            policy_id: string;
            min_required_amount: number;
            discount_amount: number;
            days_expire?: number | null;
            date_expire?: number | null;
            title?: string | null;
          }
        | { __typename: 'EventMetadataRewardAsyncCouponPack'; id: string; uid: string; title?: string | null }
        | {
            __typename: 'EventMetadataRewardCoupon';
            policy_id: string;
            min_required_amount: number;
            discount_amount: number;
            days_expire?: number | null;
            date_expire?: number | null;
            title?: string | null;
          }
        | {
            __typename: 'EventMetadataRewardCouponPack';
            uid: string;
            title?: string | null;
            item_list: Array<{
              policy_id: string;
              min_required_amount: number;
              discount_amount: number;
              days_expire?: number | null;
              date_expire?: number | null;
              title?: string | null;
            }>;
          }
        | {
            __typename: 'EventMetadataRewardManual';
            type: string;
            title?: string | null;
            message?: string | null;
            image_url?: string | null;
            link_title?: string | null;
            link_url?: string | null;
            alert_type: ParticipateEventMetadataResultAlertType;
          }
        | {
            __typename: 'EventMetadataRewardPoint';
            amount: number;
            days_expire?: number | null;
            description?: string | null;
          }
        | { __typename: 'EventMetadataRewardRandom'; value?: any | null }
        | { __typename: 'EventMetadataRewardUserSelected'; value?: any | null }
        | {};
      button_list?: Array<{
        text: string;
        color: string;
        background_color: string;
        action: {
          type: EventMetadataResultButtonActionType;
          option?:
            | { __typename: 'EventMetadataResultButtonActionKakaoShare'; data?: any | null }
            | { __typename: 'EventMetadataResultButtonActionOptionDeepLink'; zigzag_link: string }
            | null;
        };
      }> | null;
    } | null>;
  };
};

export type CmsEventMetadataReward = {
  type: EventMetadataRewardType;
  value:
    | {
        __typename: 'EventMetadataRewardAsyncCoupon';
        policy_id: string;
        min_required_amount: number;
        discount_amount: number;
        days_expire?: number | null;
        date_expire?: number | null;
        title?: string | null;
      }
    | { __typename: 'EventMetadataRewardAsyncCouponPack'; id: string; uid: string; title?: string | null }
    | {
        __typename: 'EventMetadataRewardCoupon';
        policy_id: string;
        min_required_amount: number;
        discount_amount: number;
        days_expire?: number | null;
        date_expire?: number | null;
        title?: string | null;
      }
    | {
        __typename: 'EventMetadataRewardCouponPack';
        uid: string;
        title?: string | null;
        item_list: Array<{
          policy_id: string;
          min_required_amount: number;
          discount_amount: number;
          days_expire?: number | null;
          date_expire?: number | null;
          title?: string | null;
        }>;
      }
    | {
        __typename: 'EventMetadataRewardManual';
        type: string;
        title?: string | null;
        message?: string | null;
        image_url?: string | null;
        link_title?: string | null;
        link_url?: string | null;
        alert_type: ParticipateEventMetadataResultAlertType;
      }
    | {
        __typename: 'EventMetadataRewardPoint';
        amount: number;
        days_expire?: number | null;
        description?: string | null;
      }
    | { __typename: 'EventMetadataRewardRandom'; value?: any | null }
    | { __typename: 'EventMetadataRewardUserSelected'; value?: any | null }
    | {};
  button_list?: Array<{
    text: string;
    color: string;
    background_color: string;
    action: {
      type: EventMetadataResultButtonActionType;
      option?:
        | { __typename: 'EventMetadataResultButtonActionKakaoShare'; data?: any | null }
        | { __typename: 'EventMetadataResultButtonActionOptionDeepLink'; zigzag_link: string }
        | null;
    };
  }> | null;
};

export type CmsRewardCoupon = {
  __typename: 'EventMetadataRewardCoupon';
  policy_id: string;
  min_required_amount: number;
  discount_amount: number;
  days_expire?: number | null;
  date_expire?: number | null;
  title?: string | null;
};

export type CmsRewardCouponPack = {
  __typename: 'EventMetadataRewardCouponPack';
  uid: string;
  title?: string | null;
  item_list: Array<{
    policy_id: string;
    min_required_amount: number;
    discount_amount: number;
    days_expire?: number | null;
    date_expire?: number | null;
    title?: string | null;
  }>;
};

export type CmsRewardPoint = {
  __typename: 'EventMetadataRewardPoint';
  amount: number;
  days_expire?: number | null;
  description?: string | null;
};

export type CmsRewardRandom = { __typename: 'EventMetadataRewardRandom'; value?: any | null };

export type CmsRewardManual = {
  __typename: 'EventMetadataRewardManual';
  type: string;
  title?: string | null;
  message?: string | null;
  image_url?: string | null;
  link_title?: string | null;
  link_url?: string | null;
  alert_type: ParticipateEventMetadataResultAlertType;
};

export type CmsRewardUserSelected = { __typename: 'EventMetadataRewardUserSelected'; value?: any | null };

export type CmsButtonActionKakaoShare = { __typename: 'EventMetadataResultButtonActionKakaoShare'; data?: any | null };

export type CmsButtonActionOptionDeepLink = {
  __typename: 'EventMetadataResultButtonActionOptionDeepLink';
  zigzag_link: string;
};

export type CmsRewardAsyncCoupon = {
  __typename: 'EventMetadataRewardAsyncCoupon';
  policy_id: string;
  min_required_amount: number;
  discount_amount: number;
  days_expire?: number | null;
  date_expire?: number | null;
  title?: string | null;
};

export type CmsRewardAsyncCouponPack = {
  __typename: 'EventMetadataRewardAsyncCouponPack';
  id: string;
  uid: string;
  title?: string | null;
};

export type EventContentReplyPart = {
  id: string;
  event_content_reply_metadata_uuid: string;
  status: EventContentReplyStatus;
  user_account_profile_id: string;
  user_account_profile_nickname: string;
  content: string;
  user_account_id: string;
  date_created: number;
  date_updated: number;
  is_mine?: boolean | null;
};

export type GetCmsEventContentReplyListVariables = Exact<{
  event_content_reply_metadata_uuid: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  limit_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetCmsEventContentReplyList = {
  getEventContentReplyList: {
    total_count: number;
    has_next_page: boolean;
    end_cursor?: string | null;
    item_list: Array<{
      id: string;
      event_content_reply_metadata_uuid: string;
      status: EventContentReplyStatus;
      user_account_profile_id: string;
      user_account_profile_nickname: string;
      content: string;
      user_account_id: string;
      date_created: number;
      date_updated: number;
      is_mine?: boolean | null;
    }>;
  };
};

export type GetCmsEventContentReplyMetadataVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetCmsEventContentReplyMetadata = {
  getEventContentReplyMetadata?: { uuid: string; date_started: number; date_ended: number } | null;
};

export type CreateCmsEventContentReplyVariables = Exact<{
  content: Scalars['String'];
  event_content_reply_metadata_uuid: Scalars['String'];
}>;

export type CreateCmsEventContentReply = {
  createEventContentReply: {
    id: string;
    event_content_reply_metadata_uuid: string;
    status: EventContentReplyStatus;
    user_account_profile_id: string;
    user_account_profile_nickname: string;
    content: string;
    user_account_id: string;
    date_created: number;
    date_updated: number;
    is_mine?: boolean | null;
  };
};

export type DeleteCmsEventContentReplyVariables = Exact<{
  reply_id: Scalars['ID'];
  event_content_reply_metadata_uuid: Scalars['String'];
}>;

export type DeleteCmsEventContentReply = { deleteEventContentReply: boolean };

export type CmsEventMetadata = {
  total_point?: number | null;
  uuid: string;
  event_content_uuid?: string | null;
  event_status_code: EventMetadataEventStatusCode;
  date_started: number;
  date_ended: number;
  limit_participation: number;
  total_participation: number;
  client_pre_condition?: Array<{
    type: EventMetadataPreConditionType;
    condition: any;
    logical_operator: EventMetadataLogicalOperatorType;
    message_on_failure?: {
      type: string;
      title?: string | null;
      message?: string | null;
      image_url?: string | null;
      link_title?: string | null;
      link_url?: string | null;
      alert_type: ParticipateEventMetadataResultAlertType;
    } | null;
  }> | null;
};

export type ClientPreCondition = {
  type: EventMetadataPreConditionType;
  condition: any;
  logical_operator: EventMetadataLogicalOperatorType;
  message_on_failure?: {
    type: string;
    title?: string | null;
    message?: string | null;
    image_url?: string | null;
    link_title?: string | null;
    link_url?: string | null;
    alert_type: ParticipateEventMetadataResultAlertType;
  } | null;
};

export type MessageOnFailure = {
  type: string;
  title?: string | null;
  message?: string | null;
  image_url?: string | null;
  link_title?: string | null;
  link_url?: string | null;
  alert_type: ParticipateEventMetadataResultAlertType;
};

export type GetCmsEventMetadataVariables = Exact<{
  event_metadata_uuid: Scalars['String'];
}>;

export type GetCmsEventMetadata = {
  getEventMetadata: {
    server_time: number;
    metadata: {
      total_point?: number | null;
      uuid: string;
      event_content_uuid?: string | null;
      event_status_code: EventMetadataEventStatusCode;
      date_started: number;
      date_ended: number;
      limit_participation: number;
      total_participation: number;
      client_pre_condition?: Array<{
        type: EventMetadataPreConditionType;
        condition: any;
        logical_operator: EventMetadataLogicalOperatorType;
        message_on_failure?: {
          type: string;
          title?: string | null;
          message?: string | null;
          image_url?: string | null;
          link_title?: string | null;
          link_url?: string | null;
          alert_type: ParticipateEventMetadataResultAlertType;
        } | null;
      }> | null;
    };
  };
};

export type ProductCardItem = {
  image_url?: string | null;
  webp_image_url?: string | null;
  shop_name?: string | null;
  title?: string | null;
  price?: number | null;
  discount_rate?: number | null;
  free_shipping?: boolean | null;
  zpay?: boolean | null;
  log?: string | null;
  aid?: string | null;
  product_url?: string | null;
  has_coupon: boolean;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id?: string | null;
  shop_product_no?: string | null;
  shop_id: string;
  is_zonly: boolean;
  is_brand: boolean;
  is_saved_product: boolean;
  review_count?: number | null;
  review_score?: number | null;
  is_new?: boolean | null;
  badge_list?: Array<{ type?: string | null }> | null;
  thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
};

export type ProductCardList = {
  total_count: number;
  end_cursor?: string | null;
  item_list?: Array<{
    image_url?: string | null;
    webp_image_url?: string | null;
    shop_name?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    log?: string | null;
    aid?: string | null;
    product_url?: string | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    catalog_product_id?: string | null;
    shop_product_no?: string | null;
    shop_id: string;
    is_zonly: boolean;
    is_brand: boolean;
    is_saved_product: boolean;
    review_count?: number | null;
    review_score?: number | null;
    is_new?: boolean | null;
    badge_list?: Array<{ type?: string | null }> | null;
    thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
  }> | null;
};

export type ProductInfo = {
  catalog_product_id: string;
  image_url?: string | null;
  webp_image_url?: string | null;
  shop_name?: string | null;
  title?: string | null;
  price?: number | null;
  discount_rate?: number | null;
  free_shipping?: boolean | null;
  zpay?: boolean | null;
  log?: string | null;
  aid?: string | null;
  product_url?: string | null;
  has_coupon: boolean;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  shop_product_no?: string | null;
  shop_id: string;
  is_zonly: boolean;
  is_brand: boolean;
  is_saved_product: boolean;
  review_count?: number | null;
  review_score?: number | null;
  is_new?: boolean | null;
  is_ad: boolean;
  badge_list: Array<{ type: string }>;
  thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
};

export type ProductItemInfo = { price_delta: number; options: string };

export type VbankPayInfoItem = {
  vbank_pay_info?: { bank_name: string; number: string; holder: string; due_date_ymd: number; amount: number } | null;
};

export type OrderReceiverInfo = {
  order_receiver?: {
    first_name: string;
    last_name: string;
    contract_number: string;
    country_number: string;
    postcode: string;
    address: string;
    detail_address?: string | null;
    city?: string | null;
    state?: string | null;
    memo?: string | null;
    masked_name: string;
    masked_mobile_tel: string;
    masked_address: string;
    masked_detail_address?: string | null;
    masked_personal_clearance_code?: string | null;
  } | null;
};

export type OrderItemRequestInfo = {
  order_item_request_number?: string | null;
  type: OrderItemRequestType;
  status: OrderItemRequestStatus;
  date_requested: number;
  date_completed?: number | null;
  requested_reason_category: RequestedReasonCategory;
  requested_reason?: string | null;
  withdrawn_reason?: string | null;
  date_marked_return_collecting?: number | null;
  date_marked_return_collected?: number | null;
  date_marked_exchange_collecting?: number | null;
  date_marked_exchange_collected?: number | null;
  date_withdrawn?: number | null;
  collecting_type?: CollectingType | null;
  payment_uuid_at_request?: string | null;
  requested_international_order_shipping_fee_total_amount?: number | null;
  withdrawn_account_info?: { account_type?: OrderAccountType | null } | null;
  active_claim_item_defer?: { date_created: number; deferred_reason?: string | null } | null;
  order_item_request_reason_list: Array<{
    order_item_request_status: OrderItemRequestStatus;
    reason?: string | null;
    reason_category: RequestedReasonCategory;
    date_created: number;
    account_info: { account_type?: OrderAccountType | null };
  }>;
  refund_payment?: {
    method?: RefundPaymentMethod | null;
    refund_bank_account?: { code?: string | null; number?: string | null; holder?: string | null } | null;
  } | null;
  refunded_international_order_shipping_fee_amounts?: {
    total_amount: number;
    cash_amount: number;
    point_amount: number;
  } | null;
  shipping_fee_additional_charge_method?: {
    initial?: ShippingFeeChargeMethod | null;
    exchange?: ShippingFeeChargeMethod | null;
    return?: ShippingFeeChargeMethod | null;
  } | null;
  shipping_group_refund?: {
    revoked_discount_amount: number;
    refunded_amounts: { total_amount: number; cash_amount: number; point_amount: number };
    requested_amounts: { total_amount: number; items_amount: number; shipping_fee: number };
    deduction_cost: {
      free_shipping_breaking_fee: number;
      return_shipping_fee: number;
      initial_shipping_fee: number;
      total_fee: number;
    };
    deduction_shipping_fee_detail?: {
      initial_shipping_fee_cash_amount: number;
      initial_shipping_fee_point_amount: number;
      return_shipping_fee_cash_amount: number;
      return_shipping_fee_point_amount: number;
    } | null;
    enclose_amount: {
      free_shipping_breaking_fee: number;
      return_shipping_fee: number;
      initial_shipping_fee: number;
      total_fee: number;
    };
    expired_amounts: { point_amount: number };
    order_discount_revoked_list: Array<{
      revoked_discount_amount: number;
      order_discount: {
        user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
      };
    }>;
  } | null;
  history_list: Array<{
    shipping_fee_additional_charge_method?: {
      initial?: ShippingFeeChargeMethod | null;
      return?: ShippingFeeChargeMethod | null;
    } | null;
  }>;
  active_defer?: { date_created: number } | null;
  recent_defer?: { date_created: number; date_withdrawn?: number | null } | null;
  attachment_list: Array<{ id: string; original_url: string; thumbnail_url: string }>;
};

export type OrderDiscountInfo = {
  order_discount_list: Array<{ discount_amount: number; user_account_coupon_id: string }>;
};

export type BodyValue = { value: number; label: string };

export type BodyStringValue = { value: string; label: string };

export type UserAccountBodyFragment = {
  height: { value: number; label: string };
  weight: { value: number; label: string };
  upper_size: { value: number; label: string };
  bottom_size: { value: number; label: string };
  shoe_size: { value: number; label: string };
};

export type GetHomeProductAdOrProductIdListVariables = Exact<{
  category_key?: InputMaybe<Scalars['String']>;
}>;

export type GetHomeProductAdOrProductIdList = {
  home_ad_or_product_id_list: { item_list: Array<{ ad_id?: string | null; product_id?: string | null }> };
};

export type GetProductListByIdListVariables = Exact<{
  product_id_list: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetProductListByIdList = {
  product_list_by_id_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type GetHomeAdAndPersonalizedProductListVariables = Exact<{
  ad_id_list: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetHomeAdAndPersonalizedProductList = {
  homeAdAndPersonalizedProductList: {
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetProductListByAdIdListVariables = Exact<{
  ad_id_list: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetProductListByAdIdList = {
  product_list_by_id_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type GetFbkSaleTimeSaleProductListVariables = Exact<{ [key: string]: never }>;

export type GetFbkSaleTimeSaleProductList = {
  fbk_sale_timesale_product_list: {
    item_list: Array<{
      date_started: number;
      date_ended: number;
      product: {
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        catalog_product_id: string;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        badge_list: Array<{ type: string }>;
      };
    }>;
  };
};

export type GetPersonalizedProductListVariables = Exact<{ [key: string]: never }>;

export type GetPersonalizedProductList = {
  personalized_product_list: Array<{
    catalog_product_id: string;
    image_url?: string | null;
    webp_image_url?: string | null;
    shop_name?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    zpay?: boolean | null;
    log?: string | null;
    aid?: string | null;
    product_url?: string | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    shop_product_no?: string | null;
    shop_id: string;
    is_zonly: boolean;
    is_brand: boolean;
    is_saved_product: boolean;
    review_count?: number | null;
    review_score?: number | null;
    is_new?: boolean | null;
    is_ad: boolean;
    badge_list: Array<{ type: string }>;
    thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
  }>;
};

export type GetFbkStorePickListVariables = Exact<{
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetFbkStorePickList = {
  fbk_storepick_list: {
    end_cursor?: string | null;
    item_list: Array<{
      store_pick_id: number;
      banner: {
        title: string;
        image_url: string;
        discount_enabled: boolean;
        tag_free_shipping: boolean;
        tag_coupon: boolean;
        tag_product_discount?: number | null;
      };
      shop: {
        ranking: number;
        shop_id: string;
        name: string;
        main_domain: string;
        category?: string | null;
        age_list: Array<string>;
        typical_image_url: string;
        bookmark_count?: number | null;
        is_zpay: boolean;
        is_free_shipping: boolean;
        date_approved?: number | null;
        coupon_description?: string | null;
        is_zonly: boolean;
        is_saved_shop: boolean;
        style_list: Array<{ value: string; color: string }>;
        goods_list: Array<{ url: string; image_url: string; log: string }>;
        variance?: { type?: UxShopRankingVarianceType | null; value?: number | null; color: string } | null;
      };
    }>;
  };
};

export type GetCartItemsCountVariables = Exact<{ [key: string]: never }>;

export type GetCartItemsCount = { cart_items_count: number };

export type GetBannerAdDisplayVariables = Exact<{ [key: string]: never }>;

export type GetBannerAdDisplay = {
  fbk_banner_ad_display?: {
    id: string;
    title: string;
    banner_image_url: string;
    status: string;
    link_url: string;
  } | null;
};

export type GetServerTimeVariables = Exact<{ [key: string]: never }>;

export type GetServerTime = { metadata: { server_time: number } };

export type GetDeviceUuidVariables = Exact<{ [key: string]: never }>;

export type GetDeviceUuid = { user?: { uuid: string } | null };

export type GetAbTestListVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']>;
}>;

export type GetAbTestList = { metadata: { feature_list: Array<string> } };

export type GetTrendCarouselListVariables = Exact<{ [key: string]: never }>;

export type GetTrendCarouselList = {
  trend_carousel_list: Array<{
    benefit_section_id: string;
    main_title: string;
    sub_title?: string | null;
    product_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  }>;
};

export type GetDiscountedNewProductListVariables = Exact<{
  display_category_id?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDiscountedNewProductList = {
  discounted_new_product_list: {
    total_count: number;
    end_cursor?: string | null;
    has_next_page?: boolean | null;
    item_list: Array<{
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      catalog_product_id: string;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type GetActiveBannerListVariables = Exact<{
  banner_page_name: Scalars['String'];
}>;

export type GetActiveBannerList = {
  active_banner_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      title: string;
      image_url: string;
      landing_url: string;
      date_start: number;
      date_end: number;
    }>;
  };
  active_line_banner?: {
    id: string;
    landing_url: string;
    banner_text_content: string;
    background_color: string;
    text_color: string;
    highlight_text_color: string;
  } | null;
};

export type GetActivatedDisplayCategoryVariables = Exact<{
  product_list_type?: InputMaybe<Scalars['String']>;
}>;

export type GetActivatedDisplayCategory = {
  activated_display_category: {
    id: string;
    name: string;
    icon_url: string;
    sub_category_list: Array<{
      id: string;
      name: string;
      icon_url: string;
      sub_category_list: Array<{ id: string; name: string; icon_url: string }>;
    }>;
  };
};

export type GetActivatedShopMetaCategoryListVariables = Exact<{
  shop_list_type?: InputMaybe<Scalars['String']>;
}>;

export type GetActivatedShopMetaCategoryList = {
  activated_shop_meta_category_list: { item_list: Array<{ id: string; name: string }> };
};

export type GetBestShopRankingListVariables = Exact<{
  shop_meta_category_id: Scalars['ID'];
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetBestShopRankingList = {
  best_shop_ranking_list: {
    end_cursor?: string | null;
    item_list: Array<{
      ranking: number;
      shop_id: string;
      name: string;
      typical_image_url?: string | null;
      bookmark_count?: number | null;
      style_list: Array<string>;
      is_saved_shop: boolean;
      best_product_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      }>;
    }>;
  };
};

export type GetBestProductRankingListVariables = Exact<{
  display_category_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetBestProductRankingList = {
  best_product_ranking_list: {
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetNewContentListVariables = Exact<{
  page: Scalars['Int'];
  page_size?: Scalars['Int'];
}>;

export type GetNewContentList = {
  new_content_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      title: string;
      text_content: string;
      landing_url: string;
      product_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      }>;
      base_content_media?: { media_type: string; video_url?: string | null; image_url: string } | null;
    }>;
  };
};

export type GetTrendKeywordListVariables = Exact<{ [key: string]: never }>;

export type GetTrendKeywordList = {
  trend_keyword_list: { item_list: Array<{ keyword: string; image_url?: string | null }> };
};

export type GetActiveRecommendShopListVariables = Exact<{
  page_name: Scalars['String'];
  group_name?: InputMaybe<Scalars['String']>;
}>;

export type GetActiveRecommendShopList = {
  active_recommend_shop_list: {
    total_count: number;
    item_list: Array<{
      shop_id: string;
      display_order: number;
      shop_name: string;
      title?: string | null;
      group_name?: string | null;
      shop_main_domain: string;
      typical_image_url: string;
      manual_uploaded_image_url?: string | null;
      product_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      }>;
    }>;
  };
};

export type GetRelatedProductListByRecentViewVariables = Exact<{ [key: string]: never }>;

export type GetRelatedProductListByRecentView = {
  related_product_list_by_recent_view: {
    main_title: string;
    sub_title: string;
    recent_view_product_list: Array<{
      recent_view_product: {
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      };
      product_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
        shop_name?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        zpay?: boolean | null;
        log?: string | null;
        aid?: string | null;
        product_url?: string | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        is_zonly: boolean;
        is_brand: boolean;
        is_saved_product: boolean;
        review_count?: number | null;
        review_score?: number | null;
        is_new?: boolean | null;
        is_ad: boolean;
        badge_list: Array<{ type: string }>;
        thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
      }>;
    }>;
  };
};

export type GetAppReviewRecommendableVariables = Exact<{ [key: string]: never }>;

export type GetAppReviewRecommendable = { isFbkAppReviewRecommendableUserAccount: boolean };

export type GetCouponIssueStatusVariables = Exact<{
  policy_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  coupon_pack_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetCouponIssueStatus = {
  getCouponIssueStatus: {
    is_issued_coupon_pack: boolean;
    issued_policy_id_list: Array<string>;
    not_issued_policy_id_list: Array<string>;
  };
};

export type GetBoostShopCouponPolicyVariables = Exact<{ [key: string]: never }>;

export type GetBoostShopCouponPolicy = {
  fbk_boost_shop_coupon_policy?: {
    coupon_discount_rate_bp: number;
    coupon_policy_id: string;
    shop: { name: string; shop_id: string; typical_image_url?: string | null };
    product_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  } | null;
};

export type GetMdPickNewProductListVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
}>;

export type GetMdPickNewProductList = {
  md_pick_new_prodduct_list: {
    total_count: number;
    coupon_policy_id?: string | null;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetNewCouponVariables = Exact<{ [key: string]: never }>;

export type GetNewCoupon = {
  new_coupon: {
    new_product_coupon_policy: { is_issued: boolean; coupon_policy_id: string; coupon_discount_rate_bp: number };
  };
};

export type GetNewProductListVariables = Exact<{
  display_category_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
}>;

export type GetNewProductList = {
  new_product_list: {
    total_count: number;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetNativeAppTabListVariables = Exact<{ [key: string]: never }>;

export type GetNativeAppTabList = {
  native_app_tab_list: Array<{
    id: string;
    name: string;
    page_key?: string | null;
    tab_type: string;
    landing_url?: string | null;
    is_default_tab: boolean;
    icon?: { active_icon_url: string; inactive_icon_url: string } | null;
    name_color: { active_name_color: string; inactive_name_color: string };
    tab_list: Array<{
      id: string;
      name: string;
      page_key?: string | null;
      tab_type: string;
      landing_url?: string | null;
      is_default_tab: boolean;
      icon?: { active_icon_url: string; inactive_icon_url: string } | null;
      name_color: { active_name_color: string; inactive_name_color: string };
    }>;
  }>;
};

export type GetFbkPromotionBdageListVariables = Exact<{ [key: string]: never }>;

export type GetFbkPromotionBdageList = {
  promotion_badge_list: { item_list: Array<{ id: string; badge_image_url: string; type: string }> };
};

export type GetUserInviteStatusVariables = Exact<{ [key: string]: never }>;

export type GetUserInviteStatus = {
  user_invite_status: { count_of_invitee: number; count_of_promotion_fulfill: number; reward_amount: number };
};

export type CreateInviteCodeVariables = Exact<{ [key: string]: never }>;

export type CreateInviteCode = { createInviteCode: string };

export type IsUserInviteEnableVariables = Exact<{ [key: string]: never }>;

export type IsUserInviteEnable = { isUserInviteEnable: boolean };

export type RewardInviteeVariables = Exact<{
  input: RewardInviteeInput;
}>;

export type RewardInvitee = { rewardInvitee: { is_success: boolean; message?: string | null } };

export type GetFbkLikeShopListVariables = Exact<{
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetFbkLikeShopList = {
  fbk_like_shop_list: {
    end_cursor?: string | null;
    item_list?: Array<{
      ranking: number;
      shop_id: string;
      name: string;
      typical_image_url: string;
      bookmark_count?: number | null;
      is_saved_shop: boolean;
      main_domain: string;
      new_product?: { id?: string | null; is_new?: boolean | null; renewed_ad?: number | null } | null;
      style_list: Array<{ value: string; color: string }>;
    }> | null;
  };
};

export type GetFbkShopLatestProductListVariables = Exact<{
  shop_id?: InputMaybe<Scalars['ID']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetFbkShopLatestProductList = {
  fbk_like_shop_latest_product_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      image_url?: string | null;
      webp_image_url?: string | null;
      product_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      catalog_product_id: string;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_new?: boolean | null;
      is_saved_product: boolean;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type GetFbkLikeProductListVariables = Exact<{
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetFbkLikeProductList = {
  fbk_like_product_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      image_url?: string | null;
      webp_image_url?: string | null;
      product_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      catalog_product_id: string;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      is_new?: boolean | null;
      review_count?: number | null;
      review_score?: number | null;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type SaveProductLikeVariables = Exact<{
  product_ids: UmdProductIdentifiersInput;
  folder_id?: InputMaybe<Scalars['String']>;
  folder_name?: InputMaybe<Scalars['String']>;
}>;

export type SaveProductLike = {
  saveUmdProduct: {
    discount_rate?: number | null;
    max_price: number;
    final_price: number;
    browsing_type: UmdBrowsingType;
    has_coupon: boolean;
    is_zpay_discount: boolean;
    is_zonly: boolean;
    is_brand: boolean;
    is_display_not_zpay: boolean;
    product: { shop_id: string; shop_product_no?: string | null; catalog_product_id?: string | null };
    shop: {
      id: string;
      shop_main_domain: string;
      name: string;
      url: string;
      logo_url: string;
      category?: string | null;
      status: UmdShopStatus;
      is_free_shipping: boolean;
      customer_center_tel?: string | null;
      zpay_status: UmdShopZpayStatus;
      bookmark_count?: number | null;
      age_list?: Array<string> | null;
      delete_action: UmdDeleteAction;
      style_list?: Array<{ value: string; color: string }> | null;
    };
  };
};

export type UnSaveProductLikeVariables = Exact<{
  product_info?: InputMaybe<UmdProductInfoInput>;
}>;

export type UnSaveProductLike = { removeUmdSavedProduct: boolean };

export type SaveStoreLikeVariables = Exact<{
  shop_id: Scalars['String'];
}>;

export type SaveStoreLike = {
  saveUmdShop: { tag_list?: Array<string> | null; new_item_count: number; shop: { id: string } };
};

export type UnSaveStoreLikeVariables = Exact<{
  shop_id: Scalars['String'];
}>;

export type UnSaveStoreLike = { removeUmdSavedShop: boolean };

export type GetShopLiveUpcomingCampaignVariables = Exact<{ [key: string]: never }>;

export type GetShopLiveUpcomingCampaign = {
  fbk_shoplive_upcoming_campaign?: {
    campaign_key: string;
    campaign_url: string;
    title: string;
    memo?: string | null;
    campaign_status: string;
    unsupport_info_url?: string | null;
    campaign_goods_count: number;
    scheduled_at: number;
    scheduled_end_at: number;
    user_count: number;
    adore_count: number;
    poster_url?: string | null;
    preview_live_url?: string | null;
    live_url?: string | null;
    background_url?: string | null;
    started_at?: number | null;
    replay_live_url?: string | null;
    poster2_url?: string | null;
    ended_at?: number | null;
    campaign_goods_list?: Array<{
      campaign_id: number;
      goods_id: number;
      name: string;
      brand?: string | null;
      description?: string | null;
      sku?: string | null;
      action?: string | null;
      payload?: string | null;
      showing_now?: boolean | null;
      sold_out?: boolean | null;
      original_price: number;
      discounted_price?: number | null;
      discount_percentage?: number | null;
      currency?: string | null;
      medias?: Array<{
        id: number;
        media_type?: string | null;
        url: string;
        size?: number | null;
        width?: number | null;
        height?: number | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetShopLiveCampaignListVariables = Exact<{
  status?: InputMaybe<Scalars['String']>;
  date_start_scheduled_gte?: InputMaybe<Scalars['CrTimestamp']>;
  date_start_scheduled_lt?: InputMaybe<Scalars['CrTimestamp']>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
}>;

export type GetShopLiveCampaignList = {
  fbk_shoplive_campaign_list: {
    item_list: Array<{
      campaign_key: string;
      title: string;
      description?: string | null;
      status: string;
      date_start_scheduled: number;
      stream_preview_live_url: string;
      stream_replay_live_url?: string | null;
    }>;
  };
};

export type ShopliveIssueCouponVariables = Exact<{
  input: IssueShopliveCouponInput;
}>;

export type ShopliveIssueCoupon = {
  fbkShopliveIssueCoupon: {
    landing_url?: string | null;
    user_account_coupon: { id: string; status: ShopliveCouponStatus };
  };
};

export type IssueCouponPackVariables = Exact<{
  input: FbkIssueCouponPackInput;
}>;

export type IssueCouponPack = { fbkIssueCouponPack: { id: string; status: string } };

export type GenerateFbkShopliveJwtTokenVariables = Exact<{ [key: string]: never }>;

export type GenerateFbkShopliveJwtToken = { generateFbkShopliveJwtToken: string };

export type DeleteUserAccountVariables = Exact<{
  input: DeleteUserAccountInput;
}>;

export type DeleteUserAccount = { deleteUserAccount: { success: boolean } };

export type GetUserInfoVariables = Exact<{ [key: string]: never }>;

export type GetUserInfo = {
  has_user_account_order: boolean;
  user_account?: {
    uuid: string;
    email: string;
    full_name?: string | null;
    mobile_tel?: string | null;
    sms_reception_agreed?: boolean | null;
    email_reception_agreed?: boolean | null;
    nightly_ad_noti_agreed?: boolean | null;
    authenticated?: boolean | null;
    kakao_connected: boolean;
    apple_connected: boolean;
    product_review_info: {
      warning?: { title: string; message: string } | null;
      restriction?: { title: string; message: string } | null;
    };
    body?: {
      height: { value: number; label: string };
      weight: { value: number; label: string };
      upper_size: { value: number; label: string };
      bottom_size: { value: number; label: string };
      shoe_size: { value: number; label: string };
    } | null;
  } | null;
};

export type GetUserAvailablePointVariables = Exact<{ [key: string]: never }>;

export type GetUserAvailablePoint = { user_account_available_point: number };

export type LogoutVariables = Exact<{ [key: string]: never }>;

export type Logout = { logout: boolean };

export type GetUserAdNotiStatusVariables = Exact<{ [key: string]: never }>;

export type GetUserAdNotiStatus = { user?: { ad_noti_status?: AdNotiStatus | null } | null };

export type UpdateUserAdNotiStatusVariables = Exact<{
  input: UpdateUserAdNotiStatusInput;
}>;

export type UpdateUserAdNotiStatus = { updateUserAdNotiStatus: { success: boolean } };

export type RecertificationKakaoVariables = Exact<{
  input: RecertificationSocialInput;
}>;

export type RecertificationKakao = { recertificationKakao: { result: boolean } };

export type VerifyRecertificationVariables = Exact<{
  input: VerifyRecertificationInput;
}>;

export type VerifyRecertification = { verifyRecertification: { result: boolean } };

export type UpdateSmsReceptionAgreedVariables = Exact<{
  input: SetUserAccountSmsReceptionAgreedInput;
}>;

export type UpdateSmsReceptionAgreed = { setUserAccountSmsReceptionAgreed: boolean };

export type UpdateEmailReceptionAgreedVariables = Exact<{
  input: SetUserAccountEmailReceptionAgreedInput;
}>;

export type UpdateEmailReceptionAgreed = { setUserAccountEmailReceptionAgreed: boolean };

export type UpdateNightlyAdNotiAgreedVariables = Exact<{
  input: SetUserAccountNightlyAdNotiAgreedInput;
}>;

export type UpdateNightlyAdNotiAgreed = { setUserAccountNightlyAdNotiAgreed: boolean };

export type GetUserAccountPointListVariables = Exact<{
  expire_days: Scalars['Int'];
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetUserAccountPointList = {
  user_account_point_info: { available_point_of_use: number; expire_point: number };
  user_account_point_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      type: UserAccountPointType;
      point: number;
      description?: string | null;
      target_uid: string;
      date_issued: number;
      date_expire?: number | null;
      user_account_point_transaction_list: Array<{ point: number; date_expire?: number | null }>;
    }>;
  };
};

export type GetUserAccountMembershipVariables = Exact<{ [key: string]: never }>;

export type GetUserAccountMembership = {
  user_account?: { full_name?: string | null } | null;
  latest_user_membership?: {
    date_ymd_latest_updated: number;
    date_ym: number;
    membership?: {
      date_ym: number;
      paid_cash_amount: number;
      membership_level: { code: string; display_name: string; paid_cash_amount_gte: number };
    } | null;
  } | null;
  estimated_user_membership: {
    date_latest_updated: number;
    date_ymd: number;
    paid_cash_amount: number;
    is_minimum_target_membership_level_higher: boolean;
    minimum_target_membership_level: { code: string; display_name: string; paid_cash_amount_gte: number };
    estimated_membership_level?: { code: string; display_name: string; paid_cash_amount_gte: number } | null;
    next_target_membership_level?: { code: string; display_name: string; paid_cash_amount_gte: number } | null;
  };
};

export type ReviewEventFragment = {
  badge_title?: string | null;
  banner?: { image_url: string; image_ratio: number; description: string } | null;
};

export type GetReviewableOrderItemListVariables = Exact<{ [key: string]: never }>;

export type GetReviewableOrderItemList = {
  reviewable_order_item_list: {
    total_count: number;
    item_list: Array<{
      status: OrderItemStatus;
      total_amount: number;
      quantity: number;
      date_confirmed?: number | null;
      product_item_id: string;
      product_id: string;
      catalog_product_id: string;
      shop_id: string;
      shop_name: string;
      order_item_number: string;
      product_info: {
        image_url: string;
        url: string;
        name: string;
        options: string;
        product_no?: string | null;
        option_detail_list?: Array<{ name: string; value: string }> | null;
      };
      product_review_create_info: {
        attribute_question_list: Array<string>;
        date_deadline: number;
        is_selling: boolean;
        product_reviews_count: number;
        notice_html?: string | null;
        description_html?: string | null;
        point_amounts: { normal: { text_only: number; with_photo: number } };
        attribute_option_list: Array<{
          question: { label: string; value: string };
          answer_list: Array<{ label: string; value: string }>;
        }>;
        attribute_image_url_list: Array<{ title: string; image_url: string }>;
        warning?: { title: string; message: string } | null;
        restriction?: { title: string; message: string } | null;
        user_account_body?: {
          height: { value: number; label: string };
          weight: { value: number; label: string };
          upper_size: { value: number; label: string };
          bottom_size: { value: number; label: string };
          shoe_size: { value: number; label: string };
        } | null;
        review_event?: {
          badge_title?: string | null;
          banner?: { image_url: string; image_ratio: number; description: string } | null;
        } | null;
      };
    }>;
    review_event?: {
      badge_title?: string | null;
      banner?: { image_url: string; image_ratio: number; description: string } | null;
    } | null;
  };
};

export type MyProductReviewFragment = {
  id: string;
  country: string;
  country_name: string;
  language: string;
  shop_id: string;
  catalog_product_id: string;
  order_item_number: string;
  translated_contents?: string | null;
  contents: string;
  date_created: number;
  date_updated?: number | null;
  rating: number;
  updatable: boolean;
  display_limited: boolean;
  user_account_liked: boolean;
  like_count: number;
  user_account_disliked: boolean;
  dislike_count: number;
  order_item: {
    browsing_type: BrowsingType;
    pdp_landing_url: string;
    product_info: {
      image_url: string;
      url: string;
      name: string;
      options: string;
      option_detail_list?: Array<{ name: string; value: string }> | null;
    };
  };
  delete_info?: { reason: string; reason_detail?: string | null; message: string } | null;
};

export type GetMyReviewListVariables = Exact<{
  only_mine?: InputMaybe<Scalars['Boolean']>;
  skip_count?: InputMaybe<Scalars['Int']>;
  date_created_lt?: InputMaybe<Scalars['CrTimestamp']>;
  limit_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetMyReviewList = {
  product_review_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      country: string;
      country_name: string;
      language: string;
      shop_id: string;
      catalog_product_id: string;
      order_item_number: string;
      translated_contents?: string | null;
      contents: string;
      date_created: number;
      date_updated?: number | null;
      rating: number;
      updatable: boolean;
      display_limited: boolean;
      user_account_liked: boolean;
      like_count: number;
      user_account_disliked: boolean;
      dislike_count: number;
      order_item: {
        browsing_type: BrowsingType;
        pdp_landing_url: string;
        product_info: {
          image_url: string;
          url: string;
          name: string;
          options: string;
          option_detail_list?: Array<{ name: string; value: string }> | null;
        };
      };
      delete_info?: { reason: string; reason_detail?: string | null; message: string } | null;
    }>;
  };
};

export type SetUserAccountBodyVariables = Exact<{
  input: SetUserAccountBodyInput;
}>;

export type SetUserAccountBody = { setUserAccountBody: boolean };

export type GetShippingGroupListForCompletedVariables = Exact<{
  order_number: Scalars['String'];
}>;

export type GetShippingGroupListForCompleted = {
  shipping_group_list: {
    item_list: Array<{
      total_shipping_fee: number;
      order: {
        order_number: string;
        date_created: number;
        country: CountryType;
        payment_status: PaymentStatus;
        payment_method: PaymentMethod;
        points_used: number;
        total_item_amount: number;
        total_shipping_fee: number;
        domestic_shipping_fee?: number | null;
        international_shipping_fee?: number | null;
        total_payment_amount: number;
        shipping_memo?: string | null;
        pay_info?: any | null;
        global_estimated_shipping_date: { estimated_date: { start: number; end: number } };
        order_receiver?: {
          first_name: string;
          last_name: string;
          contract_number: string;
          country_number: string;
          postcode: string;
          address: string;
          detail_address?: string | null;
          city?: string | null;
          state?: string | null;
          memo?: string | null;
          masked_name: string;
          masked_mobile_tel: string;
          masked_address: string;
          masked_detail_address?: string | null;
          masked_personal_clearance_code?: string | null;
        } | null;
        vbank_pay_info?: {
          bank_name: string;
          number: string;
          holder: string;
          due_date_ymd: number;
          amount: number;
        } | null;
        order_discount_list: Array<{ discount_amount: number; user_account_coupon_id: string }>;
      };
      order_item_list: Array<{
        order_item_number: string;
        shipping_group_id: string;
        status: OrderItemStatus;
        product_id: string;
        product_item_id: string;
        quantity: number;
        total_amount: number;
        total_product_price: number;
        due_date_in_transit?: number | null;
        order_promotion_item?: {
          promotion_applied_discount_price: number;
          order_promotion_item_group_id?: string | null;
          promotion_applied_item_discount_price: number;
          order_promotion: {
            promotion_type: PromotionType;
            promotion_id: string;
            promotion_title: string;
            total_promotion_price: number;
            min_required_quantity?: number | null;
            applied_quantity?: number | null;
            min_required_amount?: number | null;
          };
        } | null;
        order_item_product: {
          option_type?: OrderItemProductOptionType | null;
          option_detail_list?: Array<{ name: string; value: string }> | null;
        };
        preorder?: { date_start: number; date_end: number; date_shipping: number } | null;
        zonly_info?: {
          origin_shop_id: string;
          origin_shop_name: string;
          origin_shop?: { id: string; main_domain: string; url: string } | null;
        } | null;
        product_info: {
          name: string;
          url: string;
          image_url: string;
          price: number;
          product_no?: string | null;
          price_delta: number;
          options: string;
        };
        product_category?: { category_full_name: string; id: string } | null;
        shop: {
          name: string;
          main_domain: string;
          url: string;
          typical_image_url?: string | null;
          customer_center_tel?: string | null;
          is_zonly: boolean;
          id: string;
          state: number;
        };
      }>;
    }>;
  };
};

export type UpdateUserAccountDateReadOrderItemInquiriesVariables = Exact<{ [key: string]: never }>;

export type UpdateUserAccountDateReadOrderItemInquiries = { updateUserAccountDateReadOrderItemInquiries: boolean };

export type GetOrderItemInquiryListVariables = Exact<{
  has_reply?: InputMaybe<Scalars['Boolean']>;
  date_created_ymd_from?: InputMaybe<Scalars['Int']>;
  limit_count?: InputMaybe<Scalars['Int']>;
  skip_count?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrderItemInquiryList = {
  order_item_inquiry_list: {
    item_list: Array<{
      id: string;
      content: string;
      date_created?: number | null;
      date_replied?: number | null;
      order_item: {
        quantity: number;
        product_info: { name: string; image_url: string; options: string };
        shop: { name: string };
      };
      shop: { name: string; is_zonly: boolean };
      reply_list: Array<{
        account_type: OrderItemInquiryReplyAccountType;
        date_created: number;
        date_read?: number | null;
        content: string;
      }>;
      attachment_list: Array<{ id: string; original_url: string; thumbnail_url: string }>;
    }>;
  };
};

export type DeleteOrderItemInquiryVariables = Exact<{
  input: DeleteOrderItemInquiryInput;
}>;

export type DeleteOrderItemInquiry = { deleteOrderItemInquiry: boolean };

export type CreateOrderItemInquiryVariables = Exact<{
  input: CreateOrderItemInquiryInput;
}>;

export type CreateOrderItemInquiry = { createOrderItemInquiry: { id?: string | null } };

export type GetOrderItemForInquiryVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetOrderItemForInquiry = {
  order_item?: {
    quantity: number;
    total_amount: number;
    product_info: { image_url: string; name: string; price_delta: number; options: string };
    shop: { typical_image_url?: string | null; name: string; is_zonly: boolean };
  } | null;
};

export type GetProductInquiryListVariables = Exact<{
  input: CatalogQuestionListInput;
}>;

export type GetProductInquiryList = {
  catalog_question_list: {
    item_list: Array<{
      id: string;
      date_created: number;
      contents: string;
      answered_date_at?: number | null;
      answer?: { date_created: number; contents: string } | null;
      shop: { name: string };
      catalog_product: {
        name: string;
        product_type: CatalogProductType;
        product_image_list: Array<{ image_url: string; image_type: CatalogProductImageType }>;
      };
    }>;
  };
};

export type DeleteCatalogProductQnaVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCatalogProductQna = { deleteCatalogQuestion: boolean };

export type GetOrderItemRequestInfoVariables = Exact<{
  order_number: Scalars['String'];
  order_item_request_number: Scalars['String'];
}>;

export type GetOrderItemRequestInfo = {
  order_item_list: {
    item_list: Array<{
      shipping_group_id: string;
      order_item_number: string;
      country: CountryType;
      status: OrderItemStatus;
      quantity: number;
      total_amount: number;
      total_product_price: number;
      date_refunded?: number | null;
      shipping_group: {
        shipping_address?: {
          return_address: {
            postcode: string;
            address: string;
            address_detail?: string | null;
            shipping_company?: string | null;
          };
        } | null;
      };
      receiver: { postcode: string; address1: string; address2?: string | null };
      order_promotion_item?: {
        promotion_applied_discount_price: number;
        promotion_applied_item_discount_price: number;
        order_promotion_item_group_id?: string | null;
        order_promotion: {
          promotion_id: string;
          promotion_title: string;
          promotion_type: PromotionType;
          total_promotion_price: number;
          min_required_quantity?: number | null;
          applied_quantity?: number | null;
          min_required_amount?: number | null;
        };
      } | null;
      order_item_product: {
        option_type?: OrderItemProductOptionType | null;
        option_detail_list?: Array<{ name: string; value: string }> | null;
        product_detail_info?: {
          minimum_order_quantity?: number | null;
          minimum_order_quantity_type: MinimumOrderQuantityType;
        } | null;
      };
      product_info: { name: string; image_url: string; price_delta: number; options: string };
      preorder?: { date_start: number; date_end: number; date_shipping: number } | null;
      request_list: Array<{
        order_item_request_number?: string | null;
        type: OrderItemRequestType;
        status: OrderItemRequestStatus;
        date_requested: number;
        date_completed?: number | null;
        requested_reason_category: RequestedReasonCategory;
        requested_reason?: string | null;
        withdrawn_reason?: string | null;
        date_marked_return_collecting?: number | null;
        date_marked_return_collected?: number | null;
        date_marked_exchange_collecting?: number | null;
        date_marked_exchange_collected?: number | null;
        date_withdrawn?: number | null;
        collecting_type?: CollectingType | null;
        payment_uuid_at_request?: string | null;
        requested_international_order_shipping_fee_total_amount?: number | null;
        withdrawn_account_info?: { account_type?: OrderAccountType | null } | null;
        active_claim_item_defer?: { date_created: number; deferred_reason?: string | null } | null;
        order_item_request_reason_list: Array<{
          order_item_request_status: OrderItemRequestStatus;
          reason?: string | null;
          reason_category: RequestedReasonCategory;
          date_created: number;
          account_info: { account_type?: OrderAccountType | null };
        }>;
        refund_payment?: {
          method?: RefundPaymentMethod | null;
          refund_bank_account?: { code?: string | null; number?: string | null; holder?: string | null } | null;
        } | null;
        refunded_international_order_shipping_fee_amounts?: {
          total_amount: number;
          cash_amount: number;
          point_amount: number;
        } | null;
        shipping_fee_additional_charge_method?: {
          initial?: ShippingFeeChargeMethod | null;
          exchange?: ShippingFeeChargeMethod | null;
          return?: ShippingFeeChargeMethod | null;
        } | null;
        shipping_group_refund?: {
          revoked_discount_amount: number;
          refunded_amounts: { total_amount: number; cash_amount: number; point_amount: number };
          requested_amounts: { total_amount: number; items_amount: number; shipping_fee: number };
          deduction_cost: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          deduction_shipping_fee_detail?: {
            initial_shipping_fee_cash_amount: number;
            initial_shipping_fee_point_amount: number;
            return_shipping_fee_cash_amount: number;
            return_shipping_fee_point_amount: number;
          } | null;
          enclose_amount: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          expired_amounts: { point_amount: number };
          order_discount_revoked_list: Array<{
            revoked_discount_amount: number;
            order_discount: {
              user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
            };
          }>;
        } | null;
        history_list: Array<{
          shipping_fee_additional_charge_method?: {
            initial?: ShippingFeeChargeMethod | null;
            return?: ShippingFeeChargeMethod | null;
          } | null;
        }>;
        active_defer?: { date_created: number } | null;
        recent_defer?: { date_created: number; date_withdrawn?: number | null } | null;
        attachment_list: Array<{ id: string; original_url: string; thumbnail_url: string }>;
      }>;
      active_request?: {
        order_item_request_number?: string | null;
        type: OrderItemRequestType;
        status: OrderItemRequestStatus;
        date_requested: number;
        date_completed?: number | null;
        requested_reason_category: RequestedReasonCategory;
        requested_reason?: string | null;
        withdrawn_reason?: string | null;
        date_marked_return_collecting?: number | null;
        date_marked_return_collected?: number | null;
        date_marked_exchange_collecting?: number | null;
        date_marked_exchange_collected?: number | null;
        date_withdrawn?: number | null;
        collecting_type?: CollectingType | null;
        payment_uuid_at_request?: string | null;
        requested_international_order_shipping_fee_total_amount?: number | null;
        withdrawn_account_info?: { account_type?: OrderAccountType | null } | null;
        active_claim_item_defer?: { date_created: number; deferred_reason?: string | null } | null;
        order_item_request_reason_list: Array<{
          order_item_request_status: OrderItemRequestStatus;
          reason?: string | null;
          reason_category: RequestedReasonCategory;
          date_created: number;
          account_info: { account_type?: OrderAccountType | null };
        }>;
        refund_payment?: {
          method?: RefundPaymentMethod | null;
          refund_bank_account?: { code?: string | null; number?: string | null; holder?: string | null } | null;
        } | null;
        refunded_international_order_shipping_fee_amounts?: {
          total_amount: number;
          cash_amount: number;
          point_amount: number;
        } | null;
        shipping_fee_additional_charge_method?: {
          initial?: ShippingFeeChargeMethod | null;
          exchange?: ShippingFeeChargeMethod | null;
          return?: ShippingFeeChargeMethod | null;
        } | null;
        shipping_group_refund?: {
          revoked_discount_amount: number;
          refunded_amounts: { total_amount: number; cash_amount: number; point_amount: number };
          requested_amounts: { total_amount: number; items_amount: number; shipping_fee: number };
          deduction_cost: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          deduction_shipping_fee_detail?: {
            initial_shipping_fee_cash_amount: number;
            initial_shipping_fee_point_amount: number;
            return_shipping_fee_cash_amount: number;
            return_shipping_fee_point_amount: number;
          } | null;
          enclose_amount: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          expired_amounts: { point_amount: number };
          order_discount_revoked_list: Array<{
            revoked_discount_amount: number;
            order_discount: {
              user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
            };
          }>;
        } | null;
        history_list: Array<{
          shipping_fee_additional_charge_method?: {
            initial?: ShippingFeeChargeMethod | null;
            return?: ShippingFeeChargeMethod | null;
          } | null;
        }>;
        active_defer?: { date_created: number } | null;
        recent_defer?: { date_created: number; date_withdrawn?: number | null } | null;
        attachment_list: Array<{ id: string; original_url: string; thumbnail_url: string }>;
      } | null;
      shop_shipping_user_extra_payment?: {
        bank_code: string;
        bank_account_holder: string;
        bank_account_number: string;
      } | null;
      shop: {
        name: string;
        state: number;
        typical_image_url?: string | null;
        customer_center_tel?: string | null;
        is_zonly: boolean;
      };
    }>;
  };
  order?: {
    payment_method: PaymentMethod;
    pay_info?: any | null;
    version: OrderVersionType;
    country: CountryType;
    international_order_shipping_fee_list: Array<{ amount: number }>;
  } | null;
};

export type GetPaymentVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetPayment = {
  payment: {
    deposit_refund?: { status: DepositRefundStatus } | null;
    refund_bank_account?: { holder?: string | null; number?: string | null; code?: string | null } | null;
  };
};

export type GetOrderItemExchangeRequestInfoVariables = Exact<{
  order_number: Scalars['String'];
  order_item_request_number: Scalars['String'];
  exclude_user_withdrawn_request?: InputMaybe<Scalars['Boolean']>;
  include_withdrawn_request: Scalars['Boolean'];
}>;

export type GetOrderItemExchangeRequestInfo = {
  order_item_list: {
    item_list: Array<{
      shipping_group_id: string;
      order_item_number: string;
      country: CountryType;
      status: OrderItemStatus;
      quantity: number;
      total_amount: number;
      shipping_memo?: string | null;
      shipping_group: {
        shipping_address?: {
          return_address: {
            postcode: string;
            address: string;
            address_detail?: string | null;
            shipping_company?: string | null;
          };
        } | null;
      };
      order_promotion_item?: {
        promotion_applied_discount_price: number;
        promotion_applied_item_discount_price: number;
        order_promotion_item_group_id?: string | null;
        order_promotion: {
          promotion_id: string;
          promotion_title: string;
          promotion_type: PromotionType;
          total_promotion_price: number;
          min_required_quantity?: number | null;
          applied_quantity?: number | null;
          min_required_amount?: number | null;
        };
      } | null;
      order_item_product: {
        option_type?: OrderItemProductOptionType | null;
        option_detail_list?: Array<{ name: string; value: string }> | null;
        product_detail_info?: {
          minimum_order_quantity?: number | null;
          minimum_order_quantity_type: MinimumOrderQuantityType;
        } | null;
      };
      exchange_new_order_item?: {
        order_item_number: string;
        status: OrderItemStatus;
        shipping_memo?: string | null;
      } | null;
      receiver: { postcode: string; address1: string; address2?: string | null };
      preorder?: { date_start: number; date_end: number; date_shipping: number } | null;
      product_info: { name: string; image_url: string; price_delta: number; options: string };
      request_list: Array<{
        extra_additional_fee_charge_method?: ExtraAdditionalFeeChargeMethod | null;
        order_item_request_number?: string | null;
        type: OrderItemRequestType;
        status: OrderItemRequestStatus;
        date_requested: number;
        date_completed?: number | null;
        requested_reason_category: RequestedReasonCategory;
        requested_reason?: string | null;
        withdrawn_reason?: string | null;
        date_marked_return_collecting?: number | null;
        date_marked_return_collected?: number | null;
        date_marked_exchange_collecting?: number | null;
        date_marked_exchange_collected?: number | null;
        date_withdrawn?: number | null;
        collecting_type?: CollectingType | null;
        payment_uuid_at_request?: string | null;
        requested_international_order_shipping_fee_total_amount?: number | null;
        exchange_amounts_info?: {
          total_amount: number;
          exchange_order_item_price_difference_info: {
            previous_exchange_item_total_price: number;
            new_exchange_item_total_price: number;
            total_option_price_delta_difference: number;
          };
          exchange_shipping_fee_info: {
            exchange_shipping_fee: number;
            exchange_extra_shipping_fee: { exchange_previous_item_return: number; exchange_new_item_shipment: number };
          };
        } | null;
        exchange_request_info?: {
          quantity: number;
          processed_total_amount: number;
          shipping_memo?: string | null;
          product_info: {
            name: string;
            image_url: string;
            price_delta: number;
            options: string;
            option_detail_list?: Array<{ name: string; value: string }> | null;
          };
          receiver: { name: string; mobile_tel: string; postcode: string; address1: string; address2?: string | null };
        } | null;
        withdrawn_account_info?: { account_type?: OrderAccountType | null } | null;
        active_claim_item_defer?: { date_created: number; deferred_reason?: string | null } | null;
        order_item_request_reason_list: Array<{
          order_item_request_status: OrderItemRequestStatus;
          reason?: string | null;
          reason_category: RequestedReasonCategory;
          date_created: number;
          account_info: { account_type?: OrderAccountType | null };
        }>;
        refund_payment?: {
          method?: RefundPaymentMethod | null;
          refund_bank_account?: { code?: string | null; number?: string | null; holder?: string | null } | null;
        } | null;
        refunded_international_order_shipping_fee_amounts?: {
          total_amount: number;
          cash_amount: number;
          point_amount: number;
        } | null;
        shipping_fee_additional_charge_method?: {
          initial?: ShippingFeeChargeMethod | null;
          exchange?: ShippingFeeChargeMethod | null;
          return?: ShippingFeeChargeMethod | null;
        } | null;
        shipping_group_refund?: {
          revoked_discount_amount: number;
          refunded_amounts: { total_amount: number; cash_amount: number; point_amount: number };
          requested_amounts: { total_amount: number; items_amount: number; shipping_fee: number };
          deduction_cost: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          deduction_shipping_fee_detail?: {
            initial_shipping_fee_cash_amount: number;
            initial_shipping_fee_point_amount: number;
            return_shipping_fee_cash_amount: number;
            return_shipping_fee_point_amount: number;
          } | null;
          enclose_amount: {
            free_shipping_breaking_fee: number;
            return_shipping_fee: number;
            initial_shipping_fee: number;
            total_fee: number;
          };
          expired_amounts: { point_amount: number };
          order_discount_revoked_list: Array<{
            revoked_discount_amount: number;
            order_discount: {
              user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
            };
          }>;
        } | null;
        history_list: Array<{
          shipping_fee_additional_charge_method?: {
            initial?: ShippingFeeChargeMethod | null;
            return?: ShippingFeeChargeMethod | null;
          } | null;
        }>;
        active_defer?: { date_created: number } | null;
        recent_defer?: { date_created: number; date_withdrawn?: number | null } | null;
        attachment_list: Array<{ id: string; original_url: string; thumbnail_url: string }>;
      }>;
      shop_shipping_user_extra_payment?: {
        bank_code: string;
        bank_account_holder: string;
        bank_account_number: string;
      } | null;
      shop: {
        name: string;
        state: number;
        typical_image_url?: string | null;
        customer_center_tel?: string | null;
        is_zonly: boolean;
      };
    }>;
  };
};

export type GetWithdrawnOrderItemExchangeRequestInfoVariables = Exact<{
  order_item_number_list?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  order_item_request_number: Scalars['String'];
}>;

export type GetWithdrawnOrderItemExchangeRequestInfo = {
  order_item_list: {
    item_list: Array<{ request_list: Array<{ withdrawn_reason?: string | null; date_withdrawn?: number | null }> }>;
  };
};

export type UpdateReceiverExchangeRequestInfoVariables = Exact<{
  input: UpdateReceiverExchangeRequestInfoInput;
}>;

export type UpdateReceiverExchangeRequestInfo = { updateReceiverExchangeRequestInfo: boolean };

export type GetEstimatedRefundPointVariables = Exact<{
  point: Scalars['Int'];
  refund_responsibility?: InputMaybe<UserAccountPointRefundResponsibility>;
  target_uid: Scalars['String'];
  type: UserAccountPointType;
}>;

export type GetEstimatedRefundPoint = {
  user_account_refund_point_info: { total_refund_point: number; expire_refund_point: number };
};

export type GetReturnOrderItemRequestWithShippingTrackingDetailHistoryVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetReturnOrderItemRequestWithShippingTrackingDetailHistory = {
  order_item?: {
    shop_name: string;
    receiver: { name: string };
    active_request?: {
      type: OrderItemRequestType;
      status: OrderItemRequestStatus;
      order_item_request_number?: string | null;
      shipping_company?: string | null;
      invoice_number?: string | null;
      shipping_tracking_id?: string | null;
      return_shipping_tracking_detail_history_list: {
        item_list: Array<{
          sequence_number: number;
          date_processed: number;
          brand_name: string;
          status: string;
          delivery_driver_name?: string | null;
          delivery_driver_phone_number?: string | null;
        }>;
      };
    } | null;
  } | null;
};

export type GetOrderItemWithShippingTrackingDetailHistoryVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetOrderItemWithShippingTrackingDetailHistory = {
  order_item?: {
    shop_name: string;
    shipping_company?: string | null;
    invoice_number?: string | null;
    status: OrderItemStatus;
    shipping_tracking_detail_history_list: {
      success: boolean;
      item_list: Array<{
        sequence_number: number;
        date_processed: number;
        brand_name: string;
        status: string;
        delivery_driver_name?: string | null;
        delivery_driver_phone_number?: string | null;
      }>;
    };
  } | null;
};

export type GetOrderItemShippingDeferVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetOrderItemShippingDefer = {
  order_item?: {
    recent_defer?: {
      date_created: number;
      deferred_reason: string;
      date_withdrawn?: number | null;
      withdrawn_reason?: string | null;
    } | null;
  } | null;
};

export type GetOrderShippingScheduleDelayInfoVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetOrderShippingScheduleDelayInfo = {
  order_item?: {
    due_date_in_transit?: number | null;
    shipping_schedule_delay_info: {
      recent_delay?: {
        delay_reason?: string | null;
        delay_reason_category: OrderItemDelayReasonCategory;
        date_scheduled_ymd: number;
      } | null;
    };
    shop: { customer_center_tel?: string | null };
    order_item_date_trace: { date_expected_in_transit?: number | null };
  } | null;
};

export type CheckBankAccountVariables = Exact<{
  bank_code: Scalars['String'];
  bank_account_number: Scalars['String'];
  bank_account_holder: Scalars['String'];
}>;

export type CheckBankAccount = { checkBankAccount: boolean };

export type GetPaymentMethodInformationListVariables = Exact<{ [key: string]: never }>;

export type GetPaymentMethodInformationList = {
  payment_method_information_list: {
    item_list: Array<{ payment_method: PaymentMethod; is_disabled: boolean; disabled_message?: string | null }>;
  };
};

export type GetPaymentEventListVariables = Exact<{ [key: string]: never }>;

export type GetPaymentEventList = {
  payment_event_list: {
    item_list: Array<{
      payment_method: PaymentMethod;
      financial_company_code?: FinancialCompanyCode | null;
      event_type: VendorEventType;
      min_amount: number;
      discount_amount: number;
    }>;
  };
};

export type GetPaymentFinancialCompanyListVariables = Exact<{ [key: string]: never }>;

export type GetPaymentFinancialCompanyList = {
  payment_financial_company_list: {
    item_list: Array<{
      payment_method: PaymentMethod;
      financial_company_code: FinancialCompanyCode;
      max_installment_month: number;
      interest_free_list: Array<{
        financial_company_code: FinancialCompanyCode;
        installment_month_list: Array<number>;
        min_amount: number;
      }>;
      payment_financial_company_maintenance_list: Array<{ is_active: boolean }>;
    }>;
  };
};

export type GetPaymentBadgeListVariables = Exact<{ [key: string]: never }>;

export type GetPaymentBadgeList = {
  payment_badge_list: {
    item_list: Array<{
      payment_method: PaymentMethod;
      payment_badge_content_list: Array<{ content: string; language: string }>;
    }>;
  };
};

export type GetPaymentNotificationListVariables = Exact<{ [key: string]: never }>;

export type GetPaymentNotificationList = {
  payment_notification_list: {
    item_list: Array<{
      id: string;
      payment_method: PaymentMethod;
      notification_type: PaymentNotificationType;
      financial_company_code?: FinancialCompanyCode | null;
      payment_notification_content_list: Array<{ content: string; language: string }>;
    }>;
  };
};

export type CreateOrderSheetVariables = Exact<{
  input: CreateOrderSheetInput;
}>;

export type CreateOrderSheet = { createOrderSheet: string };

export type GetOrderSheetVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetOrderSheet = {
  order_sheet?: {
    uuid: string;
    total_item_amount: number;
    total_shipping_fee: number;
    selected_user_account_coupon_id_list?: Array<string> | null;
    product_review_issue_point_maximum: number;
    order_shop_list: Array<{
      total_item_amount: number;
      total_shipping_fee: number;
      shop: { id: string; name: string; main_domain: string; typical_image_url?: string | null; is_zonly: boolean };
      shipping_group_list: Array<{
        total_item_amount: number;
        total_shipping_fee: number;
        shipping_info: {
          shipping_type: ShippingType;
          shipping_fee?: number | null;
          extra_shipping_fee2?: number | null;
          extra_shipping_fee3?: number | null;
          free_shipping_amount: number;
        };
        order_item_list: Array<{
          total_amount: number;
          quantity: number;
          free_shipping: boolean;
          product_item_id: string;
          is_zigzag_mate: boolean;
          due_date_in_transit: number;
          cart_item_hash?: string | null;
          is_fast_delivery: boolean;
          additional_option_detail_list?: Array<{
            name: string;
            option_code?: string | null;
            value: string;
            value_code?: string | null;
          }> | null;
          product_info: {
            id: string;
            product_no?: string | null;
            price: number;
            image_url: string;
            name: string;
            options: string;
            price_delta: number;
            preorder?: { date_start: number; date_end: number; reserved_date_in_transit: number } | null;
          };
          item_final_price?: {
            product_original_price: number;
            item_discount_price: number;
            item_promotion_discount_price?: number | null;
            promotion_price?: number | null;
            final_price: number;
            final_discount_amount: number;
            final_discount_rate_bp: number;
          } | null;
          origin_shop?: { id: string; name: string } | null;
        }>;
      }>;
    }>;
    order_sheet_event: { html: string; pay_type: Array<string> };
    user_account?: {
      email: string;
      full_name?: string | null;
      mobile_tel?: string | null;
      authenticated?: boolean | null;
    } | null;
  } | null;
};

export type AuthorizeMobileTokenVariables = Exact<{
  input: AuthorizeMobileTokenInput;
}>;

export type AuthorizeMobileToken = { authorizeMobileToken: boolean };

export type CreateCheckoutVariables = Exact<{
  input: CreateCheckoutInput;
}>;

export type CreateCheckout = { createCheckout: string };

export type RequestUserPayVariables = Exact<{
  input: RequestUserPayInput;
}>;

export type RequestUserPay = { requestUserPay: { order_number: string; redirect_url: string } };

export type GetShippingRegionNoVariables = Exact<{
  postcode: Scalars['String'];
}>;

export type GetShippingRegionNo = { shipping_region?: { region_no: number } | null };

export type GetCouponAvailableStatusVariables = Exact<{
  shop_id: Scalars['ID'];
  product_id: Scalars['ID'];
}>;

export type GetCouponAvailableStatus = { coupon_available_status: CouponAvailableStatus };

export type GetUserAccountAvailablePointVariables = Exact<{ [key: string]: never }>;

export type GetUserAccountAvailablePoint = { user_account_point_info: { available_point_of_use: number } };

export type GetZonlyOneDayShippingInfoVariables = Exact<{
  product_Ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  postcode?: InputMaybe<Scalars['String']>;
}>;

export type GetZonlyOneDayShippingInfo = {
  zonly_estimate_shipping_info_with_postcode?: Array<{
    estimate_content: string;
    estimate_timestamp: number;
    arrival_date_text: string;
    arrival_one_day_text?: string | null;
    arrival_date_title_text?: string | null;
    arrival_date_except_title_text?: string | null;
    arrival_one_day_title_text?: string | null;
    product_id?: string | null;
  }> | null;
};

export type GetNotTransferredOrderVariables = Exact<{
  order_number: Scalars['String'];
}>;

export type GetNotTransferredOrder = {
  order?: {
    order_number: string;
    payment_method: PaymentMethod;
    payment_status: PaymentStatus;
    points_used: number;
    total_payment_amount: number;
    order_item_list: Array<{
      shop_name: string;
      quantity: number;
      total_amount: number;
      product_info: { name: string; image_url: string; price: number; price_delta: number; options: string };
    }>;
  } | null;
};

export type CancelNotTransferredOrderVariables = Exact<{
  input: CancelNotTransferredOrderInput;
}>;

export type CancelNotTransferredOrder = { cancelNotTransferredOrder: boolean };

export type GetOrderItemListForConfirmVariables = Exact<{
  order_number: Scalars['String'];
}>;

export type GetOrderItemListForConfirm = {
  order_item_list: {
    item_list: Array<{
      order_item_number: string;
      confirmable: boolean;
      quantity: number;
      total_amount: number;
      product_info: { image_url: string; name: string; price_delta: number; options: string };
      shop: { id: string; name: string };
      product_review_create_info: { point_amounts: { maximum_point: number } };
    }>;
  };
};

export type ConfirmOrderItemListAndIssueRepurchaseCouponListVariables = Exact<{
  input: ConfirmOrderItemListAndIssueRepurchaseCouponListInput;
}>;

export type ConfirmOrderItemListAndIssueRepurchaseCouponList = {
  confirmOrderItemListAndIssueRepurchaseCouponList: { succeed: boolean; issued_coupon_shop_id_list: Array<string> };
};

export type UpdateOrderReceiverVariables = Exact<{
  input: UpdateOrderReceiverV2Input;
}>;

export type UpdateOrderReceiver = { updateOrderReceiver: boolean };

export type GetReceiverByOrderNumberVariables = Exact<{
  order_number: Scalars['String'];
}>;

export type GetReceiverByOrderNumber = {
  order?: {
    shipping_memo?: string | null;
    order_receiver?: {
      first_name: string;
      last_name: string;
      contract_number: string;
      country_number: string;
      postcode: string;
      address: string;
      detail_address?: string | null;
      city?: string | null;
      state?: string | null;
      memo?: string | null;
      masked_name: string;
      masked_mobile_tel: string;
      masked_address: string;
      masked_detail_address?: string | null;
      masked_personal_clearance_code?: string | null;
    } | null;
  } | null;
};

export type GetEstimatedShippingGroupRefundAmountsVariables = Exact<{
  shipping_group_id: Scalars['ID'];
  order_number: Scalars['String'];
  order_item_number_list: Array<Scalars['String']> | Scalars['String'];
  refund_responsibility: OrderRefundResponsibility;
  shipping_fee_additional_charge_method: ShippingFeeAdditionalChargeMethodInput;
}>;

export type GetEstimatedShippingGroupRefundAmounts = {
  estimated_shipping_group_refund_amounts: {
    revoked_discount_amount: number;
    estimated_refund_amounts: { total_amount: number; cash_amount: number; point_amount: number };
    requested_amounts: { total_amount: number; items_amount: number; shipping_fee: number };
    deduction_cost: {
      free_shipping_breaking_fee: number;
      return_shipping_fee: number;
      initial_shipping_fee: number;
      total_fee: number;
    };
    enclose_amount: {
      free_shipping_breaking_fee: number;
      return_shipping_fee: number;
      initial_shipping_fee: number;
      total_fee: number;
    };
    order_discount_revoked_list: Array<{
      revoked_discount_amount: number;
      order_discount: {
        user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
      };
    }>;
    deduction_shipping_fee_detail?: {
      initial_shipping_fee_cash_amount: number;
      initial_shipping_fee_point_amount: number;
      return_shipping_fee_cash_amount: number;
      return_shipping_fee_point_amount: number;
    } | null;
  };
};

export type GetShippingGroupListVariables = Exact<{
  order_number?: InputMaybe<Scalars['String']>;
}>;

export type GetShippingGroupList = {
  shipping_group_list: {
    item_list: Array<{
      total_shipping_fee: number;
      order: {
        country: CountryType;
        order_number: string;
        date_created: number;
        payment_status: PaymentStatus;
        payment_method: PaymentMethod;
        available_to_user_hidden: boolean;
        points_used: number;
        total_item_amount: number;
        total_shipping_fee: number;
        domestic_shipping_fee?: number | null;
        international_shipping_fee?: number | null;
        total_discount_item_amount: number;
        total_discount_shipping_fee: number;
        total_payment_amount: number;
        shipping_memo?: string | null;
        pay_info?: any | null;
        remaining_cash_amount: number;
        remaining_point_amount: number;
        international_order_shipping_fee_list: Array<{ amount: number }>;
        order_discount_list: Array<{
          discount_amount: number;
          remaining_discount_amount: number;
          user_account_coupon_info: { target_type: CouponTargetType; code?: string | null };
        }>;
        order_receiver?: {
          first_name: string;
          last_name: string;
          contract_number: string;
          country_number: string;
          postcode: string;
          address: string;
          detail_address?: string | null;
          city?: string | null;
          state?: string | null;
          memo?: string | null;
          masked_name: string;
          masked_mobile_tel: string;
          masked_address: string;
          masked_detail_address?: string | null;
          masked_personal_clearance_code?: string | null;
        } | null;
        vbank_pay_info?: {
          bank_name: string;
          number: string;
          holder: string;
          due_date_ymd: number;
          amount: number;
        } | null;
      };
      order_item_list: Array<{
        country: CountryType;
        shipping_group_id: string;
        order_item_number: string;
        status: OrderItemStatus;
        confirmable: boolean;
        total_amount: number;
        total_product_price: number;
        quantity: number;
        browsing_type: BrowsingType;
        shop_id: string;
        product_id: string;
        pdp_landing_url: string;
        due_date_in_transit?: number | null;
        is_zigzag_mate: boolean;
        available_request_type_list: Array<OrderItemRequestType>;
        shipping_company?: string | null;
        invoice_number?: string | null;
        is_fast_delivery: boolean;
        order_promotion_item?: {
          promotion_applied_discount_price: number;
          promotion_applied_item_discount_price: number;
          order_promotion: {
            promotion_id: string;
            promotion_title: string;
            promotion_type: PromotionType;
            total_promotion_price: number;
            min_required_quantity?: number | null;
            applied_quantity?: number | null;
            min_required_amount?: number | null;
          };
        } | null;
        zonly_info?: {
          origin_shop_id: string;
          origin_shop_name: string;
          origin_shop?: { id: string; main_domain: string; url: string } | null;
        } | null;
        product_info: {
          product_no?: string | null;
          name: string;
          image_url: string;
          url: string;
          price_delta: number;
          options: string;
        };
        preorder?: { date_start: number; date_end: number; date_shipping: number } | null;
        shop: {
          state: number;
          name: string;
          main_domain: string;
          url: string;
          typical_image_url?: string | null;
          customer_center_tel?: string | null;
          is_zonly: boolean;
        };
        request_list: Array<{
          order_item_request_number?: string | null;
          type: OrderItemRequestType;
          date_withdrawn?: number | null;
        }>;
        active_request?: {
          type: OrderItemRequestType;
          status: OrderItemRequestStatus;
          order_item_request_number?: string | null;
          requested_reason_category: RequestedReasonCategory;
          shipping_company?: string | null;
          invoice_number?: string | null;
          shipping_fee_additional_charge_method?: {
            initial?: ShippingFeeChargeMethod | null;
            exchange?: ShippingFeeChargeMethod | null;
            return?: ShippingFeeChargeMethod | null;
          } | null;
          shipping_group_refund?: { date_refunded: number } | null;
          active_defer?: { date_created: number } | null;
          exchange_request_info?: {
            quantity: number;
            processed_total_amount: number;
            product_info: { name: string; image_url: string; url: string; price_delta: number; options: string };
          } | null;
        } | null;
        exchange_new_order_item?: {
          order_item_number: string;
          status: OrderItemStatus;
          shipping_company?: string | null;
          invoice_number?: string | null;
          confirmable: boolean;
        } | null;
        last_request_number_withdrawn_by_seller_per_type: { exchange?: string | null };
        shipping_schedule_delay_info: {
          recent_delay?: {
            delay_reason_category: OrderItemDelayReasonCategory;
            delay_reason?: string | null;
            date_scheduled_ymd: number;
          } | null;
        };
        recent_reject_request?: { type: OrderItemRequestType; withdrawn_reason?: string | null } | null;
        order_item_product: {
          option_type?: OrderItemProductOptionType | null;
          option_detail_list?: Array<{ name: string; value: string }> | null;
          product_detail_info?: {
            minimum_order_quantity?: number | null;
            minimum_order_quantity_type: MinimumOrderQuantityType;
          } | null;
        };
      }>;
      shipping_group_refund_list: Array<{
        revoked_discount_amount: number;
        promotion_applied_discount_price?: number | null;
        refunded_amounts: { total_amount: number; cash_amount: number; point_amount: number };
        requested_amounts: {
          total_amount: number;
          items_amount: number;
          shipping_fee: number;
          total_product_price: number;
        };
        expired_amounts: { point_amount: number; mileage_amount: number };
        deduction_cost: {
          free_shipping_breaking_fee: number;
          return_shipping_fee: number;
          initial_shipping_fee: number;
          total_fee: number;
        };
        deduction_shipping_fee_detail?: {
          initial_shipping_fee_cash_amount: number;
          initial_shipping_fee_point_amount: number;
          return_shipping_fee_cash_amount: number;
          return_shipping_fee_point_amount: number;
        } | null;
        enclose_amount: {
          free_shipping_breaking_fee: number;
          return_shipping_fee: number;
          initial_shipping_fee: number;
          total_fee: number;
        };
        order_discount_revoked_list: Array<{
          revoked_discount_amount: number;
          order_discount: {
            user_account_coupon_info: { target_type: CouponTargetType; title: string; code?: string | null };
          };
        }>;
      }>;
    }>;
  };
};

export type GetShippingGroupListForClaimVariables = Exact<{
  order_number?: InputMaybe<Scalars['String']>;
  shipping_group_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetShippingGroupListForClaim = {
  shipping_group_list: {
    item_list: Array<{
      remaining_item_amount: number;
      remaining_shipping_fee: number;
      total_item_amount: number;
      shipping_info: {
        free_shipping_amount?: number | null;
        shipping_fee: number;
        extra_shipping_fee: number;
        exchange_shipping_fee?: number | null;
      };
      order: {
        order_number: string;
        refund_bank_account_required: boolean;
        total_item_amount: number;
        remaining_cash_amount: number;
        payment_method: PaymentMethod;
        pay_info?: any | null;
        version: OrderVersionType;
        country: CountryType;
        order_receiver?: {
          first_name: string;
          last_name: string;
          contract_number: string;
          country_number: string;
          postcode: string;
          address: string;
          detail_address?: string | null;
          city?: string | null;
          state?: string | null;
          memo?: string | null;
          masked_name: string;
          masked_mobile_tel: string;
          masked_address: string;
          masked_detail_address?: string | null;
        } | null;
        order_promotion_list: Array<{ min_required_amount?: number | null; promotion_type: PromotionType }>;
      };
      order_item_list: Array<{
        shipping_group_id: string;
        order_item_number: string;
        quantity: number;
        total_amount: number;
        total_product_price: number;
        available_request_type_list: Array<OrderItemRequestType>;
        due_date_in_transit?: number | null;
        product_id: string;
        is_zigzag_mate: boolean;
        shipping_group: {
          area_fee: { jeju: number; isolated: number };
          shipping_address?: {
            return_address: {
              postcode: string;
              address: string;
              address_detail?: string | null;
              shipping_company?: string | null;
            };
          } | null;
        };
        shop: {
          id: string;
          name: string;
          state: number;
          main_domain: string;
          customer_center_tel?: string | null;
          is_zonly: boolean;
        };
        order_promotion_item?: {
          promotion_applied_discount_price: number;
          promotion_applied_item_discount_price: number;
          order_promotion: {
            promotion_id: string;
            promotion_title: string;
            promotion_type: PromotionType;
            total_promotion_price: number;
            min_required_quantity?: number | null;
            applied_quantity?: number | null;
            min_required_amount?: number | null;
          };
        } | null;
        product_info: {
          product_no?: string | null;
          name: string;
          image_url: string;
          price: number;
          price_delta: number;
          options: string;
        };
        product_category?: { category_full_name: string; id: string } | null;
        shipping_schedule_delay_info: {
          recent_delay?: {
            delay_reason_category: OrderItemDelayReasonCategory;
            delay_reason?: string | null;
            date_scheduled_ymd: number;
          } | null;
        };
        order_item_date_trace: { date_expected_in_transit?: number | null };
        shop_shipping_user_extra_payment?: {
          bank_code: string;
          bank_account_holder: string;
          bank_account_number: string;
        } | null;
        preorder?: { date_start: number; date_end: number; date_shipping: number } | null;
        order_item_product: {
          option_type?: OrderItemProductOptionType | null;
          option_detail_list?: Array<{ name: string; value: string }> | null;
          product_detail_info?: {
            trait_list: Array<string>;
            minimum_order_quantity?: number | null;
            minimum_order_quantity_type: MinimumOrderQuantityType;
          } | null;
        };
      }>;
    }>;
  };
  user_refund_account?: { bank_code: string; account_number: string; account_holder: string } | null;
  user_account?: { full_name?: string | null; term_agreement_list: Array<{ term_type: UserAccountTermType }> } | null;
};

export type DeleteOrderVariables = Exact<{
  input: DeleteOrderInput;
}>;

export type DeleteOrder = { deleteOrder: boolean };

export type WithdrawOrderItemRequestListVariables = Exact<{
  input: WithdrawOrderItemRequestListInput;
}>;

export type WithdrawOrderItemRequestList = { withdrawOrderItemRequestList: boolean };

export type UserRequestCancelAndRefundOrderItemListVariables = Exact<{
  input: UserRequestCancelAndRefundOrderItemListInput;
}>;

export type UserRequestCancelAndRefundOrderItemList = { userRequestCancelAndRefundOrderItemList: boolean };

export type RequestExchangeOrderItemListVariables = Exact<{
  input: RequestExchangeOrderItemListInput;
}>;

export type RequestExchangeOrderItemList = { requestExchangeOrderItemList: boolean };

export type GetProductListVariables = Exact<{
  shop_main_domain?: InputMaybe<Scalars['String']>;
  product_no_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetProductList = {
  exchange_product_list: {
    products: Array<{
      id: string;
      product_no: string;
      option_type: ProductOptionType;
      option_list: Array<{
        name: string;
        code: string;
        is_required: boolean;
        value_set: Array<{ code: string; name: string; additional_price: number }>;
      }>;
      item_list: Array<{
        id: string;
        price: number;
        price_delta: number;
        status: ProductItemStatus;
        option_list: Array<{ name: string; value: string }>;
      }>;
      text_options: Array<{ name: string; is_required: boolean; max_length: number }>;
    }>;
  };
};

export type RequestReturnOrderVariables = Exact<{
  input: RequestReturnOrderInput;
}>;

export type RequestReturnOrder = { requestReturnOrder: boolean };

export type GetOrderItemReviewableListVariables = Exact<{
  order_number: Scalars['String'];
}>;

export type GetOrderItemReviewableList = {
  order_item_list: {
    item_list: Array<{
      order_item_number: string;
      product_review_create_info: { reviewable: boolean; point_amounts: { maximum_point: number } };
    }>;
  };
};

export type GetUserOrderListVariables = Exact<{
  status_list?: InputMaybe<Array<OrderItemStatus> | OrderItemStatus>;
  last_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetUserOrderList = {
  user_order_list: {
    has_next: boolean;
    item_list: Array<{
      id: string;
      order_number: string;
      date_created: number;
      order_item_list: Array<{
        status: OrderItemStatus;
        order_item_number: string;
        date_confirmed?: number | null;
        product_item_id: string;
        product_id: string;
        catalog_product_id: string;
        shop_id: string;
        shop_name: string;
        is_zonly: boolean;
        total_amount: number;
        quantity: number;
        pdp_landing_url: string;
        browsing_type: BrowsingType;
        is_fast_delivery: boolean;
        product_info: { image_url: string; name: string; options: string; product_no?: string | null };
      }>;
    }>;
  };
};

export type GetUserClaimListVariables = Exact<{
  status_list?: InputMaybe<Array<OrderItemRequestStatus> | OrderItemRequestStatus>;
  last_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetUserClaimList = {
  user_claim_list: {
    has_next: boolean;
    item_list: Array<{
      id: string;
      date_requested: number;
      order: { order_number: string };
      order_item: {
        status: OrderItemStatus;
        order_item_number: string;
        date_confirmed?: number | null;
        product_item_id: string;
        product_id: string;
        catalog_product_id: string;
        shop_id: string;
        shop_name: string;
        is_zonly: boolean;
        total_amount: number;
        quantity: number;
        pdp_landing_url: string;
        browsing_type: BrowsingType;
        is_fast_delivery: boolean;
        product_info: { image_url: string; name: string; options: string; product_no?: string | null };
      };
    }>;
  };
};

export type GetUserOrderCountsVariables = Exact<{ [key: string]: never }>;

export type GetUserOrderCounts = {
  ALL: { total_count: number };
  IN_TRANSIT: { total_count: number };
  SHIPPED: { total_count: number };
  CLAIM: { total_count: number };
};

export type GetShippingCompanyListVariables = Exact<{ [key: string]: never }>;

export type GetShippingCompanyList = {
  shipping_company_list: Array<{ code: string; name: string; en_name: string; ja_name: string }>;
};

export type GetFbkRecentVisitProductListVariables = Exact<{
  limit_count?: InputMaybe<Scalars['Int']>;
  date_from?: InputMaybe<Scalars['CrTimestamp']>;
  date_to?: InputMaybe<Scalars['CrTimestamp']>;
}>;

export type GetFbkRecentVisitProductList = {
  fbk_recent_visit_product_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      catalog_product_id: string;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      sales_status: string;
      badge_list: Array<{ type: string }>;
    }>;
  };
};

export type GetOrderItemForReviewVariables = Exact<{
  order_item_number: Scalars['String'];
}>;

export type GetOrderItemForReview = {
  order_item?: {
    order_item_number: string;
    shop_id: string;
    shop_name: string;
    quantity: number;
    total_amount: number;
    product_info: { image_url: string; name: string; options: string };
    product_review_create_info: {
      attribute_question_list: Array<string>;
      date_deadline: number;
      is_selling: boolean;
      product_reviews_count: number;
      notice_html?: string | null;
      description_html?: string | null;
      point_amounts: { normal: { text_only: number; with_photo: number } };
      attribute_option_list: Array<{
        question: { label: string; value: string };
        answer_list: Array<{ label: string; value: string }>;
      }>;
      attribute_image_url_list: Array<{ title: string; image_url: string }>;
      warning?: { title: string; message: string } | null;
      restriction?: { title: string; message: string } | null;
      user_account_body?: {
        height: { value: number; label: string };
        weight: { value: number; label: string };
        upper_size: { value: number; label: string };
        bottom_size: { value: number; label: string };
        shoe_size: { value: number; label: string };
      } | null;
      review_event?: {
        badge_title?: string | null;
        banner?: { image_url: string; image_ratio: number; description: string } | null;
      } | null;
    };
  } | null;
};

export type GetReviewIssuePointVariables = Exact<{
  shop_id: Scalars['ID'];
  product_id?: InputMaybe<Scalars['ID']>;
  item_price?: InputMaybe<Scalars['Int']>;
}>;

export type GetReviewIssuePoint = {
  product_review_issue_point_info: {
    maximum_point: number;
    estimated_point: {
      normal: { text_only: number; with_photo: number };
      first_review_bonus: { text_only: number; with_photo: number };
      review_bonus_point_group: {
        max_bonus_point: number;
        bonus_point_conditions: Array<{
          bonus_point: number;
          photo_bonus_point: number;
          min_length: number;
          max_length: number;
        }>;
      };
      review_point_info?: {
        title: string;
        base_point_description: { condition: string; description: string };
        bonus_point_description: Array<{ condition: string; description: string }>;
        exceptional_point_description: Array<{ condition: string; description: string }>;
      } | null;
    };
    review_event_point_list: Array<{ type: ProductReviewSellerEventType; max_point: number }>;
  };
};

export type CreateProductReviewVariables = Exact<{
  input: CreateProductReviewInput;
}>;

export type CreateProductReview = {
  createProductReview: { id: string; point_issued: number; is_first_review: boolean; type: ProductReviewType };
};

export type UpdateProductReviewVariables = Exact<{
  input: UpdateProductReviewInput;
}>;

export type UpdateProductReview = { updateProductReview: boolean };

export type CreateUploadImageLinkVariables = Exact<{
  input: CreateUploadLinkInput;
}>;

export type CreateUploadImageLink = {
  createUploadLink: { acl: string; url: string; cloudfront_url: string; key_prefix: string; fields: any };
};

export type GetUserReviewPenaltyVariables = Exact<{
  limit_count?: InputMaybe<Scalars['Int']>;
  only_mine?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUserReviewPenalty = {
  product_review_list: {
    item_list: Array<{
      user_account_info: {
        status: ProductReviewUserAccountInfoStatus;
        warning_count: number;
        date_penalty_lt?: number | null;
      };
    }>;
  };
};

export type GetImageSearchPresetProductsVariables = Exact<{ [key: string]: never }>;

export type GetImageSearchPresetProducts = {
  fbk_image_search_preset_product_list: { item_list: Array<{ catalog_product_id: string; image_url?: string | null }> };
};

export type SearchProductsByImageVariables = Exact<{
  content_type: Scalars['String'];
  content: Scalars['String'];
}>;

export type SearchProductsByImage = {
  fbk_image_search_product_list: {
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetAgeRecommendedKeywordInfoVariables = Exact<{ [key: string]: never }>;

export type GetAgeRecommendedKeywordInfo = {
  fbk_age_recommended_keyword_info: {
    title?: string | null;
    age_recommended_keyword_group_list: Array<{ age: string; recommended_keyword_list: Array<string | null> } | null>;
  };
};

export type GetRecommendedSearchKeywordVariables = Exact<{
  keyword: Scalars['String'];
}>;

export type GetRecommendedSearchKeyword = {
  recommended_search_keyword_list: {
    item_list: Array<
      | {
          keyword: string;
          keyword_highlighted: string;
          type: RecommendedSearchKeywordType;
          type_text?: string | null;
          display_category_id_list: Array<string>;
        }
      | {
          keyword: string;
          keyword_highlighted: string;
          type: RecommendedSearchKeywordType;
          type_text?: string | null;
          event_link_url: string;
        }
      | {
          keyword: string;
          keyword_highlighted: string;
          type: RecommendedSearchKeywordType;
          type_text?: string | null;
          pick_link_url?: string | null;
        }
      | {
          keyword: string;
          keyword_highlighted: string;
          type: RecommendedSearchKeywordType;
          type_text?: string | null;
          shop_id: string;
          typical_image_url?: string | null;
        }
      | {
          keyword: string;
          keyword_highlighted: string;
          type: RecommendedSearchKeywordType;
          type_text?: string | null;
          query_type: UserQueryRecommendedSearchKeywordType;
        }
      | {}
    >;
  };
};

export type GetSearchGoodsSuggestionVariables = Exact<{ [key: string]: never }>;

export type GetSearchGoodsSuggestion = {
  fbk_search_popular_keyword: { recent_searches_title: string; keywords_title: string; keywords: Array<string> };
};

export type GetSearchShopListVariables = Exact<{
  query_string: Scalars['String'];
  limit_count?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetSearchShopList = {
  fbk_srp_shop_list: {
    total_count: number;
    end_cursor?: string | null;
    item_list: Array<{
      shop_id: string;
      main_domain: string;
      name: string;
      keyword_list: Array<string | null>;
      bookmark_count: number;
      bookmarked: boolean;
      age_list: Array<string>;
      is_zpay: boolean;
      is_brand: boolean;
      state: number;
      category?: string | null;
      typical_image_url: string;
      is_free_shipping: boolean;
      alias_list?: Array<string> | null;
      delete_action: number;
      is_saved_shop: boolean;
      style_list: Array<{ __typename: 'ShopStyle'; value: string; color: string }>;
    }>;
  };
};

export type GetSearchFilterVariables = Exact<{
  category_id?: InputMaybe<Scalars['Int']>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  goods_filter_option?: InputMaybe<GoodsFilterOptionInput>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetSearchFilter = {
  fbk_srp_search_product_list?: {
    page_name?: string | null;
    has_next?: boolean | null;
    end_cursor?: string | null;
    goods_filter_option_list?: {
      item_list: Array<
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            category_option_list: Array<{
              id: string;
              name: string;
              multiple: boolean;
              properties: boolean;
              selected: boolean;
              count: number;
              sub_category_list: Array<{
                id: string;
                name: string;
                multiple: boolean;
                properties: boolean;
                selected: boolean;
                count: number;
              }>;
            }>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            color_option_list: Array<{
              name: string;
              image_url: string;
              text_color: number;
              selected: boolean;
              count: number;
            }>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            delivery_option_list: Array<{ name: string; selected: boolean }>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            interval: number;
            min: number;
            min_selected?: number | null;
            max: number;
            max_selected?: number | null;
            count_list: Array<number>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            total_count: number;
            price_range_list: Array<{ name: string; count: number; min: number; max: number; selected: boolean }>;
          }
        | {}
      >;
    } | null;
  } | null;
};

export type GetSearchProductListFilterVariables = Exact<{
  category_id?: InputMaybe<Scalars['Int']>;
  sorting_id?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  goods_filter_option?: InputMaybe<GoodsFilterOptionInput>;
  filter_id_list?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetSearchProductListFilter = {
  fbk_srp_search_product_list?: {
    page_name?: string | null;
    has_next?: boolean | null;
    end_cursor?: string | null;
    ui_item_list: Array<
      | {
          is_ad: boolean;
          image_url?: string | null;
          webp_image_url?: string | null;
          shop_name?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          zpay?: boolean | null;
          log?: string | null;
          aid?: string | null;
          product_url?: string | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          shop_product_no?: string | null;
          shop_id: string;
          is_zonly: boolean;
          is_brand: boolean;
          is_saved_product: boolean;
          review_count?: number | null;
          review_score?: number | null;
          is_new?: boolean | null;
          badge_list?: Array<{ type?: string | null }> | null;
          thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
        }
      | {}
    >;
    top_item_list: Array<
      | {
          image_url?: string | null;
          webp_image_url?: string | null;
          shop_name?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          zpay?: boolean | null;
          log?: string | null;
          aid?: string | null;
          product_url?: string | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          shop_product_no?: string | null;
          shop_id: string;
          is_zonly: boolean;
          is_brand: boolean;
          is_saved_product: boolean;
          review_count?: number | null;
          review_score?: number | null;
          is_new?: boolean | null;
          badge_list?: Array<{ type?: string | null }> | null;
          thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
        }
      | {}
    >;
    goods_filter_option_list?: {
      item_list: Array<
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            color_option_list: Array<{
              name: string;
              image_url: string;
              text_color: number;
              selected: boolean;
              count: number;
            }>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            delivery_option_list: Array<{ name: string; selected: boolean }>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            interval: number;
            min: number;
            min_selected?: number | null;
            max: number;
            max_selected?: number | null;
            count_list: Array<number>;
          }
        | {
            type: UxGoodsFilterOptionType;
            name: string;
            total_count: number;
            price_range_list: Array<{ name: string; count: number; min: number; max: number; selected: boolean }>;
          }
        | {}
      >;
    } | null;
  } | null;
};

export type GetRelatedSearchWordListVariables = Exact<{
  search_word: Scalars['String'];
}>;

export type GetRelatedSearchWordList = { fbk_related_search_word_list: Array<string> };

export type GetSearchProductListVariables = Exact<{
  input: FbkSearchProductInput;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetSearchProductList = {
  search_product_list: {
    has_next: boolean;
    end_cursor?: string | null;
    total_count: number;
    item_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  };
};

export type GetCarouselProductListVariables = Exact<{
  carousel_page_name?: InputMaybe<Scalars['String']>;
}>;

export type GetCarouselProductList = {
  carousel_list: Array<{
    benefit_section_id: string;
    main_title: string;
    sub_title?: string | null;
    carousel_product_list: Array<{
      catalog_product_id: string;
      image_url?: string | null;
      webp_image_url?: string | null;
      shop_name?: string | null;
      title?: string | null;
      price?: number | null;
      discount_rate?: number | null;
      free_shipping?: boolean | null;
      zpay?: boolean | null;
      log?: string | null;
      aid?: string | null;
      product_url?: string | null;
      has_coupon: boolean;
      final_price: number;
      max_price: number;
      is_zpay_discount: boolean;
      shop_product_no?: string | null;
      shop_id: string;
      is_zonly: boolean;
      is_brand: boolean;
      is_saved_product: boolean;
      review_count?: number | null;
      review_score?: number | null;
      is_new?: boolean | null;
      is_ad: boolean;
      badge_list: Array<{ type: string }>;
      thumbnail_emblem_badge_list: Array<{ type: string; image_url: string }>;
    }>;
  }>;
};

export type GetZigzinMainInfoVariables = Exact<{ [key: string]: never }>;

export type GetZigzinMainInfo = { zigzin_main_info: { entry_banner: { title: string; image_url?: string | null } } };

export type ShopInfo = {
  id: string;
  name: string;
  typical_image_url: string;
  comment?: string | null;
  bookmark_count: number;
  is_saved_shop: boolean;
  main_domain: string;
  style_list: Array<{ value?: string | null }>;
  representative_coupon?: { title: string; link_url: string } | null;
};

export type BannerGroup = {
  auto_rolling_interval?: number | null;
  is_auto_rolling: boolean;
  banner_list?: Array<{
    id: string;
    link_type?: ShopUxBannerLinkType | null;
    image_url?: string | null;
    link?: string | null;
    title?: string | null;
    name?: string | null;
  }> | null;
};

export type GetStoreInfoVariables = Exact<{
  shop_id: Scalars['ID'];
}>;

export type GetStoreInfo = {
  shop_ux_shop: {
    id: string;
    name: string;
    typical_image_url: string;
    comment?: string | null;
    bookmark_count: number;
    is_saved_shop: boolean;
    main_domain: string;
    banner_group?: {
      auto_rolling_interval?: number | null;
      is_auto_rolling: boolean;
      banner_list?: Array<{
        id: string;
        link_type?: ShopUxBannerLinkType | null;
        image_url?: string | null;
        link?: string | null;
        title?: string | null;
        name?: string | null;
      }> | null;
    } | null;
    style_list: Array<{ value?: string | null }>;
    representative_coupon?: { title: string; link_url: string } | null;
  };
  shop_ux_category_list: Array<{ id: string; name: string }>;
};

export type ShopFilterItem = {
  id: string;
  name?: string | null;
  description?: string | null;
  selected?: boolean | null;
};

export type ImageInfo = { image_url: string; aspect_Ratio?: number | null; link_url?: string | null };

export type ShopUxProductCardItemInfo = {
  is_new: boolean;
  browsing_type: ShopUxBrowsingType;
  has_coupon: boolean;
  is_zpay_discount: boolean;
  final_price: number;
  max_price: number;
  is_display_not_zpay: boolean;
  is_zonly: boolean;
  is_brand: boolean;
  ranking?: number | null;
  column_count?: number | null;
  review_count?: string | null;
  review_score?: number | null;
  is_saved_product?: boolean | null;
  product: {
    shop_product_no?: string | null;
    catalog_product_id?: string | null;
    shop_id: number;
    url: string;
    image_url: string;
    webp_image_url?: string | null;
    name: string;
    price: number;
    discount_rate?: number | null;
    payment_type?: ShopUxPaymentType | null;
    shipping_fee_type?: ShopUxShippingFeeType | null;
    sales_status?: ShopUxSalesStatus | null;
  };
  discount_info?: { image_url?: string | null; title?: string | null; color?: string | null } | null;
  thumbnail_emblem_badge_list?: Array<{ image_url: string }> | null;
};

export type GetComponentListVariables = Exact<{
  shop_id: Scalars['ID'];
  category_id?: InputMaybe<Scalars['ID']>;
  after_id?: InputMaybe<Scalars['ID']>;
  sorting_item_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetComponentList = {
  shop_ux_category_list: Array<{ id: string; name: string; children: Array<{ id: string; name: string }> }>;
  shop_ux_component_list?: {
    after_id?: string | null;
    has_next_page?: boolean | null;
    category_list?: Array<{ id: string; name: string }> | null;
    item_list: Array<
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | {
          type: ShopUxComponentType;
          is_new: boolean;
          browsing_type: ShopUxBrowsingType;
          has_coupon: boolean;
          is_zpay_discount: boolean;
          final_price: number;
          max_price: number;
          is_display_not_zpay: boolean;
          is_zonly: boolean;
          is_brand: boolean;
          ranking?: number | null;
          column_count?: number | null;
          review_count?: string | null;
          review_score?: number | null;
          is_saved_product?: boolean | null;
          product: {
            shop_product_no?: string | null;
            catalog_product_id?: string | null;
            shop_id: number;
            url: string;
            image_url: string;
            webp_image_url?: string | null;
            name: string;
            price: number;
            discount_rate?: number | null;
            payment_type?: ShopUxPaymentType | null;
            shipping_fee_type?: ShopUxShippingFeeType | null;
            sales_status?: ShopUxSalesStatus | null;
          };
          discount_info?: { image_url?: string | null; title?: string | null; color?: string | null } | null;
          thumbnail_emblem_badge_list?: Array<{ image_url: string }> | null;
        }
      | { type: ShopUxComponentType }
      | {
          type: ShopUxComponentType;
          main_title?: { text: string } | null;
          sub_title?: { text: string } | null;
          image?: { image_url: string; aspect_Ratio?: number | null; link_url?: string | null } | null;
          product_carousel: {
            item_column_count: number;
            component_list: Array<{
              is_new: boolean;
              browsing_type: ShopUxBrowsingType;
              has_coupon: boolean;
              is_zpay_discount: boolean;
              final_price: number;
              max_price: number;
              is_display_not_zpay: boolean;
              is_zonly: boolean;
              is_brand: boolean;
              ranking?: number | null;
              column_count?: number | null;
              review_count?: string | null;
              review_score?: number | null;
              is_saved_product?: boolean | null;
              product: {
                shop_product_no?: string | null;
                catalog_product_id?: string | null;
                shop_id: number;
                url: string;
                image_url: string;
                webp_image_url?: string | null;
                name: string;
                price: number;
                discount_rate?: number | null;
                payment_type?: ShopUxPaymentType | null;
                shipping_fee_type?: ShopUxShippingFeeType | null;
                sales_status?: ShopUxSalesStatus | null;
              };
              discount_info?: { image_url?: string | null; title?: string | null; color?: string | null } | null;
              thumbnail_emblem_badge_list?: Array<{ image_url: string }> | null;
            }>;
          };
          more_button?: { text: string; is_html_text?: boolean | null; link_url: string } | null;
        }
      | { type: ShopUxComponentType }
      | { type: ShopUxComponentType }
      | {
          type: ShopUxComponentType;
          main_title?: { text: string } | null;
          sub_title?: { text: string } | null;
          sorting_item_list: Array<{
            id: string;
            name?: string | null;
            description?: string | null;
            selected?: boolean | null;
          }>;
        }
      | null
    >;
  } | null;
};

export type UxGoodsCardItemInfo = {
  goods_id?: string | null;
  image_url?: string | null;
  webp_image_url?: string | null;
  video_url?: string | null;
  uuid?: string | null;
  bridge?: boolean | null;
  product_url?: string | null;
  title?: string | null;
  price?: number | null;
  discount_rate?: number | null;
  free_shipping?: boolean | null;
  origin_id?: string | null;
  zpay?: boolean | null;
  column_count?: number | null;
  ranking?: number | null;
  has_coupon: boolean;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id?: string | null;
  browsing_type: UxBrowsingType;
  aid?: string | null;
  image_ratio?: number | null;
  review_count?: number | null;
  shop_product_no?: string | null;
  shop_id: string;
  shop_name?: string | null;
  zpay_price?: number | null;
  is_zonly: boolean;
  is_brand: boolean;
  similar_search?: boolean | null;
  is_saved_product: boolean;
  bookmarked: boolean;
  shop?: { id: string; name: string; main_domain: string; state: number; zpay_status?: number | null } | null;
  discount_info?: { image_url?: string | null; color?: string | null; title?: string | null } | null;
  thumbnail_emblem_badge_list?: Array<{ image_url: string; type?: string | null }> | null;
};

export type GetZigzinShippingComponentListVariables = Exact<{
  shop_id: Scalars['ID'];
  after?: InputMaybe<Scalars['String']>;
  sorting_id?: InputMaybe<Scalars['ID']>;
  category_id?: InputMaybe<Scalars['ID']>;
}>;

export type GetZigzinShippingComponentList = {
  zigzin_saved_shop_items: {
    has_next?: boolean | null;
    end_cursor?: string | null;
    ui_item_list: Array<
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | {
          type: UxComponentType;
          position: number;
          filter_item_list: Array<{ str_id: string; name?: string | null; selected?: boolean | null }>;
        }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | {
          type: UxComponentType;
          position: number;
          image_url?: string | null;
          webp_image_url?: string | null;
          shop_name?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          zpay?: boolean | null;
          log?: string | null;
          aid?: string | null;
          product_url?: string | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          shop_product_no?: string | null;
          shop_id: string;
          is_zonly: boolean;
          is_brand: boolean;
          is_saved_product: boolean;
          review_count?: number | null;
          review_score?: number | null;
          is_new?: boolean | null;
          badge_list?: Array<{ type?: string | null }> | null;
          thumbnail_emblem_badge_list?: Array<{ type?: string | null; image_url: string }> | null;
        }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | {
          type: UxComponentType;
          position: number;
          main_title?: { text: string } | null;
          sub_title?: { text: string } | null;
          sorting_item_list: Array<{ id: string; name: string; selected: boolean; description?: string | null }>;
        }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
      | { type: UxComponentType; position: number }
    >;
  };
};

export type StorePickBannerDataPart = {
  title: string;
  image_url: string;
  date_started: number;
  date_ended: number;
  enabled?: boolean | null;
  discount: { enabled: boolean };
  tags: { free_shipping: boolean; coupon: boolean };
};

export type StorePickDetailDataPart = {
  artwork_image_url: string;
  description: string;
  artwork_image_width?: number | null;
  artwork_image_height?: number | null;
  coupon: { enabled: boolean; description: string; background_color: string };
};

export type StorePickProductsPresetDataPart = {
  products_preset_uuid?: string | null;
  catalog_product_id_list: Array<string>;
};

export type GetStorePickDataVariables = Exact<{
  id: Scalars['Int'];
  store_id: Scalars['Int'];
  store_pick_data_id?: InputMaybe<Scalars['Int']>;
}>;

export type GetStorePickData = {
  getStorePickData: {
    content_short_url?: string | null;
    banner: {
      title: string;
      image_url: string;
      date_started: number;
      date_ended: number;
      enabled?: boolean | null;
      discount: { enabled: boolean };
      tags: { free_shipping: boolean; coupon: boolean };
    };
    detail: {
      artwork_image_url: string;
      description: string;
      artwork_image_width?: number | null;
      artwork_image_height?: number | null;
      coupon: { enabled: boolean; description: string; background_color: string };
    };
    products_preset: { products_preset_uuid?: string | null; catalog_product_id_list: Array<string> };
    store: {
      id: string;
      main_domain: string;
      name: string;
      url: string;
      typical_image_url?: string | null;
      bookmark_count?: number | null;
    };
  };
};

export type ProductsPresetShopPart = {
  id: string;
  main_domain: string;
  name: string;
  url: string;
  typical_image_url?: string | null;
};

export type ProductsPresetCardItemPart = {
  image_url?: string | null;
  webp_image_url?: string | null;
  uuid?: string | null;
  product_url?: string | null;
  title?: string | null;
  price?: number | null;
  discount_rate?: number | null;
  free_shipping?: boolean | null;
  origin_id?: string | null;
  zpay?: boolean | null;
  column_count?: number | null;
  ranking?: number | null;
  has_coupon: boolean;
  final_price: number;
  max_price: number;
  is_zpay_discount: boolean;
  catalog_product_id?: string | null;
  browsing_type: ProductsPresetBrowsingType;
  review_count?: number | null;
  bookmarked: boolean;
  shop_product_no?: string | null;
  shop_id: string;
  zpay_price?: number | null;
  catalog_product_type?: ProductsPresetCatalogProductType | null;
  is_zonly: boolean;
  is_brand: boolean;
  status?: ProductsPresetGoodsStatus | null;
  quantity?: number | null;
  sales_status?: ProductsPresetSearchedProductSalesStatus | null;
  discount_info?: { image_url?: string | null; color?: string | null; title?: string | null } | null;
};

export type ProductsPresetResponseDataPart = {
  shop: { id: string; main_domain: string; name: string; url: string; typical_image_url?: string | null };
  product: {
    image_url?: string | null;
    webp_image_url?: string | null;
    uuid?: string | null;
    product_url?: string | null;
    title?: string | null;
    price?: number | null;
    discount_rate?: number | null;
    free_shipping?: boolean | null;
    origin_id?: string | null;
    zpay?: boolean | null;
    column_count?: number | null;
    ranking?: number | null;
    has_coupon: boolean;
    final_price: number;
    max_price: number;
    is_zpay_discount: boolean;
    catalog_product_id?: string | null;
    browsing_type: ProductsPresetBrowsingType;
    review_count?: number | null;
    bookmarked: boolean;
    shop_product_no?: string | null;
    shop_id: string;
    zpay_price?: number | null;
    catalog_product_type?: ProductsPresetCatalogProductType | null;
    is_zonly: boolean;
    is_brand: boolean;
    status?: ProductsPresetGoodsStatus | null;
    quantity?: number | null;
    sales_status?: ProductsPresetSearchedProductSalesStatus | null;
    discount_info?: { image_url?: string | null; color?: string | null; title?: string | null } | null;
  };
};

export type GetProductsPresetDataVariables = Exact<{
  products_preset_uuid: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  store_unique?: InputMaybe<Scalars['Boolean']>;
  group_index?: InputMaybe<Scalars['Int']>;
  use_ad_image?: InputMaybe<Scalars['Boolean']>;
  app_version?: InputMaybe<Scalars['String']>;
}>;

export type GetProductsPresetData = {
  products_preset_data: {
    type?: ProductsPresetType | null;
    total_count: number;
    end_cursor?: string | null;
    has_next_page: boolean;
    group_list_type?: ProductsPresetIdListType | null;
    groups: Array<{
      total_count: number;
      item_list: Array<{
        shop: { id: string; main_domain: string; name: string; url: string; typical_image_url?: string | null };
        product: {
          image_url?: string | null;
          webp_image_url?: string | null;
          uuid?: string | null;
          product_url?: string | null;
          title?: string | null;
          price?: number | null;
          discount_rate?: number | null;
          free_shipping?: boolean | null;
          origin_id?: string | null;
          zpay?: boolean | null;
          column_count?: number | null;
          ranking?: number | null;
          has_coupon: boolean;
          final_price: number;
          max_price: number;
          is_zpay_discount: boolean;
          catalog_product_id?: string | null;
          browsing_type: ProductsPresetBrowsingType;
          review_count?: number | null;
          bookmarked: boolean;
          shop_product_no?: string | null;
          shop_id: string;
          zpay_price?: number | null;
          catalog_product_type?: ProductsPresetCatalogProductType | null;
          is_zonly: boolean;
          is_brand: boolean;
          status?: ProductsPresetGoodsStatus | null;
          quantity?: number | null;
          sales_status?: ProductsPresetSearchedProductSalesStatus | null;
          discount_info?: { image_url?: string | null; color?: string | null; title?: string | null } | null;
        };
      }>;
    }>;
    item_list: Array<{
      shop: { id: string; main_domain: string; name: string; url: string; typical_image_url?: string | null };
      product: {
        image_url?: string | null;
        webp_image_url?: string | null;
        uuid?: string | null;
        product_url?: string | null;
        title?: string | null;
        price?: number | null;
        discount_rate?: number | null;
        free_shipping?: boolean | null;
        origin_id?: string | null;
        zpay?: boolean | null;
        column_count?: number | null;
        ranking?: number | null;
        has_coupon: boolean;
        final_price: number;
        max_price: number;
        is_zpay_discount: boolean;
        catalog_product_id?: string | null;
        browsing_type: ProductsPresetBrowsingType;
        review_count?: number | null;
        bookmarked: boolean;
        shop_product_no?: string | null;
        shop_id: string;
        zpay_price?: number | null;
        catalog_product_type?: ProductsPresetCatalogProductType | null;
        is_zonly: boolean;
        is_brand: boolean;
        status?: ProductsPresetGoodsStatus | null;
        quantity?: number | null;
        sales_status?: ProductsPresetSearchedProductSalesStatus | null;
        discount_info?: { image_url?: string | null; color?: string | null; title?: string | null } | null;
      };
    }>;
  };
};

export type GetRelatedShopListByRecentSavedShopVariables = Exact<{ [key: string]: never }>;

export type GetRelatedShopListByRecentSavedShop = {
  fbk_related_shop_list_by_recent_saved_shop: {
    item_list: Array<{ shop_id: string; name: string; typical_image_url?: string | null }>;
  };
};

export type GetActiveCouponPolicyListVariables = Exact<{
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
}>;

export type GetActiveCouponPolicyList = {
  active_coupon_policy_list: {
    total_count: number;
    item_list: Array<{
      id: string;
      coupon_target_type: string;
      title?: string | null;
      days_expire?: number | null;
      date_expire?: number | null;
      date_issue_start: number;
      date_issue_end: number;
      min_required_amount?: number | null;
      max_discount_amount?: number | null;
      discount_type: string;
      discount_amount?: number | null;
      discount_rate_bp?: number | null;
      display_discount_rate_bp: number;
      user_account_coupon?: { id: string; status: string } | null;
      shop_list: Array<{ shop_id: string; name: string; typical_image_url?: string | null }>;
    }>;
  };
};

export type GetShopListHasNewProductVariables = Exact<{ [key: string]: never }>;

export type GetShopListHasNewProduct = {
  getFbkShopListHasNewProduct: {
    item_list: Array<{ shop_id: string; name: string; typical_image_url?: string | null }>;
  };
};

export type GetStyleTabVariables = Exact<{ [key: string]: never }>;

export type GetStyleTab = {
  fbk_style_tab?: {
    event_type_list: { item_list: Array<{ event_type: string; description: string }> };
    content_list: Array<{
      id: string;
      uuid: string;
      title?: string | null;
      event_type: string;
      banner_image_url?: string | null;
      date_event_start?: number | null;
      date_event_end?: number | null;
      related_product_list: Array<{
        catalog_product_id: string;
        image_url?: string | null;
        webp_image_url?: string | null;
      }>;
    }>;
  } | null;
};

export type GetUserRefundAccountVariables = Exact<{ [key: string]: never }>;

export type GetUserRefundAccount = {
  user_refund_account?: { bank_code: string; account_number: string; account_holder: string } | null;
  user_account?: {
    full_name?: string | null;
    authenticated?: boolean | null;
    term_agreement_list: Array<{ term_type: UserAccountTermType }>;
  } | null;
};

export type AgreeUserRefundBankAccountVariables = Exact<{
  term_type_list: Array<UserAccountTermType> | UserAccountTermType;
}>;

export type AgreeUserRefundBankAccount = { agreeUserAccountTerm: { success: boolean } };

export type CreateUserRefundAccountVariables = Exact<{
  input: CreateUserRefundAccountInput;
}>;

export type CreateUserRefundAccount = { createUserRefundAccount: { refund_account_id: string } };

export type RequestUserAccountAuthenticationVariables = Exact<{ [key: string]: never }>;

export type RequestUserAccountAuthentication = { requestUserAccountAuthentication: { tr_cert: string } };
